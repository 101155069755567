import React from 'react';
import PreviewPDF from '../../../../components/previewPDF/PreviewPDF';
import moment from 'moment';
import { DATEFORMATHOURS} from '../../../../../services/service.values';

const PreviewPTDoc = ({ptDocument, paymentTerm, onClose}) => {
    const files = ptDocument.files;
    const status= ptDocument.status;

    const getPreviewWidth = () => {
        const containerWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) * 0.95 - 70;
        return containerWidth / files.length;
    };

    const renderDraftDocuments = () => {
        let updatedDocs = [];
        if(ptDocument.files.length >= 1){
            updatedDocs = ptDocument.files.map((item, index) => {
                const changes = ptDocument.requestedChanges ? ptDocument.requestedChanges[index] : null;
                return changes ? {...item, requestedChange: {...changes}} : {...item, requestedChange: null}
            }).reverse();
        }
        return updatedDocs.map((file, index) => {
            return(
                <div className="modal__preview-file" key={`file-${file.id}`} id={`file-${file.id}`} style={{margin: '0 16px'}}>
                    <div className="doc-preview-modal__version">{index === 0 ? 'Current version' : 'Earlier version'}</div>
                    <table className="table table-bordered table-hover table-striped">
                        <tbody>
                            <tr className={status === "CONFIRMED" && index === 0 ? 'table-success' : 'table-danger'}>
                                <th scope="col">Status</th>
                                <th>{status === "CONFIRMED" && index === 0 ? 'Approved' : 'Not-Approved'}</th>
                            </tr> 
                        </tbody>
                        <tbody>
                            <tr>
                                <th scope="col">{index === 0 ? 'Current Version Date' : 'Earlier Version Date'}</th>
                                <th>{moment(file.createdAt).format(DATEFORMATHOURS)}</th>
                            </tr> 
                        </tbody>
                        <tbody>
                            <tr>
                                <th scope="col">Additional Information</th>
                                <th>{file.remark}</th>
                            </tr> 
                        </tbody>
                        <tbody>
                            <tr>
                                <th scope="col">Change Request Date</th>
                                <th> {file.requestedChange ? moment(file.requestedChange.createdAt).format(DATEFORMATHOURS) : "-"}</th>
                            </tr> 
                        </tbody>
                        <tbody>
                            <tr>
                                <th scope="col">Change Request</th>
                                <th> {file.requestedChange ? file.requestedChange.text : "-"}</th>
                            </tr> 
                        </tbody>
                    </table>
                    <PreviewPDF width={getPreviewWidth()} file={file}/>
                </div>
            )
       }) 
    }

    const renderDraftTexts = () => {
        let updatedDraftTexts = [];
        if(ptDocument.ptText.length >= 1){
            updatedDraftTexts = ptDocument.ptText.map((item, index) => {
                const changes = ptDocument.requestedChanges ? ptDocument.requestedChanges[index] : null;
                return changes ? {...item, requestedChange: {...changes}} : {...item, requestedChange: null}
            }).reverse();
        }
        return updatedDraftTexts.map((draft, index) => {
            return(
                <div className="modal__preview-file" style={{margin: '0 16px'}} key={index}>
                <div>
                   <table className="table table-bordered table-hover table-striped">
                        <tbody>
                            <tr className={status === "CONFIRMED" && index === 0 ? 'table-success' : 'table-danger'}>
                                <th scope="col">Status</th>
                                <th>{status === "CONFIRMED" && index === 0 ? 'Approved' : 'Not-Approved'}</th>
                            </tr> 
                        </tbody>
                        <tbody>
                            <tr>
                                <th scope="col">{index === 0 ? 'Current Version Date' : 'Earlier Version Date'}</th>
                                <th>{moment(draft.createdAt).format(DATEFORMATHOURS)}</th>
                            </tr> 
                        </tbody>
                        
                        <tbody>
                            <tr>
                                <th scope="col">Additional Information</th>
                                <th>{draft.additionalInformation}</th>
                            </tr> 
                        </tbody>
                        <tbody>
                            <tr>
                                <th scope="col">Change Request Date</th>
                                <th> {draft.requestedChange ? moment(draft.createdAt).format(DATEFORMATHOURS) : "-"}</th>
                            </tr> 
                        </tbody>
                        <tbody>
                            <tr>
                                <th scope="col">Change Request</th>
                                <th>{draft.requestedChange ? draft.requestedChange.text : "-"}</th>
                            </tr> 
                        </tbody>
                        <tbody>
                            <tr>
                                <th scope="col">Draft Text</th>
                                <th>{draft.text}</th>
                            </tr> 
                        </tbody>
                    </table>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="modal__container doc-preview-modal">
            <form className="modal__wrapper px-0 py-0" >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-header-center">
                            <h5 className="modal-title">
                                {ptDocument.ptType === "document" ? "Preview Documents" : "Preview Drafts"}
                            </h5></div>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true"><span>&times;</span></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="doc-preview-modal__type">
                            <div className="doc-preview-modal__type__title">
                                Document type
                            </div>
                            <div className="doc-preview-modal__type__value">
                                {paymentTerm === 'Letter of credit' ? 'L/C Drafts' : paymentTerm === 'Confirmed letter of credit' ? 'Confirmed L/C Drafts' : 'Avalised Drafts'}
                            </div>
                        </div>
                        <div className="d-flex" id="doc-preview-container">
                            {ptDocument.ptType === "document" ? renderDraftDocuments() : renderDraftTexts()}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default PreviewPTDoc;
