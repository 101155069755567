import React, { Component } from 'react';
import { EnumsService, PortService } from '../../../../services';
import { INCOTERMOPT, MEASUREMENTS } from '../../../../services/service.values';
import { DOCUMENT_TYPES, DocumentFactory } from '../services/documents.service';
import FormRadioField from '../../../components/form/FormRadioField';
import NumberFormat from 'react-number-format';
const Countries = EnumsService.countries();

export default class DocumentaryInstructions extends Component {
    CUSTOM_COMPONENT_STYLE = {
        wrapperClassName: "form-group",
        labelClassName: "label",
        inputClassName: "form-control",
        selectClassName: "custom-select",
        radioColLayout: { left: "col-sm-6 col-md-6 col-lg-6 col-xl-4", right: "col-auto" }
    }

    constructor(props) {
        super(props);
        const data = props.data
            ? {
                ...props.data.documentaryInstructions,
                instructions: props.data.shipments.map(shipment => ({
                    id: Math.floor(Math.random() * 10000),
                    amount: shipment.amount
                }))
            } : {
                instructions: [],
                packingAndMarkings: '',
                billOfLadingNotify: '',
                billOfLadingConsignee: '',
                certOfOriginNotify: '',
                certOfOriginConsignee: ''
            };
        this.state = {
            multipleSets: data.instructions && data.instructions.length > 0,
            packingAndMarkings: data.packingAndMarkings,
            instructions: data.instructions || [],
            billOfLading: {
                notify: data.billOfLadingNotify,
                consignee: data.billOfLadingConsignee
            },
            certificateOfOrigin: {
                notify: data.certOfOriginNotify,
                consignee: data.certOfOriginConsignee,
                sameAsBillOfLading: !props.data
            },
            destination: {
                country: props.trade.destCountry,
                port: props.trade.destPort
            },
            commercialInvInd: 'GLOBAL',
            showError: false
        };
        this.updateInstruction = this.updateInstruction.bind(this);
        this.addInstruction = this.addInstruction.bind(this);
        this.removeInstruction = this.removeInstruction.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }

    componentDidMount() {
        if (this.state.instructions.length === 0) {
            this.addBalanceItem();
        }
    }

    get trade() {
        return this.props.trade;
    }

    addBalanceItem() {
        this.setState({
            instructions: [{
                id: 'balance',
                amount: parseFloat((this.props.vesselNomination && this.props.vesselNomination.nominateQuantity) ? this.props.vesselNomination.nominateQuantity : this.trade.measure)
            }]
        })
    }

    addInstruction(id, amount) {
        this.setState({
            instructions: [
                ...this.state.instructions.slice(0, this.state.instructions.length - 1),
                {
                    id: id || Math.floor(Math.random() * 10000),
                    amount: amount || ''
                },
                this.state.instructions[this.state.instructions.length - 1]
            ]
        })
    }

    updateInstruction(id, index, amount) {
        const items = [...this.state.instructions];
        items[index].amount = parseFloat(amount) || '';
        this.recalculateBalanceItem(items);
        this.setState({
            instructions: items
        })
    }

    removeInstruction(index) {
        const items = [...this.state.instructions];
        items.splice(index, 1);
        this.recalculateBalanceItem(items);
        this.setState({
            instructions: items
        })
    }

    recalculateBalanceItem(items) {
        const balanceItem = items.find(item => item.id === 'balance');
        balanceItem.amount = ((this.props.vesselNomination && this.props.vesselNomination.nominateQuantity) ? this.props.vesselNomination.nominateQuantity : this.trade.measure) - items.reduce((prev, current) => {
            if (current.id === 'balance') {
                return prev;
            }
            return prev + (parseFloat(current.amount) || 0);
        }, 0);
    }

    mapInstructionsToPostParams(instructions = []) {
        if (!this.state.multipleSets) {
            return [{
                amount: parseFloat((this.props.vesselNomination && this.props.vesselNomination.nominateQuantity) ? this.props.vesselNomination.nominateQuantity : this.trade.measure),
                text: ''
            }]
        }
        return instructions.reduce((memo, instruction) => {
            if (instruction.amount && instruction.amount > 0) {
                memo.push({
                    text: '',
                    amount: instruction.amount
                });
            }
            return memo;
        }, [])
    }

    onSubmitHandler(e) {
        e.preventDefault();
        const form = e.target;
        const isValid = form.checkValidity();
        if (!isValid) {
            this.setState({ showError: true });
            form.reportValidity();
            return;
        }
        this.setState({ showError: false });
        const params = {
            instructions: this.mapInstructionsToPostParams([...this.state.instructions]),
            packingAndMarkings: this.state.packingAndMarkings,
            billOfLadingNotify: this.state.billOfLading.notify,
            billOfLadingConsignee: this.state.billOfLading.consignee,
            certOfOriginNotify: this.state.certificateOfOrigin.notify,
            certOfOriginConsignee: this.state.certificateOfOrigin.consignee,
            destPort: this.state.destination.port,
            destCountry: this.state.destination.country,
            commercialInvInd: this.state.commercialInvInd
        };
        if (this.state.certificateOfOrigin.sameAsBillOfLading) {
            params.certOfOriginNotify = params.billOfLadingNotify;
            params.certOfOriginConsignee = params.billOfLadingConsignee;
        }
        this.props.onSubmit(params);
    }

    renderDocumentaryInstructions() {
        return (
            <div className="di-form__doc-info">
                {this.trade.payment} {this.trade.paymentPeriod}
                <br />
                The original doc to consist of:<br />
                <ul>
                    <li>
                        Full set of 3/3 originals plus 3 (three) non-negotiable copies of 'clean on board'
                        charter party bills
                        of lading made out to order and blanked endorsed, marked 'freight prepaid' as per
                        Charter Party
                        {this.trade.incoterm === 'CIF' || this.trade.incoterm === 'CFR' ? `, and showing ${Countries[this.trade.destCountry]}` : '.'}
                    </li>
                    <li>
                        Seller's original signed Invoice for the value of Product based on the Bill of
                        Lading quantity (expressed in MT) payable
                        3 business days after receipt.
                    </li>

                    <li>Certificate of Weight and Quality issued by contractual appointed {this.trade.inspectionName}.</li>
                    <li>Certificate of packing issued by seller ({this.trade.seller}).</li>
                    <li>{this.trade.shipping === ("CONTAINER") ? <>Container Cleanliness Certificate</> : <> Hold Cleanliness Certificate</>} issued by {this.trade.inspectionName}</li>
                    <li>
                        Certificate of fumigation of goods effected at time of shipment of the goods from
                        the origin issued by{` `}
                        {this.trade.inspectionName}. Fumigation certificate with date after B/L date is acceptable.
                    </li>
                    <li>Phytosanitary certificate issued by competent authority</li>
                    <li>Certificate of origin issued by competent authority.</li>
                    {this.trade.riceCategory === ("ORGANIC") ? <li>A certificate enumerating that the rice is Organic issued by an accredited supervision company or organisation</li> : ""}
                    {this.trade.riceCategory === ("SRP") ? <li>SRP verification statement to be delivered by an accredited supervision company</li> : ""}
                    {/* {<li>{this.trade.riceCategory} Rice Certificate</li>} */}
                    {(this.trade.incoterm === 'CIF') && <li>Insurance Certificate.</li>}
                    {DocumentFactory.getSelectedOpsDocumentList(this.trade) && DocumentFactory.getSelectedOpsDocumentList(this.trade).map(doc => {
                        switch (doc.type) {
                            case DOCUMENT_TYPES.QUALITY_APPEARANCE_CERT:
                                return <li key={doc.type}>
                                    Certificate issued by contractual appointed {this.trade.inspectionName} certifying that the
                                    quality and appearance of rice delivered is equal to or better than the above mentioned {this.trade.inspectionName} sealed sample.</li>
                            case DOCUMENT_TYPES.NON_GMO:
                                return <li key={doc.type}>Non-GMO certificate issued by shippers.</li>
                            case DOCUMENT_TYPES.EXPORT_DECLARATION:
                                return <li key={doc.type}>Copy of export declaration.</li>
                            case DOCUMENT_TYPES.CERT_OF_CONFORMITY:
                                return <li key={doc.type}>Certificate of conformity issued by contractual appointed {this.trade.inspectionName}.</li>
                            case DOCUMENT_TYPES.BSC:
                                return <li key={doc.type}>Bordereau De Suivi Des Cargaison.</li>
                            default:
                                return <li key={doc.type}>{doc.title}</li>
                        }
                    })}
                </ul>
                Except for the commercial invoice, third party doc are acceptable. Letters of indemnity for
                missing doc are not acceptable.

                {this.trade.specialRequest !== '' ? (
                    <>
                        <br />
                        Special Request - {this.trade.specialRequest}
                    </>
                ) : ''}

            </div>
        )
    }

    renderDestination = () => {
        const ports = PortService.getAsMap(this.state.destination.country);
        return (
            <React.Fragment>
                <h3 className="di-form__heading">Destination</h3>
                <div className="row notify-consignee-block mb-4">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-4">
                                <label className="modal__label" htmlFor="country">Country <span className="req-field">*</span>:</label>
                            </div>
                            <div className="col-8">
                                <select
                                    className="di-form__input"
                                    id="country"
                                    value={this.state.destination.country}
                                    disabled={this.props.previewMode}
                                    required={true}
                                    onChange={e => this.setState({
                                        destination: {
                                            ...this.state.destination,
                                            country: e.target.value
                                        }
                                    }
                                    )}
                                >
                                    <option value="">
                                        Select
                                    </option>
                                    {Object.keys(Countries).map(i => {
                                        return (
                                            <option key={i} value={i}>
                                                {Countries[i]}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-4">
                                <label className="modal__label" htmlFor="port">Port*:</label>
                            </div>
                            <div className="col-8">
                                {Object.keys(ports).length !== 0 ? (
                                    <select
                                        className="di-form__input"
                                        id="port"
                                        value={this.state.destination.port}
                                        disabled={this.props.previewMode}
                                        required={true}
                                        onChange={e => this.setState({
                                            destination: {
                                                ...this.state.destination,
                                                port: e.target.value
                                            }
                                        }
                                        )}
                                    >
                                        <option value="">
                                            Select
                                        </option>
                                        {Object.keys(ports).map(i => {
                                            return (
                                                <option key={i} value={i}>
                                                    {ports[i]}
                                                </option>
                                            );
                                        })}
                                    </select>
                                ) : (
                                    <input
                                        className="di-form__input"
                                        type="text"
                                        id="port"
                                        value={this.state.destination.port}
                                        disabled={this.props.previewMode}
                                        onChange={e => this.setState({
                                            destination: {
                                                ...this.state.destination,
                                                port: e.target.value
                                            }
                                        }
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const className = this.props.isModal ? 'di-form di-form--modal di-form-modal-pop' : 'di-form';
        const isMultipleSetsCreated = this.state.multipleSets && this.state.instructions.length === 1;
        return (
            <form onSubmit={this.onSubmitHandler} className={className} noValidate={true}>
                {this.props.isModal && (
                    <h5 className="di-form__heading">Documentary Instructions</h5>
                )}
                 <h3 className='chidlTradeRef_header'>{this.props.trade.tradeRefId || this.props.trade.id}</h3>
                 <div className='br_bottom'></div>
                {/* RDEV-1455 #Hiding multiple sets for sandbox */}
                {!this.props.previewMode && (
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.multipleSets}
                            id="multipleSets"
                            disabled={this.props.previewMode}
                            onChange={e => {
                                let checked = e.target.checked;
                                this.setState(prevState => ({
                                    multipleSets: checked,
                                    commercialInvInd: checked ? prevState.commercialInvInd : 'GLOBAL',
                                    instructions: [{
                                        id: 'balance',
                                        amount: parseFloat((this.props.vesselNomination && this.props.vesselNomination.nominateQuantity) || this.trade.measure)
                                    }]
                                }))
                            }}
                        />
                        <label className="form-check-label" htmlFor="multipleSets">
                            Multiple sets of bills of lading required
                        </label>
                    </div>
                )}
                <div className={this.state.multipleSets ? 'left-padded1' : 'd-none'}>
                    <div className="row">
                        {this.state.instructions.map((instruction, index) =>
                            <div className="col-sm-12 col-md-4 mb-3" key={instruction.id}>
                                <div className="row instruction-items">
                                    <div className="col-1">
                                        {index + 1}.
                                    </div>
                                    <div className="col-4">
                                        <NumberFormat
                                            displayType="input"
                                            className="di-form__input m-0 w-100"
                                            data-readonly={this.props.previewMode || instruction.id === 'balance'}
                                            min={1}
                                            thousandSeparator={true}
                                            max={this.trade.measure}
                                            disabled={this.props.previewMode}
                                            onValueChange={values => this.updateInstruction(instruction.id, index, values.value)}
                                            tabIndex={instruction.id === 'balance' ? -1 : null}
                                            value={instruction.amount}
                                            decimalScale={2}
                                            allowNegative={false}
                                            isAllowed={(values) => {
                                                const { floatValue, value } = values;
                                                return value === "" || floatValue <= this.trade.measure;
                                            }}
                                        />
                                    </div>
                                    <div className="col-1">
                                        {MEASUREMENTS[this.trade.measurement]}
                                    </div>
                                    <div className="col-4">
                                        {!this.props.previewMode && instruction.id !== 'balance' && (
                                            <button
                                                className="btn btn-link"
                                                type="button"
                                                onClick={e => this.removeInstruction(index)}
                                            >
                                                Delete
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!this.props.previewMode && (
                        <button className="btn btn-link" type="button" onClick={e => this.addInstruction()}>
                            + Add New
                        </button>
                    )}
                    <hr />
                    <div className="total-measure">
                        Total
                        {/* <input className="di-form__input d-inline" value={this.trade.measure}
                            readOnly={true} /> */}
                        <NumberFormat className="di-form__input d-inline"
                            value={this.state.instructions.length && this.state.instructions.reduce((acc, instruction) => { return acc + parseFloat(instruction.amount || 0) }, 0)}
                            thousandSeparator={true}
                            displayType="input"
                            disabled="true"
                        />
                        {MEASUREMENTS[this.trade.measurement]}
                    </div>
                </div>
                {this.trade.incoterm === INCOTERMOPT.FOB && this.renderDestination()}
                <h3 className="di-form__heading">Bill of Lading</h3>
                <div className="row notify-consignee-block mb-4">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4">
                                <label className="modal__label" htmlFor="notifyBOL">Notify <span className="req-field">*</span>:</label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    className="di-form__input"
                                    type="text"
                                    id="notifyBOL"
                                    value={this.state.billOfLading.notify}
                                    disabled={this.props.previewMode}
                                    required={true}
                                    onChange={e => this.setState({
                                        billOfLading: {
                                            ...this.state.billOfLading,
                                            notify: e.target.value
                                        }
                                    }
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4">
                                <label className="modal__label" htmlFor="consigneeBOL">Consignee <span className="req-field">*</span>:</label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    className="di-form__input"
                                    type="text"
                                    id="consigneeBOL"
                                    value={this.state.billOfLading.consignee}
                                    disabled={this.props.previewMode}
                                    required={true}
                                    onChange={e => this.setState({
                                        billOfLading: {
                                            ...this.state.billOfLading,
                                            consignee: e.target.value
                                        }
                                    }
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {!this.props.previewMode && (
                    <div className="form-check left-padded">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.certificateOfOrigin.sameAsBillOfLading}
                            disabled={this.props.previewMode}
                            onChange={e => this.setState({
                                certificateOfOrigin: {
                                    ...this.state.certificateOfOrigin,
                                    sameAsBillOfLading: e.target.checked
                                }
                            })}
                            id="certificateOriginCheck"
                        />
                        <label className="form-check-label" htmlFor="certificateOriginCheck">
                            Use same info for certificate of origin
                        </label>
                    </div>
                )}
                <div
                    className={this.state.certificateOfOrigin.sameAsBillOfLading ? 'd-none' : ''}>
                    <h3 className="di-form__heading">Certificate of origin</h3>
                    <div className="row notify-consignee-block mb-4">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="modal__label"
                                        htmlFor="certOfOriginNotify">Notify <span className="req-field">*</span>:</label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        className="di-form__input"
                                        type="text"
                                        id="certOfOriginNotify"
                                        required={!this.state.certificateOfOrigin.sameAsBillOfLading}
                                        value={this.state.certificateOfOrigin.notify}
                                        disabled={this.props.previewMode}
                                        onChange={e => this.setState({
                                            certificateOfOrigin: {
                                                ...this.state.certificateOfOrigin,
                                                notify: e.target.value
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="modal__label"
                                        htmlFor="certOfOriginConsignee">Consignee <span className="req-field">*</span>:</label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        className="di-form__input"
                                        type="text"
                                        id="certOfOriginConsignee"
                                        required={!this.state.certificateOfOrigin.sameAsBillOfLading}
                                        value={this.state.certificateOfOrigin.consignee}
                                        disabled={this.props.previewMode}
                                        onChange={e => this.setState({
                                            certificateOfOrigin: {
                                                ...this.state.certificateOfOrigin,
                                                consignee: e.target.value
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.multipleSets && !this.props.previewMode ? <div>
                    <FormRadioField
                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                        name="commercialInvInd"
                        items={{
                            GLOBAL: 'One Global Invoice For All B/Ls',
                            MULTISET: 'One Invoice per Each Set of B/L'
                        }}
                        required={true}
                        onChange={(event) => this.setState({
                            commercialInvInd: event.target.value
                        })}
                        value={{
                            label: 'Select Commercial invoice',
                            value: this.state.commercialInvInd,
                        }} />
                </div> : null}
                <label className="modal__label doc-instruct__label">Packing &amp; Markings:</label>
                <textarea
                    onChange={e => this.setState({
                        packingAndMarkings: e.target.value
                    })}
                    placeholder="Changes in packing relative to the original trade request will not be reflected in platform prices."
                    disabled={this.props.previewMode}
                    value={this.state.packingAndMarkings}
                    className="di-form__textarea di-form__textarea--short"
                    rows="1"
                />
                {this.renderDocumentaryInstructions()}
                {!this.props.previewMode && (
                    <React.Fragment>
                        <div className="required-fields-note">
                            <span className="req-field">*</span> Required fields
                        </div>
                        {this.state.showError && (
                            <div className="trades-dtls__error-message text-center">
                                Please, complete all required fields before submitting
                            </div>
                        )}
                        {isMultipleSetsCreated ? <div className="trades-dtls__error-message text-center">
                            Please, split the quantity for multiple set B/L or uncheck the multiple set B/L option
                        </div> : null}
                        <div className="text-center">
                            <button disabled={isMultipleSetsCreated} type="submit" className="btn btn-primary">
                                    Submit
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </form>
        );
    }
}
