import React from 'react';
import './FormSelectDropdown.scss';

class FormSelectDropdown extends React.Component {


  render() {
    const { 
      name,  
      value, 
      items,
      keyLabel='label', 
      keyValue='value',
      onSelect, 
      validation, 
      disabled, 
      showDefault,
      captionText,
      disableDefault=true,
      defaultValue ="",
      defaultLabel = "Select",
      customStyle = {
        labelClassName :"label",
        wrapperClassName : "form-select",
        inputClassName : "input",
        isInvalidClassName: "select_error select_error--dropdown",
        isValidClassName: ''
      } 
    } = this.props;

    const isInvalid = validation && validation.hasOwnProperty(name);

    if(!items || items.length === 0){
      return false;
    }else{
      return (
        <div className={customStyle.wrapperClassName}>
          <label className={customStyle.labelClassName} 
            htmlFor={name}>
            {value.label}
            {value.required && <span className="req-field">&nbsp;*</span>}
          </label>
          <select
            className={`${customStyle.selectClassName}
                        ${value.value ? "value" : "placeholder"} 
                        ${isInvalid ? customStyle.isInvalidClassName :  value.touched || value.validated ? customStyle.isValidClassName : ''}`}
            name={name}
            id={name}
            required = {!!value.required}
            disabled={disabled}
            onChange={e => {
              onSelect(e)
            }
            }
            value={value.value ? value.value : ''}
          >
            <option value="" disabled={disableDefault} hidden={!showDefault} defaultValue={defaultValue} >{defaultLabel}</option>
            {items.map((item, index) => {
              return ( <option key={index} label={item[keyLabel]} value={(keyValue)?item[keyValue]:item}>{item[keyLabel]}</option> );
            })}
          </select>
          { captionText && <small id={`${name}Help`} className="form-text text-muted">{captionText}</small>}
        </div>
      );
    }

  }
}

export default FormSelectDropdown;
