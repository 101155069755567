import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from "connected-react-router";
import _ from 'lodash';
import moment from 'moment';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CustomMaterialPreloader from '../../components/preloader/CustomMaterialPreloader';
import TradeTablePanel from './TradeTablePanel';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

import { FetchNewTrade, FetchTrade} from '../../../modules/module.trade';

import { DATEFORMAT, INCOTERMOPT, SHIPPINGTYPES, TRADE_STATUS_HUMANIZE, TRADE_STATUS, getNominateVesselStatus } from '../../../services/service.values';
import { viewTradeListPermission } from '../../../services/service.permission';
import { EnumsService } from "../../../services/service.utils";
import { RiceService } from '../../../services/service.rice';

import './trades.scss';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { faTimes, faHistory, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import TradeQuantityFormatter from '../../components/Formatter/TradeQuantityFormatter';
const Countries = EnumsService.countries();

class PastTrades extends React.Component {

    constructor(props){
        super(props);
        if(!props.trade.items.CLOSED.bookMark){
            this.props.FetchTrade('CLOSED');
        }
        this.state = {
            showModal: true,
            modal: '',
            modalData: {},
        };
    }

    componentDidMount = () => {
        if(this.props.trade.lastUpdate){
            this.props.FetchNewTrade();
        }
    }
   
//Render functions
sortRef  = (a, b) => { 
    let refA = a.tradeRefId || a.id;
    let refB = b.tradeRefId || b.id;
    return refA.localeCompare(refB);
};
sortQuantity  = (a, b) => (parseInt(a.quantity) - parseInt(b.quantity));
renderOverflow = (content) => <span className="exchange__overflow">{content}</span>
renderOrigin = rowData => this.renderOverflow(Countries[rowData.origin]);
renderRiceType = rowData => {
    if(rowData.riceType === RiceService.enums.REF_OTHER){
        return rowData.riceTypeOther
    }else{
        const rice = RiceService.getByRef(rowData.riceType);
        return rice?rice.label:rowData.riceType;
    }   
}
renderStatus = (rowData) => (<React.Fragment>
    <div className={`trades__cell trades__cell_status trades__cell_status--${TRADE_STATUS[rowData.status]}`}>
        {TRADE_STATUS.PROFORMA_INVOICE !== rowData.status ? TRADE_STATUS_HUMANIZE[TRADE_STATUS[rowData.status]] : getNominateVesselStatus(rowData, TRADE_STATUS_HUMANIZE[TRADE_STATUS[rowData.status]], this.props.user.companyId)}
    </div>
</React.Fragment>);

//TODO TO EXTERNALIZE IN A TRADE SERVICE
columns =  {
    REF:            { title: 'Trade Ref Id', field: 'tradeRefId ', type: 'string', customSort: this.sortRef, render: rowData => rowData.tradeRefId || rowData.id},
    STATUS:         { title: 'Status', field: 'status', render: this.renderStatus },
    BUYER:          { title: 'Buyer', field: 'buyer', render: rowData => this.renderOverflow(rowData.buyer)},
    SELLER:         { title: 'Seller', field: 'seller', render: rowData => this.renderOverflow(rowData.seller)},
    UPDATED_AT:     { title: 'Updated At ', field: 'updatedAt', type: 'date', hidden: true, defaultSort: 'desc'},
    SHIPPING_TYPE:  { title: 'Vessel/ container', field: 'shipping', render: rowValue => SHIPPINGTYPES[rowValue.shipping] },
    QUANTITY: { title: 'Quantity(MT)', filed: 'measure', customSort: this.sortQuantity, render: rowData => <TradeQuantityFormatter trade={rowData} />},
    PRICE:          { title: 'Price', field: 'price', type: 'numeric', render: rowData => <NumberFormat value={rowData.price} displayType={'text'} thousandSeparator={true} prefix={rowData.currency === 'USD' || rowData.currency==='US$'?'$':'€'} renderText={value => <span className="exchange__overflow">{value}</span>} ></NumberFormat>},
    ORIGIN:         { title: 'Origin', field: 'origin', render: this.renderOrigin },
    INCOTERM:       { title: 'Incoterm', field: 'incoterm' },
    PORT:           { title: 'Port of load/ Destination', field: 'destPort', render: rowData => this.renderOverflow((rowData.incoterm === INCOTERMOPT.FOB)?
                        `${rowData.loadPort}, ${Countries[rowData.loadCountry]}` : `${rowData.destPort}, ${Countries[rowData.destCountry]}`)},
    RICE_TYPE:      { title: 'Rice Type', field: 'riceType', render: rowData => this.renderOverflow(this.renderRiceType(rowData))},
    SHIPPING_FROM:  { title: 'Shipping/ Delivery Period Start', field: 'deliveryStartDate', render: rowData => this.renderOverflow(moment(rowData.deliveryStartDate).format(DATEFORMAT))},
    SHIPPING_TO:    { title: 'Shipping/ Delivery Period End', field: 'deliveryEndDate', render: rowData => this.renderOverflow(moment(rowData.deliveryEndDate).format(DATEFORMAT))},
    CREATED_AT:     { title: 'Creation Date ', field: 'createdAt', render: rowData => this.renderOverflow(moment(rowData.createdAt).format(DATEFORMAT))},
}

defaultColumns = [
    this.columns.REF, 
    this.columns.BUYER, 
    this.columns.SELLER, 
    this.columns.STATUS, 
    this.columns.QUANTITY, 
    this.columns.PRICE, 
    this.columns.RICE_TYPE, 
    this.columns.CREATED_AT, 
    this.columns.SHIPPING_TYPE, 
    this.columns.SHIPPING_TO]

    renderModal = () => {
                return (<div className="modal__container">
                    <form className="modal__wrapper px-0 py-0 authcode"
                        onSubmit={e => {
                                e.preventDefault();  
                                this.closeModal();}}>
                        <div className="modal-content">
                            <div className="modal-header">   
                                <Link to="/"  className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>    
                                </Link>
                            </div>          
                            <div className="modal-body">
                                <p className="center-text"><h6>You are not authorized to perform this action.</h6> </p>
                            </div>     
                            <div className="modal-footer"><Link to="/" className="btn-popup btn-cancel "><FontAwesomeIcon icon={faTimes} /> Cancel</Link> </div>
                        </div>
                    </form>
                </div>);
    };  

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            modalData: {}
        });
    };

    render() {
        const { trade } = this.props;
        
        const data = Object.values(trade.items.tradeMap)
            .filter(({requestStatus}) => ['PAYED','CLOSED'].includes(requestStatus))
            .filter(({childTradeRequestID}) => !!childTradeRequestID.length) //empty array = parent trade

        if(viewTradeListPermission() === 0 ){
            return ( <div>{this.state.showModal && this.renderModal()} </div>);
        }else {
        return (
            <>
                <Header />
                <section className="trades">
                    <div className="row">
                        <div className="col"><h2 className="trades__heading">Past Contracts</h2></div>
                        <div className="col text-right">
                            <Tooltip title="Refresh"><IconButton onClick={ (e) => this.props.FetchNewTrade("CLOSED") }><FontAwesomeIcon icon={faSyncAlt}/></IconButton></Tooltip>
                            <Tooltip title="Load more"><IconButton onClick={ (e) => this.props.FetchTrade("CLOSED")  }><FontAwesomeIcon icon={faHistory}/></IconButton></Tooltip>
                        </div>
                    </div>

                    <MaterialTable
                            columns={this.defaultColumns}
                            data={data}
                            title="Trades Board"
                            onRowClick={(event, rowData) => this.props.navigate('/details/' + rowData.id)}
                            isLoading={trade.items.CLOSED.pending || trade.items.NEW.pending}
                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                SortArrow: ArrowUpward,
                                DetailPanel: ChevronRight
                            }}
                            components={{
                                OverlayLoading: CustomMaterialPreloader,
                                Toolbar: () => (false)
                            }}
                            detailPanel={[{
                                disabled: false,
                                isFreeAction: true,
                                render: rowData => rowData.childTradeRequestID.length !== 0 ? <TradeTablePanel trade={rowData} />:false
                            }]}
                            options={{
                                search: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 15, 20, 30],
                                rowStyle: (rowData, index) => {
                                    const backgroundColor = (index%2===0)?'#00ff301c':'#FBFBFB';
                                    return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                                },
                                thirdSortClick: false
                            }}
                        />
                </section>
                <Footer />
            </>
        );
      }
    }
}

const mapStateToProps = state => {
    return {
        counts: state.trade.counts,
        trade: state.trade,
        user: state.account.user,
        loading: state.loading.requestLoading
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            FetchTrade,
            FetchNewTrade,
            navigate: path => push('/trades' + path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PastTrades);
