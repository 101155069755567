import React, { Component } from 'react';

import {
  FormRow,
  VirtualDropdown,
} from '../../Form';

import { ADDRESSPROOF_TYPES } from '../../../../services/service.values';
import FormInputField from '../../form/FormInputField';
import FormPhoneField from '../../form/FormPhoneField';
import FormFileUpload from '../../form/FormFileUpload'

const addressProofOptions = Object.keys(ADDRESSPROOF_TYPES).map(key => ({
  value: key,
  label: ADDRESSPROOF_TYPES[key]
}));

export class CompanyAdminDetails extends Component {
  state = {
    addressType: ''
  };

  render() {

    let { state, setField, validation, canSubmit, fileUploadValidate} = this.props;
    return (
      <div>
        <FormRow>
          <div className="col-12 col-md-6 mb-3">
            <FormInputField
              validation={validation}
              name="name"
              value={state.companyAdmin.name}
              onChange={e => setField(e.target.name, e.target.value, "companyAdmin")}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <FormInputField
              validation={validation}
              name="title"
              value={state.companyAdmin.title}
              onChange={e => setField(e.target.name, e.target.value, "companyAdmin")}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="col-12 col-md-6 mb-3">
            <FormInputField
              validation={validation}
              name="email"
              value={state.companyAdmin.email}
              onChange={e => setField(e.target.name, e.target.value, "companyAdmin")}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <FormPhoneField
              value={state.companyAdmin.phone}
              name="phone"
              validation={validation}
              onChange={value => setField('phone', value, "companyAdmin")}
              canSubmit={canSubmit}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="col-12 col-md-6 mb-3">
            <FormPhoneField
              value={state.companyAdmin.mobile}
              name="mobile"
              validation={validation}
              onChange={value => setField('mobile', value, "companyAdmin")}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <FormFileUpload 
              value={state.companyAdmin.passport} 
              name="passport"
              validation={validation}
              onChange={file => setField("passport", file, "companyAdmin" )}
              onError={fileUploadValidate}/>
            
          </div>
        </FormRow>
        <FormRow>
          <div className="col-12 col-md-6 mb-3">
            <VirtualDropdown
              validation={validation}
              name="addressProofType"
              label="Select Proof of Address"
              items={addressProofOptions}
              onChange={val => {
                setField('addressProofType', val.value, "companyAdmin");
              }}
              value={{ value: state.companyAdmin.addressProofType.value, label: ADDRESSPROOF_TYPES[state.companyAdmin.addressProofType.value] }}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <FormFileUpload 
              value={state.companyAdmin.addressProof} 
              name="addressProof"
              validation={validation}
              onChange={file => setField("addressProof", file, "companyAdmin")}
              onError={fileUploadValidate}/>
          </div>
        </FormRow>
        <FormRow>
        <div className="col-lg-12 col-md-6 mb-3">
          <FormFileUpload 
            value={state.companyAdmin.boardResolution} 
            name="boardResolution"
            validation={validation}
            onChange={file => setField("boardResolution", file, "companyAdmin")}
            fileUploadValidate={fileUploadValidate} />
        </div>
        </FormRow>
        <div className="form-text">
          <div>Official document proving that the Company Admin can act on behalf of the Company duly signed by an authorized Company Officer.</div>
          <ul className="nav">
            <li> a) The Name/Passport number of the individual(s)
                   with the authority to set up and administer the company's Ricex account.</li>
            <li> b) The Name/Passport number of the individual(s) with the authority to sign
              agreements/enter
                   into contracts on behalf of the company.</li>
          </ul>
        </div>



      </div>
    );
  }
}
