export const getMin = (quantity, tolerance) => {
    return parseFloat(quantity) * (1 - (parseFloat(tolerance) / 100))
}

export const getMax = (quantity, tolerance) => {
    return parseFloat(quantity) * (1 + (parseFloat(tolerance) / 100))
}

export const getShipmentList = (parentId, tradeMap, nominationMap, shipmentMap) => {
    const res = [];
    if (parentId && tradeMap[parentId]) {
        const parent = tradeMap[parentId];
        if (parent.childTradeRequestID && parent.childTradeRequestID.length !== 0) {
            // Case trade has children
            if (parentShipment && parentShipment.list && parentShipment.list.length !== 0 && parentShipment.list[0].Bill.quantCleanOnBoard) {
                res.push(new Shipment(parentShipment.list[0].Bill))
            } else if (parentVesselNomination) {
                res.push(new Shipment(parentVesselNomination))
            }
            
        } else {
        }
        
    } else {
        throw new Error('Error unable to find parent in the map')
    }
}


export const getSum = (shipmentList) => {
    const res = {
        shipments: [],
        total: 0,
        totalMin: 0,
        totalMax: 0
    }
    for (let shipment of shipmentList) {
        const quantity = shipment.quantity;
        const min = getMin(quantity, shipment.tolerance);
        const max = getMax(quantity, shipment.tolerance);

        if (shipment.status !== 'REJECTED') {
            res.totalMin += min;
            res.totalMax += max;
            res.total += quantity
        }

        const row = {
            ...shipment,
            min,
            max
        }
        res.shipments.push(row)
    }
    return res;

}

export const getBalance = (trade, shipmentList) => {
    const { measure, tolerance } = trade;
    const sum = getSum(shipmentList)
    const balance = {
        min: getMin(measure, tolerance),
        max: getMax(measure, tolerance)
    }
    if (sum) {
        balance.min -= sum.totalMin;
        balance.max -= sum.totalMax;
    }

    // Balance cannot be negative
    if (balance.max < 0) {
        balance.max = 0
    }

    if (balance.min < 0) {
        balance.min = 0
    }
    return balance
}

/**
 * @param  {} trade
 * @param  {} tradeMap
 * @param  {} vesselNominationMap
 * @param  {} shipmentMap
 */
export const getSynthesis = (trade, tradeMap, vesselNominationMap, shipmentMap) => {
    const synthesis = {
        tradeId: "",
        contractualQuantity: 0,
        contractualMin: 0,
        contractualMax: 0,
        numberOfShipments: 0,
        partial: {},
        totalShipped: undefined,
        totalNominated: undefined,
        balance: {
            toShip: {
                min: undefined,
                max: undefined
            },
            toNominate: {
                min: undefined,
                max: undefined
            }
        }
    }
    if(!trade) {
        throw new Error("Trade is not defined")
    }
    if (trade.parentTradeRequestID) {
        throw new Error("Trade is not a parent trade")
    } else {
        synthesis.tradeId = trade.id;
        synthesis.partialShipmentAllowed = trade.partialShipmentFlag
        synthesis.contractualQuantity = trade.measure
        synthesis.contractualMax = getMax(trade.measure, trade.tolerance)
        synthesis.contractualMin = getMin(trade.measure, trade.tolerance)
        synthesis.measurement = trade.measurement
    }
    
    if (trade.childTradeRequestID) {
        synthesis.numberOfShipments = trade.childTradeRequestID.length;
        if (trade.childTradeRequestID.length === 0) {
            // Case no child
            synthesis.numberOfShipments = 1;
            // TODO: implement synthesis for parent trade without child
            const tradeSynthesis = {
                tradeId: trade.id,
                vesselNomination: undefined,
                shippingAdvice: undefined,
                nominatedQuantity: undefined,
                shippedQuantity: undefined
            }
            
            // Shipping
            const shippingAdvice = shipmentMap[trade.id];
            synthesis.shippingAdvice = shippingAdvice;
            if (shippingAdvice && shippingAdvice.list && Array.isArray(shippingAdvice.list) && shippingAdvice.list[0] && shippingAdvice.list[0].Bill.quantCleanOnBoard) {
                synthesis.totalShipped = shippingAdvice.list.reduce((acc, shipment) => {
                    return acc + shipment.Bill.quantCleanOnBoard
                }, 0)
            }
            
            
            // Nomination
            const vesselNomination = vesselNominationMap[trade.id];
            synthesis.vesselNomination = vesselNomination;
            if (! shippingAdvice && vesselNomination) {
                synthesis.totalNominated = vesselNomination.nominateQuantity
            }    
            
        } else {
            // Case has children
            for (let childId of trade.childTradeRequestID) {
                const childTrade = tradeMap[childId]
                if (!childTrade) {
                    throw new Error(`Child trade ${childId} is not present in the tradeMap`)
                }
                const childSynthesis = {
                    tradeId: childId,
                    vesselNomination: undefined,
                    shippingAdvice: undefined,
                    nominatedQuantity: undefined,
                    shippedQuantity: undefined     
                }
                
                // Shipments
                // Test on status
                if (/* */ true) {
                    const shippingAdvice = shipmentMap[childId];
                    // Test if the bill of lading is present and filled
                    childSynthesis.shippingAdvice = shippingAdvice;
                    if (shippingAdvice && shippingAdvice.list && shippingAdvice.list.length !== 0 && shippingAdvice.list[0].Bill.ID) {
                        childSynthesis.shippedQuantity = shippingAdvice.list.reduce((acc, shipment) => {
                            return acc + shipment.Bill.quantCleanOnBoard
                        }, 0)
                    }

                    /*  
                        if (!shippingAdvice) {
                            throw new Error("Shipping advice not present in the shipmentMap")
                        } 
                    */
                    
                }
                
                // Nominations
                // Test on status
                if (/* */ true) {
                    const vesselNomination = vesselNominationMap[childId];
                    childSynthesis.vesselNomination = vesselNomination;
                    if (vesselNomination) {
                        childSynthesis.nominatedQuantity = vesselNomination.nominateQuantity
                    }         
                    /*  
                        if (!vesselNomination) {
                            throw new Error("Vessel nomination not present in the vesselNominationMap")
                        } 
                    */
                }
                synthesis.partial[childId] = childSynthesis;
            }
            
            // Calculate totals for trade with children
            synthesis.totalNominated = 0;
            synthesis.totalShipped = 0;
            for (let childId in synthesis.partial) {
                const childSynthesis = synthesis.partial[childId];
                if (childSynthesis.shippedQuantity) {
                    synthesis.totalShipped += childSynthesis.shippedQuantity
                } else if (childSynthesis.nominatedQuantity) {
                    synthesis.totalNominated += childSynthesis.nominatedQuantity
                }

            }                 
        }
        // Calculate balance
        const minStillToShip = (synthesis.contractualMin <= synthesis.totalShipped) ? 0 : synthesis.contractualMin - synthesis.totalShipped;
        const maxStillToShip = (synthesis.contractualMax <= synthesis.totalShipped) ? 0 : synthesis.contractualMax - synthesis.totalShipped;
        
        const sum = (synthesis.totalShipped + synthesis.totalNominated)
            const minStillToNominate = (synthesis.contractualMin <= sum) ? 0 : synthesis.contractualMin - sum;
        const maxStillToNominate = (synthesis.contractualMax <= sum) ? 0 : synthesis.contractualMax - sum;
        synthesis.balance = {
            toShip: {
                min: minStillToShip,
                max: maxStillToShip
            },
            toNominate: {
                min: minStillToNominate,
                max: maxStillToNominate
            }
        }
    } else {
        throw new Error("Unable to get shipment details from a trade without property 'childTradeRequestID'")
    }
    return synthesis
}