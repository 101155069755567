import React from 'react';
import ConfirmModal from './ConfirmModal';
import Contract from '../../../trades/components/Contract';
import Preloader from '../../../../components/preloader/Preloader';

/**
 * Display confirm modal 
 *
 * @since  x.x.x
 * @access public
 *
 * @type     {React.Component}
 * @property {content}
 * @property {title}
 * @property {onCancel}
 * @property {onConfirm}
 * @property {show}
 * @property {onCancel}
 */
class ConfirmModalViewContract extends React.Component {
    render() {
        const { 
            loading = false,
            buttonClass,
            trade,
            user,
            bankDetailsRejected,
            loadingBidInfo = false,
            bidInfoHandler = () => {}
        } = this.props;

        return <ConfirmModal loading={loading}
            title={`View Contract`}
            buttonClass = {buttonClass}
            buttonLabel = "View Contract" 
            onCancel={this.onCancel}
            modalBodyClass="contract-modal"
            buttonConfirmLabel=""
            buttonConfirmClass=""
            showButtonConfirm={false}
            buttonHandler={bidInfoHandler}>   
                <Preloader loading={loadingBidInfo} loadingStyle="swirl">
                    <div className="contract-modal">
                        <Contract trade={trade}  user={user} bankRejected={bankDetailsRejected}/>       
                    </div>
                </Preloader>    
        </ConfirmModal>
    }
}

export default ConfirmModalViewContract;


