import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const InspectionNotAvailableModal = ({ onClick }) => {
    return (
        <React.Fragment>
            <div className='modal__container'>
                <div className="modal__wrapper px-0 py-0 authcode">
                    <div className="modal-content">
                        <div className="modal__body text-left" style={{padding: '20px'}}>
                            The inspection company is not on the list of approved service providers. Please contact your company administrator to add service providers.
                        </div>
                        <div className="modal-footer pt-0">
                            <button type="button" className="btn-popup btn-submit" onClick={onClick}>
                                <FontAwesomeIcon icon={faCheck} /> OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default InspectionNotAvailableModal;