import React, { Component } from 'react';
import { EnumsService } from '../../../../services/service.utils';

import {
    FormRow,
    VirtualDropdown,
} from '../../Form';

import { COMPANY_TYPES } from '../../../../services/service.values';
import FormInputField from '../../form/FormInputField';
import FormPhoneField from '../../form/FormPhoneField';
import FormFileUpload from '../../form/FormFileUpload';

const Countries = EnumsService.countries();
const countriesOptions = Object.keys(Countries).map((key) => ({
    value: key,
    label: Countries[key]
}));
const companyTypeOptions = Object.keys(COMPANY_TYPES).map(key => ({
    value: key,
    label: COMPANY_TYPES[key]
}));

export class CompanyDetails extends Component {
    state = {
        companyType: ''
    };

    render() {

        let { state, setField, validation, setMailing, fileUploadValidate} = this.props;
        return (
            <div className="">
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            disabled={true}
                            validation={validation}
                            name="name"
                            value={state.name}
                            onChange={e => setField(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation}
                            name="regNumber"
                            disabled={true}
                            value={state.regNumber}
                            onChange={e => setField(e.target.name, e.target.value)}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <VirtualDropdown
                            validation={validation}
                            name="companyType"
                            label="Company Type"
                            items={companyTypeOptions}
                            isDisabled={true}
                            onChange={val => {
                                setField('companyType', val.value);
                            }}
                            value={{ value: state.companyType.value, label: COMPANY_TYPES[state.companyType.value] }}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation}
                            name="tax"
                            required={true}
                            value={state.tax}
                            onChange={e => setField(e.target.name, e.target.value)}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation.registeredAddress}
                            name="line1"
                            value={state.registeredAddress.line1}
                            onChange={e => setField(e.target.name, e.target.value, "registeredAddress")}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation}
                            name="line2"
                            value={state.registeredAddress.line2}
                            onChange={e => setField(e.target.name, e.target.value, "registeredAddress")}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation.registeredAddress}
                            name="city"
                            value={state.registeredAddress.city}
                            onChange={e => setField(e.target.name, e.target.value, "registeredAddress")}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation.registeredAddress}
                            name="postal"
                            value={state.registeredAddress.postal}
                            onChange={e => setField(e.target.name, e.target.value, "registeredAddress")}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <VirtualDropdown
                            validation={validation.registeredAddress}
                            name="country"
                            label="Registered Address Country"
                            items={countriesOptions}
                            onChange={
                                val => {
                                    setField('country', val.value, "registeredAddress");
                                }
                            }
                            value={{ value: state.registeredAddress.country.value, label: Countries[state.registeredAddress.country.value] }}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <FormFileUpload
                            value={state.addressProof} 
                            name="addressProof"
                            validation={validation}
                            onChange={file => setField("addressProof", file)}
                            onError={fileUploadValidate}
                        />
                    </div>
                </FormRow>
               
            
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox"
                                className="custom-control-input"
                                checked={state.isMailingAddressDifferent}
                                id="isMailingAddressDifferent"
                                onChange={val => {
                                    setMailing(val.target.checked);
                                }} />
                            <label className="custom-control-label" htmlFor="isMailingAddressDifferent">Is Mailing Address Different from Registered Address</label>
                        </div>
                    </div>
                </FormRow>
                {!state.isMailingAddressDifferent ? '' : (
                    <React.Fragment>
                        <FormRow>
                            <div className="col-12 col-md-6 mb-3">
                                <FormInputField
                                    validation={validation.mailingAddress}
                                    name="line1"
                                    value={state.mailingAddress.line1}
                                    onChange={e => setField(e.target.name, e.target.value, "mailingAddress")}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <FormInputField
                                    validation={validation}
                                    name="line2"
                                    value={state.mailingAddress.line2}
                                    onChange={e => setField(e.target.name, e.target.value, "mailingAddress")}
                                />
                            </div>
                        </FormRow>
                        <FormRow>
                            <div className="col-12 col-md-6 mb-3">
                                <FormInputField
                                    validation={validation.mailingAddress}
                                    name="city"
                                    value={state.mailingAddress.city}
                                    onChange={e => setField(e.target.name, e.target.value, "mailingAddress")}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <FormInputField
                                    validation={validation.mailingAddress}
                                    name="postal"
                                    value={state.mailingAddress.postal}
                                    onChange={e => setField(e.target.name, e.target.value, "mailingAddress")}
                                />
                            </div>
                        </FormRow>
                        <FormRow>
                            <div className="col-12 col-md-6 mb-3">
                                <VirtualDropdown
                                    validation={validation.mailingAddress}
                                    name="country"
                                    label="Mailing Address Country"
                                    items={countriesOptions}
                                    onChange={
                                        val => {
                                            setField('country', val.value, "mailingAddress");
                                        }
                                    }
                                    value={{ value: state.mailingAddress.country.value, label: Countries[state.mailingAddress.country.value] }}
                                />
                            </div>
                        </FormRow>
                    </React.Fragment>
                )}
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation}
                            name="email"
                            value={state.email}
                            onChange={e => setField(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <FormPhoneField
                            value={state.phone}
                            name="phone"
                            validation={validation}
                            onChange={value => setField('phone', value)}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation}
                            name="site"
                            maxLength="50"
                            value={state.site}
                            onChange={e => setField(e.target.name, e.target.value)}
                        />
                    </div>
                </FormRow>
            </div>
        );
    }
}
