import React, { Component } from 'react';
import KycCompanyWizard from './KycWizard';

class KycHome extends Component {
  render() {
    return (
      <React.Fragment>
        <KycCompanyWizard />
      </React.Fragment>
    );
  }

}

export default KycHome;
