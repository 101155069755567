import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Preloader from '../../components/preloader/Preloader';

const TenderConfirmModal = ({onCancel, onConfirm, loading, children}) => {
    return(
        <div className="modal__container">
            <div className="modal__wrapper px-0 py-0">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" onClick={onCancel} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn-popup btn-cancel" onClick={onCancel}>
                            <FontAwesomeIcon icon={faTimes} /> Back
                        </button>  
                        <button type="button" className="btn-popup btn-submit" onClick={onConfirm} disabled={loading}>
                            <Preloader loadingStyle="dots" loading={loading}>
                                <FontAwesomeIcon icon={faCheck} /> Confirm
                            </Preloader>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TenderConfirmModal;