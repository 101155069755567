import React, { Component } from 'react';
import { EnumsService } from '../../../../services/service.utils';

import {
    FormRow,
    VirtualDropdown
} from '../../Form';

import { RELATIONSHIP_TYPES } from '../../../../services/service.values';
import FormInputField from '../../form/FormInputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

const Countries = EnumsService.countries();
const countriesOptions = Object.keys(Countries).map((key) => ({
    value: key,
    label: Countries[key]
}));

const relationshipOptions = Object.keys(RELATIONSHIP_TYPES).map(key => ({
    value: key,
    label: RELATIONSHIP_TYPES[key]
}));

export class AssociatedCompanies extends Component {

    render() {

        let { state, setField,  add, remove } = this.props;
        return (
            <React.Fragment>
                {state.associatedCompanies &&
                    state.associatedCompanies.map((k, i) => {
                        return (
                            <React.Fragment key={i}>
                                <FormRow>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            // validation={validation}
                                            name="name"
                                            value={k.name}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "associatedCompanies", i)
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <VirtualDropdown
                                            // validation={validation}
                                            name="country"
                                            label="Registered Country"
                                            items={countriesOptions}
                                            required={false}
                                            onChange={
                                                val => {
                                                    setField('country', val.value, "associatedCompanies", i);
                                                }
                                            }
                                            value={{ value: k.country.value, label: Countries[k.country.value] }}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow key={i}>
                                    <div className="col-12 col-md-6 mb-3">
                                        <VirtualDropdown
                                            // validation={validation}
                                            name="relationship"
                                            label="Company Relationship"
                                            items={relationshipOptions}
                                            required={false}
                                            onChange={
                                                val => {
                                                    setField('relationship', val.value, "associatedCompanies", i);
                                                }
                                            }
                                            value={{ value: k.relationship.value, label: RELATIONSHIP_TYPES[k.relationship.value] }}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        {k.relationship.value === "OTHER" ? (
                                            <FormInputField
                                                // validation={validation}
                                                name="otherRelationship"
                                                value={k.otherRelationship}
                                                onChange={e => {
                                                    setField(e.target.name, e.target.value, "associatedCompanies", i)
                                                }
                                                }
                                            />
                                        ) : ''}
                                    </div>
                                </FormRow>

                                <div className="text-right">
                                    {i !== 0 ? (
                                        <button type="button" className="btn btn-section-removed" onClick={e => remove(i)}>
                                            <FontAwesomeIcon icon={faTimes} /> Remove
                                        </button>
                                    ) : ''}
                                    {i === state.associatedCompanies.length - 1 ? (
                                        <button type="button" className="btn btn-add" onClick={e => add()}>
                                            <FontAwesomeIcon icon={faPlus} /> Add
                                        </button>
                                    ) : ''}

                                </div>
                            </React.Fragment>
                        );
                    })}
            </React.Fragment>
        );
    }
}
