import PropTypes from "prop-types";
import React, {
    useState,
    //useEffect
} from 'react';

/* ICONS */
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DocumentFactory } from '../../../trades/services/documents.service';
import DocumentSelection from './DocumentSelection';


/**
 * Step 4 of the form : create trade request 
 *
 * @since  x.x.x
 * @access public
 *
 * @type     {React.Component}
 * @property {Boolean} active display or hide the modal.
 * @property {Function} onNext takes a list of optional documents as argument.
 *
 */


const DocumentSelectionModal = ({
    onChange,
    onNext,
    onClose,
    show,
    trade,
    isnonIntegratedInspector }) => {

    const erroMessage = 'Before clicking on "NEXT", please confirm insertion of your document or click on cancel';
    const [isValid, setIsValid] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const handleNext = () => {
        if (isTouched) {
            return false;
        } else {
            onNext()
        }
    }



    return <React.Fragment>
        {show && <div className="modal__container">

            <div className="modal__wrapper px-0 py-0 edit-field">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-header-center"><h5>Document Selection/Uploading Party</h5>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body py-0">
                        <p className="text-left">Select optional documents and define the uploading party. Please consider regulatory requirement for your origin and destination. Documents with a grey tick box are mandatory and cannot be removed. If a document is not on the list then please add it in the free input field.</p>
                        <DocumentSelection
                            value={DocumentFactory.getFEDocumentList(trade)}
                            onChange={documentList => {
                                onChange(DocumentFactory.getBEOptionalDocumentList(documentList))
                            }}
                            onValidationChange={setIsValid}
                            isnonIntegratedInspector={isnonIntegratedInspector}
                            onTouchChange={setIsTouched}
                            errorMessage={erroMessage}
                        />
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-popup btn-submit" type='button' disabled={isTouched && isValid} onClick={handleNext} >
                            Next <FontAwesomeIcon icon={faChevronRight} />
                        </button>

                    </div>
                </div>
            </div>
        </div>}
    </React.Fragment>

}

DocumentSelectionModal.propTypes = {
    onClose: PropTypes.any,
    onNext: PropTypes.func,
    optionalDocuments: PropTypes.shape({
        forEach: PropTypes.func,
        length: PropTypes.number
    }),
    show: PropTypes.any,
    showMandatoryCIF: PropTypes.any,
    showRiceCategory: PropTypes.any
}

export default DocumentSelectionModal



