import React, { Component } from 'react';
import './footer.scss';

class Footer extends Component {
    year = new Date().getFullYear();

    render() {
        return (
            <React.Fragment>
                <div className="footer">
                <div> Copyright &copy; RiceExchange {this.year}</div>
                <div className="footer-link"><span><a href="https://fujitsubic.atlassian.net/servicedesk/customer/portal/3/topic/8734df17-d747-4b53-a68e-69c6d94422d0" target="_blank" rel="noopener noreferrer" >FAQ</a></span> | <span><a href="https://fujitsubic.atlassian.net/servicedesk/customer/portal/3/topic/c8261845-dde2-453a-83bd-d312eb3085c2" target="_blank" rel="noopener noreferrer">How To</a></span> </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Footer;

