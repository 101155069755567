import React, { Component } from 'react';
import AdminNav from './AdminNav';

class PlatformAdminDashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <AdminNav />
      </React.Fragment>
    )
  }
}

export default PlatformAdminDashboard;
