import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function SubmitConfirmModal(props) {

return (<React.Fragment>
    <div className="modal__container">
        <form
            className="modal__wrapper px-0 py-0 authcode"
            onSubmit={e => {
                e.preventDefault();
                props.onClose();
            }}>
            <div className="modal-content">
                <div className="modal-header">
                    <button className="close" data-dismiss="modal" onClick={props.onClose} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p className="center-text"><h6>{props.title}</h6></p>
                </div>
                <div className="modal-footer">
                    <button className="btn-popup btn-cancel ">
                        <FontAwesomeIcon icon={faTimes} /> Close</button>
                </div>
            </div>
        </form>
    </div>
    </React.Fragment>
   );
 }
    


export default SubmitConfirmModal;
