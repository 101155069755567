import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import jwtDecode from "jwt-decode";
import moment from "moment";
import { logout } from '../../modules/module.account';
import { AccountApi } from '../../services/service.api'
import IdleTimer from 'react-idle-timer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faRedo } from '@fortawesome/free-solid-svg-icons';
import Preloader from '../components/preloader/Preloader';

class TimeoutInfo extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            connecting: false,
            error: null
        }
        this.idleTimerRef = null;
    }

    calculateRemainingTime = () => {
        const token = jwtDecode(sessionStorage.getItem("jwt"));
        return token.exp - moment().unix();
    }

    onIdle = () => {
        const diff = this.calculateRemainingTime();
        this.idleTimerRef.reset();
        if(diff <= 0){
            this.logoutHandler();
        }else if(diff < 300 && diff > 0){
            this.setState({
                showModal: true
            })
        }else{
            this.setState({showModal: false})
        }    
    }

    logoutHandler = () => {
        this.setState({showModal: false});
        this.props.logout();
        this.props.navigate();
    }

    stayConnectedHandler = () => {
        this.setState({connecting: true})
        AccountApi.updateJWTToken()
        .then(t => {
            this.setState({
                connecting: false,
                showModal: false
            })
            AccountApi.setToken(t.data.token);
        })
        .catch(e => {
            this.setState({
                connecting: false,
                error: "Error occured while updating the token"
            })
        });
    }

    render(){
        return(
            <>
                <IdleTimer
                    ref={ref => { this.idleTimerRef = ref }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={1000 * 5 * 1}
                    startOnMount={true}
                />
                {this.state.showModal && (
                    <div className='modal__container'>
                        <div className="modal__wrapper px-0 py-0 authcode">
                            <div className="modal-header">
                                <h4>Your session is due to expire in 5 minutes</h4>
                            </div>
                            <div className="modal-content">
                                <div className="modal-body pb-3">
                                    <div>
                                        Your session is about to expire. Please click on Stay Connected to continue using the platform or click on Logout to end the session.
                                    </div>
                                    {this.state.error && <div className="counterparty__error text-left pl-3">{this.state.error}</div>}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn-popup btn-cancel" onClick={this.logoutHandler}>
                                        <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                                    </button>
                                    <button type="button" className="btn-popup btn-submit" onClick={this.stayConnectedHandler}>
                                        <Preloader loadingStyle="dots" loading={this.state.connecting}>
                                            <FontAwesomeIcon icon={faRedo} /> Stay Connected
                                        </Preloader>     
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        logout,
        navigate: () => push('/')
    }, dispatch);

export default connect(null, mapDispatchToProps)(TimeoutInfo);