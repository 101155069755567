import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Disclaimer from './Disclaimer';
import ScheduleTable from './ScheduleTable';
import { BRAND_SCAC } from '../../../../services/service.values';

//ACTIONS
import { maerskBookingReq, resetForm} from '../../../../modules/module.shipmentQuote';
import Preloader from '../../../components/preloader/Preloader';

import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RequestBookingModal(props){

    const { shipmentQuote = [], allowBooking=false, trade={}, maerskBookingReq, account} = props;
    const [route, setRoute] = useState( '' );
    const [consent, setConsent] = useState( false );
    
    function handleOnClose() {
        if(props.onClose){
            props.onClose();

        }
    }

    function handleOnCloseAll() {
        if(props.onCloseAll){
            props.onCloseAll();
        }
    }

    function handleOnSelect(e, rowData){
        setRoute(rowData);
    }

    function handleOnBook(e) {
        maerskBookingReq(trade.items.single.id, shipmentQuote.priceQuote.priceQuoteId, route.price.prices_per_container[0].priceid,shipmentQuote.priceQuote.companyName,shipmentQuote.priceQuote.companyId,route.price.total,account.user.companyId);
        // shipmentCreated();  
    }

    useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            resetForm();
        }
    }, [])

    return <div className="modal__container">
        <div className="modal__wrapper px-0 py-0" style={{maxWidth: "80%"}}>
            <div className="modal-content"> 
                <div className="modal-header">
                    <div className="text-center">
                        { route ?
                        <h5 className="modal-title">Confirm your booking</h5>
                        :
                        <h5 className="modal-title">Available routes <small>with {BRAND_SCAC[shipmentQuote.priceQuote.brandScac]}</small></h5>}
                    </div>
                    <button type="button" className="close" data-dismiss="modal" onClick={e => handleOnClose()} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    { route ?
                    <>
                    { shipmentQuote.bookingResponse && 
                    <p className="my-3">
                        Your booking request has been sent to Maersk please wait while Maersk review and respond via Acknowledgement file followed by booking confirmation file. 
                        To check the status of booking request please go to shipping quotes overview screen - Final offer section
                    </p>}
                    <Disclaimer route={route} onCheck={e => setConsent(!consent)}/>
                    </>
                    :
                    <ScheduleTable quote={shipmentQuote.priceQuote} 
                        allowBooking={allowBooking} 
                        onSelectRoute={ handleOnSelect }/>}
                </div>
                <div className="modal-footer">
                    { route ?
                    <>
                        {!shipmentQuote.bookingResponse?
                        <React.Fragment>
                            <button type="button" onClick={e => handleOnClose()} className="btn-popup btn-cancel "><FontAwesomeIcon icon={faTimes} />&nbsp;Back</button>
                            <button className="btn-popup btn-submit" type="button" disabled={props.shipmentQuote.bookingPending} onClick={ handleOnBook}>
                                <Preloader loadingStyle="dots" loading={props.shipmentQuote.bookingPending}>
                                    <FontAwesomeIcon icon={faCheck} />&nbsp;Submit Booking
                                </Preloader>  
                            </button>
                        </React.Fragment>
                        :
                        <button type="button" onClick={e => handleOnCloseAll()} className="btn-popup btn-submit ">Close</button>}
                    </>
                    :<button onClick={e => handleOnClose()} className="btn-popup btn-cancel ">
                        <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                    </button>}
                </div>
            </div>
        </div>              
    </div>
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        shipmentQuote: state.shipmentQuote,
        account: state.account
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            maerskBookingReq,
            resetForm,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RequestBookingModal);