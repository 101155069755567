import React from 'react';

import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from '../../../../app/components/preloader/Preloader';

const AlertModal = function (props) {

    let { isCancelButtonEnabled = true, isSubmitButtonEnabled = true,
        cancelButtonContent = 'Reset', submitButtonContent = 'Submit',
        cancelFontIcon, submitFontIcon, modalBody,
        modalContent, onCancel, onSubmit, styles = '',submitButtonLoader=false,modalContentLoader=false } = props;
    return (
        <div className="modal__container">
            <div className={` modal__wrapper px-0 py-0 ${styles}`}>
            <Preloader loadingStyle="overflow-spinner" loading={modalContentLoader}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-header-center">
                            <p className="modal-title">
                                {modalContent}
                            </p>
                        </div>
                    </div>
                    {modalBody ?
                        <div className="modal-body">
                            {modalBody}
                        </div> : null
                    }
                    <div className="modal-footer pt-0">
                        {isCancelButtonEnabled ?
                            <button className={`btn-popup btn-cancel `} type="button" onClick={onCancel} >
                                {cancelFontIcon ? <FontAwesomeIcon icon={faTimes} /> : null}&nbsp;{cancelButtonContent}
                            </button> : null
                        }
                        {isSubmitButtonEnabled ?
                            <button className={`btn-popup btn-submit`} type="button" onClick={onSubmit} >
                                <Preloader loadingStyle="dots" loading={submitButtonLoader}>
                                {submitFontIcon ? <FontAwesomeIcon icon={faCheck} /> : null}&nbsp;{submitButtonContent}
                                </Preloader>
                            </button> : null}

                    </div>
                </div>
            </Preloader>
            </div>
        </div>
    )
}

export default AlertModal;