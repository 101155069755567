import React, { Component } from 'react';
// import Preloader from '../../../../components/preloader/Preloader';
import BagmarkingsItem from './BagmarkingsItem';
import UploadBagmarking from './UploadBagmarking';
import { uploadBagmarkingPermission } from '../../../../../services/service.permission';

class Bagmarkings extends Component {
    state = {
        text: '',
        receiver: null,
        replyTo: null,
        replyMessage: '',
        acceptProgress: false,
        rejectProgress: false,
        bagmarkingID: ""
    };

    postBagmarking = (e, formData, cb) => {
        e.preventDefault();
        this.props.postBagmarking(this.props.match.params.id, formData, cb);
    };

    approveBagmarking = (e, brandID, bagmarkingID, cb) => {
        e.preventDefault();
        this.setState({ acceptProgress: true, bagmarkingID: bagmarkingID });
        this.props.approveBagmarking(this.props.match.params.id, brandID, bagmarkingID, () => {
            this.setState({ acceptProgress: false, bagmarkingID: "" });
        });
    };

    rejectBagmarking = (e, brandID, bagmarkingID, cb) => {
        e.preventDefault();
        this.setState({ rejectProgress: true, bagmarkingID: bagmarkingID });
        this.props.rejectBagmarking(this.props.match.params.id, brandID, bagmarkingID, () => {
            this.setState({ rejectProgress: false, bagmarkingID: "" });
        });
    };

    canEdit = (companyID) => {
        let canEdit = false;
        if (companyID === this.props.trade.buyerId) {
            if (this.props.user.companyId === this.props.trade.sellerId) {
                canEdit = true;
            }
        } else if (companyID === this.props.trade.sellerId) {
            if (this.props.user.companyId === this.props.trade.buyerId) {
                canEdit = true;
            }
        }
        return canEdit;
    }

    render() {
        return (
            <div className="trades-dtls__contract">
                {(this.props.canUploadBagmarking && uploadBagmarkingPermission()) ? (
                    <UploadBagmarking
                        postBagmarking={(e, formData, cb) => this.postBagmarking(e, formData, cb)}
                    />) : null
                }

                <div className="table-responsive mt-3">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Bagmarking Name</th>
                                <th>Uploaded By</th>
                                <th>Uploaded At</th>
                                <th>Status</th>
                                <th>Approved/Rejected By</th>
                                <th>Approved/Rejected On</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {this.props.bagmarkings.list && this.props.bagmarkings.list.length > 0 ? (
                            <tbody>
                                {this.props.bagmarkings.list.map((bagmarking, i) => {
                                    return (
                                        <BagmarkingsItem
                                            key={bagmarking.ID}
                                            bagmarking={bagmarking}
                                            brandID={this.props.bagmarkings.brandID}
                                            acceptProgress={this.state.acceptProgress}
                                            rejectProgress={this.state.rejectProgress}
                                            bagmarkingID={this.state.bagmarkingID}
                                            canEdit={(companyID) => this.canEdit(companyID)}
                                            approveBagmarking={(e, brandID, bagmarkingID) => this.approveBagmarking(e, brandID, bagmarkingID)}
                                            rejectBagmarking={(e, brandID, bagmarkingID) => this.rejectBagmarking(e, brandID, bagmarkingID)}
                                        />
                                    )
                                }
                                )}
                            </tbody>
                        ) : (<tbody><tr><td colSpan="7">No Bagmarking uploaded</td></tr></tbody>)
                        }
                    </table>
                </div>
            </div>
        )
    }
}

export default Bagmarkings;
