import React, { useEffect } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Preloader from '../../../components/preloader/Preloader';
import ContractFulfillConfirmation from './shipments/ContractFulfillConfirmation';

const dealBar = (trade, trader, action, loading, isTradeOwner) => {
    let render = () => {
        let signed = (trader === 'seller' && trade.requestInfo.signSeller) || (trader === 'buyer' && trade.requestInfo.signBuyer);
        if (trade.items.single.multipleOriginAccept) {
            let notSignedByBoth = !trade.requestInfo.signSeller && !trade.requestInfo.signBuyer;
            return notSignedByBoth ? (
                <button
                    onClick={action}
                    className={`trades-dtls__button ${!isTradeOwner ? 'trades-dtls__button_disabled' : ''}`}>
                    <Preloader loadingStyle="dots" loading={loading}>
                        <span>{isTradeOwner ? 'Select Origin & Sign Contract' : 'Pending for Signature'}</span>
                    </Preloader>
                </button>
            ) : (
                    <button
                        onClick={action}
                        className={`trades-dtls__button ${isTradeOwner ? 'trades-dtls__button_success' : ''}`}>
                        <Preloader loadingStyle="dots" loading={loading}>
                            <span>{isTradeOwner ? 'Pending Other Signature' : 'Sign Contract'}</span>
                        </Preloader>
                    </button>
                )
        } else {
            return (
                <button
                    onClick={action}
                    className={`trades-dtls__button ${signed ? 'trades-dtls__button_success' : ''}`}>
                    <Preloader loadingStyle="dots" loading={loading}>
                        <span>{signed ? 'Pending Other Signature' : 'Sign Contract'}</span>
                    </Preloader>
                </button>
            );
        }
    };
    return {
        Buyer: render,
        Seller: render
    };
};
const MultipleShipmentsPending = (onClick) => {
    const button = <button onClick={onClick} className={`trades-dtls__button`}>
        <span>Multiple Shipments Pending</span>
    </button>
    return {
        Buyer: () => button,
        Seller: () => button
    }
}


const onNominateVesselClick = (nominate) => {
  //vesselModalStore && vesselModalStore.set('show', true)
  nominate()
}

const NominateButton = ({
  nominate,
  isNominated,
  loading,
  parentTradeRequestID,
  tradeId,
}) => {
  /*
  useEffect(() => {
    // abort if triggering again on the same document
    if (window.lastNominated === tradeId) return null

    // abort if there was no recent click on the Nominate next vessel button
    if (!window.createChildTrade) return null

    // debounce fast component reloads
    clearTimeout(window.nominateTimer)

    // delay nominate modal for 1 second
    window.nominateTimer = setTimeout(() => {
      nominate()

      // save id for aborting duplicate activations
      window.lastNominated = tradeId

      // recent click on Nominate next vessel button no longer applies
      window.createChildTrade = false
    }, 500)

    // kill timeout if component unloads
    return () => {
      clearTimeout(window.nominateTimer)
    }
  })*/

  return (
    <button
      onClick={() => onNominateVesselClick(nominate)}
      className={`trades-dtls__button ${isNominated &&
        'trades-dtls__button_success'}`}>
      <Preloader loadingStyle="dots" loading={loading}>
        <span>
          {parentTradeRequestID
            ? 'Multiple Shipment/Nominate Vessel'
            : 'Nominate Vessel'}
        </span>
      </Preloader>
    </button>
  )
}

const vesselBar = (trade, info, nominate, acceptingNomination, onApproveByBuyer, loading) => {
    const isNominated = info && info.vesselNominated;
    const isLaycanDateChanged = info && info.isLaycanDateChanged;
    const { parentTradeRequestID } = trade
    const tradeId = trade.id

    const nominateAcceptingButton = (
        <button onClick={acceptingNomination} className={`trades-dtls__button`}>
            <Preloader loadingStyle="dots" loading={loading}>
                <span>
                   {parentTradeRequestID?`Multiple Shipment/Approve ${trade.shipping === "VESSEL" ? "Vessel" : "Container"} Nomination`:`Approve ${trade.shipping === "VESSEL" ? "Vessel" : "Container"}  Nomination`}
                </span>
            </Preloader>
        </button>
    );

    const approveByBuyer = (
        <button onClick={onApproveByBuyer} className={`trades-dtls__button`}>
            <Preloader loadingStyle="dots" loading={loading}>
                <span>Manage Vessel Nomination </span>
            </Preloader>
        </button>
    );

    const waitNominationButton = (
        <button className="trades-dtls__button trades-dtls__button_info">{parentTradeRequestID?"Multiple Shipment/Wait Vessel Nomination":"Wait Vessel Nomination"}</button>);

    const waitApprovalButton = (
        <button className="trades-dtls__button trades-dtls__button_info">{parentTradeRequestID?"Multiple Shipment/Wait Vessel Nomination Approval":"Wait Vessel Nomination Approval"}</button>);

    return {
        Buyer: () => {
            // return trade.incoterm === 'FOB'
            //     ? isNominated
            //         ? waitApprovalButton
            //         : nominateButton
            //     : waitNominationButton;
            if (trade.incoterm === 'FOB') {
                if (isNominated && !isLaycanDateChanged) {
                    return waitApprovalButton;
                } else if (isNominated && isLaycanDateChanged) {
                    return approveByBuyer;
                } else {
                    return <NominateButton {...{ nominate, isNominated, loading, parentTradeRequestID, tradeId }} />
                }
            } else {
                return waitNominationButton
            }
        },
        Seller: () => {
            // return trade.incoterm === 'CIF' || trade.incoterm === 'CFR'
            //     ? nominateButton
            //     : isNominated
            //         ? nominateAcceptingButton
            //         : waitNominationButton;
            if (trade.incoterm === 'CIF' || trade.incoterm === 'CFR') {
                return <NominateButton {...{ nominate, isNominated, loading, parentTradeRequestID, tradeId }} />
            } else {
                if (isNominated && !isLaycanDateChanged) {
                    return nominateAcceptingButton;
                } else if (isNominated && isLaycanDateChanged) {
                    return waitApprovalButton;
                } else {
                    return waitNominationButton;
                }
            }
        }
    };
};

const proformaInvoiceBar = (openProformaInvoiceModal, navigateToDocuments, trade, loading) => {
    const proformaInvoiceButton = (
        <button onClick={openProformaInvoiceModal} className={`trades-dtls__button`}>
            <Preloader loadingStyle="dots" loading={loading}>
                <span>Fill Out Proforma Invoice</span>
            </Preloader>
        </button>
    );

    const waitProfomraInvoice = (
        <button className="trades-dtls__button trades-dtls__button_info">Wait For Proforma Invoice</button>
    );

    const uploadPaymentReciept = (
        <button onClick={navigateToDocuments} className={`trades-dtls__button`}>
            <span>Confirm Down Payment </span>
        </button>
    );

    const waitPaymentReciept = (
        <button className="trades-dtls__button trades-dtls__button_info">Wait For Down Payment Proof</button>
    );

    const confirmPayment = (
        <button onClick={navigateToDocuments} className={`trades-dtls__button`}>
            <span>View and Confirm Down Payment</span>
        </button>
    );

    const waitPaymentConfirmation = (
        <button className="trades-dtls__button trades-dtls__button_info">Wait for Down Payment Confirmation</button>
    );

    return {
        Buyer: () => {
            if (trade.items.single.status === 'SIGNED') {
                if (!trade.proformaInvoice) {
                    return waitProfomraInvoice;
                } else if (trade.proformaInvoice.status === 'NEW' || trade.proformaInvoice.status === 'EDIT') {
                    return uploadPaymentReciept;
                } else if (trade.proformaInvoice.status === 'UPLOADED' || trade.proformaInvoice.status === 'EFFECTED') {
                    return waitPaymentConfirmation;
                } else if (trade.proformaInvoice.status === 'PAYMENT_NOT_CONFIRMED') {
                    return uploadPaymentReciept;
                }
            }

        },
        Seller: () => {
            if (trade.items.single.status === 'SIGNED') {
                if (!trade.proformaInvoice) {
                    return proformaInvoiceButton;
                } else if (trade.proformaInvoice.status === 'NEW' || trade.proformaInvoice.status === 'EDIT' || trade.proformaInvoice.status === "PAYMENT_NOT_CONFIRMED") {
                    return waitPaymentReciept
                } else if (trade.proformaInvoice.status === 'UPLOADED' || trade.proformaInvoice.status === 'EFFECTED') {
                    return confirmPayment;
                }
            }

        }
    }
}

const docInstBar = (trade, action, loading) => {
    return {
        Buyer: () => {
            return (
                <button onClick={() => action()} className="trades-dtls__button">
                    <Preloader loadingStyle="dots" loading={loading}>
                        <span>{trade.items.single.parentTradeRequestID ? "Multiple Shipment/Send Documentary Instructions" : "Send Documentary Instructions"} </span>
                    </Preloader>
                </button>
            );
        },
        Seller: () => (
            <button className="trades-dtls__button trades-dtls__button_info">{trade.items.single.parentTradeRequestID ?"Multiple Shipment/Wait For Documentary Instructions":"Wait For Documentary Instructions"}</button>
        )
    };
};

const confirmContractFulfilled = (trade, action, loading, incoterm) => {
    return {
        Buyer: () => {
            return (incoterm === 'FOB') ? <button className="trades-dtls__button">Pending Seller</button> :
                <button onClick={() => action()} className="trades-dtls__button trades-dtls__button_info">
                    <Preloader loadingStyle="dots" loading={loading}>
                        <span>Manage Multiple Shipments</span>
                    </Preloader>
                </button>
        },
        Seller: () => {
            return ((incoterm === 'FOB') ?
                <button onClick={() => action()} className="trades-dtls__button trades-dtls__button_info">
                    <Preloader loadingStyle="dots" loading={loading}>
                        <span>Manage Multiple Shipments</span>
                    </Preloader>
                </button> :
                <button className="trades-dtls__button">Pending Buyer</button>
            );
        }
    }

};

const contractFulfilled = () => {
    const contractFulfilled = <button className="trades-dtls__button trades-dtls__button_info">Multiple Shipment Pending Completion</button>
    return {
        Buyer: () => (contractFulfilled),
        Seller: () => (contractFulfilled)
    }
};


const shippingAdvice = (trade, action, loading) => {
    return {
        Buyer: () => <button className="trades-dtls__button trades-dtls__button_info">{trade.items.single.parentTradeRequestID ?"Multiple Shipment/Wait For Shipping Advice":"Wait For Shipping Advice"}</button>,
        Seller: () => {
            return (
                <button onClick={() => action()} className="trades-dtls__button">
                    <Preloader loadingStyle="dots" loading={loading}>
                        <span>{trade.items.single.parentTradeRequestID ? "Multiple Shipment/Send Shipping Advice" : "Send Shipping Advice"}</span>
                    </Preloader>
                </button>
            );
        }
    };
};

const documentsFill = (url,trade) => {
    return {
        Buyer: () => <button className="trades-dtls__button trades-dtls__button_info">{trade.items.single.parentTradeRequestID ?"Multiple Shipment/Documents being prepared":"Documents being prepared"}</button>,
        Seller: () => (
            <Link to={url}>
                <button className="trades-dtls__button trades-dtls__button_info">
                    <FaInfoCircle className="trades-dtls__info-sign" />{trade.items.single.parentTradeRequestID ?"Multiple Shipment/Please fill all documents":"Please fill all documents"}
                </button>
            </Link>
        )
    };
};

const documentsFull = (url, loading) => {
    return {
        Buyer: () => { return <button className="trades-dtls__button trades-dtls__button_info">Documents filled in</button> },
        Seller: () => (
            <button className="trades-dtls__button trades-dtls__button_info">
                <Preloader loadingStyle="dots" loading={loading}>
                    <React.Fragment>
                        <FaInfoCircle className="trades-dtls__info-sign" />Please fill all{' '}
                        <span>
                            <Link to={url}>documents</Link>
                        </span>
                    </React.Fragment>
                </Preloader>
            </button>
        )
    };
};

const paymentRequired = (trade, action, loading) => {
    return {
        Buyer: () => {
            if (!trade.requestInfo.payed) {
                return (
                    <button onClick={() => action(true)} className={`trades-dtls__button`}>
                        <Preloader loadingStyle="dots" loading={loading}>
                            <span>{trade.items.single.parentTradeRequestID ? "Multiple Shipment/Payment Confirmation" : "Payment Confirmation"}</span>
                        </Preloader>
                    </button>
                );
            } else {
                return <button className="trades-dtls__button trades-dtls__button_info">{trade.items.single.parentTradeRequestID ?"Multiple Shipment/Wait for Confirmation":"Wait for Confirmation"}</button>;
            }
        },
        Seller: () => {
            if (trade.requestInfo.payed) {
                return (
                    <button onClick={() => action(false)} className={`trades-dtls__button`}>
                        <Preloader loadingStyle="dots" loading={loading}>
                            <span>{trade.items.single.parentTradeRequestID ? "Multiple Shipment/Confirm Payment" : "Confirm Payment"}</span>
                        </Preloader>
                    </button>
                );
            } else {
                return <button className="trades-dtls__button trades-dtls__button_info">{trade.items.single.parentTradeRequestID ?"Multiple Shipment/Wait for Payment":"Wait for Payment"}</button>;
            }
        }
    };
};

const waitCloseTrade = (trade) => {
    return {
        Buyer: () => <button className="trades-dtls__button trades-dtls__button_info">{trade.items.single.parentTradeRequestID ?"Multiple Shipment/Shipment Completed":"Shipment Completed"}</button>,
        Seller: () => <button className="trades-dtls__button trades-dtls__button_info">{trade.items.single.parentTradeRequestID ?"Multiple Shipment/Shipment Completed":"Shipment Completed"}</button>
    };
};

const closeTrade = (action, loading, trader, trade) => {
    let signed =
        (trader === 'seller' && trade.requestInfo.sellerClose) || (trader === 'buyer' && trade.requestInfo.buyerClose);
    return {
        Buyer: () => (
            <button onClick={signed ? undefined : () => action()}
                className={`trades-dtls__button ${signed ? 'trades-dtls__button_success' : ''}`}>
                <Preloader loadingStyle="dots" loading={loading}>
                    <span>{signed ? 'Trade Closed' : 'Close Trade'}</span>
                </Preloader>
            </button>
        ),
        Seller: () => (
            <button onClick={signed ? undefined : () => action()}
                className={`trades-dtls__button ${signed ? 'trades-dtls__button_success' : ''}`}>
                <Preloader loadingStyle="dots" loading={loading}>
                    <span>{signed ? 'Trade Closed' : 'Close Trade'}</span>
                </Preloader>
            </button>
        )
    };
};

export const ControlBar = {
    Deal: dealBar,
    VesselBar: vesselBar,
    MultipleShipmentsPending,
    ProformaInvoiceBar: proformaInvoiceBar,
    DocInst: docInstBar,
    ConfirmContractFulfilled: confirmContractFulfilled,
    contractFulfilled: contractFulfilled,
    ShippingAdvice: shippingAdvice,
    DocumentsFill: documentsFill,
    DocumentsFull: documentsFull,
    PaymentRequired: paymentRequired,
    Wait: waitCloseTrade,
    Close: closeTrade
};