import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import moment from 'moment';

import { EnumsService } from '../../../services/service.utils';
import { DATEFORMAT, SHIPPINGTYPES } from '../../../services/service.values';

//COMPONENTS
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import CustomMaterialPreloader from '../../components/preloader/CustomMaterialPreloader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import NumberFormat from 'react-number-format';
import { fetchShipment } from '../../../modules/module.shipment';


const Countries = EnumsService.countries();

//Render functions
const sortRef = (a, b) => {
    let refA = a.tradeRefId || a.id;
    let refB = b.tradeRefId || b.id;
    return refA.localeCompare(refB);
};
const sortQuantity = (a, b) => (parseInt(a.quantity) - parseInt(b.quantity));
const renderOverflow = (content, className) => <div className={`trades__overflow ${className}`}>{content ? content : '-'}</div>;


const columns = [
    { title: 'Trade Ref Id', field: 'tradeRefId ', type: 'string', customSort: sortRef, render: rowData => rowData.tradeRefId || rowData.id },
    { title: 'Updated At ', field: 'updatedAt', type: 'date', hidden: true, defaultSort: 'desc' },
    { title: 'Creation Date ', field: 'createdAt', render: rowData => renderOverflow(moment(rowData.createdAt).format(DATEFORMAT)) },
    { title: 'Vessel/ container', field: 'shipping', render: rowValue => SHIPPINGTYPES[rowValue.shipping] },
    { title: 'Quantity (MT)', filed: 'measure', customSort: sortQuantity, render: rowData => <NumberFormat value={rowData.measure} displayType={'text'} thousandSeparator={true} suffix={rowData.measurement === 'TONS' ? "tons" : "cwt"} renderText={value => <span className="exchange__overflow">{value}</span>} ></NumberFormat>},
    { title: 'Price', field: 'price', type: 'numeric', render: rowData => <NumberFormat value={rowData.price} displayType={'text'} thousandSeparator={true} prefix={rowData.currency === 'USD' || rowData.currency==='US$'?'$':'€'} renderText={value => <span className="exchange__overflow">{value}</span>} ></NumberFormat> },
    { title: 'Origin', field: 'origin' }
]

const Shipments = ({
        shipmentMap, 
        fetchShipment,
        fetchShipmentPending, 
        fetchShipmentError 
    }) => {

    useEffect(() => {
        fetchShipment();
    },[])

    const handleOnRefresh = e => {
        fetchShipment();  
    };

    const handleRowClicked = (e, rowData) => {
    };

    return (<>
            <Header />
            <section className="requests">
                <div className="row">
                    <div className="col"><h2 className="trades__heading">My Shipments</h2></div>
                    <div className="col text-right">
                        <Tooltip title="Refresh">
                            <IconButton onClick={handleOnRefresh}>
                                <FontAwesomeIcon icon={faSyncAlt} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className="requests__table">
                    <MaterialTable
                        columns={columns}
                        isLoading={fetchShipmentPending}
                        data={_.values(shipmentMap)}
                        onRowClick={handleRowClicked}
                        icons={{
                            Filter: FilterList,
                            FirstPage: FirstPage,
                            LastPage: LastPage,
                            NextPage: ChevronRight,
                            PreviousPage: ChevronLeft,
                            SortArrow: ArrowUpward,
                        }}
                        components={{
                            OverlayLoading: CustomMaterialPreloader,
                            Toolbar: () => false
                        }}
                        options={{
                            search: false,
                            pageSize: 10,
                            pageSizeOptions: [10, 15, 20, 30],
                            rowStyle: (rowData, index) => {
                                let backgroundColor = (index % 2 === 0) ? '#00ff301c' : '#FBFBFB';                     
                                return { borderColor: '#c3e6cb', backgroundColor: backgroundColor }
                            }
                        }} />
                </div>
            </section>
            <Footer />
        </>);
}

const mapStateToProps = state => {
    return {
        shipmentMap: state.shipment.map,
        fetchShipmentPending: state.shipment.fetchShipmentPending,
        fetchShipmentError: state.shipment.fetchShipmentError,
        user: state.account.user
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({
            fetchShipment,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Shipments);