import {tenderApi} from '../services/service.api'; 
import _ from 'lodash';

export const SUBSCRIBE_PENDING = 'tender/SUBSCRIBE_PENDING';
export const SUBSCRIBE_SUCCESS = 'tender/SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_ERROR = 'tender/SUBSCRIBE_ERROR';
export const APPROVE_PENDING = 'tender/APPROVE_PENDING';
export const APPROVE_SUCCESS = 'tender/APPROVE_SUCCESS';
export const APPROVE_ERROR = 'tender/APPROVE_ERROR';
export const REJECT_PENDING = 'tender/REJECT_PENDING';
export const REJECT_SUCCESS = 'tender/REJECT_SUCCESS';
export const REJECT_ERROR = 'tender/REJECT_ERROR';
export const GET_TENDER_SUBSCRIPTIONS_PENDING = 'tender/GET_TENDER_SUBSCRIPTIONS_PENDING';
export const GET_TENDER_SUBSCRIPTIONS_SUCCESS = 'tender/GET_TENDER_SUBSCRIPTIONS_SUCCESS';
export const GET_TENDER_SUBSCRIPTIONS_ERROR = 'tender/GET_TENDER_SUBSCRIPTIONS_ERROR';
export const GET_COMPANY_SUBSCRIPTIONS_PENDING = 'tender/GET_COMPANY_SUBSCRIPTIONS_PENDING';
export const GET_COMPANY_SUBSCRIPTIONS_SUCCESS = 'tender/GET_COMPANY_SUBSCRIPTIONS_SUCCESS';
export const GET_COMPANY_SUBSCRIPTIONS_ERROR = 'tender/GET_COMPANY_SUBSCRIPTIONS_ERROR';
export const INIT_PRICES = 'tender/INIT_PRICES';
export const GET_PRICE_PENDING = 'tender/GET_PRICE_PENDING';
export const GET_PRICE_SUCCESS = 'tender/GET_PRICE_SUCCESS';
export const GET_PRICE_ERROR = 'tender/GET_PRICE_ERROR';
export const CLEAR_ERRORS = 'tender/CLEAR_ERRORS';
export const CLEAR = 'tender/CLEAR';
export const INIT_WHITELISTING = 'tender/INIT_WHITELISTING';
export const GET_WHITELISTED_COMPANIES_SUCCESS = 'tender/GET_WHITELISTED_COMPANIES_SUCCESS';
export const GET_WHITELISTED_COMPANIES_FAILED = 'tender/GET_WHITELISTED_COMPANIES_FAILED';
export const REMOVE_WHITELISTED_COMPANY_SUCCESS = 'tender/REMOVE_WHITELISTED_COMPANY_SUCCESS';
export const REMOVE_WHITELISTED_COMPANY_FAILED = 'tender/REMOVE_WHITELISTED_COMPANY_FAILED';
export const ADD_WHITELISTED_COMPANY_SUCCESS = 'tender/ADD_WHITELISTED_COMPANY_SUCCESS';
export const ADD_WHITELISTED_COMPANY_FAILED = 'tender/ADD_WHITELISTED_COMPANY_FAILED';

const initialState = {
    subscribePending: false,
    subscribeError: false,
    getTenderSubscriptionsPending: false,
    tenderSubscriptions: [],
    getTenderSubscriptionsError: false,
    getCompanySubscriptionsPending: false,
    companySubscriptions: {},
    getCompanySubscriptionsError: false,
    getPricesPending: false,
    prices: [],
    getPricesError: false,
    whitelistingProcess: '',
    whitelistedGroup: {},
    getWhitelistedCompaniesError: false
}

export default (state = {...initialState}, action = {}) => {
    let approvingSub;
    let rejectingSub;
    switch(action.type){
        case SUBSCRIBE_PENDING:
            return {...state, subscribePending: true, subscribeError: false}
        case SUBSCRIBE_SUCCESS:
            state.tenderSubscriptions.push(action.payload)
            return {...state, subscribePending: false, subscribeError: false}
        case SUBSCRIBE_ERROR:
            return {...state, subscribePending: false, subscribeError: action.error}
        case APPROVE_PENDING:
            approvingSub = state.tenderSubscriptions.find(item => (item.subscriptionID === action.payload.subscriptionID));
            approvingSub.approvePending = true;
            approvingSub.approveError = "";
            return {...state}
        case APPROVE_SUCCESS:
            const approveIndex = state.tenderSubscriptions.findIndex(item => (item.subscriptionID === action.payload.subscriptionID));
            state.tenderSubscriptions[approveIndex] = action.payload;
            return {...state, tenderSubscriptions: [...state.tenderSubscriptions]}
        case APPROVE_ERROR:
            approvingSub = state.tenderSubscriptions.find(item => (item.subscriptionID === action.payload.subscriptionID));
            approvingSub.approvePending = false;
            approvingSub.approveError = action.error;
            return {...state}
        case REJECT_PENDING:
            rejectingSub = state.tenderSubscriptions.find(item => (item.subscriptionID === action.payload.subscriptionID));
            rejectingSub.rejectPending = true;
            rejectingSub.rejectError = "";
            return {...state}
        case REJECT_SUCCESS:
            const rejectIndex = state.tenderSubscriptions.findIndex(item => (item.subscriptionID === action.payload.subscriptionID));
            state.tenderSubscriptions[rejectIndex] = action.payload;
            return {...state, tenderSubscriptions: [...state.tenderSubscriptions]}    
        case REJECT_ERROR:
            rejectingSub = state.tenderSubscriptions.find(item => (item.subscriptionID === action.payload.subscriptionID));
            rejectingSub.rejectPending = false;
            rejectingSub.rejectError = action.error;
            return {...state}
        case GET_TENDER_SUBSCRIPTIONS_PENDING:
            return {...state, getTenderSubscriptionsPending: true, tenderSubscriptions: [], getTenderSubscriptionsError: false};
        case GET_TENDER_SUBSCRIPTIONS_SUCCESS:
            return {...state, getTenderSubscriptionsPending: false, tenderSubscriptions: action.payload, getTenderSubscriptionsError: false};
        case GET_TENDER_SUBSCRIPTIONS_ERROR:
            return {...state, getTenderSubscriptionsPending: false, tenderSubscriptions:  [], getTenderSubscriptionsError: action.error};
        case GET_COMPANY_SUBSCRIPTIONS_PENDING:
            return {...state, getCompanySubscriptionsPending: true, companySubscriptions:  {}, getCompanySubscriptionsError: false};
        case GET_COMPANY_SUBSCRIPTIONS_SUCCESS:
            return {...state, getCompanySubscriptionsPending: false, companySubscriptions: action.payload, getCompanySubscriptionsError: false};
        case GET_COMPANY_SUBSCRIPTIONS_ERROR:
            return {...state, getCompanySubscriptionsPending: false, companySubscriptions:  {}, getCompanySubscriptionsError: action.error};
        case INIT_PRICES:
                return {...state, getPricesPending: "init", getPricesError: false };
        case GET_PRICE_PENDING:
            return {...state, getPricesPending: true, getPricesError: false };
        case GET_PRICE_SUCCESS:
            return {...state, getPricesPending: false, prices: action.payload, getPricesError: false };
        case GET_PRICE_ERROR:
            return {...state, getPricesPending: false, prices: [], getPricesError: action.error };
        case CLEAR_ERRORS: 
            state.tenderSubscriptions.forEach(sub => {
                sub.approveError = false
                sub.rejectError = false;
                sub.subscribeError = false;
            });
            return {...state, subscribeError: false, approve};
        case CLEAR:
            return {...initialState};
        case INIT_WHITELISTING:
            return { ...state, whitelistingProcess: action.payload, getWhitelistedCompaniesError: null}
        case GET_WHITELISTED_COMPANIES_SUCCESS:
            return { ...state, whitelistingProcess: '', whitelistedGroup: action.payload, getWhitelistedCompaniesError: null}
        case GET_WHITELISTED_COMPANIES_FAILED:
            return { ...state, whitelistingProcess: '', whitelistedGroup: {}, getWhitelistedCompaniesError: action.payload}
        case REMOVE_WHITELISTED_COMPANY_SUCCESS:
            let updatedGroup = { ...state.whitelistedGroup }
            let updatedCompanies = updatedGroup.companies.filter(company => company.ID !== action.payload);
            updatedGroup.companies = updatedCompanies;
            return {
                ...state,
                whitelistingProcess: '',
                whitelistedGroup: updatedGroup
            }
        case REMOVE_WHITELISTED_COMPANY_FAILED:
            return { ...state, whitelistingProcess: ''}
        case ADD_WHITELISTED_COMPANY_SUCCESS:
            let updatedWhitelistedGroup = { ...state.whitelistedGroup }
            if(Object.keys(updatedWhitelistedGroup).length === 0){
                updatedWhitelistedGroup.groupID = action.payload.groupID;
                updatedWhitelistedGroup.companies = [action.payload.company];
            }else{
                updatedWhitelistedGroup.companies.push(action.payload.company);
            }
            return {
                ...state,
                whitelistingProcess: '',
                whitelistedGroup: updatedWhitelistedGroup
            }
        case ADD_WHITELISTED_COMPANY_FAILED:
            return { ...state, whitelistingProcess: ''}
        default:
            return state;
    }
};

export const getCompanySubscriptions = () => {
    return dispatch => {
        dispatch({type: GET_COMPANY_SUBSCRIPTIONS_PENDING});
        tenderApi.getCompanySubscriptions()
        .then(res => {
            const subscriptions = _.keyBy(res.data.subscriptionList, 'subscriptionID');
            dispatch({type: GET_COMPANY_SUBSCRIPTIONS_SUCCESS, payload: subscriptions});
        })
        .catch(e => {
            dispatch({type: GET_COMPANY_SUBSCRIPTIONS_ERROR, payload: e});
        })
    };
};

export const getTenderSubscriptions = (tradeId) => {
    return dispatch => {
        dispatch({type: GET_TENDER_SUBSCRIPTIONS_PENDING});
        tenderApi.getTenderSubscriptions(tradeId)
        .then(res => {
            /** To Be Handeled in BE **/
            res.data.subscriptionList.forEach(subscription => {
                if(subscription.approvedAt === "0001-01-01T00:00:00Z")
                    subscription.approvedAt = null;
                if(subscription.rejectedAt === "0001-01-01T00:00:00Z")
                    subscription.rejectedAt = null;
            });
            /** END To Be Handeled in BE **/
            dispatch({type: GET_TENDER_SUBSCRIPTIONS_SUCCESS, payload: res.data.subscriptionList});
        })
        .catch(e => {
            dispatch({type: GET_TENDER_SUBSCRIPTIONS_ERROR, payload: e});
        })
    };
};

export const subscribe = (tradeId) => {
    return dispatch => {
        dispatch({type: SUBSCRIBE_PENDING});
        return tenderApi.subscribe(tradeId)
        .then(res => {
            let subscription = res.data.subscription;
            /** To Be Handeled in BE **/
            if(subscription.approvedAt === "0001-01-01T00:00:00Z")
                subscription.approvedAt = null;
            if(subscription.rejectedAt === "0001-01-01T00:00:00Z")
                subscription.rejectedAt = null;
            /** END To Be Handeled in BE **/
            dispatch({type: SUBSCRIBE_SUCCESS, payload: subscription});
        })
        .catch(e => {
            let error = (e.response)?e.response.data.message:"Unable to perform subscription";
            dispatch({type: SUBSCRIBE_ERROR, error: error});
            throw new Error(error)
        })
    };  
}

export const approve = (tradeId, subscriptionId) => {
    return dispatch => {
        dispatch({type: APPROVE_PENDING, payload:{subscriptionID: subscriptionId}});
        return tenderApi.approve(tradeId, subscriptionId)
        .then(res => {
            let subscription = res.data.subscription;
            /** To Be Handeled in BE **/
            if(subscription.approvedAt === "0001-01-01T00:00:00Z")
                subscription.approvedAt = null;
            if(subscription.rejectedAt === "0001-01-01T00:00:00Z")
                subscription.rejectedAt = null;
            /** END To Be Handeled in BE **/
            dispatch({type: APPROVE_SUCCESS, payload: subscription});
        })
        .catch(e => {
            let error = (e.response)?e.response.data.message:"Unable to approve subscription";
            dispatch({type: APPROVE_ERROR, payload:{subscriptionID: subscriptionId}, error: error});
            throw new Error(error)
        })
    };  
}

export const reject = (tradeId, subscriptionId) => {
    return dispatch => {
        dispatch({type: REJECT_PENDING, payload:{subscriptionID: subscriptionId}});
        return tenderApi.reject(tradeId, subscriptionId)
        .then(res => {
            let subscription = res.data.subscription;
            /** To Be Handeled in BE **/
            if(subscription.approvedAt === "0001-01-01T00:00:00Z")
                subscription.approvedAt = null;
            if(subscription.rejectedAt === "0001-01-01T00:00:00Z")
                subscription.rejectedAt = null;
            /** END To Be Handeled in BE **/
            dispatch({type: REJECT_SUCCESS, payload: subscription});
        })
        .catch((e) => {
            let error = "Unable to reject subscription"
            if(e.response && e.response.data){
                error = e.response.data.message
            }
            dispatch({type: REJECT_ERROR, payload:{subscriptionID: subscriptionId}, error: error});
            throw new Error(error)
        })
    };  
}

export const getPrices = (tradeId, init=false) => {
    return dispatch => {
        dispatch({type: (init)?INIT_PRICES:GET_PRICE_PENDING});
        return tenderApi.getPrices(tradeId)
        .then(res => {
            dispatch({type:GET_PRICE_SUCCESS, payload: res.data.priceList }); 
        })
        .catch(e => {
            let error = "An error occured";
            if(e.response && e.response.data){
                error = e.response.data.message
            }
            dispatch({type: GET_PRICE_ERROR, error: error}); 
        });
    }
}

export const clearErrors = () => {
    return dispatch => (dispatch({type: CLEAR_ERRORS}))
}

export const getWhitelistedCompanies = () => {
    return dispatch => {
        dispatch({type: INIT_WHITELISTING, payload: 'INIT'});
        return tenderApi.getWhitelistedCompanies().then(response => {
            dispatch({
                type: GET_WHITELISTED_COMPANIES_SUCCESS,
                payload: response.data.group
            })
        }).catch(error => {
            dispatch({
                type: GET_WHITELISTED_COMPANIES_FAILED,
                payload: error
            })
        })
    }
}

export const removeWhitelistedCompany = (groupId, companyId, cb) => {
    return dispatch => {
        dispatch({type: INIT_WHITELISTING, payload: 'REMOVING'});
        return tenderApi.removeWhitelistedCompany(groupId, companyId).then(() => {
            dispatch({
                type: REMOVE_WHITELISTED_COMPANY_SUCCESS,
                payload: companyId
            })
            if(cb){cb()}
        }).catch(error => {
            dispatch({
                type: REMOVE_WHITELISTED_COMPANY_FAILED,
                payload: error
            })
        })
    }
}

export const addNewWhitelistedCompany = (companyDetails) => {
    return dispatch => {
        dispatch({type: INIT_WHITELISTING, payload: 'ADDING'});
        return tenderApi.addNewWhitelistedCompany(companyDetails).then(response => {
            dispatch({
                type: ADD_WHITELISTED_COMPANY_SUCCESS,
                payload: {
                    company: companyDetails.company,
                    groupID: response.data.groupID
                }
            })
        }).catch(error => {
            dispatch({
                type: ADD_WHITELISTED_COMPANY_FAILED,
                payload: error
            })
        })
    }
}