import React, { useState, useMemo, useEffect } from 'react'
import Modal from '../../../../components/modal/Modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { rejectContractFulfilled, acceptContractFulfilled, SetTradeStatus } from '../../../../../modules/module.trade';
import Preloader from '../../../../components/preloader/Preloader';
import { NavigateBeforeSharp } from '@material-ui/icons';
import FormTextareaField from '../../../../components/form/FormTextareaField';
import ShipmentRecapTable from './ShipmentRecapTable';
import { getShipmentList } from '../../../../../constants/shipmentRecapTableConstants';


const ContractFulfillConfirmation = ({ trade, onClose, acceptContractFulfilled, rejectContractFulfilled, loadingContractFulfilled, tradeMap, shipmentMap, vesselNominationMap, synthesis }) => {


    const [reject, setReject] = useState(false);
    const [rejectReason, setRejectReason] = useState({
        label: "Reason for Rejection",
        value: "",
        name: "rejectReason"
    })
    const [errors, setErrors] = useState({});
    const [short, setShort] = useState();
    const [treshold, setTreshold] = useState();

    const parentTrade = tradeMap ? tradeMap[trade.id] : null;
    useEffect(() => {
        setShort(synthesis.balance.toShip.min > 0);
        setTreshold(synthesis && synthesis.balance.toShip.min === 0 && synthesis.balance.toShip.max > 0);
    }, [synthesis])

    const shipmentList = useMemo(() => {
        return getShipmentList(tradeMap, parentTrade, vesselNominationMap, shipmentMap)
    }, [tradeMap, parentTrade, vesselNominationMap, shipmentMap]);

    const Footer = ({ onReject, onAccept, loading, reject, onRejectConfirm, onRejectCancel }) => {
        return (<div className="my-2 text-center">
            {reject ?
                <>
                    <button type="button" className="btn btn-secondary" disabled={loading} onClick={onRejectCancel}>Cancel</button>
                    <button type="button" className="btn btn-primary" disabled={loading} style={{ minHeight: '33.1px' }} onClick={onRejectConfirm}>
                        <Preloader loadingStyle="dots" loading={loading}>Confirm</Preloader>
                    </button>
                </>
                :
                <>
                    <button type="button" className="btn btn-secondary" onClick={onReject}>Reject</button>
                    <button type="button" className="btn btn-primary" style={{ minHeight: '33.1px' }} onClick={onAccept}>
                        <Preloader loadingStyle="dots" loading={loading}>Accept</Preloader>
                    </button>
                </>}
        </div>)
    }

    const handleOnAccept = () => {
        acceptContractFulfilled(trade.id)
            .then(response => {

                onClose();
            })
            .catch(e => {
            })
    }

    const handleOnReject = () => {
        if (validate()) {
            rejectContractFulfilled(trade.id, { rejectReason: rejectReason.value })
                .then(response => {
                    SetTradeStatus('CONTRACT_FULFILL_REJECT', trade.id)
                    onClose();
                })
                .catch(e => {
                })
        }
    }

    const resetRejection = () => {
        setReject(false);
        setRejectReason({ ...rejectReason, value: "" })
    }

    const validate = () => {
        const isEmpty = rejectReason.value === "";
        if (isEmpty) {
            setErrors({ errors, rejectReason: "Please enter a reason in order to submit the rejection." })
            return false;
        } else {
            setErrors({});
            return true;
        }
    }

    const handleOnChange = (e) => {
        setRejectReason({ ...rejectReason, value: e.target.value });
    }

    const getTotalQuantity = () => {
        let totalQuantity = 0;
        parentTrade.childTradeRequestID.map((childId, index) => {
            const childTrade = tradeMap[childId]
            if (!childTrade) return null
            const shipment = shipmentMap[childId];

            if (shipment &&
                shipment.list &&
                shipment.list.length !== 0 &&
                shipment.list[0].Bill.quantCleanOnBoard
            ) {
                return totalQuantity += shipment.list.reduce(
                    (init, item) => init + item.Bill.quantCleanOnBoard,
                    0
                )
            }
        })
        return totalQuantity;
    }
    const getAlertMessageContent = () => {
        return trade.partialShipmentFlag && short ?
            <p className='contractfullfilConfirmation_alert_text'> <strong>{parentTrade.incoterm === 'FOB'?`${parentTrade.buyer}(${parentTrade.buyerUser})`:`${parentTrade.seller}(${parentTrade.sellerUser})`}</strong> has sent you contract fulfillment request to accept tonnage {<strong>{getTotalQuantity()}MT</strong>} for trade <strong>{trade.tradeRefId}</strong> ,and the total quantity shipped under this contract is inferior to the Min required contract tonnage</p>
            : trade.partialShipmentFlag && treshold ?
                <p className='contractfullfilConfirmation_alert_text'><strong>{parentTrade.incoterm === 'FOB'?`${parentTrade.buyer}(${parentTrade.buyerUser})`:`${parentTrade.seller}(${parentTrade.sellerUser})`}</strong> has sent you contract fulfillment request to accept tonnage {<strong>{getTotalQuantity()}MT</strong>}  for trade <strong>{trade.tradeRefId}</strong> ,and the total quantity shipped under this contract is between the Min and the Max required contract tonnage.</p>
                : null
    }
    
    return (<Modal title={reject ? 'Reject Contract Fulfillment Request' : 'Confirm Contract Fulfilled'}
        onClose={onClose}
        reject={reject}
        rejectReason={rejectReason}
        onReject={() => setReject(true)}
        onAccept={handleOnAccept}
        onRejectConfirm={handleOnReject}
        onRejectCancel={resetRejection}
        loading={loadingContractFulfilled}
        components={{
            footer: Footer
        }}>

        {reject ?
            <form>
                <FormTextareaField
                    maxLength="300"
                    rows="3"
                    cols="70"
                    name="rejectReason"
                    value={rejectReason}
                    placeholder={"Enter rejection reason"}
                    onChange={handleOnChange}
                    validation={errors}
                />
                {errors.rejectReason && <div className="text-danger">{errors.rejectReason}</div>}
            </form> :
            <>
                <ShipmentRecapTable
                    parentTrade={parentTrade}
                    shipmentList={shipmentList}
                    onTableUpdate={() => { return false }}
                />
                <div className="text-center">
                    {getAlertMessageContent()}
                    <p className='contractfullfilConfirmation_alert_text mt-10'>{` Do you consider this contract as fulfilled ?`}</p>
                </div>
            </>}
    </Modal>)

}

const mapStateToProps = state => {
    return {
        trade: state.trade.items.single,
        loadingContractFulfilled: state.loading.loadingContractFulfilled,
        shipmentMap: state.trade.shipmentMap,
        vesselNominationMap: state.trade.vesselNominationMap,
        synthesis: state.shipment.synthesis
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            rejectContractFulfilled,
            acceptContractFulfilled,
            SetTradeStatus

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ContractFulfillConfirmation);