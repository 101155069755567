import React from 'react';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import { DATEFORMATHOURS } from '../../../../services/service.values';
import { Link } from 'react-router-dom';
import {getLocalTimezone} from '../../../../services/service.utils';


const MultiPartyCommunication = ({ onSelect, selected, trade, counterpartyList }) => {
    const renderPrice = () => {
        if(!trade.price &&  trade.bestPrice) return 'Best Price';
        return(
            <NumberFormat 
                value={trade.price} 
                displayType={'text'} 
                thousandSeparator={true} 
                suffix={trade.currency} 
                renderText={value => value} 
            />
        )
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-sm-6">
                    <h3 className="request-dtls__card-heading">
                     Private Communication
                        </h3>
                </div>
            </div>
            <div className="table-responsive mt-2">
                <table className="table table-hover latest-negociation-table">
                    <thead>
                        <tr>
                               <th>Counterparty Name</th>
                                <th>Price per Mton</th>
                                <th>Quantity(MT)</th>
                                <th>`Expires ({getLocalTimezone()})`</th>
                                <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {counterpartyList && counterpartyList.map((item, i) =>
                            <tr className={([item.id].includes(selected)) ? "table-info" : ""}
                                onClick={() => onSelect(item.id,item.name)}
                                key={i}>
                                    <td><Link to={`/company-profile/${item.id}`}>{`${item.name}`}</Link></td>
                                    <td>{renderPrice()}</td>

                                    <td><NumberFormat value={trade.measure} displayType={'text'} thousandSeparator={true} renderText={value => value} ></NumberFormat></td>
                                    <td>{moment(trade.validateDate).format(DATEFORMATHOURS)}</td>
                                    <td>No offer Received </td>

                            </tr>)}
                    </tbody>
                </table>
            </div>
        </React.Fragment>)
}
export default MultiPartyCommunication;