import React, { Component } from 'react';

import {
  FormRow
} from '../../Form';

import FormFileUpload from '../../form/FormFileUpload'



export class Documents extends Component {
  state = { };

  render() {

    let { state, setField, validation, fileUploadValidate } = this.props;
    return (
      <div >
        <FormRow>
        <div className="col-12 col-md-6 mb-3">
          <FormFileUpload 
              value={state.certificateOfIncorporation} 
              name="certificateOfIncorporation"
              validation={validation}
              onChange={file => setField("certificateOfIncorporation", file)}
              onError={fileUploadValidate}
              />
            
        </div>
        <div className="col-12 col-md-6 mb-3">
          <FormFileUpload 
              value={state.articlesOfIncorporation} 
              name="articlesOfIncorporation"
              validation={validation}
              onChange={file => setField("articlesOfIncorporation", file)}
              onError={fileUploadValidate}
              />
            
        </div>
    </FormRow>


      </div>
    );
  }
}
