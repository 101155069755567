import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DetailTable from '../../../requests/detail/DetailTable';
import moment from 'moment';
import { 
    DATEFORMAT, 
    DATEFORMATHOURS, 
    PAYMENTPERIODOPT, 
    DISCHARGE_VALUES, 
    getDischargeRateLabel, 
    getDischargeLabel,
    RICECATEGORY
} from '../../../../../services/service.values';
import { PortService, getLocalTimezone} from '../../../../../services/service.utils';
import { faTimes, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//STYLE
import '../../../requests/detail/detail.scss';
import { RiceService } from '../../../../../services/service.rice';
import Preloader from '../../../../../app/components/preloader/Preloader';
import NumberFormat from 'react-number-format';
import { 
    renderDocumentsForm, 
    renderDocumentsTitle, 
    renderCountry,
    renderPrice
} from '../../../requests/detail/detailTable/Renderers';
import {getInspectionCompanyName} from '../../../../../services/service.format';
import TradeQuantityFormatter from '../../../../components/Formatter/TradeQuantityFormatter';

/**
 * Short description. (use period)
 *
 * @since  05/02/20
 * @access public
 *
 * @type     React.Component
 * @property {type} key Description.
 *
 * @member   {type} realName
 * @memberof className
 */
class TermDetailsModal extends React.Component {
    FORM_GROUP_WRAPPER_CLASS = "col-md-4";
    FORM_CREATE_WRAPPER_CLASS = "col-md-6";
    CUSTOM_COMPONENT_STYLE = {
        wrapperClassName: "form-group",
        labelClassName: "label",
        inputClassName: "form-control",
        selectClassName: "custom-select",
        radioColLayout: { left: "col-sm-6 col-md-6 col-lg-6 col-xl-4", right: "col-auto" }
    }
    constructor(props) {
        super(props);
        this.state = ({
            'state': 'INITIAL',
            'series': [],
            declineModal: false
        });
        this.fieldList = [
            { key: "price", label: "Price", "customStyle": "row-price", format: renderPrice, tdClass: this.tdClass },
            ...(this.props.trade.items.single.riceCategory && this.props.trade.items.single.riceCategory !== "NON-ORGANIC"? 
            [{ key: "riceCategory", label: "Rice Category", format: value => (RICECATEGORY[value]|| value) , tdClass:this.tdClass }]
            :
            []),
            { key: "riceType", label: "Type of Rice", format: value => (RiceService.getByRef(value) ? RiceService.getByRef(value).label : value), tdClass: this.tdClass },
            
            { key: "riceTypeOther", label: "Specify Type of Rice", tdClass: this.tdClass },
            { key: "incoterm", label: "Incoterms", tdClass: this.tdClass },
            { key: "shipping", label: "Shipping", tdClass: this.tdClass },
            { key: "partialShipmentFlag", label: "Partial Shipment Allowed", format: value => value ? "Yes" : "No", tdClass: this.tdClass },
            { key: "inspection", label: "Inspection Company", format:(value, serie, index, series)=> getInspectionCompanyName(value, serie, index, series,this.props.trade.inspections), tdClass: this.tdClass },
            { key: "measure", label: "Quantity(MT)", format: (value, serie) => <TradeQuantityFormatter trade={serie.data}/> , tdClass: this.tdClass },
            { key: "tolerance", label: "Contractual Tolerance", format: (value) => !value ? '-' : value === 0 ? `Min/Max in option of ${this.props.trade.items.single.incoterm === "FOB" ? "Buyer" : "Seller"}` : `+/- ${value}% in option of ${this.props.trade.items.single.incoterm === "FOB" ? "Buyer" : "Seller"}`, tdClass: this.tdClass },
            { key: "quality", label: "Quality Standard", tdClass: this.tdClass },
            { key: "packaging", label: "Packaging", tdClass: this.tdClass },
            ...(this.props.trade.privacyType === 'TENDER' ? []:
            [{ key: "euroConversionAvl", label: "Euro Conversion Available", format: this.renderYesNo, tdClass: this.tdClass }]),
            { key: "origin", label: "Origin", format: renderCountry, tdClass: this.tdClass },
            { key: "loadCountry", label: "Country of Load", format: renderCountry, tdClass: this.tdClass },
            { key: "loadPort", label: "Port of Load", format: this.renderPortLabel, tdClass: this.tdClass },
            { key: "destCountry", label: "Country of Destination", format: renderCountry, tdClass: this.tdClass },
            { key: "destPort", label: "Port of Destination", format: this.renderPortLabel, tdClass: this.tdClass },
            { key: "deliveryStartDate", label: "Delivery Start Date", format: (value) => moment(value).format(DATEFORMAT), tdClass: this.tdClass },
            { key: "deliveryEndDate", label: "Delivery End Date", format: (value) => moment(value).format(DATEFORMAT), tdClass: this.tdClass },
            { key: "discharge", label: "Load or Discharge Terms", format: this.renderDischarge, tdClass: this.tdClass },
            { key: "dischargeRate", label: "Load or Discharge Rate", format: this.renderDischargeRate, tdClass: this.tdClass },
            { key: "cropYear", label: "Crop Year", tdClass: this.tdClass },
            { key: "payment", label: "Payment Terms", tdClass: this.tdClass },
            { key: "paymentPeriod", label: "Payment Period", format: (value => PAYMENTPERIODOPT[value]), tdClass: this.tdClass },
            { key: "contractValidateDate", label: `Amendment Expiry Date (${getLocalTimezone()})`, format: this.formatExpiryDate, tdClass: this.tdClass },
            { key: "nameOfBank", label: "Name of Bank", tdClass: this.tdClass },
            { key: "locationOfBank", label: "Location of Bank", tdClass: this.tdClass },
            { key: "swiftCodeOfBank", label: "Swiftcode of Bank", tdClass: this.tdClass },
            { key: "nameOfConfirmingBank", label: "Name of Confirming Bank", tdClass: this.tdClass },
            { key: "locationOfConfirmingBank", label: "Location of Confirming Bank", tdClass: this.tdClass },
            { key: "swiftCodeOfConfirmingBank", label: "Swiftcode of Confirming Bank", tdClass: this.tdClass },
            { key: "downPaymentPercentage", label: "Down Payment", format: (value) => (value) ? `${value}%` : "NO", tdClass: this.tdClass },
            { key: "downPaymentPeriod", label: "Down payment to be effected within", tdClass: this.tdClass },
           
            { key: "optionalDocuments", label: renderDocumentsTitle(), format: renderDocumentsForm, tdClass: this.tdClass },
            { key: "SpecialRequest", label: "Special Request", format: value => "", tdClass: "bg-light font-weight-bold" },
            { key: "specialRequest", label: "Content", format: value => value ? value : "NO", tdClass: this.tdClass },
        ];
    }

    componentDidMount() {
        this.updateColumns();
    }

    renderPortLabel = value => {
        let label = "";
        if (value) {
            label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
        }
        return label;
    }

    renderDischarge = (value, serie) => {
        if (value) {
            const incoterm = serie.data.incoterm;
            const formatedValue = DISCHARGE_VALUES[value];
            return `${getDischargeLabel(incoterm)}: ${formatedValue}`
        } else {
            return "";
        }

    }

    renderDischargeRate = (value, serie) => {
        if (value) {
            const incoterm = serie.data.incoterm;
            const discharge = serie.data.discharge;
            return  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={getDischargeRateLabel(incoterm, discharge)+": "} />
        } else {
            return "";
        }
    }
    

    updateColumns() {
        const nbDynamic = 3;
        const terms = this.props.terms;
        let nbFixed = 1;
        if (!this.props.trade.items.single.price) {
            nbFixed = 3;
        }

        terms.reverse();
        if (this.props.requestLoaded) {
            terms.push(this.props.requestLoaded);
        }

        const fix = terms.splice(0, nbFixed);
        const dyn = terms.splice(-nbDynamic, nbDynamic);
        const termsToDisplay = [...fix, ...dyn]
        const series = termsToDisplay.map((term, index, termArray) => {
            const title = this.getColumnTitle(index, term, termArray);
            return { title: title, data: term }
        });
        this.setState({ series: series });
    }

    /** Render functions **/
    tdClass(value, serie) {
        let className = "";
        if (serie.data.status === "REVIEW") {
            className = "cell-review";
        }
        return className;
    }
    tdClassOption(value, serie) {
        let className = this.tdClass(value, serie);
        if (className !== "") {
            className = "bg-light";
        }
        return className;
    }

    formatExpiryDate = (value, serie) => {
        if (serie.data.contractValidateDate) {
            return moment(serie.data.contractValidateDate).format(DATEFORMATHOURS);
        }
        //  else if (serie.data.validateDate) {
        //     return moment(serie.data.validateDate).format(DATEFORMATHOURS);
        // }
         else {
            return "-";
        }

    }

    renderYesNo = value => {
        let res = "";
        if (typeof value === "undefined" || value === null || value === '') {
            res = "-"
        } else {
            res = (value) ? "Yes" : "No";
        }
        return res;
    }
    get tradeRequest() {
        return this.props.trade.items.single || {};
    }

    get isTradeOwner() {
        if (this.props.account && this.props.account.token) {
            return this.props.account.token.companyId === this.tradeRequest.ownerId;
        }
        return false;
    }

    isTermOwner(term) {
        if (term && term.fromCompanyID) {
            return term.fromCompanyID.trim() === this.props.account.user.companyId.trim();
        } else {
            return undefined
        }
    }

    getColumnTitle(index, term, termArray) {
        const isTermOwner = this.isTermOwner(term);
        const isReview = term.status === 'REVIEW';
        let title = "";
            switch (index) {
                case 0: title = "Initial request"; break;
                case 1: title = isReview ? "Review Amendment request" : isTermOwner ? "Request Sent" : "Request Received"; break;
                default: title = isReview ? "Review Amendment request" : isTermOwner ? "Request Sent" : "Request Received";
            }
        return title;
    }
    
    DeclineContractModal = () => {
        this.setState({ declineModal: true });
    }

    CloseDeclineModal = () => {
        this.setState({ declineModal: false });
    }

    
    render() {
        return (
            <div className="modal__container confirm-modal">
                <div className="modal__wrapper px-0 py-0 modal-scroll">
                    <div className="modal-header">
                        <div className="modal-header-with-note">
                        <h5 className="modal-title w-100">{this.props.title}</h5>
                        { ( this.props.isSentContract ||  this.props.isRecivedContract || this.props.isDeclineContract) &&
                            <div className="modal-note-text">Request sent and request received always show the latest sent or received value</div>}
                        </div>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onClose} aria-label="Close">
                            <span aria-hidden="true" >&times;</span>
                        </button>
                    </div>
                    <div className="modal-body py-0">
                        <div className="table-responsive mt-1 trade-dtls__table-section">
                            <DetailTable
                                series={this.state.series}
                                fieldList={this.fieldList}>
                            </DetailTable>
                        </div>
                    </div>
                    {this.props.isSubmitContract &&
                        <div className="modal-footer">
                           {this.props.isDeclarationCheck}
                            <button className={`btn btn-confirm-modal btn--grey`} type="button" onClick={this.props.onClose}><FontAwesomeIcon icon={faTimes} /> Cancel</button>
                            <button className={`btn btn-confirm-modal btn--orange`} type="button" onClick={this.props.editContract}><FontAwesomeIcon icon={faEdit} /> Edit</button>
                            <button className={`btn btn-confirm-modal btn--blue`} type="button" onClick={this.props.onSubmitContract}><FontAwesomeIcon icon={faCheck} /> Submit</button>
                        </div>}
                    {this.props.isSentContract &&
                        <div className="modal-footer">
                            <button className={`btn btn-confirm-modal btn--grey`} type="button" onClick={this.props.onClose}><FontAwesomeIcon icon={faTimes} /> Close</button>
                        </div>}
                    {this.props.isRecivedContract &&
                        <div className="modal-footer">
                             {this.props.isDeclarationCheck}
                            <button className={`btn btn-confirm-modal btn--blue`} type="button" onClick={this.props.counterContract}><FontAwesomeIcon icon={faEdit} /> Propose New Amendment</button>

                            <button className={`btn btn-confirm-modal btn--blue`} disabled={this.props.requestLoading} type="button" onClick={this.props.acceptContract}>
                            <Preloader loadingStyle="dots" loading={this.props.requestLoading}>
                                   <span><FontAwesomeIcon icon={faCheck} />&nbsp;Accept</span>
                            </Preloader>
                             </button>
                            <button className={`btn btn-confirm-modal btn--grey`} type="button" onClick={e => { this.props.declineContractModal() }}><FontAwesomeIcon icon={faTimes} /> Decline</button>
                        </div>}
                    {this.props.isDeclineContract &&
                        <div className="modal-footer">
                            <button className={`btn btn-confirm-modal btn--grey`} type="button" onClick={this.props.onClose}>Cancel</button>
                            <button className={`btn btn-confirm-modal btn--blue`} type="button" onClick={this.props.counterContract}>Propose New Amendment</button>
                        </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        account: state.account,
        requestLoading: state.loading.requestLoading,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            //  DeclineContractTermrequestLoaded
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(TermDetailsModal);