import React from 'react';

const UboDetails = ({ company }) => (
    <div>
        <h4 className="mt-1">Company UBO details</h4>
        <div className="table-responsive mb-4">
            <table className="table table-bordered table-hover table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name of Ultimate Beneficial Owner</th>
                        <th scope="col">UBO Passport</th>
                        <th scope="col">UBO Percentage</th>
                    </tr>
                </thead>
                <tbody>
                    {company.ubos &&
                        company.ubos.map((k, i) => {
                            return (
                                <tr key={i}>
                                    <td>{k.name}</td>
                                    <td>{k.passport && k.passport.fileUrl !== "#" && <a href={k.passport.fileUrl} >View Passport</a>}</td>
                                    <td>{k.percentage}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    </div>
);

export default UboDetails;