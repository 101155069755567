import React from 'react';

import TRSurcharge from './TRSurcharge';

class PriceDetails extends React.Component{

    
    render(){

        const { price } = this.props;
        if(!price){
            return false;
        }
        const surchargesPerDoc = price.surcharges_per_doc || [];
        const surchargesPerContainer = (price.prices_per_container && price.prices_per_container.length !==0)? price.prices_per_container[0].surcharges_per_container:[];
        const BAS = (price.prices_per_container && price.prices_per_container !==0)? price.prices_per_container[0].bas:null;
        const quantity = BAS?BAS.qty:'-';
        let pricePerFCL = BAS?BAS.rateusd:0;

        return (<div className="row">
        <div className="table-responsive col-sm-12">
            <table className="table table-striped table-bordered table-hover table-sm">
                <thead className="border-top-0">
                    <tr>
                        <th>Surcharge name</th>
                        <th>Basis</th>
                        <th>Price per FCL</th>
                        <th>{`Total Price (${quantity} FCL)`}</th>
                    </tr>
                </thead>
                <tbody>
                    {surchargesPerDoc.map((item, index) =>{
                        pricePerFCL+=item.rateusd;
                        return <TRSurcharge price={item} key={`doc-${index}`}/>}
                    )}
                    {surchargesPerContainer.map((item, index) => {
                        pricePerFCL+=item.rateusd;
                        return <TRSurcharge price={item} key={`container-${index}`}/>}
                    )}
                    {BAS &&
                    <TRSurcharge price={BAS} key={`container-BAS`}/>}



                </tbody>
                <tfoot className="table-dark">
                    <tr>
                        <td colSpan="1">Total</td>
                        <td colSpan="1"></td>
                        <td colSpan="1">
                            <div><span className={''}>{`Total price for one container`}</span></div>
                            <div><span className={'ml-2 font-weight-bold float-right'}>{`${ Math.round((pricePerFCL + Number.EPSILON) *100) / 100} USD`}</span></div>
                        </td>
                        <td colSpan="1">
                            <div><span className={''}>{`Total price for ${quantity} container${quantity > 1?'s':''}`}</span></div>
                            <div><span className={'ml-2 font-weight-bold float-right'}>{`${price.total} USD`}</span></div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>)
    }
}

export default PriceDetails;