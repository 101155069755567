import React, { useEffect, useState } from 'react'
import moment from 'moment';
import FormTextareaField from '../../../../components/form/FormTextareaField';
import FormInputGroupField from '../../../../components/form/FormInputGroupField';
import FormDateFieldFromTo from '../../../../components/form/FormDateFieldFromTo';
import FormInputField from '../../../../components/form/FormInputField';
import FormFileUpload from '../../../../components/form/FormFileUpload';
import FormSelectDropdown from '../../../../components/form/FormSelectDropdown';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import FormInputNumberFormat from '../../../../components/form/FormInputNumberFormat';


const initForm = (initial) => ({
    'isSeller': {
        value: initial.isSeller,
        label: '',
        required: true,
        errorMessage: ''
    },
    'message': {
        value: initial.message || '',
        label: 'Special Instructions',
        isValid: (message) => true,
        required: false,
        errorMessage: ''
    },
    'name': {
        value: initial.name || '',
        label: 'Vessel Name',
        isValid: (name) => (name.value !== ''),
        required: true,
        errorMessage: ''
    },
    'nominateQuantity': {
        value: initial.nominateQuantity || '',
        label: 'Quantity (MTons)',
        type: 'number',
        isValid: (nominateQuantity) => (nominateQuantity.value !== ''),
        required: true,
        errorMessage: ''
    },
    'shippingTolerance': {
        value: initial.shippingTolerance || '',
        label: 'Shipping Tolerance',
        type: 'number',
        required: false,
        errorMessage: ''
    },
    'laycanDateFrom': {
        value: initial.laycanDateFrom? moment(initial.laycanDateFrom) : '',
        label: 'Laycan Date From',
        isValid: (laycanDateFrom) => (laycanDateFrom.value !== ''),
        required: true,
        errorMessage: ''
    },
    'laycanDateTo': {
        value: initial.laycanDateTo? moment(initial.laycanDateTo) : '',
        label: 'Laycan Date To',
        isValid: (laycanDateTo) => (laycanDateTo.value !== ''),
        required: true,
        errorMessage: ''
    },
    'vesselInspector': {
        value: initial.vesselInspector,
        label: 'Inspector',
        disabled: initial.vesselInspector ? true : false,
        isValid: (vesselInspector) => (vesselInspector.value !== ''),
        required: initial.isSeller
    },
    'document': {
        value: initial.document,
        label: 'Upload Supporting Documents',
        required: false
    }
});

/**
 * VesselNominationForm
 *
 * @component
 * @example
 * return (
 *   <VesselNominationForm />
 * )
 */
const VesselNominationForm = ({ initialValues = {}, inspectors, onClose, onSubmit, onValidate, onReset, helper, children }) => {

    const [vesselForm, setVesselForm] = useState(initForm(initialValues));
    const [isValidated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});

    const handleOnChange = (e) => {
        setValidated(false);
        let value = e.target.value;
        let name = e.target.name;
        if (vesselForm[name]) {
            const update = { ...vesselForm };
            update[name].value = value
            setVesselForm(update);
        } else {
            console.error(`No parameter field ${name} present in vesselForm`)
        }
    }

    // Validates the form according to its validation rules
    const validate = () => {
        let errors = {};
        for (const [key, value] of Object.entries(vesselForm)) {
            if (typeof value.isValid === "function") {
                if (!value.isValid(value, vesselForm)) {
                    errors[key] = value.errorMessage;
                }
            }
        }
        setErrors(errors);
        const validated = Object.keys(errors).length === 0
        setValidated(validated);
        return validated
    }
    
    const serializeForm = () => {
        const values = {};
        for (const [key, formItem] of Object.entries(vesselForm)) {
            if (formItem.type === 'number') {
                values[key] = parseFloat(formItem.value)
            } else {
                values[key] = formItem.value
            }
        }
        return values
    }
    

    // Triggered by the cancel button : resets the form.
    const handleOnReset = (e) => {
        e.stopPropagation();
        setValidated(false);
        setVesselForm(initForm(initialValues));
        if (onReset) {
            onReset();
        }
    }
    
    // Triggered by validate button : validates the form and display confirmation or warning message.
    const handleOnValidate = (e) => {
        e.stopPropagation();
        if (validate()) {
            onValidate(serializeForm())
        }
    }

    // Triggered by submit button : validates the form and confirm submit
    const handleOnSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (validate()) {
            onSubmit(serializeForm());
        }
    }

    useEffect(() => {
        if (initialValues && initialValues !== {}) {
            setVesselForm(initForm(initialValues))
        }
    }, [initialValues])

    return (<form noValidate>
        { !isValidated && <div className="row">
            <div className="col-12 mb-3">
                <FormTextareaField
                    name='message'
                    value={vesselForm.message}
                    onChange={handleOnChange}
                    validation={errors}
                    customStyle={{
                        isInvalidClassName: "is-invalid select_error",
                        isValidClassName: 'is-valid',
                        wrapperClassName: "form-group",
                        labelClassName: "label",
                        inputClassName: "form-control"
                    }}
                />
            </div>
            <div className="col-6 my-1">
                <FormInputField
                    name='name'
                    value={vesselForm.name}
                    onChange={handleOnChange}
                    validation={errors}
                    customStyle={{
                        isInvalidClassName: "is-invalid select_error",
                        isValidClassName: 'is-valid',
                        wrapperClassName: "form-group",
                        labelClassName: "label",
                        inputClassName: "form-control"
                    }}
                />
            </div>
            <div className="col-6 my-1">
                <FormInputNumberFormat
                    name='nominateQuantity'
                    value={vesselForm.nominateQuantity}
                    thousandSeparator={true}
                    decimalScale={2}
                    allowNegative={false}
                    type='text'
                    isAllowed={(values) => {
                        const { floatValue, value } = values;
                        return value === "" || floatValue <= 100000;
                    }}
                    customStyle={{
                        isInvalidClassName: "is-invalid select_error",
                        isValidClassName: 'is-valid',
                        wrapperClassName: "form-group",
                        labelClassName: "label",
                        inputClassName: "form-control"
                    }}
                    onChange={val => handleOnChange({
                        target: {
                            name: 'nominateQuantity',
                            value: val
                        }
                    })}
                    validation={errors} />
            </div>
            <div className="col-6 my-1">
                <FormInputNumberFormat
                    name='shippingTolerance'
                    value={vesselForm.shippingTolerance}
                    thousandSeparator={true}
                    decimalScale={2}
                    allowNegative={false}
                    type='text'
                    isAllowed={(values) => {
                        const { floatValue, value } = values;
                        return value === "" || floatValue <= 100;
                    }}
                    onChange={val => handleOnChange({
                        target: {
                            name: 'shippingTolerance',
                            value: val
                        }
                    })}
                    customStyle={{
                        isInvalidClassName: "is-invalid select_error",
                        isValidClassName: 'is-valid',
                        wrapperClassName: "form-group",
                        labelClassName: "label",
                        inputClassName: "form-control"
                    }}
                    validation={errors} />
            </div>
            <div className="col-12 my-1">
                <FormDateFieldFromTo
                    minDate={moment()}
                    required={true}
                    itemStart={vesselForm.laycanDateFrom}
                    time={false}
                    onSelect={(name, value) => handleOnChange({
                        target: {
                            name: name,
                            value: value
                        }
                    })}
                    nameStart="laycanDateFrom"
                    nameEnd="laycanDateTo"
                    itemEnd={vesselForm.laycanDateTo}
                    popperPlacementStart = 'top-start'
                    popperPlacementEnd = 'top-end'
                    validation={errors}
                />
            </div>
            {(inspectors && vesselForm.isSeller) &&
                <div className="col-6 mt-3">
                    <FormSelectDropdown
                        customStyle={{
                            isInvalidClassName: "is-invalid select_error",
                            isValidClassName: 'is-valid',
                            selectClassName: "custom-select"
                        }}
                        validation={errors}
                        name='vesselInspector'
                        disabled={vesselForm.vesselInspector.disabled}
                        onSelect={handleOnChange}
                        items={inspectors}
                        value={vesselForm.vesselInspector}
                    />
                </div>}
{/*             <div className="col-6 my-1">
                <FormFileUpload
                    name='document'
                    value={vesselForm.document}
                    validation={errors}
                    onChange={(file) => handleOnChange({
                        target: {
                            name: 'document',
                            value: file
                        }
                    })}
                    onError='' />
            </div> */}
        </div>}
        
        {children}
        { isValidated && <div>
            {helper}
        </div> }
        { Object.keys(errors).length !== 0 && <div className="text-danger">
            Please fill the form in order to complete the vessel nomination
        </div>}
        
        <div className='modal-footer' style={{ alignItems: 'center', justifyContent: 'center' }}>
            {isValidated ?
                <button type="button" data-dismiss="modal" className="btn btn-secondary" onClick={handleOnReset} aria-label="Close">
                <FontAwesomeIcon icon={faTimes} />&nbsp;
                Cancel
            </button>
            :
                <button type="button" data-dismiss="modal" className="btn btn-secondary" onClick={onClose} aria-label="Close">
                <FontAwesomeIcon icon={faTimes} />&nbsp;
                Close
            </button>}
            {isValidated ?
                <button type="button" className="btn btn-primary" aria-label="Submit" onClick={handleOnSubmit}>
                <FontAwesomeIcon icon={faCheck} />&nbsp;
                Submit
            </button>
            :
                <button type="button" className="btn btn-primary" aria-label="button" onClick={handleOnValidate}>
                <FontAwesomeIcon icon={faCheck} />&nbsp;
                Validate
            </button>}
        </div>
    </form>)
}

VesselNominationForm.propTypes = {
    helper: PropTypes.elementType,
    children: PropTypes.elementType,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    inspectors: PropTypes.object
}

VesselNominationForm.defaultProps = {
    onClose: () => alert(`Go back to previous screen`),
    onValidate: (values) => alert(`Validates form values : ${JSON.stringify(values)}`),
    onSubmit: (values) => alert(`Submits form values : ${JSON.stringify(values)}`),
    initialValues: {},
    inspectors: {}
}

export default VesselNominationForm;