import React, { Component } from 'react';
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {push} from "connected-react-router";
import AccessControl, { INSPECTION_COMPANY, SHIPPING_COMPANY } from '../../components/AccessControl';
import { UserTypes } from '../../../utils/userTypes';

import {
  faInbox, faShip, faChartBar,
  faShoppingCart, faMoneyCheckAlt, faFunnelDollar, faLightbulb, faHandPointRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import "./index.scss";
import NumberFormat from 'react-number-format';// this is for prices in thousand formatter
//module
import {
  getUserDashboard
} from '../../../modules/module.account';

import {
  GetNotifications,
  DeleteNotification
} from '../../../modules/module.notifications';

//COMPONENTS
import Header from "../../components/header/Header";
import PositionChart from './PositionChart';
import RiceIndexGraph from './RiceIndexGraph';
import FloatingTransitMap from './FloatingTransitMap';
import Preloader from '../../components/preloader/Preloader';
import Notification from '../../components/header/Notification';
import moment from 'moment';
import Footer from '../../components/footer/Footer';


class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      scriptLoaded: false,
      scriptError: false
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getUserDashboard();
  }

  getRequestIdFromPath = (path) => {
    const splitParams = path.split('/');
    const detailsIndex = splitParams.findIndex(path => path === 'details');
    if (detailsIndex > -1) {
      return splitParams[detailsIndex + 1];
    }
  };

  onNotificationClick = (notificationId) => {
    return path => {
      // this.props.markRead(notificationId);
      const requestIdFromLink = this.getRequestIdFromPath(path);
      const requestId = this.getRequestIdFromPath(window.location.pathname);
      if (requestId === requestIdFromLink) {
        path += `${path.includes('?') ? '&' : '?'}forceUpdate=true`;
      }
      this.props.navigate(path);
    }
  };

  deleteNotification = id => {
    this.props.deleteNotification(id);
  };

  getYear = () => {
    return new Date().getFullYear();
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  render() {
    const dashboard = this.props.dashboard;
    return (
      <div>
        <Header />

        <div className="container-fluid content-height">
         <div className="news">
            {/* <div className="d-flex">
              <div className="news-heading text-uppercase">
               
              </div>
            
              <span className="news-text">
                <div className="scrolling-text">
                  <p>Welcome to Rice Exchange. Remember to connect to service providers in Admin Management before you start trading </p>
                </div>
              </span>
            </div> */}
          </div>
          <div className="overview">

            <div className="row">
              <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY, SHIPPING_COMPANY]} renderNoAccess={<div className="d-none" />}  >

                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 px-2">
                      <div className="box">
                        <div className="box-title">
                          <h4>MY SALES REQUESTS <small className="ml-2">{this.getYear()}</small></h4>
                        </div>

                        <div className="box-content">
                          <div className="tiles-text">
                            {dashboard === null ? 
                            '' 
                            :
                            <NumberFormat value={dashboard.mySales} 
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => <h1 title={value}>{value}</h1>} >
                            </NumberFormat>}
                            <small>in MT</small>
                          </div>

                        </div>
                        <div className="tiles-icon fa-w-16">
                          <FontAwesomeIcon icon={faChartBar} />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 px-2">
                      <div className="box">
                        <div className="box-title">
                          <h4>MY PURCHASE REQUESTS <small className="ml-1">{this.getYear()}</small></h4>
                        </div>
                        <div className="box-content">
                          <div className="tiles-text">
                            {dashboard === null ? 
                            '' : 
                            <NumberFormat value={dashboard.myPurchase} 
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => <h1 title={value}>{value}</h1>} >
                            </NumberFormat>}
                            <small>in MT</small>
                          </div>
                          <div className="tiles-icon fa-w-16 ">
                            <FontAwesomeIcon icon={faShoppingCart} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 px-2">
                      <div className="box">
                        <div className="box-title">
                          <h4>MY OPEN REQUESTS </h4>
                        </div>
                        <div className="box-content">
                          <div className="tiles-text">
                            {dashboard === null ? '' : 
                            <NumberFormat value={dashboard.myOpenRequest} 
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => <h1 title={value}>{value}</h1>} >
                            </NumberFormat>}
                            <small>in MT</small>
                          </div>
                          <div className="tiles-icon fa-w-16">
                            <FontAwesomeIcon icon={faInbox} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 px-2">
                      <div className="box">
                        <div className="box-title">
                          <h4>My Trades in Execution </h4>
                        </div>
                        <div className="box-content">
                          <div className="tiles-text">
                            {dashboard === null ?
                            '' 
                            : 
                            <NumberFormat value={dashboard.myTradesInExecution} 
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => <h1 title={value}>{value}</h1>} >
                            </NumberFormat>}
                            <small>in MT</small>
                          </div>
                          <div className="tiles-icon">
                            <FontAwesomeIcon icon={faShip} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 px-2">
                      <div className="box">
                        <div className="box-title">
                          <h4>My Total Payable </h4>
                        </div>
                        <div className="box-content">
                          <div className="tiles-text">
                            {dashboard === null ? '' : 
                            <NumberFormat value={dashboard.myTotalPayable} 
                              displayType={'text'}
                              thousandSeparator={true}
                              renderText={value => <h1 title={value}>{value}</h1>} >
                            </NumberFormat>}
                            <small>in US$</small>
                          </div>
                          <div className="tiles-icon">
                            <FontAwesomeIcon icon={faMoneyCheckAlt} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 px-2">
                      <div className="box">
                        <div className="box-title">
                          <h4>My Total Receivable </h4>
                        </div>
                        <div className="box-content">
                          <div className="tiles-text">
                            {dashboard === null ?
                            '' 
                            : 
                            <NumberFormat value={dashboard.myTotalReceivable} 
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => <h1 title={value}>{value}</h1>} >
                            </NumberFormat>}
                            <small>in US$</small>
                          </div>
                          <div className="tiles-icon">
                            <FontAwesomeIcon icon={faFunnelDollar} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccessControl>
              <div className="col-lg-3 col-md-6 px-2">
                <div className=" box box-exchange">
                  <iframe title="widget" src="//www.forextime.com/widgets/live-rates?theme=light&pairs=eurusdusdjpygbpusdusdcadaudusd&v=eu" frameBorder="0" width="100%" height="200px"></iframe>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 px-2">
                <div className="box box-notifications">
                  <div className="box-title-with-selectionfield ">
                    <h4 className="mb-0">Notifications</h4>
                    <span className="pull-right">
                      {/* <select className="form-control form-control-sm" style={{width: '90px'}}>
                    <option>Date</option>
                    <option>To Do</option>
                    <option>Assigned to me</option>
                    <option>Assigned by me</option>
                  </select> */}
                    </span>
                  </div>
                  <div className="box-content-with-selectionfield">
                    <div className="notification-activity-list">
                      <Preloader loadingStyle="swirl" loading={this.props.loading}>
                        {this.props.notifications && this.props.notifications
                          .sort((a, b) => moment.utc(b.date).diff(moment.utc(a.date)))
                          .filter(item => !item.deletePending)
                          .map((notification, index) =>
                          <div className="notification-element" key={notification.id}>
                            <div className="notification__section">
                              <Notification
                                {...notification}
                                onClick={this.onNotificationClick(notification.id)}
                                onDelete={this.deleteNotification.bind(this, notification.id)}/>
                            </div>
                          </div>
                        )}
                      </Preloader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="RiceIndex">
            <div className="row">
              <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY, SHIPPING_COMPANY]} renderNoAccess={<div className="d-none" />}  >

                <div className="col-lg-6 px-2">
                  <div className="box mh-299">
                    <div className="box-title-with-selectionfield">
                      <h4>Rice Index</h4>
                      {/* <span className="pull-right">
                  <select className="form-control form-control-sm ">
                    <option>Last Month</option>
                    <option>Month wise</option>
                  </select></span> */}
                    </div>
                    <div className="box-content">
                      <div><RiceIndexGraph dashboard={dashboard} /></div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 px-2">
                  <div className="box mh-299">
                    <div className="box-title ">
                      <h4><FontAwesomeIcon icon={faLightbulb} /> Tips </h4>
                    
                    </div>
                    <div className="box-content-with-selectionfield box-tips">

                      {/* <img src={commingSoon} width={300} alt="..." /> */}
                      {/* Coming Soon */}
                      <div className="notification-activity-list">
                        <div className="notification-element">
                          <div className="notification__section">
                            <div className="n-content">
                            <FontAwesomeIcon icon={faHandPointRight} /> Have you set up Admin Management and invited other users from your company? Select Admin Management from the drop down menu under your name in the top-right corner.
                              </div>
                          </div>
                        </div>
                        <div className="notification-element">
                          <div className="notification__section">
                            <div className="n-content">
                            <FontAwesomeIcon icon={faHandPointRight} /> Have you connected with service providers: Maersk, Cotecna and ISC? Select the Service Provider tab in Admin Management.
                              </div>
                          </div>
                        </div>
                        <div className="notification-element">
                          <div className="notification__section">
                            <div className="n-content">
                            <FontAwesomeIcon icon={faHandPointRight} /> After posting a trade request please log on to the platform regularly and check your notifications. At this stage notifications are not sent by email.
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 px-2">
                  <div className="box mh-299">
                    <div className="box-title">
                      <h4><FontAwesomeIcon icon={faLightbulb} /> Tips </h4>
                    </div>
                    <div className="box-content-with-selectionfield box-tips">

                      {/* <img src={commingSoon} width={300} alt="..." /> */}
                      {/* Coming Soon */}
                      <div className="notification-activity-list">
                        <div className="notification-element">
                          <div className="notification__section">
                            <div className="n-content">
                            <FontAwesomeIcon icon={faHandPointRight} /> When you counter a trade request the action cannot be seen by other users just by your counterparty. 
                              </div>
                          </div>
                        </div>
                        <div className="notification-element">
                          <div className="notification__section">
                            <div className="n-content">
                            <FontAwesomeIcon icon={faHandPointRight} /> We recommend that you always have a valid trade request on the platform as this will raise your profile on the platform. 
                              </div>
                          </div>
                        </div>
                        <div className="notification-element">
                          <div className="notification__section">
                            <div className="n-content">
                            <FontAwesomeIcon icon={faHandPointRight} /> We suggest that your set long validity dates for trade requests so all users have the opportunity to see your request.
                              </div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </AccessControl>
            </div>

          </div>
          <div className="Map">
            <div className="row">
              <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY, SHIPPING_COMPANY]} renderNoAccess={<div className="d-none" />}  >

                <div className="col-lg-6 px-2">
                  <div className="box">
                    <div className="box-title">
                      <h4>Country Wise Trade Volume</h4>
                    </div>

                    <div className="box-content">
                      <div className="row">
                        <div className="col-md-8">
                          <div><FloatingTransitMap dashboard={dashboard} /></div>
                        </div>


                      </div>

                    </div>

                  </div>
                </div>
              </AccessControl>
              <PositionChart
                dashboard={dashboard}
              />
            </div>

          </div>

        </div>

        <div className="footer-section">
          <Footer />
        </div>

      </div>

    );
  }
}

const mapStateToProps = state => ({
  dashboard: state.account.dashboard,
  notifications: state.notifications.messages.list,
  user: state.account.user,
  loading: state.loading.loadingNotifications
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserDashboard,
      getAllNotification: GetNotifications,
      deleteNotification: DeleteNotification,
      navigateTo: path => push('/'),
      navigate: path => push(path),
    },
    dispatch
  );

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Dashboard);



