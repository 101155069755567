import React from 'react';
import moment from 'moment-timezone';
import Preloader from '../../../../components/preloader/Preloader';

function getReportStatus(status) {
    switch (status) {
        case "CLEAN":
            return "Clean"
        case "UNCLEAN":
            return "Unclean"
        case "APPROVED_BY_BUYER":
            return "Approved by buyer"
        case "REJECTED_BY_BUYER":
            return "Rejected by buyer"
        default:
            return "Clean"
    }
}


function getReportStatusStyle(status) {
    switch (status) {
        case "CLEAN":
            return "badge badge-new"
        case "UNCLEAN":
            return "badge badge-warning"
        case "APPROVED_BY_BUYER":
            return "badge badge-success"
        case "REJECTED_BY_BUYER":
            return "badge badge-danger"
        default:
            return "Clean"
    }
}

export default function InspectionReportsItem(props) {
    return (
        <div className={props.highlightReport ? "inspection-reports__item highlight" : "inspection-reports__item"}>
            <span className="inspection-reports__item__name">{props.report.name}</span>
            <span className="inspection-reports__item__info">| {moment.utc(props.report.CreatedAt).local().format('DD/MM/YYYY HH:mm')}</span>
            <span className="inspection-reports__item__info">| </span>
            <span className={getReportStatusStyle(props.report.status)}>{getReportStatus(props.report.status)}</span>
            <span className="inspection-reports__item__preview-button">
                <button type="button" className="btn btn-link" onClick={props.onPreviewClick}>Preview</button>
            </span>
            {(props.isBuyer && !props.report.isClean && props.report.status === "UNCLEAN") &&
                (
                    <>
                        <span className="inspection-reports__item__preview-button">
                            <button type="button" className="btn btn-link" onClick={props.onApproveClick}>
                            <Preloader loadingStyle="overflow-spinner" loading={props.loading}>
                            <span>Approve</span>
                            </Preloader></button>
                        </span>
                        <span className="inspection-reports__item__preview-button">
                            <button type="button" className="btn btn-link" onClick={props.onRejectClick}>
                            <Preloader loadingStyle="overflow-spinner" loading={props.loading}>
                            <span>Reject</span>
                             </Preloader>
                            </button>
                        </span>
                    </>
                )}
        </div>
    )
}