import React from 'react';
import {Link} from "react-router-dom";
import moment from 'moment-timezone';

import { DATEFORMAT, CURRENCYOPT,TOOLTIP_TITLE } from '../../../../services/service.values';
import { renderSelectedOrigins, EnumsService } from  '../../../../services/service.utils';
import { RiceService } from '../../../../services/service.rice';
import CardOfferField from './CardOfferField';
import CardMultiOriginPrice from './CardMultiOriginPrice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from 'react-number-format';
import { Tooltip } from '@material-ui/core';
import { faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
import TradeQuantityFormatter from '../../../components/Formatter/TradeQuantityFormatter';
const Countries = EnumsService.countries();

class CardOffer extends React.Component {
    constructor(props){
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    updateTotalPrice = (enteredPrice) => {
        let totalPrice = enteredPrice;
        if(typeof this.props.bid.optionsPackagingTypePremOrDiscount === 'number'){
            totalPrice += this.props.bid.optionsPackagingTypePremOrDiscount;
        }
        if(typeof this.props.bid.optionsQualityTypePremOrDiscount === 'number' ){
            totalPrice += this.props.bid.optionsQualityTypePremOrDiscount 
        }
        if(typeof this.props.bid.optionsShippingTypePremOrDiscount === 'number'){
            totalPrice += this.props.bid.optionsShippingTypePremOrDiscount 
        }
        if(typeof this.props.bid.optionsTypeOfRicePremOrDiscount === 'number'){
            totalPrice += this.props.bid.optionsTypeOfRicePremOrDiscount 
        }
        return totalPrice.toFixed(2);
    }
    
    renderTotalPrice = () => {
        return this.updateTotalPrice(this.props.bid.price);
    }

    renderSingleOriginPrice = () => {
        const {price, bestPrice, currency} = this.props.bid;
        const currencySign = (currency === "EURO")?"€":currency;
        if(!price && bestPrice) return "Best Price";
        return(
            <NumberFormat 
                value={this.renderTotalPrice()} 
                displayType={'text'} 
                thousandSeparator={true} 
                prefix={currencySign +' '} />
        )
    }

    renderMultiOriginPrice = () => {
        const {multipleOriginPrice, origin, currency, price, bestPrice} = this.props.bid;
        if(multipleOriginPrice && multipleOriginPrice.length > 1){
            return origin.split(',').map(item => {
                let isExist = multipleOriginPrice.find(x => x.origin === item);
                return isExist.isPriceCheck ? (
                    <NumberFormat
                        key={item}
                        displayType="text" 
                        value={isExist.price}  
                        renderText={text => <div>{text}</div>} 
                        thousandSeparator={true}
                        suffix={` ${CURRENCYOPT[currency] || currency} (${Countries[isExist.origin]})`}/>
                ):(
                    <div key={item}>-</div>
                ) 
            })
        }else{
            return origin.split(',').map(item => {
                if(!price && bestPrice){
                    return <div key={item}>Best Price ({Countries[item]})</div>
                }
                return(
                    <NumberFormat
                        key={item}
                        displayType="text" 
                        value={price}  
                        renderText={text => <div>{text}</div>} 
                        thousandSeparator={true}
                        suffix={` ${CURRENCYOPT[currency] || currency} (${Countries[item]})`}
                    />
                )
            })
        }
    }

    handleOnClick(){
        this.props.onClick(this.props.bid.ID);
    }

    getInspectionCompanyName = (companyId,inspectionOther) => {
        let companyName = "";
        if (this.props.inspections && companyId) {
            companyName = this.props.inspections[companyId.trim()];
            if (companyId === "1") {
                return <>
                    <Tooltip title={TOOLTIP_TITLE.NON_INSPECTOR_TITLE} placement="top-start" arrow>
                        <span>{inspectionOther}&nbsp;
                        <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} /></span>
                    </Tooltip>
                </>
            }
            else {
                return companyName;
            }
        }
    }

    render() {

        const {
            currency,
            origin,
            fromCompanyID,
            toCompanyID,
            ownerId,
            price, 
            shipping,
            riceType,
            riceTypeOther,
            quality,
            incoterm,
            deliveryStartDate,
            deliveryEndDate,
            sellerId,
            seller,
            buyerId,
            buyer,
            fromCompanyName,
            toCompanyName,
            optionsPackagingTypePremOrDiscount,
            optionsPackagingType,
            optionsQualityTypePremOrDiscount,
            optionsQualityType,
            optionsShippingTypePremOrDiscount,
            optionsShippingType,
            optionsTypeOfRicePremOrDiscount,
            optionsTypeOfRice,
            status,
            inspection,
            inspectionOther
        } = this.props.bid;

        const {linkToTrade, companyId} = this.props;
        const currencySign = (currency === "EURO")?"€":currency;

        let counterparty = "";
        let counterpartyId = "";
        let recieved;
        //Case Trade
        if(ownerId){
            if(ownerId === sellerId){
                counterparty = seller;
                counterpartyId = sellerId;
            }
            if(ownerId === buyerId){
                counterparty = buyer;
                counterpartyId = buyerId;
            }   
        }
        //Case Bid
        if(companyId){
            if(companyId === fromCompanyID){
                counterparty = toCompanyName;
                counterpartyId = toCompanyID;
                recieved = false;
            }else{
                recieved = true;
                counterparty = fromCompanyName;
                counterpartyId = fromCompanyID
            }
        }

        return  <div className="compare-offer">
            <div className="compare-wrapper px-3 py-3" >
                    { status === "DECLINED" ?
                        <span style={{backgroundColor: '#ff3223'}} className="status">{'declined'}</span>
                    :
                    <>
                        { (!ownerId && recieved) &&
                        <span style={{backgroundColor: '#36af47'}} className="status">{'received from'}</span>}
                        { (!ownerId &&  !recieved) &&
                        <span style={{backgroundColor: '#7b7b7b'}} className="status">{'sent to'}</span>}

                    </>
                    }
                    <div className="text-right">
                        {this.props.onClick && 
                        <button className="btn btn--blue btn-sm" onClick={this.handleOnClick}>
                            <FontAwesomeIcon icon={faEyeSlash}/> Minimize
                        </button>}
                        {linkToTrade &&
                            <Link to={linkToTrade}>
                                <button className="btn btn--blue btn-sm">Initial request</button>
                            </Link>}
                    </div>
                {this.props.showMultiOriginPrice ? (
                    <CardMultiOriginPrice heading={true}
                        fieldName={ counterparty }
                        fieldValue={this.renderMultiOriginPrice()}
                        counterpartyId={counterpartyId}/>
                ) : (
                    <CardOfferField heading={true}
                        fieldName={ counterparty }
                        fieldValue={this.renderSingleOriginPrice()}
                        counterpartyId={counterpartyId}
                    />
                )}
                
                <CardOfferField fieldName="Vessel or Container"
                    fieldValue={shipping}/>
                <CardOfferField fieldName="Quantity (expressed in MT)"
                    fieldValue={<TradeQuantityFormatter trade={this.props.bid} />}/>
                <CardOfferField fieldName="Type of Rice"
                    fieldValue={RiceService.getByRef(riceType) ? RiceService.getByRef(riceType).label : riceType}/>
                {riceTypeOther &&
                <CardOfferField fieldName="Specity type "
                    fieldValue={riceTypeOther}/>}
                <CardOfferField fieldName="Inspection Company"
                    fieldValue={this.getInspectionCompanyName(inspection,inspectionOther)}/>
                {!this.props.showMultiOriginPrice &&
                <CardOfferField fieldName="Origin"
                    fieldValue={renderSelectedOrigins(origin)}/>}
                <CardOfferField fieldName="Quality Standard"
                    fieldValue={quality}/>
                <CardOfferField fieldName="Incoterm"
                    fieldValue={incoterm}/>
                <CardOfferField fieldName="Shipping Period" 
                    fieldValue={
                        <React.Fragment>
                            <span>From {moment(deliveryStartDate).format(DATEFORMAT)}</span>
                            <span>&nbsp;</span>
                            <span>To {moment(deliveryEndDate).format(DATEFORMAT)}</span>
                        </React.Fragment>}/>
                {this.props.tradePrivacyType !== "TENDER" &&
                    <>
                        <CardOfferField fieldName="Total Price"
                            fieldValue= {<React.Fragment><NumberFormat value={this.renderTotalPrice()} displayType={'text'} thousandSeparator={true} suffix={' ' +currencySign} /></React.Fragment>}/>
                        <div className="card mt-3">
                            <div className="card-body">
                                <CardOfferField className="mt-0" 
                                    fieldName="Base Price"
                                    fieldValue={<React.Fragment>
                                        <div>{`${currencySign} ${price>0?"+":""}`}
                                        <React.Fragment><NumberFormat value={price} displayType={'text'} thousandSeparator={true}/></React.Fragment>
                                        </div>
                                        <div></div>
                                    </React.Fragment>}/>
                                <CardOfferField className="mt-2" 
                                    fieldName="Quality Standard"
                                    fieldValue={<React.Fragment>
                                        <div>{(optionsQualityType)?optionsQualityType:"No option"}</div>
                                        <div>{`${currencySign} ${optionsQualityTypePremOrDiscount>0?"+":""}${optionsQualityTypePremOrDiscount || 0}`}</div>
                                    </React.Fragment>}/>
                                <CardOfferField className="mt-2" 
                                    fieldName="Container"
                                    fieldValue={<React.Fragment>
                                        <div>{(optionsShippingType)?optionsShippingType:"No option"}</div>
                                        <div>{`${currencySign}  ${optionsShippingTypePremOrDiscount>0?"+":""}${optionsShippingTypePremOrDiscount || 0}`}</div>
                                    </React.Fragment>}/>
                                <CardOfferField className="mt-2" 
                                    fieldName="Packaging"
                                    fieldValue={<React.Fragment>
                                        <div>{(optionsPackagingType)?optionsPackagingType:"No option"}</div>
                                        <div>{`${currencySign}  ${optionsPackagingTypePremOrDiscount>0?"+":""}${optionsPackagingTypePremOrDiscount || 0}`}</div>
                                    </React.Fragment>}/>
                                <CardOfferField className="mt-2" 
                                    fieldName="Type of Rice"
                                    fieldValue={<React.Fragment>
                                        <div>{(optionsTypeOfRice)?optionsTypeOfRice:"No option"}</div>
                                        <div>{`${currencySign}  ${optionsTypeOfRicePremOrDiscount>0?"+":""}${optionsTypeOfRicePremOrDiscount || 0}`}</div>
                                    </React.Fragment>}/>
                            </div>
                        </div>
                    </>}       
            </div>
        </div>

    }
}

export default CardOffer;
