import React from 'react';
import DetailTableDivision from './DetailTableDivision'


/**
 * Short description. (use period)
 *
 * @since  05/02/20
 * @access public
 *
 * @type     React.Component
 * @property {String} cellList : List of cells.
 * @property {String} className : class name of the row (<tr> element ).
 * @property {Boolean} fixWidth : Indicates if column width should be equal
 *
 */
class DetailTableRow extends React.Component{

    render(){
        const {cellList, className, fixWidth=true, minWidth="80px",} = this.props; 
        let style = {minWidth: minWidth};
        if(fixWidth){
            style.width = (cellList && cellList.length!==0)?`${100/cellList.length}%`:minWidth;
        }
        
        if(cellList && cellList.length!==0){
            return <tr className={className}> 
                        {cellList.map((cell,index) => 
                        <DetailTableDivision
                            key={`detail-division-${index}`}
                            fixWidth={fixWidth}
                            index={index}
                            style={style}
                            className={cell.className}
                            content={cell.content}/>)}
                    </tr>
        }else{
            return null;
        }
    }
}

export default DetailTableRow;