import Form from '@rjsf/core'
import { push } from 'connected-react-router'
import { observer } from 'mobx-react-lite'
import { GetVesselNomination } from 'modules/module.trade'
import { PropTypes } from 'prop-types'
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { useSelector } from 'react-redux'

import NominatedVesselInfo from '../NominatedVesselInfo'
import MultipleShipmmentDetail from './MultipleShipmmentDetail'
import ShipmentRecapTable from './ShipmentRecapTable'
import vesselInfoTabStore from './VesselInfoTab.store'
import Modal from 'app/components/modal/Modal'
import Preloader from 'app/components/preloader/Preloader'
import { getShipmentList } from 'constants/shipmentRecapTableConstants'
import { STEPS, TRADE_STATUS } from 'services/service.values'
import { TradeApi, smartApi } from 'services/service.api'
import { getPartialShipments } from 'modules/module.trade'

const { createChildTrade } = TradeApi

/**
 * @author Jean-Marc Cheynier <jean-marc.cheynier@fujitsu.com>
 * @version RDEV-2063
 * VesselInfoTab component shows the balance of the shipments.
 * @param  {string} parentId - The ID of the parent trade
 * @returns {Component} VesselInfoTab component, component to be foud under the Vessel Tab for trades with multiple shipments
 */
const VesselInfoTab = ({
  pointOfView,
  parentId,
  tradeMap,
  trade,
  loadingVesselDetails,
  vesselNominationMap,
  shipmentMap,
  getPartialShipments,
}) => {
  const parent = tradeMap[parentId]
  const [nominationFullfilled, setNominationFullfilled] = useState(false)
  const divRref = useRef(null)

  useEffect(() => {
    // if notification was recently clicked
    if (!window.notificationClicked) return

    // and if referenced element exists
    if (!divRref.current) return
    if (!divRref.current.scrollIntoView) return

    // and if pathname is correct
    if (!window.location.search.includes('?substituteVessel')) return

    // and if data is available
    if (!vesselNominationMap[trade.id]) return

    // then scroll the substituteVessel into the viewport
    divRref.current.scrollIntoView({ behavior: 'smooth' })
  })

  const shipmentList = useMemo(() => {
    return getShipmentList(tradeMap, parent, vesselNominationMap, shipmentMap)
  }, [tradeMap, parent, vesselNominationMap, shipmentMap])

  const previousNominationComplete = useMemo(() => {
    let res = false
    if (parent) {
      if (parent.childTradeRequestID.length !== 0) {
        res = true
        for (let childId of parent.childTradeRequestID) {
          if (
            tradeMap[childId] &&
            tradeMap[childId].status !== 'CANCELED' &&
            (!vesselNominationMap[childId] ||
              !vesselNominationMap[childId].vesselAccepted)
          ) {
            res = false
            break
          }
        }
      } else {
        res =
          vesselNominationMap[parentId] &&
          vesselNominationMap[parentId].vesselAccepted
      }
    }
    return res
  }, [parent, vesselNominationMap])

  const handleOnTableUpdate = ({ balance }) => {
    vesselInfoTabStore.set('balance', balance)
    setNominationFullfilled(balance.max === 0)
  }

  const vesselNomination = vesselNominationMap[trade.id]

  const showNewChildButton =
    parent &&
    parent.partialShipmentFlag &&
    previousNominationComplete &&
    !nominationFullfilled &&
    ((pointOfView === 'buyer' && parent.incoterm === 'FOB') ||
      (pointOfView === 'seller' && ['CIF', 'CFR'].includes(parent.incoterm))) &&
    (STEPS.indexOf(trade.status) <= STEPS.indexOf(TRADE_STATUS.ADVICE) ||
      parent.status == 'MULTIPLE_SHIPMENTS' ||
      parent.status === 'CONTRACT_FULFILL_REJECT')

  return (
    <React.Fragment>
      {parentId && (
        <MultipleShipmmentDetail
          parentId={parentId}
          redirect="nominatedVesselInfo"
        />
      )}
      <div className="py-2 px-3">
        <label className="label h6">Shipments</label>
        <Preloader loadingStyle="swirl" loading={loadingVesselDetails}>
          <ShipmentRecapTable
            parentTrade={parent}
            shipmentList={shipmentList}
            onTableUpdate={handleOnTableUpdate}
          />
        </Preloader>
      </div>

      {vesselNomination && (
        <div className="py-2 px-3" ref={divRref}>
          {vesselNomination.rejectMessage ? (
            <strong className="text-danger h6">
              Vessel Nomination Rejected
            </strong>
          ) : (
            <label className="label h6">
              Vessel Nomination<small>{` (Ref: ${trade.tradeRefId})`}</small>
            </label>
          )}
          <NominatedVesselInfo
            tradeId={trade.id}
            inspectionName={trade.inspectionName}
            whoItis={pointOfView}
          />
        </div>
      )}

      {showNewChildButton && (
        <OnCreateChildTrade
          {...{ parent, shipmentList, getPartialShipments }}
        />
      )}
    </React.Fragment>
  )
}

const { showModal, hideModal } =  vesselInfoTabStore

const onFormChange = ({ formData }) => {
  const data = { ...formData }

  if (vesselInfoTabStore.isVessel) {
    // TODO: implement inspector dropdown select options
    //data.vesselInspector = '4a1f1b40-8271-11eb-9cf3-935bfea763d0'
    data.vesselInspector = 'Inspector'
  } else {
    // TODO: implement dynamically updating calculated fields via configuration
    const { quantityPerContainer, noOfContainer } = data
    data.nominateQuantity =
      parseFloat(quantityPerContainer, 10) > 0 &&
      parseFloat(noOfContainer, 10) > 0
        ? Number(quantityPerContainer * noOfContainer)
        : 0
  }
  vesselInfoTabStore.set('formData', data)
}

const ReactDatePicker = (props) => {
  const [startDate, setStartDate] = useState('')

  const DatePickerInput = forwardRef(
    (
      {
        value,
        readonly,
        disabled,
        autofocus,
        onChange,
        onBlur,
        onClick,
        onFocus,
        id,
      },
      ref
    ) => (
      <input
        className="form-control"
        readOnly={readonly}
        disabled={disabled}
        autoFocus={autofocus}
        value={value == null ? '' : value}
        ref={ref}
        onChange={onChange && ((ev) => onChange(id, ev.target.value))}
        onBlur={onBlur && ((ev) => onBlur(id, ev.target.value))}
        onFocus={onFocus && ((ev) => onFocus(id, ev.target.value))}
        onClick={onClick && ((ev) => onClick(id, ev.target.value))}
      />
    )
  )

  DatePickerInput.displayName = 'DatePickerInput'

  return (
    <>
      <label className="control-label">
        {props.schema.title}
        <span className="required">*</span>
      </label>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date)
          const field = props.name

          // new form data object
          const data = {
            // copy existing data
            ...vesselInfoTabStore.formData,
            // override existing date field
            [field]: moment(date)
              .startOf('day')
              .toISOString(),
          }

          vesselInfoTabStore.set('formData', data)
        }}
        customInput={<DatePickerInput />}
      />

      {props.errors && <div className="invalid-feedback">{props.errors}</div>}
    </>
  )
}

const fields = { dpDate: ReactDatePicker }

const onFormSubmit = async (data) => {
  vesselInfoTabStore.set('loading', true)

  const { formData } = data
  formData.isSeller = vesselInfoTabStore.isSeller

  // TODO: this should return id of the selected inspector
  if (formData.vesselInspector) {
    formData.vesselInspector = '4a1f1b40-8271-11eb-9cf3-935bfea763d0'
  }

  const newChild = await createChildTrade(vesselInfoTabStore.parentId)
  const child = newChild.data.childTradeRequestID

  await smartApi(`/trade/${child}/smart`).nominateVessel(formData)

  hideModal()
  vesselInfoTabStore.set('loading', false)

  vesselInfoTabStore.set('fetchPartialShipments', true)
}

const Warning = observer(() => (
  <div className="px-3">{vesselInfoTabStore.warning}</div>
))

const GetPartialShipments = observer(({ getPartialShipments }) => {
  const { requestLoading } = useSelector((state) => state.loading)

  // Refresh the tradeMap with the list of child trades
  useEffect(
    () =>
      setTimeout(() => getPartialShipments(vesselInfoTabStore.parentId), 500),
    [vesselInfoTabStore.parentId, getPartialShipments]
  )

  return requestLoading ? <Preloader loadingStyle="swirl" loading /> : null
})

export const ModalForm = observer(({ parent, shipmentList }) => {
  const trade = useSelector((state) => state.trade)
  const user = useSelector((state) => state.account.user)
  const companyId = useSelector((state) => state.account.token.companyId)

  useEffect(() => {
    if (!vesselInfoTabStore) return
    if (!vesselInfoTabStore['set']) return
    const set = vesselInfoTabStore.set

    // clone data from legacy state to MST
    set('trade', trade)
    set('user', user)
    set('companyId', companyId)
  }, [parent, trade, user, companyId])

  return (
    <Modal
      onClose={hideModal}
      title={vesselInfoTabStore.modalTitle}
      components={{
        footer: false,
      }}>
      {shipmentList && <ShipmentRecapTable parentTrade={parent} shipmentList={shipmentList} />}
      {vesselInfoTabStore.uiSchema && vesselInfoTabStore.schema && (
        <Form
          fields={fields}
          uiSchema={vesselInfoTabStore.uiSchema}
          schema={vesselInfoTabStore.schema}
          formData={vesselInfoTabStore.formData}
          onChange={onFormChange}
          onSubmit={onFormSubmit}
          onError={(e) => console.log('errors', e)}>
          <Warning />
          <div
            className="modal-footer"
            style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Cancel onClick={hideModal} />
            <Submit />
          </div>
        </Form>
      )}
    </Modal>
  )
})

const Submit = observer(() => (
  <Preloader loadingStyle="swirl" loading={vesselInfoTabStore.loading}>
    <button type="submit" className="btn btn-primary" aria-label="Submit">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="check"
        className="svg-inline--fa fa-check fa-w-16 "
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
      </svg>
      &nbsp;Submit
    </button>
  </Preloader>
))

const Cancel = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="btn btn-secondary"
    aria-label="Close">
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="times"
      className="svg-inline--fa fa-times fa-w-11 "
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 352 512">
      <path
        fill="currentColor"
        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
    </svg>
    &nbsp; Cancel
  </button>
)

const OnCreateChildTrade = observer(
  ({ parent, shipmentList, loadingRequestCreation, getPartialShipments }) => (
    <>
      {vesselInfoTabStore.fetchPartialShipments && (
        <GetPartialShipments {...{ getPartialShipments }} />
      )}
      <div className="py-2 px-3 text-right">
        <button
          className="trades-dtls__button"
          type="button"
          disabled={loadingRequestCreation}
          onClick={showModal}>
          <Preloader loadingStyle="dots" loading={loadingRequestCreation}>
            <span>Nominate a Next Vessel</span>
          </Preloader>
        </button>
      </div>
      {vesselInfoTabStore.show && <ModalForm {...{ parent, shipmentList }} />}
    </>
  )
)

VesselInfoTab.propTypes = {
  vesselNominationMap: PropTypes.object,
}

VesselInfoTab.defaultProps = {
  vesselNominationMap: {},
}

const mapStateToProps = (state) => {
  return {
    trade: state.trade.items.single,
    tradeMap: state.trade.items.tradeMap,
    vesselNomination: state.vesselNomination,
    vesselNominationMap: state.trade.vesselNominationMap,
    synthesis: state.shipment.synthesis,
    shipmentMap: state.trade.shipmentMap,
    loadingRequestCreation: state.loading.requestCreation,
    loadingVesselDetails: state.loading.loadingVesselDetails,
    triggerNominationUpdate: state.trade.triggerNominationUpdate,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      GetVesselNomination,
      createChildTrade,
      getPartialShipments,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(VesselInfoTab)
