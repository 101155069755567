import React from 'react';
import NumberFormat from 'react-number-format';
import './FormUnitsInputField.scss';

const defaultStyle = {
    labelClassName :"label",
    wrapperClassName : "form-date",
    inputClassName : "input",
    isValidClassName: "",
    isInvalidClassName: "input_error"
}; 


export default class FormUnitsInputField extends React.Component {

    constructor(props) {
        super(props);
        const value = this.props.value.value;
        this.state = {
            initialValue: (!!value)? value : '',
            initialValueSet: false
        };

    }

    componentDidUpdate() {
        const value = this.props.value.value;
        if ((!this.state.initialValueSet && !!value) || (value !== this.state.initialValue)) {
            this.setState({initialValue: value, initialValueSet: true});
        }
    }

    render() {
        const {
            name,
            value,
            dropValue,
            items,
            validation,
            customStyle,
            onChange,
            onSelect,
            disabled = false,
            unitsDisabled = false,
            inputDisabled = false,
            dropName,
            ...rest
        } = this.props;

        const style = { ...defaultStyle, ...customStyle};
        const isInvalid = validation && validation.hasOwnProperty(name);
        const inputValue = value.value;

        return (

            <div className="form-group">
                <label className="label" htmlFor="amount">
                    {value.label}
                    {value.required && <span className="req-field">&nbsp;*</span>}
                </label>
                <div className="input-group mb-2">
                    <NumberFormat value={inputValue} 
                        displayType={'input'} 
                        className={`${style.inputClassName} ${isInvalid ? style.isInvalidClassName :  value.touched || value.validated ? style.isValidClassName : ''}`}
                        id={name}
                        name={name}
                        onValueChange={values => onChange(values.value)}
                        required={!!value.required}
                        disabled={disabled || inputDisabled}
                        {...rest}/>
                    <div className="input-group-append">
                        <select className="custom-select curreny-select" 
                            value={dropValue.value} 
                            name={dropName}
                            onChange={ e => { onSelect(e.target.value)}}
                            disabled={disabled || unitsDisabled}>
                            {Object.keys(items).map(i => {
                                return (
                                    <option key={i} value={i}>{items[i]}</option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}