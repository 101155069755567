import React, { Component } from 'react';

import {
    FormRow,
} from '../../Form';

import FormInputField from '../../form/FormInputField';
import FormPhoneField from '../../form/FormPhoneField';

export class BankDetails extends Component {

    render() {

        let { state, setField, validation } = this.props;
        return (
            <div>
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation}
                            name="name"
                            value={state.bank.name}
                            onChange={e => setField(e.target.name, e.target.value, "bank")}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation}
                            name="managerName"
                            value={state.bank.managerName}
                            onChange={e => setField(e.target.name, e.target.value, "bank")}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation}
                            name="email"
                            value={state.bank.email}
                            onChange={e => setField(e.target.name, e.target.value, "bank")}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <FormInputField
                            validation={validation}
                            name="address"
                            required={true}
                            value={state.bank.address}
                            onChange={e => setField(e.target.name, e.target.value, "bank")}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col-12 col-md-6 mb-3">
                        <FormPhoneField
                            value={state.bank.phone}
                            name="phone"
                            validation={validation}
                            onChange={value => setField('phone', value, "bank")}
                        />
                    </div>
                </FormRow>
            </div>
        );
    }
}
