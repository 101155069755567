import React, { Component } from 'react';
import FormInputGroupField from '../../../../components/form/FormInputGroupField';
import FormInputField from '../../../../components/form/FormInputField';
import FormInputNumberFormat from '../../../../components/form/FormInputNumberFormat';
import FormSelectDropdown from '../../../../components/form/FormSelectDropdown';
import FormSelectDropdownList from '../../../../components/form/FormSelectDropdownList';
import FormTextareaField from '../../../../components/form/FormTextareaField';
import FormDateField from '../../../../components/form/FormDateField';
import FormUnitsInputField from '../../../../components/form/FormUnitsInputField';
import FormAsyncSelectPort from '../../../../components/form/FormAsyncSelectPort';

import scrollToElement from 'scroll-to-element';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

//ACTIONS
import {
    preloadInspectionCompanies,
} from '../../../../../modules/module.trade';
import { EnumsService, getLocalTimezone } from '../../../../../services/service.utils';
import { RiceService } from '../../../../../services/service.rice';
import { filterInspectionCompany } from '../../../../../services/service.utils';

//CSS
import './RequestContract.scss';

//values
import {
    DATEFORMATHOURS,
    DATEFORMAT,
    QUALITYSTANDARD,
    PAYMENTTERMSOPT,
    PAYMENTPERIODOPT,
    DOWNPAYMENTPERIODOPT,
    INCOTERMOPT,
    DISCHARGEFOB,
    DISCHARGECIF,
    SHIPPINGTYPES,
    PACKAGING,
    CURRENCYOPT,
    CROP_YEARS,
    RICECATEGORY,
    getNonIntegratedInspector,
    TOOLTIP_TITLE,
    TOLERANCE
} from '../../../../../services/service.values';
import FormRadioFieldTrueFalse from '../../../../components/form/FormRadioFieldTrueFalse';
import DocumentSelection from '../../../requests/create/documentSelection/DocumentSelection'
import { DocumentFactory } from '../../../trades/services/documents.service';

const Countries = EnumsService.countries();
const erroMessage = 'Before clicking on "REVIEW", please confirm insertion of your document or click on cancel';
class RequestContract extends Component {
    FORM_CREATE_WRAPPER_CLASS = "col-sm-6 col-md-4 col-lg-3";
    state = {
        request: {},
        required: {},
        initiated: false,
        showInsuranceTerms: false,
        isValidCustomdDoc: false,
        isValidDoc: true
    }

    constructor(props) {
        super(props)
        this.onReview = this.onReview.bind(this);
        this.isRiceTypeOtherInvalid = this.isRiceTypeOtherInvalid.bind(this);
        this.validate = this.validate.bind(this);
        this.addDocuments = this.addDocuments.bind(this);
        this.onValid = this.onValid.bind(this);
        preloadInspectionCompanies();

        this.CUSTOM_COMPONENT_STYLE = {
            wrapperClassName: "form-group",
            labelClassName: "label",
            inputClassName: "form-control",
            selectClassName: "custom-select",
            radioColLayout: { left: "col-12", right: "col-12" },
            isInvalidClassName: "is-invalid select_error",
            isValidClassName: 'is-valid',
        }
    }

    componentDidMount() {
        this.initializeForm(this.props.term);
    }

    setCountriesValues() {
        let arrayOfCountries = [];
        const allowCountries = {
            KH: 'Cambodia',
            TH: 'Thailand',
            VN: 'Vietnam',
            MM: 'Myanmar',
            IN: 'India',
            CN: 'China, People\'s Republic of',
            PK: 'Pakistan',
            ES: 'Spain',
            FR: 'France',
            GR: 'Greece',
            IT: 'Italy',
            EG: 'Egypt',
            US: 'United States',
            BR: 'Brazil',
            GY: 'Guyana',
            UY: 'Uruguay',
            PY: 'Paraguay',
            AR: 'Argentina',
            SR: 'Suriname'
        };
        Object.keys(allowCountries).map(i => {
            arrayOfCountries.push({
                [i]: Countries[i]
            });
            return false;
        });
        arrayOfCountries.sort(function (a, b) {
            if (a[Object.keys(a)[0]].toUpperCase() < b[Object.keys(b)[0]].toUpperCase()) {
                return -1;
            }
            if (a[Object.keys(a)[0]].toUpperCase() > b[Object.keys(b)[0]].toUpperCase()) {
                return 1;
            }
            // names must be equal
            return 0;
        });
        let result = {};

        arrayOfCountries.map(i => {
            result[Object.keys(i)[0]] = i[Object.keys(i)[0]];
            return false;
        });
        this.setState({
            countries: result
        });
    }


    // loadCounter(){
    //     this.props.onReview(this.state.request)
    // }

    isEmpty = value => !value || value === undefined || value === '';

    isRiceTypeOtherInvalid = (value) => { this.state.request.riceType.value === RiceService.enums.REF_OTHER && this.isEmpty(value) }


    initializeForm(trade) {
        this.setCountriesValues();
        const requestType = this.props.trade.requestType || '';
        this.initField('requestType', 'Request Type', requestType, this.isEmpty);
        const currency = trade.currency || "US$";
        this.initField('currency', '', currency, this.isEmpty);
        const price = trade.price || '';
        this.initField('price', 'Your Price per MT', price, entry => this.isEmpty(entry) || parseFloat(entry) === 0);
        const measurement = trade.measurement || 'TONS';
        this.initField('measurement', '', measurement, this.isEmpty);
        const measure = trade.measure || "";
        this.initField('measure', 'Quantity (expressed in MT)', measure, this.isEmpty);
        this.initField('contractValidateDate', `Validity of your Term (${getLocalTimezone()})`, '',   value => { return this.isEmpty(value) || moment().diff(value) > 0 });
        const euroConversionAvl = trade.euroConversionAvl || false;
        this.initField('euroConversionAvl', 'Euro Conversion Available', (euroConversionAvl === true), false);
        const standardContract = trade.standardContract ? trade.standardContract : true;
        this.initField('standardContract', 'Standard Contract', standardContract, false);
        const shipping = trade.shipping || '';
        this.initField('shipping', 'Shipping Type', shipping, this.isEmpty);
        const partialShipmentFlag = trade.partialShipmentFlag || false;
        this.initField('partialShipmentFlag', 'Partial Shipment Allowed', partialShipmentFlag, false, 'step3');

        const origin = trade.origin;
        this.initField('origin', 'Origin', origin, this.isEmpty);
        const riceType = trade.riceType || "";
        this.initField('riceType', 'Type of Rice', riceType, this.isEmpty);
        const riceTypeOther = trade.riceTypeOther || "";
        this.initField('riceTypeOther', 'Specify Rice Type', riceTypeOther, this.riceTypeOtherInvalid);
        const riceCategory = trade.riceCategory || "";
        this.initField('riceCategory', 'Category of Rice', riceCategory, this.isEmpty);
        const quality = trade.quality || 'Standard';
        this.initField('quality', 'Quality Standard (Base Price)', quality, this.isEmpty);
        const cropYear = trade.cropYear;
        this.initField('cropYear', 'Crop Year', cropYear, this.isEmpty);
        const incoterm = trade.incoterm;
        this.initField('incoterm', 'Incoterms', incoterm, this.isEmpty);
        const loadCountry = trade.loadCountry;
        this.initField('loadCountry', 'Country of Load', loadCountry, false);
        const loadPort = trade.loadPort;
        this.initField('loadPort', 'Port of Load', loadPort, false);
        const destCountry = trade.destCountry;
        this.initField('destCountry', 'Destination', destCountry, false);
        const destPort = trade.destPort;
        this.initField('destPort', 'Port of Destination', destPort, false);
        const insuranceRequired = trade.insuranceRequired || false;
        this.initField('insuranceRequired', 'Do You want a Ricex Dedicated Insurance Quote?', insuranceRequired, false);
        const destinationZone = trade.destinationZone;
        const destinationZoneInvalid = insuranceRequired === "true" ? this.isEmpty : false;
        this.initField('destinationZone', 'Select Destination Zone', destinationZone, destinationZoneInvalid);
        const typeOfCover = trade.typeOfCover;
        const typeOfCoverInvalid = insuranceRequired === "true" ? this.isEmpty : false;
        this.initField('typeOfCover', 'Select the Type of Cover', typeOfCover, typeOfCoverInvalid);
        const deliveryStartDate = trade.deliveryStartDate;
        this.initField('deliveryStartDate', 'Shipping / Delivery Period (from)', moment(deliveryStartDate), this.isEmpty);
        const deliveryEndDate = trade.deliveryEndDate;
        this.initField('deliveryEndDate', 'Shipping / Delivery Period (to)', moment(deliveryEndDate), this.isEmpty);
        const packaging = trade.packaging || ''
        if (PACKAGING.hasOwnProperty(packaging)) {
            this.initField('packaging', 'Packaging (Base Price)', packaging, this.isEmpty, 'step3');
            this.initField('packagingOther', 'Packaging', "", (value) => { return this.state.request.packaging.value === 'OTHER' && value === "" });
        } else {
            this.initField('packaging', 'Packaging (Base Price)', 'OTHER', this.isEmpty, 'step3');
            this.initField('packagingOther', 'Packaging', packaging, (value) => { return this.state.request.packaging.value === 'OTHER' && value === "" });
        }
        const inspection = trade.inspection || '';
        this.initField('inspection', 'Inspection Company', inspection, this.isEmpty);
        const inspectionOther = trade.inspectionOther || '';
        this.initField('inspectionOther', `Select Other Inspector`, inspectionOther, (value) => { return this.state.request.inspection.value === "1" && this.isEmpty(value) });
        const discharge = trade.discharge || '';
        this.initField('discharge', 'Load & Discharge Terms', discharge, (value) => (this.state.request.shipping.value === "VESSEL" && this.isEmpty(value)));
        const dischargeRate = trade.dischargeRate;
        this.initDischargeRate(discharge, dischargeRate);
        const optionalDocuments = trade.optionalDocuments || [];
        this.initField("optionalDocuments", 'Optional Documents', optionalDocuments);
        const payment = trade.payment || "";
        this.initField('payment', 'Payment Terms', payment, this.isEmpty);
        const paymentPeriod = trade.paymentPeriod || '';
        this.initField('paymentPeriod', 'Payment Period', paymentPeriod, this.isEmpty);
        let nameOfBankInvalid = (value) => {
            return this.props.pov === "BUYER" &&
                this.state.request.payment.value !== "CAD" &&
                this.isEmpty(value)
        };
        const nameOfBank = trade.nameOfBank || '';
        this.initField('nameOfBank', "Name of Buyer's Bank", nameOfBank, nameOfBankInvalid);
        const locationOfBank = trade.locationOfBank || "";
        this.initField('locationOfBank', "Location of Buyer's Bank", locationOfBank, nameOfBankInvalid);
        const swiftCodeOfBank = trade.swiftCodeOfBank || "";
        this.initField('swiftCodeOfBank', "Swiftcode of Buyer's Bank", swiftCodeOfBank, nameOfBankInvalid);
        let confirmingBankInvalid = (value) => {
            return this.state.request.payment.value === "Confirmed letter of credit"
                && this.props.pov === "BUYER"
                && this.isEmpty(value)
        };
        const nameOfConfirmingBank = trade.nameOfConfirmingBank || "";
        this.initField('nameOfConfirmingBank', 'Name of Confirming Bank', nameOfConfirmingBank, confirmingBankInvalid);
        const locationOfConfirmingBank = trade.locationOfConfirmingBank || "";
        this.initField('locationOfConfirmingBank', 'Location of Confirming Bank', locationOfConfirmingBank, confirmingBankInvalid);
        const swiftCodeOfConfirmingBank = trade.swiftCodeOfConfirmingBank || "";
        this.initField('swiftCodeOfConfirmingBank', 'Swiftcode of Bank', swiftCodeOfConfirmingBank, confirmingBankInvalid);
        const downPaymentRequired = trade.downPaymentPercentage ? true : false;
        this.initField('downPaymentRequired', 'DownPayment Required', downPaymentRequired, false);
        const downPaymentPercentage = trade.downPaymentPercentage || '';
        let downPaymentInvalid = (value) => {
            return this.state.request.downPaymentRequired.value &&
                (this.isEmpty(value) || parseFloat(value) < 0 || parseFloat(value) > 100)
        };
        this.initField('downPaymentPercentage', 'Down Payment (%)', downPaymentPercentage, downPaymentInvalid);
        const downPaymentPeriod = trade.downPaymentPeriod || '';
        let downPaymentPeriodInvalid = (value) => { return this.state.request.downPaymentRequired.value && this.isEmpty(value) }
        this.initField('downPaymentPeriod', 'Down Payment to be effected within', downPaymentPeriod, downPaymentPeriodInvalid);
        const specialRequest = trade.specialRequest || "";
        this.initField("specialRequest", "Special Request", specialRequest, false );

        const tolerance = (trade.tolerance !== undefined) ? trade.tolerance : ''
        const tolerancePreset = (trade.tolerance !== undefined) ? TOLERANCE[tolerance] ? tolerance : 'OTHER' : '';
        this.initField('tolerancePreset', `Contractual Tolerance`, tolerancePreset, this.isEmpty,);
        this.setState(prev => ({
            request: {
                ...prev.request,
                tolerance: {
                    name: 'tolerance',
                    label: `Other`,
                    value: tolerance,
                    required: this.isEmpty,
                    visible: tolerancePreset === 'OTHER'
                }
            }
        }));
        
        let showInsuranceTerms = false;
        if (shipping === 'CONTAINER') {
            if (requestType === 'SELL' && incoterm === INCOTERMOPT.CIF) {
                showInsuranceTerms = true;
            }
            if (requestType === 'BUY' && [INCOTERMOPT.CFR, INCOTERMOPT.FOB].includes(incoterm)) {
                showInsuranceTerms = true;
            }
        }
        this.setState({
            initiated: true,
            showInsuranceTerms: showInsuranceTerms
        }, () => { this.validate() });

    }
    initField(name, label, value, required, touched = true) {
        this.setState(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                [name]: {
                    name: name,
                    label: label,
                    required: required,
                    touched: touched,
                    value: value,
                    disabled: false
                }
            }
        }));
    }

    setField = (name, value) => {
        const updateRequest = (args) => this.setState(args, () => this.validate())
        switch (name) {
            case 'contractValidateDate':
            case 'deliveryStartDate':
            case 'deliveryEndDate':
                if (typeof value === 'object' && value !== null) {
                    let request = this.state.request;
                    request[name] = {
                        ...request[name],
                        value: value
                    };

                    updateRequest({
                        required: {},
                        request: request
                    });
                }
                break;
            case 'incoterm': {
                let request = { ...this.state.request };
                request.incoterm.value = value;
                if (value === INCOTERMOPT.FOB) {
                    request.destCountry.value = '';
                    request.destCountry.required = false;
                    request.destPort.value = '';
                    request.destPort.required = false;
                    request.loadCountry.required = this.isEmpty;
                    request.loadPort.required = this.isEmpty;
                    request.deliveryStartDate.label = 'Delivery Period from';
                    request.deliveryEndDate.label = 'Delivery Period to';
                    if (this.state.request.origin.value && !request.destCountry.value) {
                        request.loadCountry.value = this.state.request.origin.value;
                    }
                } else if (value === INCOTERMOPT.CIF || value === INCOTERMOPT.CFR) {
                    request.loadCountry.value = '';
                    request.loadCountry.required = false;
                    request.loadPort.value = '';
                    request.loadPort.required = false;
                    request.destCountry.required = this.isEmpty;
                    request.destPort.required = this.isEmpty;
                    request.deliveryStartDate.label = 'Shipping period from';
                    request.deliveryEndDate.label = 'Shipping period to';
                }
                let showInsuranceTerms = false;
                if (request.shipping.value === 'CONTAINER') {
                    if (request.requestType.value === 'SELL') {
                        if (value === INCOTERMOPT.CIF) {
                            showInsuranceTerms = true;
                        }
                    } else {
                        if (value === INCOTERMOPT.CFR || value === INCOTERMOPT.FOB) {
                            showInsuranceTerms = true;
                        }
                    }
                }
                updateRequest({ request, showInsuranceTerms });
                break;
            }
            case 'payment': {
                this.setState(prevState => {
                    let request = { ...prevState.request };
                    request.payment.value = value;
                    request.paymentPeriod.value = undefined;
                    request.paymentPeriod.disabled = false;

                    request.nameOfBank = { ...request.nameOfBank, label: "Name of buyer's Bank", value: "" };
                    request.locationOfBank = { ...request.locationOfBank, label: "Location of Buyer's Bank", value: "" };
                    request.swiftCodeOfBank = { ...request.swiftCodeOfBank, label: "Swiftcode of Buyer's Bank", value: "" };

                    request.nameOfConfirmingBank = { ...request.nameOfConfirmingBank, label: "Name of Confirming Bank", value: "" };
                    request.locationOfConfirmingBank = { ...request.locationOfConfirmingBank, label: "Location of Confirming Bank", value: "" };
                    request.swiftCodeOfConfirmingBank = { ...request.swiftCodeOfConfirmingBank, label: "Swiftcode of Confirming Bank", value: "" };

                    if (value === 'CAD') {
                        request.paymentPeriod.value = PAYMENTPERIODOPT['At sight'];
                        request.nameOfBank = { ...request.nameOfBank, value: "" };
                        request.locationOfBank = { ...request.locationOfBank, value: "" };
                        request.swiftCodeOfBank = { ...request.swiftCodeOfBank, value: "" };
                    }
                    if (value === 'Confirmed letter of credit') {
                        request.nameOfBank = { ...request.nameOfBank, label: "Name of Opening Bank", value: "" };
                        request.locationOfBank = { ...request.locationOfBank, label: "Location of Opening Bank", value: "" };
                        request.swiftCodeOfBank = { ...request.swiftCodeOfBank, label: "Swiftcode of Opening Bank", value: "" };

                        request.nameOfConfirmingBank = { ...request.nameOfConfirmingBank, label: "Name of Confirming Bank", value: "" };
                        request.locationOfConfirmingBank = { ...request.locationOfConfirmingBank, label: "Location of Confirming Bank", value: "" };
                        request.swiftCodeOfConfirmingBank = { ...request.swiftCodeOfConfirmingBank, label: "Swiftcode of Confirming Bank", value: "" };
                    }
                    return { request: request }

                }, () => { this.validate() });
                break;
            }
            case 'euroConversionAvl' :
            case 'downPaymentRequired' :
            case 'insuranceRequired' :
            case 'standardContract' :
            case 'partialShipmentFlag':{
                let request = this.state.request;
                request[name] = {
                    ...request[name],
                    value: (value === 'true')
                };
                this.setState({
                    required: {},
                    request: request
                }, () => this.validate());
                break;
            }

            case '':
                break;
            default: {
                let request = this.state.request;
                request[name] = {
                    ...request[name],
                    value: value
                };
                this.setState({
                    required: {},
                    request: request
                }, () => this.validate());
                break;
            }
        }
    };

    onReview = () => {
        this.addDocuments();
        if (this.validate()) {
            this.setState({ showError: false });
            this.setState({
                disabled: true
            });
            if (this.state.isValidDoc) {
                this.setState({ isValidCustomdDoc: false });
                this.props.onReview(this.getStateValue(this.state.request));
            }
            else {
                this.setState({ isValidCustomdDoc: true });
            }
        } else {
            this.setState({ showError: true });
        }
    };

    addDocuments(documentList) {
        var doc = [];
        if (documentList !== undefined) {

            for (var i = 0; i < documentList.length; i++) {
                let title = documentList[i].title;
                let sellerDoc = documentList[i].sellerDoc;
                let inspectionDoc = documentList[i].inspectionDoc;
                let type = documentList[i].type;
                let listItem = { title, sellerDoc, inspectionDoc, type }
                doc.push(listItem);
            }
        }
        this.setField("optionalDocuments", documentList === undefined ? this.state.request.optionalDocuments.value : doc);
    }

    onValid(newDocumnet) {
        let isValidDoc = newDocumnet ? false : true;
        this.setState({ isValidDoc: isValidDoc, isValidCustomdDoc: false });
    }

    validate() {
        let required = {};
        let container = this.state.request;
        Object.keys(container).map(key => {
            let v = container[key];
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
            }
            return false;
        });
        if (this.state.privacyType === 'PRIVATE' && this.state.counterparty === '') {
            required.counterparty = this.state.request.counterparty;
        }
        if (Object.keys(required).length > 0) {
            this.setState({ required: required }, () => {
                scrollToElement('input', {
                    offset: -130,
                    ease: 'inOutQuad',
                    duration: 600
                });
            });
            return false;
        }
        return true;
    }

    getStateValue(container) {
        let result = {};
        Object.keys(container).map(key => {
            switch (key) {
                case 'price':
                case 'optionsPackagingTypePremOrDiscount':
                case 'optionsQualityTypePremOrDiscount':
                case 'optionsShippingTypePremOrDiscount':
                case 'optionsTypeOfRicePremOrDiscount':
                case 'measure':
                    result[key] = parseFloat(container[key].value);
                    break;

                case 'inspection':
                    result[key] = container[key].value ? String(container[key].value) : null;
                    break;
                case 'contractValidateDate':
                case 'deliveryStartDate':
                case 'deliveryEndDate':
                    result[key] = (container[key].value) ? container[key].value.format() : container[key].value;
                    break;
                case 'downPayment':
                    result[key] = parseInt(container[key].value);
                    break
                case 'packaging':
                    if (container[key].value === "OTHER") {
                        result[key] = container['packagingOther'].value;
                    } else {
                        result[key] = container[key].value;
                    }
                    break;
                case 'optionsPackagingType':
                    if (container[key].value === "OTHER") {
                        result[key] = container['optionsPackagingTypeOther'].value;
                    } else {
                        result[key] = container[key].value;
                    }
                    break;
                case 'optionsPackagingTypeOther':
                case 'packagingOther':
                    break;
                case 'downPaymentPercentage': 
                    result[key] =container[key].value===""? 0 : container[key].value;
                    break;
                case 'tolerance':
                    result[key] = parseFloat(container[key].value);
                    break;
                case 'tolerancePreset':
                    break
                default:
                    result[key] = container[key].value;
                    break;
            }
            return false;
        });

        return result;
    }

    initDischargeRate = (dischargeValue, value) => {
        let DISCHARGE_RATE_LABEL = "Load & Discharge";
        let DISCHARGE_RATE_VALUE = (value) ? value : "";
        let isInValid = (valueInvalid) => (this.state.request.shipping.value !== "CONTAINER" && this.isEmpty(valueInvalid));
        switch (dischargeValue) {
            case 'CQD load':
                DISCHARGE_RATE_LABEL = "Demurrage rate /day in $";
                break;
            case 'loadrate':
                DISCHARGE_RATE_LABEL = "Specify load rate details";
                break;
            case 'CQD':
                DISCHARGE_RATE_LABEL = "Demurrage rate/day in $";
                break;
            case 'Discharge rate':
                DISCHARGE_RATE_LABEL = "Specify discharge rate details ";
                break;
            default: break;


        }
        this.initField('dischargeRate', DISCHARGE_RATE_LABEL, DISCHARGE_RATE_VALUE, isInValid, 'step3');
    }

    formDischarge = discharge => {
        return (
            <FormSelectDropdown
                customStyle={this.CUSTOM_COMPONENT_STYLE}
                validation={this.state.required}
                items={discharge}
                name="discharge"
                onSelect={e => {
                    this.setField('discharge', e.target.value);
                    this.initDischargeRate(e.target.value, '');
                }}
                value={this.state.request.discharge}
            />);
    };

    renderTitle() {
        var type = this.props.type;
        let title = "";
        switch (type) {
            case "CREATE":
                title = "Prepare";
                break;
            case "EDIT":
                title = "Edit";
                break;
            case "COUNTER":
                title = "Prepare Counter proposal for ";
                break;
            default: break;
        }
        return title;
    }

    render() {
        if (!this.state.initiated) {
            return null;
        }

        return (
            <div className="modal__container counter-request">
                <div className="modal__wrapper px-0 py-0" style={{ maxWidth: '90vw' }}>
                    <div className="rtb-popup-box container-fluid">
                        <div className="popup-header">
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    {/* <span className="status-flag"> <FontAwesomeIcon icon={faInfoCircle} />{this.props.type==="CREATE"?"Prepare":"Edit"}  Amendment  </span> */}
                                </div>
                                <div className="col-md-6">
                                    <h3 className="heading-4 text-center">
                                        {this.renderTitle()} Contract Amendment
                                    </h3>
                                    <strong className="float-left">Note</strong>
                                    {this.props.type === "CREATE" ? <div className="note-text">
                                        <div>1) The values are those of the concluded contract.</div>
                                        <div>2) Prior to submitting the request you can review, edit or cancel it.</div>
                                        <div>3) Fields in grey colour can not be changed.</div>
                                    </div> :
                                        <div className="note-text">
                                            <div>1) The values of your amendment request.</div>
                                            <div>2) After edititng you can review your changes again prior to submitting.</div>
                                        </div>}
                                </div>
                                <span className="modal__close" onClick={this.props.onClose} />
                            </div>
                        </div>
                        <hr />
                        <div className="popup-body container-fluid">
                            <form className={`create-counter-form needs-validation`}  noValidate>
                                <div className="row align-items-start">
                                    {this.props.trade.privacyType !== 'TENDER' &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        
                                        <FormRadioFieldTrueFalse
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"euroConversionAvl"}
                                            value={this.state.request.euroConversionAvl} 
                                            onChange={ e => this.setField(e.target.name, e.target.value)}
                                        />
                                    </div>}
                                    <div className="w-100 d-md-none d-lg-block"/>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormUnitsInputField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="price"
                                            dropName="currency"
                                            items={CURRENCYOPT}
                                            validation={this.state.required}
                                            placeholder="Price"
                                            type="text"
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            allowNegative={false}
                                            isAllowed={(values) => {
                                                const {floatValue, value } = values;
                                                return value === "" || floatValue <= 1000000;
                                            }}
                                            onSelect={val => this.setField('currency', val)}
                                            onChange={val => this.setField("price", val)}
                                            value={this.state.request.price}
                                            dropValue={this.state.request.currency}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdownList
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="origin"
                                            items={RiceService.getAvailableCountries(this.state.request.riceType.value)}
                                            validation={this.state.required}
                                            value={this.state.request.origin}
                                            keyLabel={'label'}
                                            keyValue={'value'}
                                            onSelect={e => {
                                                this.setField('origin', e.target.value);   
                                                if(e.target.value === ""){
                                                    this.setField("riceType", "");
                                                    this.setField("riceTypeOther", "");
                                                }
                                            }}
                                            placeholder={this.state.request.origin.value}
                                            captionText={this.state.request.riceType.value?`*List of origins for the selected type`:''} 
                                            required={false}
                                            defaultLabel={"Select"}
                                            disableDefault={false}
                                            showDefault={this.state.request.origin.value?true:false}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        {this.state.request.riceCategory.value !== "NON-ORGANIC" && 
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="riceCategory"
                                            items={RICECATEGORY}
                                            validation={this.state.required}
                                            value={this.state.request.riceCategory}
                                            disabled={this.state.request.riceCategory.value}
                                            onSelect={e => this.setField('riceCategory', e.target.value)}
                                            placeholder={this.state.request.riceCategory.value}
                                            required={false}
                                        />}
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdownList
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            items={RiceService.getAvailableReference(this.state.request.origin.value)}
                                            value={this.state.request.riceType}
                                            keyLabel={'label'}
                                            keyValue={'value'}
                                            validation={this.state.required}
                                            placeholder={"Type of rice"}
                                            name="riceType"
                                            captionText={this.state.request.origin.value?`*List of types for the selected origin`:''} 
                                            required={true}
                                            onSelect={e => {                 
                                                this.setField("riceType", e.target.value);
                                                this.setField("riceTypeOther", "");
                                                const countryList = RiceService.getAvailableCountries(e.target.value);
                                                if(e.target.value === ""){
                                                    this.setField("origin", "");
                                                }
                                                if(countryList.length === 1){
                                                    this.setField("origin", countryList[0].value);
                                                }                                        
                                            }}
                                            defaultLabel={"Select"}
                                            disableDefault={false}
                                            showDefault={this.state.request.riceType?true:false} />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        {this.state.request.riceType.value === RiceService.enums.REF_OTHER && 
                                        <FormInputField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            value={this.state.request.riceTypeOther}
                                            validation={this.state.required}
                                            placeholder={"Please Specify"}
                                            name="riceTypeOther"
                                            required={true}
                                            onChange={e => {
                                                this.setField("riceTypeOther", e.target.value);
                                            }}/>}
                                    </div>
                                    <div className="w-100"/>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            className="select"
                                            name="shipping"
                                            items={SHIPPINGTYPES}
                                            onSelect={e => {
                                                this.setField('shipping', e.target.value);
                                                this.initDischargeRate(undefined, undefined);
                                                this.setField('discharge', "");
                                                this.setField('dischargeRate', "");
                                            }}
                                            value={this.state.request.shipping}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormInputNumberFormat
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="measure"
                                            placeholder="Quantity"
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            allowNegative={false}
                                            type='text'
                                            isAllowed={(values) => {
                                                const {floatValue, value } = values;
                                                return value === "" || floatValue <= 100000;
                                            }}
                                            validation={this.state.required}
                                            onChange={val => this.setField('measure', val)}
                                            value={this.state.request.measure}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="quality"
                                            items={QUALITYSTANDARD}
                                            validation={this.state.required}
                                            value={this.state.request.quality}
                                            onSelect={e => this.setField('quality', e.target.value)}
                                            placeholder={this.state.request.quality.value}
                                            required={false}
                                            showDefault={true}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdownList
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="cropYear"
                                            items={CROP_YEARS}
                                            validation={this.state.required}
                                            value={this.state.request.cropYear}
                                            onSelect={e => this.setField('cropYear', e.target.value)}
                                            placeholder={this.state.request.cropYear.value}
                                            required={false}
                                            showDefault={true}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="tolerancePreset"
                                            items={TOLERANCE}
                                            validation={this.state.required}
                                            captionText="Tolerance is in buyer's option for FOB and in seller's option for CFR and CIF and  MIN/MAX will have effect of zero tolerance up or down"
                                            value={this.state.request.tolerancePreset}
                                            onSelect={e => {
                                                if (e.target.value === 'OTHER') {
                                                    this.setState(prev => {
                                                        prev.request.tolerance.visible = true
                                                        return { ...prev }
                                                    })
                                                    this.setField('tolerance', "");
                                                } else {
                                                    this.setState(prev => {
                                                        prev.request.tolerance.visible = false
                                                        return { ...prev }
                                                    })
                                                    this.setField('tolerance', e.target.value);
                                                }
                                                this.setField('tolerancePreset', e.target.value);
                                            }}
                                            placeholder={this.state.request.tolerance.value}
                                            required={false}
                                            showDefault={true}
                                        />
                                    </div>

                                    <div className={`${this.FORM_CREATE_WRAPPER_CLASS} ${this.state.request.tolerance.visible ? "visible" : "invisible"}`}>
                                        <FormInputGroupField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={'tolerance'}
                                            apValue={"%"}
                                            validation={this.state.required}
                                            value={this.state.request.tolerance}
                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                            placeholder="Tolerance value"
                                            options={{
                                                numeral: true,
                                                numeralIntegerScale: 2,
                                                numeralPositiveOnly: true,
                                                stripLeadingZeroes: true
                                            }}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            name="incoterm"
                                            items={INCOTERMOPT}                                                                                                                                                                                                                                                                                                                                                             
                                            onSelect={e => {
                                                this.setField('incoterm', e.target.value);
                                                this.setField('discharge', "");
                                                this.setField('dischargeRate', "");
                                            }}
                                            value={this.state.request.incoterm}
                                            disabled={true}
                                        />
                                    </div>
                                    {(this.state.request.incoterm.value === 'CIF' || this.state.request.incoterm.value === 'CFR') &&
                                        <React.Fragment>
                                            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                <FormSelectDropdown
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    validation={this.state.required}
                                                    className="select"
                                                    name="destCountry"
                                                    captionText="Country of the port of destination"
                                                    items={Countries}
                                                    onSelect={e => {
                                                        this.setField("destCountry", e.target.value);
                                                        this.setField("destPort", "");
                                                    }}
                                                    value={this.state.request.destCountry}
                                                    placeholder="Country"
                                                />
                                            </div>
                                            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                <FormAsyncSelectPort
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    fieldData={this.state.request.destPort}
                                                    onChange={(name, value, fromDropdown) => {
                                                        this.setField(name, value);
                                                        if (fromDropdown) {
                                                            this.setField("destCountry", value.slice(0, 2));
                                                        }
                                                    }}
                                                    country={this.state.request.destCountry.value}
                                                    validation={this.state.required}
                                                    styles={{
                                                        indicatorsContainer: (provided) => ({ ...provided, height: 31 })
                                                    }}
                                                />
                                            </div>
                                        </React.Fragment>}
                                    {(this.state.request.incoterm.value === 'FOB') &&
                                        <React.Fragment>
                                            <div className={`${this.FORM_CREATE_WRAPPER_CLASS}`}>
                                                <FormSelectDropdown
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    className="select"
                                                    validation={this.state.required}
                                                    name="loadCountry"
                                                    items={Countries}
                                                    onSelect={e => {
                                                        this.setField("loadCountry", e.target.value);
                                                        this.setField("loadPort", "");
                                                    }}
                                                    value={this.state.request.loadCountry}
                                                    placeholder="Country"
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className={`${this.FORM_CREATE_WRAPPER_CLASS}`}>
                                                <FormAsyncSelectPort
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    fieldData={this.state.request.loadPort}
                                                    onChange={(name, value, fromDropdown) => {
                                                        this.setField(name, value);
                                                        if (fromDropdown) {
                                                            this.setField("loadCountry", value.slice(0, 2));
                                                        }
                                                    }}
                                                    country={this.state.request.loadCountry.value}
                                                    validation={this.state.required}
                                                    styles={{
                                                        indicatorsContainer: (provided) => ({ ...provided, height: 31 })
                                                    }}
                                                />
                                            </div>
                                        </React.Fragment>}
                                    <div className="col-sm-12 col-md-8 col-lg-6">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <FormDateField
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    dateFormat={DATEFORMAT}
                                                    required={false}
                                                    validation={this.state.required}
                                                    time={false}
                                                    name="deliveryStartDate"
                                                    minDate={moment()}
                                                    item={this.state.request.deliveryStartDate}
                                                    label={true}
                                                    onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <FormDateField
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    dateFormat={DATEFORMAT}
                                                    required={false}
                                                    validation={this.state.required}
                                                    time={false}
                                                    name="deliveryEndDate"
                                                    minDate={this.state.request.deliveryStartDate.value}
                                                    item={this.state.request.deliveryEndDate}
                                                    label={true}
                                                    selectsStart={this.state.request.deliveryStartDate.value}
                                                    selectsEnd={this.state.request.deliveryEndDate.value}
                                                    onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="packaging"
                                            items={PACKAGING}
                                            validation={this.state.required}
                                            value={this.state.request.packaging}
                                            onSelect={e => {
                                                this.setField('packaging', e.target.value);
                                                if (e.target.value === 'OTHER') {
                                                    this.initField('packagingOther', "Packaging (other)", "", this.required);
                                                }
                                            }}
                                            placeholder={this.state.request.packaging.value}
                                            required={false}
                                            showDefault={true}
                                        />
                                    </div>
                                    {this.state.request.packaging.value === 'OTHER' &&
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name="packagingOther"
                                                validation={this.state.required}
                                                value={this.state.request.packagingOther}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder={"Packing"}
                                                required={true}
                                            />
                                        </div>}
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="inspection"
                                            items={filterInspectionCompany(this.props.inspectionCompanies, this.state.request.origin.value, true)}
                                            validation={this.state.required}
                                            value={this.state.request.inspection}
                                            onSelect={e => {
                                                this.setField('inspection', e.target.value)
                                                this.setField('inspectionOther', "");
                                            }}
                                            placeholder={this.state.request.inspection.value}
                                            required={false}
                                            showDefault={true}
                                            disabled={true}
                                        />
                                    </div>
                                    {this.state.request.inspection.value === "1" &&
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormSelectDropdown
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name="inspectionOther"
                                                items={getNonIntegratedInspector(this.state.request.origin.value)}
                                                validation={this.state.required}
                                                value={this.state.request.inspectionOther}
                                                onSelect={e => this.setField('inspectionOther', e.target.value)}
                                                placeholder={this.state.request.inspectionOther.value}
                                                required={true}
                                                showDefault={true}
                                                isShowToolTipLabel={this.state.request.inspection.value === "1"}
                                                toolTipTitle={TOOLTIP_TITLE.NON_INSPECTOR_TITLE}
                                                disabled={true}
                                            />
                                        </div>}
                                    <div className="w-100" />
                                    {this.state.request.shipping.value === "VESSEL" &&
                                        <React.Fragment>
                                            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                <FormSelectDropdown
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    validation={this.state.required}
                                                    name="discharge"
                                                    items={this.state.request.incoterm.value === "FOB" ? DISCHARGEFOB : DISCHARGECIF}
                                                    onSelect={e => {
                                                        this.setField('discharge', e.target.value);
                                                        this.initDischargeRate(e.target.value, '');
                                                    }}
                                                    value={this.state.request.discharge}
                                                />
                                            </div>
                                            {["CQD load", "CQD"].includes(this.state.request.discharge.value) &&
                                                <div className="col-sm-8">
                                                    <FormInputNumberFormat
                                                        type="text"
                                                        thousandSeparator={true}
                                                        step="any"
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        name="dischargeRate"
                                                        isAllowed={(values) => {
                                                            const { floatValue, value } = values;
                                                            return value === "" || floatValue >= 0;
                                                        }}
                                                        validation={this.state.required}
                                                        value={this.state.request.dischargeRate}
                                                        onChange={val => this.setField('dischargeRate', val)}
                                                        placeholder={"Rate"}
                                                        required={true}
                                                    />
                                                </div>}
                                            {["loadrate", "Discharge rate"].includes(this.state.request.discharge.value) &&
                                                <div className="col-sm-8">
                                                    <FormTextareaField
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        name="dischargeRate"
                                                        validation={this.state.required}
                                                        value={this.state.request.dischargeRate}
                                                        onChange={e => this.setField(e.target.name, e.target.value)}
                                                        placeholder={"Rate"}
                                                        required={true}
                                                    />
                                                </div>}
                                        </React.Fragment>}
                                </div>
                                <div className="row">
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="payment"
                                            items={PAYMENTTERMSOPT}
                                            validation={this.state.required}
                                            value={this.state.request.payment}
                                            onSelect={e => this.setField('payment', e.target.value)}
                                            placeholder={this.state.request.payment.value}
                                            required={false}
                                            showDefault={true}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="paymentPeriod"
                                            items={PAYMENTPERIODOPT}
                                            validation={this.state.required}
                                            value={this.state.request.paymentPeriod}
                                            disabled={this.state.request.payment.value === "CAD"}
                                            onSelect={e => this.setField('paymentPeriod', e.target.value)}
                                            placeholder={this.state.request.paymentPeriod.value}
                                            required={false}
                                        />
                                    </div>
                                    {this.state.request.payment.value !== "CAD" &&
                                        <React.Fragment>
                                            <div className="w-100" />
                                            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                <FormInputField
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    name={'nameOfBank'}
                                                    readOnly={this.props.pov === "SELLER"}
                                                    validation={this.state.required}
                                                    value={this.state.request.nameOfBank}
                                                    onChange={e => this.setField(e.target.name, e.target.value)}
                                                    placeholder="Bank name"
                                                    type="text"
                                                />
                                            </div>
                                            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                <FormInputField
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    name={'locationOfBank'}
                                                    readOnly={this.props.pov === "SELLER"}
                                                    validation={this.state.required}
                                                    value={this.state.request.locationOfBank}
                                                    onChange={e => this.setField(e.target.name, e.target.value)}
                                                    placeholder="Location"
                                                    type="text"
                                                />
                                            </div>
                                            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                <FormInputField
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    name={'swiftCodeOfBank'}
                                                    readOnly={this.props.pov === "SELLER"}
                                                    validation={this.state.required}
                                                    value={this.state.request.swiftCodeOfBank}
                                                    onChange={e => this.setField(e.target.name, e.target.value)}
                                                    placeholder="Swift code"
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-100" />
                                        </React.Fragment>}
                                        {this.state.request.payment.value === "Confirmed letter of credit" &&
                                            <React.Fragment>
                                                <div className="w-100" />
                                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                    <FormInputField
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        name={'nameOfConfirmingBank'}
                                                        readOnly={this.props.pov === "SELLER"}
                                                        validation={this.state.required}
                                                        value={this.state.request.nameOfConfirmingBank}
                                                        onChange={e => this.setField(e.target.name, e.target.value)}
                                                        placeholder="Confirming Bank name"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                    <FormInputField
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        name={'locationOfConfirmingBank'}
                                                        readOnly={this.props.pov === "SELLER"}
                                                        validation={this.state.required}
                                                        value={this.state.request.locationOfConfirmingBank}
                                                        onChange={e => this.setField(e.target.name, e.target.value)}
                                                        placeholder="Location"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                    <FormInputField
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        name={'swiftCodeOfConfirmingBank'}
                                                        readOnly={this.props.pov === "SELLER"}
                                                        validation={this.state.required}
                                                        value={this.state.request.swiftCodeOfConfirmingBank}
                                                        onChange={e => this.setField(e.target.name, e.target.value)}
                                                        placeholder="Swift code"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="w-100" />
                                            </React.Fragment>}
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <label className="label mr-3">
                                                DownPayment Required
                                            {/* <span className="req-field">&nbsp;*</span> */}
                                            </label>
                                            <label className="label mr-3">
                                                {this.state.request.downPaymentRequired.value===true? "Yes":"No"} 
                                            </label>
                                        </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormRadioFieldTrueFalse
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"partialShipmentFlag"}
                                            value={this.state.request.partialShipmentFlag}
                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                        />
                                    </div>
                                    {this.state.request.downPaymentRequired.value && 
                                    <React.Fragment>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputGroupField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'downPaymentPercentage'}
                                                apValue={"%"}
                                                validation={this.state.required}
                                                value={this.state.request.downPaymentPercentage}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Down payment"
                                                step="any"
                                                max={100}
                                                min={0}
                                                options={{
                                                    numeral: true,
                                                    numeralIntegerScale: 3,
                                                    numeralPositiveOnly: true,
                                                    stripLeadingZeroes: true
                                                }}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormSelectDropdown
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                validation={this.state.required}
                                                name="downPaymentPeriod"
                                                items={DOWNPAYMENTPERIODOPT}
                                                captionText="(days counting from proforma invoice date)"
                                                onSelect={e => this.setField('downPaymentPeriod', e.target.value)}
                                                value={this.state.request.downPaymentPeriod}
                                                disabled={true}
                                            />
                                        </div>
                                    </React.Fragment>}
                                    <div className={'col-sm-12'}>
                                        <FormTextareaField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            //validation={this.state.required}
                                            // disabled={true}
                                            name="specialRequest"
                                            onChange={e => this.setField('specialRequest', e.target.value)}
                                            value={this.state.request.specialRequest}
                                            required={false}
                                        />
                                    </div>
                                </div>

                                <hr />
                                <div className="modal-header-center"> <h6>Document Selection/ Uploading party</h6>
                                    <div className="text-left">
                                        Add or Remove documents and define the uploading party. Always consider regulatory requirements for your origin and destination. Documents with a grey tick box cannot be removed. In addition to the documents listed below the full set of documents will also include the following mandatory documents : bills of lading, commercial invoice , quality-weight-packing certificate, {this.state.request.shipping.value === "VESSEL" ? <span>hold cleanliness certificate</span> : <span>container cleanliness certificate</span>} issued and uploaded by the contractual inspector
                                    </div>
                                </div>
                                <DocumentSelection
                                    value={DocumentFactory.getFormFEDocumentList(this.getStateValue(this.state.request))}
                                    onChange={documentList => this.setField('optionalDocuments', DocumentFactory.getBEOptionalDocumentList(documentList))}
                                    errorMessage={erroMessage}
                                    isnonIntegratedInspector={this.state.request.inspection.value === "1"}
                                />
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormDateField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            dateFormat={DATEFORMATHOURS}
                                            required={true}
                                            validation={this.state.required}
                                            time={true}
                                            name="contractValidateDate"
                                            minDate={moment().add(1, 'hours').minutes(0).seconds(0)}
                                            item={this.state.request.contractValidateDate}
                                            label={true}
                                            onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)} />
                                    </div>
                                </div>
                                <div className="text-center mb-3">
                                    <button className="btn btn--grey" type="button" onClick={this.props.onClose}><i className="fas fa-times"></i>
                                        <FontAwesomeIcon icon={faTimes} /> Back</button>
                                    <button className="btn btn--blue bc-btn-radius" type="button" onClick={this.onReview}>
                                        <FontAwesomeIcon icon={faCheck} /> Review</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RequestContract;