import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const ErrorModal = ({content, onClose}) => {
    return (
            <div className='modal__container'>
            <div className="modal__wrapper px-0 py-0 authcode">
            <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                    {/* <button type="button" className="modal__close" onClick={onClose}>
                    </button> */}
                </div>
                <div className="modal-content">
                    <div className="modal-body pb-3">
                    {content}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn-popup btn-submit" onClick={onClose}>
                            <FontAwesomeIcon icon={faCheck} /> OK
                        </button>
                    </div>
                </div>
            </div>
            </div>
    )
};
