import PropTypes from "prop-types";
import React, { useState, useEffect } from 'react';
import FormTradeDocumentList from '../../../../components/form/FormTradeDocumentList';
import FormSelectDropdown from '../../../../components/form/FormSelectDropdown';
import {  DocumentFactory, documentUploader } from './../../../trades/services/documents.service';

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Document selection component 
 * @since  01 26 2021 (RDEV-1777)
 * @param optionalDocuments documents given as input value ( ie : trade.optionalDocuments)
 * @param initialList default list of documents to show in the selection form
 */
const DEFAULT_ERR_MSG = "Error"


const DocumentSelection = ({ onChange, onTouchChange, onValidationChange, isnonIntegratedInspector, errorMessage = DEFAULT_ERR_MSG, value = [], disableDocumentSelection = false }) => {

    const newDoc = DocumentFactory.getNewFEDoc('', '', '', 'SELLER', false, true, true);
    const [documentForm, setDocumentForm] = useState({ ...newDoc });
    const [isValid, setIsValid] = useState(false);
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        if (onValidationChange)
            onValidationChange(isValid)
    }, [isValid, onValidationChange])

    useEffect(() => {
        if (onTouchChange)
            onTouchChange(touched)
    }, [touched, onTouchChange])


    /* Handle input change ( add item in the list ) */
    const handleOnChange = (e) => {
        const newOptionalDocument = { ...documentForm, [e.target.name]: e.target.value.trimStart() }
        // Set the appropriate boolean sellerDoc, inspectionDoc
        newOptionalDocument.type = newOptionalDocument.title;
        newOptionalDocument.sellerDoc = (newOptionalDocument.uploadedBy === 'SELLER')
        newOptionalDocument.inspectionDoc = (newOptionalDocument.uploadedBy === 'CONTRACTUAL_INSPECTOR')
        let _isValid = (newOptionalDocument.title !== '' && newOptionalDocument.uploadedBy !== '');
        let _touched = JSON.stringify(newOptionalDocument) !== JSON.stringify({ ...newDoc });
        setDocumentForm(newOptionalDocument)
        setIsValid(_isValid);
        setTouched(_touched);
    }

    const handleOnSelectionChange = (documentList) => {
        // selection changed';

        // documentList
        const res = documentList.filter(doc => doc.selected)
        onChange(res)
    }

    const onInsert = () => {
        const newDocumentList = [...value, { ...documentForm }];
        handleOnSelectionChange(newDocumentList);
        setDocumentForm({ ...newDoc })
        setTouched(false)
        setIsValid(false)
    }

    const resetForm = () => {
        setDocumentForm({ ...newDoc })
        setTouched(false)
        setIsValid(false)
    }

    return (<div className="card my-3 p-3 bg-light shadow-sm">

        <FormTradeDocumentList
            documentList={value}
            onChange={handleOnSelectionChange}
            uploaderOptions={documentUploader(isnonIntegratedInspector)}
            disableDocumentSelection={disableDocumentSelection} />

        <div className="form-row mt-3">
            <div className="col-auto">
                <div className="form-check mb-2">
                    <input className="form-check-input"
                        disabled
                        type="checkbox"
                        checked={true}
                        id="documentFormCheck" />
                </div>
            </div>
            <div className="col">
                <input className="form-control form-control-sm mb-2"
                    placeholder=""
                    name='title'
                    type="text"
                    value={documentForm.title}
                    onChange={handleOnChange}
                    id="documentForm"
                    disabled={disableDocumentSelection} />
            </div>
            <div className="col-sm-4">
                <FormSelectDropdown
                    name='uploadedBy'
                    value={{
                        label: 'Uploaded By ',
                        value: documentForm.uploadedBy,
                    }}
                    onSelect={handleOnChange}
                    items={documentUploader(isnonIntegratedInspector)}
                    customStyle={{
                        wrapperClassName: 'form-inline float-right',
                        labelClassName: 'col-form-label-sm sr-only mr-2',
                        selectClassName: 'custom-select custom-select-sm'
                    }}
                    disabled={disableDocumentSelection} />
            </div>
            <div className="col-sm-4">
                <button type="button"
                    disabled={!isValid}
                    className="btn btn-addmore btn-sm ml-1"
                    onClick={onInsert}>
                    <FontAwesomeIcon icon={faPlus} /> Insert
                </button>
                {touched &&
                    <button type="button"
                        className="btn btn-sm btn-document-cancel float-right"
                        onClick={resetForm}>
                        cancel
                </button>}
            </div>
        </div>
        {(touched) &&
            <div className="text-danger text-center">
                {errorMessage}
            </div>}
    </div>)

}

DocumentSelection.propTypes = {
    documentList: PropTypes.any,
    errorMessage: PropTypes.any,
    onChange: PropTypes.func,
    onTouchChange: PropTypes.func,
    onValidationChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.object),
    disableDocumentSelection: PropTypes.bool,
    isnonIntegratedInspector: PropTypes.bool
}

export default DocumentSelection;