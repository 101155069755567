import React from 'react';
import moment from 'moment';

//COMPONENTS
// import Reply from './Reply';
import { DATEFORMATHOURS } from '../../../../services/service.values';

const Message = ({ message, userId }) => {
    const checkDate = (date) => {
        if (moment.duration(moment(new Date()).diff(date)).humanize() < 24 * 60 * 60) {
            return moment.duration(moment(new Date()).diff(date)).humanize() + ' ago'
        } else {
            return moment(date).format(DATEFORMATHOURS)
        }
    };

    const highlightedUserClass = userId === message.userID ? 'text-primary' : '';
    const highlightedUser = userId === message.userID ? <span>(You)</span> : null;

    return (
        <div className="request-dtls__comment">
            <span className={`request-dtls__comment-author ${highlightedUserClass}`}>{message.userName.trim()}, {message.companyName} {highlightedUser}</span>
            <span className={`request-dtls__comment-time ${highlightedUserClass}`}>{checkDate(message.createdAt)}</span>
            <div className="request-dtls__comment-text" style={{whiteSpace: 'pre-wrap'}}>
                {message.text}
            </div>
        </div>
    );
}

export default Message;
