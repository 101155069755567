import React from 'react';
import PreviewPDF from '../../../components/previewPDF/PreviewPDF';
import moment from 'moment';
import { 
    DATEFORMAT
} from '../../../../services/service.values';

const PreviewDocumentsModal = ({files, name, onClose, buyerDeclarationDate, remark}) => {
    const getPreviewWidth = () => {
        const containerWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) * 0.95 - 70;
        return containerWidth / files.length;
    };

    return (
        <div className="modal__container doc-preview-modal">

        <form className="modal__wrapper px-0 py-0" >
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-header-center"> <h5 className="modal-title">Preview Document</h5></div>
                  <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                    <span aria-hidden="true"><span>&times;</span></span>
                  </button>
                </div>

                <div className="modal-body">
            {/* <div className="modal__wrapper">
                <span className="modal__close" onClick={onClose}/>
                <h3 className='modal__heading'>Preview Document</h3> */}
                    {buyerDeclarationDate ? <div className="table-responsive mb-4">
                        <table className="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Buyer Declaration Date</th>
                                    <th scope="col">Additional Information</th>
                                </tr> 
                            </thead>
                            <tbody>
                                <tr>
                                    <td> {moment(buyerDeclarationDate).format(DATEFORMAT)}</td>
                                    <td> {files[0].remark}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> : null}
                <div className="doc-preview-modal__type">
                    <div className="doc-preview-modal__type__title">
                        Document type
                    </div>
                    <div className="doc-preview-modal__type__value">
                        {name}
                    </div>
                </div>
                <div className="d-flex" id="doc-preview-container">
                    {files.map((file, index) => {
                        return(
                            <div className="modal__preview-file" key={`file-${file.id}`} id={`file-${file.id}`} style={{margin: '0 16px'}}>
                                <div className="doc-preview-modal__version">{index === 0 ? 'Current version' : 'Earlier version'}</div>
                                {
                                    file.buyerDeclarationDate ?
                                    <table className="table table-bordered table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Buyer Declaration Date</th>
                                                <th scope="col">Additional Information</th>
                                            </tr> 
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> {moment(file.buyerDeclarationDate).format(DATEFORMAT)}</td>
                                                <td> {file.buyerAdditionalInfo}</td>
                                            </tr>
                                        </tbody>
                                    </table> : null
                                }
                                <PreviewPDF width={getPreviewWidth()} file={file}/>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>
        </form>
        </div>
    )
};

export default PreviewDocumentsModal;
