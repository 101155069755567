import React from 'react';
import { Link } from 'react-router-dom';
function CardOfferField(props) {
    const { fieldName, fieldValue, heading=false, className="mt-3", counterpartyId} = props;
    
    return <div className={`${(heading)?'heading-border':''}`}>
    <div className={`row ${className} `}>
        <div className="col-6">
            <div className={`compare-${(heading)?'heading-':''}left`}>{ fieldName }
            {heading ? <div><Link to={`/company-profile/${counterpartyId}`}>Profile</Link></div> : null}
            </div>
        </div>
        <div className="col-6">
            {heading &&
            <h3 className="compare-heading-right">{fieldValue}</h3>}
            {!heading &&
            <div className="compare-heading-right">{fieldValue}</div>}

        </div>
    </div>
    </div>;
}

export default CardOfferField;


