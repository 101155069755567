import React from 'react';
import { connect } from "react-redux";
import { DATEFORMAT,MEASUREMENTS, TRADE_STATUS, STEPS } from '../../../../services/service.values';
import moment from 'moment-timezone';
import { EnumsService, PortService } from '../../../../services/service.utils';

import { RiceService } from '../../../../services/service.rice';
import RxNumberFormat from '../../../components/Formatter/RxNumberFormat';

const COUNTRIES = EnumsService.countries();

const DocumentShippingAdvice = ({ trade, bill, userName, isModal, status, synthesis, tradeMap }) => {
    status = status || trade.status;
    //TODO : modify the API to return always camelCase fields in JSON responses
    const billNumber = bill.BillNumber || bill.billNumber;
    const quantCleanOnBoard = bill.QuantCleanOnBoard || bill.quantCleanOnBoard;
    const packGoodsDescript = bill.PackGoodsDescript || bill.packGoodsDescript;
    const vessVoyage = bill.VessVoyage || bill.vessVoyage;
    const shippedOnBoard = bill.ShippedOnBoard || bill.shippedOnBoard;
    const ID = bill.ID || bill.billID;
    const { riceType, riceTypeOther, incoterm, loadPort, loadCountry, destPort, destCountry, origin, tradeRefId, parentTradeRequestID, id, acceptedAt } = trade;
    const parent = tradeMap[parentTradeRequestID];
    if (!ID) {
        return false;
    }



    return (
                    <React.Fragment>
                        <h3 className="modal__heading pb-2">Bill Of Lading No. {billNumber}</h3>
                        <div className="modal__label1 text-center pb-3" style={{ lineHeight: 'initial' }}>
                            {(parent && synthesis ) ?
                            <>
                                Partial Shipment ( ref: <b>{tradeRefId || id}{' '}</b> ) of the parent contract No.{' '} <b>{parent.tradeRefId || id}{' '}</b>,  
                                dated {' '}
                                <b>{moment(acceptedAt).format(DATEFORMAT)}{' '}</b>
                                covering{' '}
                                <RxNumberFormat value={synthesis.contractualQuantity} suffix={` ${MEASUREMENTS[trade.measurement]}`} />,{' '}
                            </>
                            :
                            <>
                                Contract No.{' '}
                                <b>{tradeRefId || id}{' '}</b> 
                                dated {' '}
                                <b>{moment(acceptedAt).format(DATEFORMAT)}{' '}</b>
                                covering{' '}
                                <RxNumberFormat value={trade.measure} suffix={` ${MEASUREMENTS[trade.measurement]}`} />,{' '}
                            </>
                            }

                            {RiceService.getByRef(riceType) ?
                                <React.Fragment>
                                    {RiceService.getByRef(riceType).ref === RiceService.enums.REF_OTHER ?
                                    <React.Fragment>
                                        <span></span>{RiceService.getByRef(riceType).label}<br />
                                        <span></span>{riceTypeOther}<br />
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <span></span>{RiceService.getByRef(riceType).label}<br />
                                    </React.Fragment>}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <span></span>{riceType}<br />
                                </React.Fragment>
                            }
                        </div>
                        <div className="modal__textarea modal__textarea_dark textarea"
                            id={`shipment-advice-text-${ID}`}>
                            <p>
                                <span className="bold">Dear Sir</span>,
                                </p>
                            <p>
                                { (synthesis && synthesis.balance) &&
                                    <>
                                        {(synthesis.balance.toShip.min > 0 && synthesis.numberOfShipments === 1) &&
                                            <span>
                                                U.U.R. and in part of the captioned contract we tender
                                                hereby as follows:<br/>
                                            </span>
                                        }
                                        {(synthesis.balance.toShip.min > 0 && synthesis.numberOfShipments !== 1) &&
                                            <span>
                                                U.U.R. and in further part of the captioned contract we tender
                                                hereby as follows:<br/>
                                            </span>
                                        }
                                        {(synthesis.balance.toShip.min === 0) &&
                                            <span>
                                                U.U.R. and in complete fulfillment of the captioned contract we tender
                                                hereby as follows:<br/>
                                            </span>
                                        }
                                    </>
                                }
                                <span className="bold bold--underline">
                                    <RxNumberFormat value={quantCleanOnBoard} suffix={' MT '}/>
                                </span>of{' '}    
                                <span
                                    className="bold bold--underline">{packGoodsDescript}</span> shipped
                                    on
                                    MV <span
                                    className="bold bold--underline">{vessVoyage}</span> dated{' '}
                                <span className="bold bold--underline">
                                    {moment(shippedOnBoard).format(DATEFORMAT)}
                                </span>{' '}
                                shipped from{' '}
                                { incoterm === 'FOB' ? <span className="bold bold--underline">{PortService.getName(loadPort)}, {COUNTRIES[loadCountry]}</span>: <span className="bold bold--underline">{COUNTRIES[origin]}</span>}{' '}
                                to{' '}
                                <span className="bold bold--underline">
                                {PortService.getName(destPort)}, {COUNTRIES[destCountry]}
                                </span>
                            </p>

                            {incoterm === 'FOB' && (
                                <p>Kindly cover the marine insurance for this parcel.</p>
                            )}
                            <p>With kind regards.</p>
                            <p>
                                <span className="bold">{userName}</span>
                            </p>
                        </div>
                    </React.Fragment>

    );
};

const mapStateToProps = state => ({
    synthesis: state.shipment.synthesis,
    tradeMap: state.trade.items.tradeMap,
});

export default connect(mapStateToProps, null)(DocumentShippingAdvice);
