import React from "react";
import { Route, NavLink } from 'react-router-dom';
import Header from '../../components/header/Header';
import '../../adminManagement/adminManagement-style.scss';
// import './modals.scss';
import ViewCompanyProfile from './viewCompanyProfile/ViewCompanyProfile';
import CompanyCapabilities from './viewCompanyProfile/CompanyCapabilities';
import AccessControl, { INSPECTION_COMPANY, SHIPPING_COMPANY } from '../../components/AccessControl';
import { UserTypes } from '../../../utils/userTypes';

const CompanyProfileInfo = ({user, company, sellingCapability, buyingPreference, profileId}) => (
  <React.Fragment>
    <Header />
    <div className="trades-dtls__info">
      <div className="trades-dtls__tabs">
        <NavLink 
          to={`/company-profile/${profileId}`}
          exact
          className="trades-dtls__tab trades-dtls__tab_active">
            Profile
        </NavLink>
        <AccessControl user={user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY, SHIPPING_COMPANY]} >
        <NavLink 
          to={`/company-profile/${profileId}/sellingInfo`}
          exact
          className="trades-dtls__tab">
            Selling Capabilities
        </NavLink>
        <NavLink 
          to={`/company-profile/${profileId}/buyingInfo`}
          exact
          className="trades-dtls__tab">
            Buying Preferences
        </NavLink>
        </AccessControl>
      </div>
    </div>

      <Route
        exact
        path={'/company-profile/:id'}
        render={() => <ViewCompanyProfile company={company} />}/>
         <AccessControl user={user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY, SHIPPING_COMPANY]} >
       <Route
        exact
        path={'/company-profile/:id/sellingInfo'}
        render={() => <CompanyCapabilities tabData="sellingInfo" capability={sellingCapability}/>}/>
        <Route
        exact
        path={'/company-profile/:id/buyingInfo'}
        render={() => <CompanyCapabilities tabData="buyingInfo" capability={buyingPreference}/>}/>
         </AccessControl>
  </React.Fragment >
);

export default CompanyProfileInfo;
