import React from 'react';
import {Bar} from 'react-chartjs-2';
import { EnumsService } from "../../../services/service.utils";

const Countries = EnumsService.countries();


class FloatingTransitMap extends React.Component {
  render() {
    const countryTradeCode = [];
    const saleAndPurchase = [];


    if(this.props.dashboard && this.props.dashboard.countryTradeObject){
      Object.keys(this.props.dashboard.countryTradeObject).map(key => {
        saleAndPurchase.push(this.props.dashboard.countryTradeObject[key]);
        countryTradeCode.push(Countries[key]);
        return key; 
      });
    }

    const data = {
      labels:  countryTradeCode,
      datasets: [{
          label: 'Country Trade volume',
          type:'bar',
          data: saleAndPurchase,
          fill: false,
          borderColor: '#EC932F',
          backgroundColor: '#EC932F',
          pointBorderColor: '#EC932F',
          pointBackgroundColor: '#EC932F',
          pointHoverBackgroundColor: '#EC932F',
          pointHoverBorderColor: '#EC932F',
         
        }]
    };
    
    const options = {
      responsive: true,
      tooltips: {
        mode: 'label'
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false
            }
          }
        ]
      }
    };
    
    const plugins = [{
        afterDraw: (chartInstance, easing) => {
            const ctx = chartInstance.chart.ctx;
            ctx.fillText("", 100, 100);
        }
    }];

		return (
	  <div>
        <Bar
          data={data}
          options={options}
          plugins={plugins} width={282} height={130}
        />
      </div>
		);
	}
}
export default FloatingTransitMap; 