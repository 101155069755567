import React , {useState, useEffect, useRef} from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Preloader from '../../../components/preloader/Preloader';
import {TradeApi} from '../../../../services/service.api';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const TradeDocumentPreview = function({doc}){

    const wrapperRef = useRef(null);
    const [numPages, setNumPages] = useState(null);
    const [url, setUrl] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [width, setWidth] = useState(700);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        TradeApi.getTradeDocument(doc)
        .then(res => {
            setUrl(window.URL.createObjectURL(new Blob([res.data])))
        })
        .catch( e => {
            console.error(e);
        })
    },[doc]);

    useEffect ( () => {     
        if(wrapperRef.current){       
            let wrapperWidth  = wrapperRef.current.offsetWidth; 
            setWidth(wrapperWidth-20); 
        }
    }, [wrapperRef]);

    return <>
    <div className="text-center" ref={wrapperRef}>
        { url && <div className='position-fixed text-right' style={{right: '20px', zIndex: '1000'}}>{`${pageNumber}/${numPages}`}</div>}
        <Document 
            file={url} 
            noData={() => (<div style={{minHeight: '300px'}}>
                <div className="text-center">Retrieving Data</div>
                <Preloader loadingStyle="swirl" loading={true}/>
            </div>)}
            loading={() => (<div style={{minHeight: '300px'}}>
                <div className="text-center">Loading PDF</div>
                <Preloader loadingStyle="swirl" loading={true}/>
            </div>)}
            renderMode="svg"
            onLoadSuccess={onDocumentLoadSuccess} 
            className="m-auto">
            <Page 
                width={width} 
                debug={true} 
                pageNumber={pageNumber}
                loading={() => (<div style={{minHeight: '300px'}}>
                    <div className="text-center">{`Loading Page ${pageNumber} / ${numPages}`}</div>
                    <Preloader loadingStyle="swirl" loading={true}/>
                </div>)}
            />
        </Document>
        {pageNumber!==1 && <span className='position-fixed' style={{top: '50%', left:'20px'}} onClick={e => setPageNumber(pageNumber-1)}><FontAwesomeIcon icon={faChevronLeft} size="3x"/></span>}
        {pageNumber < numPages && <span className='position-fixed' style={{top: '50%', right:'20px'}} onClick={e => setPageNumber(pageNumber+1)}><FontAwesomeIcon icon={faChevronRight} size="3x" /></span>}
        </div>   
    </>
}

export default TradeDocumentPreview;