import React from 'react';
import { EnumsService } from "../../../../../services/service.utils";

const Countries = EnumsService.countries();

const TradeReferences = ({ company }) => (
    <div>
        <h4 className="mt-1">Trade References</h4>
        <div className="table-responsive ">
            <table className="table table-bordered table-hover table-striped">
                <thead>
                    <tr>
                        <th scope="col">Company Name</th>
                        <th scope="col">Contact Name</th>
                        <th scope="col">Telephone No.</th>
                        <th scope="col">Email</th>
                        <th scope="col">Address</th>
                        <th scope="col">City</th>
                        <th scope="col">Zip Code</th>
                        <th scope="col">Country</th>
                    </tr>
                </thead>
                <tbody>
                    {company.tradeReferences &&
                        company.tradeReferences.map((k, i) => {
                            return (
                                <tr key={i}>
                                    <td>{k.name}</td>
                                    <td>{k.contact}</td>
                                    <td>{k.phone}</td>
                                    <td>{k.email}</td>
                                    <td>{k.address.line1 + " " + k.address.line2}</td>
                                    <td>{k.address.city}</td>
                                    <td>{k.address.postal}</td>
                                    <td>{Countries[k.address.country]}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    </div>
);

export default TradeReferences;