import React from "react";
import "./Editable.scss";

const isEditable = false;

class Editable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.children,
      isEditing: false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnEdit = this.handleOnEdit.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleOnCancel = this.handleOnCancel.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isEditing !== prevProps.isEditing) {
      this.setState({ isEditing: this.props.isEditing });
    }
    if (this.props.isEditing !== prevProps.isEditing) {
      this.setState({ isEditing: this.props.isEditing });
    }
  }

  handleOnChange(e) {
    this.setState({ content: e.target.value });
  }

  handleOnEdit(e) {
    this.setState({ isEditing: true });
    if (this.props.onEdit) {
      this.props.onEdit(this.props.name);
    }
  }

  handleOnCancel(e) {
    this.setState({
      content: this.props.children,
      isEditing: false,
    });
    if (this.props.onCancel) {
      this.props.onCancel(this.props.name);
    }
  }

  handleOnSave(e) {
    this.setState({
      isEditing: false,
    });
    if (this.props.onSave) {
      this.props.onSave(this.state.content, this.props.name);
    }
  }

  render() {
    const { name = "default" } = this.props;

    return (
      <React.Fragment>
        {this.state.isEditing ? (
          <React.Fragment>
            <textarea
              name={name}
              value={this.state.content}
              className="form-control"
              onChange={this.handleOnChange}
            />
            <div className="text-right">
              <button
                className="btn btn-link"
                style={{ textTransform: "capitalize", lineHeight: "1" }}
                onClick={this.handleOnCancel}
              >
                Cancel
              </button>
              <button
                className="btn btn-link"
                style={{ textTransform: "capitalize", lineHeight: "1" }}
                onClick={this.handleOnSave}
              >
                Save
              </button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {isEditable && (
              <div className="text-right" data-html2canvas-ignore="true">
                <button
                  className={`btn btn-link editBtn`}
                  onClick={this.handleOnEdit}
                >
                  Edit
                </button>
              </div>
            )}
            <p className="contentText">{this.state.content}</p>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Editable;
