import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import {push} from "connected-react-router";
import { Layout } from '../index';
import './register.scss';
import { RegisterCompany } from './RegisterCompany';
import { AccountApi } from '../../../services';
import logo from '../../../static/img/logo.png';
import { AppErrors } from '../../components/errors';
import { LoadingRegisterData } from '../../../modules/module.loading';

class RegisterClass extends Component {
    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);

        let isEmpty = value => !value || value === undefined || value === '';
        let isEmail = value => {
            // eslint-disable-next-line
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(String(value).toLowerCase());
        };

        let company = {};
        this.initField(company, 'name', 'Registered Company Name', '', isEmpty);
        this.initField(company, 'site', 'Company Web Site', '', '');
        this.initField(company, 'companyType', 'Company Type', '', isEmpty);
        this.initField(company, 'country', 'Country', '', isEmpty);
        this.initField(company, 'regNumber', 'Company Registration No.', '', isEmpty);
        this.initField(company, 'email', 'Registration Contact Email', '', isEmail);
        this.initField(company, 'phone', 'Registration Contact Telephone No.', '', isEmpty);
        this.initField(company, 'contact', 'Registration Contact Name', '', isEmpty);
        this.initField(company, 'about', 'Brief Company Profile', '', isEmpty);

        this.state = {
            company: company,
            required: null,
            serverError: '',
            showGreetings: false
        };
    }

    initField(state, name, label, value, required) {
        state[name] = {
            value: value,
            required: required,
            label: label
        };
        return state;
    }

    componentDidMount() {
    }

    setCompanyField(name, value) {
        if (name !== '') {
            let company = this.state.company;
            let field = company[name];
            field.value = value;
            this.setState({ company: company, required: null });
        }
    }

    validate(container) {
        let required = {};
        Object.keys(container).map(key => {
            let v = container[key];
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
            }
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }

        return true;
    }

    getStateValue(container) {
        let result = {};
        Object.keys(container).map(key => {
            result[key] = container[key].value;
            return false;
        });
        return result;
    }

    submit() {
        this.props.LoadingRegisterData(true);
        AccountApi.createJoiningRequest({
            company: this.getStateValue(this.state.company)
        })
            .then(() => {
                this.props.LoadingRegisterData(false);
                this.setState({
                    showGreetings: true
                });
            }, () => {
                this.props.LoadingRegisterData(false);
                this.setState({
                    serverError: AppErrors["registration_company_name_exist"]
                });
            })
            .catch(error => {
                this.props.LoadingRegisterData(false);
                this.setState({
                    serverError: AppErrors["registration_company_name_exist"]
                });
            });
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.login();
        }
    }

    render() {
        return (
            <Layout {...this.props}>
                <div className="register">
                    <ul className="account-tabs">
                        <li className="account-tab active">Registration of Interest</li>
                    </ul>
                    {this.state.showGreetings ? (
                        <div className="user-greetings">
                            <h3 className="text-lg">
                                Please check your e-mail to find confirmation of your registration of interest.
                            </h3>
                        </div>
                    ) : (
                            <div className="register-wrapper">
                                <div className="register-logo">
                                    <img src={logo} alt="Rice exchange logo" />
                                </div>
                                <div className="row">
                                    <ul className="errors">{this.state.serverError &&
                                        <li>{this.state.serverError}</li>}</ul>
                                    <RegisterCompany
                                        isRegistering={this.props.loading}
                                        state={this.state.company}
                                        setField={(name, value) => this.setCompanyField(name, value)}
                                        validation={this.state.required}
                                        onComplete={e => {
                                            e.preventDefault();
                                            if (this.validate(this.state.company)) {
                                                this.submit();
                                            }
                                        }}
                                    />

                                </div>
                            </div>
                        )}
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account,
    loading: state.loading.registerLoading,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            navigate: () => push('/'),
            LoadingRegisterData
        },
        dispatch
    );

export const Register = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RegisterClass);
