// var countries = require("i18n-iso-countries");
// countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import tz from 'timezonelist-js';
import _ from 'lodash';
import {JSON as ports}  from 'rx-sea-ports'
import axios from 'axios'
import moment from 'moment-timezone';
import { CURRENCYOPT,getNonIntegratedInspector } from './service.values';


const countries = require('simple-iso-countries');

// RDEV-1867 Order object properties by value
export const orderByValue = (object) => {
    const res = {}
    Object.entries(object)
    .sort(([keyA, valueA], [keyB, valueB]) => (valueA > valueB)?1:-1)
    .forEach(([key, value]) => {res[key] = value})
    return res;
  }

// Format bill object retrieved by the API /bill
export const formatBill = () => {

}


export const PortService = {
    getJSON: () => ports,
    getAsMap: UNLOC => {
        return _.reduce(ports, function(result, value, key) {
            if(!UNLOC || key.match(`^${UNLOC}`)){
                result[key] = `${value.name} (${key.slice(2)})`;
            }
            return result;
        }, {});
    },
    getAsList: UNLOC => {
        let list =  _.chain(ports)
            .map((value, key) => ({ 
                value: key,
                label: `${value.name} (${key.slice(2)})`
            }))
            .filter(item => {
                if(Array.isArray(UNLOC)){
                    return UNLOC.includes(item.value.slice(0, 2));
                }
                return !UNLOC || item.value.match(`^${UNLOC}`)
            })
            .orderBy(['label'], ['asc'])
            .value();
        return list;
    },
    getName: value => (ports[value] ? ports[value].name : value)  
}

export const EnumsService = {
    years: (min, max) => {
        let res = {};
        if (max > min) {
            let i = min;
            while (i < max) {
                i++;
                res[i] = i;
            }
        }
        return res;
    },
    yearsFrom: (from, count) => EnumsService.years(from, from + count),
    yearsTo: (to, count) => EnumsService.years(to - count, to),
    countries: () => orderByValue(countries),
    cities: () => [
        {country: 'BY', name: 'Minsk'},
        {country: 'US', name: 'Washington'},
        {country: 'KH', name: 'Phnom Penh'},
        {country: 'TH', name: 'Bangkok'},
        {country: 'VN', name: 'Hanoi'},
        {country: 'MM', name: 'Naypyidaw'},
        {country: 'IN', name: 'New Delhi'},
        {country: 'PK', name: 'Islamabad'},
        {country: 'CN', name: 'Peking'},
        {country: 'ES', name: 'Madrid'},
        {country: 'FR', name: 'Paris'},
        {country: 'GR', name: 'Athens'},
        {country: 'IT', name: 'Rome'},
        {country: 'EG', name: 'Cairo'},
        {country: 'BR', name: 'Brasília'},
        {country: 'GY', name: 'Georgetown'},
        {country: 'UY', name: 'Montevideo'},
        {country: 'PY', name: 'Asunción'},
        {country: 'SR', name: 'Paramaribo'},
        {country: 'AR', name: 'Buenos Aires'}
    ],
    ports: ports,
    timezones: tz
};

export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

export const getPropertyByPath = (propertyName, object) => {
    let parts = propertyName.split( "." ),
        length = parts.length,
        i,
        property = object || this;
    if (!parts) {
        return null;
    }

    for ( i = 0; i < length; i++ ) {
        if (!property) {
            return property;
        }
        property = property[parts[i]];
    }

    return property;
};

export const getSelectedCountries = (countriesArray = []) => {
    return countriesArray.reduce((prev, countryCode) => {
        prev[countryCode] = EnumsService.countries()[countryCode];
        return prev;
    }, {})
}

export const renderSelectedOrigins = (origin) => {
    if(origin.includes(',')){
        return Object.values(getSelectedCountries(origin.split(','))).join(', ');
    }else{
        return EnumsService.countries()[origin];
    }
}

// RDEV-1925 Get index.html and compare ETag with local version. 
// In case of new version, force the refresh of the page
export const checkIndexVersion = () => {
    let currentETag = window.sessionStorage.getItem('indexETag');
    axios.get(window.location.origin ).then(res => {
        let newETag = res.headers.etag;

        window.sessionStorage.setItem('indexETag', newETag);
        if(currentETag && currentETag !== newETag) {
            window.location.reload();
        }
    })
}


export const getLocalTimezone =() =>  moment.tz.zone(moment.tz.guess()).abbr(new Date().getTimezoneOffset())

export const MultiOriginService = {
    getOriginData: (origin, origins) => {
        let originData = origins.find(item => item.origin === origin);
        if (originData) return {origin: originData.origin, loadCountry: originData.loadCountry, loadPort: originData.loadPort, price: originData.price};
        return { origin: '', loadCountry: '', loadPort: '', price: '' };
    },
    getOfferedPrices: (origins, currency) => {
        return _.reduce(origins, (prev, item) => {
            if (item.isPriceCheck) {
                prev[item.origin] = `${orderByValue(countries)[item.origin]} (${item.price} ${CURRENCYOPT[currency] || currency})`;
            }
            return prev
        }, {})
    }
}

export const renderPortLabel = value => {
    let label = "";
    if (value) {
        label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
    }
    return label;
}

export const filterInspectionCompany = (inspections, selectedOrigin, contractAmendment) => {
    let result = [];
    let listInspector = null;
    if (!contractAmendment) {
        Object.entries(inspections).forEach(([key, val]) => {
            result.push({ label: val, value: key })
        });
        if (selectedOrigin.length === 1) {
            listInspector = getNonIntegratedInspector(selectedOrigin[0]);
            if (listInspector !== undefined) {
                result.push({ label: "Other Inspectors", value: 1 })
            }
        }
    } else {
        result = { ...inspections }
        listInspector = getNonIntegratedInspector(selectedOrigin);
        if (listInspector !== undefined) {
            result = { ...result, 1: "Other Inspectors" };
        }
    }
    return result;
}