import React, { Component } from 'react'
import MaterialTable from 'material-table';

import ScheduleDetails from './ScheduleDetails'

import {ChevronRight, ArrowUpward} from '@material-ui/icons';


//ICONS
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faExclamationCircle, faShip } from "@fortawesome/free-solid-svg-icons";

class ScheduleTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null
    }
    this.actions = [];
    if(this.props.allowBooking){
      this.actions.push(this.handleOnRouteSelected)
    }
  }

  handleOnRouteSelected = rowData => ({
    icon: () => <span className="btn-popup btn-submit btn-book">Book</span>,
    tooltip: 'Book Voyage',
    onClick: this.props.onSelectRoute,
    sorting: false
  })



  render() {

    const { quote } = this.props;
    if(!quote.data){
      return false;
    }

    const route0 = quote.data.routeSchedulesWithPrices[0];
    const p0 = route0 && route0.price.prices_per_container[0];
    const nbFCL = p0 && p0.bas.qty;

    return (
      <div className="tbl-routes" style={{ maxWidth: '100%' }}>
        <MaterialTable
          columns={[
            { title: 'Departs', field: 'routeScheduleFull.fromLocation.date' },
            { title: 'Arrives', field: 'routeScheduleFull.toLocation.date' },
            { title: 'Vessel', field: 'routeScheduleFull.vessel.name' },
            { title: 'Voyage Number', field: 'routeScheduleFull.voyageNumber' },
            { title: 'Latest gate-in', sorting: false, render: rowData => `${rowData.routeScheduleFull.fromLocation.date} ${rowData.routeScheduleFull.fromLocation.time}`},
            { title: 'Options', sorting: false, render: rowData => quote.data.productOffer.productName },
            { title: `Total price (${nbFCL} containers)`,  field: 'price.total', render: rowData => `${rowData.price.total} (USD)`},
          ]}
          components={{
            Pagination: () => false,
            Toolbar: () => false
          }}
          icons={{
            DetailPanel: ChevronRight,
            SortArrow: ArrowUpward
          }}
          data={quote.data.routeSchedulesWithPrices}
          title="Provional Shipping Quote"
          detailPanel={rowData => 
            <ScheduleDetails routeScheduleDetails={rowData} options={quote.data.productOffer.productName}/>
          }
          actions={this.actions}
          onRowClick={(event, selectedRow, togglePanel) => {
            this.setState({ selectedRow: selectedRow.tableData.id })
            togglePanel()
          }}
          options={{
            actionsColumnIndex: -1,
            sorting: true,
            rowStyle: rowData => ({
              backgroundColor: (this.state.selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
            thirdSortClick: false
          }}
        />
      </div>
    )
  }
}

export default ScheduleTable;