import React from 'react';
import ConfirmModal from './ConfirmModal';
import PropTypes from "prop-types";

/**
 * Display confirm modal 
 *
 * @since  x.x.x
 * @access public
 *
 * @type     {React.Component}
 * @property {content}
 * @property {title}
 * @property {onCancel}
 * @property {onConfirm}
 * @property {show}
 */
const ConfirmModalSubmitCounter = ({onConfirm, loading = false, type = "counter"}) => {
    const typeLabel = type === "counter" ? "a counter" : type === "improve" ? "a improved price" : "an offer";
        
        let confirmationMessage = (
            <p>By clicking confirm I acknowledge that all other terms and conditions have been reviewed and are accepted;
            I furthermore acknowledge that my {type} is valid till the expiry date and that it cannot be cancelled prior to that date unless
            I have received a counterproposal that I have declined. When the counterparty accepts my {type} then
             this will result in a firm commitment. Click 'Confirm' to submit your {type} or 'Back' to go back to previous screen.</p>
        )
        if(type === "improve"){
            confirmationMessage = (
                <p>
                    I confirm that I am improving my proposal. The previous one sent therefore becomes null and void
                    and is replaced by the improved proposal. Click 'CONFIRM' to submit the improved proposal or 'BACK' to go back to previous screen.
                </p>
            )
        }

        return <ConfirmModal loading={loading}
            modalBodyClass = ""
            title={`You are about to submit ${typeLabel}`}
            buttonClass="btn btn-action btn--blue bc-btn__small"
            buttonLabel="Submit"
            onConfirm={onConfirm}
            >
                {confirmationMessage} 
        </ConfirmModal>
}

ConfirmModalSubmitCounter.propsTypes = {
    onConfirm: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.string
}

export default ConfirmModalSubmitCounter;


