import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { changeAuthorisationCode } from '../../modules/module.adminManagement';
import FormInputField from '../components/form/FormInputField';
import Preloader from '../components/preloader/Preloader';

class AuthorisationCode extends Component {
    state = {
        showModal: false,
        saveData: {
            code: ""
        },
        confirmCode: "",
        errors: {},
        inProgress: false
    };

    openModal = () => {
        this.setState({
            ...this.state,
            showModal: true,
            saveData: {
                code: ""
            },
            confirmCode: "",
            errors: {},
            saveSuccess: false
        });
    };

    saveCode = (e) => {
        this.setState({ ...this.state, saveData: { code: e.target.value } });
    }

    saveConfirmCode = (e) => {
        this.setState({ ...this.state, confirmCode: e.target.value });
    }

    validate = () => {
        if (this.state.saveData.code === "") {
            this.setState({ ...this.state, errors: { code: this.state.saveData.code } });
            return false;
        }
        if (this.state.confirmCode === "") {
            this.setState({ ...this.state, errors: { confirmCode: this.state.confirmCode } });
            return false;
        }
        if (this.state.saveData.code.length !== 4) {
            this.setState({ ...this.state, errors: { invalidLength: true } });
            return false;
        }
        if (this.state.saveData.code !== this.state.confirmCode) {
            this.setState({ ...this.state, errors: { codeNotMatch: true } });
            return false;
        }
        this.setState({ ...this.state, errors: {} });
        return true;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validate()) {
            this.setState({ ...this.state, inProgress: true });
            changeAuthorisationCode(this.state.saveData).then(() => {
                this.closeModal();
                this.setState({ ...this.state, saveSuccess: true, inProgress: false });
            }, () => {
                this.setState({ ...this.state, errors: { serverError: true }, inProgress: false });
            }).catch(() => {
                this.setState({ ...this.state, errors: { serverError: true }, inProgress: false });
            });
        }
    }

    renderModal = () => {
        return (
            <div className="modal__container">

                <form  autoComplete='off'
                    className="modal__wrapper px-0 py-0 authcode"
                    onSubmit={this.handleSubmit}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-header-center"> 
                            <h5 className="modal-title create-heading">Change Authorisation Code</h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            {this.state.errors.codeNotMatch ? (
                                <div className="alert alert-danger alert-sm" role="alert">
                                    Authorisation Code and Confirm Code do not match.
                                </div>
                            ) : ''}
                            {this.state.errors.invalidLength ? (
                                 <div className="alert alert-danger alert-sm" role="alert">
                                Authorisation Code should be 4 digits.
                                </div>
                            ) : ''}
                            {this.state.errors.serverError ? (
                                 <div className="alert alert-danger alert-sm" role="alert">
                               There was some issue saving Authorisation Code.

                                </div>
                            ) : ''}
                            <div className="form-group">
                            <div className="create-req__wrapper mb-0">
                                <div className="form-input">
                                    <label className="label">New Authorisation Code</label>
                                    <FormInputField
                                    type="password"
                                    validation={this.state.errors}
                                    name="code"
                                    required={true}
                                    maxlength="4"
                                    value={this.state.saveData.code}
                                    onChange={this.saveCode}
                                />                                           
                                </div>     
                            </div>     
                              
                            </div>
                            <div className="form-group">
                            <div className="create-req__wrapper mb-0">
                                <div className="form-input">
                                    <label className="label">Confirm Authorisation Code</label>
                                    <FormInputField
                                    type="password"
                                    validation={this.state.errors}
                                    name="confirmCode"
                                    required={true}
                                    maxlength="4"
                                    value={this.state.confirmCode}
                                    onChange={this.saveConfirmCode}
                                />                                      
                                </div>     
                            </div>    
                              
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" onClick={this.closeModal} className="btn-popup btn-cancel ">
                                <FontAwesomeIcon icon={faTimes} /> Back
                            </button>
                            <button type="submit" className="btn-popup btn-submit__authcode">
                                <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                    <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Submit</span>
                                </Preloader>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    };

    render() {
        return (
            <>
                <div className="card-body rbac-body">
                    {this.state.showModal && this.renderModal()}
                    <h5 className="create-heading">Current Authorisation Code</h5>
                    <div className="row mt-5">
                        <div className="col-md-2">
                            <label className="ins-label">Authorisation Code</label>
                        </div>
                        <div className="col-md-10">
                            <div className="currcode"><span className="code">******</span>
                            {this.state.saveSuccess ? (
                            
                            <span className="codemsg">
                                 <span className="alert alert-success alert-sm" role="alert">
                                <FontAwesomeIcon icon={faCheckDouble} /> Authorisation Code saved successfully.
                                </span>
                                </span>
                    ) : ''}
                            </div>
                            <button className="btn btn-changeauthcode mt-2 ;" onClick={() => this.openModal()}>Change Authorisation Code</button>
                        </div>
                    </div>

                   
                </div>
            </>
        )
    }

}

export default AuthorisationCode