import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import {push} from "connected-react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

//API service
import { getCompany } from '../../modules/module.platformAdmin';
import { rejectProviderKyc, approveProviderKyc } from '../../modules/module.adminManagement';


import Preloader from '../components/preloader/Preloader';
import FormTextareaField from '../components/form/FormTextareaField';
import CompanyDetails from '../components/kyc/CompanyDetails';
import ShareholderDetails from '../components/kyc/ShareholderDetails';
import UboDetails from '../components/kyc/UboDetails';
import DirectorDetails from '../components/kyc/DirectorDetails';
import AssociatedCompnies from '../components/kyc/AssociatedCompnies';
import TradeReferences from '../components/kyc/TradeReferences';
import CompanyAdminDetails from '../components/kyc/CompanyAdminDetails';
import BankDetails from '../components/kyc/BankDetails';
import CompanyDocuments from '../components/kyc/CompanyDocuments';

class CompanyKycInfo extends Component {

    state = {
        showModal: false,
        modal: "",
        saveData: {
            reason: ""
        },
        errors: {},
        inProgress: false
    }

    UNSAFE_componentWillMount = () => {
        this.props.getCompany(this.props.match.params.id);
    }

    getKYCStatus = (company) => {
        if (company && company.serviceProviders) {
            let currentProvider = company.serviceProviders.filter(c => c.companyID === this.props.providerCompany.companyId);
            if (currentProvider && currentProvider.length) {
                return currentProvider[0].kycStatus;
            }
        }
        return "";
    }

    openModal = (modal) => {
        this.setState({ showModal: true, modal: modal });
    };

    renderModal = (modalName) => {
        switch (modalName) {
            case 'REJECT_REASON':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                if (this.validate()) {
                                    this.closeModal();
                                    this.props.rejectProviderKyc(this.props.match.params.id, { providerCompanyID: this.props.providerCompany.companyId, reason: this.state.saveData.reason });
                                }
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                <div className="modal-header-center"> <h5 className="modal-title">Reject KYC</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="create-req__wrapper">
                                        <div className="form-input">
                                            <label className="control-label">Enter Rejection Reason<span className="reqfield">*</span></label>
                                            <FormTextareaField
                                                validation={this.state.errors}
                                                name="reason"
                                                maxLength="300"
                                                rows="5"
                                                value={this.state.saveData.reason}
                                                onChange={this.saveReason}
                                            />
                                        </div>
                                        {/* <div className="col-md-7">
                                           
                                        </div> */}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel
                                    </button>
                                    <button type="submit" disabled={this.state.inProgress} className="btn-popup btn-submit">
                                        <FontAwesomeIcon icon={faCheck} className="mr-0" /> Reject
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case 'APPROVE_KYC':
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                                this.props.approveProviderKyc(this.props.match.params.id, { providerCompanyID: this.props.providerCompany.companyId });
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                <div className="modal-header-center"><h5 className="modal-title">Approve KYC</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {this.getKYCStatus(this.props.company.company) === "REJECTED" ? (
                                        <p className="center-text">
                                            You are about to set the status of {this.props.company.company.name} from REJECTED TO APPROVED.
                                            <br />
                                            Press back to return to previous screen or press confirm to continue.
                                        </p>
                                    ) :
                                        (
                                            <p className="center-text">
                                                You are about to set the status of <b>{this.props.company.company.name}</b> to APPROVED.
                                                <br />
                                                Press back to return to previous screen or press confirm to continue.
                                            </p>
                                        )}
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel">
                                        <FontAwesomeIcon icon={faTimes} /> Back
                                    </button>
                                    <button type="submit" className="btn-popup btn-submit">
                                        <FontAwesomeIcon icon={faCheck} className="mr-0" /> Confirm
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            default:
                return ''
        }
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            saveData: { reason: "" },
            errors: {}
        });
    };

    saveReason = e => {
        this.setState({ saveData: { reason: e.target.value } });
    };

    validate = () => {
        if (this.state.saveData.reason === "") {
            this.setState({ errors: { reason: this.state.saveData.reason } });
            return false;
        }
        this.setState({ errors: {} });
        return true;
    }

    render() {
        let company = this.props.company ? this.props.company.company : this.props.company;
        let companyKYC = company ? company.companyKYC : undefined;
        let kycStatus = company ? this.getKYCStatus(company) : "";
        return (
            <React.Fragment>
                <Preloader loadingStyle="overflow-spinner" loading={this.props.isloading}>
                    {this.state.showModal && this.renderModal(this.state.modal)}
                    {companyKYC ? (
                        <div className="box__shadow">

                            <div className="content-wrappper">
                                <h3 className="mb-1">KYC Details</h3>
                                <Preloader loadingStyle="swirl" loading={this.props.loading}>
                                    <div className="mb-3">
                                        <span> KYC Status:  {kycStatus}</span>
                                    </div>
                                    <CompanyDetails company={companyKYC}></CompanyDetails>
                                    <ShareholderDetails company={companyKYC}></ShareholderDetails>
                                    <UboDetails company={companyKYC}></UboDetails>
                                    <DirectorDetails company={companyKYC}></DirectorDetails>
                                    <AssociatedCompnies company={companyKYC}></AssociatedCompnies>
                                    <TradeReferences company={companyKYC}></TradeReferences>
                                    <CompanyAdminDetails admin={companyKYC.companyAdmin} ></CompanyAdminDetails>
                                    <BankDetails bank={companyKYC.bank}></BankDetails>
                                    <CompanyDocuments company={companyKYC}></CompanyDocuments>
                                    <div className="text-center mt-4 mb-5">
                                        <NavLink className="btn btn-back  lh-1" exact to="/adminManagement/provider-kyc-list" id="drop-menu">
                                            <FontAwesomeIcon icon={faAngleLeft} /> Back
                                            </NavLink>
                                        {kycStatus === "PENDING" || kycStatus === "APPROVED" ?
                                            (
                                                <React.Fragment>
                                                    <button onClick={() => this.openModal("REJECT_REASON")} className="btn btn-reject lh-1">
                                                        <FontAwesomeIcon icon={faTimes} /> Reject
                                                        </button>
                                                </React.Fragment>)
                                            : ''}
                                        {kycStatus === "PENDING" || kycStatus === "REJECTED" ?
                                            (
                                                <React.Fragment>
                                                    <button disabled={this.state.inProgress} onClick={() => this.openModal("APPROVE_KYC")} className="btn btn-approve lh-1">
                                                        <FontAwesomeIcon icon={faCheck} className="mr-0" /> Approve
                                                    </button>
                                                </React.Fragment>)
                                            : ''}
                                    </div>
                                </Preloader>
                            </div>
                        </div>
                    ) : (
                            <React.Fragment>
                                <div className="dashboard-kyc">
                                    <div className="content">
                                        <div className="row">KYC Details not found</div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                </Preloader>

            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading.requestLoading,
        company: state.platformAdmin.company,
        isloading: state.loading.kycLoading,
        providerCompany: state.account.token
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCompany,
            approveProviderKyc,
            rejectProviderKyc,
            navigate: path => push(path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyKycInfo);
