import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import DetailedInfosWrapper from './DetailedInfosWrapper';
import ContractInfoWrapper from './ContractInfoWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const DetailedInfosModal = ({ show, onClose, isContractInfo, isDeclined, loadingBids,  ...rest}) => {
    if(show){
        return (
            <div className="modal__container confirm-modal">
                <div className={`modal__wrapper px-0 py-0`}>
                    <div className="modal-header">
                        <h5 className="modal-title w-100">History</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true" >&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {isContractInfo ?(
                            <ContractInfoWrapper 
                                isContractInfo={isContractInfo} 
                                isDeclined={isDeclined} 
                                {...rest}
                            /> 
                        ) : (<DetailedInfosWrapper {...rest}/>)}
                    </div>
                    <div className="modal-footer">
                        <button className="btn-popup btn-cancel" type="button" onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes}/>&nbsp;Close
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

DetailedInfosModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    isDeclined: PropTypes.bool,
    isContractInfo: PropTypes.bool,
    loadingBids: PropTypes.bool
}

const mapStateToProps = state => ({loadingBids: state.loading.bidLoading});

export default connect(mapStateToProps, null)(DetailedInfosModal);