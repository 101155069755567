import React, {useState} from 'react';
import moment from 'moment-timezone';
import ConfirmModal from './ConfirmModal';
import { DATEFORMATHOURS } from '../../../../../services/service.values';
import FormDateField from '../../../../components/form/FormDateField';

const initialState = {
    bidValidateDate: {
        label: 'Validity Date',
        value: undefined,
        required: value => value === null || value === undefined || value === '' || moment().diff(value) > 0,
        touched: false
    }
}

const ConfirmModalAcceptOffer = ({
    trade,
    loading,
    buttonClass,
    onAcceptOffer,
    bidInfoHandler = () => {}
}) => {
    const [request, setRequest] = useState({...initialState});
    const [required, setRequired] = useState({});

    const CUSTOM_COMPONENT_STYLE = {
        wrapperClassName: "form-group",
        labelClassName: "label",
        inputClassName: "form-control",
        isInvalidClassName: "is-invalid select_error",
        isValidClassName: 'is-valid',
    };

    const validate = (container) => {
        let invalidFields = {};
        Object.keys(container).map(key => {
            let v = container[key];
            if (v && v.required && v.required(v.value)) {
                invalidFields[key] = v;
            }
            return false;
        });
        if (Object.keys(invalidFields).length > 0) {
            setRequired(invalidFields);
            return false;
        }
        setRequired({});
        return true;
    }

    const onConfirm = () => {
        if(validate(request)){
            const counteredBid = {
                ...trade,
                subjectToConfirmInd: true,
                bankOnlyInd: false,
                bidValidateDate: request.bidValidateDate.value.format()
            }
            onAcceptOffer(counteredBid);
        }
    }

    const onCancel = () => {
        setRequest({...initialState});
        setRequired({})
    }

    return(
        <ConfirmModal 
            loading={loading}
            buttonClass = {buttonClass}
            buttonLabel = "Accept"
            modalBodyClass = "py-0"
            onConfirm = {onConfirm}
            onCancel={onCancel}
            buttonHandler={bidInfoHandler}
            closeOnSubmit={Object.keys(required).length === 0 && Boolean(request.bidValidateDate.value)}
            modalWrapperClass="authcode modal__wrapper_show-Calender">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <label className="label">
                            You are about to accept an offer which is subject to final confirmation by the seller. 
                            Click BACK to return to the previous screen or CONFIRM to continue.
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <FormDateField
                            customStyle={CUSTOM_COMPONENT_STYLE}
                            dateFormat={DATEFORMATHOURS}
                            required={true}
                            validation={required}
                            name="bidValidateDate"
                            minDate={moment().add(1, 'hours').minutes(0).seconds(0)}
                            item={request.bidValidateDate}
                            label={true}
                            time={true}
                            onSelect={(name, onSelectDate) => {
                                let updateRequest = {
                                    ...request,
                                    [name]: {
                                        ...request[name],
                                        value: onSelectDate,
                                        touched: true
                                    }
                                }
                                setRequest(updateRequest);
                                validate(updateRequest);
                            }} 
                            popperPlacement="top"/>
                    </div>
                </div>  
        </ConfirmModal>
    )
}

export default ConfirmModalAcceptOffer;