import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from "connected-react-router";
import { NavLink } from 'react-router-dom';

//service api
import { getCompanyList } from '../../../modules/module.platformAdmin';
import { blockCompany } from '../../../modules/module.platformAdmin';
import { unblockCompany } from '../../../modules/module.platformAdmin';

//style
import '../admin.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faEye, faCheck, faTimes, faBan } from "@fortawesome/free-solid-svg-icons";
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

//component
import Footer from '../../components/footer/Footer';
import Preloader from '../../components/preloader/Preloader';
import { EnumsService } from "../../../services/service.utils";
import AdminNav from './AdminNav';
import FormTextareaField from '../../components/form/FormTextareaField';

const Countries = EnumsService.countries();


class kycCompanyList extends Component {

    state = {
        showModal: false,
        modal: "",
        saveData: {
            reason: ""
        },
        companyID: "",
        errors: {},
        inProgress: false
    }

    UNSAFE_componentWillMount = () => {
        this.props.getCompanyList();
    }

    openModal = (modal, companyID) => {
        this.setState({ showModal: true, modal: modal, companyID: companyID });
    };

    renderModal = (modalName) => {
        switch (modalName) {
            case 'BLOCK_REASON':
                return (
                    <div className="modal__container"
                        onSubmit={e => {
                            e.preventDefault();
                            if (this.validate()) {
                                this.blockCompany();
                            }
                        }}>
                        <form className="modal__wrapper px-0 py-0">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title w-100">Block Company</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="row mb-4 mt-4">
                                        <div className="col-md-3 offset-md-1">
                                            <label className="control-label">Enter Block Reason<span className="reqfield">*</span></label>
                                        </div>
                                        <div className="col-md-7">
                                            <FormTextareaField
                                                validation={this.state.errors}
                                                name="reason"
                                                maxLength="300"
                                                rows="10"
                                                value={this.state.saveData.reason}
                                                onChange={this.saveReason}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-4">
                                    <button className="btn btn-reject" data-dismiss="modal" onClick={this.closeModal} ><FontAwesomeIcon icon={faTimes} />  Close</button>
                                    <button type="submit" disabled={this.state.inProgress} className="btn btn-approve">
                                        <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                            <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Block</span>
                                        </Preloader>
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                );
            case 'UNBLOCK_REASON':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0"
                            onSubmit={e => {
                                e.preventDefault();
                                if (this.validateUnblock()) {
                                    this.unblockCompany();
                                }
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title w-100">Unblock Company</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="row mb-4 mt-4">
                                        <div className="col-md-3 offset-md-1">
                                            <label className="control-label">Enter Unblock Reason<span className="reqfield">*</span></label>
                                        </div>
                                        <div className="col-md-7">
                                            <FormTextareaField
                                                validation={this.state.errors}
                                                name="unblockReason"
                                                maxLength="300"
                                                rows="10"
                                                value={this.state.saveData.reason}
                                                onChange={this.saveReason}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-4">
                                    <button className="btn btn-reject" data-dismiss="modal" onClick={this.closeModal} ><FontAwesomeIcon icon={faTimes} />  Close</button>
                                    <button type="submit" disabled={this.state.inProgress} className="btn btn-approve">
                                        <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                            <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Unblock</span>
                                        </Preloader>
                                    </button>
                                </div>
                            </div>
                        </form >

                    </div >
                );
            default:
                return null;
        }
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            saveData: { reason: "" },
            companyID: "",
            errors: {}
        });
    };

    saveReason = e => {
        this.setState({ saveData: { reason: e.target.value } });
    };

    validate = () => {
        if (this.state.saveData.reason === "") {
            this.setState({ errors: { reason: this.state.saveData.reason } });
            return false;
        }
        this.setState({ errors: {} });
        return true;
    }

    validateUnblock = () => {
        if (this.state.saveData.reason === "") {
            this.setState({ errors: { unblockReason: this.state.saveData.reason } });
            return false;
        }
        this.setState({ errors: {} });
        return true;
    }

    blockCompany = () => {
        this.setState({ inProgress: true });
        this.props.blockCompany(this.state.companyID, this.state.saveData, response => {
            this.setState({ inProgress: false });
            if(response && response.success){
                this.closeModal();
                this.props.getCompanyList();
            }
        })
    }

    unblockCompany = () => {
        this.setState({ inProgress: true });
        this.props.unblockCompany(this.state.companyID, this.state.saveData, response => {
            this.setState({ inProgress: false });
            if(response && response.success){
                this.closeModal();
                this.props.getCompanyList();
            }
        })
    }

    render() {
        let classOnDasboardKyc = '';
        if(!this.props.showIconLabel){
            classOnDasboardKyc = 'maximum';
        }
        return (
            <React.Fragment>
                {this.state.showModal && this.renderModal(this.state.modal)}
                <AdminNav />
                <div className={"dashboard-kyc " + classOnDasboardKyc}>
                    <div className="content">
                        <h3 className="mb-1">Company List</h3>
                        <nav className="mb-2">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <NavLink exact to="/" id="drop-menu">
                                        <a href="#/">Dashboard</a>
                                    </NavLink>
                                </li>
                                <li className="breadcrumb-item active">Company List</li>
                            </ol>
                        </nav>
                        <div className="content-wrappper-kyc box-shadow">
                            <Preloader loadingStyle="overflow-spinner" loading={this.props.isloading}>
                                <MaterialTable
                                    columns={[
                                        { title: 'Registered Name', field: 'name' },
                                        { title: 'Registration No', field: 'regNumber' },
                                        {
                                            title: 'Country', field: 'country', render: rowData =>
                                                <span className="exchange__overflow">{`${Countries[rowData.country]}`}</span>
                                        },
                                        {
                                            title: 'Telephone No', field: 'phone'
                                        },
                                        { title: 'Company Admin', field: 'companyAdmin', render: rowData => rowData.companyKYC.companyAdmin.name },
                                        { title: 'Block Reason', field: 'blockReason' },
                                        { title: 'Unblock Reason', field: 'unblockReason' },
                                        {
                                            title: 'Actions', sorting: false, render: rowData =>
                                                <span>
                                                    {/* <a href="#/" className="btn-circle admin" data-toggle="tooltip"
                                                        data-placement="top" title="Change company admin">
                                                        <FontAwesomeIcon icon={faUserShield} />
                                                    </a> */}
                                                    {!rowData.isBlocked ? (
                                                        < a href="#/" onClick={() => this.openModal("BLOCK_REASON", rowData.ID)} className="btn-circle block" data-toggle="tooltip"
                                                            data-placement="top" title="Block company">
                                                            <FontAwesomeIcon icon={faBan} />
                                                        </a>
                                                    ) : (
                                                            <a href="#/" onClick={() => this.openModal("UNBLOCK_REASON", rowData.ID)} className="btn-circle unblock" data-toggle="tooltip"
                                                                data-placement="top" title="Unblock company">
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </a>
                                                        )
                                                    }
                                                </span>

                                        }
                                    ]}
                                    data={this.props.kycCompanyList ? this.props.kycCompanyList.companies : []}
                                    title={<nav className="mb-4">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">

                                                <NavLink exact to="/" id="drop-menu">
                                                    <a href="#/">Dashboard</a>
                                                </NavLink>

                                            </li>
                                            <li className="breadcrumb-item active">Company List</li>
                                        </ol>
                                    </nav>}

                                    icons={{
                                        Filter: FilterList,
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        SortArrow: ArrowUpward,
                                    }}
                                    options={{
                                        pageSize: 10,
                                        search: false,
                                        pageSizeOptions: [10, 20, 30, 40, 50],
                                        rowStyle: (rowData, index) => {
                                            const backgroundColor = (index % 2 === 0) ? '#00ff301c' : '#FBFBFB';
                                            return { borderColor: '#c3e6cb', backgroundColor: backgroundColor }
                                        },
                                        thirdSortClick: false
                                    }}
                                />
                            </Preloader>
                        </div>
                    </div>
                </div>
                <div className={"footer-left dashboard-kyc " + classOnDasboardKyc}>
                    <Footer />
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading.requestLoading,
        kycCompanyList: state.platformAdmin.kycCompanyList,
        isloading: state.loading.kycLoading,
        showIconLabel: state.platformAdmin.showIconLabel
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCompanyList,
            blockCompany,
            unblockCompany,
            navigate: path => push('/kycCompanyList' + path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(kycCompanyList);


