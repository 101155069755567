import React from 'react';
import './FormInputField.scss';

export default ({
                    name,
                    value,
                    type,
                    validation,
                    onChange,
                    max,
                    maxlength,
                    placeholder = '', 
                    disabled
                }) => (
    <div className="input-group input-group-table mb-3">
        {value.label &&
        <label htmlFor={name} className="label">
            {value.label}
            <span className="req-field">{value.required ? '*' : ''}</span>
        </label>}
        <div className="input-group-append form-input">
        <input
            type={type}
            placeholder={placeholder}
            value={value.value && Math.abs(value.value)}
            className={`input form-control  ${ validation && validation.hasOwnProperty(name) ? ' input_error ' : ''}`}
            id={name}
            name={name}
            onChange={onChange}
            max={max}
            maxLength={maxlength}
            required={!!value.required}
            disabled={disabled}
        />
       <span className="input-group-text">%</span>
       </div>
       
    </div>
);