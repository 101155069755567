const permissionCheck = (name) => {
    var permissionsObj = {};
    var userPermissions = JSON.parse(sessionStorage.getItem("setPermission"));
    if (userPermissions.length > 0) {
        permissionsObj = Object.entries(userPermissions).map(([i]) => {
            return userPermissions[i].permissions;
        })

        for (var j = 0, len = permissionsObj.length; j < len; j++) {
            if (name in permissionsObj[j]) {
                return 1;
            }
        }
        return 0;
    }
}

export const bypassAuthCodePermission = () => {
    return permissionCheck('bypassAuthorisationCode');
}

export const createTradeRequestPermission = () => {
    return permissionCheck('createTradeRequest');
}

export const negotiateTradePermission = () => {
    return permissionCheck('negotiateTradeRequest');
}
export const viewExchangePermission = () => {
    return permissionCheck('viewExchange');
}
export const viewTradeRequestPermission = () => {
    return permissionCheck('viewTradeRequest');
}

export const viewTradeListPermission = () => {
    return permissionCheck('viewTradeList');
}


export const signContractPermission = () => {
    return permissionCheck('signContract');
}

export const viewContractPermission = () => {
    return permissionCheck('viewContract');
}

export const uploadBagmarkingPermission = () => {
    return permissionCheck('uploadBagMarking');
}

export const approveBagmarkingPermission = () => {
    return permissionCheck('approveBagMarking');
}

export const viewBagmarkingPermission = () => {
    return permissionCheck('viewBagMarkings');
}

export const sendVessalNominationPermission = () => {
    return permissionCheck('sendVessalNomination');
}

export const approveVessalNominationPermission = () => {
    return permissionCheck('approveVessalNomination');
}

export const sendDocInstructionsPermission = () => {
    return permissionCheck('sendDocInstructions');
}

export const viewDocInstructionsPermission = () => {
    return permissionCheck('viewDocInstructions');
}

export const issueDocumentPermission = () => {
    return permissionCheck('issueDocument');
}

export const uploadDocumentPermission = () => {
    return permissionCheck('uploadDocument');
}

export const approveDocumentPermission = () => {
    return permissionCheck('approveDocument');
}

export const releaseDocumentPermission = () => {
    return permissionCheck('releaseDocument');
}

export const paymentPermission = () => {
    return permissionCheck('payment');
}

export const confirmPaymentPermission = () => {
    return permissionCheck('confirmPayment');
}

export const viewCompanyProfilePermission = () => {
    return permissionCheck('viewCompanyProfile');
}

export const manageTenderWhitelistPermission = () => {
    return permissionCheck('manageTenderWhitelist');
}

export const viewInspectionQuotePermission = () => {
    return permissionCheck('viewInspectionQuote');
}
export const viewShippingQuotePermission = () => {
    return permissionCheck('viewShippingQuote');
}
export const negotiateInspectionQuotePermission = () => {
    return permissionCheck('negotiateInspectionQuote');
}
export const negotiateShippingQuotePermission = () => {
    return permissionCheck('negotiateShippingQuote');
}