import React, { Component } from 'react';
import FormPhoneField from '../../../../components/form/FormPhoneField';
import FormInputField from '../../../../components/form/FormInputField';

import './edit-field-modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

class EditFieldModal extends Component {
    state = {
        value: null
    };

    onChange = value => {
        this.setState({value: value || ''});
    };

    renderInput = () => {
        const value = {
            value: this.state.value,
            required: true,
            label: this.props.label
        };
        switch (this.props.type) {
            case 'phone':
                return (
                    <FormPhoneField
                        placeholder="Enter phone number"
                        value={value}
                        name="phone"
                        onChange={this.onChange}
                    />
                );
            case 'text':
                return (
                    <FormInputField
                        name="confirmEmail"
                        maxLength="50"
                        value={value}
                        onChange={(e) => this.onChange(e.target.value)}
                    />
                );
            default:
                return null;
        }
    };

    onSubmit = event => {
        event.preventDefault();
        this.props.onSubmit(this.state.value);
    };

    render() {
        return (
            <div className="modal__container">
                <form className="modal__wrapper px-0 py-0 authcode" onSubmit={this.onSubmit}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-header-center">
                                <h5 className="modal-title">Edit {this.props.label}</h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="edit-field__description">
                                Please enter your new {this.props.descriptionLabel} no.
                            </div>
                            {this.renderInput()}
                            <div className="edit-field__required pt-2"><span className="req-field">*</span> Required fields</div>
                        </div>
                        <div className="modal-footer" >
                            <button type="submit" className="btn-popup btn-submit">
                                <FontAwesomeIcon icon={faCheck} />&nbsp;Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default EditFieldModal;