import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import Preloader from '../../../../components/preloader/Preloader';
import FormRadioField from '../../../../components/form/FormRadioField';
import FormTextareaField from '../../../../components/form/FormTextareaField';
import domtoimage from 'dom-to-image';
import { LOADING_PT_PROCESS } from '../../services/documents.service';

class ConfirmPTDoc extends Component {
    constructor(props) {
        super(props);

        this.CUSTOM_COMPONENT_STYLE = {
            wrapperClassName : "form-group",
            labelClassName :"label",
            inputClassName : "form-control",
            selectClassName : "custom-select",
            isInvalidClassName: "is-invalid input_error",
            isValidClassName: 'is-valid',
            radioColLayout: { left: "d-none", right: "col-auto" },
            radioDisplayClassName: "d-block"
        }
        
        this.state = { 
            request: {},
            required: {},
            capturingImage: false,
            fileToDownload: null
        }
    }

    get draftType(){
        return this.props.trade.payment === 'Letter of credit' ? 'L/C Draft' : this.props.trade.payment === 'Confirmed letter of credit' ? 'Confirmed L/C Draft' : 'Avalised Draft'
    }

    componentDidMount(){
        let isEmpty = value => !value || value === undefined || value === '';
        this.initField('approvePTDoc', '', 'CONFIRMED', isEmpty, false);
        this.initField('requestChanges', 'Change Request', '', false, false);
    }

    initField = (name, label, value, required, touched = true) => {
        this.setState( prevState => {
            return {
                ...prevState,
                request: {
                    ...prevState.request,
                    [name]: { name, label, value, required, touched }
                }
            }
        });
    }

    onSubmitHandler = (event) => {
        event.preventDefault();
        if(this.validate()){
            let data = {
                requestedChanges: this.state.request.requestChanges.value
            }
            let url = `/trade/${this.props.trade.id}/letterOfCredit/${this.props.ptDocDetails.ptDocId}/status/${this.state.request.approvePTDoc.value}`;
            if(this.props.trade.payment === 'Avalised Draft') url = `/trade/${this.props.trade.id}/avalizedDraft/${this.props.ptDocDetails.ptDocId}/status/${this.state.request.approvePTDoc.value}`;
            if(this.props.trade.payment === 'Confirmed letter of credit') url = `/trade/${this.props.trade.id}/confirmedLC/${this.props.ptDocDetails.ptDocId}/status/${this.state.request.approvePTDoc.value}`;
            this.props.onSubmit(url, data, this.state.request.approvePTDoc.value, this.props.onCancel);
        }
    }

    validateField = (name, value) => {
        this.setState(prevState => {
            let request = { ...prevState.request };
            let required = { ...prevState.required };
            request[name].validated = true;

            if (request[name].required && request[name].required(value)) {
                required[name] = prevState.request[name];
            } else {
                delete required[name];
            }
            return { request, required }
        });
    }

    validate = () => {
        let container = { ...this.state.request };
        let required = {};
        Object.keys(container).map(key => {
            let v = container[key];
            container[key].touched = true;
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
            }
            this.setState(prevState => {
                prevState.request[key].validated = true;
                return { ...prevState };
            })
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    setField = (name, value, options = { validate: true, touch: true }) => {
        let request = { ...this.state.request };
            if(name==='approvePTDoc'){
                request[name] = {
                    ...request[name],
                    value,
                    touched: true
                }
                if(value === 'CONFIRMED'){
                    request.requestChanges.value = '';
                    request.requestChanges.required = false;
                }else{
                    if(request.requestChanges.touched){
                        this.validateField('requestChanges', request.requestChanges.value)
                    }
                    request.requestChanges.required = changedValue => !changedValue || changedValue === undefined || changedValue === '';
                }
                this.setState(prevState => {
                    return {
                        ...prevState,
                        request
                    }
                },() => {this.validateField(name, value)})
            }else{
                request[name] = {
                    ...request[name],
                    value,
                    touched: true
                }
                this.setState(prevState => {
                    return {
                        ...prevState,
                        request
                    }
            },() => {this.validateField(name, value)})
        }
    }

    onDownloadHandler = () => {
        if(this.props.ptDocDetails.ptType === 'text'){
            this.setState({capturingImage: true});
            let node = document.getElementById('lc-image-content');
            domtoimage.toJpeg(node, { bgcolor: '#fff' }).then(dataURL => {
                let link = document.createElement('a');
                link.download = `${this.props.trade.payment === 'Avalised Draft' ? 'Avalised Draft' :this.props.trade.payment === 'Confirmed letter of credit' ? 'Confirmed LC Draft' : 'LC Draft'}.jpeg`;
                link.href = dataURL;
                link.click();
                this.setState({capturingImage: false});
            }).catch(() => {
                this.setState({capturingImage: false});
            })
        }else{
            this.props.downloadPaymentTermDoc('download')
        }
            
    }

    render() {
        const draftTextDetails = this.props.ptDocDetails.ptText ? this.props.ptDocDetails.ptText[this.props.ptDocDetails.ptText.length-1] : null;
        return ( 
            <div className="modal__container">
                <div className="modal__wrapper px-0 py-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title w-100">
                                {`View ${this.draftType}`}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onCancel} aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body py-0">
                            <form 
                                onSubmit={this.onSubmitHandler}>
                                    <div className="form-group">
                                        <FormRadioField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="approvePTDoc"
                                            items={{
                                                CONFIRMED: `Approve ${this.draftType}`,
                                                REJECTED: `Request to Change ${this.draftType}`
                                            }}
                                            required={true}
                                            onChange={(event) => this.setField(event.target.name, event.target.value)}
                                            value={this.state.request.approvePTDoc || {}} 
                                            displayBlock={true}/>
                                    </div>
                                    {this.state.request.approvePTDoc &&  this.state.request.approvePTDoc.value === 'REJECTED' && <div className="form-group">
                                        <FormTextareaField
                                            name="requestChanges"
                                            type="text"
                                            value={this.state.request.requestChanges ? this.state.request.requestChanges : {}}
                                            onChange={(event) => this.setField(event.target.name, event.target.value)}
                                            rows="4"
                                            validation={this.state.required}
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}/>
                                    </div>}
                                    <div id="lc-image-content">
                                        {this.props.ptDocDetails.ptType === 'text' && 
                                        <React.Fragment>
                                            <div className="form-group">
                                                <FormTextareaField
                                                    name="ptText"
                                                    type="text"
                                                    value={{
                                                        label: this.draftType,
                                                        required: false,
                                                        value: draftTextDetails.text
                                                    }}
                                                    onChange={() => {}}
                                                    rows="4"
                                                    disabled={true}/>
                                            </div>
                                            <div className="form-group">
                                                <FormTextareaField
                                                    name="additionalInformation"
                                                    type="text"
                                                    value={{
                                                        label: 'Additional information',
                                                        required: false,
                                                        value: draftTextDetails.additionalInformation
                                                    }}
                                                    onChange={() => {}}
                                                    rows="4"
                                                    disabled={true}/>
                                            </div>
                                        </React.Fragment>}
                                    </div>
                            </form>
                        </div>
                        <div className="col-md-12">
                            <div className="text-center mb-2 large">
                                {this.props.ptDocDetails.ptType === 'document' ? `Please click Preview or Download to review uploaded ${this.draftType} before approving or rejecting.` : `Please click Download to review ${this.draftType} before approving or rejecting.`}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className={`btn-popup btn-cancel`} type="button" onClick={this.props.onCancel} >
                                <FontAwesomeIcon icon={faTimes} />&nbsp;Cancel
                            </button>
                            <button className={`btn-popup btn-success`} type="button" onClick={this.onDownloadHandler}>
                                <Preloader loadingStyle="dots" loading={this.state.capturingImage || this.props.loading === LOADING_PT_PROCESS.DOWNLOAD}>
                                    <FontAwesomeIcon icon={faDownload} />&nbsp;Download
                                </Preloader>
                            </button>
                            {this.props.ptDocDetails.ptType === 'document' && <button className={`btn-popup btn-success`} type="button" onClick={() => this.props.downloadPaymentTermDoc('preview')}>
                                <Preloader loadingStyle="dots" loading={this.props.loading === LOADING_PT_PROCESS.PREVIEW}>
                                    <FontAwesomeIcon icon={faEye} />&nbsp;Preview
                                </Preloader>
                            </button>}
                            <button 
                                className={`btn-popup btn-submit`}  
                                type="button" 
                                onClick={this.onSubmitHandler}
                                disabled={this.props.loading}>
                                    <Preloader loadingStyle="dots" loading={this.props.loading === LOADING_PT_PROCESS.CONFIRM}>
                                        <FontAwesomeIcon icon={faCheck} />&nbsp;Submit
                                    </Preloader>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ConfirmPTDoc;