import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye} from "@fortawesome/free-solid-svg-icons";
import NumberFormat from 'react-number-format';
import { CURRENCYOPT } from '../../../../services/service.values';
import { EnumsService } from '../../../../services';

const Countries = EnumsService.countries();

class CardOfferCollapse extends React.Component {

    constructor(props){
        super(props);
        this.handleOnAdd = this.handleOnAdd.bind(this);
    }

    updateTotalPrice = (enteredPrice) => {
        let totalPrice = enteredPrice;
        if(typeof this.props.bid.optionsPackagingTypePremOrDiscount === 'number'){
            totalPrice += this.props.bid.optionsPackagingTypePremOrDiscount;
        }
        if(typeof this.props.bid.optionsQualityTypePremOrDiscount === 'number' ){
            totalPrice += this.props.bid.optionsQualityTypePremOrDiscount 
        }
        if(typeof this.props.bid.optionsShippingTypePremOrDiscount === 'number'){
            totalPrice += this.props.bid.optionsShippingTypePremOrDiscount 
        }
        if(typeof this.props.bid.optionsTypeOfRicePremOrDiscount === 'number'){
            totalPrice += this.props.bid.optionsTypeOfRicePremOrDiscount 
        }
        return totalPrice.toFixed(2);
    }

    renderTotalPrice = () => {
        return this.updateTotalPrice(this.props.bid.price);
        
    }

    renderMultiOriginPrice = () => {
        return this.props.bid.origin.split(',').map((origin,index) => {
            let isExist = this.props.bid.multipleOriginPrice.find(item => item.origin === origin);
            return (isExist && isExist.isPriceCheck) ? (
                <NumberFormat
                    key={index}
                    displayType="text" 
                    value={this.updateTotalPrice(isExist.price)}  
                    renderText={text => <div>{text}</div>} 
                    thousandSeparator={true}
                    suffix={` ${CURRENCYOPT[this.props.bid.currency] || this.props.bid.currency} (${Countries[isExist.origin]})`}/>
            ):(<div key={index}>-</div>) 
        })
    }

    handleOnAdd(){
        this.props.onAdd(this.props.bid.ID);
    }

    render() {

        let counterpartyCompanyName;
 
        if(this.props.companyId === this.props.bid.fromCompanyID){
            counterpartyCompanyName = this.props.bid.toCompanyName;
        }else{
            counterpartyCompanyName = this.props.bid.fromCompanyName;
        }

        return  (<div className="card compare-mini">
                <div className="row">
                    <div className="col">
                        <div className="pl-1 pt-1">{ counterpartyCompanyName }</div>
                        {this.props.showMultiOriginPrice ? 
                        <div className="pl-1 pb-1">{this.renderMultiOriginPrice()}</div> 
                        : 
                        <div className="pl-1 pb-1"> <NumberFormat value={this.renderTotalPrice()} thousandSeparator={true} displayType={'text'} prefix={this.props.bid.currency}/></div>}
                    </div>
                    <div className="col text-right">
                        <button className="btn btn--blue btn-sm" onClick={this.handleOnAdd}>
                        <FontAwesomeIcon icon={faEye}/>
                        </button>
                    </div>
            </div>
        </div>)
    }
}

export default CardOfferCollapse;
