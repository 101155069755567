import { SubstituteNominationApi } from '../services/service.api';
import { LoadingNominationInfo } from './module.loading';

export const GET_SUBSTITUTE_NOMINATION = 'nominaton/SUBSTITUTE_NOMINATION';
export const CLEAR_SUBSTITUTEINFO = 'nominaton/CLEAR_SUBSTITUTEINFO';
export const GET_SUBSTITUTE_AUDIT = 'nominaton/GET_SUBSTITUTE_AUDIT';



function getInitialState() {
    return {
        substituteInfo: [],
        substituteAuditLog: []
    };
}


export default (state = getInitialState(), action = {}) => {
    switch (action.type) {

        case GET_SUBSTITUTE_NOMINATION:
            return {
                ...state,
                substituteInfo: action.payload
            };
        case CLEAR_SUBSTITUTEINFO:
            return getInitialState()

        case GET_SUBSTITUTE_AUDIT:
            return {
                ...state,
                substituteAuditLog: action.payload
            };

        default:
            return state;
    }
};


export const substituteNominateVessel = (id, params, cb) => {
    return dispatch => {
        LoadingNominationInfo(dispatch, true);
        SubstituteNominationApi.substituteNominateVessel(id, params)
            .then(response => {
                LoadingNominationInfo(dispatch, false);
                getSubstituteNominateVessel(id)(dispatch);

                cb({
                    ...response
                });
            })
            .catch(error => {
                console.error(error);
                LoadingNominationInfo(dispatch, false);
            })
    }
};

export const getSubstituteNominateVessel = id => {
    return dispatch => {
        SubstituteNominationApi.getSubstituteNominateVessel(id)
            .then(response => {
                dispatch({
                    type: GET_SUBSTITUTE_NOMINATION,
                    payload: response.data.substituteVessel
                });
            }).catch(error => {
                console.error(error);
            })
    }
};

export const substituteAcceptNewLaycanDates = (id, params, cb) => {
    return dispatch => {
        LoadingNominationInfo(dispatch, true);
        SubstituteNominationApi.substituteAcceptNewLaycanDates(id, params)
            .then(response => {
                getSubstituteNominateVessel(id)(dispatch);
                LoadingNominationInfo(dispatch, false);
                cb({
                    ...response
                });
            })
            .catch(error => {
                console.error(error);
                LoadingNominationInfo(dispatch, false);
            })
    }
};

export const substituteVesselReject = (id, params, cb) => {
    return dispatch => {
        LoadingNominationInfo(dispatch, true);
        SubstituteNominationApi.substituteVesselReject(id, params)
            .then(response => {
                getSubstituteNominateVessel(id)(dispatch);
                LoadingNominationInfo(dispatch, false);
                cb({
                    ...response
                });
            })
            .catch(error => {
                console.error(error);
                LoadingNominationInfo(dispatch, false);
            })
    }
};

export const substituteVesselAccept = (id, params, cb) => {
    return dispatch => {
        LoadingNominationInfo(dispatch, true);
        SubstituteNominationApi.substituteVesselAccept(id, params)
            .then(response => {
                getSubstituteNominateVessel(id)(dispatch);
                LoadingNominationInfo(dispatch, false);
                cb({
                    ...response
                });
            })
            .catch(error => {
                console.error(error);
                LoadingNominationInfo(dispatch, false);
            })
    }
};

export const ClearSubstituteNominationInfo = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_SUBSTITUTEINFO,
        })
    }
};

export const substitueAuditLogs = id => {
    return dispatch => {
        LoadingNominationInfo(dispatch, true);
        SubstituteNominationApi.substitueAuditLogs(id)
            .then(response => {
                LoadingNominationInfo(dispatch, false);
                dispatch({
                    type: GET_SUBSTITUTE_AUDIT,
                    payload: response.data.substituteVessel
                });
            })
            .catch(error => {
                console.error(error);
                LoadingNominationInfo(dispatch, false);
            })
    }
};







