import React from 'react';

import ConfirmModal from './ConfirmModal';

const ConfirmModalTenderReject = function ({ subscription, ...rest }) {
    return <ConfirmModal loading={subscription}
        title="Reject Subscription to Tender"
        modalWrapperClass="w-auto"
        {...rest}>
        <p className="pb-2">{`You are about to reject the request of ${subscription.subscribedByUser.companyName} to participate in the tender.`}</p>
        <p className="pb-2">{`Click 'confirm' to continue or 'back' to return to the previous screen`}</p>
        {subscription.rejectError && <p className="text-danger pt-2">{`An error occured : ${subscription.rejectError}`}</p>}
    </ConfirmModal>
}

export default ConfirmModalTenderReject; 