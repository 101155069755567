import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import FormTextareaField from '../../../../components/form/FormTextareaField';
import FormInputField from '../../../../components/form/FormInputField';
import domtoimage from 'dom-to-image';
import Preloader from '../../../../components/preloader/Preloader';
import { LOADING_PT_PROCESS } from '../../services/documents.service';

const CompletedPTDoc = ({ptDocDetails, downloadPaymentTermDoc, loading, trade, onClose}) => {
    const [capturingImage, setCapturingImage] = useState(false);
    const captureImage = (event) => {
        event.preventDefault()
        setCapturingImage(true);
        let node = document.getElementById('pt-image-content');
        domtoimage.toJpeg(node, { bgcolor: '#fff' }).then(dataURL => {
            let link = document.createElement('a');
            link.download = `${trade.payment === 'Letter of credit' ? 'LC Draft' : trade.payment === 'Confirmed letter of credit' ? 'Confirmed LC Draft' : 'Avalised Draft'}.jpeg`;
            link.href = dataURL;
            link.click();
            setCapturingImage(false);
        }).catch(() => {
            setCapturingImage(false);
        })
    };
    const file = ptDocDetails.files && ptDocDetails.files[0];
    const draftType = trade.payment === 'Letter of credit' ? 'L/C Draft' : trade.payment === 'Confirmed letter of credit' ? 'Confirmed L/C Draft' : 'Avalised Draft'
    const draftTextDetails = ptDocDetails.ptText ? ptDocDetails.ptText[ptDocDetails.ptText.length-1] : null; 

    return(
        <div className="modal__container">
            <div className="modal__wrapper px-0 py-0" id="lc-wrapper">
                <div className="modal-content">
                    <div id="pt-image-content">
                        <div className="modal-header">
                            <h5 className="modal-title w-100">View {draftType}</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {ptDocDetails.ptType === 'document' && <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormInputField
                                            name="ptDoc"
                                            type="text"
                                            value={{
                                                label: draftType,
                                                required: false,
                                                value: `${file.fileName || file.name}`
                                            }}
                                            onChange={() => {}}
                                            rows="4"
                                            disabled={true}/>
                                    </div>
                                </div>
                            </div>}
                            {ptDocDetails.ptType === 'text' && <div className="form-group">
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormTextareaField
                                            name="ptText"
                                            type="text"
                                            value={{
                                                label: draftType,
                                                required: false,
                                                value: draftTextDetails.text
                                            }}
                                            onChange={() => {}}
                                            rows="4"
                                            disabled={true}/>
                                    </div>
                                </div>
                            </div>}
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormTextareaField
                                            name="additionalInformation"
                                            type="text"
                                            value={{
                                                label: 'Additional information',
                                                required: false,
                                                value: ptDocDetails.ptType === 'text' ? draftTextDetails.additionalInformation : file.remark
                                            }}
                                            onChange={() => {}}
                                            rows="4"
                                            disabled={true}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className={`btn-popup btn-cancel`} type="button" onClick={onClose} >
                            <FontAwesomeIcon icon={faTimes} />&nbsp;Close
                        </button>
                        {ptDocDetails.ptType === 'text' ? (
                            <button className={`btn-popup btn-submit`} type="button" onClick={captureImage}>
                                <Preloader loadingStyle="dots" loading={capturingImage}>
                                    <FontAwesomeIcon icon={faDownload} />&nbsp;Download
                                </Preloader>
                            </button>
                        ):(
                            <React.Fragment>
                                <button className={`btn-popup btn-success`} type="button" onClick={() => downloadPaymentTermDoc('preview')}>
                                    <Preloader loadingStyle="dots" loading={loading === LOADING_PT_PROCESS.PREVIEW}>
                                        <FontAwesomeIcon icon={faEye} />&nbsp;Preview
                                    </Preloader>
                                </button>
                                <button className={`btn-popup btn-submit`} type="button" onClick={() => downloadPaymentTermDoc('download')}>
                                    <Preloader loadingStyle="dots" loading={loading === LOADING_PT_PROCESS.DOWNLOAD}>
                                        <FontAwesomeIcon icon={faDownload} />&nbsp;Download
                                    </Preloader>
                                </button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompletedPTDoc;