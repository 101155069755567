import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {push} from "connected-react-router";
import { connect } from 'react-redux';
import {
    PostTradeDocumentProformaInvoice,
    SetTradeStatus,
    GetTradeProformaInvoice,
    UpdateTradeDocumentProformaInvoice
} from '../../../../modules/module.trade';
import moment from 'moment';
import { EnumsService, PortService } from '../../../../services/service.utils';
import { DATEFORMAT } from '../../../../services/service.values';
import Preloader from '../../../components/preloader/Preloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import FormTextareaField from '../../../components/form/FormTextareaField';
import FormInputField from '../../../components/form/FormInputField';
import converter from 'number-to-words';
import { KycApi } from '../../../../services/service.api';
import ImageViewer from '../../../components/ImageViewer';
import { RiceService } from '../../../../services/service.rice';

const Countries = EnumsService.countries();

const defaultFormValues = {

}

class ProformaInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proformaInvoice: {},
            required: {},
            companyDetails: {}
        }
    }

    initField(document, name, label, value, required) {
        this.setState(prevState => {
            prevState[document][name] = {
                name: name,
                label: label,
                required: required,
                value: value
            }
            return prevState;
        })
    }

    componentDidMount() {
        this.initialize(this.props.trade.proformaInvoice || defaultFormValues)
        if(this.props.trade.items.single) {
            KycApi.getById(this.props.trade.items.single.sellerId)
            .then(res => {
                this.setState({companyDetails: res.data.company})
            })
        }    
    }

    // RDEV-1812 - Get seller details -load logo
    componentDidUpdate(prevProps, prevState) {
        if (this.props.trade.proformaInvoice !== prevProps.trade.proformaInvoice) {
            this.initialize(this.props.trade.proformaInvoice);
        }
        if(prevProps.trade.items.single !== this.props.trade.items.single) {
            KycApi.getById(this.props.trade.items.single.sellerId)
            .then(res => {
                this.setState({companyDetails: res.data.company})
            })
        }
    }

    setField(name, value) {
        let documentValue = this.state.proformaInvoice;
        documentValue[name] = {
            ...documentValue[name],
            value: value
        };
        this.setState({
            proformaInvoice: documentValue
        });
    }

    get trade() {
        return this.props.trade.items.single;
    }
        
    get totalAmount() {
        if (this.trade.downPaymentPercentage > 0) {
            return this.trade.downPaymentAmount;
        } else {
            return this.trade.price * this.trade.measure;
        }
    }

    renderPortLabel = value => {
        let label = "";
        if (value) {
            label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
        }
        return label;
    }

    setPaymentValue = () => {
        let initialPayment = `Payment : ${this.trade.payment}\nPayment Period : ${this.trade.paymentPeriod}`;
        // RDEV-1991
        if(this.trade.payment === 'Avalised Draft') {
            initialPayment = initialPayment.concat(`\nAvalised Draft to be issued latest on ${moment(this.trade.ADIssuingDate).format(DATEFORMAT)}`)
        }
        if(['Letter of credit', 'Confirmed letter of credit'].includes(this.trade.payment)) {
            initialPayment = initialPayment.concat(`\nLetter of Credit to be Opened Latest On ${moment(this.trade.LCOpeningDate).format(DATEFORMAT)}`)
        }
        if (this.trade.nameOfBank) {
            initialPayment = initialPayment + `\nName of Buyer's Bank : ${this.trade.nameOfBank}\nLocation of Buyer's Bank : ${this.trade.locationOfBank}\nSwift Code of Buyer's Bank : ${this.trade.swiftCodeOfBank}`;
        }
        if (this.trade.nameOfConfirmingBank) {
            initialPayment = initialPayment + `\nName of Confirming Bank : ${this.trade.nameOfConfirmingBank}\nLocation of Confirming Bank : ${this.trade.locationOfConfirmingBank}\nSwift Code of Confirming Bank : ${this.trade.swiftCodeOfConfirmingBank}`
        }

        return initialPayment;
    }

    setSellerBuyerDetails = (isSeller) => {
        if(isSeller){
            return `${this.trade.seller}, ${this.trade.sellerAddress1.line1}, ${this.trade.sellerAddress1.line2}, ${this.trade.sellerAddress1.postal}, Mob: ${this.props.trade.requestInfo.sellerPhone}\nEmail: ${this.props.trade.requestInfo.sellerEmail}`
        }else{
            return `${this.trade.buyer}, ${this.trade.buyerAddress1.line1}, ${this.trade.buyerAddress1.line2}, ${this.trade.buyerAddress1.postal}, Mob: ${this.props.trade.requestInfo.buyerPhone}\nEmail: ${this.props.trade.requestInfo.buyerEmail}`
        }
    }

    initialize = (proformaInvoice) => {
        const isEmpty = value => !value || value === undefined || value === '';
        this.initField('proformaInvoice', 'invoiceNo', 'Invoice No', proformaInvoice.proformaInvoiceNo || '', isEmpty);
        this.initField('proformaInvoice', 'sellerDetails', 'From', this.setSellerBuyerDetails(true) || '', isEmpty);
        this.initField('proformaInvoice', 'buyerDetails', 'To', this.setSellerBuyerDetails(false) || '', isEmpty);
        if (this.props.isUpdate) {
            this.initField('proformaInvoice', 'invoiceDate', 'Invoice Date', moment(new Date()).format(DATEFORMAT), isEmpty);
        } else {
            this.initField('proformaInvoice', 'invoiceDate', 'Invoice Date', moment(proformaInvoice.proformaInvoiceDate).format(DATEFORMAT) || moment(new Date()).format(DATEFORMAT), isEmpty);
        }
        this.initField('proformaInvoice', 'eventualPackaging', 'Packaging', proformaInvoice.eventualPackaging || this.trade.packaging || '', false);
        this.initField('proformaInvoice', 'brandName', 'Brand Name', proformaInvoice.brandName || '', false);
        this.initField('proformaInvoice', 'payment', this.trade.downPaymentPercentage > 0 ? 'Balance Payment' : 'Payment Terms', this.setPaymentValue() || '', isEmpty);
        this.initField('proformaInvoice', 'payTo', this.trade.downPaymentPercentage > 0 ? 'Remit To' : 'Bank Details for 100 pct Proforma', proformaInvoice.bankDetails || '', isEmpty);
        this.initField('proformaInvoice', 'additionalInformation', 'Aditional Information', proformaInvoice.additionalInformation || '', false);
    }

    validate(container) {
        let required = {};
        let containerKeys = Object.keys(container);
        containerKeys.map(key => {
            let v = containerKeys[key];
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
            }
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    submitForm = (event) => {
        event.preventDefault();
        if (this.validate(this.state.proformaInvoice)) {
            let jsonBody = {
                "proformaInvoiceNo": this.state.proformaInvoice.invoiceNo.value,
                "proformaInvoiceDate": new Date(this.state.proformaInvoice.invoiceDate.value).toISOString(),
                "eventualPackaging": this.state.proformaInvoice.eventualPackaging.value,
                "brandName": this.state.proformaInvoice.brandName.value,
                "payment": this.state.proformaInvoice.payment.value,
                "bankDetails": this.state.proformaInvoice.payTo.value,
                "totalAmount": this.totalAmount,
                "additionalInformation": this.state.proformaInvoice.additionalInformation.value
            }
            if (this.props.isCreate) {
                if (this.trade.downPaymentPercentage > 0) {
                    jsonBody["status"] = "NEW";
                } else {
                    jsonBody["status"] = "COMPLETED";
                }
                this.props.onFormSubmit(this.state.proformaInvoice.invoiceDate.value);
                const tradeId = this.props.id;
                this.props.PostTradeDocumentProformaInvoice(tradeId, jsonBody, () => {
                    this.props.onFormSubmit();
                    if (this.trade.downPaymentPercentage === 0) {
                        this.props.SetTradeStatus('PROFORMA_INVOICE', tradeId);
                    }
                    this.props.GetTradeProformaInvoice(tradeId);
                });
            }
            if (this.props.isUpdate) {
                jsonBody["status"] = 'EDIT';
                jsonBody["proformaInvoiceID"] = this.props.trade.proformaInvoice.proformaInvoiceID;
                this.props.UpdateTradeDocumentProformaInvoice(this.props.id, jsonBody, () => {
                    this.props.GetTradeProformaInvoice(this.props.id);
                    this.props.navigateDocs(this.props.id);
                });
            }

        }
    }

    render() {
        let portOfLoading = this.trade.loadPort ? `${this.renderPortLabel(this.trade.loadPort)}, ${Countries[this.trade.loadCountry]}` : '';
        if (this.props.isCreate) {
            return (
                <form
                    className="trades-dtls__doc-wrapper trades-dtls__invoice-wrapper"
                    onSubmit={event => this.submitForm(event)}>
                    <div className="container-fluid">
                        {this.state.companyDetails.companyLogo && <ImageViewer image={this.state.companyDetails.companyLogo}/>}
                        <h5 className="trades-dtls__doc-heading">Proforma Invoice</h5>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="trades-dtls__input-wrapper">
                                    <FormTextareaField
                                        name="sellerDetails"
                                        type="text"
                                        value={this.state.proformaInvoice.sellerDetails || {}}
                                        onChange={(event) => this.setField(event.target.name, event.target.value)}
                                        disabled={true}
                                        inlineStyle={{ 'height': '70px' }} />
                                </div>
                            </div>
                            <div className="w-100"/>
                            <div className="col-md-6 col-sm-12">
                                <div className="trades-dtls__input-wrapper">
                                    <FormTextareaField
                                        name="buyerDetails"
                                        type="text"
                                        value={this.state.proformaInvoice.buyerDetails || {}}
                                        onChange={(event) => this.setField(event.target.name, event.target.value)}
                                        disabled={true}
                                        inlineStyle={{ 'height': '70px' }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="trades-dtls__input-wrapper">
                                    <FormInputField
                                        name="invoiceNo"
                                        type="text"
                                        value={this.state.proformaInvoice.invoiceNo || {}}
                                        onChange={(event) => this.setField(event.target.name, event.target.value)}
                                        disabled={this.props.isPreview ? true : false} />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="trades-dtls__input-wrapper">
                                    <FormInputField
                                        name="invoiceDate"
                                        type="text"
                                        disabled={true}
                                        value={this.state.proformaInvoice.invoiceDate || {}} />
                                </div>
                            </div>
                            {this.trade.incoterm === 'FOB' ? <div className="col-sm-4">
                                <div className="trades-dtls__input-wrapper">
                                    <FormInputField
                                        name="portOfLoading"
                                        disabled={true}
                                        type="text"
                                        value={{ label: 'Port of Loading', value: portOfLoading }} />
                                </div>
                            </div> :
                                <div className="col-sm-4">
                                    <div className="trades-dtls__input-wrapper">
                                        <FormInputField
                                            name="portOfDischarge"
                                            disabled={true}
                                            type="text"
                                            value={{ label: 'Port of Discharge', value: `${this.renderPortLabel(this.trade.destPort)}, ${Countries[this.trade.destCountry]}` }} />
                                    </div>
                                </div>}
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="trades-dtls__input-wrapper">
                                    <FormInputField
                                        name="contractNo"
                                        type="text"
                                        disabled={true}
                                        value={{ label: 'Contract No', value: this.trade.tradeRefId ? this.trade.tradeRefId : this.trade.id }} />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="trades-dtls__input-wrapper">
                                    <FormInputField
                                        name="contractDate"
                                        type="text"
                                        disabled={true}
                                        value={{ label: 'Contract Date', value: moment(this.trade.createdAt).format(DATEFORMAT) }} />
                                </div>
                            </div>
                            <div className="col-sm-4">
                            <div className="trades-dtls__input-wrapper">
                                <FormInputField
                                    name="eventualPackaging"
                                    type="text"
                                    value={this.state.proformaInvoice.eventualPackaging || {}}
                                    onChange={(event) => this.setField(event.target.name, event.target.value)}
                                    disabled={true} />
                                    </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                            <div className="trades-dtls__input-wrapper">
                                <FormInputField
                                    name="delieveryLoadStartDate"
                                    type="text"
                                    disabled={true}
                                    value={{ label: this.trade.incoterm !== 'FOB' ? 'Delivery Start Date' : 'Load Start Date', value: moment(this.trade.deliveryStartDate).format(DATEFORMAT) }} />
                           </div>
                            </div>
                            <div className="col-sm-4">
                            <div className="trades-dtls__input-wrapper">
                                <FormInputField
                                    name="delieveryLoadEndtDate"
                                    type="text"
                                    disabled={true}
                                    value={{ label: this.trade.incoterm !== 'FOB' ? 'Delivery End Date' : 'Load End Date', value: moment(this.trade.deliveryEndDate).format(DATEFORMAT) }} />
                           </div>
                            </div>
                            <div className="col-sm-4">
                            <div className="trades-dtls__input-wrapper">
                                <FormInputField
                                    name="brandName"
                                    type="text"
                                    value={this.state.proformaInvoice.brandName || {}}
                                    onChange={(event) => this.setField(event.target.name, event.target.value)}
                                    disabled={this.props.isPreview ? true : false} />
                                    </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <div className="col-sm-12">
                            <div className="trades-dtls__input-wrapper">
                                <div className="trades-dtls__invoice-th">
                                    <div className="trades-dtls__invoice-td">Description of goods </div>
                                    <div className="trades-dtls__invoice-td">Unit price ({this.trade.currency === 'USD' ? 'US$' : this.trade.currency})</div>
                                    <div className="trades-dtls__invoice-td">Amount ({this.trade.currency === 'USD' ? 'US$' : this.trade.currency})</div>
                                </div>
                                <div className="trades-dtls__invoice-tr">
                                    <div
                                        className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                        <div>
                                            <b>Measure : </b> {this.trade.measure.toLocaleString(undefined, { maximumFractionDigits: 2 })} ({converter.toWords(this.trade.measure)})
                                                </div>
                                        <div>
                                        <b>Measurement : </b> Metric Ton
                                        </div>
                                        <div>
                                            
                                            <b>Contractual Tolerance : </b>{!this.trade.tolerance ? '-' : this.trade.tolerance===0 ?`Min/Max in option of ${this.trade.incoterm==="FOB"?"Buyer":"Seller"}`:`+/- ${this.trade.tolerance}% in option of ${this.trade.incoterm==="FOB"?"Buyer":"Seller"}`}
                                          
                                        </div>
                                        <div>
                                            <b>Origin : </b> {Countries[this.trade.origin]}
                                        </div>
                                        <div>
                                            {RiceService.getByRef(this.trade.riceType) ?
                                            <React.Fragment>
                                                {RiceService.getByRef(this.trade.riceType).ref === RiceService.enums.REF_OTHER ?
                                                    <React.Fragment>
                                                        <span><strong>Rice Type : </strong></span>{RiceService.getByRef(this.trade.riceType).label}<br />
                                                        <span><strong>Specify Type : </strong></span>{this.trade.riceTypeOther}<br />
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <span><strong>Rice Type : </strong></span>{RiceService.getByRef(this.trade.riceType).label}<br />
                                                    </React.Fragment>}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <span><strong>Rice Type : </strong></span>{this.trade.riceType}<br />
                                            </React.Fragment>
                                        }
                                        </div>
                                        <div>
                                            <b>Quality : </b> {this.trade.quality}
                                        </div>
                                        <div>
                                            <b>Crop year : </b>{this.trade.cropYear}
                                        </div>
                                    </div>
                                    <div className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                        <div>
                                            {this.trade.price.toLocaleString(undefined, { maximumFractionDigits: 2 })} PMT
                                        </div>
                                        <br />
                                        <div>
                                            {
                                                this.trade.incoterm !== 'FOB' ?
                                                    <b>{this.trade.incoterm} {this.renderPortLabel(this.trade.destPort)}, {Countries[this.trade.destCountry]}</b>
                                                    : <b>{this.trade.incoterm} {this.renderPortLabel(this.trade.loadPort)}, {Countries[this.trade.loadCountry]}</b>
                                            }
                                        </div>
                                    </div>
                                    <div
                                        className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                        <div>
                                            {(this.trade.price * this.trade.measure).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                        </div>
                                        <br />
                                        {this.trade.downPaymentPercentage > 0 ? <div>
                                            <b>{this.trade.downPaymentAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} down payment to be received within {this.trade.downPaymentPeriod} from date of invoice</b>
                                        </div> : null}
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="trades-dtls__input-wrapper">
                                    <FormTextareaField
                                        name="additionalInformation"
                                        type="text"
                                        value={this.state.proformaInvoice.additionalInformation || {}}
                                        onChange={(event) => this.setField(event.target.name, event.target.value)}
                                        rows="4" 
                                        inlineStyle={{ width: '355px' }}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                            <div className="trades-dtls__input-wrapper">
                                <FormTextareaField
                                    name="payment"
                                    type="text"
                                    value={this.state.proformaInvoice.payment || {}}
                                    onChange={(event) => this.setField(event.target.name, event.target.value)}
                                    disabled={true}
                                    inlineStyle={{ width: '355px' }} 
                                    rows="8"/>
                           
                           </div> </div>
                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-sm-6">
                            <div className="trades-dtls__input-wrapper">
                                <FormTextareaField
                                    name="payTo"
                                    type="text"
                                    value={this.state.proformaInvoice.payTo || {}}
                                    onChange={(event) => this.setField(event.target.name, event.target.value)}
                                    disabled={this.props.isPreview ? true : false} rows="7"/>
                                    </div>
                            </div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-3">
                            <div className="trades-dtls__input-wrapper">
                                <FormInputField
                                    name="signedBy"
                                    type="text"
                                    value={{ label: 'Signed By', value: this.trade.seller, required: true }}
                                    disabled={true} />
                                    </div>
                            </div>
                        </div>
                    </div>
                    {this.state.required && Object.keys(this.state.required).length > 0 && (
                        <div className="trades-dtls__error-message text-center">
                            Please, complete all required fields before submitting
                        </div>
                    )}
                    <div className="trades-dtls__bottom-btn-section" style={{ marginTop: '10px' }}>
                        <button className="btn btn-secondary" onClick={this.props.onCancel}>
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                        <button type="submit" className="btn btn-primary mr-0"
                            disabled={this.props.loading}>
                            <Preloader loadingStyle="dots" loading={this.props.loading}>
                                <FontAwesomeIcon icon={faCheck} /> <span>Submit</span>
                            </Preloader>
                        </button>
                    </div>
                </form>
            );
        } else {
            return (
                <div className="trades-dtls__doc">
                    <div className="trades-dtls__doc-navigation">
                        <div className="container-fluid">
                                {!this.props.isComponentPrinting ? <div className="row">
                                    <div className="col-md-6">
                                        <Link to={`/trades/details/${this.props.id}/documents`}>Documents</Link> / Proforma Invoice
                                    </div>
                                    <div className="col-md-6 trades-dtls__buttons-wrapper">
                                        <button type="submit" className="btn btn-save mr-0" onClick={() => {
                                            this.props.navigateDocs(this.props.id)
                                        }}>
                                            <FontAwesomeIcon icon={faArrowLeft} /> <span style={{color: '#fff'}}>Back To Documents</span>
                                        </button>
                                    </div>
                                </div> : null}       
                        </div>
                        <form
                            className="trades-dtls__doc-wrapper trades-dtls__invoice-wrapper"
                            onSubmit={event => this.submitForm(event)}>
                            
                            <div className="container-fluid">
                            {this.props.isComponentPrinting  ?
                            <div className="img-fluid" style={{ height: '100px'}}>
                                {this.state.companyDetails.companyLogo && <ImageViewer image={this.state.companyDetails.companyLogo}/>}
                            </div>:
                            <div>
                                 {this.state.companyDetails.companyLogo && <ImageViewer image={this.state.companyDetails.companyLogo}/>}
                            </div>}
                                <h4 className="trades-dtls__doc-heading">PROFORMA INVOICE</h4>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormTextareaField
                                                name="sellerDetails"
                                                type="text"
                                                value={this.state.proformaInvoice.sellerDetails || {}}
                                                onChange={(event) => this.setField(event.target.name, event.target.value)}
                                                disabled={true}
                                                inlineStyle={{ 'height': '70px' }} />
                                        </div>
                                    </div>
                                    <div className="w-100"/>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormTextareaField
                                                name="buyerDetails"
                                                type="text"
                                                value={this.state.proformaInvoice.buyerDetails || {}}
                                                onChange={(event) => this.setField(event.target.name, event.target.value)}
                                                disabled={true}
                                                inlineStyle={{ 'height': '70px' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormInputField
                                                name="invoiceNo"
                                                type="text"
                                                value={this.state.proformaInvoice.invoiceNo || {}}
                                                onChange={(event) => this.setField(event.target.name, event.target.value)}
                                                disabled={this.props.isPreview ? true : false} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormInputField
                                                name="invoiceDate"
                                                type="text"
                                                disabled={true}
                                                value={this.state.proformaInvoice.invoiceDate || {}} />
                                        </div>
                                    </div>
                                    {this.trade.incoterm === 'FOB' ? <div className="col-sm-4">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormInputField
                                                name="portOfLoading"
                                                disabled={true}
                                                type="text"
                                                value={{ label: 'Port of Loading', value: portOfLoading }} />
                                        </div>
                                    </div> :
                                        <div className="col-sm-4 pt-1">
                                            <div className="trades-dtls__input-wrapper">
                                                <FormInputField
                                                    name="portOfDischarge"
                                                    disabled={true}
                                                    type="text"
                                                    value={{ label: 'Port of Discharge', value: `${this.renderPortLabel(this.trade.destPort)}, ${Countries[this.trade.destCountry]}` }} />
                                            </div>
                                        </div>}
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormInputField
                                                name="contractNo"
                                                type="text"
                                                disabled={true}
                                                value={{ label: 'Contract No', value: this.trade.tradeRefId ? this.trade.tradeRefId : this.trade.id }} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormInputField
                                                name="contractDate"
                                                type="text"
                                                disabled={true}
                                                value={{ label: 'Contract Date', value: moment(this.trade.createdAt).format(DATEFORMAT) }} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormInputField
                                                name="eventualPackaging"
                                                type="text"
                                                value={this.state.proformaInvoice.eventualPackaging || {}}
                                                onChange={(event) => this.setField(event.target.name, event.target.value)}
                                                disabled={true} />
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormInputField
                                                name="delieveryLoadStartDate"
                                                type="text"
                                                disabled={true}
                                                value={{ label: this.trade.incoterm !== 'FOB' ? 'Delivery Start Date' : 'Load Start Date', value: moment(this.trade.deliveryStartDate).format(DATEFORMAT) }} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormInputField
                                                name="delieveryLoadEndDate"
                                                type="text"
                                                disabled={true}
                                                value={{ label: this.trade.incoterm !== 'FOB' ? 'Delivery End Date' : 'Load End Date', value: moment(this.trade.deliveryEndDate).format(DATEFORMAT) }} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormInputField
                                                name="brandName"
                                                type="text"
                                                value={this.state.proformaInvoice.brandName || {}}
                                                onChange={(event) => this.setField(event.target.name, event.target.value)}
                                                disabled={this.props.isPreview ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-2" >
                                    <div className="col-sm-12">
                                    <div className="trades-dtls__input-wrapper">
                                        <div className="trades-dtls__invoice-th">
                                            <div className="trades-dtls__invoice-td">Description of goods </div>
                                            <div className="trades-dtls__invoice-td">Unit price ({this.trade.currency === 'USD' ? 'US$' : this.trade.currency})</div>
                                            <div className="trades-dtls__invoice-td">Amount ({this.trade.currency === 'USD' ? 'US$' : this.trade.currency})</div>
                                        </div>
                                        <div className="trades-dtls__invoice-tr">
                                            <div
                                                className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                                <div>
                                                    <b>Measure : </b> {this.trade.measure.toLocaleString(undefined, { maximumFractionDigits: 2 })} ({converter.toWords(this.trade.measure)})
                                                    </div>
                                                <div>
                                                <b>Measurement : </b> Metric Ton
                                                </div>
                                                <div>
                                                <b>Contractual Tolerance : </b>{!this.trade.tolerance ? '-' : this.trade.tolerance===0 ?`Min/Max in option of ${this.trade.incoterm==="FOB"?"Buyer":"Seller"}`:`+/- ${this.trade.tolerance}% in option of ${this.trade.incoterm==="FOB"?"Buyer":"Seller"}`}
                                                 </div>
                                                <div>
                                                    <b>Origin : </b> {Countries[this.trade.origin]}
                                                </div>
                                                <div>
                                                {RiceService.getByRef(this.trade.riceType) ?
                                            <React.Fragment>
                                                {RiceService.getByRef(this.trade.riceType).ref === RiceService.enums.REF_OTHER ?
                                                    <React.Fragment>
                                                        <span><strong>Rice Type : </strong></span>{RiceService.getByRef(this.trade.riceType).label}<br />
                                                        <span><strong>Specify Type : </strong></span>{this.trade.riceTypeOther}<br />
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <span><strong>Rice Type : </strong></span>{RiceService.getByRef(this.trade.riceType).label}<br />
                                                    </React.Fragment>}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <span><strong>Rice Type : </strong></span>{this.trade.riceType}<br />
                                            </React.Fragment>
                                        }
                                                </div>
                                                <div>
                                                    <b>Quality : </b> {this.trade.quality}
                                                </div>
                                                <div>
                                                    <b>Crop year : </b>{this.trade.cropYear}
                                                </div>
                                            </div>
                                            <div className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                                <div>
                                                    {this.trade.price.toLocaleString(undefined, { maximumFractionDigits: 2 })} PMT
                                                </div>
                                                <br />
                                                <div>
                                                    {
                                                        this.trade.incoterm !== 'FOB' ?
                                                            <b>{this.trade.incoterm} {this.renderPortLabel(this.trade.destPort)}, {Countries[this.trade.destCountry]}</b>
                                                            : <b>{this.trade.incoterm} {this.renderPortLabel(this.trade.loadPort)}, {Countries[this.trade.loadCountry]}</b>
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                                <div>
                                                    {(this.trade.price * this.trade.measure).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                </div>
                                                <br />
                                                {this.trade.downPaymentPercentage > 0 ? <div>
                                                    <b>{this.trade.downPaymentAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} down payment to be received within {this.trade.downPaymentPeriod} from date of invoice</b>
                                                </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="trades-dtls__input-wrapper">
                                            <FormTextareaField
                                                name="additionalInformation"
                                                type="text"
                                                value={this.state.proformaInvoice.additionalInformation || {}}
                                                onChange={(event) => this.setField(event.target.name, event.target.value)}
                                                disabled={this.props.isPreview ? true : false}
                                                rows="4" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                    <div className="trades-dtls__input-wrapper">
                                        <FormTextareaField
                                            name="payment"
                                            type="text"
                                            value={this.state.proformaInvoice.payment || {}}
                                            onChange={(event) => this.setField(event.target.name, event.target.value)}
                                            disabled={true}
                                            rows="8" />
                                            </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                    <div className="trades-dtls__input-wrapper">
                                        <FormTextareaField
                                            name="payTo"
                                            type="text"
                                            value={this.state.proformaInvoice.payTo || {}}
                                            onChange={(event) => this.setField(event.target.name, event.target.value)}
                                            disabled={this.props.isPreview ? true : false} rows="7"/>
                                            </div>
                                    </div>
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-3">
                                    <div className="trades-dtls__input-wrapper">
                                        <FormInputField
                                            name="signedBy"
                                            type="text"
                                            value={{ label: 'Signed By', value: this.trade.seller, required: true }}
                                            disabled={true} />
                                            </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.required && Object.keys(this.state.required).length > 0 && (
                                <div className="trades-dtls__error-message text-center">
                                    Please, complete all required fields before submitting
                                </div>
                            )}
                            <div className="trades-dtls__bottom-btn-section" style={{ marginTop: '10px' }}>
                                {!this.props.isComponentPrinting ? <Link to={`/trades/details/${this.props.id}/documents`}
                                    className="btn-popup btn-cancel">
                                    <FontAwesomeIcon icon={faTimes} /> Cancel
                                </Link> : null}
                                {!this.props.isPreview ? <button type="submit" className="btn-popup btn-submit mr-0"
                                    disabled={this.props.loading}>
                                    <Preloader loadingStyle="dots" loading={this.props.loading}>
                                        <FontAwesomeIcon icon={faCheck} /> <span>Submit</span>
                                    </Preloader>
                                </button> : null}
                            </div>
                        </form>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        loading: state.loading.loadingProformaInvoice
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            PostTradeDocumentProformaInvoice,
            SetTradeStatus,
            GetTradeProformaInvoice,
            UpdateTradeDocumentProformaInvoice,
            navigateDocs: path => push(`/trades/details/${path}/documents`)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProformaInvoice);
