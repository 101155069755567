// @ts-nocheck
import React from 'react'
import { types } from 'mobx-state-tree'
import loadable from '@loadable/component'

const schema = loadable(() => import('./c.rx.schema.vesselmodal.json'))
const uiSchema = loadable(() => import('./c.rx.uischema.vesselmodal.json'))
const schemaContainer = loadable(() =>
  import('./c.rx.schema.containermodal.json')
)
const uiSchemaContainer = loadable(() =>
  import('./c.rx.uischema.containermodal.json')
)

const Store = types
  .model({
    show: false,
    loading: false,
    fetchPartialShipments: false,
    schemaVessel: types.frozen({}),
    uiSchemaVessel: types.frozen({}),
    schemaContainer: types.frozen({}),
    uiSchemaContainer: types.frozen({}),
    formData: types.frozen({}),
    balance: types.frozen({}),
    trade: types.frozen({}),
    user: types.frozen({}),
    companyId: '',
    trdId: '',
  })
  .views((_) => ({
    get schema() {
      if (_.isVessel) return _.schemaVessel
      return _.schemaContainer
    },

    get uiSchema() {
      if (_.isVessel) return _.uiSchemaVessel
      return _.uiSchemaContainer
    },

    get tradeId() {
      return _.trade?.items?.single?.id
    },

    get tradeRefId() {
      return _.trade?.requestInfo?.tradeRefId ?? ''
    },

    get parentId() {
      // return self if no parent
      return _.trade?.items?.single?.parentTradeRequestID || _.tradeId
    },

    get isSeller() {
      if (
        _.trade?.privacyType === 'PRIVATE' ||
        _.trade?.items?.single?.privacyType === 'PRIVATE'
      ) {
        return _.companyId === _.trade?.items?.single?.sellerId
      }

      return _.trade?.requestType === 'SELL' ||
        _.trade?.items?.single?.requestType === 'SELL'
        ? _.isTradeOwner
        : !_.isTradeOwner
    },

    get isTradeOwner() {
      return _.companyId === _.trade?.items?.single?.ownerId
    },

    get isVessel() {
      return (
        _.trade?.items?.single?.shipping === 'VESSEL' ||
        _.trade?.shipping === 'VESSEL'
      )
    },

    get isPartialShipment() {
      return !!_.trade?.items?.single?.partialShipmentFlag
    },

    get isParent() {
      // parents do not have -# appended to the end of the trade reference id
      return _.tradeRefId.charAt(_.tradeRefId.length - 2) !== '-'
    },

    get modalTitle() {
      let id = ''

      if (_.isPartialShipment) {
        id = _.tradeRefId

        /*if (_.trade?.vesselNominationMap) {
          id = Object.keys(_.trade.vesselNominationMap)
            .sort()
            .pop()
        }*/

        //increment last letter to estimate the next id
        const next = String.fromCharCode(id.charCodeAt(id.length - 1) + 1)

        id = id.slice(0, -1) + next
      } else {
        // append the first letter to the end of the trade reference id
        id = _.tradeRefId + '-a'
      }

      if (_.isVessel) return `Nominate a vessel for ${id}`
      return `Nominate Container for ${id}`
    },

    get vesselWarning() {
      const { balance, isPartialShipment } = _
      let { min, max } = balance

      const total = _.formData?.nominateQuantity ?? _.formData?.totalMTon ?? 0

      // reduce by total but not below 0
      min = Math.max(0, min - total)
      max = Math.max(0, max - total)

      if (isPartialShipment) {
        if (min !== 0 && max !== 0) {
          return (
            <>
              After this nomination, the minimum estimated quantity{' '}
              <b>
                still to be shipped under this contract is about{' '}
                {min.toFixed(2)} Mton and max about {max.toFixed(2)} Mton
                (excluding shipping tolerances).
              </b>{' '}
              Further shipment(s) are therefore likely required and can be added
              at any time under VESSEL INFO / NOMINATE A NEXT VESSEL. Click
              CANCEL to go back to the previous screen or SUBMIT to Submit the
              present nomination
            </>
          )
        }

        if (min === 0 && max !== 0) {
          return (
            <>
              After this nomination,{' '}
              <b>
                the minimum quantity still to be shipped under this contract is
                0 Mton and max about {max.toFixed(2)} Mton (excluding shipping
                tolerances).
              </b>{' '}
              An eventual next shipment can be added to this contract at any
              time under VESSEL INFO / NOMINATE A NEXT VESSEL.Click CANCEL to go
              back to the previous screen or SUBMIT to Submit the present
              nomination
            </>
          )
        }
      }

      if (!isPartialShipment) {
        if (min !== 0 && max !== 0) {
          return (
            <>
              Warning: The minimum quantity to be shipped under this contract is{' '}
              {min.toFixed(2)} Mton and max {max.toFixed(2)} Mton and partial
              shipments are not allowed. Please increase the nominated volume to
              reach at least the min required quantity or use “request contract
              amendment “ function to request the counter party to accept
              “partial shipments “ or “ a reduction in the contract
              quantity”.Click CANCEL to go back to the previous screen or SUBMIT
              to submit the present nomination
            </>
          )
        }
      }

      return null
    },

    get containerWarning() {
      const { balance, isPartialShipment } = _
      let { min, max } = balance

      const total = _.formData?.nominateQuantity ?? _.formData?.totalMTon ?? 0

      // reduce by total but not below 0
      min = Math.max(0, min - total)
      max = Math.max(0, max - total)

      if (isPartialShipment) {
        if (min !== 0 && max !== 0) {
          return (
            <>
              After this nomination, the minimum estimated quantity{' '}
              <b>
                still to be shipped under this contract is about{' '}
                {min.toFixed(2)} Mton and max about {max.toFixed(2)} Mton
                (excluding shipping tolerances).
              </b>{' '}
              Further shipment(s) are therefore likely required and can be added
              at any time under VESSEL INFO / NOMINATE A NEXT VESSEL. Click
              CANCEL to go back to the previous screen or SUBMIT to Submit the
              present nomination
            </>
          )
        }

        if (min === 0 && max !== 0) {
          return (
            <>
              After this nomination,{' '}
              <b>
                the minimum quantity still to be shipped under this contract is
                0 Mton and max about {max.toFixed(2)} Mton (excluding shipping
                tolerances).
              </b>{' '}
              An eventual next shipment can be added to this contract at any
              time under VESSEL INFO / NOMINATE A NEXT VESSEL.Click CANCEL to go
              back to the previous screen or SUBMIT to Submit the present
              nomination
            </>
          )
        }
      }

      if (!isPartialShipment) {
        if (min !== 0 && max !== 0) {
          return (
            <>
              Warning: The minimum quantity to be shipped under this contract is{' '}
              {min.toFixed(2)} Mton and max {max.toFixed(2)} Mton and partial
              shipments are not allowed. Please increase the nominated volume to
              reach at least the min required quantity or use “request contract
              amendment “ function to request the counter party to accept
              “partial shipments “ or “ a reduction in the contract
              quantity”.Click CANCEL to go back to the previous screen or SUBMIT
              to submit the present nomination
            </>
          )
        }
      }

      return null
    },

    get warning() {
      if (!_.balance?.max) return null
      if (!_.formData) return null

      const total = _.formData?.nominateQuantity ?? _.formData?.totalMTon ?? 0

      // hide warnings if total is not yet available
      if (total === 0) return null

      // return the proper warning
      return _.isVessel ? _.vesselWarning : _.containerWarning
    },
  }))
  .volatile(() => ({
    onClose: null,
    onSubmit: null,
    initialValues: null,
    inspectors: null,
  }))
  .actions((_) => ({
    set: (field, value) => {
      _[field] = value
    },

    showModal: () => {
      _.set('fetchPartialShipments', false)
      _.set('show', true)
    },

    hideModal: () => _.set('show', false),
  }))

const store = Store.create()

//async load configurations
schema.load().then((re) => store.set('schemaVessel', re.default))
uiSchema.load().then((re) => store.set('uiSchemaVessel', re.default))
schemaContainer.load().then((re) => store.set('schemaContainer', re.default))
uiSchemaContainer
  .load()
  .then((re) => store.set('uiSchemaContainer', re.default))

export default store
