import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { preloadInspectionCompanies } from '../../../../modules/module.trade';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailTradeForm from './DetailTradeForm';

class ReviewTradeFormModel extends React.Component {
    constructor(props){
        super(props);
        preloadInspectionCompanies();
    }

    render() {
        const { show, onClose,  request} = this.props;
        if(show){
            return (<div className="modal__container confirm-modal">
                <div className="modal__wrapper px-0 py-0">
                    <div className="modal-header">
                        <h5 className="modal-title w-100">Review Trade Details</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true" >&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="contract-modal"> 
                            <DetailTradeForm series={[{ title: 'Field Value', data: request }]} {...this.props}/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={onClose} className="btn-popup btn-cancel">
                            <FontAwesomeIcon icon={faTimes} /> <span>Close</span>
                        </button>
                    </div>
                </div>
            </div>)
        }else{
            return false;
        }
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        loading: state.loading.requestCreation,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ preloadInspectionCompanies }, dispatch );

export default connect(mapStateToProps, mapDispatchToProps)(ReviewTradeFormModel);