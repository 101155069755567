import React, { Component } from "react";
import {RicexCustomFileLabel} from './../../../../components/form/FormFileUpload.module.scss';

class UploadBagmarking extends Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();

        this.state = {
            url: null,
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false,
            previews: {}
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    componentWillUnmount() {
        this.onCancel();
    }

    onFilesAdded(e) {
        if (e.target.files.length !== 0 && this.isValid(e.target.files[0])) {
            e.persist()
            this.setState(prevState => {
                return {
                    file: e.target.files[0]
                }
            });
        } else {
            this.setState(prevState => {
                return {
                    file: "",
                }
            });
        }
    }

    onCancel() {
        this.input.current.value = "";
        this.setState(prevState => {
            return {
                file: ""
            }
        });
    }

    uploadFile(e) {
        e.preventDefault();
        e.persist();
        if (this.isValid(this.state.file)) {
            this.setState({ uploading: true });
            const formData = new FormData();
            formData.append("upload", this.state.file, this.state.file.name);
            this.props.postBagmarking(e, formData, ()=>{
                this.input.current.value = "";
                    this.setState(prevState => {
                        return {
                            uploading: false, 
                            file: ""
                        }
                    });
            });
        }
    }

    isValid = (image) => {
        const fileType = image.type;
        let fileSize = image.size;
        fileSize = fileSize / (1024 * 1024);
        let result = true;
        if (!["image/png", "image/jpeg", "application/pdf"].includes(fileType)) {
            this.setState({ fileError: "Please upload file only with *.png, *.jpeg, *.jpg, *.pdf" });
            result = false;
        } else if (fileSize > 15.36) {
            this.setState({ fileError: "File size can't exceed 15 MB" });
            result = false;
        } else {
            this.setState({ fileError: undefined });
        }
        return result;
    }

    render() {

        return (
            <div className="row create-trade-form  mt-3">
                <div className="col-md-2">
                    <label className="label">Upload Bagmarkings</label>
                </div>
                <div className="col-md-6">
                    <div className="form-row">
                        <div className="col">
                            <div className="custom-file">
                                <input type="file" ref={this.input}
                                    className={`custom-file-input`}
                                    id="inputImage"
                                    aria-describedby="inputImage"
                                    accept=".jpg,.png,.jpeg,.pdf"
                                    onChange={this.onFilesAdded} />
                                <label className={`custom-file-label ${RicexCustomFileLabel}`} htmlFor="inputImage">{ this.state.file?this.state.file.name : 'Choose file'}</label>                                
                            </div>
                        </div>
                        {this.state.file && 
                        <div>
                            <button type="button" className="btn btn-clear ml-1"
                                disabled={this.state.uploading}
                                onClick={this.onCancel}>
                                <span>{"Cancel"}</span>
                            </button>
                            <button type="button" className="btn btn-upload ml-1"
                                disabled={this.state.uploading}
                                onClick={this.uploadFile}>
                                <span>{this.state.uploading ? "Uploading..." : "Upload"}</span>
                            </button>
                        </div>}
                    </div>
                    {this.state.fileError 
                    ?
                    <small id="helpFile" className="form-text text-danger">{this.state.fileError}</small> 
                    :
                    <small id="helpFile" className="form-text text-muted">Only file types .png, .jpg, .jpeg, .pdf accepted (max size 15MB per file)</small>}
                </div>
            </div>
        );
    }
}

export default UploadBagmarking;
