import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import './TenderWhitelisting.scss';
import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import CustomMaterialPreloader from '../../components/preloader/CustomMaterialPreloader';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import {
    getWhitelistedCompanies,
    removeWhitelistedCompany,
    addNewWhitelistedCompany
} from '../../../modules/module.tender';
import {searchCompanies} from '../../../modules/module.trade'
import TenderWhitelistCompanyAddition from './TenderWhitelistCompanyAddition';
import TenderConfirmModal from './TenderConfirmModal';
import Preloader from '../../components/preloader/Preloader';

class TenderWhitelisting extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showModal: false,
            modalName: true,
            selectedCompany: null
        }
    }

    componentDidMount(){
        this.props.getWhitelistedCompanies();
    }

    onCloseModalHandler = () => {
        this.setState({
            showModal: false,
            modalName: '',
            selectedCompany: null
        })
    }

    onRemoveCompanyHandler = () => {
        this.props.removeWhitelistedCompany(this.props.whitelistedGroup.groupID, this.state.selectedCompany, this.onCloseModalHandler);
    }

    onAddNewCompany = () => {
        this.props.searchCompanies('');
        this.setState({
            showModal: true,
            modalName: 'ADD_NEW_COMPANY'
        })
    }

    onCompanyAdditionHandler = (companyRow) => {
        let data = {
            company: {
                ID: companyRow.ID,
                name: companyRow.name,
                companyType: companyRow.companyType
            }
        }
        this.props.addNewWhitelistedCompany(data);
        this.onCloseModalHandler();
    }

    removeDuplicateCounterParties = () => {
        if(Object.keys(this.props.whitelistedGroup).length === 0){
            return this.props.counterParties;
        }else{
            if(this.props.whitelistedGroup.companies.length === 0){
                return this.props.counterParties;
            }else{
                return this.props.counterParties.reduce((initialCounterParties, company) => {
                    let companyAddedIndex = this.props.whitelistedGroup.companies.findIndex(item => item.ID === company.ID)
                    if(companyAddedIndex === -1){
                       initialCounterParties.push(company);
                    }
                    return initialCounterParties;
                }, []);
            }
        }
    }

    onRenderModalHandler = () => {
        switch(this.state.modalName){
            case 'ADD_NEW_COMPANY':
                return(
                    <TenderWhitelistCompanyAddition 
                        onCancel={this.onCloseModalHandler}
                        onAddition={this.onCompanyAdditionHandler}
                        loading={this.props.loadingCounterParties}
                        counterParties={this.removeDuplicateCounterParties()}/>
                )
            case 'REMOVE_WHITELISTED_COMPANY':
                return (
                    <TenderConfirmModal
                        onCancel={this.onCloseModalHandler}
                        onConfirm={this.onRemoveCompanyHandler}
                        loading={this.props.removingCompany}>
                            <p>Are you sure to remove this company from the whitelist group? Please confirm to continue or press back to return to previous screen</p>
                    </TenderConfirmModal>
                )
            default:
                return null;
        }
    }

    render() { 
        const whitelistedCompanies = this.props.whitelistedGroup.companies || [];
        return ( 
            <React.Fragment>
                <Header />
                {this.state.showModal && this.onRenderModalHandler()}
                <Preloader loadingStyle="overflow-spinner" loading={this.props.addingCompany} />
                <div className="container-fluid">
                    <div className="tender-whitelisting">
                        <div className="trades-dtls__info">
                            <div className="profile__back" onClick={this.props.history.goBack}>Back</div>
                            <div className="trades-dtls__tabs">
                                <NavLink
                                    exact
                                    to="/tender-whitelisting"
                                    className="profile__tab"
                                >
                                    Tender Whitelist Group
                                </NavLink>
                            </div>
                        </div>
                        <section className="whitelist-section">
                            <button 
                                type="button" 
                                className="trades-dtls__verify-docs" 
                                onClick={this.onAddNewCompany}
                                disabled={this.props.loadingCompanies}>
                                    Add New Company
                            </button>
                            <MaterialTable 
                                columns={[{
                                    title: 'Company Name', field: 'name'
                                },{
                                    title: 'Company Type', field: 'companyType'
                                }]}
                                data={whitelistedCompanies}
                                title="Manage Tender Whitelist Group"
                                actions={[{
                                    tooltip: 'Remove Company',
                                    icon: () => <RemoveCircleIcon color="secondary" fontSize="large"/>,
                                    onClick: (event, rowData) => {
                                        this.setState({
                                            showModal: true,
                                            modalName: 'REMOVE_WHITELISTED_COMPANY',
                                            selectedCompany: rowData.ID
                                        })
                                    }
                                }]}
                                icons={{
                                    Filter: FilterList,
                                    FirstPage: FirstPage,
                                    LastPage: LastPage,
                                    NextPage: ChevronRight,
                                    PreviousPage: ChevronLeft,
                                    Search: Search,
                                    SortArrow: ArrowUpward,
                                    ResetSearch: Clear
                                }}
                                options={{
                                    actionsColumnIndex: -1,
                                    rowStyle: (rowData, index) => {
                                        const backgroundColor = (index%2 === 0) ? '#00ff301c' : '#FBFBFB';
                                        return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                                    },
                                    pageSize: 5,
                                    thirdSortClick: false
                                }}
                                components={{
                                        OverlayLoading: CustomMaterialPreloader
                                }}
                                isLoading={this.props.loadingCompanies}
                            />
                        </section>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingCompanies: state.tender.whitelistingProcess === 'INIT',
        removingCompany: state.tender.whitelistingProcess === 'REMOVING',
        addingCompany: state.tender.whitelistingProcess === 'ADDING',
        whitelistedGroup: state.tender.whitelistedGroup,
        counterParties: state.trade.companies.companies || [],
        loadingCounterParties: state.loading.loadingCounterParties
    };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWhitelistedCompanies,
      removeWhitelistedCompany,
      addNewWhitelistedCompany,
      searchCompanies
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TenderWhitelisting);