import React from 'react';

const CompanyAdminDetails = ({ admin }) => (
    <div>
        <h4>Authorized Company Admin Details</h4>
        {admin ? (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Name
                            </div>
                            <div className="detail-text">
                                {admin.name}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Position
                            </div>
                            <div className="detail-text">
                                {admin.title}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Email
                            </div>
                            <div className="detail-text">
                                {admin.email}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Telephone No.
                            </div>
                            <div className="detail-text">
                                {admin.phone}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Mobile No.
                            </div>
                            <div className="detail-text">
                                {admin.mobile}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Proof of Address
                            </div>
                            <div className="detail-text">
                                {admin.addressProof && admin.addressProof.fileUrl !== "#" && <a href={admin.addressProof.fileUrl} >View {admin.addressProofType ? admin.addressProofType : ''}</a>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Passport
                            </div>
                            <div className="detail-text">
                                {admin.passport && admin.passport.fileUrl !== "#" && <a href={admin.passport.fileUrl} >View Passport</a>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Corporate Authorisation
                            </div>
                            <div className="detail-text">
                                {admin.boardResolution && admin.boardResolution.fileUrl !== "#" && <a href={admin.boardResolution.fileUrl} >View Corporate Authorisation</a>}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        ) : "Admin Details Not Found"}
    </div>
);

export default CompanyAdminDetails;