import React from 'react';
import moment from 'moment';
import { EnumsService } from "../../../../../services/service.utils";
import { DATEFORMAT } from '../../../../../services/service.values';

const Countries = EnumsService.countries();

const CompanyDetails = ({ company }) => (
    <div className="">
        <h4>Company Details</h4>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Registered Name
                            </div>
                    <div className="detail-text">
                        {company.name}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Type of Company
                            </div>
                    <div className="detail-text">
                        {company.companyType}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Registered Address Line1
                            </div>
                    <div className="detail-text">
                        {company.registeredAddress.line1}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Registered Address Line2
                            </div>
                    <div className="detail-text">
                        {company.registeredAddress.line2}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        City
                            </div>
                    <div className="detail-text">
                        {company.registeredAddress.city}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Zip Code
                            </div>
                    <div className="detail-text">
                        {company.registeredAddress.postal}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Country
                            </div>
                    <div className="detail-text">
                        {Countries[company.registeredAddress.country]}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Mailing Address Line1
                            </div>
                    <div className="detail-text">
                        {company.mailingAddress.line1}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Mailing Address Line2
                            </div>
                    <div className="detail-text">
                        {company.mailingAddress.line2}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        City
                            </div>
                    <div className="detail-text">
                        {company.mailingAddress.city}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Zip Code
                            </div>
                    <div className="detail-text">
                        {company.mailingAddress.postal}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Country
                            </div>
                    <div className="detail-text">
                        {Countries[company.mailingAddress.country]}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Company Registration No.
                            </div>
                    <div className="detail-text">
                        {company.regNumber}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Email
                            </div>
                    <div className="detail-text">
                        <a href="#/">{company.companyAdmin && company.companyAdmin.email}</a>
                    </div>
                </div>
            </div>

        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Telephone No.
                            </div>
                    <div className="detail-text">
                        {company.phone}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Vat Number
                            </div>
                    <div className="detail-text">
                        {company.tax}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Website
                            </div>
                    <div className="detail-text">
                        <a href="http://ricex.redledger.eu/">{company.site}</a>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Registration Completion Date
                    </div>
                    <div className="detail-text">
                    {company.approvedAt === "0001-01-01T00:00:00Z" || !company.approvedAt ? '' : moment(company.approvedAt).format(DATEFORMAT)}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default CompanyDetails;