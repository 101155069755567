import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import account from './module.account';
import app from './module.app';
import trade from './module.trade';
import tender from './module.tender';
import shipment from './module.shipment';
import loading from './module.loading'
import monitoring from './module.monitoring'
import notifications from './module.notifications'
import usermanagment from './module.adminManagement';
import platformAdmin from './module.platformAdmin';
import shipmentQuote from './module.shipmentQuote';
import inspectionQuote from './module.inspectionQuote';
import contract from './module.contract';
import nomination from './module.nomination';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    account,
    app,
    trade,
    tender,
    shipment,
    loading,
    monitoring,
    notifications,
    usermanagment,
    platformAdmin,
    shipmentQuote,
    inspectionQuote,
    contract,
    nomination
});

export default createRootReducer;
