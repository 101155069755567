import React, { Component } from 'react';

import './inspectionReports.scss';
import InspectionReportsItem from './InspectionReportsItem';
import PreviewPDF from '../../../../components/previewPDF/PreviewPDF';
import FileUploadModal from '../FileUploadModal';
import { authHeader, downloadInspectionReportTemplate, getFileUrl } from '../../../../../services/service.api';
import AccessControl, { INSPECTION_COMPANY } from '../../../../components/AccessControl';
import 'formdata-polyfill';

export default class InspectionReports extends Component {
    state = {
        uploadModal: {
            opened: false,
            docName: '',
            document: null,
            isReportClean: true,
            isloading:false

        },
        previewDocument: {
            opened: false,
            file: null
        },
        reportOptions: [],
        selectedReport: null,
        highlightReportID: null
    };

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const highlightReportID = params.get('highlightReport');
        if (highlightReportID) {
            this.setState({
                highlightReportID: highlightReportID
            });
        }
    }


    getReportOptions() {
        const reports = [...this.props.reports];
        reports.shift();
        return reports.map(report => ({
            ...report,
            value: report.ID,
            label: report.name,
        }));
    }

    handleReportChange = (report) => {
        this.setState({
            selectedReport: report
        });
    };

    onDrop = (filesAccept, filesNotAccept, docName) => {
        if (filesAccept.length > 0) {
            this.setState((prevState) => ({
                uploadModal: {
                    ...prevState.uploadModal,
                    document: filesAccept
                }
            }))
        }
    };

    postDoc = (id, e) => {
        e.preventDefault();
        this.openloading();
        let newData = new FormData();
        newData.append('upload', this.state.uploadModal.document[0]);
        newData.append('isReportClean', this.state.uploadModal.isReportClean)
        this.props.PostInspectionReport(id, newData, () => {
            this.closeUploadModal();
        })
    };

    openUploadModal = () => {
        this.setState(prevState => ({
            uploadModal: {
                opened: true,
                document: null,
                docName: '',
                isReportClean: true,
                isloading:false
            }
        }));
    };

    onCleanChange = (e) => {
        this.setState({ ...this.state, uploadModal: { ...this.state.uploadModal, isReportClean: (e.target.value === 'true') } })
    }

    handlePreviewClick = (id) => {
        this.setState({
            previewDocument: {
                opened: true,
                file: {
                    url: getFileUrl(id),
                    httpHeaders: authHeader().headers
                }
            }
        });
    };

    handleApproveClick = (id) => {
      this.openloading();
      this.props.approveInspectionReport(id);
         }

    handleRejectClick = (id) => {
        this.openloading();
        this.props.rejectInspectionReport(id);
        }

    closePreviewModal = () => {
        this.setState({
            previewDocument: {
                opened: false,
                file: null
            }
        });
    };

    closeUploadModal = () => {
        this.setState(prevState => ({
            uploadModal: {
                ...prevState.uploadModal,
                opened: false,
                isloading:false  
            }
        }))
    };

    openloading = () => {
        this.setState(prevState => ({
            uploadModal: {
                ...prevState.uploadModal,
                isloading:true
            }
        }))
    };

    render() {
        return (
            <div className="inspection-reports">
                <div className="inspection-reports__content">
                    <div className="inspection-reports__heading">
                        <div className="inspection-reports__recent-version">List of Reports</div>
                        <AccessControl user={this.props.user} companyTypes={[INSPECTION_COMPANY]}>
                            <div className="inspection-reports__buttons">
                                <button type="button" className="btn btn-sm btn-up-doc" onClick={this.openUploadModal}>Upload</button>
                                <button type="button" className="btn btn-sm btn-up-doc" hidden={true} onClick={downloadInspectionReportTemplate}>Get template</button>
                            </div>
                        </AccessControl>
                    </div>
                    {this.props.reports[0] ? (
                        this.props.reports.map((report, i) => {
                            return (
                                <InspectionReportsItem
                                    report={report}
                                    isBuyer={this.props.isBuyer}
                                    loading={this.state.uploadModal.isloading}
                                    highlightReport={this.state.highlightReportID === report.ID}
                                    onPreviewClick={(e) => this.handlePreviewClick(report.ID)}
                                    onApproveClick={(e) => this.handleApproveClick(report.ID)}
                                    onRejectClick={(e) => this.handleRejectClick(report.ID)}
                                />
                            )
                        })
                    ) : (<div className="inspection-reports__item"> {this.props.isNonInspection?"Daily inspection reports are not available for the contractual inspection company as they are not an integrated inspection company of the platform":"No Reports Uploaded"}</div>)
                    }
                </div>
                <FileUploadModal
                    visibility={this.state.uploadModal.opened}
                    loading={this.state.uploadModal.isloading}
                    onDrop={(filesAccept, filesNotAccept, docName) => this.onDrop(filesAccept, filesNotAccept, docName)}
                    name="Inspection report"
                    docName="Inspection report"
                    postDoc={e => this.postDoc(this.props.match.params.id, e)}
                    file={this.state.uploadModal.document}
                    close={this.closeUploadModal}
                    accept="application/pdf"
                    isInspectionReport={true}
                    isReportClean={this.state.uploadModal.isReportClean}
                    onCleanChange={(e) => this.onCleanChange(e)}
                />
                {this.state.previewDocument.opened && (
                    <div className="modal__container">
                        <div className="modal__wrapper">
                            <span className="modal__close" onClick={this.closePreviewModal} />
                            <PreviewPDF file={this.state.previewDocument.file} />
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
