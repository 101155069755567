import React, { useState, useMemo}  from 'react'
import { createChildTrade, GetVesselNomination } from '../../../../../modules/module.trade';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Preloader from '../../../../components/preloader/Preloader';
import NominatedVesselInfo from '../NominatedVesselInfo';
import MultipleShipmmentDetail from './MultipleShipmmentDetail';
import ShipmentRecapTable from './ShipmentRecapTable';
import Shipment from './Shipment'
import { PropTypes } from 'prop-types';
import { push } from "connected-react-router";
import DocumentShippingAdvice from '../DocumentShippingAdvice';
import { STEPS, TRADE_STATUS } from '../../../../../services/service.values';
import {getShipmentList} from '../../../../../constants/shipmentRecapTableConstants';
/**
 * @author Jean-Marc Cheynier <jean-marc.cheynier@fujitsu.com>
 * @version RDEV-2063
 * ShipmentInfoTab component shows the balance of the shipments.
 * @param  {string} parentId - The ID of the parent trade
 * @returns {Component} ShipmentInfoTab component, component to be foud under the Vessel Tab for trades with multiple shipments
 */
const ShipmentInfoTab = ({
    pointOfView,
    type,
    parentId,
    tradeMap,
    trade,
    loadingRequestCreation,
    loadingShipmentDetails,
    vesselNominationMap,
    shipmentMap,
    createChildTrade,
    push,
    userName,
    bills,
    tradeRefIdHeader
}) => {
    
    const parent = tradeMap[parentId];
    const [nominationFullfilled, setNominationFullfilled] = useState(false)
    
    const shipmentList = useMemo(() => {
        return getShipmentList(tradeMap, parent, vesselNominationMap, shipmentMap);
    }, [tradeMap, parent, vesselNominationMap, shipmentMap]);
    
    const previousNominationComplete = useMemo(() => {
        let res = false;
        if (parent) {
            if (parent.childTradeRequestID && parent.childTradeRequestID.length !== 0) {
                res = true;
                for (let childId of parent.childTradeRequestID) {
                    if (!vesselNominationMap[childId] || !vesselNominationMap[childId].vesselAccepted) {
                        res = false;
                        break;
                    }
                }
            } else {
                res = (vesselNominationMap[parentId] && vesselNominationMap[parentId].vesselAccepted)
            }
        }
        return res;
    }, [parent, vesselNominationMap]);
    
    const handleOnCreateChildTrade = () => {
        window.createChildTrade = true
        setTimeout(() => {
            window.createChildTrade = false
        },20 * 1000)

        createChildTrade(parentId).then((newChild) => {
          if(newChild && newChild.id) push(`/trades/details/${newChild.id}/nominatedVesselInfo`)
        })
    }
    
    const handleOnTableUpdate = ({ max, min, newShipment, shippingHistory, balance }) => {
        setNominationFullfilled(balance.min === 0);
    }
    
    const vesselNomination = vesselNominationMap[trade.id];
    const isParent = trade.parentTradeRequestID === "";
    
    const showNewChildButton = (parent && parent.partialShipmentFlag &&
        previousNominationComplete &&
        !nominationFullfilled &&
        (
            (pointOfView === 'buyer' && parent.incoterm === "FOB") ||
            (pointOfView === 'seller' && ["CIF", "CFR"].includes(parent.incoterm))
        ) &&
        ( STEPS.indexOf(trade.status) <= STEPS.indexOf(TRADE_STATUS.ADVICE) || 
        parent.status=="MULTIPLE_SHIPMENTS" || parent.status==="CONTRACT_FULFILL_REJECT"))
    
    
    return (
        <React.Fragment>
            {tradeRefIdHeader?
            <>
             <h3 className='chidlTradeRef_header'>{tradeRefIdHeader}</h3>
             <div className='br_bottom'></div>
             </>
             :null
            }
             {parentId &&
            <MultipleShipmmentDetail parentId={parentId} redirect="advice" vesselNominationMap={vesselNominationMap} shipmentMap={shipmentMap}
            />}
            <div className='py-2 px-3'>
                <label className="label h6">Shipments</label>
                <Preloader loadingStyle="swirl" loading={loadingShipmentDetails}>
                    <ShipmentRecapTable parentTrade={parent} shipmentList={shipmentList} onTableUpdate={handleOnTableUpdate} />
                </Preloader>
            </div>
            {/* Case trade without child shipments or child trade : show the list of shipping advices attached to the trade */}
            {(!trade.parentTradeRequestID && shipmentMap[trade.id] || trade.parentTradeRequestID ) ?
                <div className='py-2 px-3'>
                    {shipmentMap[trade.id] && shipmentMap[trade.id].list && Array.isArray(shipmentMap[trade.id].list) && shipmentMap[trade.id].list.map( (shipment, index) => (
                        <DocumentShippingAdvice
                            key={index}
                            bill={shipment.Bill }
                            trade={trade}
                            userName={userName}
                            isModal={false}
                        />
                    ))
                    }

                </div>
            :

                <>
                    {/* Case parent trade  */}
                    { parent.childTradeRequestID && parent.childTradeRequestID.map(childId => {
                            const shipmentList = shipmentMap[childId] ? shipmentMap[childId].list : null;
                            const child = tradeMap[childId]
                            if (shipmentList && shipmentList.length !== 0) {
                                return shipmentList.map(shipment => {
                                    if (shipment.Bill.billNumber) {
                                        return <DocumentShippingAdvice
                                            bill={shipment.Bill}
                                            trade={child}
                                            userName={userName}
                                            isModal={false}
                                        />
                                    } else {
                                        return false
                                    }
                                })
                            }
                    })
                }
                </>
            }

            { showNewChildButton &&
            <div className='py-2 px-3 text-right'>
                <button className="trades-dtls__button" type="button" disabled={loadingRequestCreation} onClick={handleOnCreateChildTrade}>
                    <Preloader loadingStyle="dots" loading={loadingRequestCreation}>
                        <span>Nominate a Next Vessel</span>
                    </Preloader>
                </button>
            </div>}

            
        </React.Fragment>
    )

}

ShipmentInfoTab.propTypes = {
    vesselNominationMap: PropTypes.object
}

ShipmentInfoTab.defaultProps = {
    vesselNominationMap: {}
}



const mapStateToProps = state => {
    return {
        bills: state.trade.bills,
        trade: state.trade.items.single,
        tradeMap: state.trade.items.tradeMap,
        userName: state.trade.requestInfo.sellerUserName,
        vesselNomination: state.vesselNomination,
        vesselNominationMap: state.trade.vesselNominationMap,
        shipmentMap: state.trade.shipmentMap,
        loadingRequestCreation: state.loading.requestCreation,
        loadingShipmentDetails: state.loading.loadingShipmentDetails,
        triggerNominationUpdate: state.trade.triggerNominationUpdate,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            push,
            GetVesselNomination,
            createChildTrade
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentInfoTab);