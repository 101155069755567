import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DATEFORMATHOURS, COUNTER_TYPES, BID_STATUS } from '../../../../services/service.values';
import ConfirmModalAcceptAll from '../components/confirmModals/ConfirmModalAcceptAll';
import ConfirmModalDeclineAll from '../components/confirmModals/ConfirmModalDeclineAll';
import ConfirmModalViewContract from '../components/confirmModals/ConfirmModalViewContract';
import ConfirmModalAcceptTenderWithMultiOrigin from '../components/confirmModals/ConfirmModalAcceptTenderWithMultiOrigin';
import { Link } from 'react-router-dom';
import { negotiateTradePermission } from '../../../../services/service.permission';
import { GetBidInfo, GetContractUserInfo } from '../../../../modules/module.trade';
import ConfirmModal from '../components/confirmModals/ConfirmModal';
import {getLocalTimezone} from '../../../../services/service.utils';
import CompareGraphDutchAuction from '../compare/CompareGraphDutchAuction';
import PublicCommunicationModal from '../components/confirmModals/PublicCommunicationModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';
import ConfirmModalAcceptOffer from '../components/confirmModals/ConfirmModalAcceptOffer';

class BidList extends Component {
    constructor(props) {
        super(props);
        this.handleOnRowClick = this.handleOnRowClick.bind(this);
        this.renderBidActions = this.renderBidActions.bind(this);
        this.props.GetContractUserInfo(this.props.user.companyId);
        this.state = {
            selection: [],
            showModalInfo: false,
            contentModalInfo: {
                declineReason: '',
                fromCompanyName: ''
            }
        }
    }

    get isTenderWithMultiOrigin() {
        return this.props.trade.privacyType === 'TENDER' && this.props.trade.origin.split(',').length > 1
    }
  
    getCounterParty = (bid) => {
        const userCompId = this.props.user.companyId;
        if (bid.fromCompanyID !== userCompId) {
            return bid.fromCompanyName
        } else {
            return bid.toCompanyName
        }
    }

    getContractTradeData = (trade) => {
        let data = { ...this.props.ContractCompanyinfo };
        let incomingTrade = { ...trade }
        let info = {};
        if (data.companyAddress) {
            info = { ...data.companyAddress }
        }
        if (incomingTrade.privacyType === "PUBLIC") {
            if (incomingTrade.buyerAddress1 === "") {
                incomingTrade.buyerAddress1 = info
                incomingTrade.buyer = data.companyName
                incomingTrade.buyerUser = this.props.user.name;
            }
            if (incomingTrade.sellerAddress1 === "") {
                incomingTrade.sellerAddress1 = info
                incomingTrade.seller = data.companyName;
                incomingTrade.sellerUser = this.props.user.name;
            }
        }
        if (incomingTrade.sellerUser === "") {
            incomingTrade.sellerUser = this.props.user.name;
        }
        if (incomingTrade.buyerUser === "") {
            incomingTrade.buyerUser = this.props.user.name;
        }

        return incomingTrade;
    }

    checkBankDetailsFilled = (trade) => {
        if (this.props.trade.requestType === 'SELL') {
            if (trade.payment === 'CAD') return true;
            if (trade.payment === 'Avalised Draft' || trade.payment === 'Letter of credit' || trade.payment === 'Documentary presentation') {
                return trade.nameOfBank && trade.locationOfBank && trade.swiftCodeOfBank;
            }
            if (trade.payment === 'Confirmed letter of credit') {
                return trade.nameOfBank && trade.locationOfBank && trade.swiftCodeOfBank && trade.nameOfConfirmingBank && trade.locationOfConfirmingBank && trade.swiftCodeOfConfirmingBank
            }
            return true
        }
        return true;
    }

    renderAcceptForTenderMultiOrigin = (bid) => {
        return (
            <ConfirmModalAcceptTenderWithMultiOrigin
                onConfirm={bidAcceptBody => this.props.onAccept(bid.fromCompanyID, bid.inspection, bidAcceptBody)}
                loading={this.props.loading}
                loadingBidInfo={this.props.loadingBidInfo}
                buttonClass="bc-counter mr-3"
                fromCompanyName={this.getCounterParty(bid)}
                trade={{ ...this.props.trade, ...this.getContractBidData(bid) }}
                onClickHandler={() => this.props.GetBidInfo(bid.ID)}
                user={this.props.account.user}
                isTradeOwner={this.props.trade.ownerId === this.props.user.companyId}
            />
        )
    }

    renderAcceptForMultipleBids = (bid) => {
        if (!this.checkBankDetailsFilled(bid)) {
            return (
                <button 
                    className="bc-counter mr-3" 
                    onClick={() => { this.props.onCounter(bid, COUNTER_TYPES.BANK_ONLY) }}
                >
                    Accept
                </button>
            )
        } else {
            var updatedBid = { ...bid, tenderTermsDoc: this.props.trade.tenderTermsDoc };
            if (this.isTenderWithMultiOrigin) {
                return this.renderAcceptForTenderMultiOrigin(updatedBid)
            } else {
                return (
                    <ConfirmModalAcceptAll
                        onConfirm={bidAcceptBody => { this.props.onAccept(bid.fromCompanyID, bid.inspection, bidAcceptBody) }}
                        loading={this.props.loading}
                        loadingBidInfo={this.props.loadingBidInfo}
                        buttonClass="bc-counter mr-3"
                        fromCompanyName={this.getCounterParty(bid)}
                        trade={{ ...this.props.trade, ...this.getContractBidData(updatedBid) }}
                        bidInfoHandler={() => this.props.GetBidInfo(bid.ID)}
                        user={this.props.account.user}
                        isOtherInspector={bid.inspection ==="1"}
                        
                    />
                )
            }
        }
    }

    renderAcceptForNoBids = (trade, acceptSubjectConfirmation, onAccept) => {
        if (trade.origin.includes(',')) {
            return (
                <ConfirmModal
                    buttonClass="bc-counter mr-3"
                    buttonLabel="Accept"
                    showCancelButton={false}
                    buttonConfirmLabel={<React.Fragment><FontAwesomeIcon icon={faCheck} />&nbsp;Ok</React.Fragment>}>
                    <p className="text-center">Multiple origins appear in this trade request. Please select a single origin and submit the counter.</p>
                </ConfirmModal>
            )
        } else if (!this.checkBankDetailsFilled(trade)) {
            return (
                <button 
                    className="bc-counter mr-3" 
                    onClick={() => { this.props.onCounter(trade, COUNTER_TYPES.BANK_ONLY) }}>
                        Accept
                </button>)
        } else if(acceptSubjectConfirmation){
            return (
                <ConfirmModalAcceptOffer
                    trade={trade}
                    buttonClass="bc-counter mr-3"
                    loading={this.props.loading}
                    loadingBidInfo={this.props.loadingBidInfo}
                    onAcceptOffer={this.props.onAcceptOffer} 
                />
            )
        } else {
            return (
                <ConfirmModalAcceptAll
                    onConfirm={e => { onAccept(trade.ownerId, trade.inspection) }}
                    buttonClass="bc-counter mr-3"
                    loading={this.props.loading}
                    loadingBidInfo={this.props.loadingBidInfo}
                    fromCompanyName={trade.requestType === "BUY" ? trade.buyer : trade.seller}
                    trade={this.getContractTradeData(trade)}
                    user={this.props.account.user}
                    isOtherInspector={trade.inspection ==="1"}
                />
            )
        }
    }

    handleOnRowClick = item => {
        this.props.bids.map(itemBid => itemBid.selected = false);
        item.selected = true;
        this.props.GetBidInfo(item.ID);
        if (this.props.onBidSelect) {
            this.props.onBidSelect(item);
        }
    }

    getContractBidData = (bid) => {
        let updatedBid = { ...bid };
        if (Object.keys(this.props.bidInfo).length !== 0) {
            updatedBid.buyerAddress1 = this.props.bidInfo.buyerAddress1 ? this.props.bidInfo.buyerAddress1 : '';
            updatedBid.sellerAddress1 = this.props.bidInfo.sellerAddress1 ? this.props.bidInfo.sellerAddress1 : '';
            updatedBid.buyer = this.props.bidInfo.buyerName;
            updatedBid.seller = this.props.bidInfo.sellerName;
            updatedBid.sellerUser = this.props.bidInfo.sellerUserName;
            updatedBid.buyerUser = this.props.bidInfo.buyerUserName;
            updatedBid.inspectionName = this.props.bidInfo.inspectionName;
            updatedBid.tradeRefId = this.props.trade.tradeRefId;
        }
        return updatedBid;
    }

    renderBidActions = (bid, isOffer, trade, isTradeOwner) => {
        if (bid.fromCompanyID === this.props.user.companyId) {
            switch (bid.status) {
                case BID_STATUS.DECLINED: 
                    return <span>Declined</span>;
                case BID_STATUS.NEW:{
                    if(!isTradeOwner && trade.privacyType === "TENDER"){
                        return (
                            <button 
                                className="bc-counter mr-3" 
                                onClick={() => this.props.onCounter(bid, COUNTER_TYPES.IMPROVE_PRICE)}
                            >
                                Improve Price
                            </button>
                        )
                    }
                    return <span>{isOffer ? 'Offer Sent' : 'Counter Sent'}</span>;
                }
                case BID_STATUS.PEND_CONFIRM_NEW:
                    return <span>Acceptance Sent Pending Confirmation</span>;
                case BID_STATUS.BANK_ONLY_NEW: 
                    return <span>{bid.fromCompanyID === this.props.trade.sellerId ? 'Bank Details Rejected' : 'Bank Details Sent'}</span>
                default:
                    break;
            }
        } else
            if (bid.toCompanyID === this.props.user.companyId) {
                var isNegotiate = false;
                if (negotiateTradePermission() === 1) {
                    isNegotiate = true;
                }
                switch (bid.status) {
                    case BID_STATUS.DECLINED: 
                        return (
                            <button className="bc-counter"
                                onClick={e => {
                                    this.setState({
                                        showModalInfo: true,
                                        contentModalInfo: {
                                            declineReason: bid.declineReason,
                                            fromCompanyName: bid.declinedByCompanyName
                                        }
                                })}}
                            >
                                Declined by {bid.declinedByCompanyName}
                            </button>
                        );
                    case BID_STATUS.COUNTER: 
                        return <span>Countered</span>;
                    case BID_STATUS.PEND_CONFIRM_NEW:
                    case BID_STATUS.NEW:
                        if (isNegotiate) {
                            return (
                                <span>
                                    <button className="bc-counter mr-3" onClick={e => { this.props.onCounter(bid, COUNTER_TYPES.NORMAL) }}> Counter </button>
                                    {((bid.price !== 0 && bid.price !== '0' && bid.price) || this.isTenderWithMultiOrigin) && this.renderAcceptForMultipleBids(bid)}
                                    <ConfirmModalDeclineAll
                                        onConfirm={declineReason => { this.props.onDecline(bid.fromCompanyID, { declineReason }) }}
                                        loading={this.props.loading}
                                        buttonClass="bc-counter mr-3"
                                        fromCompanyName={this.getCounterParty(bid)} />
                                    { trade.privacyType !== "TENDER" &&
                                        <ConfirmModalViewContract
                                            buttonClass="bc-counter mr-3"
                                            loading={this.props.loading}
                                            loadingBidInfo={this.props.loadingBidInfo}
                                            fromCompanyName={this.getCounterParty(bid)}
                                            trade={this.getContractBidData(bid)}
                                            user={this.props.account.user}
                                            bidInfoHandler={() => this.props.GetBidInfo(bid.ID)}
                                        />}
                                </span>
                            );
                        } else {
                            return (
                                <span>
                                    You are not authorized to perform this action
                                </span>
                            )
                        }
                    case BID_STATUS.BANK_ONLY_NEW:
                        if (isNegotiate) {
                            return (
                                <span>
                                    { bid.toCompanyID === this.props.trade.sellerId ?
                                        <button className="bc-counter mr-3" onClick={this.props.onAlternateBankRequest}> Request Alternative Bank </button>
                                        : <button className="bc-counter mr-3" onClick={() => this.props.onCounter(bid, COUNTER_TYPES.BANK_ONLY)}> Resubmit Bank Details </button>}

                                    {(bid.price !== 0 && bid.price !== '0' && bid.toCompanyID === this.props.trade.sellerId && !this.isTenderWithMultiOrigin) && (
                                        <ConfirmModalAcceptAll
                                            onConfirm={e => { this.props.onAccept(bid.fromCompanyID, bid.inspection) }}
                                            loading={this.props.loading}
                                            loadingBidInfo={this.props.loadingBidInfo}
                                            buttonClass="bc-counter mr-3"
                                            fromCompanyName={this.getCounterParty(bid)}
                                            trade={this.getContractBidData(bid)}
                                            bidInfoHandler={() => this.props.GetBidInfo(bid.ID)}
                                            user={this.props.account.user}
                                            isOtherInspector={bid.inspection ==="1"}
                                        />
                                    )}
                                    {this.isTenderWithMultiOrigin && this.renderAcceptForTenderMultiOrigin(bid)}
                                    <ConfirmModalDeclineAll
                                        onConfirm={declineReason => { this.props.onDecline(bid.fromCompanyID, { declineReason }) }}
                                        loading={this.props.loading}
                                        buttonClass="bc-counter mr-3"
                                        fromCompanyName={this.getCounterParty(bid)} />
                                    { trade.privacyType !== "TENDER" &&
                                        <ConfirmModalViewContract
                                            buttonClass="bc-counter mr-3"
                                            loading={this.props.loading}
                                            loadingBidInfo={this.props.loadingBidInfo}
                                            fromCompanyName={this.getCounterParty(bid)}
                                            trade={this.getContractBidData(bid)}
                                            user={this.props.account.user}
                                            bankDetailsRejected={bid.fromCompanyID === this.props.trade.sellerId}
                                            bidInfoHandler={() => this.props.GetBidInfo(bid.ID)}
                                        />}
                                </span>
                            )
                        } else {
                            return (
                                <span>
                                    You are not authorized to perform this action
                                </span>
                            )
                        }
                    default:
                        break;
                }
            }
        return null;
    };


    render() {
        const { trade, bids, onCounter, onAccept, compareLink, selected, onSelect } = this.props;
        if (!trade) {
            // RDEV-1849 - Prevent crash if BidList is null or undefined
            return false;
        }
        const fromCompanyIDFilter = bids && bids.map(s => s.fromCompanyID);
        const toCompanyIDFilter = bids && bids.map(s => s.toCompanyID);
        const listCounetpartyMessage = bids && (!fromCompanyIDFilter.includes(this.props.user.companyId) && !toCompanyIDFilter.includes(this.props.user.companyId));
        const IS_PRIVATE = (trade.sellerId && trade.buyerId)
        const WITH_PRICE = (trade.price !== undefined && trade.price !== 0 && trade.price !== '0');
        const OFFER_REQUIRED = (trade.privacyType === "TENDER" || !WITH_PRICE);
        var isNegotiate = false;
        const isTradeOwner = trade.ownerId === this.props.user.companyId;
        const canCompare = bids && bids.length > 1 && isTradeOwner && !IS_PRIVATE;
        const canComparePrices = trade.privacyType === "TENDER" && trade.dutchAuction && bids && bids.length !== 0 && trade.ownerId !== this.props.user.companyId;
        if (negotiateTradePermission() === 1) {
            isNegotiate = true;
        }
        const acceptSubjectConfirmation = bids && bids.length === 0 && trade.requestType === "SELL" && trade.offerType === 'SUBJECT_CONFIRMATION';

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="request-dtls__card-heading">
                            Negotiation Details & Status
                        </h3>
                        {this.isTenderWithMultiOrigin ? isTradeOwner ?
                            <small className="trades-dtls__error-message">
                                * Multiple prices offered as per origin. Please either visit counter received/improved price received or compare screen for best offers.
                            </small> :
                            <small className="trades-dtls__error-message">
                                * Please consult prices in table below.
                            </small> : null}
                    </div>
                    <div className="col-sm-6">
                        {canCompare > 0 &&
                            <Link to={{
                                pathname: compareLink,
                                state: { initialSelection: this.state.selection }
                            }}>
                                <button className="btn btn-compare float-right mr-0">Compare</button>
                            </Link>}
                        {(canComparePrices) &&
                            <ConfirmModal title="Tender Price Overview"
                                buttonClass="btn btn-compare float-right mr-0"
                                buttonLabel="Compare Prices"
                                showButtonConfirm={false}
                                modalBodyClass="overflow-auto"
                                buttonCancelLabel="Back">
                                <>
                                    <CompareGraphDutchAuction />
                                </>

                            </ConfirmModal>}
                        {(!isTradeOwner && trade.privacyType === "PUBLIC" && listCounetpartyMessage) &&
                            <PublicCommunicationModal
                                onConfirm={(message) => { this.props.onPublicMessage(message) }}
                                loading={this.props.loading}
                                fromCompanyName={this.getCounterParty(trade)}
                            ></PublicCommunicationModal>}
                    </div>
                </div>
                <div className="table-responsive mt-2">
                    <table className="table table-hover latest-negociation-table">
                        <thead>
                            <tr>
                                <th>Counterparty Name</th>
                                <th>Price Per MT</th>
                                <th>Quantity(MT)</th>
                                <th>`Expires ({getLocalTimezone()})`</th>
                                {trade.status === BID_STATUS.NEW && <th>Action</th>}
                                {canCompare && <th>Add to compare</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {bids && bids.length !== 0 && bids.map((item, i) =>
                                <tr className={([item.fromCompanyID, item.toCompanyID].includes(selected)) ? "table-info" : (item.status === BID_STATUS.DECLINED) ? "table-dark" : ""}
                                    onClick={() => onSelect(item)}
                                    key={i}>
                                    <td><Link to={`/company-profile/${item.fromCompanyID}`}>{this.getCounterParty(item)}</Link></td>
                                    <td>
                                        <NumberFormat
                                            value={this.isTenderWithMultiOrigin ? '-' : item.price}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            suffix={this.isTenderWithMultiOrigin ? '' : item.currency === 'USD' ? ' US$' : ` ${item.currency}`}
                                            renderText={value => value} 
                                        />

                                    </td>
                                    <td><NumberFormat value={item.measure} displayType={'text'} thousandSeparator={true} renderText={value => value} ></NumberFormat></td>
                                    <td>{moment(item.bidValidateDate).format(DATEFORMATHOURS)}</td>
                                    {[BID_STATUS.NEW, BID_STATUS.PEND_CONFIRM_NEW].includes(trade.status) &&
                                        <td> {this.renderBidActions(item, (!WITH_PRICE && item.previousBidId === "0"), trade, isTradeOwner)}</td>}
                                    {canCompare &&
                                        <td>
                                            <input type="checkbox"
                                                checked={item.compare}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState(prevState => {
                                                        let array = prevState.selection;
                                                        if (array.includes(item.ID)) {
                                                            array = array.filter(id => id !== item.ID);
                                                        } else {
                                                            array.push(item.ID);
                                                        }
                                                        return { selection: array };
                                                    });
                                                }} />
                                        </td>}
                                </tr>)}
                            {bids && bids.length === 0 &&
                                <tr className={"table-info"}>
                                    <td>
                                        {trade.requestType === "BUY" ? (
                                            <Link to={`/company-profile/${trade.buyerId}`}>{trade.buyer}</Link>
                                        ) : (
                                            <Link to={`/company-profile/${trade.sellerId}`}>{trade.seller}</Link>
                                        )}
                                    </td>
                                    <td>
                                        {trade.bestPrice ? (
                                            <span>Best Price</span>
                                        ) : (
                                            <NumberFormat 
                                                value={this.isTenderWithMultiOrigin ? '-' : trade.price} 
                                                displayType={'text'} 
                                                thousandSeparator={true} 
                                                suffix={this.isTenderWithMultiOrigin ? '' : ` ${trade.currency}`} renderText={value => value} 
                                            />
                                        )}
                                        
                                    </td>
                                    <td>
                                        <NumberFormat 
                                            value={trade.measure} 
                                            displayType={'text'} 
                                            thousandSeparator={true} 
                                            renderText={value => value} />
                                        </td>
                                    <td>{moment(trade.validateDate).format(DATEFORMATHOURS)}</td>

                                    {trade.status === BID_STATUS.NEW &&
                                        <td>
                                            {isNegotiate ?
                                                <>
                                                    {OFFER_REQUIRED ?
                                                        <span>
                                                            <button className="bc-counter mr-3" onClick={e => { onCounter(trade, COUNTER_TYPES.NORMAL) }}>
                                                                Offer Price
                                                            </button>
                                                            {trade.privacyType !== "TENDER" &&
                                                                <ConfirmModalViewContract
                                                                    buttonClass="bc-counter mr-3"
                                                                    loading={this.props.loading}
                                                                    loadingBidInfo={this.props.loadingBidInfo}
                                                                    fromCompanyName={trade.requestType === "BUY" ? trade.buyer : trade.seller}
                                                                    trade={this.getContractTradeData(trade)}
                                                                    user={this.props.account.user}
                                                                />}
                                                        </span>
                                                        :
                                                        <span>
                                                            <button className="bc-counter mr-3" onClick={e => { onCounter(trade, COUNTER_TYPES.NORMAL) }}>Counter</button>
                                                            {this.renderAcceptForNoBids(trade, acceptSubjectConfirmation, onAccept)}
                                                            {(trade.seller && trade.buyer) &&
                                                                <ConfirmModalDeclineAll
                                                                    onConfirm={declineReason => { this.props.onDecline(trade.ownerId, { declineReason }) }}
                                                                    loading={this.props.loading}
                                                                    buttonClass="bc-counter mr-3"
                                                                    fromCompanyName={trade.requestType === "BUY" ? trade.buyer : trade.seller} />}
                                                            {trade.privacyType !== "TENDER" &&
                                                                <ConfirmModalViewContract
                                                                    buttonClass="bc-counter mr-3"
                                                                    loading={this.props.loading}
                                                                    loadingBidInfo={this.props.loadingBidInfo}
                                                                    fromCompanyName={trade.requestType === "BUY" ? trade.buyer : trade.seller}
                                                                    trade={this.getContractTradeData(trade)}
                                                                    user={this.props.account.user} />}

                                                        </span>}
                                                </>
                                                :
                                                <span>
                                                    You are not authorized to perform this action
                                        </span>}
                                        </td>}
                                </tr>}
                        </tbody>
                    </table>
                </div>
                {this.state.showModalInfo &&
                    <div className="modal__container confirm-modal">
                        <div className="modal__wrapper">
                            <span className="modal__close" onClick={e => this.setState({ showModalInfo: false })} />
                            <h3 className="modal__heading my-3">Declining Reason</h3>
                            <div className="modal-body text-left">
                                <div className="text-primary"><strong>From {this.state.contentModalInfo.fromCompanyName}</strong></div>
                                {this.state.contentModalInfo.declineReason ? this.state.contentModalInfo.declineReason : "No reason provided."}
                            </div>

                            <div className="text-center mt-3">
                                <button className={`btn btn-confirm-modal btn--blue`} type="button" onClick={e => this.setState({ showModalInfo: false })} >
                                    {"Close"}
                                </button>
                            </div>
                        </div>
                    </div>}
            </React.Fragment>)
    }
}
const mapStateToProps = state => {
    return {
        trade: state.trade.items.single,
        ContractCompanyinfo: state.trade.ContractCompanyinfo,
        bidInfo: state.trade.bidInfo,
        account: state.account,
        loadingBidInfo: state.loading.loadingBidInfo,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            GetBidInfo,
            GetContractUserInfo
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BidList);