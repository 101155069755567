import React, { Component } from "react";
import {RicexCustomFileLabel} from './../../../components/form/FormFileUpload.module.scss'
import { TradeApi } from '../../../../services';
import _ from 'lodash';
import TradeImagePreview  from '../components/TradeImagePreview';

class UploadImage extends Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();
        const initialImages = _.keyBy(this.props.value.value, 'hash') || {};

        this.state = {
            url: null,
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false,
            images: initialImages,
            previews:{}
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.onCancel= this.onCancel.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    componentWillUnmount(){
        this.onCancel();
    }

    componentDidUpdate(prevProps){
        if(prevProps.value !== this.props.value){
            let updatesImages = this.props.value.value;
            let tempKeyExist = false;
            updatesImages.forEach(element => {
                if(element.temp){
                    tempKeyExist = true;
                }
            });
            if(!tempKeyExist){
                this.setState({
                    images: _.keyBy(this.props.value.value, 'hash') || {}
                })
            }
            
        }
    }

    onFilesAdded(e) {
        if (e.target.files.length!==0 && this.isValid(e.target.files[0])) {
            e.persist()
            this.setState(prevState => { 
                return {
                    file: e.target.files[0],
                    images: { ...prevState.images, loaded: { temp: true}}
                }
            },
            () => {
                this.props.onChange(_.values(this.state.images), false)
            });
        }
    }

    onCancel(){
        this.input.current.value = "";
        this.setState(prevState => { 
            delete prevState.images.loaded;
            return {
                file: "",
                images: prevState.images
            }
        },
        () => {
            this.props.onChange(_.values(this.state.images), true)
        });
        

    }

    uploadFile(e) {
        e.preventDefault();
        e.persist();
        if (this.isValid(this.state.file)) {
            this.setState({uploading: true});
            const formData = new FormData();
            formData.append("upload", this.state.file, this.state.file.name);
            TradeApi.postTradeImage(formData)
            .then(res => {
                this.input.current.value = "";
                if (res.status===200) {
                    this.setState(prevState => {
                        delete prevState.images.loaded;
                        return {
                            uploading: false, 
                            file: "",
                            images: {...prevState.images, [res.data.hash]: res.data}
                        }
                    }, () =>  {
                        this.props.onChange(_.values(this.state.images), true)
                    });
                }else{
                    this.setState({
                        uploading: false, 
                        fileError : "An error occured while uploading the image"
                    }); 
                }                
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }               
            });           
        }
    }

    isValid = (image) => {
        const fileType = image.type;
        let fileSize = image.size;
        fileSize = fileSize / (1024 * 1024);
        let result = true;
        if (!["image/png", "image/jpeg"].includes(fileType)) {
            this.setState({ fileError : "Please upload file only with *.png, *.jpeg"});
            result = false;
        }else if(fileSize > 15.36) {
            this.setState({ fileError : "File size can't exceed 15 MB"});
            result = false;
        }else if(Object.keys(this.state.images).filter(key => key !== "loaded").length >= 10) {
            this.setState({ fileError : "The limit of 10 images attached to a trade has been reached"});
            result = false;
        
        }else{
            this.setState({fileError: undefined});
        }
        return result;
    }

    removeImage(imageHash) {
        this.setState(prevState => {
            delete prevState.images[imageHash]               
            return {images: prevState.images}
        }, () => {
            this.props.onChange(_.values(this.state.images), false)
        });
    }

    render() {

        const { value, validation, name } = this.props;
        const isInvalid = validation && validation.hasOwnProperty(name);
        return (
            <div className="row">
                <div className="col-sm-12 mt-2">
                    {Object.keys(this.state.images).length !== 0?
                        <label className="label mt-2 mnb-2"><span>Uploaded Images </span><span>{Object.keys(this.state.images).filter(key => key !== "loaded").length}</span><span>/10</span></label>:
                        <label className="label mt-2 mnb-2">Add images to your trade request</label>}
                </div>
                <div className="col-sm-12">
                    <p className="text-justified">
                        <small>You represent, warrant and agree that you have obtained all the necessary copyright, trademark, trade secret, patent licences or permissions as may be required in for any material 
                        or information posted on or authorised to appear on the Rice Exchange Platform. 
                        All material or images you upload here will be stored in the ledger and be made visible in the trade request.
                        </small>
                    </p>
                </div>
                {Object.keys(this.state.images).filter(key => key !== "loaded").map( (key, index) => 
                <div className="col-sm-12 col-lg-4" key={`card-wrapper-${key}`}>
                    <div className="card">
                        <div className="card-header">
                           <strong>{this.state.images[key].name}</strong> 
                            <button type="button" className="close float-right" data-dismiss="card" aria-label="Close" onClick={e => this.removeImage(key)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="card-body overflow-hidden">
                            <TradeImagePreview image={this.state.images[key]}/>
                        </div>
                    </div>
                </div>)}

                
                <div className="col-sm-12 mt-3">
                    <div className="form-row">
                        <div className="col">   
                            <div className="custom-file">
                                <input type="file" ref={this.input} 
                                    className={`custom-file-input ${(isInvalid || this.state.fileError) ? 'is-invalid':  value.touched || value.validated ? 'is-valid' : ''}`}
                                    id="inputImage" 
                                    aria-describedby="inputImage"
                                    accept=".jpg,.png,.jpeg,.tif, image/*" 
                                    onChange={this.onFilesAdded}/>
                                <label className={`custom-file-label ${RicexCustomFileLabel}`} htmlFor="customFile">{ this.state.file?this.state.file.name : 'Choose file'}</label>
                            </div>
                        </div>
                        <div>
                            {(this.state.file || this.state.fileError) && 
                            <button  type="button" className="btn btn-clear ml-1"
                                disabled={this.state.uploading}
                                onClick={this.onCancel}>
                                <span>{"Cancel"}</span>
                            </button>}

                            {this.state.file  && 
                            <button  type="button" className="btn btn-upload ml-1"
                                disabled={this.state.uploading || Object.keys(this.state.images).filter(key => key !== "loaded").length >= 10}
                                onClick={this.uploadFile}>
                                <span>{this.state.uploading?"Uploading...":"Upload"}</span>
                            </button>}
                        </div>
                    </div>
                    { isInvalid &&
                    <small id="helpFile" className="form-text text-danger">This image is not uploaded yet</small> 
                    }
                    {this.state.fileError?
                    <small id="helpFile" className="form-text text-danger">{this.state.fileError}</small>:
                    <small id="helpFile" className="form-text text-muted">Only image types .png and .jpg accepted (max size 15MB per images, max 10 images)</small>} 
                </div>
            </div>
        );
    }
}

export default UploadImage;
