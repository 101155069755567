import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const MessageNonIntegratedInspector = function ({ onCancel }) {

    return <div className="modal__container">
        <div className="modal__wrapper px-0 py-0 warningCode" >
            <div className="modal-content">
                <div className="modal-header">
                    <div className="modal-header-center">
                        <h5 className="modal-title">Warning Message</h5>
                    </div>
                </div>
                <div className="modal-body py-0">
                    <p>You are selecting an inspection company that is not an integrated inspector of the platform.
                         Consequently following functionality will not be available:</p>
                    <p>
                        1. Automatic nomination and notifications<br />
                        2. Daily inspection reports<br />
                        3. Verification of the uploading party<br />
                        4. Verification of authenticity of the reports or certificates rendered
                    </p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-btn-popup btn-cancel" id="btnclose" data-dismiss="modal" onClick={onCancel} >
                        <FontAwesomeIcon icon={faTimes} /> Close
                <span aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>

}

export default MessageNonIntegratedInspector;