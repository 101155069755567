import React, { useState, useEffect, useMemo}  from 'react'
import { getChildVesselNomination, GetVesselNomination, getChildShipments, GetShipments } from '../../../../../modules/module.trade';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ShipmentInfoTab from './ShipmentInfoTab';
import { STEPS, TRADE_STATUS } from '../../../../../services/service.values';

const infoType = {
    DEFAULT: "DEFAULT",
    MULTIPLE_SHIPMENTS_PARENT: "MULTIPLE_SHIPMENTS_PARENT",
    MULTIPLE_SHIPMENTS_CHILD: "MULTIPLE_SHIPMENTS_CHILD",
}

/**
 * @author Jean-Marc Cheynier <jean-marc.cheynier@fujitsu.com>
 * @version RDEV-2063
 * Vessel Info Tab component wrapper
 * @param  {string} parentId - The ID of the parent trade
 * @returns {Component} ShipmentInfoTabWrapper
 */
const ShipmentInfoTabWrapper = ({ trade, tradeMap,pointOfView,tradeRefIdHeader,shipmentMap,openModal,checkShipmentquantityStatus }) => {
    const vesselInfoType = useMemo(() => {
        if (trade) {
            if (trade.partialShipmentFlag && trade.parentTradeRequestID) {
                return infoType.MULTIPLE_SHIPMENTS_CHILD;
            }

            if (trade.partialShipmentFlag) {
                return infoType.MULTIPLE_SHIPMENTS_PARENT;
            }
        }

        return infoType.DEFAULT;
    }, [trade])
    
    const parentTradeRequestID=(vesselInfoType === infoType.MULTIPLE_SHIPMENTS_CHILD)?trade.parentTradeRequestID:trade.id;
    const parentTrade=tradeMap[parentTradeRequestID];

    useEffect(() => {
        if(pointOfView ==='buyer' && parentTrade && parentTrade.status === 'MULTIPLE_SHIPMENTS' && parentTrade.incoterm === 'FOB'  && parentTrade.childTradeRequestID && parentTrade.childTradeRequestID.length>0){
             if(checkAllChildTradeStausAdvice()){
                openModal('RENDER_FOB_SHIPPING_ADVICE_ALERT');
                checkShipmentquantityStatus(parentTrade);
             }
        }
    }, [trade,shipmentMap])

    const checkAllChildTradeStausAdvice=()=>{
        const adviceStatusChildTrades=[];
        const isMultiChild=parentTrade.childTradeRequestID.length > 1;
        parentTrade.childTradeRequestID.map(childTradeRqId=>{
            if(STEPS.indexOf(tradeMap[childTradeRqId].status) >= STEPS.indexOf(TRADE_STATUS.ADVICE) || 
              (isMultiChild && STEPS.indexOf(tradeMap[childTradeRqId].status)==STEPS.indexOf(TRADE_STATUS.PROFORMA_INVOICE))){
                 adviceStatusChildTrades.push(tradeMap[childTradeRqId])
            }
        })
        return parentTrade.childTradeRequestID.length === adviceStatusChildTrades.length;
    }
    return (
        <div className="trades-dtls__contract">
            {  ( vesselInfoType === infoType.MULTIPLE_SHIPMENTS_PARENT || vesselInfoType === infoType.DEFAULT) &&
                <ShipmentInfoTab
                    tradeRefIdHeader={tradeRefIdHeader}
                    type={infoType.MULTIPLE_SHIPMENTS_PARENT}
                    pointOfView={pointOfView}
                    parentId={parentTradeRequestID} />
            }
            {   vesselInfoType === infoType.MULTIPLE_SHIPMENTS_CHILD &&
                <ShipmentInfoTab
                    tradeRefIdHeader={tradeRefIdHeader}
                    type={infoType.MULTIPLE_SHIPMENTS_CHILD}
                    pointOfView={pointOfView}
                    parentId={parentTradeRequestID} />
            } 
        </div>
    )
}
    
const mapStateToProps = state => {
    return {
        tradeMap: state.trade.items.tradeMap,
        trade: state.trade.items.single || {},
        loading: state.loading.loadingVesselDetails,
        LoadingNominationInfo: state.loading.loadingNomination,
        triggerNominationUpdate: state.trade.triggerNominationUpdate,
        synthesis: state.shipment.synthesis
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getChildVesselNomination: getChildVesselNomination,
            getVesselNomination: GetVesselNomination,
            getChildShipments: getChildShipments,
            getShipments: GetShipments
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentInfoTabWrapper);

