/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  DATEFORMAT,
  MEASUREMENTS,
  TRADE_STATUS_HUMANIZE,
  getNominateVesselStatus,
} from '../../../../../services/service.values'
import RxNumberFormat from '../../../../components/Formatter/RxNumberFormat'
import { TRADE_STATUS } from '../../../../../services/service.values'

/**
 * @author Jean-Marc Cheynier <jean-marc.cheynier@fujitsu.com>
 * @version RDEV-2063
 * Component to show the detail of the partial shipments ( child trades ) of a trade in Material-Table
 * @param  {string} parentId - The ID of the parent trade
 * @returns {Component} MultipleShipmmentDetail - A component to be shown on Material-Table DetailPanel when the user expands a row
 * @Component
 */

const TableHead = () => (
  <thead className="thead-light">
    <tr>
      <th scope="col">Trade Ref ID</th>
      <th scope="col">Vessel</th>
      <th scope="col">Nominated / Shipped Quantity</th>
      <th scope="col">Created On</th>
      <th scope="col">Status</th>
    </tr>
  </thead>
)

const Vessel = ({ vesselNomination }) => {
  if (!vesselNomination) return '-'
  if (!vesselNomination.name) return '-'
  return '' + vesselNomination.name
}

const Quantity = ({ suffix, quantity }) => {
  if (quantity === 0) return '-'
  return <RxNumberFormat value={quantity} suffix={suffix} />
}
const Created = ({ t }) => <td>{moment(t.createdAt).format(DATEFORMAT)}</td>
const Status = ({ t, companyId, vesselNomination }) => (
  <td>
    <span
      className={
        t.status === 'CANCELED' ? 'badge bg-danger' : 'badge bg-primary'
      }>
      {TRADE_STATUS.PROFORMA_INVOICE !== t.status
        ? TRADE_STATUS_HUMANIZE[TRADE_STATUS[t.status]]
        : getNominateVesselStatus(
            t,
            TRADE_STATUS_HUMANIZE[TRADE_STATUS[t.status]],
            companyId,
            vesselNomination
          )}
    </span>
  </td>
)
const onTradeRowClick = (id, status, history, pathName) => {
  if (status == 'CANCELED') {
    history.push(`/requests/details/${id}/?canceledTrade=true`)
  } else if (pathName.includes('/advice')) {
    history.push(`/trades/details/${id}/advice`)
  } else {
    history.push(`/trades/details/${id}/nominatedVesselInfo`)
  }
}

const TableRow = ({
  trade,
  childId,
  t,
  companyId,
  index,
  vesselNomination,
  quantity,
}) => {
  const history = useHistory()
  const location = useLocation()
  return (
    <tr
      className={trade && trade.id === childId ? 'table-info' : ''}
      key={`child-${index}`}
      onClick={() =>
        onTradeRowClick(t.id, t.status, history, location.pathname)
      }>
      <th scope="row">{t.tradeRefId}</th>
      <td>
        <Vessel {...{ vesselNomination }} />
      </td>
      <td>
        <Quantity
          quantity={quantity}
          suffix={` ${MEASUREMENTS[t.measurement]}`}
        />
      </td>
      <Created {...{ t }} />
      <Status {...{ t, companyId, vesselNomination }} />
    </tr>
  )
}

const tableRow = ({
  childId,
  index,
  tradeMap,
  vesselNominationMap,
  shipmentMap,
  trade,
  companyId,
}) => {
  const t = tradeMap[childId]
  const vesselNomination = t && t.id ? vesselNominationMap[t.id] : null
  const shipment = t && t.id ? shipmentMap[t.id] : null
  let quantity
  if (!t || !vesselNomination) return null

  if (
    shipment &&
    shipment.list &&
    shipment.list.length !== 0 &&
    shipment.list[0].Bill.quantCleanOnBoard
  ) {
    quantity = shipment.list.reduce(
      (init, item) => init + item.Bill.quantCleanOnBoard,
      0
    )
  } else if (vesselNomination && vesselNomination.nominateQuantity) {
    quantity = vesselNomination.nominateQuantity
  }

  return (
    <TableRow
      key={childId}
      shipments={shipment}
      {...{
        vesselNomination,
        trade,
        childId,
        t,
        quantity,
        companyId,
        index,
      }}
    />
  )
}

const TableBody = (props) => {
  return (
    <tbody>
      {props.parent.childTradeRequestID && props.parent.childTradeRequestID.map((childId, index) =>
        tableRow({ ...props, childId, index })
      )}
    </tbody>
  )
}

const MultipleShipmmentDetail = ({
  parentId,
  trade,
  tradeMap,
  vesselNominationMap,
  shipmentMap,
  companyId,
}) => {
  // Get parent trade from the local tradeMap
  const parent = useMemo(() => {
    if (tradeMap) {
      return tradeMap[parentId]
    } else {
      return false
    }
  }, [parentId, tradeMap])

  // If the parent cannot be found in the map, or ig the parent has no child then the component is not displayed
  if (!parent || (parent.childTradeRequestID && parent.childTradeRequestID.length === 0)) return null

  return (
    <div className="py-2 px-3">
      <label className="label h6">Partial Shipments</label>
      <table className="table table-sm table-hover table-striped">
        <TableHead />
        <TableBody
          {...{
            parentId,
            parent,
            trade,
            tradeMap,
            vesselNominationMap,
            shipmentMap,
            companyId,
          }}
        />
      </table>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    trade: state.trade.items.single,
    tradeMap: state.trade.items.tradeMap,
    vesselNominationMap: state.trade.vesselNominationMap,
    shipmentMap: state.trade.shipmentMap,
    companyId: state.account.user.companyId,
  }
}

export default connect(mapStateToProps, null)(MultipleShipmmentDetail)
