import React from 'react';
import {MEASUREMENTS,DATEFORMAT } from '../services/service.values';
import RxNumberFormat from '../app/components/Formatter/RxNumberFormat';
import {getInspectionCompanyName} from '../app/admin/trades/modals/ViewSubstitueVesselInfo';
import moment from 'moment-timezone';

export const vesselInfoFieldList=[
    {key: 'name', label: 'Vessel Name'},
    {key: 'message', label: 'Special Instructions'},
    {key: 'laycanDateFrom', label: 'Laycan Date From', format: (value) => moment(value).format(DATEFORMAT)},
    {key: 'laycanDateTo', label: 'Laycan Date To', format: (value) => moment(value).format(DATEFORMAT)},
    {key: 'shippingTolerance', label: 'Shipping Tolerance'},
    {key: 'nominateQuantity', label: 'Quantity (MTons)' },
    {key: 'inspectionName', label: "Inspection Company"},
    {key: 'rejectMessage', label: 'Reason to reject vessel'}
]
export const containerInfoFieldList=(measurement)=>{
    return [
        { key: 'name', label: 'Intended Vessel, or Sub, OBN' },
        { key: 'message', label: 'Special Instructions',format: value => (value !== undefined && value !== null && value !== "" ) ? `${value}` : '-' },
        { key: 'bookingReference', label: 'Booking Reference' },
        { key: 'cutOfDate', label: 'Cut off Date', format: (value) => moment(value).format(DATEFORMAT) },
        { key: 'shippingLine', label: 'Name of Shipping Line' },
        { key: 'noOfContainer', label: 'Number of Containers' },
        { key: 'quantityPerContainer', label: 'MTon per Container'},
        {
            key: 'nominateQuantity', label: 'Total MTon', format: value => <RxNumberFormat
                value={value}
                suffix={` ${MEASUREMENTS[measurement]}`}
            />
        },
        {key: 'rejectMessage', label: 'Reason to reject container'}
    ]
}
export const  rejectedSubstituteFieldList =(shippingType,incoterm,inspector)=>{
    return [
        {key: 'name', label: 'Vessel Name'},
        {key: 'message', label: 'Special Instructions'},
        ...(shippingType === "VESSEL" ? [
            {key: 'laycanDateFrom', label: 'Laycan Date From', format: (value) => moment(value).format(DATEFORMAT)},
            {key: 'laycanDateTo', label: 'Laycan Date To', format: (value) => moment(value).format(DATEFORMAT)},
            {key: 'shippingTolerance', label: 'Shipping Tolerance'},
        ] : [
            {key: 'bookingReference', label: 'Booking Reference'},
            {key: 'cutOfDate', label: 'Cut off Date', format: (value) => moment(value).format(DATEFORMAT)},
            {key: 'shippingLine', label: 'Name of Shipping Line'},
            {key: 'noOfContainer', label: 'Number of Containers'},
            {key: 'quantityPerContainer', label: 'MTon per Container'},
        ]),
        {key: 'nominateQuantity', label: shippingType === "VESSEL" ? 'Quantity (MTons)' : 'Total MTon'},
        {key: 'inspectionName', label: "Inspection Company",format: (value,serie) => getInspectionCompanyName(value,serie,inspector)},
        ...(incoterm === "FOB" ? [{key: 'rejectMessage', label: 'Reason to reject vessel'}] : [])
    ]
}
export const View_Rejected_Vessel_Nomination='View Rejected Vessel Nomination';
export const View_Rejected_Container_Nomination='View Rejected Container Nomination'
export const GET_VESSEL_NOMINATION_URL='vessel_nomination/vesselId';
export const GET_REJECTED_SUBSTITUTE_NOMINATION_URL='substitute_vessel';
export const View_Rejected_Substitute_Container_Nomination='View Rejected Substitute Container Nomination';
export const View_Rejected_Substitute_Vessel_Nomination='View Rejected Substitute Vessel Nomination'