import React from 'react'

export default function CustomDotsPreloader({ loading, children}) {
    return (<>
            { loading ?
                <div className="preloader my-2">
                    <div className="dots__item dots__item--black dots__item--first" />
                    <div className="dots__item dots__item--black dots__item--second" />
                    <div className="dots__item dots__item--black dots__item--third" />
                </div>
                :
                <>{ children }</>
            }
        </>)


}

