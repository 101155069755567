import React, { Component } from "react";
import { bindActionCreators, compose } from 'redux';
import {push} from "connected-react-router";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DropzoneCustom from "../../../components/form/dropzone/DropzoneCustom";
import "./InspectionUpload.scss";
import { LoadingFileUpload } from '../../../../modules/module.loading';

class InspectionUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: {},
            uploading: false,
            loading:false,
            uploadProgress: {},
            successfullUploaded: false
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }

    onFilesAdded(files) {
        this.setState({
            files: files
        });
    }

    uploadFiles(e) {
        this.setState({ loading: true });
        e.preventDefault();
        if (this.validateFileType()) {
            this.props.fileUploadValidate('');
            const formData = new FormData();
            formData.append("upload", this.state.files[0], this.state.files[0].name);
            this.props.fileUploadData(this.state.files);
            this.setState({ loading: false });
                this.props.onChange(
                    this.props.name,
                    this.state.files[0].name,
                    this.props.subState !== '' ? this.props.subState : '',
                    this.props.i !== '' ? this.props.i : '');
        }
    }

    validateFileType = () => {
        const fileType = this.state.files[0].type;
        let fileSize = this.state.files[0].size;
        fileSize = fileSize / (1024 * 1024);
        let result = false;
        if (fileType === 'application/pdf') {
            result = true;
        } else {
            this.props.fileUploadValidate("Please upload file only with *.pdf format");
            window.scrollTo(0,0);
            return result;
        }

        if (fileSize > 15.36) {
            this.props.fileUploadValidate("File size can't exceed 15 MB");
            window.scrollTo(0,0);
            result = false;
        }
        return result;
    }

    removeFile(e) {
        e.preventDefault();
        this.setState({ files: null });
        this.props.onChange(
            this.props.name,
            '',
            this.props.subState !== '' ? this.props.subState : '',
            this.props.i !== '' ? this.props.i : ''
        );
    }

    render() {
        return (
            <div className="form-input">

                <label className="label">
                    {this.props.value ? this.props.value.label : ''}
                    <span className="req-field">{this.props.value && this.props.value.required ? '*' : ''}</span>
                </label>
                {this.props.value.value !== '' ? (
                    <div className="custom-file-container ">
                        <div className="custom-file-field mr-md-3">
                            <input
                                type="text"
                                value={this.props.value.value}
                                className="input form-control"
                                disabled={true}
                            />
                        </div>
                        <div className="pull-right">
                            <button
                                className="btn btn-upload-clear pull-right mt-xxs-1 lh-1"
                                onClick={(e) => { this.removeFile(e) }}
                              //  disabled={this.props.canSubmit}
                            >Clear
                                </button>
                        </div>
                    </div>
                ) : (
                        <DropzoneCustom
                            onFilesAdded={this.onFilesAdded}
                            disabled={this.state.uploading || this.state.successfullUploaded}
                            uploadFiles={e => this.uploadFiles(e)}
                            files={this.state.files}
                            validation={this.props.validation}
                            name={this.props.name}
                            loading={this.state.loading}
                        />

                    )}
            </div>
        );
    }
}


const mapStateToProps = state => ({
    account: state.account,
    fileUpload: state.platformAdmin.fileUpload,
  //  loading: state.loading.isFileUpload,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            navigate: () => push('/'),
            LoadingFileUpload,
        },
        dispatch
    );

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(InspectionUpload);
