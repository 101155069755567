import { Fab } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const RxXsFab = withStyles({
    root: {
        height: '18px !important',
        width: '18px',
        lineHeight: '18px',
        fontSize: '10px',
        minHeight: '18px',
    },
    label: {
      textTransform: 'capitalize',
    },
  })(Fab);