import { AccountApi } from '../services';
import { LoadingMoreNotifications, LoadingNotifications } from './module.loading';

export const NOTIFICATION_NEW = 'notification/NEW';
export const NOTIFICATION_MARK_READ_PENDING = 'notification/MARK_READ_PENDING'
export const NOTIFICATION_MARK_READ = 'notification/MARK_READ';
export const GET_NOTIFICATIONS = 'notification/GET_NOTIFICATIONS';
export const NOTIFICATION_MARK_ALL_READ = 'notification/MARK_ALL_READ';
export const NOTIFICATION_DELETE_ALL = 'notification/CLEAR_ALL';
export const NOTIFICATION_DELETE = 'notification/CLEAR';
export const DELETE_NOTIFICATION_PENDING = 'notification/DELETE_PENDING';
export const DELETE_NOTIFICATION_ERROR = 'notification/DELETE_ERROR';

const getInitial = () => ({
        messages: {
            list: [],
            new: 0,
            count: 0
        }
    });

export default (state = getInitial(), action = {}) => {
    let type = action.type;
    let payload = action.payload;
    switch (type) {
        case NOTIFICATION_NEW:
            payload.id = payload.id || payload.ID;
            state.messages.list = [payload, ...state.messages.list];
            state.messages.new = state.messages.new + 1
            state.messages.count = state.messages.count + 1;
            return { ...state };
        case NOTIFICATION_MARK_READ_PENDING:
            let tNotifPending = state.messages.list.find(item => item.id === payload)
            if (tNotifPending) {
                tNotifPending.read = true;
            }
            state.messages.list = [...state.messages.list]
            return { ...state };
        case NOTIFICATION_MARK_READ:
            const notification = state.messages.list.find(item => (item.id === payload && !item.read))
            if (notification) {
                notification.read = true;
                state.messages.new = state.messages.new - 1
            }
            return { ...state };
        case NOTIFICATION_MARK_ALL_READ:
            state.messages.list.forEach(item => (item.read = true));
            state.messages.new = 0;
            return { ...state };
        case GET_NOTIFICATIONS:
            const loadedNotifications = payload.notifications.map(notification => (
                {
                    ...notification,
                    date: notification.date || notification.CreatedAt,
                    id: notification.id || notification.ID
                }
            ));
            state.messages.list = payload.append ? [...state.messages.list, ...loadedNotifications] : loadedNotifications
            state.messages.count = payload.count;
            state.messages.new = payload.unread;
            return { ...state };
        case DELETE_NOTIFICATION_PENDING:
            let mNotifPending = state.messages.list.find(item => item.id === payload)
            if (mNotifPending) {
                mNotifPending.deletePending = true;
                mNotifPending.deleteError = false;
            }
            state.messages.list = [...state.messages.list]
            return { ...state };

        case DELETE_NOTIFICATION_ERROR:
            let mNotifError = state.messages.list.find(item => item.id === payload)
            if (mNotifError) {
                mNotifError.deletePending = false;
                mNotifError.deleteError = true;
            }
            return { ...state };
        case NOTIFICATION_DELETE:
            let newList = state.messages.list.filter(notification => notification.id !== payload);
            state.messages.list = newList;
            state.messages.count = newList.length;
            state.messages.new = state.messages.list.find(item => !item.read).length
            return { ...state };
        case NOTIFICATION_DELETE_ALL:
            return getInitial();
        default:
            return state;
    }
};

export const GetNotifications = params => {
    return dispatch => {
        LoadingMoreNotifications(true, dispatch);
        AccountApi.getNotifications(params)
            .then(response => {
                LoadingMoreNotifications(false, dispatch);
                dispatch({type: GET_NOTIFICATIONS, payload: {...response.data, append: !!params.skip}});
            })
            .catch(e => {
                console.error("Failed to load Notifications");
            })
    };
};

export const MarkReadNotificationPending = (notificationId, dispatch) => {
    return dispatch({
        type: NOTIFICATION_MARK_READ_PENDING,
        payload: notificationId
    })
};

export const MarkRead = (id) => {
    return dispatch => {
        MarkReadNotificationPending(id, dispatch)
        AccountApi.markNotificationAsRead(id)
            .then(() => {
                dispatch({
                    type: NOTIFICATION_MARK_READ,
                    payload: id
                });
            });
    };
};

export const MarkAllAsRead = () => {
    return dispatch => {
        LoadingNotifications(true, dispatch);
        AccountApi.markAllNotificationsAsRead()
            .then(() => {
                LoadingNotifications(false, dispatch);
                dispatch({
                    type: NOTIFICATION_MARK_ALL_READ,
                });
            })
            .catch(() => {
                LoadingNotifications(false, dispatch);
            });
    };
};

export const DeleteNotificationPending = (notificationId, dispatch) => {
    return dispatch({
        type: DELETE_NOTIFICATION_PENDING,
        payload: notificationId
    })
};

export const DeleteNotificationError = (notificationId, dispatch) => {
    return dispatch({
        type: DELETE_NOTIFICATION_ERROR,
        payload: notificationId
    })
};

export const DeleteNotification = id => {
    return dispatch => {
        //LoadingNotifications(true, dispatch);
        DeleteNotificationPending(id, dispatch)
        AccountApi.deleteNotification(id)
        .then(() => {
            dispatch({type: NOTIFICATION_DELETE, payload: id});
            //LoadingNotifications(false, dispatch);
        })
        .catch(() => {
            DeleteNotificationError(id, dispatch)
        });
    };
};

export const DeleteAllNotifications = () => {
    return dispatch => {
        LoadingNotifications(true, dispatch);
        AccountApi.deleteAllNotifications()
            .then(() => {
                dispatch({type: NOTIFICATION_DELETE_ALL});
                LoadingNotifications(false, dispatch);
            })
            .catch(() => LoadingNotifications(false, dispatch));
    }
};
