import { history } from '../store';
import { Redirect, Route, Switch } from 'react-router-dom';
import withTracker from './components/withTracker';
import { ForgotPasswordSent } from './account/password/ForgotSent';
import { Login } from './account';
import { Register } from './account/register/Register';
import { RegisterByInvite } from './account/register/RegisterInvite';
import { UpdatePassword } from './account/password/UpdatePassowrd';
import { ForgotPassword } from './account/password/Forgot';
import RequestDetails from './admin/requests/RequestDetails';
import CompareOffers from './admin/requests/compare/CompareOffer'
import Exchange from './admin/exchange/Exchange';
import CreateRequest from './admin/requests/create/CreateTradeRequest';
import Requests from './admin/requests/Requests';
import TradesDetails from './admin/trades/TradesDetails';
import Trades from './admin/trades/Trades';
import PastTrades from './admin/trades/PastTrades';
import Profile from './admin/profile/Profile';
import CompanyProfile from './admin/companyProfile/CompanyProfile';
import { ConnectedRouter } from 'connected-react-router'
import React, { Component } from 'react';
import AccessControl from './components/AccessControl';
import { UserTypes } from '../utils/userTypes';
import PlatformAdminDashboard from './platformAdmin/components/PlatformAdminDashboard';
import Dashboard from './admin/dashboard';
import AdminManagement from './adminManagement/AdminManagement';
import JoiningRequestList from './platformAdmin/components/JoiningRequestList';
import CompanyList from './platformAdmin/components/CompanyList';
import kycPendingList from './platformAdmin/components/KycPendingList';
import AdminAuditLog from './platformAdmin/components/AdminAuditLog';
import CompanyJoiningDetail from './platformAdmin/components/JoiningDetails';
import CompanyKYCDetail from './platformAdmin/components/KycDetails';
import KycHome from './platformAdmin/components/KycHome';
import ShippingQuoteOverview from './admin/trades/components/ShippingQuoteOverview';
import ShippingQuoteDetails from './admin/trades/components/ShippingQuoteDetails'; 
import ProvisionalShippingList from './admin/trades/components/ProvisionalShippingList'; 
import FinalShippingList from './admin/trades/components/FinalShippingList';
import InspectionQuoteOverview from './admin/trades/components/InspectionQuoteOverview'; 
import ProvisionalInspectionList from './admin/trades/components/ProvisionalInspectionList'; 
import FinalInspectionList from './admin/trades/components/FinalInspectionList';
import InspectionQuoteDetails from './admin/trades/components/InspectionQuoteDetails';
import TenderWhitelisting from './admin/tenderWhitelisting/TenderWhitelisting';
import Shipments from './admin/shipments/Shipments';

const defaultAccessControl = {
    //commenting as inspeciton company user not able to complete kyc due to this. Need further analysis on impact.
   // excludeCompanyTypes: [INSPECTION_COMPANY],
    excludeUserTypes: [UserTypes.PLATFORM_ADMIN, UserTypes.KYC_USER],
    renderNoAccess: <Redirect to={{ pathname: '/trades' }} />
};

const kycAccessControl = {
    includeUserTypes: [UserTypes.PLATFORM_ADMIN],
    excludeUserTypes: [UserTypes.KYC_USER, UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]
};

const rolebaseAccessControl = {
    includeUserTypes: [UserTypes.COMPANY_ADMIN],
    excludeUserTypes: [UserTypes.KYC_USER, UserTypes.PLATFORM_ADMIN, UserTypes.COMPANY_EMPLOYEE],
};

const tradesAccessControl = {
    excludeUserTypes: [UserTypes.PLATFORM_ADMIN, UserTypes.KYC_USER],
    renderNoAccess: <Redirect to={{ pathname: '/' }} />
};

function getDashboard(user) {
    if (user && user.userType === UserTypes.PLATFORM_ADMIN) {
        return withTracker(PlatformAdminDashboard);
    }
     if (user && user.userType === UserTypes.KYC_USER) {
        return withTracker(KycHome);
    } else {
        return withTracker(Dashboard);
    }
}

const withSearch = (WrappedComponent) => {
    return class extends Component {
        render() {
            return <WrappedComponent locationSearch={window.location.search} {...this.props} />
        }
    }
};

function ProtectedRoute({ component: ProtectedComponent, accessControl = {}, user, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => (
                <AccessControl
                    user={user}
                    {...accessControl}
                >
                    <ProtectedComponent {...props} />
                </AccessControl>
            )}
        />
    )
}

export const AppRouter = ({ user }) => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/account/forgot/success" component={withTracker(ForgotPasswordSent)} />
                <Route path="/account/login" component={withTracker(Login)} />
                <Route path="/account/register" component={withTracker(Register)} />
                <Route path="/account/accept/:code" render={() => <UpdatePassword isReset={true} />} />
                <Route path="/account/reset/:code" render={() => <UpdatePassword isReset={true} />} />
                <Route path="/account/invite/:code" component={withTracker(RegisterByInvite)} />
                <Route path="/account/forgot" component={withTracker(ForgotPassword)} />


                {user !== undefined && !user && <Redirect to="/account/login" />}
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path={"/shipments"} component={withTracker(Shipments)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path={"/exchange/details/:id/compare"} component={withTracker(CompareOffers)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path={"/requests/details/:id/counterparty/:counterparty/compare" } component={withTracker(CompareOffers)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path={"/requests/details/:id/compare"} component={withTracker(CompareOffers)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/exchange/details/:id" component={withTracker(RequestDetails)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/exchange" component={withTracker(Exchange)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/requests/details/:id/counterparty/:counterparty" component={withTracker(withSearch(RequestDetails))} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/requests/details/:id" component={withTracker(withSearch(RequestDetails))} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/requests/create" component={withTracker(CreateRequest)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/requests/update/:id" component={withTracker(CreateRequest)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/requests" component={withTracker(Requests)} />
                <ProtectedRoute user={user} accessControl={tradesAccessControl} path="/trades/details/:id" component={withTracker(withSearch(TradesDetails))} />
                <ProtectedRoute user={user} accessControl={tradesAccessControl} path="/trades" component={withTracker(Trades)} />
                <ProtectedRoute user={user} accessControl={tradesAccessControl} path="/pasttrades" component={withTracker(PastTrades)} />
                <ProtectedRoute user={user} accessControl={{ ...defaultAccessControl, excludeUserTypes: [] }} exact path="/" component={getDashboard(user)} />
                <ProtectedRoute user={user} path="/profile" component={withTracker(Profile)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/tender-whitelisting" component={withTracker(TenderWhitelisting)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/company-profile/:id" component={withTracker(CompanyProfile)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/company-profile" component={withTracker(CompanyProfile)} />
                <ProtectedRoute user={user} accessControl={kycAccessControl} path="/company/joiningList" component={withTracker(JoiningRequestList)} />
                <ProtectedRoute user={user} accessControl={kycAccessControl} path="/company/list" component={withTracker(CompanyList)} />
                <ProtectedRoute user={user} accessControl={kycAccessControl} path="/company/kycPendingList" component={withTracker(kycPendingList)} />
                <ProtectedRoute user={user} accessControl={kycAccessControl} path="/company/:id/joiningDetails" component={withTracker(CompanyJoiningDetail)} />
                <ProtectedRoute user={user} accessControl={kycAccessControl} path="/company/:id/kycDetails" component={withTracker(CompanyKYCDetail)} />
                <ProtectedRoute user={user} accessControl={kycAccessControl} path="/log" component={withTracker(AdminAuditLog)} />
                <ProtectedRoute user={user} accessControl={rolebaseAccessControl} path="/adminManagement" component={withTracker(AdminManagement)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/shippingQuoteOverview/" component={withTracker(ShippingQuoteOverview)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/ShippingQuoteDetails/:id/:shipmentQuoteID" component={withTracker(ShippingQuoteDetails)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/provisionalShippingList/" component={withTracker(ProvisionalShippingList)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/finalShippingList/" component={withTracker(FinalShippingList)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/inspectionQuoteOverview/" component={withTracker(InspectionQuoteOverview)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/finalInspectionList/" component={withTracker(FinalInspectionList)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/provisionalInspectionList/" component={withTracker(ProvisionalInspectionList)} />
                <ProtectedRoute user={user} accessControl={defaultAccessControl} path="/inspectionQuoteDetails/:id/:inspectionQuoteID" component={withTracker(InspectionQuoteDetails)} />
            </Switch>
        </ConnectedRouter>
    )
};
