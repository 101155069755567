import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FormRadioLabelVerticalTrueFalse from '../../../../components/form/FormRadioLabelVerticalTrueFalse';
import { PostInspectionQuote } from '../../../../../modules/module.inspectionQuote';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from '../../../../components/preloader/Preloader';
import FormInputField from '../../../../components/form/FormInputField';
import { MEASUREMENTS } from '../../../../../services/service.values';

class InspectionStepTwoModel extends React.Component {
    constructor(props) {
        super(props);
        const isEmpty = value => !value || value === undefined || value === '';
        let inspectionQuoteNext = {};
        const { initialData, stepTwoData } = props;
        this.initField(inspectionQuoteNext, 'preShipmentInfo', 'PSI (pre-shipment inspection)', stepTwoData ? stepTwoData.preShipmentInfo.value : true, false);
        this.initField(inspectionQuoteNext, 'preShipmentLocation', 'Location of PSI', `${(stepTwoData && stepTwoData.preShipmentLocation.value) || ''}`, '');
        this.initField(inspectionQuoteNext, 'draftSurvey', 'Draft Survey', stepTwoData ? stepTwoData.draftSurvey.value : true, false);
        this.initField(inspectionQuoteNext, 'tally', 'Tally', stepTwoData ? stepTwoData.tally.value : true, false);
        this.initField(inspectionQuoteNext, 'sampling', 'Sampling', stepTwoData ? stepTwoData.sampling.value : true, false);
        this.initField(inspectionQuoteNext, 'markingsCheck', 'Markings Check', stepTwoData ? stepTwoData.markingsCheck.value : true, false);
        this.initField(inspectionQuoteNext, 'sealing', `${initialData.shipping === 'VESSEL' ? 'Sealing of Holds' : 'Sealing of containers'}`, stepTwoData ? stepTwoData.sealing.value : true, false);
        this.initField(inspectionQuoteNext, 'hoseTestRequired', 'Hose Test Required', stepTwoData ? stepTwoData.hoseTestRequired.value : true, false);
        this.initField(inspectionQuoteNext, 'holdsInspectionReport', 'Holds Inspection Report', stepTwoData ? stepTwoData.holdsInspectionReport.value : true, false);
        this.initField(inspectionQuoteNext, 'fumigation', 'Fumigation', stepTwoData ? stepTwoData.fumigation.value : true, false);
        this.initField(inspectionQuoteNext, 'location', `${initialData.shipping === 'VESSEL' ? 'Location of Loading' : 'Location of Stuffing container'}`, `${(stepTwoData && stepTwoData.location.value) || ''}`, isEmpty);
        this.initField(inspectionQuoteNext, 'sampleDistribution', 'Sample Distribution', `${(stepTwoData && stepTwoData.sampleDistribution.value) || ''}`, isEmpty);
        this.initField(inspectionQuoteNext, 'sampleRetention', 'Number of samples to be retained', `${(stepTwoData && stepTwoData.sampleRetention.value) || ''}`, isEmpty);
        this.initField(inspectionQuoteNext, 'requiredAnalysis', 'Required Analysis', `${(stepTwoData && stepTwoData.requiredAnalysis.value) || ''}`, '');
        this.initField(inspectionQuoteNext, 'others', 'Special Request/Special Documents', `${(stepTwoData && stepTwoData.others.value) || ''}`, '');
        this.initField(inspectionQuoteNext, 'desiccants', 'Desiccants (Calcium Chloride base only : for voyage upto 60 days min 4KG, over 60 days min 6KG)', `${(stepTwoData && stepTwoData.desiccants.value) || ''}`, isEmpty);
        this.initField(inspectionQuoteNext, 'preparationKraftPaper', 'Preparation: Kraft Paper', 'On all sides and on top of cargo', isEmpty);

        this.state = ({
            vesselData: {
                id: "",
                name: "",
            },
            inspectionQuoteNext: inspectionQuoteNext,
            required: null,
            serverError: '',
            companyError: '',
        });

        this.FORM_GROUP_WRAPPER_CLASS = "col-md-4";
        this.FORM_CREATE_WRAPPER_CLASS = "col-md-6";
        this.CUSTOM_COMPONENT_STYLE = {
            wrapperClassName: "form-group",
            labelClassName: "label",
            inputClassName: "form-control",
            selectClassName: "custom-select",
            radioColLayout: { left: "col-sm-6 col-md-6 col-lg-6 col-xl-4", right: "col-auto" }
        }
    }

    initField(state, name, label, value, required) {
        state[name] = {
            value: value,
            required: required,
            label: label
        };
        return state;
    }

    setInspectionFieldNext(name, value) {
        if (name !== '') {
            switch (name) {
                case 'tally':
                case 'sealing':
                case 'markingsCheck':
                case 'holdsInspectionReport':
                case 'hoseTestRequired':
                case 'sampling':
                case 'draftSurvey':
                case 'fumigation':
                case 'preShipmentInfo': {
                    let inspectionQuoteNext = this.state.inspectionQuoteNext;
                    inspectionQuoteNext[name] = {
                        ...inspectionQuoteNext[name],
                        value: (value === 'true')
                    };
                    this.setState({
                        inspectionQuoteNext: inspectionQuoteNext,
                        required: null
                    });
                    break;
                }
                case '':
                    break;
                default: {
                    let inspectionQuoteNext = this.state.inspectionQuoteNext;
                    inspectionQuoteNext[name] = {
                        ...inspectionQuoteNext[name],
                        value: value
                    };
                    this.setState({
                        inspectionQuoteNext: inspectionQuoteNext,
                        required: null
                    });
                    break;
                }
            }
        }
    }

    inspectionValidate = errorMessage => {
        this.setState({
            serverError: errorMessage
        });
    }

    updatedState() {
        return this.state.inspectionQuoteNext;
    }

    validate(container) {
        let required = {};
        Object.keys(container).map(key => {
            if (this.props.initialData.shipping === "VESSEL" && (key !== "desiccants" && key !== "preparationKraftPaper")) {
                let v = container[key];
                if (v && v.required && v.required(v.value)) {
                    required[key] = v;
                    if (key === "inspection") {
                        this.inspectionValidate("Please select an item in the list.");
                    }
                }
                return false;
            }
            if (this.props.initialData.shipping === "CONTAINER") {
                let v = container[key];
                if (v && v.required && v.required(v.value)) {
                    required[key] = v;
                }
                return false;
            }
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    onSubmitInspection = (e) => {
        e.preventDefault();
        if (this.validate(this.state.inspectionQuoteNext)) {
            var CompanyName = "";
            var data = this.props.inspections;
            var toCompanyId = String(this.props.stepOneData.inspection.value);
            Object.keys(data).forEach(function eachKey(key) {
                if (toCompanyId === key) {
                    CompanyName = data[key];
                }
            });
            var inspectionInfo = this.getStateValue(this.state.inspectionQuoteNext);
            var destCountry = (this.props.initialData.incoterm === "CIF" || this.props.initialData.incoterm === "CFR") ? this.props.initialData.destCountry : this.props.stepOneData.destinationCountry.value;
            var destPort = (this.props.initialData.incoterm === "CIF" || this.props.initialData.incoterm === "CFR") ? this.props.initialData.destPort : this.props.stepOneData.destinationPort.value;
            const quoteData = {
                packaging: this.props.initialData.packaging,
                tradeRefID: this.props.initialData.tradeRefId,
                tradeType: this.props.initialData.buyerId && this.props.initialData.sellerId ? "Private" : "Public",
                incoterm: this.props.initialData.incoterm,
                toCompanyId: this.props.stepOneData.inspection.value ? String(this.props.stepOneData.inspection.value) : null,
                toCompanyName: CompanyName,
                shippingType: this.props.initialData.shipping,
                status: "NEW",
                rejectReason: this.props.stepOneData.vesselData.remark,
                inspectionQuoteType: "PROVISIONAL",
                originPort: this.props.initialData.origin.includes(',') ? this.props.stepOneData.originCountry.value : this.props.initialData.origin,
                destinationPort: destPort,
                originCountry: this.props.initialData.origin.includes(',') ? this.props.stepOneData.originCountry.value : this.props.initialData.origin,
                destinationCountry: destCountry,
                tonnage: this.props.initialData.measure + MEASUREMENTS[this.props.initialData.measurement],
                deliveryDateTo: this.props.stepOneData.deliveryStartDate.value.format('YYYY-MM-DD'),
                deliveryDateFrom: this.props.stepOneData.deliveryEndDate.value.format('YYYY-MM-DD'),
                vatNumber: "",
                quoteRequestDate: "",
                inspectionInfo,
            }
            this.setState({ inProgress: true });
            this.props.PostInspectionQuote(this.props.initialData.id, quoteData, this.props.account.user.companyId, response => {
                if (response.inspectionQuoteId !== undefined) {
                    this.setState({ inProgress: false, isThankyouHeader: true, isCreateProvsional: true });
                    this.props.onSubmitSuccess('Inspection');
                }
            });
        }
    }

    getStateValue(container) {
        let result = {};
        Object.keys(container).map(key => {
            if (this.props.initialData.shipping === "VESSEL") {
                switch (key) {
                    case 'desiccants':
                    case 'preparationKraftPaper':
                        result[key] = "";
                        break;
                    default:
                        result[key] = container[key].value;
                        break;
                }
            }
            else {
                if (key) {
                    result[key] = container[key].value;

                }
            }
            return false;
        });
        return result;
    }

    onCancelHandler = () => {
        this.props.onCancel({ ...this.state.inspectionQuoteNext })
    }

    render() {
        return (
            <div className="modal__container">
                <form className="modal__wrapper px-0 py-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-header-center">
                                <h5 className="modal-title">Request a Provisional Quote for Inspection via {this.props.initialData.shipping ? "Vessel" : "Container"} </h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body provisional-Quote">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className=" form-group">
                                        <FormInputField
                                            name={'location'}
                                            validation={this.state.required}
                                            value={this.state.inspectionQuoteNext.location}
                                            onChange={e => this.setInspectionFieldNext("location", e.target.value)}
                                            placeholder="Location "
                                            type="text"
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <FormInputField
                                            validation={this.state.required}
                                            value={this.state.inspectionQuoteNext.preShipmentLocation}
                                            onChange={e => this.setInspectionFieldNext("preShipmentLocation", e.target.value)}
                                            placeholder="Location of PSI"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="form-group">
                                        <FormInputField
                                            name={'sampleRetention'}
                                            validation={this.state.required}
                                            value={this.state.inspectionQuoteNext.sampleRetention}
                                            onChange={e => this.setInspectionFieldNext("sampleRetention", e.target.value)}
                                            placeholder="Sample Retention"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <FormInputField
                                            name={'sampleDistribution'}
                                            validation={this.state.required}
                                            value={this.state.inspectionQuoteNext.sampleDistribution}
                                            onChange={e => this.setInspectionFieldNext(e.target.name, e.target.value)}
                                            placeholder="Sample distribution"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                            {this.props.initialData.shipping !== 'VESSEL' &&
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <FormInputField
                                                name={'desiccants'}
                                                validation={this.state.required}
                                                value={this.state.inspectionQuoteNext.desiccants}
                                                onChange={e => this.setInspectionFieldNext("desiccants", e.target.value)}
                                                placeholder="Desiccants"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mt-3">
                                            <FormInputField
                                                name={'preparationKraftPaper'}
                                                validation={this.state.required}
                                                value={this.state.inspectionQuoteNext.preparationKraftPaper}
                                                onChange={e => this.setInspectionFieldNext("preparationKraftPaper", e.target.value)}
                                                placeholder="preparation KraftPaper"
                                                type="text"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="form-group">
                                        <FormInputField
                                            name={'requiredAnalysis'}
                                            validation={this.state.required}
                                            value={this.state.inspectionQuoteNext.requiredAnalysis}
                                            onChange={e => this.setInspectionFieldNext("requiredAnalysis", e.target.value)}
                                            placeholder="Required Analysis"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <FormInputField
                                            name={'others'}
                                            validation={this.state.required}
                                            value={this.state.inspectionQuoteNext.others}
                                            onChange={e => this.setInspectionFieldNext("others", e.target.value)}
                                            placeholder="Special Request/Special Documents"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="radio__label">
                                        <FormRadioLabelVerticalTrueFalse
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"preShipmentInfo"}
                                            value={this.state.inspectionQuoteNext.preShipmentInfo}
                                            onChange={e => this.setInspectionFieldNext("preShipmentInfo", e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="radio__label">
                                        <FormRadioLabelVerticalTrueFalse
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"markingsCheck"}
                                            value={this.state.inspectionQuoteNext.markingsCheck}
                                            onChange={e => this.setInspectionFieldNext("markingsCheck", e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {this.props.initialData.shipping === 'VESSEL' &&
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="radio__label">
                                            <FormRadioLabelVerticalTrueFalse
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={"draftSurvey"}
                                                value={this.state.inspectionQuoteNext.draftSurvey}
                                                onChange={e => this.setInspectionFieldNext("draftSurvey", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="radio__label">
                                            <FormRadioLabelVerticalTrueFalse
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={"tally"}
                                                value={this.state.inspectionQuoteNext.tally}
                                                onChange={e => this.setInspectionFieldNext("tally", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>}
                            {this.props.initialData.shipping === 'VESSEL' &&
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="radio__label">
                                            <FormRadioLabelVerticalTrueFalse
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={"hoseTestRequired"}
                                                value={this.state.inspectionQuoteNext.hoseTestRequired}
                                                onChange={e => this.setInspectionFieldNext("hoseTestRequired", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="radio__label">
                                            <FormRadioLabelVerticalTrueFalse
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={"holdsInspectionReport"}
                                                value={this.state.inspectionQuoteNext.holdsInspectionReport}
                                                onChange={e => this.setInspectionFieldNext("holdsInspectionReport", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="radio__label">
                                        <FormRadioLabelVerticalTrueFalse
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"sampling"}
                                            value={this.state.inspectionQuoteNext.sampling}
                                            onChange={e => this.setInspectionFieldNext("sampling", e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="radio__label">
                                        <FormRadioLabelVerticalTrueFalse
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"sealing"}
                                            value={this.state.inspectionQuoteNext.sealing}
                                            onChange={e => this.setInspectionFieldNext("sealing", e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="radio__label">
                                    <FormRadioLabelVerticalTrueFalse
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name={"fumigation"}
                                        value={this.state.inspectionQuoteNext.fumigation}
                                        onChange={e => this.setInspectionFieldNext("fumigation", e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={this.onCancelHandler} className="btn-popup btn-cancel" href="#">
                            <FontAwesomeIcon icon={faTimes} /> <span>Back</span>
                        </button>
                        <button type="submit" onClick={(e) => this.onSubmitInspection(e)} disabled={this.state.inProgress} className="btn-popup btn-submit">
                            <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                <FontAwesomeIcon icon={faCheck} /> <span>Confirm</span>
                            </Preloader>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        inspectionQuote: state.inspectionQuote,
        account: state.account
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            PostInspectionQuote,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(InspectionStepTwoModel);