export const AppErrors = {
    registration_email_exist : "The email address you have entered is already registered.",
    login_user_not_exist: "Login user not exist.",
    login_incorrect: "Sorry, we couldn't find an account with that Email and password.",
    login_invalid_email: "Login invalid email.",
    registration_company_name_exist: "This registration number/email is already registered in the System. Please contact Customer Support to get more details.",
    login_account_not_active: "Login account not active.",
    login_account_not_verified: "Your account is pending for activation. Please check your inbox for the correct activation link.",
    forgot_password: "Sorry, we couldn't find an account with that Email.",
    internal_server_error: "Sorry, there was some error while saving user data.",
    admin_email_exist : "The email address you have entered for company admin is already registered.",
    kyc_submit_error: "Sorry, there was some error while saving kyc data.",
    kyc_save_draft_error: "Sorry, there was some error while saving kyc draft data."
};
