import React, { Component } from 'react';

import {
    FormRow,
    VirtualDropdown
} from '../../Form';

import { ADDRESSPROOF_TYPES } from '../../../../services/service.values';
import FormInputField from '../../form/FormInputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import FormFileUpload from '../../form/FormFileUpload';

const addressProofOptions = Object.keys(ADDRESSPROOF_TYPES).map(key => ({
    value: key,
    label: ADDRESSPROOF_TYPES[key]
}));

export class DirectorDetails extends Component {

    render() {

        let { state, setField, validation, add, remove, fileUploadValidate} = this.props;
        return (
            <React.Fragment>
                {state.directors &&
                    state.directors.map((k, i) => {
                        return (
                            <React.Fragment key={i}>
                                <FormRow>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            validation={validation[i]}
                                            name="name"
                                            value={k.name}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "directors", i)
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            validation={validation[i]}
                                            name="title"
                                            value={k.title}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "directors", i)
                                            }}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-12 col-md-6 mb-3">
                                        <VirtualDropdown
                                            validation={validation[i]}
                                            name="addressProofType"
                                            label="Select Proof of Address"
                                            items={addressProofOptions}
                                            onChange={val => {
                                                setField('addressProofType', val.value, "directors", i);
                                            }}
                                            value={{ value: k.addressProofType.value, label: ADDRESSPROOF_TYPES[k.addressProofType.value] }}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                    <FormFileUpload
                                        value={k.addressProof} 
                                        name="addressProof"
                                        validation={validation[i]}
                                        onChange={val => setField("addressProof", val, "directors", i)}
                                        onError={fileUploadValidate}
                                    />
                                  </div>
                                </FormRow>
                                <FormRow>
                                  <div className="col-12 col-md-6 mb-3">
                                    <FormFileUpload
                                        value={k.passport} 
                                        name="passport"
                                        validation={validation[i]}
                                        onChange={val => setField("passport", val, "directors", i)}
                                        onError={fileUploadValidate}

                                    />
                                  </div>
                                </FormRow>

                                <div className="text-right">
                                    {i !== 0 ? (
                                        <button type="button" className="btn btn-section-removed" onClick={e => remove(i)}>
                                            <FontAwesomeIcon icon={faTimes} /> Remove
                                        </button>
                                    ) : ''}
                                    {i === state.directors.length - 1 ? (
                                        <button type="button" className="btn btn-add" onClick={e => add()}>
                                            <FontAwesomeIcon icon={faPlus} /> Add
                                        </button>
                                    ) : ''}

                                </div>
                            </React.Fragment>
                        );
                    })}
            </React.Fragment>
        );
    }
}
