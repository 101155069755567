import React from 'react';

const DirectorDetails = ({ company }) => (
    <div>
        <h4 className="mt-1">Details of Senior Manager(s)</h4>
        <div className="table-responsive mb-4">
            <table className="table table-bordered table-hover table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Position</th>
                        <th scope="col">Proof of Address</th>
                        <th scope="col">Passport</th>
                    </tr>
                </thead>
                <tbody>
                    {company.directors &&
                        company.directors.map((k, i) => {
                            return (
                                <tr key={i}>
                                    <td>{k.name}</td>
                                    <td>{k.title}</td>
                                    <td>{k.addressProof && k.addressProof.fileUrl !== "#" ? <a href={k.addressProof.fileUrl}>View {k.addressProofType ?? ''}</a> : ''}</td>
                                    <td>{k.passport && k.passport.fileUrl !== "#" ? <a href={k.passport.fileUrl} >View Passport</a> : ""}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    </div>
);

export default DirectorDetails;