import React from 'react';
import FormInputField from '../../components/form/FormInputField';
import FormPhoneField from '../../components/form/FormPhoneField';
import Preloader from '../../components/preloader/Preloader';

export const RegisterUser = ({ state, setField, validation, onNext, isRegistering }) =>
    <div className="container">
        <form onSubmit={(e) => onNext(e)}>
            <div className="row">
                <div className="col-12 col-md-6 mb-3">
                    <FormInputField
                        name="firstName"
                        autoComplete="new-firstName"
                        maxLength="50"
                        validation={validation}
                        value={state.firstName}
                        onChange={(e) => setField(e.target.name, e.target.value)}
                    />
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <FormInputField
                        name="lastName"
                        autoComplete="new-lastName"
                        maxLength="50"
                        validation={validation}
                        value={state.lastName}
                        onChange={(e) => setField(e.target.name, e.target.value)}
                    />
                </div>

            </div>

            <div className="row">
                <div className="col-12 col-md-6 mb-3">
                    <FormPhoneField
                        placeholder="Enter phone number"
                        value={state.phone}
                        name="phone"
                        autoComplete="new-phone"
                        validation={validation}
                        onChange={value => setField('phone', value)}
                    />
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <FormInputField
                        maxLength="140"
                        name="companyRole"
                        autoComplete="new-companyRole"
                        validation={validation}
                        value={state.companyRole}
                        onChange={(e) => setField(e.target.name, e.target.value)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6 mb-3">
                    <FormInputField
                        type="password"
                        autoComplete="new-password"
                        name="password"
                        maxLength="50"
                        validation={validation}
                        value={state.password}
                        onChange={(e) => setField(e.target.name, e.target.value)}
                    />
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <FormInputField
                        type="password"
                        name="confirmPassword"
                        autoComplete="new-confirmPassword"
                        maxLength="50"
                        validation={validation}
                        value={state.confirmPassword}
                        onChange={(e) => setField(e.target.name, e.target.value)}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 justify-content-center">
                    <span className="register__note">

                    Your password should be at least 8 characters long and must contain at least 1 upper and lowercase letter, numbers and symbols.
                    Symbols can only include !,@,#,$,%,&,*
                    </span>
                </div>
                <div className="col-12">
                    <span className="register-required">*Required fields</span>
                    <button
                        disabled={isRegistering}
                        onClick={(e) => onNext(e)}
                        className="btn btn--blue regSubmitUser d-block">
                        <Preloader loadingStyle="dots" loading={isRegistering}>
                            <span>Submit</span>
                        </Preloader>
                    </button>
                </div>
            </div>
        </form>
    </div>