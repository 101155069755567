import {TradeApi} from '../services/service.api'; 
import { TRADE_STATUS } from '../services/service.values';
import _ from 'lodash'; 
import { getSynthesis } from '../app/admin/trades/components/shipments/shipmentUtil';

export const FETCH_SHIPMENT_PENDING = 'shipment/FETCH_SHIPMENT_PENDING';
export const FETCH_SHIPMENT_SUCCESS = 'shipment/FETCH_SHIPMENT_SUCCESS';
export const FETCH_SHIPMENT_ERROR = 'shipment/FETCH_SHIPMENT_ERROR';
export const CREATE_SHIPMENT_PENDING = 'shipment/FETCH_SHIPMENT_PENDING';
export const CREATE_SHIPMENT_SUCCESS = 'shipment/CREATE_SHIPMENT_SUCCESS';
export const CREATE_SHIPMENT_ERROR = 'shipment/CREATE_SHIPMENT_ERROR';
export const SYNTHESIS_SUCCESS = 'shipment/SYNTHESIS_SUCCESS';
export const SYNTHESIS_ERROR = 'shipment/SYNTHESIS_ERROR';

const initialState = {
    map: {},
    synthesis: null,
    fetchShipmentPending: false,
    fetchShipmentError: false,
    createShipmentPending: false,
    createShipmentError: false,
}

export default (state = {...initialState}, action = {}) => {
    switch(action.type){
        case FETCH_SHIPMENT_PENDING:
            return {...state, fetchShipmentPending: true, fetchShipmentError: false}
        case FETCH_SHIPMENT_SUCCESS:
            state.map = {...state.map, ...action.payload}
            return {...state, fetchShipmentPending: false, fetchShipmentError: false}
        case FETCH_SHIPMENT_ERROR:
            return {...state, fetchShipmentPending: false, fetchShipmentError: action.error}

        case CREATE_SHIPMENT_PENDING:
            return {...state, createShipmentPending: true, createShipmentError: false}
        case CREATE_SHIPMENT_SUCCESS:
            state.shipments = {...state.shipments, ...action.payload}
            return {...state, createShipmentPending: false, createShipmentError: false}
        case CREATE_SHIPMENT_ERROR:
            return { ...state, createShipmentPending: false, createShipmentError: action.error }
        case SYNTHESIS_SUCCESS:
            return { ...state, synthesis: action.payload }
        case SYNTHESIS_ERROR:
            return { ...state, synthesis: undefined, synthesisError: action.error }
        default:
            return state;
    }
};

export const getShipmentSynthesis = () => {
    return (dispatch, getState) => {
        let trade = getState().trade;
        const { shipmentMap, vesselNominationMap } = trade;
        const { single, tradeMap } = trade.items
        let parentTrade;
        if (single.parentTradeRequestID) {
            parentTrade = tradeMap[single.parentTradeRequestID]
        } else {
            parentTrade = single
        }
        try {
            const synthesis = getSynthesis(parentTrade, tradeMap, vesselNominationMap, shipmentMap);
            dispatch({ type: SYNTHESIS_SUCCESS, payload: synthesis })
        } catch (e) {
            //console.error(e);
            dispatch({ type: SYNTHESIS_ERROR, error: e })
        }
    }
}

export const fetchChildVesselShipments = (childIDArray) => {
    return (dispatch, getState) => {
        dispatch({type: FETCH_SHIPMENT_PENDING});
        const tradeMap = getState().trade.items.tradeMap;
            const filteredChildID = childIDArray.filter(childId => {
            const trade = tradeMap[childId];
            return (!trade || ![TRADE_STATUS.PROFORMA_INVOICE, TRADE_STATUS.VESSEL_NOMINATED].includes(trade.status))
        })
        const requests = filteredChildID.map(childId => TradeApi.smart(childId).getVesselNomination())
        return TradeApi.getShipments(tradeId)
        .then(res => {
            const shipmentMap = { [tradeId] : res.data.shipments};
            dispatch({type: FETCH_SHIPMENT_SUCCESS, payload: shipmentMap});
        })
        .catch(e => {
            dispatch({type: FETCH_SHIPMENT_ERROR, payload: e});
        })
    };
};

export const fetchShipment = (tradeId) => {
    return dispatch => {
        dispatch({type: FETCH_SHIPMENT_PENDING});
        return TradeApi.getShipments(tradeId)
        .then(res => {
            const shipmentMap = { [tradeId] : res.data.shipments};
            dispatch({type: FETCH_SHIPMENT_SUCCESS, payload: shipmentMap});
        })
        .catch(e => {
            dispatch({type: FETCH_SHIPMENT_ERROR, payload: e});
        })
    };
};

/* export const createShipment = (shipment) => {
    return dispatch => {
        dispatch({type: CREATE_SHIPMENT_PENDING});
        ShipmentApi.createShipment(shipment)
        .then(res => {
            const newShipment = res.data;
            dispatch({type: CREATE_SHIPMENT_SUCCESS, payload: newShipment});
        })
        .catch(e => {
            dispatch({type: CREATE_SHIPMENT_ERROR, payload: e});
        })
    };
}; */