import React from 'react'

export default function CustomMaterialPreloader(props) {
    return  <div style={{display: 'table', width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)'}}>
        <div style={{display: 'table-cell', width: '100%', height: '100%', verticalAlign: 'middle', textAlign: 'center'}}>
            <div className="preloader--swirl text-center">
                <i className="k-spinner--swirl" />
            </div>
        </div>
    </div>
}

