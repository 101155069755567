import React from 'react';
import moment from 'moment';

import { DATEFORMAT, quoteModalsReactSelectStyle } from '../../../../../services/service.values';
import {TradeQuotesError} from '../../../../../services/service.errors';
import FormDateField from '../../../../components/form/FormDateField';
import FormSelectDropdown from '../../../../components/form/FormSelectDropdown';
import { EnumsService, getSelectedCountries, renderSelectedOrigins, renderPortLabel } from '../../../../../services/service.utils';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from '../../../../components/preloader/Preloader';
import FormAsyncSelectPort from '../../../../components/form/FormAsyncSelectPort';
import TradeQuantityFormatter from '../../../../components/Formatter/TradeQuantityFormatter';

const Countries = EnumsService.countries();

class InspectionStepOneModel extends React.Component {
    constructor(props) {
        super(props);

        let isEmpty = value => !value || value === undefined || value === '';
        let inspectionQuote = {};
        const {stepOneData, initialData} = props;
        const inspectionCompany = stepOneData ? stepOneData.inspection.value : '';
        this.initField(inspectionQuote, 'inspection', 'Inspection Company', inspectionCompany, isEmpty);
        const deliveryStartDate = moment(initialData.deliveryStartDate).startOf('day');
        this.initField(inspectionQuote, 'deliveryStartDate', 'Shipping / Delivery Period (from)', deliveryStartDate, isEmpty);
        const deliveryEndDate = (initialData.deliveryEndDate) ? moment(initialData.deliveryEndDate) : undefined;
        this.initField(inspectionQuote, 'deliveryEndDate', 'Shipping / Delivery Period (to)', deliveryEndDate, isEmpty);
        this.initField(inspectionQuote, 'destinationCountry', 'Destination Country', stepOneData ? stepOneData.destinationCountry.value : '', false);
        this.initField(inspectionQuote, 'destinationPort', 'Destination Port', stepOneData ? stepOneData.destinationPort.value : '', false);
        this.initField(inspectionQuote, 'originCountry', 'Origin Country', stepOneData ? stepOneData.originCountry.value : '', value => initialData.origin.includes(',') && isEmpty(value));

        this.state = ({
            vesselData: {
                id: "",
                name: '',
            },
            inspectionQuote: inspectionQuote,
            required: null,
            serverError: '',
            companyError: '',
        });

        this.FORM_GROUP_WRAPPER_CLASS = "col-md-4";
        this.FORM_CREATE_WRAPPER_CLASS = "col-md-6";
        this.CUSTOM_COMPONENT_STYLE = {
            wrapperClassName: "form-group",
            labelClassName: "label",
            inputClassName: "form-control",
            selectClassName: "custom-select",
            radioColLayout: { left: "col-sm-6 col-md-6 col-lg-6 col-xl-4", right: "col-auto" }
        }
    }

    initField(state, name, label, value, required) {
        state[name] = {
            value: value,
            required: required,
            label: label
        };
        return state;
    }

    setInspectionField(name, value) {
        if (name !== '') {
            let inspectionQuote = this.state.inspectionQuote;
            let field = inspectionQuote[name];
            field.value = value;
            this.setState({
                inspectionQuote: inspectionQuote, required: null, serverError: '', countryError: '', companyError: '',
                portError: ''
            });
        }
    }

    inspectionValidate = errorMessage => {
        this.setState({
            serverError: errorMessage
        });
    }

    companyValidate = errorMessage => {
        this.setState({
            companyError: errorMessage
        });
    }


    validateFinalOffer() {
        let container = this.state.inspectionQuote;
        let required = {};
        Object.keys(container).forEach(key => {
            if (key === "inspection") {
                let v = container[key];
                if (v && v.required && v.required(v.value)) {
                    required[key] = v;
                    this.inspectionValidate("Please select an item.");

                }
                if (v.value) {
                    let selectedInspectionCompany = this.props.inspectionCompanies.find(company => company.ID === v.value);
                    if (selectedInspectionCompany && selectedInspectionCompany.serviceProviderFlag === false) {
                        required[key] = v;
                        this.setState({companyError: TradeQuotesError.INSPECTION_COMPANY_NOT_APPROVED});
                        this.props.sendEmail();
                    }
                }
                return false;
            }
            if (key === "originCountry") {
                let v = container[key];
                if (v && v.required && v.required(v.value)) {
                    required[key] = v;
                    required[key].validated = true;
                }
            }
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    handleChangeInput = e => {
        this.setState({ vesselData: { ...this.state.vesselData, [e.target.name]: e.target.value } });
    };

    onSubmitHandler = (event) => {
        event.preventDefault();
        if(this.validateFinalOffer()){
            this.props.onSubmit({ ...this.state.inspectionQuote, vesselData: this.state.vesselData })
        }
    }

    render() {
        return (
            <div className="modal__container" >
                <form
                    className="modal__wrapper px-0 py-0"
                    onSubmit={this.onSubmitHandler}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-header-center">
                                <h5 className="modal-title">Request a Provisional Quote for Inspection via {this.props.initialData.shipping === 'VESSEL' ? "Vessel" : "Container"}</h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onCancel} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body provisional-Quote">
                            <p> Please provide some background information on your request and click 'Confirm' to continue or click 'Back' to return previous screen.
                                Note that the information listed next to the text box is the information that is automatically added to your request.</p>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <div className="trades-header-wrapper">
                                        <div className="trades-header-label">
                                            Buyer
                                        </div>
                                        <div className="trades-header-value">
                                            {this.props.initialData.buyer}
                                        </div>
                                    </div>
                                    <div className="trades-header-wrapper">
                                        <div className="trades-header-label">
                                            Seller
                                        </div>
                                        <div className="trades-header-value">
                                            {this.props.initialData.seller}
                                        </div>
                                    </div>
                                    <div className="trades-header-wrapper">
                                        <div className="trades-header-label">
                                            Quantity (MT)
                                        </div>
                                        <div className="trades-header-value">
                                            <span>
                                                <TradeQuantityFormatter trade={this.props.initialData} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="trades-header-wrapper">
                                        <div className="trades-header-label">
                                            Shipment
                                        </div>
                                        <div className="trades-header-value">
                                            {this.props.initialData.shipping}
                                        </div>
                                    </div>
                                    <div className="trades-header-wrapper">
                                        <div className="trades-header-label">
                                            Packaging
                                        </div>
                                        <div className="trades-header-value">
                                            {renderPortLabel(this.props.initialData.packaging)}
                                        </div>
                                    </div>
                                    <div className="trades-header-wrapper">
                                        <div className="trades-header-label">
                                            Incoterm
                                        </div>
                                        <div className="trades-header-value">
                                            {this.props.initialData.incoterm}
                                        </div>
                                    </div>
                                    <div className="trades-header-wrapper">
                                        <div className="trades-header-label">
                                            Origin Country {this.props.initialData.origin.includes(',') ? <span className="req-field">&nbsp;*</span> : null}
                                        </div>
                                        {
                                            !this.props.initialData.origin.includes(',') ?
                                                <div className="trades-header-value">
                                                    {renderSelectedOrigins(this.props.initialData.origin)}
                                                </div>
                                                :
                                                <div className="trades-header-value">
                                                    <FormSelectDropdown
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        validation={this.state.required}
                                                        className="select"
                                                        name="originCountry"
                                                        placeholder="Origin Country"
                                                        items={getSelectedCountries(this.props.initialData.origin.split(','))}
                                                        onSelect={(event) => {
                                                            this.setInspectionField("originCountry", event.target.value);
                                                        }}
                                                        value={this.state.inspectionQuote.originCountry} />
                                                </div>
                                        }
                                    </div>
                                    {(this.props.initialData.incoterm === "CIF" || this.props.initialData.incoterm === "CFR") &&
                                        <div className="trades-header-wrapper">
                                            <div className="trades-header-label">
                                                Country of Destination
                                       </div>
                                            <div className="trades-header-value">
                                                {renderSelectedOrigins(this.props.initialData.destCountry)}
                                            </div>
                                        </div>}
                                    {this.props.initialData.incoterm === "FOB" &&
                                        <div className="trades-header-wrapper">
                                            <div className="trades-header-label">
                                                Country of Load
                                            </div>
                                            <div className="trades-header-value">
                                                <FormSelectDropdown
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    validation={this.state.required}
                                                    className="select"
                                                    name="destinationCountry"
                                                    items={Countries}
                                                    onSelect={e => {
                                                        this.setInspectionField("destinationCountry", e.target.value);
                                                        this.setInspectionField("destinationPort", "");
                                                    }}
                                                    value={this.state.inspectionQuote.destinationCountry}
                                                    placeholder="Country"
                                                />
                                            </div>
                                        </div>}
                                    {(this.props.initialData.incoterm === "CIF" || this.props.initialData.incoterm === "CFR") &&
                                        <div className="trades-header-wrapper">
                                            <div className="trades-header-label">
                                                Port of Destination
                                            </div>
                                            <div className="trades-header-value">
                                                {renderPortLabel(this.props.initialData.destPort)}
                                            </div>
                                        </div>
                                    }
                                    {this.props.initialData.incoterm === "FOB" &&
                                        <div className="trades-header-wrapper">
                                            <div className="trades-header-label">
                                                Port of Load
                                            </div>
                                            <div className="trades-header-value">
                                                <FormAsyncSelectPort 
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    fieldData={this.state.inspectionQuote.destinationPort}
                                                    onChange={(name, value, fromDropdown) => {
                                                        this.setInspectionField("destinationPort", value);
                                                        if(fromDropdown){
                                                            this.setInspectionField("destinationCountry", value.slice(0, 2));
                                                        }
                                                    }}
                                                    country={this.state.inspectionQuote.destinationCountry.value}
                                                    menuPlacement="top"
                                                    styles={quoteModalsReactSelectStyle}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {this.props.inspections &&
                                                <FormSelectDropdown
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    name="inspection"
                                                    items={this.props.inspections}
                                                    validation={this.state.required}
                                                    value={this.state.inspectionQuote.inspection}
                                                    onSelect={e => this.setInspectionField('inspection', e.target.value)}
                                                    placeholder={this.state.inspectionQuote.inspection.value}
                                                    showDefault={true}
                                                    className="select"
                                                />
                                            }
                                            {this.state.serverError &&
                                                <div className="counterparty__error">{this.state.serverError}</div>}
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <FormDateField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                dateFormat={DATEFORMAT}
                                                required={true}
                                                validation={this.state.required}
                                                name="deliveryStartDate"
                                                minDate={moment()}
                                                item={this.state.inspectionQuote.deliveryStartDate}
                                                time={false}
                                                label={true}
                                                showTimeSelect={false}
                                                onSelect={(name, date) => this.setInspectionField(name, date)}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <FormDateField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                dateFormat={DATEFORMAT}
                                                required={true}
                                                validation={this.state.required}
                                                name="deliveryEndDate"
                                                minDate={moment()}
                                                item={this.state.inspectionQuote.deliveryEndDate}
                                                time={false}
                                                label={true}
                                                showTimeSelect={false}
                                                onSelect={(name, date) => this.setInspectionField(name, date)}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea name="remark" onChange={this.handleChangeInput} placeholder="Add your message.." className="form-control text-color" rows="5">{this.state.vesselData.remark || (this.props.stepOneData && this.props.stepOneData.vesselData.remark)}</textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.companyError &&
                                <div className="popupCompany__error">{this.state.companyError}</div>}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={this.props.onCancel} className="btn-popup btn-cancel" >
                            <FontAwesomeIcon icon={faTimes} /> <span>Back</span></button>
                        <button type="submit" className="btn-popup btn-submit">
                            <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                <FontAwesomeIcon icon={faCheck} /> <span>Next</span>
                            </Preloader>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default InspectionStepOneModel;