import React from 'react';

import ConfirmModal from './ConfirmModal';

const ConfirmModalTenderApprove = function ({ subscription, ...rest }) {
    return <ConfirmModal loading={subscription}
        title="Approve Subscription to Tender"
        modalWrapperClass="w-auto"
        {...rest}>
        <p className="pb-2">{`You are about to approve ${subscription.subscribedByUser.companyName} to tender.`}</p>
        <p className="pb-2">{`Click 'confirm' to continue or 'back' to return to the previous screen`}</p>
        {subscription.approveError && <p className="pb-2 text-danger">{`An error occured : ${subscription.approveError}`}</p>}
    </ConfirmModal>
}

export default ConfirmModalTenderApprove; 