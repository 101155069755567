import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import FormInputGroupField from '../../../components/form/FormInputGroupField';
import FormInputNumberFormat from '../../../components/form/FormInputNumberFormat';
import FormTextareaField from '../../../components/form/FormTextareaField';
import FormInputField from '../../../components/form/FormInputField';
import FormSelectDropdown from '../../../components/form/FormSelectDropdown';
import FormDateField from '../../../components/form/FormDateField';
import FormRadioField from '../../../components/form/FormRadioField';
import FormSelectDropdownList from '../../../components/form/FormSelectDropdownList';
import FormUnitsInputField from '../../../components/form/FormUnitsInputField';
import FormAsyncSelectPort from '../../../components/form/FormAsyncSelectPort';
import moment from 'moment-timezone';
import ReactGA from 'react-ga';
import Preloader from '../../../components/preloader/Preloader';
import DocumentSelectionModal from './documentSelection/DocumentSelectionModal';
import InspectionNotAvailableModal from '../../../components/InspectionNotAvailableModal';
import AuthorisationCodeModal from '../../../components/AuthorisationCodeModal';
import Select from 'react-select'
import ReviewTradeFormModel from './ReviewTradeFormModel';
import { addHours } from 'date-fns';

import UploadImage from './UploadImage';
import UploadTenderConditions from './UploadTenderConditions';
import ConfirmTradeCreation from '../../trades/modals/ConfirmTradeCreation';
import AlertModal from '../../trades/modals/AlertModal.js';
//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faChevronRight,
    faChevronLeft,
    faTimes,
    faExclamationCircle,
    faFile,
    faClipboard
} from "@fortawesome/free-solid-svg-icons";

//STYLES
import 'react-datepicker/dist/react-datepicker.css';

//ACTIONS
import {
    searchCompanies,
    preloadInspectionCompanies,
    sendEmail,
    CreateTemplate,
    UpdateTemplate
} from '../../../../modules/module.trade';
import { EnumsService, getLocalTimezone } from '../../../../services/service.utils';
import { bypassAuthCodePermission } from '../../../../services/service.permission';

//values
import {
    DATEFORMATHOURS,
    DATEFORMAT,
    QUALITYSTANDARD,
    RICECATEGORY,
    PAYMENTTERMSOPT,
    PAYMENTPERIODOPT,
    DOWNPAYMENTPERIODOPT,
    INCOTERMOPT,
    DISCHARGEFOB,
    DISCHARGECIF,
    REQUESTTYPES,
    SHIPPINGTYPES,
    PACKAGING,
    DESTINATION_ZONE,
    INSURANCECOVER_TYPES,
    CURRENCYOPT,
    CROP_YEARS,
    getNonIntegratedInspector,
    requestPrivacyType,
    OFFER_TYPES,
    TOLERANCE
} from '../../../../services/service.values';
import { RiceService } from '../../../../services/service.rice';
import { DocumentFactory } from '../../trades/services/documents.service';
import FormRadioFieldTrueFalse from '../../../components/form/FormRadioFieldTrueFalse';
import TradeTemplateModal from './tradeTemplates/TradeTemplateModal';
import ValidateLoadCountryAndOrigin from '../../trades/modals/ValidateLoadCountryAndOrigin';
import MessageNonIntegratedInspector from '../../trades/modals/MessageNonIntegratedInspector';
import { filterInspectionCompany } from '../../../../services/service.utils';

const Countries = EnumsService.countries();

const BackButton = withRouter(connect(state => { return ({ list: state.history }) })(({ history, children, ...rest }) => {
    const [pending, setPending] = useState(false);
    const handleOnClick = e => {
        setPending(true);
        history.goBack();
        setTimeout(() => setPending(false), 500)
    }
    return <button type="button" onClick={handleOnClick} className="btn btn-action btn--grey mt-xs-1 lh-1">
        <Preloader loadingStyle="dots" loading={pending}>
            {children}
        </Preloader>
    </button>
}));

class TradeForm extends React.Component {

    FORM_GROUP_WRAPPER_CLASS = "col-md-4";
    FORM_CREATE_WRAPPER_CLASS = "col-md-6";
    CUSTOM_COMPONENT_STYLE = {
        wrapperClassName: "form-group",
        labelClassName: "label",
        inputClassName: "form-control",
        selectClassName: "custom-select",
        isInvalidClassName: "is-invalid select_error",
        isValidClassName: 'is-valid',
        radioColLayout: { left: "col-sm-6 col-md-6 col-lg-6 col-xl-4", right: "col-auto" }
    }

    constructor(props) {
        super(props)
        this.nextStep = this.nextStep.bind(this);
        this.previousStep = this.previousStep.bind(this);
        this.activateStep = this.activateStep.bind(this);
        this.validate = this.validate.bind(this);
        this.isInvalidPrice = this.isInvalidPrice.bind(this);
        this.validateInsurance = this.validateInsurance.bind(this);
        this.initDischargeRate = this.initDischargeRate.bind(this);
        this.state = {
            showModal: false,
            saveAsTemplate: false,
            required: {},
            modalName: '',
            request: {},
            value: '',
            selectedDay: '',
            disabled: false,
            countries: {},
            showInsuranceModal: false,
            initiated: false,
            validated: false,
            isNew: true,
            originCaption: '',
            riceTypeCaption: '',
            steps: {
                "step1": {
                    active: true,
                    next: "step2",
                    prev: null
                },
                "step2": {
                    active: false,
                    next: "step3",
                    prev: "step1"
                },
                "step3": {
                    active: false,
                    next: "documentSelection",
                    prev: "step2"
                },
                "documentSelection": {
                    active: false,
                    next: "step4",
                    prev: "step3"
                },
                "step4": {
                    active: false,
                    next: "step5",
                    prev: "step3"
                },
                "step5": {
                    active: false,
                    next: null,
                    prev: "step4"
                }

            }
            
        };

    }

    get selectedTemplateDetails() {
        let template = this.props.trade.tradeTemplates.templates.find(item => item.templateId === this.props.selectedTemplate)
        // RDEV-1777 template API Fetches documents in field 'documents'
        if (template.documents) {
            template.optionalDocuments = template.documents
        }
        return template;
    }

    componentDidMount() {
        this.props.searchCompanies('');
        this.props.preloadInspectionCompanies();
        this.initializeForm({});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedTemplate !== this.props.selectedTemplate && this.props.templateSelected) {
            if (this.props.selectedTemplate) {
                this.initializeForm(this.selectedTemplateDetails);
                // let selectedCounterparty = this.props.trade.companies.companies.find(company => company.ID === this.selectedTemplateDetails.counterParties) || '';
                let selectedCounterparty = this.props.trade.companies.companies.filter(company => this.selectedTemplateDetails.counterParties.includes(company.ID)) || [];
                this.setState(previousState => ({
                    request: {
                        ...previousState.request,
                        counterparty: {
                            ...previousState.request.counterparty,
                            value: selectedCounterparty
                        }
                    }
                }))
            } else {
                this.initializeForm({});
            }

        }

        // RDEV-1856 Initialize form
        if (prevProps.initialForm !== this.props.initialForm) {
            this.initializeForm(this.props.initialForm);
        }

        if (this.props.trade.items.single
            && !prevState.initialized
            && this.props.type === "UPDATE") {
            this.initializeForm(this.props.trade.items.single);
            const request = this.props.trade.items.single;
            let counterparty = "";
            if (request.requestType === 'BUY') {
                counterparty = request.seller || ""
            } else {
                counterparty = request.buyer || ""
            }

            this.setState({
                initialized: true,
                counterparty: counterparty,
                value: counterparty
            });
        }
    }



    loadCounter() {
        this.props.onReview(this.state.request)
    }

    isEmpty = value => !value || value === undefined || value === '';

    isQuantityEmpty = value => !value || value === undefined || value === '0' || value === '';

    isInvalidPrice = price => this.state.request.privacyType.value !== "TENDER" && this.state.request.requestType.value === 'SELL' && (price.length === 0 || price[0] === undefined || price[0] === '')


    initializeForm(trade = {}) {
        const { options } = this.props;
        /* STEP 1 */
        const requestType = trade.requestType || "";
        this.initField('requestType', 'Request Type', requestType, this.isEmpty, 'step1');
        const privacyType = trade.privacyType || "";
        this.initField('privacyType', 'Privacy', privacyType, this.isEmpty, 'step1');
        const dutchAuction = trade.hasOwnProperty('dutchAuction') ? trade.dutchAuction : "";
        this.initField('dutchAuction', 'Dutch Auction', dutchAuction, value => (this.state.request.privacyType.value === "TENDER" && value === ""), 'step1');
        const whitelistOnlyTender = trade.hasOwnProperty('whitelistOnlyTender') ? trade.whitelistOnlyTender : "";
        this.initField('whitelistOnlyTender', 'Select Tender Participants', whitelistOnlyTender, value => (this.state.request.privacyType.value === "TENDER" && value === ""), 'step1')
        const counterparty = (requestType === 'BUY') ? [trade.sellerId] || '' : [trade.buyerId] || '';
        this.initField('counterparty', 'Select Counterparty', counterparty, (value) => (this.state.request.privacyType.value === "PRIVATE" || this.state.request.privacyType.value === "MULTI_PRIVATE") && this.isEmpty(value), 'step1');
        const currency = trade.currency || "US$";
        this.initField('currency', '', currency, this.isEmpty, "step1");
        const price = trade.price ? trade.price.toString() : '';
        this.initField('price', 'Your Price per MT', price, (trade.privacyType === "TENDER" || trade.requestType === 'BUY') ? false : this.isInvalidPrice, 'step1');
        const bestPrice = trade.bestPrice || false;
        this.initField('bestPrice', 'Best Price', bestPrice, false, 'step1');
        const measurement = trade.measurement || 'TONS';
        this.initField('measurement', '', measurement, this.isEmpty, 'step1');
        const measure = trade.measure || "";
        this.initField('measure', 'Quantity (expressed in MT)', measure, this.isQuantityEmpty, 'step1');
        //const validateDate = (trade.validateDate && moment(trade.validateDate).isAfter(new Date())) ? moment(trade.validateDate) : undefined;
        this.initField('validateDate', `${trade.privacyType === "TENDER" ? `Tender Closing On (${getLocalTimezone()})` : `Valid Until (${getLocalTimezone()})`}`, undefined, value => {
            return this.isEmpty(value) || moment().diff(value) > 0 || (trade.tenderStartDate && trade.tenderStartDate.value && trade.tenderStartDate.value.diff(value, 'minutes') < 30)
        }, 'step1');


        //const tenderStartDate = (trade.tenderStartDate && moment(trade.tenderStartDate).isAfter(new Date())) ? moment(trade.tenderStartDate) : undefined;
        this.initField('tenderStartDate', `Tender Opening On (${getLocalTimezone()})`, undefined, value => {
            return (this.state.request.privacyType.value === "TENDER" && (this.isEmpty(value) || moment().diff(value) > 0))
        }, 'step1');

        const euroConversionAvl = trade.hasOwnProperty('euroConversionAvl') ? trade.euroConversionAvl : trade.hasOwnProperty('euroConversionFlag') ? trade.euroConversionFlag : false;
        this.initField('euroConversionAvl', 'Euro Conversion Available', euroConversionAvl, false, 'step1');
        const standardContract = trade.standardContract ? trade.standardContract : true;
        this.initField('standardContract', 'Standard Contract', standardContract, false, 'step1');
        const offerType = trade.offerType || '';
        const isInvalidOfferType = type => this.state.request.requestType.value === "SELL" && this.isEmpty(type);
        this.initField('offerType', 'Offer Type', offerType, isInvalidOfferType, 'step1');
        
        const tolerance = (trade.tolerance !== undefined) ? trade.tolerance : ''
        const tolerancePreset = (trade.tolerance !== undefined) ? TOLERANCE[tolerance] ? tolerance : 'OTHER' : '';
        this.initField('tolerancePreset', `Contractual Tolerance`, tolerancePreset, this.isEmpty, 'step1');
        this.setState(prev => ({
            request: {
                ...prev.request,
                tolerance: {
                    touched: false,
                    validated: false,
                    step: "step1",
                    name: 'tolerance',
                    label: `Other`,
                    value: tolerance,
                    required: value => {
                        return (this.state.request.tolerancePreset.value === "OTHER" && ["","0","5","10"].includes(value))
                    },
                    step: 'step1',
                    visible: tolerancePreset === 'OTHER'
                }
            }
        }));
            

        /* STEP 2 */
        const shipping = trade.shipping;
        this.initField('shipping', 'Shipping Type', shipping, this.isEmpty, 'step2');
        const origin = trade.origin ? trade.origin.split(',') : [];
        this.initField('origin', 'Origin', origin, (value) => value && value.length === 0, 'step2');
        const riceCategory = trade.riceCategory || "";
        this.initField('riceCategory', 'Category of Rice', riceCategory, this.isEmpty, 'step2');

        const riceType = trade.riceType || "";
        this.initField('riceType', 'Type of Rice', riceType, this.isEmpty, 'step2');
        const riceTypeOther = trade.riceTypeOther || "";
        this.initField('riceTypeOther', 'Specify Rice Type', riceTypeOther, (value) => { return this.state.request.riceType.value === RiceService.enums.REF_OTHER && this.isEmpty(value) }, 'step2');
        const quality = trade.quality || 'Standard';
        this.initField('quality', 'Quality Standard (Base Price)', quality, this.isEmpty, 'step2');
        const cropYear = trade.cropYear;
        this.initField('cropYear', 'Crop Year', cropYear, this.isEmpty, 'step2');
        const incoterm = trade.incoterm || '';
        this.initField('incoterm', 'Incoterms', incoterm, this.isEmpty, 'step2');
        const optionalIncoterm = ['CFR', 'CIF'].includes(trade.incoterm) ? trade.optionalIncoterm : '';
        this.initField('optionalIncoterm', 'Also accepting offers for incoterm', optionalIncoterm, false, 'step2');
        const initialLoadCountryPortValidation = trade.incoterm === 'FOB' ? origin.length > 1 ? false : this.isEmpty : false;
        const loadCountry = trade.loadCountry || "";
        this.initField('loadCountry', 'Country of Load', loadCountry, initialLoadCountryPortValidation, 'step2');
        const loadPort = trade.loadPort || "";
        this.initField('loadPort', 'Port of Load', loadPort, initialLoadCountryPortValidation, 'step2');
        const destCountry = trade.destCountry || "";
        this.initField('destCountry', 'Destination', destCountry, false, 'step2');
        const destPort = trade.destPort || "";
        this.initField('destPort', 'Port of Destination', destPort, false, 'step2');

        const insuranceRequired = trade.insuranceRequired || false;
        this.initField('insuranceRequired', 'Do You want a Ricex Dedicated Insurance Quote?', insuranceRequired, false, 'step2');
        const destinationZone = trade.destinationZone || "";
        const destinationZoneInvalid = (value) => { this.state.request.insuranceRequired.value && this.isEmpty(value) }
        this.initField('destinationZone', 'Select Destination Zone', destinationZone, destinationZoneInvalid, 'step2');
        const typeOfCover = trade.typeOfCover || "";
        const typeOfCoverInvalid = (value) => { this.state.request.insuranceRequired.value && this.isEmpty(value) }
        this.initField('typeOfCover', 'Select the Type of Cover', typeOfCover, typeOfCoverInvalid, 'step2');

        /* STEP 3 */
        //  const deliveryStartDate = (trade.deliveryStartDate) ? moment(trade.deliveryStartDate).startOf('day') : undefined;    
        this.initField('deliveryStartDate', 'Shipping / Delivery Period (from)', undefined, this.isEmpty, 'step3');
        //  const deliveryEndDate = (trade.deliveryEndDate) ? moment(trade.deliveryEndDate) : undefined;       
        this.initField('deliveryEndDate', 'Shipping / Delivery Period (to)', undefined, this.isEmpty, 'step3');
        const packaging = trade.packaging || ''
        if (PACKAGING.hasOwnProperty(packaging)) {
            this.initField('packaging', 'Packaging (Base Price)', packaging, this.isEmpty, 'step3');
            this.initField('packagingOther', 'Packaging', "", (value) => { return this.state.request.packaging.value === 'OTHER' && value === "" }, 'step3');
        } else {
            this.initField('packaging', 'Packaging (Base Price)', 'OTHER', this.isEmpty, 'step3');
            this.initField('packagingOther', 'Packaging', packaging, (value) => { return this.state.request.packaging.value === 'OTHER' && value === "" }, 'step3');
        }
        const inspection = trade.inspection || trade.inspectionId || '';
        this.initField('inspection', 'Inspection Company', inspection, this.isEmpty, 'step3');
        const inspectionOther = trade.inspectionOther || '';
        this.initField('inspectionOther', 'Select Other Inspector', inspectionOther, (value) => { return this.state.request.inspection.value === "1" && this.isEmpty(value) }, 'step3');

        const partialShipmentFlag =trade.hasOwnProperty('partialShipmentFlag') ? trade.partialShipmentFlag : false;
        this.initField('partialShipmentFlag', 'Partial Shipment Allow', partialShipmentFlag, false, 'step3');

        const discharge = trade.discharge || '';
        this.initField('discharge', 'Load & Discharge Terms', discharge, (value) => (this.state.request.shipping.value !== "CONTAINER" && this.isEmpty(value)), 'step3');
        const dischargeRate = trade.dischargeRate;
        this.initDischargeRate(discharge, dischargeRate);
        const optionalDocuments = DocumentFactory.initBEDocumentList(trade);
        this.initField("optionalDocuments", 'Optional Documents', optionalDocuments, false, 'step3');

        /* STEP 4*/
        const payment = trade.payment || "";
        this.initField('payment', 'Payment Terms', payment, this.isEmpty, 'step4');
        const paymentPeriod = trade.paymentPeriod || '';
        this.initField('paymentPeriod', 'Payment Period', paymentPeriod, this.isEmpty, 'step4');

        // RDEV-1813
        //const LCOpeningDate = trade.LCOpeningDate || undefined;
        this.initField('LCOpeningDate', 'Letter of Credit to be Opened Latest On', undefined, value => {
            return (this.state.request.payment.value === 'Letter of credit' && this.isEmpty(value))
        }, 'step4');

        //const ADIssuingDate = trade.ADIssuingDate || undefined;        
        this.initField('ADIssuingDate', 'Avalised Draft to be issued latest on', undefined, value => {
            return (this.state.request.payment.value === 'Avalised Draft' && this.isEmpty(value))
        }, 'step4');

        let nameOfBankInvalid = (value) => {
            return this.state.request.requestType.value === "BUY" &&
                this.state.request.payment.value !== "CAD" &&
                this.isEmpty(value)
        };
        const nameOfBank = trade.nameOfBank || '';
        this.initField('nameOfBank', "Name of Buyer's Bank", nameOfBank, nameOfBankInvalid, 'step4');
        const locationOfBank = trade.locationOfBank || "";
        this.initField('locationOfBank', "Location of Buyer's Bank", locationOfBank, nameOfBankInvalid, 'step4');
        const swiftCodeOfBank = trade.swiftCodeOfBank || "";
        this.initField('swiftCodeOfBank', "Swiftcode of Buyer's Bank", swiftCodeOfBank, nameOfBankInvalid, 'step4');

        let confirmingBankInvalid = (value) => {
            return this.state.request.payment.value === "Confirmed letter of credit"
                && this.state.request.requestType.value === "BUY"
                && this.isEmpty(value)
        };
        const nameOfConfirmingBank = trade.nameOfConfirmingBank || "";
        this.initField('nameOfConfirmingBank', 'Name of Confirming Bank', nameOfConfirmingBank, confirmingBankInvalid, 'step4');
        const locationOfConfirmingBank = trade.locationOfConfirmingBank || "";
        this.initField('locationOfConfirmingBank', 'Location of Confirming Bank', locationOfConfirmingBank, confirmingBankInvalid, 'step4');
        const swiftCodeOfConfirmingBank = trade.swiftCodeOfConfirmingBank || "";
        this.initField('swiftCodeOfConfirmingBank', 'Swiftcode of Bank', swiftCodeOfConfirmingBank, confirmingBankInvalid, 'step4');

        const downPaymentRequired = trade.downPaymentRequired ? trade.downPaymentRequired : trade.downPaymentPercentage ? true : false;
        this.initField('downPaymentRequired', 'Down Payment Required', downPaymentRequired, false, 'step4');
        const downPaymentPercentage = trade.downPaymentPercentage || '';
        let downPaymentInvalid = (value) => {
            return this.state.request.downPaymentRequired.value &&
                (this.isEmpty(value) || parseFloat(value) < 0 || parseFloat(value) > 100)
        };
        this.initField('downPaymentPercentage', 'Down Payment (%)', downPaymentPercentage, downPaymentInvalid, 'step4');

        const downPaymentPeriod = trade.downPaymentPeriod || '';
        let downPaymentPeriodInvalid = (value) => { return this.state.request.downPaymentRequired.value && this.isEmpty(value) }
        this.initField('downPaymentPeriod', 'Down Payment to be effected within', downPaymentPeriod, downPaymentPeriodInvalid, 'step4');



        /* STEP 5*/

        const specialRequest = trade.specialRequest || "";
        this.initField('specialRequest', 'Special Request', specialRequest, false, 'step5');

        const tenderTermsDoc = trade.tenderTermsDoc || [];
        this.initField('tenderTermsDoc', 'Upload Tender Terms & Conditions', tenderTermsDoc, (value) => { return this.state.request.privacyType.value === "TENDER" && value.length === 0 }, 'step5');

        const images = trade.images || [];
        this.initField('images', 'Images', images, (value) => (value.filter(item => item.temp).length !== 0), 'step5');

        // OPTIONS
        if (options) {
            const optionsPackagingType = this.props.bid && this.props.bid.optionsPackagingType ? this.props.bid.optionsPackagingType : this.props.trade.optionsPackagingType;
            const optionsPackagingTypePremOrDiscount = this.props.bid && this.props.bid.optionsPackagingTypePremOrDiscount ? this.props.bid.optionsPackagingTypePremOrDiscount : this.props.trade.optionsPackagingTypePremOrDiscount || 0;
            const optionsQualityType = this.props.bid && this.props.bid.optionsQualityType ? this.props.bid.optionsQualityType : this.props.trade.optionsQualityType;
            const optionsQualityTypePremOrDiscount = this.props.bid && this.props.bid.optionsQualityTypePremOrDiscount ? this.props.bid.optionsQualityTypePremOrDiscount : this.props.trade.optionsQualityTypePremOrDiscount || 0;
            const optionsRiceCategory = this.props.bid && this.props.bid.optionsRiceCategory ? this.props.bid.optionsRiceCategory : this.props.trade.optionsRiceCategory;
            const optionsTypeOfRice = this.props.bid && this.props.bid.optionsTypeOfRice ? this.props.bid.optionsTypeOfRice : this.props.trade.optionsTypeOfRice;
            const optionsTypeOfRicePremOrDiscount = this.props.bid && this.props.bid.optionsTypeOfRicePremOrDiscount ? this.props.bid.optionsTypeOfRicePremOrDiscount : this.props.trade.optionsTypeOfRicePremOrDiscount || 0;
            const optionsShippingType = this.props.bid && this.props.bid.optionsShippingType ? this.props.bid.optionsShippingType : this.props.trade.optionsShippingType;
            const optionsShippingTypePremOrDiscount = this.props.bid && this.props.bid.optionsShippingTypePremOrDiscount ? this.props.bid.optionsShippingTypePremOrDiscount : this.props.trade.optionsShippingTypePremOrDiscount || 0;

            this.initField('optionsPackagingType', 'Packaging (Option)', optionsPackagingType, false);
            this.initField('optionsPackagingTypePremOrDiscount', 'Premium / Discount', optionsPackagingTypePremOrDiscount, false);
            this.initField('optionsQualityType', 'Quality Standard ( Option )', optionsQualityType, false);
            this.initField('optionsQualityTypePremOrDiscount', 'Premium / Discount', optionsQualityTypePremOrDiscount, false);
            this.initField('optionsRiceCategory', 'Category of Rice (option)', optionsRiceCategory, false);
            this.initField('optionsTypeOfRice', 'Type of Rice (option)', optionsTypeOfRice, false);
            this.initField('optionsTypeOfRicePremOrDiscount', 'Premium / Option', optionsTypeOfRicePremOrDiscount, false);
            this.initField('optionsShippingType', 'Shipping Type (Option)', optionsShippingType, false);
            this.initField('optionsShippingTypePremOrDiscount', 'Premium / Option', optionsShippingTypePremOrDiscount, false);
        }
        this.activateStep(null, "step1");
        this.setState({ initiated: true, required: {} });
    }

    initField(name, label, value, required, step) {
        this.setState(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                [name]: {
                    touched: false,
                    validated: false,
                    step: step,
                    name: name,
                    label: label,
                    required: required,
                    value: value,
                    disabled: false
                }
            }
        }));
    }

    validateField(name, value) {
        if (this.state.request[name]) {
            this.setState(prevState => {
                let request = prevState.request;
                let required = { ...prevState.required }
                request[name].validated = true;

                if (request[name].required && request[name].required(value)) {
                    required[name] = prevState.request[name];
                } else {
                    delete required[name];
                }
                return { request, required }
            });
        }
    }

    setField = (name, value, options = { validate: true, touch: true }) => {
        //RDEV-1855 Notifiy parent that form hasChanged
        this.props.onChange()

        //Trigger field validation
        if (options.validate) {
            this.validateField(name, value);
        }
        //Trigger field was touched
        if (options.touch) {
            if (this.state.request[name]) {
                this.setState(prevState => {
                    prevState.request[name].touched = true;
                    return { ...prevState }
                })
            }
        }
        var request = {};
        switch (name) {
            case 'validateDate':
            case 'tenderStartDate':
            case 'deliveryStartDate':
            case 'deliveryEndDate':
                if (typeof value === 'object' && value !== null) {
                    request = this.state.request;
                    request[name] = {
                        ...request[name],
                        value: value,
                    };

                    this.setState({
                        request: request
                    });
                }
                break;
            case 'bestPrice':
                this.setState(prevState => {
                    const updatedRequest = {...prevState.request}
                    updatedRequest.bestPrice.value = value;
                    if(value){
                        updatedRequest.price.value = '';
                    }
                    return{
                        ...prevState,
                        request: updatedRequest
                    }
                })
                break;
            case 'requestType':
                this.setState(prevState => {
                    let priceRequired = false
                    if (value === "SELL") {
                        if (prevState.request.privacyType.value === "TENDER") {
                            prevState.request.privacyType.value = "";
                            prevState.request.tenderStartDate.value = undefined;
                            prevState.request.whitelistOnlyTender.value = "";
                            prevState.request.dutchAuction.value = "";
                            prevState.request.validateDate.label = `Valid Until (${getLocalTimezone()})`;
                            this.validateField('privacyType', "");
                        } else {
                            priceRequired = this.isInvalidPrice;
                            prevState.request.optionalIncoterm.value = '';
                        }
                    }
                    prevState.request.bestPrice.value = false;
                    prevState.request.price.required = priceRequired;
                    prevState.request.requestType.value = value;
                    prevState.request.offerType.value = '';
                    return { ...prevState }
                });
                break;
            case 'origin':
                this.setState(prevState => {
                    let updatedRequest = { ...prevState.request };
                    if (updatedRequest.incoterm.value === 'FOB') {
                        if (value.length > 1) {
                            updatedRequest.loadCountry.value = '';
                            updatedRequest.loadPort.value = '';
                            updatedRequest.loadCountry.required = false;
                            updatedRequest.loadPort.required = false;
                        } else if (value.length === 1 && (!updatedRequest.loadCountry.value || !updatedRequest.loadPort.value)) {
                            updatedRequest.loadCountry.value = value[0];
                            updatedRequest.loadPort.value = '';
                            updatedRequest.loadCountry.required = this.isEmpty;
                            updatedRequest.loadPort.required = this.isEmpty;
                        } else {
                            updatedRequest.loadCountry.required = this.isEmpty;
                            updatedRequest.loadPort.required = this.isEmpty;
                        }
                    }
                    updatedRequest.origin.value = value;
                    return { request: updatedRequest }
                });
                break;
            case 'incoterm':
                this.setState(prevState => {
                    request = { ...prevState.request };
                    if (request.shipping.value === 'VESSEL') {
                        switch (request.incoterm.value) {
                            case 'CIF':
                            case 'CFR':
                                request.discharge.label = 'Discharge Terms'
                                break;
                            case 'FOB':
                                request.discharge.label = 'Load Terms'
                                break;
                            default: break;
                        }
                        request.discharge.required = this.isEmpty;
                    } else {
                        switch (request.incoterm.value) {
                            case 'CIF':
                            case 'CFR':
                            case 'FOB':
                                request.discharge.label = 'Load / Discharge Terms'
                                break;
                            default: break;
                        }
                        request.discharge.required = false;
                    }

                    return { request: request }
                });

                request = { ...this.state.request };
                request.incoterm.value = value;
                request.optionalIncoterm.value = '';
                if (value === INCOTERMOPT.FOB) {
                    request.destCountry.value = undefined;
                    request.destCountry.required = false;
                    request.destPort.value = undefined;
                    request.destPort.required = false;
                    if (this.state.request.origin.value.length === 1) {
                        request.loadCountry.required = this.isEmpty;
                        request.loadPort.required = this.isEmpty;
                    } else {
                        request.loadCountry.required = false;
                        request.loadPort.required = false;
                    }
                    request.deliveryStartDate.label = 'Delivery Period from';
                    request.deliveryEndDate.label = 'Delivery Period to';
                    if (this.state.request.origin.value.length === 1 && !request.destCountry.value) {
                        request.loadCountry.value = this.state.request.origin.value[0];
                    }
                } else if (value === INCOTERMOPT.CIF || value === INCOTERMOPT.CFR) {
                    request.loadCountry.value = '';
                    request.loadCountry.required = false;
                    request.loadPort.value = '';
                    request.loadPort.required = false;
                    request.destCountry.required = this.isEmpty;
                    request.destPort.required = this.isEmpty;
                    request.deliveryStartDate.label = 'Shipping period from';
                    request.deliveryEndDate.label = 'Shipping period to';
                }
                if (request.shipping.value === 'CONTAINER') {
                    if (request.requestType.value === 'SELL') {
                        if (value === INCOTERMOPT.CIF) {
                            this.setState(prevState => {
                                prevState.request.insuranceRequired.value = true;
                                prevState.showInsuranceModal = true;
                                return { ...prevState }
                            }, () => {
                                this.setField('destinationZone', "AFRICA");
                                this.setField('typeOfCover', "ALL_RISK");
                            });
                        }
                    } else {
                        if (value === INCOTERMOPT.CFR || value === INCOTERMOPT.FOB) {
                            this.setState(prevState => {
                                prevState.request.insuranceRequired.value = true;
                                prevState.showInsuranceModal = true;
                                return { ...prevState }
                            }, () => {
                                this.setField('destinationZone', "AFRICA");
                                this.setField('typeOfCover', "ALL_RISK");
                            });
                        }
                    }
                }
                this.setState({
                    request: request
                });
                break;

            case 'payment':

                request = this.state.request;
                // RDEV-1813 Refresh avalized draft opening date and Letter of Credit opening date in case payment terms changes
                request.ADIssuingDate.value = undefined;
                request.LCOpeningDate.value = undefined;

                if (value === 'CAD') {
                    this.setField('paymentPeriod', PAYMENTPERIODOPT['At sight'], { validate: true, touch: true });
                    request.nameOfBank = { ...request.nameOfBank, value: "" };
                    request.locationOfBank = { ...request.locationOfBank, value: "" };
                    request.swiftCodeOfBank = { ...request.swiftCodeOfBank, value: "" };
                }
                if (value === 'Confirmed letter of credit') {
                    request.nameOfBank = { ...request.nameOfBank, label: "Name of Opening Bank", value: "" };
                    request.locationOfBank = { ...request.locationOfBank, label: "Location of Opening Bank", value: "" };
                    request.swiftCodeOfBank = { ...request.swiftCodeOfBank, label: "Swiftcode of Opening Bank", value: "" };
                    request.nameOfConfirmingBank = { ...request.nameOfConfirmingBank, label: "Name of Confirming Bank", value: "" };
                    request.locationOfConfirmingBank = { ...request.locationOfConfirmingBank, label: "Location of Confirming Bank", value: "" };
                    request.swiftCodeOfConfirmingBank = { ...request.swiftCodeOfConfirmingBank, label: "Swiftcode of Confirming Bank", value: "" };
                }
                request[name] = {
                    ...request[name],
                    value: value
                };

                this.setState({
                    request: request
                });
                break;

            case 'privacyType':
                this.setState(prevState => {
                    let inputValue = prevState.value;
                    request = prevState.request;
                    /* RDEV-1694 Handle Price required / validation */
                    if (value === "TENDER" || request.requestType.value === "BUY") {
                        request.price.required = false;
                    } else {
                        request.tenderStartDate.value = undefined;
                        request.price.required = this.isInvalidPrice;
                        this.validateField('tenderStartDate', undefined);
                    }
                    /* RDEV-1694 Handle Labels on Date valid until in case of tender */
                    if (value === "TENDER") {
                        if (request.requestType.value !== "BUY") {
                            request.requestType.value = "BUY";
                        }
                        request.validateDate.label = `Tender Closing On (${getLocalTimezone()})`;
                        request.dutchAuction.value = "";
                        request.whitelistOnlyTender.value = "";
                        request.tenderTermsDoc.value = [];
                    } else {
                        request.validateDate.label = `Valid Until (${getLocalTimezone()})`;
                        request.dutchAuction.value = null;
                        request.whitelistOnlyTender.value = null;
                        request.tenderStartDate.value = undefined;
                        request.tenderTermsDoc.value = null;
                        request.optionalIncoterm.value = '';
                    }

                    if (value === "PUBLIC" || value === "TENDER") {
                        request.counterparty.value = 0;
                        inputValue = '';
                    }
                    request.privacyType.value = value;

                    return {
                        request: request,
                        value: inputValue,
                    }
                });
                break;
            case 'dutchAuction':
            case 'whitelistOnlyTender':
            case 'euroConversionAvl':
            case 'downPaymentRequired':
            case 'insuranceRequired':
            case 'standardContract':
            case 'partialShipmentFlag':    
                request = this.state.request;
                request[name] = {
                    ...request[name],
                    value: (value === "true"),
                    touched: true
                };
                this.setState({
                    request: request
                });
                break;
            case '':
                break;
            default:
                request = this.state.request;
                request[name] = {
                    ...request[name],
                    value: value,
                };
                this.setState({
                    request: request
                });
                break;
        }
    };

    activateStep(event, step) {
        if (event) {
            event.preventDefault();
        }
        if (step) {
            this.setState(prevState => {
                for (let key in prevState.steps) {
                    if (step === key) {
                        prevState.steps[key].active = true;
                    } else {
                        prevState.steps[key].active = false;
                    }
                }
                return { steps: prevState.steps }
            })
        }
    }

    nextStep() {
        for (let key in this.state.steps) {
            if (this.state.steps[key].active) {
                this.activateStep(null, this.state.steps[key].next);
                break;
            }
        }
    }

    previousStep() {
        for (let key in this.state.steps) {
            if (this.state.steps[key].active) {
                this.activateStep(null, this.state.steps[key].prev);
                break;
            }
        }
    }

    trackAction(action) {
        ReactGA.event({
            category: 'Trade',
            action
        })
    }

    closeModal = () => {
        this.setState({ showModal: false, modalName: '' })
    }
    renderContinueTradeSubmitModal=()=>{
        this.setState({
            showModal: true,
            saveAsTemplate: true,
            modalName: 'TEMPLATESAVED_CONTINUE_TO_TRADE_SUBMIT'
        })
    }
    onCancelTradeTemplateSave=()=>{
        this.setState({
            showModal:false,
            modalName:''
        },()=>{
            this.validateAndAuthorise();
        })
    }
    onSubmitTradeTemplateSave=()=>{
            this.setState({
                showModal: true,
                saveAsTemplate: true,
                modalName: 'TEMPLATE_SAVE_ON_TRADE_SUBMIT'
            })
    }
    closeContinueTradeSubmit=()=>{
        this.setState({
            showModal:false,
            modalName:''
        },()=>{
            this.validateAndAuthorise();
        })

    }
    renderModal = (whatModal) => {
        switch (whatModal) {
            case 'INSPECTION_NOT_APPROVED':
                return InspectionNotAvailableModal({
                    onClick: () => {
                        this.setState({
                            showModal: false,
                            modalName: ''
                        });
                        this.openAuthorizationCodeModal();
                        this.props.sendEmail(0);
                    }
                });
            case 'AUTHORISATION_CODE_MODAL':
                return (
                    <AuthorisationCodeModal closeModal={(isAuthorized) => {
                        this.setState({
                            showModal: false,
                            modalName: ''
                        });
                        if (isAuthorized) {
                            const request = this.getStateValue()
                            this.props.onSubmit(request);
                        }
                    }} />
                );
            case 'TRADE_TEMPLATE':
                if(this.props.selectedTemplate && !this.state.saveAsTemplate){
                    return(
                        <TradeTemplateModal 
                            cancelhandler={this.closeModal}
                            isUpdate={true}
                            templateRequest={this.getStateValue()}
                            templateId={this.props.selectedTemplate}
                            templateName={this.selectedTemplateDetails.templateName}
                            submitHandler={this.props.UpdateTemplate}
                            templateCount={this.props.trade.tradeTemplates.count}
                            loading={this.props.loadingTemplates['UPDATING']}
                            closeModal={this.closeModal}
                            templates={this.props.trade.tradeTemplates.templates} />
                    );
                } else {
                    return (
                        <TradeTemplateModal
                            cancelhandler={this.closeModal}
                            submitHandler={this.props.CreateTemplate}
                            templateRequest={this.getStateValue()}
                            templateCount={this.props.trade.tradeTemplates.count}
                            loading={this.props.loadingTemplates['CREATING']}
                            closeModal={() => {
                                this.props.selectInitialTemplate();
                                this.closeModal();
                            }}
                            templates={this.props.trade.tradeTemplates.templates} />
                    );
                }
            case 'CONFIRM_TRADE_CREATION':
                return (
                    <ConfirmTradeCreation
                        requestType={this.state.request.requestType.value}
                        onCancel={this.closeModal}
                        onSubmit={this.openAuthorizationCodeModal}
                    />
                )
            case 'VALIDATE_LOAD_COUNTRY':
                return (
                    <ValidateLoadCountryAndOrigin
                        requestType={this.state.request.requestType.value}
                        onCancel={this.closeModal}
                    />
                )
            case 'MESSAGE_NON_INTIGRATED_INSPECTOR':
                return (
                    <MessageNonIntegratedInspector
                        onCancel={this.closeModal}
                    />
                )
            case 'TEMPLATE_SAVE_CONFIRM_MODAL_ON_TRADE_SUBMIT':
                return (
                    <AlertModal 
                        modalContent={`Do you want to save the Trade Request as a Template?`}
                        submitButtonContent={'Yes'}
                        cancelButtonContent={'No'}
                        submitFontIcon={'faCheck'}
                        cancelFontIcon={'faTimes'}
                        onCancel={this.onCancelTradeTemplateSave}
                        onSubmit={this.onSubmitTradeTemplateSave}
                        styles={'authcode'}
                        />
                )
            case 'TEMPLATE_SAVE_ON_TRADE_SUBMIT':
                return(
                    <TradeTemplateModal
                            cancelhandler={this.closeModal}
                            submitHandler={this.props.CreateTemplate}
                            templateSavingOnSubmitCallBack={this.renderContinueTradeSubmitModal}
                            templateRequest={this.getStateValue(this.state.request)}
                            templateCount={this.props.trade.tradeTemplates.count}
                            loading={this.props.loadingTemplates['CREATING']}
                            closeModal={() => {
                                this.props.selectInitialTemplate();
                                this.closeModal();
                            }}
                            templates={this.props.trade.tradeTemplates.templates} />
                )
            case 'TEMPLATESAVED_CONTINUE_TO_TRADE_SUBMIT':
                return (<AlertModal 
                modalContent={`Template has been successfully saved.`}
                submitButtonContent={'Continue'}
                onSubmit={this.closeContinueTradeSubmit}
                isCancelButtonEnabled={false}
                styles={'authcode'}
                />)
            default:
                break;
        }

    }

    validateAndAuthoriseReview = (e) => {
        e.preventDefault();
        if (this.validate()) {
            this.setState({
                showDetails: true
            })
        }
    }


    validateAndAuthorise = () => {
        if (this.validate()) {
            this.setState({ showError: false });
            this.setState({
                disabled: true
            });

            let selectedInspectionCompany = this.props.trade.inspectionCompanies.find(company => company.ID === this.state.request.inspection.value);
            const rtbWithoutPrice = this.state.request.requestType.value === 'BUY' && this.state.request.price.value === '';
            const rtsWithSubjectConfirmation = this.state.request.requestType.value === 'SELL' && this.state.request.offerType.value === 'SUBJECT_CONFIRMATION';
            if (selectedInspectionCompany && selectedInspectionCompany.serviceProviderFlag === false && this.state.request.requestType.value === 'SELL') {
                this.setState({
                    showModal: true,
                    modalName: 'INSPECTION_NOT_APPROVED'
                });
            } else if (!(rtbWithoutPrice || rtsWithSubjectConfirmation)) {
                this.setState({
                    modalName: 'CONFIRM_TRADE_CREATION',
                    showModal: true
                });
            }
            else {
                this.openAuthorizationCodeModal();
            }
        } else {
            this.setState({ showError: true });
        }
    }

    openAuthorizationCodeModal = () => {
        if (bypassAuthCodePermission() === 1) {
        this.closeModal();
             this.props.onSubmit(this.getStateValue());
        } else {
            if (this.state.request.requestType.value === 'SELL' || (this.state.request.requestType.value === 'BUY' && this.state.request.price.value !== '')) {
                this.setState({
                    showModal: true,
                    modalName: 'AUTHORISATION_CODE_MODAL'
                });
            } else {
                this.props.onSubmit(this.getStateValue());
            }
        }
    }

    validateInsurance() {
        const destinationZone = this.state.request.destinationZone.value;
        const typeOfCover = this.state.request.typeOfCover.value;
        const insuranceRequired = this.state.request.insuranceRequired.value;
        if (!insuranceRequired || (destinationZone && typeOfCover)) {
            this.setState({
                insuranceValidated: true,
                showInsuranceModal: false,
                insuranceTouched: true
            });
        } else {
            this.setState({
                insuranceValidated: true,
                insuranceTouched: true
            });
        }

    }

    validate() {
        let container = this.state.request;
        let required = {};
        let steps = this.state.steps;
        Object.keys(steps).map(key => {
            delete steps[key].isValid
            return false;
        })

        Object.keys(container).map(key => {
            let field = container[key];
            if (field && field.required && field.required(field.value)) {
                try {
                    steps[field.step].isValid = false;
                } catch (error) {
                    console.error(error);
                }

                required[key] = field;
            }
            this.setState(prevState => {
                prevState.request[key].validated = true;
                return { ...prevState };
            })
            return false;
        });

        this.setState({ validated: true, steps: steps });

        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    getStateValue() {
        const container = this.state.request;
        let result = {};
        Object.keys(container).map(key => {
            switch (key) {
                case 'counterparty':
                    if (Array.isArray(container.counterparty.value)) {
                        if (typeof container.counterparty.value[0] !== 'undefined') {
                            result.counterparty = container.counterparty.value.map(item => item.ID) || [""];
                            result.counterpartyName = container.counterparty.value.map(item => item.name) || [""];
                            if (container.privacyType.value === "MULTI_PRIVATE") {
                                result.multiPrivateParties = container.counterparty.value.map(item => { return { id: item.ID, name: item.name } }) || [""];
                            }
                            else {
                                result.multiPrivateParties = [];
                            }
                        } else {
                            result.counterpartyName = [""];
                        }
                    }
                    else {
                        result.counterparty = [container.counterparty.value.ID] || [""];
                        result.counterpartyName = [container.counterparty.value.name] || [""];
                    }

                    break;
                case 'price':
                case 'measure':
                    result[key] = parseFloat(container[key].value);
                    break;

                case 'dutchAuction':
                case 'whitelistOnlyTender':
                    if (container.privacyType.value === "TENDER")
                        result[key] = (container[key].value !== "") ? container[key].value : null;
                    break;
                case 'inspection':
                    result[key] = container[key].value ? String(container[key].value) : null;
                    break;
                case 'validateDate':
                case 'tenderStartDate':
                case 'deliveryStartDate':
                case 'deliveryEndDate':
                case 'ADIssuingDate':
                case 'LCOpeningDate':
                    result[key] = container[key].value ? container[key].value.format() : null;
                    break;
                case 'downPayment':
                    result[key] = parseInt(container[key].value);
                    break
                case 'packaging':
                    if (container[key].value === "OTHER") {
                        result[key] = container['packagingOther'].value;
                    } else {
                        result[key] = container[key].value;
                    }
                    break;
                case 'packagingOther':
                    break;
                case 'origin':
                    result[key] = container['origin'].value.join(',');
                    break;
                case 'tenderTermsDoc':
                    if (container.privacyType.value === "TENDER")
                        result[key] = container[key].value;
                    break;
                case 'tolerancePreset':
                    break
                case 'tolerance':
                    result[key] = parseFloat(container[key].value);
                    break;  
                default:
                    result[key] = container[key].value;
                    break;
            }
            return false;
        });

        return result;
    }

    initDischargeRate = (dischargeValue, value) => {
        let DISCHARGE_RATE_LABEL = "Load & Discharge";
        let DISCHARGE_RATE_VALUE = (value) ? value : "";
        let isInValid = (isInValidValue) => (this.state.request.shipping.value !== "CONTAINER" && this.isEmpty(isInValidValue));
        switch (dischargeValue) {
            case 'CQD load':
                DISCHARGE_RATE_LABEL = "Demurrage rate /day in $";
                break;
            case 'loadrate':
                DISCHARGE_RATE_LABEL = "Specify load rate details";
                break;
            case 'CQD':
                DISCHARGE_RATE_LABEL = "Demurrage rate/day in $";
                break;
            case 'Discharge rate':
                DISCHARGE_RATE_LABEL = "Specify discharge rate details ";
                break;
            default: break;


        }
        this.initField('dischargeRate', DISCHARGE_RATE_LABEL, DISCHARGE_RATE_VALUE, isInValid, 'step3');
    }

    filterOpeningTimes = time => {
        const now = new Date()
        const opening = this.state.request.tenderStartDate.value;
        const closing = this.state.request.validateDate.value;
        if ((!opening || opening.isSame(now, 'day')) && moment(new Date(time)).diff(now, 'minutes') < 0) {
            return false
        }
        if (closing && (!opening || opening.isSame(closing, 'day'))) {
            if (closing.diff(new Date(time), 'minutes') <= 29) {
                return false
            }
        }
        return true
    }
    handleTradeSubmit = () => {
        if(this.validate()){
            this.setState({
                showModal: true,
                modalName: 'TEMPLATE_SAVE_CONFIRM_MODAL_ON_TRADE_SUBMIT'
            })
        }
    }

    filterClosingTimes = time => {
        const now = new Date()
        const opening = this.state.request.tenderStartDate.value;
        const closing = this.state.request.validateDate.value;

        if (!closing || closing.isSame(now, 'days')) {
            if (opening) {
                return moment(new Date(time)).diff(opening, 'minutes') > 0
            } else {
                return moment(new Date(time)).diff(now, 'minutes') > 30
            }
        }
        return true
    }
    
    render() {
        const { options, type } = this.props;
        const { steps } = this.state;
        if (!this.state.initiated) {
            return null;
        }

        const isRTB = this.state.request.requestType.value === "BUY";
        const optionalIncoterms = { ...INCOTERMOPT };
        if (this.state.request.incoterm.value) {
            delete optionalIncoterms[this.state.request.incoterm.value];
        }

        return (
            <React.Fragment>
                {this.state.showModal && this.renderModal(this.state.modalName)}
                {this.state.showDetails && 
                <ReviewTradeFormModel request={this.state.showDetails && this.getStateValue()}
                    onSubmit={(e)=>this.props.onSubmit(this.getStateValue())}
                    onClose={(e) => {this.setState({showDetails: false })}} 
                    show={this.state.showDetails} />}
                <form className='create-trade-form needs-validation' noValidate>
                    <div className="trade-steps-form">
                        <ul>
                            <li className={steps.step1.active ? 'active' : ''}>
                                <a className="d-inline-block" href="#step1" onClick={e => this.activateStep(e, 'step1')}>Trade Type</a>
                                <span className={`field-alert text-danger ${(!steps.step1.active && steps.step1.isValid === false) ? '' : 'd-none'}`}
                                ><FontAwesomeIcon icon={faExclamationCircle} /></span>

                            </li>
                            <li className={steps.step2.active ? 'active' : ''}>
                                <a className="d-inline-block" href="#step2" onClick={e => this.activateStep(e, 'step2')}>Shipping</a>
                                <span className={`field-alert text-danger ${(!steps.step2.active && steps.step2.isValid === false) ? '' : 'd-none'}`}
                                ><FontAwesomeIcon icon={faExclamationCircle} /></span>

                            </li>
                            <li className={steps.step3.active ? 'active' : ''}>
                                <a className="d-inline-block" href="#step3" onClick={e => this.activateStep(e, 'step3')}>Product Spec</a>
                                <span className={`field-alert text-danger ${(!steps.step3.active && steps.step3.isValid === false) ? '' : 'd-none'}`}
                                ><FontAwesomeIcon icon={faExclamationCircle} /></span>

                            </li>
                            <li className={steps.step4.active ? 'active' : ''}>
                                <a className="d-inline-block" href="#step4" onClick={e => this.activateStep(e, 'documentSelection')}>Finance</a>
                                <span className={`field-alert text-danger ${(!steps.step4.active && steps.step4.isValid === false) ? '' : 'd-none'}`}
                                ><FontAwesomeIcon icon={faExclamationCircle} /></span>
                            </li>
                            <li className={steps.step5.active ? 'active' : ''}>
                                <a className="d-inline-block" href="#step5" onClick={e => this.activateStep(e, 'step5')}>Misc</a>
                                <span className={`field-alert text-danger ${(!steps.step5.active && steps.step5.isValid === false) ? '' : 'd-none'}`}
                                ><FontAwesomeIcon icon={faExclamationCircle} /></span>
                            </li>
                        </ul>
                    </div>

                    {this.state.steps.step1.active &&
                        <div className={`create-trade-details-form form-step mt-1`}>
                            <div className={`row`}>
                                <div className="col-sm-12 notes">
                                    <p><em>To Create a request to buy without price just leave the "Your Price" input box empty. </em></p>
                                </div>
                                <div className={`${this.FORM_CREATE_WRAPPER_CLASS} ${this.state.validated ? 'was-validated' : ''}`}>
                                    <FormRadioField
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="requestType"
                                        items={REQUESTTYPES}
                                        required={true}
                                        onChange={e => {
                                            this.setField('requestType', e.target.value);
                                            this.setField('privacyType', "")
                                        }}
                                        value={this.state.request.requestType} />
                                </div>
                                {this.state.request.privacyType.value !== 'TENDER' &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormRadioFieldTrueFalse
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"euroConversionAvl"}
                                            value={this.state.request.euroConversionAvl}
                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                        />
                                    </div>}
                            </div>
                            <div className={`row pb-3`}>
                                {/* RDEV-1864 Re-activate tender request for acceptance and preview env*/}
                                <div className={`${this.FORM_CREATE_WRAPPER_CLASS} ${this.state.validated ? 'was-validated' : ''}`}>
                                    {/* <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="privacyType"
                                        items={requestPrivacyTypes}
                                        required={true}
                                        validation={this.state.required}
                                        onSelect={e => this.setField('privacyType', e.target.value)}
                                        value={this.state.request.privacyType} /> */}
                                    <FormSelectDropdownList
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        validation={this.state.required}
                                        name={"privacyType"}
                                        items={requestPrivacyType(this.state.request.requestType.value)}
                                        required={true}
                                        onSelect={e => {
                                            this.setField('privacyType', e.target.value);
                                            this.setField('counterparty', "")
                                            this.setField("price", "")
                                        }}
                                        value={this.state.request.privacyType}
                                        showDefault={true}
                                    />
                                </div>
                                {this.state.request.privacyType.value === 'TENDER' &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            validation={this.state.required}
                                            items={{
                                                'false': "All Companies", 'true': 'Tender Whitelist Group'
                                            }}
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"whitelistOnlyTender"}
                                            required={true}
                                            value={this.state.request.whitelistOnlyTender}
                                            onSelect={e => this.setField(e.target.name, e.target.value)}
                                        />
                                    </div>}
                                {(this.state.request.privacyType.value === 'PRIVATE' || this.state.request.privacyType.value === 'MULTI_PRIVATE') &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <label className={`${this.CUSTOM_COMPONENT_STYLE.labelClassName} `}>
                                            {this.state.request.counterparty.label}
                                            {this.state.request.counterparty.required && <span className="req-field">&nbsp;*</span>}
                                        </label>
                                        <Select
                                            styles={{
                                                control: (provided, state) => {
                                                    const border = this.state.required.hasOwnProperty('counterparty') ? '1px solid rgb(220, 53, 69)' :
                                                        this.state.request.counterparty.touched ? '1px solid rgb(40, 167, 69)' : '';
                                                    return { ...provided, border };
                                                }
                                            }}
                                            filterOption={
                                                (object, input) => {
                                                    const searchvalue = input.toLowerCase();
                                                    const name = object.data.name.toLowerCase();
                                                    const test = name.includes(searchvalue)
                                                    return test
                                                }
                                            }
                                            isMulti={this.state.request.privacyType.value === 'MULTI_PRIVATE'}
                                            onChange={value => this.setField('counterparty', value)}
                                            value={this.state.request.counterparty.value}
                                            options={this.props.trade.companies.companies}
                                            getOptionLabel={option => `${option.name} (${option.companyKYC.tax})`}
                                            getOptionValue={option => option.ID} />
                                        {this.state.required.hasOwnProperty('counterparty') &&
                                            <div>
                                                <p className="text-danger">Please select from the list of registered companies</p>
                                            </div>}
                                    </div>}
                            </div>
                            <div className={`row`}>
                                {(isRTB) && (
                                    <div className="col-md-2">
                                        <FormInputField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE} 
                                            type="checkbox"
                                            name="bestPrice"
                                            className="checkbox-css"
                                            value={this.state.request.bestPrice}
                                            required={false}
                                            checked={this.state.request.bestPrice.value}
                                            onChange={(event) => this.setField("bestPrice", event.target.checked)}
                                        />
                                    </div>
                                )}
                                <div className={isRTB ? this.FORM_GROUP_WRAPPER_CLASS : this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormUnitsInputField
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="price"
                                        dropName="currency"
                                        placeholder={this.state.request.privacyType.value === 'MULTI_PRIVATE' ? "Price to be left empty for optimal price discovery" : "Price"}
                                        items={CURRENCYOPT}
                                        validation={this.state.required}
                                        type="text"
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        allowNegative={false}
                                        isAllowed={(values) => {
                                            const { floatValue, value } = values;
                                            return value === "" || floatValue <= 1000000;
                                        }}
                                        onSelect={val => this.setField('currency', val)}
                                        onChange={val => this.setField("price", val)}
                                        value={this.state.request.price}
                                        dropValue={this.state.request.currency}
                                        disabled={this.state.request.privacyType.value === 'MULTI_PRIVATE'}
                                        inputDisabled={this.state.request.bestPrice.value}
                                    />
                                </div>
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormInputNumberFormat
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="measure"
                                        placeholder="Quantity"
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        allowNegative={false}
                                        type='text'
                                        isAllowed={(values) => {
                                            const { floatValue, value } = values;
                                            return value === "" || floatValue <= 100000;
                                        }}
                                        validation={this.state.required}
                                        onChange={val => this.setField('measure', val)}
                                        value={this.state.request.measure}
                                    />
                                </div>
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="tolerancePreset"
                                        items={TOLERANCE}
                                        validation={this.state.required}
                                        captionText="Tolerance is in buyer's option for FOB and in seller's option for CFR and CIF and  MIN/MAX will have effect of zero tolerance up or down"
                                        value={this.state.request.tolerancePreset}
                                        onSelect={e => {
                                            if (e.target.value === 'OTHER') {
                                                this.setState(prev => {
                                                    prev.request.tolerance.visible = true
                                                    return { ...prev }
                                                })
                                                this.setField('tolerance', "");
                                            } else {
                                                this.setState(prev => {
                                                    prev.request.tolerance.visible = false
                                                    return { ...prev }
                                                })
                                                this.setField('tolerance', e.target.value);
                                            }
                                            this.setField('tolerancePreset', e.target.value);
                                        }}
                                        placeholder={this.state.request.tolerance.value}
                                        required={false}
                                        showDefault={true}
                                    />
                                </div>

                                <div className={`${this.FORM_CREATE_WRAPPER_CLASS} ${this.state.request.tolerance.visible?"visible":"invisible"}`}>
                                    <FormInputGroupField
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name={'tolerance'}
                                        apValue={"%"}
                                        validation={this.state.required}
                                        value={this.state.request.tolerance}
                                        onChange={e => this.setField('tolerance', e.target.value)}
                                        placeholder="Tolerance value"
                                        options={{
                                            numeral: true,
                                            numeralIntegerScale: 2,
                                            numeralPositiveOnly: true,
                                            stripLeadingZeroes: true

                                        }} 
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-sm-3"}>
                                    {/** RDEV-1785 Date picker requires both min and max time to be filled with a date object */} 
                                    <FormDateField
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="tolerance"
                                        items={TOLERANCE}
                                        validation={this.state.required}
                                        captionText="Tolerance is in buyer's option for FOB and in seller's option for CFR and CIF and  MIN/MAX will have effect of zero tolerance up or down"
                                        value={this.state.request.tolerance}
                                        onSelect={e => {
                                            this.setField('tolerance', e.target.value);
                                            this.setField('toleranceOther', "");
                                        }}
                                        placeholder={this.state.request.tolerance.value}
                                        required={false}
                                        showDefault={true}
                                    />
                                </div>
                                {this.state.request.tolerance.value === 'OTHER' &&
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormInputGroupField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={'toleranceOther'}
                                            apValue={"%"}
                                            validation={this.state.required}
                                            value={this.state.request.toleranceOther}
                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                            placeholder="Tolerance value"
                                            options={{
                                                numeral: true,
                                                numeralIntegerScale: 3,
                                                numeralPositiveOnly: true,
                                                stripLeadingZeroes: true
                                            }} 
                                        />
                                    </div>}
                            </div>
                            <div className="row">
                                {this.state.request.privacyType.value === "TENDER" &&
                                    <div className={"col-sm-3"}>
                                        {/** RDEV-1785 Date picker requires both min and max time to be filled with a date object */}
                                        <FormDateField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            dateFormat={DATEFORMATHOURS}
                                            required={true}
                                            validation={this.state.required}
                                            name="tenderStartDate"
                                            minDate={addHours(new Date(), 1)}
                                            maxDate={this.state.request.validateDate.value}
                                            filterTime={this.filterOpeningTimes}
                                            time={true}
                                            item={this.state.request.tenderStartDate}
                                            label={true}
                                            onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)} />
                                    </div>}
                                <div className={(this.state.request.privacyType.value === "TENDER") ? "col-sm-3" : this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormDateField
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        dateFormat={DATEFORMATHOURS}
                                        required={true}
                                        validation={this.state.required}
                                        name="validateDate"
                                        time={true}
                                        minDate={(this.state.request.privacyType.value === "TENDER") ? moment(this.state.request.tenderStartDate.value) : moment().add(1, 'hours').minutes(0).seconds(0)}
                                        filterTime={this.filterClosingTimes}
                                        item={this.state.request.validateDate}
                                        label={true}
                                        onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                    />
                                </div>
                                {this.state.request.privacyType.value === 'TENDER' &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            validation={this.state.required}
                                            items={{ 'true': "Yes", 'false': 'No' }}
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"dutchAuction"}
                                            required={true}
                                            value={this.state.request.dutchAuction}
                                            onSelect={e => this.setField(e.target.name, e.target.value)}
                                        />
                                    </div>}
                                {this.state.request.requestType.value === "SELL" && (
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            validation={this.state.required}
                                            items={OFFER_TYPES}
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"offerType"}
                                            required={true}
                                            value={this.state.request.offerType}
                                            onSelect={e => this.setField(e.target.name, e.target.value)}
                                        />
                                    </div>
                                )}
                            </div>
                           
                        </div>}
                    {this.state.steps.step2.active &&
                        <div className={`create-trade-details-form form-step mt-1`}>
                            <div className="row">
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        validation={this.state.required}
                                        className="select"
                                        name="shipping"
                                        items={SHIPPINGTYPES}
                                        onSelect={e => {
                                            this.setField('shipping', e.target.value);
                                            this.setField('incoterm', "");
                                            this.setField('optionalIncoterm', "");
                                            this.setField('discharge', "");
                                            this.initDischargeRate(undefined, undefined);
                                            if (e.target.value === "VESSEL") {
                                                this.setField('insuranceRequired', false);
                                                this.setField('destinationZone', undefined);
                                                this.setField('typeOfCover', undefined);
                                            }

                                        }}
                                        value={this.state.request.shipping}
                                    />
                                </div>
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    {/* <FormSelectDropdownList
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="origin"
                                        items={RiceService.getAvailableCountries(this.state.request.riceType.value)}
                                        validation={this.state.required}
                                        value={this.state.request.origin}
                                        keyLabel={'label'}
                                        keyValue={'value'}
                                        onSelect={e => {
                                            this.setField('origin', e.target.value);
                                            if (e.target.value === "") {
                                                this.setField("riceType", "");
                                                this.setField("riceTypeOther", "");
                                            }
                                        }}
                                        placeholder={this.state.request.origin.value}
                                        captionText={this.state.request.riceType.value ? `*List of origins for the selected type` : ''}
                                        required={false}
                                        defaultLabel={"Select"}
                                        disableDefault={false}
                                        showDefault={this.state.request.origin.value ? true : false}
                                    /> */}
                                    <label className={`${this.CUSTOM_COMPONENT_STYLE.labelClassName} `}>
                                        {this.state.request.origin.label}
                                        {this.state.request.origin.required && <span className="req-field">&nbsp;*</span>}
                                    </label>
                                    <Select
                                        styles={{
                                            control: (provided) => {
                                                const border = this.state.required.hasOwnProperty('origin') ? '1px solid rgb(220, 53, 69)' :
                                                    this.state.request.origin.touched ? '1px solid rgb(40, 167, 69)' : '';
                                                return { ...provided, border };
                                            }
                                        }}
                                        options={RiceService.getAvailableCountries(this.state.request.riceType.value)}
                                        isMulti
                                        onChange={selectedOrigins => {
                                            this.setField('origin', selectedOrigins.map(origin => origin.value));
                                            if (selectedOrigins.length === 0) {
                                                this.setField("riceType", "");
                                                this.setField("riceTypeOther", "");

                                            }
                                            this.setField("inspection", "");
                                        }}
                                        value={RiceService.getSelectedCountries(this.state.request.origin.value)}
                                        placeholder="Select Origins" />
                                    {this.state.request.riceType.value &&
                                        <small className="form-text text-muted">
                                            *List of origins for the selected type
                                        </small>
                                    }
                                </div>

                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="riceCategory"
                                        items={RICECATEGORY}
                                        validation={this.state.required}
                                        value={this.state.request.riceCategory}
                                        onSelect={e => {
                                            this.setField('riceCategory', e.target.value)
                                            this.setField('optionalDocuments', DocumentFactory.initBEDocumentList(this.getStateValue()))
                                            
                                        }}

                                        placeholder={this.state.request.riceCategory.value}
                                        required={false}
                                        showDefault={true}
                                        sortKeys={false}
                                    />
                                </div>

                                <div className="col-sm-6">
                                    <FormSelectDropdownList
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        items={RiceService.getAvailableReferencesFromArray(this.state.request.origin.value)}
                                        value={this.state.request.riceType}
                                        keyLabel={'label'}
                                        keyValue={'value'}
                                        validation={this.state.required}
                                        placeholder={"Type of rice"}
                                        name="riceType"
                                        captionText={this.state.request.origin.value ? `*List of types for the selected origin` : ''}
                                        required={true}
                                        onSelect={e => {
                                            this.setField("riceType", e.target.value);
                                            this.setField("riceTypeOther", "");
                                            const countryList = RiceService.getAvailableCountries(e.target.value);
                                            if (e.target.value === "") {
                                                this.setField("origin", []);
                                            }
                                            if (countryList.length === 1) {
                                                this.setField("origin", [countryList[0].value]);
                                            }
                                        }}
                                        defaultLabel={"Select"}
                                        disableDefault={false}
                                        showDefault={this.state.request.riceType ? true : false} />
                                </div>
                                {this.state.request.riceType.value === RiceService.enums.REF_OTHER &&
                                    <div className="col-sm-6">
                                        {this.state.request.riceType.value === RiceService.enums.REF_OTHER &&
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                value={this.state.request.riceTypeOther}
                                                validation={this.state.required}
                                                placeholder={"Please Specify"}
                                                name="riceTypeOther"
                                                required={true}
                                                onChange={e => {
                                                    this.setField("riceTypeOther", e.target.value);
                                                }} />}
                                    </div>
                                }
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="quality"
                                        items={QUALITYSTANDARD}
                                        validation={this.state.required}
                                        value={this.state.request.quality}
                                        onSelect={e => this.setField('quality', e.target.value)}
                                        placeholder={this.state.request.quality.value}
                                        required={false}
                                        showDefault={true}
                                    />
                                </div>

                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormSelectDropdownList
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="cropYear"
                                        items={CROP_YEARS}
                                        validation={this.state.required}
                                        value={this.state.request.cropYear}
                                        onSelect={e => this.setField('cropYear', e.target.value)}
                                        placeholder={this.state.request.cropYear.value}
                                        required={false}
                                        showDefault={true}
                                    />
                                </div>
                                <div className={(['CFR', 'CIF'].includes(this.state.request.incoterm.value) && this.state.request.privacyType.value === "TENDER") ? "col-md-3" : this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        validation={this.state.required}
                                        name="incoterm"
                                        items={INCOTERMOPT}
                                        onSelect={e => {
                                            this.setField('incoterm', e.target.value);
                                            this.setField('discharge', "");
                                            this.setField('optionalDocuments', DocumentFactory.initBEDocumentList(this.getStateValue()))
                                        }}
                                        value={this.state.request.incoterm}
                                    />
                                </div>
                                {(['CFR', 'CIF'].includes(this.state.request.incoterm.value) && this.state.request.privacyType.value === "TENDER") &&
                                    <div className="col-md-3">
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            name="optionalIncoterm"
                                            items={optionalIncoterms}
                                            onSelect={e => this.setField('optionalIncoterm', e.target.value)}
                                            value={this.state.request.optionalIncoterm}
                                        />
                                    </div>}
                                {(this.state.request.incoterm.value === 'CIF' || this.state.request.incoterm.value === 'CFR') &&
                                    <React.Fragment>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormSelectDropdown
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                validation={this.state.required}
                                                className="select"
                                                name="destCountry"
                                                captionText="Country of the port of destination"
                                                items={Countries}
                                                onSelect={e => {
                                                    this.setField("destCountry", e.target.value);
                                                    this.setField("destPort", "");
                                                }}
                                                value={this.state.request.destCountry}
                                                placeholder="Country"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormAsyncSelectPort
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                fieldData={this.state.request.destPort}
                                                onChange={(name, value, fromDropdown) => {
                                                    this.setField(name, value);
                                                    if (fromDropdown) {
                                                        this.setField("destCountry", value.slice(0, 2));
                                                    }
                                                }}
                                                country={this.state.request.destCountry.value}
                                                validation={this.state.required}
                                                styles={{
                                                    indicatorsContainer: (provided) => ({ ...provided, height: 31 })
                                                }}
                                                menuPlacement="top"
                                            />
                                        </div>
                                    </React.Fragment>}
                                {(this.state.request.incoterm.value === 'FOB' && this.state.request.origin.value.length <= 1) &&
                                    <React.Fragment>
                                        <div className={`${this.FORM_CREATE_WRAPPER_CLASS}`}>
                                            <FormSelectDropdown
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                validation={this.state.required}
                                                className="select"
                                                name="loadCountry"
                                                items={Countries}
                                                onSelect={e => {
                                                    if (!this.state.request.origin.value.includes(e.target.value)) {
                                                        this.setState({
                                                            showModal: true,
                                                            modalName: 'VALIDATE_LOAD_COUNTRY'
                                                        });
                                                        this.setField("loadCountry", e.target.value);
                                                        this.setField("loadPort", "");

                                                    } else {
                                                        this.setField("loadCountry", e.target.value);
                                                        this.setField("loadPort", "");
                                                    }

                                                    //this.openModel(e.target.value ,this.state.request)
                                                }}
                                                value={this.state.request.loadCountry}
                                                placeholder="Country"
                                            />
                                        </div>
                                        <div className={`${this.FORM_CREATE_WRAPPER_CLASS}`}>
                                            <FormAsyncSelectPort
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                fieldData={this.state.request.loadPort}
                                                onChange={(name, value, fromDropdown) => {
                                                    this.setField(name, value);
                                                    if (fromDropdown) {
                                                        this.setField("loadCountry", value.slice(0, 2));
                                                    }
                                                }}
                                                country={this.state.request.loadCountry.value}
                                                validation={this.state.required}
                                                styles={{
                                                    indicatorsContainer: (provided) => ({ ...provided, height: 31 })
                                                }}
                                                menuPlacement="top"
                                            />
                                        </div>
                                    </React.Fragment>}
                                {(this.state.request.shipping.value === 'CONTAINER' && (
                                    (this.state.request.requestType.value === 'SELL' && this.state.request.incoterm.value === INCOTERMOPT.CIF) ||
                                    (this.state.request.requestType.value === 'BUY' && [INCOTERMOPT.CFR, INCOTERMOPT.FOB].includes(this.state.request.incoterm.value))
                                )) &&
                                    <div className="col-sm-12">
                                        <button className="btn-link"
                                            type="button"
                                            onClick={(e) => { this.setState({ showInsuranceModal: true }) }}>
                                            Insurance estimate : destination {DESTINATION_ZONE[this.state.request.destinationZone.value]},
                                            type {INSURANCECOVER_TYPES[this.state.request.typeOfCover.value]}.
                                        </button>
                                    </div>}
                            </div>

                        </div>}
                    {(this.state.steps.step3.active || this.state.steps.documentSelection.active) &&
                        <div className={`create-trade-details-form form-step mt-1`}>
                            <div className="row">
                                <div className={"col-md-3"}>
                                    <FormDateField
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        dateFormat={DATEFORMAT}
                                        required={true}
                                        validation={this.state.required}
                                        time={false}
                                        name="deliveryStartDate"
                                        minDate={moment()}
                                        maxDate={(this.state.request.deliveryEndDate) ? this.state.request.deliveryEndDate.value : undefined}
                                        selectsEnd={(this.state.request.deliveryEndDate) ? this.state.request.deliveryEndDate.value : undefined}
                                        item={this.state.request.deliveryStartDate}
                                        label={true}
                                        onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                    />
                                </div>
                                <div className={"col-sm-3"}>
                                    <FormDateField
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        dateFormat={DATEFORMAT}
                                        required={true}
                                        validation={this.state.required}
                                        time={false}
                                        name="deliveryEndDate"
                                        minDate={(this.state.request.deliveryStartDate.value) ? this.state.request.deliveryStartDate.value : moment()}
                                        item={this.state.request.deliveryEndDate}
                                        label={true}
                                        selectsStart={this.state.request.deliveryStartDate.value}
                                        selectsEnd={this.state.request.deliveryEndDate.value}
                                        onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                    />
                                </div>
                                {this.props.trade.inspections &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        {/* <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="inspection"
                                            items={this.filterInspectionCompany(this.props.trade.inspections, this.state.request.origin.value)}
                                            validation={this.state.required}
                                            value={this.state.request.inspection}
                                            onSelect={e => {
                                                this.setField('inspection', e.target.value)
                                                this.setField('inspectionOther', "");
                                            }}
                                            placeholder={this.state.request.inspection.value}
                                            required={true}
                                            showDefault={true}
                                        /> */}
                                        <FormSelectDropdownList
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            name={"inspection"}
                                            items={filterInspectionCompany(this.props.trade.inspections, this.state.request.origin.value, false)}
                                            showDefault={true}
                                            onSelect={e => {
                                                this.setField('inspection', e.target.value)
                                                this.setField('inspectionOther', "");
                                            }}
                                            value={this.state.request.inspection}
                                            placeholder={this.state.request.inspection.value}
                                        />
                                    </div>}
                                {this.state.request.inspection.value === "1" &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="inspectionOther"
                                            items={getNonIntegratedInspector(this.state.request.origin.value)}
                                            validation={this.state.required}
                                            value={this.state.request.inspectionOther}
                                            onSelect={e => {
                                                this.setState({
                                                    showModal: true,
                                                    modalName: 'MESSAGE_NON_INTIGRATED_INSPECTOR'
                                                });
                                                this.setField('inspectionOther', e.target.value)
                                            }}
                                            placeholder={this.state.request.inspectionOther.value}
                                            required={true}
                                            showDefault={true}
                                        />
                                    </div>}

                            </div>
                            <div className="row">
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="packaging"
                                        items={PACKAGING}
                                        validation={this.state.required}
                                        value={this.state.request.packaging}
                                        onSelect={e => {
                                            this.setField('packaging', e.target.value);
                                            this.setField('packagingOther', "");
                                        }}
                                        placeholder={this.state.request.packaging.value}
                                        required={false}
                                        showDefault={true}
                                    />
                                </div>

                                {this.state.request.packaging.value === 'OTHER' &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormInputField
                                            type="text"
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="packagingOther"
                                            validation={this.state.required}
                                            value={this.state.request.packagingOther}
                                            onChange={e => this.setField("packagingOther", e.target.value)}
                                            placeholder={"Please specify your packaging"}
                                        />
                                    </div>
                                }
                               
                            </div>
                            {this.state.request.shipping.value === "VESSEL" &&
                                <div className="row">
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            name="discharge"
                                            items={this.state.request.incoterm.value === "FOB" ? DISCHARGEFOB : DISCHARGECIF}
                                            onSelect={e => {
                                                this.setField('discharge', e.target.value);
                                                this.initDischargeRate(e.target.value, '');
                                            }}
                                            value={this.state.request.discharge}
                                        />
                                    </div>
                                    {["CQD load", "CQD"].includes(this.state.request.discharge.value) &&
                                        <div className="col-sm-6">
                                        <FormInputNumberFormat
                                                type="text"
                                                thousandSeparator={true}
                                                step="any"
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name="dischargeRate"
                                                isAllowed={(values) => {
                                                    const { floatValue, value } = values;
                                                    return value === "" || floatValue >= 0;
                                                }}
                                                validation={this.state.required}
                                                value={this.state.request.dischargeRate}
                                                onChange={val => this.setField('dischargeRate', val)}
                                                placeholder={"Rate"}
                                                required={true}
                                            />
                                        </div>}
                                    {["loadrate", "Discharge rate"].includes(this.state.request.discharge.value) &&
                                        <div className="col-sm-12">
                                            <FormTextareaField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name="dischargeRate"
                                                validation={this.state.required}
                                                value={this.state.request.dischargeRate}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder={"Rate"}
                                                required={true}
                                            />
                                        </div>}
                                </div>}
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormRadioFieldTrueFalse
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name={"partialShipmentFlag"}
                                        value={this.state.request.partialShipmentFlag}
                                        onChange={e => this.setField(e.target.name, e.target.value)}
                                    />
                                </div>
                        </div>}
                    <DocumentSelectionModal
                        trade={this.getStateValue()}
                        show={this.state.steps.documentSelection.active}
                        onChange={documentList => this.setField("optionalDocuments", documentList)}
                        onNext={this.nextStep}
                        onClose={this.previousStep}
                        isnonIntegratedInspector={this.state.request.inspection.value === "1"}
                    />

                    {this.state.steps.step4.active &&
                        <div className={`create-trade-details-form form-step mt-1`}>
                            <div className="row">
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="payment"
                                        items={PAYMENTTERMSOPT}
                                        validation={this.state.required}
                                        value={this.state.request.payment}
                                        onSelect={e => this.setField("payment", e.target.value)}
                                        placeholder={this.state.request.payment.value}
                                    />
                                </div>
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="paymentPeriod"
                                        items={PAYMENTPERIODOPT}
                                        validation={this.state.required}
                                        value={this.state.request.paymentPeriod}
                                        onSelect={e => this.setField('paymentPeriod', e.target.value)}
                                        placeholder={this.state.request.paymentPeriod.value}
                                        disabled={this.state.request.payment.value === "CAD"}
                                        sortKeys={false}
                                    />
                                </div>
                                {(this.state.request.payment.value === 'Avalised Draft') &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormDateField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            dateFormat={DATEFORMAT}
                                            required={true}
                                            minDate={new Date()}
                                            validation={this.state.required}
                                            time={false}
                                            name="ADIssuingDate"
                                            item={this.state.request.ADIssuingDate}
                                            label={true}
                                            onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                        />

                                    </div>}
                                {(this.state.request.payment.value === 'Letter of credit' || this.state.request.payment.value === 'Confirmed letter of credit') &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormDateField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            dateFormat={DATEFORMAT}
                                            required={true}
                                            validation={this.state.required}
                                            time={false}
                                            minDate={new Date()}
                                            name="LCOpeningDate"
                                            item={this.state.request.LCOpeningDate}
                                            label={true}
                                            onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                        />
                                    </div>}
                                {(isRTB &&
                                    this.state.request.payment.value !== "Confirmed letter of credit" &&
                                    this.state.request.payment.value !== "CAD"
                                ) &&
                                    <React.Fragment>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'nameOfBank'}
                                                validation={this.state.required}
                                                value={this.state.request.nameOfBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Name of Bank"
                                                type="text"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'locationOfBank'}
                                                validation={this.state.required}
                                                value={this.state.request.locationOfBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Location of Bank"
                                                type="text"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'swiftCodeOfBank'}
                                                validation={this.state.required}
                                                value={this.state.request.swiftCodeOfBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Swift code of Bank"
                                                type="text"
                                            />
                                        </div>
                                    </React.Fragment>}
                            </div>
                            {(this.state.request.payment.value === "Confirmed letter of credit" && isRTB) &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'nameOfBank'}
                                                validation={this.state.required}
                                                value={this.state.request.nameOfBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Name of Bank"
                                                type="text"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'locationOfBank'}
                                                validation={this.state.required}
                                                value={this.state.request.locationOfBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Location of Bank"
                                                type="text"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'swiftCodeOfBank'}
                                                validation={this.state.required}
                                                value={this.state.request.swiftCodeOfBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Swift code"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'nameOfConfirmingBank'}
                                                validation={this.state.required}
                                                value={this.state.request.nameOfConfirmingBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Name of Confirming Bank"
                                                type="text"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'locationOfConfirmingBank'}
                                                validation={this.state.required}
                                                value={this.state.request.locationOfConfirmingBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Location of Confirming Bank"
                                                type="text"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'swiftCodeOfConfirmingBank'}
                                                validation={this.state.required}
                                                value={this.state.request.swiftCodeOfConfirmingBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Swift code of Confirming Bank"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>}

                            <div className="row">
                                <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                    <FormRadioFieldTrueFalse
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name={"downPaymentRequired"}
                                        value={this.state.request.downPaymentRequired}
                                        onChange={e => {
                                            this.setField(e.target.name, e.target.value);
                                            this.setField("downPaymentPercentage", "");
                                            this.setField("downPaymentPeriod", "");
                                        }}
                                    />
                                </div>
                            </div>
                            {this.state.request.downPaymentRequired.value &&
                                <div className="row">
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormInputGroupField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={'downPaymentPercentage'}
                                            apValue={"%"}
                                            validation={this.state.required}
                                            value={this.state.request.downPaymentPercentage}
                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                            placeholder="Down payment"
                                            options={{
                                                numeral: true,
                                                numeralIntegerScale: 3,
                                                numeralPositiveOnly: true,
                                                stripLeadingZeroes: true
                                            }}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        {/* RDEV-2502 Add caption text under the dropdown when a trade is created and down payment selected */}
                                        <FormSelectDropdown
                                            type="text"
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            name="downPaymentPeriod"
                                            items={DOWNPAYMENTPERIODOPT}
                                            captionText= '(days counting from proforma invoice date)'
                                            onSelect={e => this.setField('downPaymentPeriod', e.target.value)}
                                            value={this.state.request.downPaymentPeriod}
                                        />
                                    </div>
                                </div>}
                        </div>}

                    <div style={{ display: this.state.steps.step5.active ? "inherit" : "none" }} className={`create-trade-details-form form-step mt-1`}>
                        {this.state.request.privacyType.value === "TENDER" &&
                            <div className="row">
                                <div className="col-sm-12">
                                    <UploadTenderConditions value={this.state.request.tenderTermsDoc}
                                        name="tenderTermsDoc"
                                        onChange={doc => this.setField('tenderTermsDoc', doc)}
                                        validation={this.state.required} />
                                </div>
                            </div>}
                        <div className="row">
                            <div className="col-sm-12">
                                <FormTextareaField
                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                    name={'specialRequest'}
                                    validation={this.state.required}
                                    value={this.state.request.specialRequest}
                                    onChange={e => this.setField(e.target.name, e.target.value)}
                                    placeholder="Specify a Special Request"
                                />
                            </div>
                        </div>
                        <UploadImage value={this.state.request.images}
                            name={"images"}
                            onChange={(images, validationRequired) => this.setField('images', images, { validate: validationRequired })}
                            validation={this.state.required}
                            onFileSelected={e => this.setState({ fileSelected: e })} />

                    </div>
                    {this.props.systemError &&
                        <div className="mt-3 text-danger text-right"><strong>An error occured : "{this.props.systemError}"</strong></div>}
                    <div className="text-right mt-3 kyc-form-btn">
                        <BackButton> <span><FontAwesomeIcon icon={faTimes} />&nbsp;Cancel</span></BackButton>

                        {this.props.selectedTemplate ?
                            <button type="button" className="btn btn-action btn--green mt-xs-1 lh-1" onClick={() => {
                                this.setState({
                                    showModal: true,
                                    saveAsTemplate: false,
                                    modalName: 'TRADE_TEMPLATE'
                                })
                            }} >
                                <FontAwesomeIcon icon={faFile} />&nbsp; Update Template
                        </button>
                            : ''}
                        <button type="button" className="btn btn-action btn--green mt-xs-1 lh-1" onClick={() => {
                            this.setState({
                                showModal: true,
                                saveAsTemplate: true,
                                modalName: 'TRADE_TEMPLATE'
                            })
                        }} >
                            <FontAwesomeIcon icon={faFile} />&nbsp; Save as New Template
                        </button>
                        {!steps.step1.active &&
                            <button type="button" className="btn btn-action btn--grey mt-xs-1 lh-1" onClick={this.previousStep} >
                                <FontAwesomeIcon icon={faChevronLeft} />&nbsp;Previous
                        </button>}

                        {!steps.step5.active &&
                            <button type="button" className="btn btn-action btn--blue btn-next mt-xs-1 lh-1" onClick={(e) => this.nextStep()}>
                                Next&nbsp;<FontAwesomeIcon icon={faChevronRight} />
                            </button>}
                        {/* <button
                        onClick={e => this.onSave(e)}
                        value="save"
                        disabled={!this.state.isDeclarationSigned || this.props.loading }
                        className="btn btn-resubmit mt-xs-1 lh-1" >
                        <Preloader loadingStyle="dots" loading={this.props.loading}>
                            <FontAwesomeIcon icon={faSave} />
                            Save draft
                        </Preloader>
                    </button>  */}
                        <button
                            onClick={e => this.validateAndAuthoriseReview(e)}
                            type="button"
                            className="btn  btn-action btn--blue mt-xs-1 lh-1" >
                            <span><FontAwesomeIcon icon={faClipboard} /> Review</span>
                        </button>
                        {type === "CREATE" && <button
                            onClick={e => this.handleTradeSubmit(e)}
                            type="button"
                            className="btn  btn-action btn--blue mt-xs-1 lh-1" >
                            <Preloader loadingStyle="dots" loading={this.props.loading}>
                                <span><FontAwesomeIcon icon={faCheck} /> Submit</span>
                            </Preloader>
                        </button>}
                        {type === "UPDATE" && <button
                            onClick={e => this.validateAndAuthorise()}
                            type="button"
                            className="btn btn-action btn-approve mt-xs-1 lh-1" >
                            <Preloader loadingStyle="dots" loading={this.props.loading}>
                                <span><FontAwesomeIcon icon={faCheck} />
                            &nbsp;Update</span>
                            </Preloader>
                        </button>}
                    </div>

                    {options && <div className="rtb-wrapper">
                        <h4>Options</h4>
                        <div>Options are not included in the total price above</div>
                        <div className="row mt-3">
                            <div className="col-sm-3">
                                <FormSelectDropdown
                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                    name="optionsPackagingType"
                                    items={PACKAGING}
                                    validation={this.state.required}
                                    value={this.state.request.optionsPackagingType}
                                    onSelect={e => this.setField('optionsPackagingType', e.target.value)}
                                    placeholder={this.state.request.optionsPackagingType.value}
                                    required={false}
                                    showDefault={true}
                                />
                            </div>
                            <div className="col-md-3">

                                <FormInputGroupField
                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                    name={'optionsPackagingTypePremOrDiscount'}
                                    prepValue={"US $"}
                                    validation={this.state.required}
                                    value={this.state.request.optionsPackagingTypePremOrDiscount}
                                    onChange={e => this.setField(e.target.name, e.target.value)}
                                    placeholder="Price"
                                    type="number"
                                    max={9999999}
                                />
                            </div>
                            <div className="col-md-3">
                                <FormInputField
                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                    name={'optionsTypeOfRice'}
                                    validation={this.state.required}
                                    value={this.state.request.optionsTypeOfRice}
                                    onChange={e => this.setField(e.target.name, e.target.value)}
                                    placeholder="Type of rice"
                                    type="text"
                                />
                            </div>
                            <div className="col-md-3">
                                <FormInputGroupField
                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                    name={'optionsTypeOfRicePremOrDiscount'}
                                    prepValue={"US $"}
                                    validation={this.state.required}
                                    value={this.state.request.optionsTypeOfRicePremOrDiscount}
                                    onChange={e => this.setField(e.target.name, e.target.value)}
                                    placeholder="Price"
                                    type="number"
                                    max={9999999}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <FormSelectDropdown
                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                    name="optionsQualityType"
                                    items={QUALITYSTANDARD}
                                    validation={this.state.required}
                                    value={this.state.request.optionsQualityType}
                                    onSelect={e => this.setField('optionsQualityType', e.target.value)}
                                    placeholder={this.state.request.optionsQualityType.value}
                                    required={false}
                                    showDefault={true}
                                />
                            </div>
                            <div className="col-md-3">
                                <FormInputGroupField
                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                    name={'optionsQualityTypePremOrDiscount'}
                                    prepValue={"US $"}
                                    validation={this.state.required}
                                    value={this.state.request.optionsQualityTypePremOrDiscount}
                                    onChange={e => this.setField(e.target.name, e.target.value)}
                                    placeholder="Price"
                                    type="number"
                                    max={9999999}
                                />
                            </div>
                            <div className="col-md-3">
                                <FormSelectDropdown
                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                    name="optionsShippingType"
                                    items={SHIPPINGTYPES}
                                    validation={this.state.required}
                                    value={this.state.request.optionsShippingType}
                                    onSelect={e => this.setField('optionsShippingType', e.target.value)}
                                    placeholder={this.state.request.optionsShippingType.value}
                                    required={false}
                                    showDefault={true}
                                />
                            </div>
                            <div className="col-md-3">
                                <FormInputGroupField
                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                    name={'optionsShippingTypePremOrDiscount'}
                                    prepValue={"US $"}
                                    validation={this.state.required}
                                    value={this.state.request.optionsShippingTypePremOrDiscount}
                                    onChange={e => this.setField(e.target.name, e.target.value)}
                                    placeholder="Price"
                                    type="number"
                                    max={9999999}
                                />
                            </div>
                        </div>
                        <div>Discounts must be expressed as a negative premium (e.g. a 26 us$ discount for vessel per MT, please enter -26)</div>
                    </div>}

                </form>
                {(this.state.showInsuranceModal && this.props.user.companyType !== "INSURANCE") &&
                    <div className="modal__container">
                        <form className={`modal__wrapper px-0 py-0 modal-sm ${this.state.insuranceValidated ? 'was-validated' : ''}`} >
                            {/* <span className='modal__close' onClick={e => { this.setState({ showInsuranceModal: false }) }} />
                            <h3 className="modal__heading mt-4">Insurance Estimate</h3> */}
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center">
                                        <h5 className="modal-title">Coming Soon :</h5>
                                    </div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => { this.setState({ showInsuranceModal: false }) }} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body py-0">
                                    <p>
                                        Book your dedicated Marine Cargo Cover here.<br /><br />
                                Insurance policy arranged by SIACI SAINT HONORE, Paris with First Class European underwriters.
                            </p>
                                    {/* RDEV-1556 Form hidden waiting for Dedicated insurrance Quote
                                <p> The dedicated RICEX insurance policy is provided by Baloise a leading SWISS underwriter.</p>
                                <div className="row">
                                <div className="col-sm-12">
                                    <FormRadioFieldTrueFalse
                                        customStyle={{ ...this.CUSTOM_COMPONENT_STYLE, radioColLayout: { left: "col-sm-12", right: "col-12" } }}
                                        name="insuranceRequired"
                                        onChange={e => {
                                            this.setField('insuranceRequired', e.target.value);
                                            this.initField('destinationZone', 'Select Destination Zone', '', false);
                                            this.initField('typeOfCover', 'Select the type of Cover', '', false);
                                        }}
                                        value={this.state.request.insuranceRequired} />
                                </div>
                                <div className="col-sm-6">
                                    <FormRadioField
                                        customStyle={{ ...this.CUSTOM_COMPONENT_STYLE, radioColLayout: { left: "col-sm-12", right: "col-12" } }}
                                        name="destinationZone"
                                        disabled={this.state.request.insuranceRequired.value === false}
                                        items={DESTINATION_ZONE}
                                        required={true}
                                        onChange={e => this.setField('destinationZone', e.target.value)}
                                        value={this.state.request.destinationZone} />

                                    <FormRadioField
                                        customStyle={{ ...this.CUSTOM_COMPONENT_STYLE, radioColLayout: { left: "col-sm-12", right: "col-12" } }}
                                        name="typeOfCover"
                                        items={INSURANCECOVER_TYPES}
                                        disabled={this.state.request.insuranceRequired.value === false}
                                        required={true}
                                        onChange={e => this.setField('typeOfCover', e.target.value)}
                                        value={this.state.request.typeOfCover} />
                                </div>
                                <div className="col-sm-6">
                                    {(this.state.request.typeOfCover.value === 'ALL_RISK' && this.state.request.destinationZone.value === 'OTHER') &&
                                        <React.Fragment>
                                            <div>Quote : 0.25%</div>
                                        </React.Fragment>}
                                    {(this.state.request.typeOfCover.value === 'ALL_RISK' && this.state.request.destinationZone.value === 'AFRICA') &&
                                        <React.Fragment>
                                            <div>Quote : 0.30%</div>
                                            <div>With a 1% of deductible of the insured value of any shipment.</div>
                                        </React.Fragment>}
                                    {this.state.request.typeOfCover.value === 'FPA' &&
                                        <React.Fragment>
                                            <div>Quote : 0.08%</div>
                                        </React.Fragment>}
                                </div>

                            </div>
                            <p>To bring security to both buyer and the underwriter and to guarantee ongoing competitive
                                rates and best performance, RICE EXCHANGE has developed a unique prevention program for container.
                                This program consist out of applying of kraft paper in the containers in combination with Dry bags of
                                CLARIANT. The application of the Prevention Program is a condition to RICEX insurance cover.
                                Following two inspection companies have been accredited by the underwriters to carry out inspection
                                for goods shipped under the RIcex insurance policy.</p>
                            <ul>
                                <li>INTERTEK</li>
                                <li>ISC</li>
                            </ul>
                            {this.state.insuranceValidated &&
                                <div className="text-danger">
                                    Plase select mandatory options before clicking on next
                            </div>} */}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn-popup btn-submit" onClick={this.validateInsurance}>
                                        <FontAwesomeIcon icon={faTimes} />&nbsp;Close
                                </button>
                                </div>
                            </div>
                        </form>
                    </div>}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        request: state.trade.request,
        user: state.account.user,
        loadingTemplates: state.loading.loadingTemplates
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            searchCompanies,
            preloadInspectionCompanies,
            sendEmail,
            CreateTemplate,
            UpdateTemplate
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(TradeForm);