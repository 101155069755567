import React, { Component } from 'react';
import Doughnut from 'react-chartjs-2';
import AccessControl, { INSPECTION_COMPANY } from '../../components/AccessControl';
import { UserTypes } from '../../../utils/userTypes';
import "./index.scss";

class PositionChart extends Component {
  totalTrade = (sell, buy) => {
    return sell + buy;
  };

  render() {
    let sell = (this.props.dashboard && this.props.dashboard.yourPositionObject) ? this.props.dashboard.yourPositionObject.sale : '' ;
    let buy = (this.props.dashboard && this.props.dashboard.yourPositionObject) ? this.props.dashboard.yourPositionObject.purchase : '';

    return (
      <div className="col-lg-3">
      <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY]} renderNoAccess={<div className="d-none" />}  >
                   <div className="box">
          <div className="box-title">
            <h4>Total My Sales Versus My Purchases</h4>
          </div>
          <div className="box-content">
            <div className="d-flex">
              <div className="p-2">
                <div>Your Trade</div>
              </div>
              <div className="pt-2 ml-auto">
                <div>${this.totalTrade(sell, buy)}</div>
              </div>
            </div>
            <div className="text-center">
              <div>
                <Doughnut

                  data={{
                    //labels: ['Sell','Purchase'],
                    datasets: [{
                      data: [sell,
                        buy],
                      backgroundColor: ['#cc94ff', '#EFE8E6'],
                      hoverBackgroundColor: ['#cc94ff']
                    }]
                  }
                  }
                  width={380}
                  height={150}
                  options={{ maintainAspectRatio: true }} />
              </div>
            </div>
            <div className="d-flex">
              <div className="p-3">
                <div><span className="dot gray-bg" /> <strong>Sell</strong> ${sell} </div>

              </div>
              <div className="pt-3 ml-auto">
                <div><span className="dot purple-bg" /> <strong>Purchase </strong> ${buy}</div>

              </div>
            </div>
          </div>
        </div>
        </AccessControl>
      </div>

    );
  }
}

export default PositionChart;


