import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormDateField from '../../../../components/form/FormDateField';
import ConfirmModal from './ConfirmModal';
import { DATEFORMATHOURS } from '../../../../../services/service.values';
import { PatchTradeRequest, updateRequestReset } from './../../../../../modules/module.trade';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const CUSTOM_COMPONENT_STYLE = {
  wrapperClassName: "form-group",
  labelClassName: "label",
  inputClassName: "form-control",
  selectClassName: "custom-select",
  radioColLayout: { left: "col-12", right: "col-12" },
  isInvalidClassName: "is-invalid select_error",
  isValidClassName: 'is-valid',
}


function ExtendTradeValidity ({trade, isLoading, updateTrade, resetTrade}) {
  const isEmpty = value => !value || value === undefined || value === '';
  const previousDate = trade.validateDate;

  //Form's default values
  const defaultValues = {
    validateDate: {
      name: 'validateDate',
      label: 'New Validity Date',
      value: undefined,
      touched: false,
      required: date => isEmpty(date) || moment(previousDate).diff(date) >= 0
    }
  }

  //State initialization
  const [request, setRequest] = useState(defaultValues);
  const [required, setRequired] = useState({});
  
  //Trigger on change form's elements
  const handleOnSelect = (name, value) => {
    const updatedRequest = {
      ...request,
      [name]: {
        ...request[name],
        touched: true,
        value
      }
    }
    setRequest(updatedRequest);
    validate(updatedRequest)
  }

  const validate = (container) => {
    const invalidFields = {};
    Object.keys(container).forEach(key => {
      let field = container[key];
      if (field && field.required && field.required(field.value)) {
        invalidFields[key] = field;
      }
    });
    setRequired(invalidFields);
    return Object.keys(invalidFields).length === 0;
  }

  const clearValues = () => {
    setRequest({...defaultValues});
    setRequired({});
  }

  //Trigger on submit form
  const handleOnSubmit  = () => {
    if(validate(request)){
      updateTrade(trade.id, {validateDate: request.validateDate.value.format()}, clearValues)
    }
  }

  const handleOnCancel = () => {
    resetTrade();
    clearValues();
  }

  return (
      <ConfirmModal
        title = 'Extend Validity'
        loading = {isLoading}
        modalWrapperClass="authcode modal__wrapper_show-Calender"
        buttonLabel = "(Extend Validity)"
        buttonClass = "btn btn-small btn-link extend-link"
        buttonConfirmLabel = {<React.Fragment><FontAwesomeIcon icon={faCheck}/>&nbsp;Submit</React.Fragment>}
        showButtonConfirm = {!trade.updateSuccess}
        closeOnSubmit ={false}
        onCancel={handleOnCancel}
        onConfirm={handleOnSubmit}
        buttonCancelLabel = {<React.Fragment><FontAwesomeIcon icon={faTimes}/>&nbsp;{(trade.updateSuccess ? 'Close' : 'Cancel')}</React.Fragment>}>
          {trade && trade.updateSuccess ? (
            <div className="validity-text">
              <span>
                <div className="validity-date">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </div>
                Validity date has been successfully extended
              </span>
            </div>
          ):(
            <React.Fragment>
              <FormDateField
                  customStyle={CUSTOM_COMPONENT_STYLE}
                  dateFormat={DATEFORMATHOURS}
                  disabled={isLoading}
                  time={true}
                  minDate = {moment(previousDate).add(1, 'hours').minutes(0).seconds(0)}
                  required={true}
                  validation={required}
                  name="validateDate"
                  item={request.validateDate}
                  label={true}
                  onSelect={handleOnSelect}
              />
              {(trade && trade.updateError) && <span className="text-danger">{trade.updateError}</span>}
            </React.Fragment>
          )}
      </ConfirmModal>
  );
}

ExtendTradeValidity.propTypes = {
  trade: PropTypes.object,
  isLoading: PropTypes.bool,
  updateTrade: PropTypes.func,
  resetTrade: PropTypes.func
}

const mapStateToProps = state => {
  return {
      trade: state.trade.items.single,
      tradeMap: state.trade.tradeMap,
      isLoading: state.loading.loadingTradeUpdate
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
      { 
        updateTrade: PatchTradeRequest, 
        resetTrade: updateRequestReset 
      },
      dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ExtendTradeValidity);