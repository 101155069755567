import React from 'react';
import { Link } from 'react-router-dom';

const CardMultiOriginPrice = (props) => {
    const { fieldName, fieldValue, counterpartyId, heading=false, className="mt-3"} = props;
    return(
        <div className={`${(heading)?'heading-border':''}`}>
            <div className={`row ${className} `}>
                <div className="col-6">
                    <div className={`compare-${(heading)?'heading-':''}left`}>{ fieldName }
                    {heading ? <div><Link to={`/company-profile/${counterpartyId}`}>Profile</Link></div> : null}
                    </div>
                </div>
                <div className="col-6 compare-multi-origin-price">
                    {fieldValue}
                </div>
            </div>
        </div>
    )
}

export default CardMultiOriginPrice;