import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadTradeBids, loadRequestDetails, AutoupdateTriggered } from '../../../../modules/module.trade';

//COMPONENTS
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import CardOffer from './CardOffer';
import CompareGraphOwnerView from './CompareGraphOwnerView';
import CompareTableMultipleOrigin from './CompareTableMultipleOrigin';
import CardOfferCollapse from './CardOfferCollapse';
import Preloader from '../../../components/preloader/Preloader';

//CSS
import './compareOffer.scss';
import moment from 'moment-timezone';


class CompareOffer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            compare: [],
            updateTime: new Date()
        }
    }
    //Return the list of latest negociation for each counterparty
    getLatestNegotiation(bids){
        const userCompId = this.props.account.token.companyId;
        let counterpartyList = [];
        return bids.filter(bid => {    
            if (bid.fromCompanyID === userCompId && !counterpartyList.includes(bid.toCompanyID)){
                counterpartyList.push(bid.toCompanyID);
                return true
            }
            if(bid.toCompanyID === userCompId && !counterpartyList.includes(bid.fromCompanyID)){
                counterpartyList.push(bid.fromCompanyID);
                return true
            }

            return false;
        });
    }

    componentDidMount(){
        this.props.loadBids(this.props.match.params.id);
        this.props.loadRequestDetails(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.loadBids(this.props.match.params.id);
        }
        if(prevProps.trade.bids !== this.props.trade.bids && this.props.trade.bids && this.props.trade.bids.length !==0){
            const compare = this.getLatestNegotiation(this.props.trade.bids).map(bid => {
                if(this.props.location && this.props.location.state && this.props.location.state.initialSelection){
                    if(this.props.location.state.initialSelection.includes(bid.ID)){
                        bid.selected = true; 
                    }else{
                        bid.selected = false; 
                    }
                }else{
                    bid.selected = true;
                }
                return bid;
            });
            this.setState({compare : compare, updateTime: new Date()});
        }
        if (!prevProps.trade.shouldTriggerTradeUpdate && this.props.trade.shouldTriggerTradeUpdate) {
            this.props.AutoupdateTriggered();         
            if(['TRADE_COUNTERED_NOTIF', 'TRADE_BID_SUBJECT_CONFIRM', 'TRADE_BID_RECOUNTER_PRICE_IMPROVED'].includes(this.props.trade.shouldTriggerTradeUpdate)){
                this.props.loadBids(this.props.match.params.id);
            }
        }
    }

    toggleSelect(bid){
        bid.selected = !bid.selected ;
        this.forceUpdate();
    }

    render() {
        const trade = this.props.trade.items.single;
        const { bids } = this.props.trade;
        const linkToTrade = `/exchange/details/${this.props.match.params.id}`;
        let columnMd = 4;
        let columnSm = 2;
        // let latestNagotiation = [];
        if(bids){
            // latestNagotiation = this.getLatestNegotiation(bids);
            columnMd = 12 / Math.min(bids.length+1, columnMd)
            columnSm = 12 / Math.min(bids.length+1, columnSm)
        }
        
        const isTenderWithMultiOrigin = trade && trade.privacyType === "TENDER" && trade.origin.split(',').length > 1;
        const tradeIncotermBids = trade && this.state.compare.filter(bid => bid.incoterm === trade.incoterm);
        const optionalIncotermBids = trade && this.state.compare.filter(bid => bid.incoterm === trade.optionalIncoterm);

        return <React.Fragment>
                <Header />
                <Preloader loading={this.props.requestLoading} loadingStyle="overflow-spinner" />
                <div className="request-dtls">
                    <div className="container-fluid">
                        <div className="row justify-content-between mb-2 mt-2">
                            <a href="#back" className="trades-dtls__back" onClick={(event) => {
                                event.preventDefault();
                                 this.props.history.goBack();
                            }}>Back</a>
                        </div>
                        {(trade && bids && trade.privacyType === "TENDER") &&
                        <div className="card mt-2 mb-4">
                            <div className="card-body compare-box-section">
                                <div className="row">
                                    <div className="col-md-8"><h3>Compare all prices</h3></div>
                                    <div className="col-md-4 text-right">
                                        <Preloader loadingStyle="spinner" loading={bids.length > 0 && this.props.loadingBids}>
                                            <span>Last update at {moment(this.state.updateTime).format("HH:mm:ss")}</span>
                                        </Preloader>
                                    </div>
                                </div>
                                <Preloader loadingStyle="swirl" loading={bids.length === 0 && this.props.loadingBids}>
                                    <div className="row">
                                        <div className={trade.optionalIncoterm ? 'col-md-6' : "col-md-12"}>
                                            <h6>Incoterm : {trade.incoterm}</h6> 
                                            <CompareGraphOwnerView 
                                                bids={tradeIncotermBids}/>
                                            {isTenderWithMultiOrigin && <CompareTableMultipleOrigin bids={tradeIncotermBids}/>}
                                        </div>
                                        {(trade.optionalIncoterm) && (
                                            <div className={"col-md-6"}>
                                                <h6>Incoterm : {trade.optionalIncoterm}</h6> 
                                                <CompareGraphOwnerView 
                                                    bids={optionalIncotermBids}/>
                                                {isTenderWithMultiOrigin && <CompareTableMultipleOrigin bids={optionalIncotermBids}/>}
                                            </div>
                                        )}
                                    </div>
                                </Preloader>
                            </div>
                        </div>}
                        <div className="card mt-2 mb-4">
                            <div className="card-body compare-box-section">
                                <h3>Compare all offers</h3>
                                <Preloader loading={bids && bids.length > 0 && this.props.loadingBids} loadingStyle="swirl">
                                    <div className="row"> 
                                        { this.state.compare && this.state.compare.filter(bid => !bid.selected).map(bid => 
                                        <div className="col-auto" key={bid.ID}>
                                            <CardOfferCollapse
                                                companyId={this.props.account.user.companyId}
                                                bid={bid} onAdd={() => this.toggleSelect(bid)} 
                                                showMultiOriginPrice={isTenderWithMultiOrigin}
                                            />
                                        </div>)}
                                    </div>
                                    <div className="row d-flex flex-row mt-3 compare-wrap"> 
                                        {trade && 
                                        <div className={`col-sm-12 compare-box mb-3 col-md-${columnSm} col-lg-${columnMd}`} >
                                            <CardOffer 
                                                linkToTrade={linkToTrade} 
                                                bid={trade} 
                                                showMultiOriginPrice={isTenderWithMultiOrigin} 
                                                tradePrivacyType={trade.privacyType} 
                                                inspections={this.props.trade.inspections}
                                            />
                                        </div>}
                                        {(trade && this.state.compare) && this.state.compare.filter(bid => bid.selected).map(bid => 
                                        <div className={`col-sm-12 mb-3 col-md-${columnSm} col-lg-${columnMd}`} key={bid.ID}>
                                            <CardOffer bid={bid} 
                                                onClick={() => this.toggleSelect(bid)} 
                                                ownerId={trade.ownerId} 
                                                companyId={this.props.account.user.companyId}
                                                showMultiOriginPrice={isTenderWithMultiOrigin}
                                                tradePrivacyType={trade.privacyType}
                                                inspections={this.props.trade.inspections}/>
                                        </div>)}
                                    </div>
                                </Preloader>
                                
                            </div>
                        </div>
                    </div>
                </div>

                    <Footer />
                </React.Fragment>
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        account: state.account,
        requestLoading: state.loading.requestLoading,
        loadingBids: state.loading.bidLoading,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ 
        loadRequestDetails,
        loadBids: LoadTradeBids,
        AutoupdateTriggered 
    }, dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CompareOffer);