import React, { Component } from 'react';

import './profile.scss';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { ProfileRouter } from './ProfileRouter';
import { ProfileNavigation } from './ProfileNavigation';

class Profile extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="profile profile-edit">
                    <div className="container-fluid">
                        <div className="profile__back" onClick={() => {
                            this.props.history.goBack()
                        }}>Back</div>
                        <ProfileNavigation />
                        <ProfileRouter />
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default Profile;