import React from "react";

import moment from 'moment';
import converter from 'number-to-words';
import NumberFormat from 'react-number-format';
import Editable from './Editable';
import AccessControl, { INSPECTION_COMPANY } from '../../../../components/AccessControl';

import TradeQuantityFormatter from '../../../../components/Formatter/TradeQuantityFormatter';
import { EnumsService, PortService, renderSelectedOrigins } from '../../../../../services/service.utils';
import { RiceService } from '../../../../../services/service.rice';
import {
    DATEFORMAT,
    DATEFORMATHOURS,
    PACKAGING,
    SHIPPINGTYPES,
    QUALITYSTANDARD,
    PAYMENTTERMSOPT,
    CURRENCYOPT,
    PAYMENTPERIODOPT,
    DOWNPAYMENTPERIODOPT,
    RICECATEGORY_LABEL,
    TOOLTIP_TITLE
} from '../../../../../services/service.values';
import { DocumentFactory, DOCUMENT_UPLOADER } from '../../../trades/services/documents.service';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const GAFTA_FOB_CONTRACT_NO = '120 ';

const Countries = EnumsService.countries();
const PROD_AND_SPEC_DEFAULT = "Rice to be of sound loyal and merchantable quality, free from foreign and/or bad odor, free from live weevils/live insects and practically free of dead weevils, fit for direct human consumption. Otherwise as per export standards of the country of origin valid at time of shipment.";
const CONTRACTUAL_INSPECT_COMP_DEFAULT = "Quality, weight, packing, fumigation and condition shall be determined, at time of loading, by the “contractual appointed surveyor”. The Buyer shall have the right to appoint a second surveyor (“second appointed surveyor”) to check the goods prior to and at the time of loading, in which case the Seller will give full access to the goods. Costs of second appointed surveyor to be for Buyer’s account. In case of dispute, the findings of the contractual appointed surveyor per its certificates to prevail and to be final and binding on both parties";
//const QUANTITY_DEFAULT = "5 pct. more or less in option of Buyer and at contract price.";
const PACKING_DEFAULT = "Bag markings to be printed on two sides of the bags at sellers costs with the marking as agreed between the parties and apart from these marks no other counter markings to appear apart from exporters code and/or other counter markings as imposed by regulator in country of origin, ; but same always to be approved by Buyer prior to printing of the bags.All bags to have full and non-slack appearance and to be double stitched at the top and bottom and suitable for ocean transportation of rice Ink used for marking to be suitable for markings of foodstuffs bags. Sellers to supply 2 pct of empty marked spare bags per each shipment free of charge.";
const PRICE_DEFAULT = "(Incoterms 2020)";
const DISCHARGE_TERMS_DEFAULT = "";
const FUMIGATION_DEFAULT = "To be effected after completion of loading at sellers costs and risk product to be used is aluminum phosphide or aluminum phosphide at 2 gram/m3 for 120 hours or as per recommendation of the inspection company.";
const TOLARANCE_MINMAX = "Min/Max in option of Buyer";
const TOLARANCE_WITHOUT_MINMX = " in option of Buyer";

function TemplateContainerFOB({ trade, openEditables, onEdit, onSave, onCancel, user, bankRejected, lastAmendment }) {
    return (<div className="PDF-wrapper mx-3">
        <div>
            <span className="trades-dtls__contract-text">FOB contract template for Container</span>
            <span className="trades-dtls__contract-date text-right">{`${(trade.acceptedAt && trade.acceptedAt !== "0001-01-01T00:00:00Z") ? moment(trade.acceptedAt).format(DATEFORMAT) : ""}`}</span>
        </div>
        <br />
        <br />
        <h4 className="trades-dtls__contract-heading">
            Contract REF ID : {trade.tradeRefId || trade.id}
        </h4>
        {lastAmendment && <div className="trades-dtls__contract-subheading">
            {`(Amended in Mutual Agreement on ${moment(lastAmendment.acceptedAt).format(DATEFORMATHOURS)})`}
        </div>}
        <section className="section">
            <span className="trades-dtls__contract-item">1. Seller</span>
            <div className="trades-dtls__contract-text">
                <span><strong>Company : </strong></span>{trade.seller}<br />
                <span><strong>Address : </strong></span><br />
                <span>&nbsp;Street : </span>
                {trade.sellerAddress1 && <span>
                    {trade.sellerAddress1.line1 ? trade.sellerAddress1.line1 : ''}<br />
                    {trade.sellerAddress1.line2 ? trade.sellerAddress1.line2 : ''}<br />
                </span>}
                <span>&nbsp;City : </span>{trade.sellerAddress1 ? trade.sellerAddress1.city : ''}<br />
                <span>&nbsp;Zip Code : </span>{trade.sellerAddress1 ? trade.sellerAddress1.postal : ''}<br />
                <span>&nbsp;Country : </span>{trade.sellerAddress1 ? Countries[trade.sellerAddress1.country] : ''}<br />
                <br />
                <span><strong>User : </strong></span>{trade.sellerUser}<br />
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">2. Buyer</span>
            <div className="trades-dtls__contract-text">
                <span><strong>Company : </strong></span>{trade.buyer}<br />
                <span><strong>Address : </strong></span><br />
                <span>&nbsp;Street : </span>
                {trade.sellerAddress1 && <span>
                    {trade.buyerAddress1.line1 ? trade.buyerAddress1.line1 : ''}<br />
                    {trade.buyerAddress1.line2 ? trade.buyerAddress1.line2 : ''}<br />
                </span>}
                <span>&nbsp;City : </span>{trade.buyerAddress1 ? trade.buyerAddress1.city : ''}<br />
                <span>&nbsp;Zip Code : </span>{trade.buyerAddress1 ? trade.buyerAddress1.postal : ''}<br />
                <span>&nbsp;Country : </span>{trade.buyerAddress1 ? Countries[trade.buyerAddress1.country] : ''}<br />
                <br />
                <span><strong>User : </strong></span>{trade.buyerUser}<br />
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">3. Product and Specifications</span>
            <div className="trades-dtls__contract-text">
                <span><strong>Origin : </strong></span>{renderSelectedOrigins(trade.origin)}<br />
                {(trade.riceCategory && trade.riceCategory !== "NON-ORGANIC") &&
                    <React.Fragment>
                        <span><strong>Category of Rice : </strong></span>{RICECATEGORY_LABEL[trade.riceCategory]}<br />
                    </React.Fragment>
                }
                {RiceService.getByRef(trade.riceType) ?
                    <React.Fragment>
                        {RiceService.getByRef(trade.riceType).ref === RiceService.enums.REF_OTHER ?
                            <React.Fragment>
                                <span><strong>Rice Type : </strong></span>{RiceService.getByRef(trade.riceType).label}<br />
                                <span><strong>Specify Type : </strong></span>{trade.riceTypeOther}<br />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <span><strong>Rice Type : </strong></span>{RiceService.getByRef(trade.riceType).label}<br />
                            </React.Fragment>}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <span><strong>Rice Type : </strong></span>{trade.riceType}<br />
                    </React.Fragment>
                }
                <span><strong>Quality : </strong></span>{QUALITYSTANDARD[trade.quality]}<br />
                <span><strong>Crop Year : </strong></span>{trade.cropYear}

                {/* options */}
            </div>
        </section>
        <section className="section">
            <Editable isEditing={openEditables.prodAndSpec}
                name="prodAndSpec"
                onEdit={onEdit}
                onSave={onSave}
                onCancel={onCancel}>{PROD_AND_SPEC_DEFAULT}</Editable>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">4. Contractual Appointed Inspection Company </span>
            <div className="trades-dtls__contract-text">
                <span><strong>Inspection Company : </strong></span>
                {trade.inspection === "1" ?
                    <Tooltip title={TOOLTIP_TITLE.NON_INSPECTOR_TITLE} placement="top-start" arrow>
                        <span>{trade.inspectionName}&nbsp;
                        <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} /></span>
                    </Tooltip>
                    : trade.inspectionName}<br />
            </div>
            <Editable isEditing={openEditables.contractualInspect}
                name="contractualInspect"
                onEdit={onEdit}
                onSave={onSave}
                onCancel={onCancel}>{CONTRACTUAL_INSPECT_COMP_DEFAULT}</Editable>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">5. Quantity</span>
            <div className="trades-dtls__contract-text">
                <span><strong>Quantity : </strong></span><TradeQuantityFormatter trade={trade} />({trade.measure && converter.toWords(trade.measure)} Metric Ton)<br />
                <span><strong>Contractual Tolerance: </strong></span>{!trade.tolerance ? '-' : trade.tolerance === 0 ? `${TOLARANCE_MINMAX}` : `+/- ${trade.tolerance}% ${TOLARANCE_WITHOUT_MINMX}`}<br />
                <span>in 20 foot shipping containers.</span>
            </div>
            {/* <Editable isEditing={openEditables.quantityEditable} onEdit={onEdit}>{QUANTITY_DEFAULT}</Editable> */}
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">6. Packing</span>
            <div className="trades-dtls__contract-text">
                <span><strong>Packaging : </strong></span>{PACKAGING[trade.packaging] || trade.packaging}<br />
            </div>
            {trade.packaging === "OTHER" &&
                <Editable isEditing={openEditables.packingEditable} onEdit={onEdit}>{PACKING_DEFAULT}</Editable>}
        </section>
        {trade.optionsPackagingType &&
            <section className="section">
                <div className="trades-dtls__contract-text">
                    <span><strong>Packing Option : </strong></span>{PACKAGING[trade.optionsPackagingType] ? PACKAGING[trade.optionsPackagingType] : trade.optionsPackagingType}<br />
                    <span><strong>Premium / Discount : </strong></span>{trade.optionsPackagingTypePremOrDiscount} {CURRENCYOPT[trade.currency]}<br />
                </div>
            </section>}
        <section className="section">
            <span className="trades-dtls__contract-item">7. Shipping Type</span>
            <div className="trades-dtls__contract-text">
                <span><strong>Shipping : </strong></span>{SHIPPINGTYPES[trade.shipping] || trade.shipping}<br />
                <span><strong>Partial Shipment Allowed: </strong></span>{trade.partialShipmentFlag ? "Yes" : "No"}<br />
            </div>
            <div className="trades-dtls__contract-text">
                The containers to be certified clean, dry, odorless and suitable to carry the product.
                Kraft paper to be applied on all sides of the container and on top of cargo.
                <br />
                Moitsure absorbing material- desiccants shall be put in each containers as
                follows :  for voyages of less then 60 days min 4 kilos for voyage upto 60 days; for voyages over 60
                days min 6 kilos. Silica gel is not a permitted product.
                <br />
            </div>
        </section>

        {trade.insurranceRequired &&
            <section className="section">
                <div className="trades-dtls__contract-text">
                    Kraft paper to be applied on all sides of the container and on top of cargo.
                    CIF contract template for Container
                <br />
                Moitsure absorbing material- desiccants shall be put in each containers as
                follows :  for voyages of less then 60 days min 4 kilos for voyage upto 60 days ; for voyages over 60
                days min 6 kilos.    The desiccants to be supplied or manufactured by
                Clariant.
            </div>
            </section>}
        <AccessControl user={user} excludeCompanyTypes={[INSPECTION_COMPANY]}>
            <section className="section">
                <span className="trades-dtls__contract-item">8. Price</span>
                <div className="trades-dtls__contract-text">
                    <span><strong>Price : </strong></span>{' '}
                    {(!trade.price && trade.bestPrice) ? (
                        <span>Best Price</span>
                    ) : (
                        <NumberFormat 
                            value={trade.price} 
                            displayType={'text'} 
                            thousandSeparator={true} 
                            suffix={` ${trade.currency} - (${trade.price ? converter.toWords(trade.price) : "zero"}) ${trade.currency} per Metric Ton`}
                        />
                    )}<br />
                    {trade.euroConversionAvailable === "true" &&
                        <React.Fragment><span><strong>Euro Conversion Option : </strong></span>{'Yes'}<br /></React.Fragment>}
                    <span><strong>Incoterm : </strong></span>{trade.incoterm}, <br />
                    {trade.loadPort !== "" ?
                        <div>
                            <span><strong>Port of Load : </strong></span>{(PortService.getJSON()[trade.loadPort]) ? PortService.getJSON()[trade.loadPort].name : trade.loadPort}, ({Countries[trade.loadCountry]}),
                    </div> : <span><strong>Port of Load : </strong></span>}
                </div>
            </section>
            <section className="section">
                <Editable isEditing={openEditables.price} onEdit={onEdit}>{PRICE_DEFAULT}</Editable>
                {trade.optionsShippingType &&
                    <div className="trades-dtls__contract-text">
                        <span><strong>Shipping Option : </strong></span>{SHIPPINGTYPES[trade.optionsShippingType] ? SHIPPINGTYPES[trade.optionsShippingType] : trade.optionsShippingType}<br />
                        <span><strong>Premium / Discount : </strong></span>{trade.optionsShippingTypePremOrDiscount} {CURRENCYOPT[trade.currency]}<br />
                    </div>}
            </section>
        </AccessControl>
        <section className="section">
            <span className="trades-dtls__contract-item">9. Marine Insurance</span>
            <div className="trades-dtls__contract-text">
                For buyer’s account and risk.
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">10. Origin</span>
            <div className="trades-dtls__contract-text">
                <span><strong>Origin : </strong></span>{renderSelectedOrigins(trade.origin)}<br />
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">11. Loading/delivery</span>
            <div className="trades-dtls__contract-text">
                From the origin, in sellers option, during the above mentioned period
            </div>
            <div className="trades-dtls__contract-text">
                <span><strong>From : </strong></span>{moment(trade.deliveryStartDate).format(DATEFORMAT)}<br />
                <span><strong>To : </strong></span>{moment(trade.deliveryEndDate).format(DATEFORMAT)}
                <span>,in Buyer’s option with 7 working days pre-advise prior to loading. Unconditional vessel substitutions to be allowed as long as within the original declared laycan and min 7 working days pre-advise is given to Seller.</span>
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">12. Payment</span>
            <div className="trades-dtls__contract-text">
                {trade.downPaymentPercentage ? (
                    <>
                        <span><strong>Down Payment : </strong></span>{trade.downPaymentPercentage}%<br />
                        <span><strong>Down payment to be effected within : </strong></span>{DOWNPAYMENTPERIODOPT[trade.downPaymentPeriod]} from proforma invoice date.<br />
                        <span><strong>Balance Payment : </strong></span>{PAYMENTTERMSOPT[trade.payment]}<br />
                        <span><strong>Balance Payment Period : </strong></span>{PAYMENTPERIODOPT[trade.paymentPeriod]}
                    </>
                ) : (
                        <>
                            <span><strong>Payment : </strong></span>{PAYMENTTERMSOPT[trade.payment]}<br />
                            <span><strong>Payment Period : </strong></span>{PAYMENTPERIODOPT[trade.paymentPeriod]}
                        </>
                    )}
                {trade.LCOpeningDate && (
                    <>
                        <br /><span><strong>Letter of credit to be opened latest on : </strong></span>{moment(trade.LCOpeningDate).format(DATEFORMAT)}
                    </>
                )}
                {trade.ADIssuingDate && (
                    <>
                        <br /><span><strong>Avalised Draft to be issued latest on : </strong></span>{moment(trade.ADIssuingDate).format(DATEFORMAT)}
                    </>
                )}
            </div>
            <div className="trades-dtls__contract-text">
                <span><strong>Currency : </strong></span>{CURRENCYOPT[trade.currency]}
            </div>

            {trade.nameOfBank &&
                <div className="trades-dtls__contract-text">
                    <span><strong>Name of Buyer's Bank : </strong></span>{bankRejected ? <del>{trade.nameOfBank}</del> : trade.nameOfBank}<br />
                    <span><strong>Location of Buyer's Bank : </strong></span>{bankRejected ? <del>{trade.locationOfBank}</del> : trade.locationOfBank}<br />
                    <span><strong>Swift Code of Buyer's Bank : </strong></span>{bankRejected ? <del>{trade.swiftCodeOfBank}</del> : trade.swiftCodeOfBank}
                </div>}
            {trade.nameOfConfirmingBank &&
                <div className="trades-dtls__contract-text">
                    <span><strong>Name of Confirming Bank : </strong></span>{bankRejected ? <del>{trade.nameOfConfirmingBank}</del> : trade.nameOfConfirmingBank}<br />
                    <span><strong>Location of Confirming Bank : </strong></span>{bankRejected ? <del>{trade.locationOfConfirmingBank}</del> : trade.locationOfConfirmingBank}<br />
                    <span><strong>Swift Code of Confirming Bank : </strong></span>{bankRejected ? <del>{trade.swiftCodeOfConfirmingBank}</del> : trade.swiftCodeOfConfirmingBank}
                </div>}
        </section>
        <section className="section">
            <div className="trades-dtls__contract-text">
                The original doc to consist of :<br />
                <br />
                <ul>
                    {DocumentFactory.getSelectedFEDocumentList(trade).map((doc, index) => (
                        <li key={doc.type}>
                            {`${doc.title} ${doc.description ? doc.description : ''}`} <small>{` (Uploaded by ${DOCUMENT_UPLOADER[doc.uploadedBy]})`}</small>
                        </li>))}
                </ul>
                <div className="trades-dtls__contract-text">
                    Except for the commercial invoice, third party documents are acceptable. Letters of indemnity for missing documents are not acceptable.
                </div>
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">13. Load Terms</span>
            <Editable isEditing={openEditables.dischargeTerms} onEdit={onEdit}>{DISCHARGE_TERMS_DEFAULT}</Editable>
            <div className="trades-dtls__contract-text">
                All origin THC (Terminal Handling Charges) and other eventual costs at origin up to onboard vessel are for Seller’s account.
            </div>
            <div className="trades-dtls__contract-text">
                Seller shall be responsible for obtaining and maintaining in force any necessary export license(s).
                The failure to obtain and or to maintain in force such license(s) shall not be sufficient grounds for a claim of Force Majeure
                if the regulations in force, at the time when the contract was made, called for such license(s) to be obtained.
                </div>
            <div className="trades-dtls__contract-text">
                Title to goods sold and purchased under this contract shall pass from Seller to Buyer upon receipt by Seller of full payment.
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">14. Fumigation</span>
            <Editable isEditing={openEditables.fumigation} onEdit={onEdit}>{FUMIGATION_DEFAULT}</Editable>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">15. Governing Law and Arbitration</span>
            <div className="trades-dtls__contract-text">
                This contract shall be governed by and construed in accordance with English law. Any
                disputes arising out
                of or in connection the breach, termination or validity thereof shall be determined by
                arbitration in
                accordance with GAFTA Arbitration Rules No. 125.
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">16 Other Terms</span>
            <div className="trades-dtls__contract-text">
                Except to the extent inconsistent herewith, all other terms and conditions shall be as per
                    GAFTA Contract No. {GAFTA_FOB_CONTRACT_NO} which are incorporated herein.
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">17 Options</span>
            <div className="trades-dtls__contract-text">
                {trade.optionsPackagingType &&
                    <div className="trades-dtls__contract-text">
                        <span><strong>Packaging Option : </strong></span>{PACKAGING[trade.optionsPackagingType] ? PACKAGING[trade.optionsPackagingType] : trade.optionsPackagingType}<br />
                        <span><strong>Premium / Discount : </strong></span>{trade.optionsPackagingTypePremOrDiscount} {CURRENCYOPT[trade.currency]}<br />
                    </div>}
                {trade.optionsQualityType &&
                    <div className="trades-dtls__contract-text">
                        <span><strong>Quality Option : </strong></span>{QUALITYSTANDARD[trade.optionsQualityType] ? QUALITYSTANDARD[trade.optionsQualityType] : trade.optionsQualityType}<br />
                        <span><strong>Premium / Discount : </strong></span>{trade.optionsQualityTypePremOrDiscount} {CURRENCYOPT[trade.currency]}<br />
                    </div>}
                {trade.optionsShippingType &&
                    <div className="trades-dtls__contract-text">
                        <span><strong>Shipping Option : </strong></span>{SHIPPINGTYPES[trade.optionsShippingType] ? SHIPPINGTYPES[trade.optionsShippingType] : trade.optionsShippingType}<br />
                        <span><strong>Premium / Discount : </strong></span>{trade.optionsShippingTypePremOrDiscount} {CURRENCYOPT[trade.currency]}<br />
                    </div>}
                {trade.optionsTypeOfRice &&
                    <div className="trades-dtls__contract-text">
                        <span><strong>Type of Rice Option : </strong></span>{trade.optionsTypeOfRice}<br />
                        <span><strong>Premium / Discount : </strong></span>{trade.optionsTypeOfRicePremOrDiscount} {CURRENCYOPT[trade.currency]}<br />
                    </div>}
                {(!trade.optionsPackagingType && !trade.optionsQualityType && !trade.optionsShippingType && !trade.optionsTypeOfRice) &&
                    <div className="trades-dtls__contract-text">none</div>}
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">18. Miscellaneous</span>
            <div className="trades-dtls__contract-text">
                (a) Entire Agreement : This contract constitutes the entire agreement between the parties
                relating to the
                purchase of the product in the quantities and during the period specified herein. All prior
                and
                contemporaneous representations, understandings and agreements are superseded and merged
                herein.<br />
                (b) Modifications : This contract cannot be modified except in a written form signed by
                both parties
                to this contract. No usage of trade or prior course of dealing or performance between the
                parties shall be
                deemed to modify the terms of this contract.<br />
                (c) Waiver : No delay or failure on Seller's or Buyer's part to force any right or claim
                which either
                of them may have hereunder shall constitute a waiver of such right or claim. Any waiver by
                Seller or Buyer
                of any term, provision or condition hereof or of any default hereunder in any one or more
                instances shall
                not be deemed to be a further or continuing waiver of such term, provision, or condition or
                of any
                subsequent default hereunder.
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">19. Acceptance</span>
            <div className="trades-dtls__contract-text">
                No term in the Buyers' purchase order, acknowledgement form or other document which
                conflicts with the
                terms hereof shall be binding on the Seller unless accepted in writing by the Seller.
            </div>
        </section>
        {trade.specialRequest &&
            <section className="section">
                <span className="trades-dtls__contract-item">20. Special Request</span>
                <div className="trades-dtls__contract-text">
                    {trade.specialRequest}
                </div>
            </section>}
        <section className="section">
            <div className="row">
                <div className="col-6 trades-dtls__contract-signatures">
                    <span>Digitally signed</span>
                    <br />
                    {trade.seller}
                    <br />
                </div>
                <div className="col-6 trades-dtls__contract-signatures">
                    <span>Digitally signed</span>
                    <br />
                    {trade.buyer}
                </div>
            </div>
        </section>
    </div>
    )
}

export default TemplateContainerFOB;