import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import {loadCompanyDetails} from '../../../../modules/module.account';
import CompanyDetails from './components/CompanyDetails';
import DirectorDetails from './components/DirectorDetails';
import AssociatedCompnies from './components/AssociatedCompnies';
import TradeReferences from './components/TradeReferences';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { viewCompanyProfilePermission } from '../../../../services/service.permission';
// import Preloader from "../../../components/preloader/Preloader";
import CompanyLogo from '../viewCompanyProfile/components/CompanyLogo';
import './view-company-profile.scss';

class CompanyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: {},
            showModal: true,
            modal: '',
            modalData: {},
            images:[],
            companyLogo:{}
        }
    }
   
    renderModal = () => {
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Link>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text"><h6>You are not authorized to perform this action.</h6>
                                      </p>
                                </div>
                                <div className="modal-footer">
                                    <Link to="/" className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel</Link>
                                </div>
                            </div>
                        </form>

                    </div>
                );
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            modalData: {}
        });
    };

    render() {
        var checkPemission = viewCompanyProfilePermission();
        if (checkPemission === 0) {
            return (
                <div>
                    {this.state.showModal && this.renderModal()}
                </div>
            );
        }
        else {
            return (
                <>
                    {/* <Preloader loadingStyle="swirl" loading={this.props.loading}> */}
                        <div className="">
                        {(this.props.company.companyKYC !==undefined) && 
                            <div className="content-wrappper pending-list-details"> 
                                <CompanyLogo 
                                 companyLogo={this.props.company.companyLogo}
                                 companyId={this.props.account.token.companyId}/>
                                <CompanyDetails company={this.props.company.companyKYC}></CompanyDetails>
                                <DirectorDetails company={this.props.company.companyKYC}></DirectorDetails>
                                <AssociatedCompnies company={this.props.company.companyKYC}></AssociatedCompnies>
                                <TradeReferences company={this.props.company.companyKYC}></TradeReferences>
                            </div>}
                        </div>
                    {/* </Preloader> */}
                </>
            );
        }
    }
}
const mapStateToProps = state => {
    return {
        account: state.account,
        loading: state.loading.groupActionLoading
    };
};
const mapDispatchToProps = dispatch =>
   bindActionCreators(
     {
        loadCompanyDetails,
     },
     dispatch
   );
export default connect(mapStateToProps,mapDispatchToProps)(CompanyProfile);
