import React, {
    useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import VesselNominationForm from './VesselNominationForm';
import ShipmentRecapTable from './ShipmentRecapTable';
import Shipment from './Shipment';
import { SHIPPINGTYPES } from '../../../../../services/service.values';
import ContainerNominationForm from './ContainerNominationForm';
import {getShipmentList} from '../../../../../constants/shipmentRecapTableConstants'

const VesselNomination = ({ trade, tradeMap, vesselNominationMap, shipmentMap, onClose, onSubmit, inspectors, nominating, initialValues={} }) => {
    
    const nominationForm = useMemo(() => {
        if (trade.inspection) {
            return  { ...initialValues, vesselInspector: trade.inspection }
        } else {
            return { ...initialValues  }
        }
    }, [trade, initialValues]);
    
    const [newShipment, setNewShipment] = useState();
    const [helper, setHelper] = useState('');
    
    const shipmentList = useMemo(() => {
        const parent = tradeMap[trade.parentTradeRequestID];
       return getShipmentList(tradeMap, parent, vesselNominationMap, shipmentMap, initialValues)
    }, [tradeMap, trade, vesselNominationMap, shipmentMap]);
    
    
    const handleOnValidate = (nominationForm) => {
        const newShipment = new Shipment(nominationForm);
        setNewShipment(newShipment)
    }
    
    const handleOnReset = () => {
        setNewShipment(undefined)
    }
    
    const handleOnTableUpdate = ({ newShipment, shippingHistory, balance, min, max }) => {
        let newHelper = "";
        if (!newShipment) {
            return helper;
        }
        if (trade.shipping === 'VESSEL') {
            if (trade.partialShipmentFlag) {
                if (balance.min !== 0 && balance.max !== 0) {
                    newHelper = <div>After this nomination, the minimum estimated quantity <strong>still to be shipped under this contract 
                    is about {balance.min.toLocaleString(undefined, {maximumFractionDigits:2})} Mton and max about {balance.max.toLocaleString(undefined, {maximumFractionDigits:2})} Mton (excluding shipping tolerances).</strong> Further shipment(s) are therefore
                    likely required and can be added at any time under VESSEL INFO / NOMINATE A NEXT VESSEL. Click CANCEL to go back
                    to the previous screen or SUBMIT to Submit the present nomination</div>;
                } else if (balance.min === 0 && balance.max !== 0) {
                    newHelper = <div>After this nomination, <strong>the minimum quantity still to be shipped under this contract is 0 Mton
                    and max about {balance.max.toLocaleString(undefined, {maximumFractionDigits:2})} Mton (excluding shipping tolerances).</strong> An eventual next shipment can be added  to this contract
                    at any time  under VESSEL INFO / NOMINATE A NEXT VESSEL.Click CANCEL to go back to the previous screen or SUBMIT to Submit the present nomination</div>
                }
            } else {
                if(balance.min !== 0 && balance.max !== 0){
                    newHelper = `Warning : The minimum quantity to be shipped under this contract is ${min} Mton and max ${max} Mton and partial shipments are not allowed.
                Please increase the nominated volume to reach at least the min required quantity or use “request contract amendment “ function to request
                the counter party to accept “partial shipments “ or “ a reduction in the contract quantity”.Click CANCEL to go back to the previous screen
                or SUBMIT to submit the present nomination`
                }
            }
        }
        
        if (trade.shipping === 'CONTAINER') {
            if (trade.partialShipmentFlag) {
                if (balance.min !== 0 && balance.max !== 0) {
                    newHelper = <div>After this nomination, the minimum estimated quantity <strong>still to be shipped under this contract 
                    is about {balance.min.toLocaleString(undefined, {maximumFractionDigits:2})} Mton and max about {balance.max.toLocaleString(undefined, {maximumFractionDigits:2})} Mton (excluding shipping tolerances).</strong> Further shipment(s) are therefore
                    likely required and can be added at any time under VESSEL INFO / NOMINATE A NEXT VESSEL. Click CANCEL to go back
                    to the previous screen or SUBMIT to Submit the present nomination</div>;
                } else if (balance.min === 0 && balance.max !== 0) {
                    newHelper = <div>After this nomination, <strong>the minimum quantity still to be shipped under this contract is 0 Mton
                    and max about {balance.max.toLocaleString(undefined, {maximumFractionDigits:2})} Mton (excluding shipping tolerances).</strong> An eventual next shipment can be added  to this contract
                    at any time  under VESSEL INFO / NOMINATE A NEXT VESSEL.Click CANCEL to go back to the previous screen or SUBMIT to Submit the present nomination</div>
                }
            } else {
                if(balance.min !== 0 && balance.max !== 0){
                    newHelper =  `Warning : The minimum quantity to be shipped under this contract is ${min} Mton and max ${max} Mton and partial shipments are not allowed.
                    Please increase the nominated volume to reach at least the min required quantity or use “request contract amendment “ function to request
                    the counter party to accept “partial shipments “ or “ a reduction in the contract quantity”.Click CANCEL to go back to the previous screen
                    or SUBMIT to submit the present nomination`
                }
                 
            }
        }
        setHelper(newHelper)
    }
    
    const parentId = trade.parentTradeRequestID || trade.id;
    const parent = tradeMap[parentId];
    
    return (
        <div>
            {(trade && nominationForm) &&
            <>
                <ShipmentRecapTable
                    parentTrade={parent}
                    shipmentList={shipmentList}
                    newShipment={newShipment}
                    onTableUpdate={handleOnTableUpdate}
                />

                {trade.shipping === "VESSEL" ?
                <VesselNominationForm
                    initialValues={nominationForm}
                    onClose={onClose}
                    onValidate={handleOnValidate}
                    onSubmit={onSubmit}
                    onReset={handleOnReset}
                    inspectors={inspectors}
                    helper={helper}
                    />
                :
                <ContainerNominationForm
                    initialValues={nominationForm}
                    onClose={onClose}
                    onValidate={handleOnValidate}
                    onSubmit={onSubmit}
                    inspectors={inspectors}
                    helper={helper}
                    loading={nominating}
                />
                }
            </>}
        </div>)
}

VesselNominationForm.propTypes = {
    vesselNominationMap: PropTypes.object
}

VesselNominationForm.defaultProps = {
    vesselNominationMap: {}
}



const mapStateToProps = state => {
    return {
        trade: state.trade.items.single,
        tradeMap: state.trade.items.tradeMap,
        shipmentMap: state.trade.shipmentMap,
        vesselNominationMap: state.trade.vesselNominationMap,
        nomination: state.nomination,
        loading: state.loading.loadingVesselDetails,
        nominating: state.loading.loadingNomination,
        triggerNominationUpdate: state.trade.triggerNominationUpdate,
    };
};

export default connect(mapStateToProps, null)(VesselNomination);