import React from 'react';

import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConfirmTradeCreation = function ({ requestType, onCancel, onSubmit }) {

    const termValue= (requestType === 'SELL') ? "offer" : "bid"
    const term= (requestType === 'SELL') ? "sales" : "buying"
    return <div className="modal__container confirm-modal">
        <div className="modal__wrapper px-0 py-0 overflow-hidden">
            <div className="modal-header">
                <h5 className="modal-title w-100">You are about to submit {termValue==='offer'?'an':'a'} {termValue}</h5>
                <button type="button" className="close" data-dismiss="modal" onClick={onCancel} aria-label="Close">
                    <span aria-hidden="true" >&times;</span>
                </button>
            </div>
            <div className="modal-body" >
                <span>By clicking submit I acknowledge that all terms and conditions have been reviewed and are correct. I further acknowledge that 
                    upon acceptance of my {termValue} by a counterparty on the platform a firm {term} commitment exists.
                </span>
            </div>
            <div className="modal-footer">
                <button className={`btn btn-confirm-modal btn--grey mx-1`} type="button" onClick={onCancel} >
                    <FontAwesomeIcon icon={faTimes} />&nbsp;Back
            </button>
            <button className={`btn-popup btn-submit`} type="button" onClick={onSubmit} >
                    <FontAwesomeIcon icon={faCheck} />&nbsp;Submit
            </button>
                
            </div>
        </div>
    </div>

}

export default ConfirmTradeCreation;