import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPermissionListByGroup, savePermissions } from '../../modules/module.adminManagement';
import {push} from "connected-react-router";
import './adminManagement-style.scss';
import PERMISSIONS from '../../utils/permission.json';
import INSPECTION_PERMISSIONS from '../../utils/inspectionPermission.json';
import SHIPPING_PERMISSIONS from '../../utils/shippingPermission.json';
import { PERMISSION_VALUE, PERMISSION_NAME } from '../../services/service.values';
import Preloader from '../components/preloader/Preloader';
import '../components/form/FormInputField.scss';

class Permission extends Component {
  constructor(props) {
    super(props);
    var permissionJSON;
    if (this.props.user.companyType === "INSPECTION") {
      permissionJSON = INSPECTION_PERMISSIONS.permissionList;
    } else if (this.props.user.companyType === "SHIPPING") {
      permissionJSON = SHIPPING_PERMISSIONS.permissionList;
    } else {
      permissionJSON = PERMISSIONS.permissionList;
    }
    this.state = {
      showModal: false,
      allChecked: false,

      permissions: permissionJSON
    };
  }

  handleChange = e => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    let disabledCheck = checked === true ? true : false;
    this.setState(prevState => {
      let { permissions, allChecked } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        let disabledallCheck = checked === true ? true : false;
        permissions = permissions.map(item => ({ ...item, isChecked: checked })
        );
        permissions = permissions.map(item =>
          item.name === PERMISSION_NAME.VIEW_EXCHANGE ? { ...item, isDisabled: disabledallCheck } : item
        );
        permissions = permissions.map(item =>
          item.name === PERMISSION_NAME.VIEW_TRADEREQUEST ? { ...item, isDisabled: disabledallCheck } : item
        );

      }

      else {
        permissions = permissions.map(item =>
          item.name === itemName ? { ...item, isChecked: checked } : item
        );
        if (itemName === PERMISSION_NAME.CREATE_TRADEREQUEST) {
          permissions = permissions.map(item =>
            item.name === PERMISSION_NAME.VIEW_EXCHANGE ? { ...item, isChecked: checked, isDisabled: disabledCheck } : item
          );
          permissions = permissions.map(item =>
            item.name === PERMISSION_NAME.VIEW_TRADEREQUEST ? { ...item, isChecked: checked, isDisabled: disabledCheck } : item
          );
        }

        allChecked = permissions.every(item => item.isChecked);
      }
      return { permissions, allChecked };
    });
  };

  UNSAFE_componentWillMount = () => {
    this.props.getPermissionListByGroup(this.props.id);
    this.timerId = setTimeout(() => {
      this.fetchFunction();
    }, 1000);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timerId);
  }

  fetchFunction = () => {
    let data;
    if (this.props.user.companyType === "INSPECTION") {
      data = INSPECTION_PERMISSIONS.permissionList;
    } else if (this.props.user.companyType === "SHIPPING") {
      data = SHIPPING_PERMISSIONS.permissionList;
    } else {
      data = PERMISSIONS.permissionList;
    }
    if (this.props.usermanagment !== undefined) {
      for (let key of Object.keys(this.props.usermanagment)) {
        data.forEach(item => {
          if (item.value === key && this.props.usermanagment[key] === 1) {
            item.isChecked = true;
          }
        });
      }
    }
    this.setState({ permissions: data, allChecked: data.every(item => item.isChecked) })
  };

  getItems = () => {
    let data;
    if (this.props.user.companyType === "INSPECTION") {
      data = INSPECTION_PERMISSIONS.permissionList;
    } else if (this.props.user.companyType === "SHIPPING") {
      data = SHIPPING_PERMISSIONS.permissionList;
    } else {
      data = PERMISSIONS.permissionList;
    }
    for (let key of Object.keys(this.props.usermanagment)) {
      data.forEach(item => {
        if (item.value === key && this.props.usermanagment[key] === 1) {
          item.isChecked = true;

        } else if (item.value === key && this.props.usermanagment[key] === 0) { //TODO--why this:SudhierK
          item.isChecked = false;
        }
        if (item.value === PERMISSION_VALUE.VIEW_EXCHANGE && this.props.usermanagment[key] === 1) {
          item.isDisabled = true;
        }
        if (item.value === PERMISSION_VALUE.VIEW_TRADEREQUEST && this.props.usermanagment[key] === 1) {
          item.isDisabled = true;
        }
      });
    }
    return data;
  };
  onPermissionSubmit = () => {
    let permissionData = this.state.permissions;
    var keyValuePair = {};
    permissionData.map(d => {
      keyValuePair[d.value] = d.isChecked === true ? 1 : 0
      return d;
    });
    let permissions = {
      permissions: keyValuePair
    }
    this.props.savePermissions(this.props.id, permissions);
  };
  renderList = () => {
    this.getItems()
    return this.state.permissions.map(item => (
      <tr key={item.id}>
        <td>{item.name}</td>
        <td>
          <input
            key={item.id}
            type="checkbox"
            name={item.name}
            value={item.name}
            checked={item.isChecked}
            onChange={this.handleChange}
            disabled={item.isDisabled}
          />
        </td>
      </tr>
    ));
  };

  render() {
    return (
      <React.Fragment>

        <section className="rbac-body">
          {/* <h2 className="trades__heading">User Groups / Manage Permission</h2> */}
          {this.state.showModal && this.renderModal(this.state.modal)}

          <div className="permission_header">
            <div className="form-input">
              <div className="row">

                <div className="col-md-12 permission__wrapper-left">
                  <div className="form-input">
                    <label className="label">Group Name</label>
                    <input className="input form-control" name="id" disabled value={this.props.name} />
                    <div className="ml-md-3">
                      <button type="button" className="btn-popup btn-submit whsp-nowrap mt-xxs-10" onClick={this.onPermissionSubmit}>
                        <Preloader loadingStyle="dots" loading={this.props.savePermissionLoading}>
                          <span>Save</span>
                        </Preloader>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Preloader loadingStyle="overflow-spinner" loading={this.props.groupLoading}>
            <div className="table-responsive pt-2 pr-3 pl-3 pb-2">
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    <th>Permission</th>
                    <th>  <input
                      type="checkbox"
                      name="checkAll"
                      checked={this.state.allChecked}
                      onChange={this.handleChange}
                    />
       &nbsp;  Check all

        </th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderList()}
                </tbody>
              </table>
            </div>
          </Preloader>
        </section>

      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    usermanagment: state.usermanagment.permission,
    savePermissionLoading: state.loading.loadingSavePermission,
    groupLoading: state.loading.groupLoading,
    user: state.account.user,

  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPermissionListByGroup,
      savePermissions,
      navigate: path => push('/permission' + path)
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(Permission);
