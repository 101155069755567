import React from 'react';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from '../../../components/preloader/Preloader';
import { useCache } from '../../../../services/service.api';

const VesselInfo = ({id,url,fieldList}) => {
    const response = useCache(`${url}/${id}`)
    if(!response.data){
        return <div align="center">Loading...</div>;
    }
    return (<>
        <div className="trades-dtls__table-section px-0">
        {response.isLoading?<div align="center">Loading...</div>:
            <table className="table table-sm table-hover mb-1 table-light table-striped">
                <thead>
                    <tr>
                        <th className={`align-middle bg-light`}>Field name</th>
                        <th className={`align-middle bg-light`}>Rejected Vessel</th>
                    </tr>
                </thead>
                <tbody>
                    {fieldList.map(field => (
                        <tr key={field.key}>
                            <td>{field.label}</td>
                            {field.format
                                ? <td>{field.format(response.data[field.key])}</td>
                                : <td>{response.data[field.key]}</td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        }
        </div>
    </>)
}

const ViewRejectedVesselInfo = (props) => {

    const {
        onApproveWithNewLaycan, 
        onClose, 
        onReject, 
        vesselInfo,  
        whoItIs,
        incoterm, 
        isLaycanDateChanged, 
        LoadingNominationInfo, 
        vesselAccepted,
        inspector,
        vesselId,
        trade,
        fieldList,
        vesselInfoHeader,
        url
    } = props

    return (
        <React.Fragment>
            <div className='modal__container vessel_modal accpet_with_new_laycan'>
                <form className='modal__wrapper px-0 py-0 laycan-calendar' onSubmit={onApproveWithNewLaycan} noValidate={true}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-header-center">
                            <h5 className="modal-title">
                                {vesselInfoHeader}
                            </h5>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className='modal-body'>        
                        <div className='pt-1'>
                            <VesselInfo fieldList={fieldList} url={url} id={vesselId}/>
                        </div>
                    </div>
                </div>
                <div className='modal-footer' style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <button type="button" className="btn btn-secondary" onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} /> Cancel
                    </button>
                    {whoItIs==="buyer" &&
                    <div>
                        {(incoterm==="FOB" && isLaycanDateChanged && !vesselAccepted) &&  
                        <button type="button" className="btn btn-secondary" onClick={onReject}>
                            <FontAwesomeIcon icon={faTimes} /> Reject
                        </button>}
                        {(incoterm==="FOB" && isLaycanDateChanged && !vesselAccepted) &&
                        <button type="submit" className="btn btn-primary">
                            <Preloader loadingStyle="dots" loading={LoadingNominationInfo}>
                                    <FontAwesomeIcon icon={faCheck} /> Accept
                            </Preloader>
                        </button>}
                    </div>}
                </div>
            </form>
        </div>
    </React.Fragment>
    )
}

export default ViewRejectedVesselInfo