import React from 'react';

const ModalHeader = ({ title, onClose }) => {
    return (
        <div className="modal-header">
            { title &&
                <div className="modal-header-center">
                    <h5 className="modal-title">{title}</h5>
                </div>}
            <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    )
}

export default ModalHeader;

