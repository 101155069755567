import React from 'react';


/**
 * Short description. (use period)
 *
 * @since  05/02/20
 * @access public
 *
 * @type     React.Component
 * @property {type} key Description.
 *
 * @member   {type} realName
 * @memberof className
 */
class DetailTableHead extends React.Component{
    constructor(props){
        super(props);
        this.state = { data : props.data };
    }

    render(){

        const {content} = this.props;
        const textAlign = (content==="-")?"text-center":"text-left";

        return  <th className={`align-middle bg-light ${textAlign}`}>{this.props.content}</th>

    }
}

export default DetailTableHead;