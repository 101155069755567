import React from 'react';
import moment from 'moment-timezone';
import { DATEFORMATHOURS } from '../../../../services/service.values';
import { Link } from 'react-router-dom';


const PublicCommunicationList = ({ onSelect, selected, listCounetpartyMessage }) => {

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-sm-6">
                    <h3 className="request-dtls__card-heading">
                        Communication
                        </h3>
                </div>
            </div>
            <div className="table-responsive mt-2">
                <table className="table table-hover latest-negociation-table">
                    <thead>
                        <tr>
                            <th>Counterparty Name</th>
                            <th>Received at</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listCounetpartyMessage && listCounetpartyMessage.length !== 0 && listCounetpartyMessage.map((item, i) =>
                            <tr className={([item.fromCompanyID].includes(selected)) ? "table-info" : ""}
                                onClick={() => onSelect(item)}
                                key={i}>
                                <td><Link to={`/company-profile/${item.fromCompanyID}`}>{`${item.companyName}`}</Link></td>
                                <td>{moment(item.createdAt).format(DATEFORMATHOURS)}</td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </React.Fragment>)
}
export default PublicCommunicationList;