import React, { Component } from 'react';

import {
    FormRow,
} from '../../Form';

import FormInputField from '../../form/FormInputField';
import FromInputFormPercentage from '../../form/FromInputFormPercentage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

export class ShareholderDetails extends Component {

    render() {

        let { state, setField, validation, add, remove, setNoShareholder,noShareholder} = this.props;
        return (
            <React.Fragment>
                
                <FormRow>
                <div className="col-12 col-md-6 mb-3">
                <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                        className="custom-control-input"
                        checked={noShareholder}
                        id="noShareholder"
                        name="noShareholder"
                        value={noShareholder}
                        onChange={val => {
                            setNoShareholder(val.target.checked)
                        }}
                        />
                    <label
                        className="custom-control-label"
                        htmlFor="noShareholder">
                            {"All Shareholders have Ownership < 10% "}
                    </label>
                </div>
                </div>
                </FormRow>
                {!noShareholder && state.shareholders &&
                    state.shareholders.map((k, i) => {
                        return (
                            <React.Fragment key={i}>
                                <FormRow>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            validation={validation[i]}
                                            name="name"
                                            
                                            value={k.name}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "shareholders", i)
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FromInputFormPercentage
                                            type="number"
                                            validation={validation[i]}
                                            name="percentage"
                                            value={k.percentage}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "shareholders", i)
                                            }}
                                        />
                                    </div>
                                </FormRow>
                                    <div className="text-right">
                                        {i !== 0 ? (
                                        <button type="button" className="btn btn-section-removed" onClick={e => remove(i)}>
                                                <FontAwesomeIcon icon={faTimes} /> Remove
                                        </button>
                                        ) : ''}
                                        {i === state.shareholders.length - 1 ? (
                                            <button type="button" className="btn btn-add" onClick={e => add()}>
                                                <FontAwesomeIcon icon={faPlus} /> Add
                                        </button>
                                        ) : ''}

                                    </div>
                            </React.Fragment>
                        );
                    })}
            </React.Fragment>
        );
    }
}
