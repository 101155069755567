import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PriceDetails from './PriceDetails';

//ACTIONS
import { getMaerskBookingReq,getMaerskBookingReqFinal } from '../../../../modules/module.shipmentQuote';
import Preloader from '../../../components/preloader/Preloader';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class BookingDetailsModal extends React.Component{

    constructor(props){
        super(props);
        const {priceQuoteId, priceId} = this.props;
        props.getMaerskBookingReqFinal(priceQuoteId, priceId);
        this.handleOnClose = this.handleOnClose.bind(this);
    }
 
    handleOnClose(e) {
        if(this.props.onClose){
            this.props.onClose();
        }
    }

    render(){
        const {shipmentQuote} = this.props;
        const routeScheduleFull= (shipmentQuote)?shipmentQuote.bookingDetails.routeScheduleFull:false;
    
        return <div className="modal__container">
            <div className="modal__wrapper px-0 py-0" style={{maxWidth: "80%"}}>
                <div className="modal-content"> 
                    <div className="modal-header">
                        <h5 className="modal-title">Booking Details</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.handleOnClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Preloader loadingStyle="swirl" loading={shipmentQuote.fetchBookingPending}>
                            { routeScheduleFull &&<div className="row">
                                <div className="col-sm-6 col-lg-3"><strong>Departure</strong>: {`${routeScheduleFull.fromLocation.siteName} (${routeScheduleFull.fromLocation.countryName})`} </div>
                                <div className="col-sm-6 col-lg-3"><strong>Time:</strong> {`${routeScheduleFull.fromLocation.date} ${routeScheduleFull.fromLocation.time}`}</div>
                                <div className="col-sm-6 col-lg-3"><strong>Arrival:</strong> {`${routeScheduleFull.toLocation.siteName} (${routeScheduleFull.toLocation.countryName})`} </div>
                                <div className="col-sm-6 col-lg-3"><strong>Time:</strong> {`${routeScheduleFull.toLocation.date} ${routeScheduleFull.toLocation.time}`}</div>
                            </div>}
                            <br/>
                            <PriceDetails price={shipmentQuote.bookingDetails.price}/>
                        </Preloader>
                    </div>
                    <div className="modal-footer">
                        <button onClick={this.handleOnClose} className="btn-popup btn-cancel ">
                            <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                        </button>
                    </div>
                </div>
            </div>              
        </div>
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        shipmentQuote: state.shipmentQuote,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getMaerskBookingReq,
            getMaerskBookingReqFinal
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailsModal);