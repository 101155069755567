import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFile } from '@fortawesome/free-solid-svg-icons';
import FormInputField from '../../../../components/form/FormInputField';
import Preloader from '../../../../components/preloader/Preloader';

class TradeTemplateModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            tradeTemplate: {
                label: 'Template Name',
                required: true,
                value: props.templateName || '',
            },
            error: null
        }
    }
    
    handleSubmit = () => {
        if(this.state.tradeTemplate.value){
            const isValidName = this.checkValidName(this.state.tradeTemplate.value);
            if(isValidName){
                if(this.props.isRenaming){
                    this.props.submitHandler(this.props.templateId, this.state.tradeTemplate.value, this.props.closeModal);
                }else{
                    const updatedTemplateBody = { ...this.props.templateRequest }
                    updatedTemplateBody.templateName = this.state.tradeTemplate.value;
                    if(this.props.isUpdate){
                        this.props.submitHandler(this.props.templateId, updatedTemplateBody, this.props.closeModal);
                    }else{
                        if(this.props.templateSavingOnSubmitCallBack){
                            this.props.submitHandler(updatedTemplateBody);
                            this.props.templateSavingOnSubmitCallBack();
                        }else{
                            this.props.submitHandler(updatedTemplateBody, this.props.closeModal);
                        }
                    }
                }
            }else{
                this.setState({
                    error: 'A template with this name already exist'
                })
            }
        }else{
            this.setState({
                error: 'Please enter the template name before submitting'
            })
        }
    }

    templateNameChangeHandler = (event) => {
        const target = event.target;
        this.setState(prevState => ({
            tradeTemplate: {
                ...prevState.tradeTemplate,
                value: target.value.toLowerCase()
            },
            error: null
        }))
    }

    checkValidName = (enteredName) => {
        let isValid = true;
        if(this.props.templates.length > 0){
            for(let i = 0; i<this.props.templates.length; i++){
                if(enteredName === this.props.templates[i].templateName && enteredName !== this.props.templateName){
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }

    render(){
        let btnSubmitClass = "btn-popup btn-submit";
        let btnSubmitDisabled = false;
        if(this.props.templateCount === 15 && !this.props.isRenaming && !this.props.isUpdate){
            btnSubmitClass += " btn-popup-disabled";
            btnSubmitDisabled = true;
        }

        return(
            <div className="modal__container">
                <div  autoComplete='off' 
                    className="modal__wrapper px-0 py-0 authcode">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <div className="modal-header-center">
                                 <h5 className="modal-title create-heading">Trade Request Template</h5>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                            </div>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.cancelhandler} aria-label="Close">                                                                                                                                                                                                                                                                    
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="create-req__wrapper mb-0">
                                <div className="form-group">
                                    <FormInputField 
                                        name="tradeTemplate"
                                        type="text"
                                        value={this.state.tradeTemplate}
                                        placeholder="Enter Name"
                                        onChange={this.templateNameChangeHandler}/>
                                </div>
                                {this.state.error ? (
                                    <div className="trades-dtls__error-message">
                                        {this.state.error}
                                    </div>
                                ) : null}
                            </div>
                            {
                             this.props.templateCount === 15 && !this.props.isRenaming && !this.props.isUpdate ? 
                                <React.Fragment>
                                    <span className="req-field">*</span> <b>Warning</b> : You can only save a maximum of 15 templates. If you want to save this template please first delete an existing template
                                </React.Fragment> : null
                            }
                             
                        </div>
                        <div className="modal-footer pt-0">
                            <button type="button" onClick={this.props.cancelhandler} className="btn-popup btn-cancel ">
                                <FontAwesomeIcon icon={faTimes} /> Cancel
                            </button>
                            <button type="button" onClick={this.handleSubmit} className={btnSubmitClass} disabled={btnSubmitDisabled}>
                                <Preloader loadingStyle="dots" loading={this.props.loading}>
                                    <span><FontAwesomeIcon icon={faFile} className="mr-0" /> {this.props.isRenaming ? 'Rename Template' : this.props.isUpdate ? 'Update Template' :  'Save as Template'}</span>
                                </Preloader>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default TradeTemplateModal;