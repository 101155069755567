import React from 'react';
import ConfirmModal from './ConfirmModal';



/**
 * Display confirm modal 
 *
 * @since  x.x.x
 * @access public
 *
 * @type     {React.Component}
 * @property {content}
 * @property {title}
 * @property {onCancel}
 * @property {onConfirm}
 * @property {show}
 */
class ConfirmModalCancelTrade extends React.Component {
    render() {
        const {
            loading = false,
            onConfirm,
            requestStatus,
            ...rest
        } = this.props;

        return <ConfirmModal loading={loading}
            disabled={requestStatus && requestStatus === 'CANCELED'}
            title={`You are about to cancel the trade`}
            buttonClass="btn btn-action btn-danger bc-btn__small bc-btn-radius"
            {...rest}
            buttonConfirmClass="btn-danger"
            onConfirm={onConfirm}>
            <p className="text-center"> <strong>Warning</strong> You are about to cancel a trade request. This operation cannot be undone.
             If you click on 'Cancel Trade', all your changes will be lost.
                Click 'Back' to go back to the previous screen.</p>

        </ConfirmModal>
    }

}

export default ConfirmModalCancelTrade;


