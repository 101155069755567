import React from 'react';
import './FormInputField.scss';

const FormInputField = ({
        name,
        value,
        type,
        validation,
        onChange,
        max,
        maxlength,
        placeholder = '',
        disabled,
        readOnly,
        customStyle = {
            wrapperClassName : "form-input",
            inputClassName : "input form-control",
            isValidClassName: "",
            isInvalidClassName: "input_error"

        },
        ...rest
    }) => {
        const isInvalid = validation && validation.hasOwnProperty(name);
        return (<div className={customStyle.wrapperClassName}>
                {value.label &&
                <label htmlFor={name} className={customStyle.labelClassName}>
                    {value.label}
                    {value.required && <span className="req-field">*</span>}
                </label>}
                <input
                    type={type}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    value={value.value}
                    className={`${customStyle.inputClassName} 
                                ${isInvalid ? customStyle.isInvalidClassName :  value.touched || value.validated ? customStyle.isValidClassName : ''}`}
                    id={name}
                    name={name}
                    onChange={onChange}
                    max={max}
                    maxLength={maxlength}
                    required={!!value.required}
                    disabled={disabled}
                    {...rest}
                />
            </div>)
};

export default FormInputField
