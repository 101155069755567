import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Preloader from '../../../../components/preloader/Preloader';
import Dropzone from 'react-dropzone';
import { FaRegFilePdf, FaRegFile } from 'react-icons/fa';
import FormTextareaField from '../../../../components/form/FormTextareaField';

class UpdatePTDoc extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            request: {},
            required: {}
        }

        this.CUSTOM_COMPONENT_STYLE = {
            wrapperClassName : "form-group",
            labelClassName :"label",
            inputClassName : "form-control",
            selectClassName : "custom-select",
            isInvalidClassName: "is-invalid input_error",
            isValidClassName: 'is-valid',
        }
    }

    get paymentTerm(){
        return this.props.trade.payment === 'Avalised Draft' ? 'AD' : 'LC';
    }

    get draftType(){
        return this.props.trade.payment === 'Letter of credit' ? 'L/C Draft' : this.props.trade.payment === 'Confirmed letter of credit' ? 'Confirmed L/C Draft' : 'Avalised Draft'
    }

    componentDidMount(){
        let isEmpty = value => !value || value === undefined || value === '';
        if(this.props.ptDocDetails.ptType === 'document'){
            this.initField('ptDoc', `Please Upload ${this.draftType}`, null, value => isEmpty(value) || value[0].type !== 'application/pdf', false);
        }else{
            this.initField('ptText', `Please Paste Your ${this.draftType}`, '', isEmpty, false);
        }
        this.initField('additionalInformation', 'Additional Information', '', false, false);
    }

    initField = (name, label, value, required, touched = true) => {
        this.setState( prevState => {
            return {
                ...prevState,
                request: {
                    ...prevState.request,
                    [name]: { name, label, value, required, touched }
                }
            }
        });
    }

    validate = () => {
        let container = { ...this.state.request };
        let required = {};
        Object.keys(container).map(key => {
            let v = container[key];
            container[key].touched = true;
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
            }
            this.setState(prevState => {
                prevState.request[key].validated = true;
                return { ...prevState };
            })
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    validateField = (name, value) => {
        this.setState(prevState => {
            let request = { ...prevState.request };
            let required = { ...prevState.required };
            request[name].validated = true;

            if (request[name].required && request[name].required(value)) {
                required[name] = prevState.request[name];
            } else {
                delete required[name];
            }
            return { request, required }
        });
    }

    onSubmitHandler = (event) => {
        event.preventDefault();
        if(this.validate()){
            let data = null
            if(this.props.ptDocDetails.ptType === 'document'){
                data = new FormData();
                data.append('upload', this.state.request.ptDoc.value[0]);
                data.append('remark', this.state.request.additionalInformation.value);
                data.append('additionalInformation', '');
                data.append('fcn', 'reupload'); 
                if(this.props.trade.payment === 'Avalised Draft'){
                    data.append('adType', this.props.ptDocDetails.ptType);
                    data.append('avalizedDraftID', this.props.ptDocDetails.ptDocId);
                    data.append('docType', 'AVALIZED_DRAFT');
                }else if(this.props.trade.payment === 'Confirmed letter of credit'){
                    data.append('confirmedLcType', this.props.ptDocDetails.ptType);
                    data.append('confirmedLcID', this.props.ptDocDetails.ptDocId);
                    data.append('docType', 'CONFIRMED_LETTER_CREDIT');
                }else{
                    data.append('lcType', this.props.ptDocDetails.ptType);
                    data.append('letterOfCreditID', this.props.ptDocDetails.ptDocId);
                    data.append('docType', 'LETTEROFCREDIT');
                }
            }else{
                data = {};
                if(this.props.trade.payment === 'Avalised Draft'){
                    data.adText = this.state.request.ptText.value;
                    data.adType = this.state.ptType;
                    data.avalizedDraftID = this.props.ptDocDetails.ptDocId
                    
                }else if(this.props.trade.payment === 'Confirmed letter of credit') {
                    data.confirmedLcText = this.state.request.ptText.value;
                    data.confirmedLcType = this.state.ptType;
                    data.confirmedLcID = this.props.ptDocDetails.ptDocId;
                }else{
                    data.lcText = this.state.request.ptText.value;
                    data.lcType = this.state.ptType;
                    data.letterCreditID = this.props.ptDocDetails.ptDocId
                }
                data.additionalInformation = this.state.request.additionalInformation.value
                data.status = 'UPDATED';
            }
            let url = `/trade/${this.props.trade.id}/letterOfCredit`;
            if(this.props.trade.payment === 'Avalised Draft') url = `/trade/${this.props.trade.id}/avalizedDraft`;
            if(this.props.trade.payment === 'Confirmed letter of credit') url = `/trade/${this.props.trade.id}/confirmedLC`
            this.props.updatePaymentTermDoc(url, data, this.props.ptDocDetails.ptType, this.props.onCancel);
        }
    }

    setField = (name, value) => {
        let request = { ...this.state.request };
        if(name) {
            request[name] = {
                ...request[name],
                value,
                touched: true
            }
            this.setState(prevState => {
                return {
                    ...prevState,
                    request
                }
            },() => {this.validateField(name, value)})
        }
    }

    render() {
        const file = this.state.request.ptDoc ? this.state.request.ptDoc.value : null;
        const {ptDocDetails, onCancel} = this.props;
        const requestedChanges = ptDocDetails.requestedChanges ? ptDocDetails.requestedChanges[ptDocDetails.requestedChanges.length-1] : null;
        return ( 
            <div className="modal__container">
                <div className="modal__wrapper px-0 py-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title w-100">
                                {`Edit ${this.draftType}`}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={onCancel} aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form
                                className="trades-dtls__doc-wrapper trades-dtls__invoice-wrapper"
                                onSubmit={this.onSubmitHandler}
                                style={{padding: '32px'}}>
                                    {ptDocDetails.ptType === 'document' && <div className="form-group">
                                        <label className="label">
                                            {this.state.request.ptDoc ? this.state.request.ptDoc.label : ''}<span className="req-field">&nbsp;*</span>
                                        </label>
                                        <div className={`form-control pointer ${this.state.required.hasOwnProperty('ptDoc') ? 'input_error is-invalid' : this.state.request.ptDoc && this.state.request.ptDoc.touched ? 'is-valid' : ''}`}>
                                            <Dropzone
                                                className="input-group"
                                                activeClassName="upload__active"
                                                accept={'application/pdf'}
                                                onDrop={(filesAccept, filesNotAccept) => {
                                                    if(filesAccept && filesAccept.length !== 0){
                                                        this.setField('ptDoc', filesAccept);
                                                    }else{
                                                        this.setField('ptDoc', filesNotAccept);
                                                    } 
                                                }}
                                            >
                                                {file ? file[0].type === 'application/pdf' ? <FaRegFilePdf className="icon" /> : <FaRegFile className="icon" /> : null}
                                                {file ? <p className="file">{file[0].name}</p> : <p>Choose File (or Drop)</p>}
                                            </Dropzone>
                                        </div>
                                        <small className={Object(this.state.required).hasOwnProperty('ptDoc') ? 'trades-dtls__error-message' : ''}>
                                            * Only files with *.pdf extension are allowed.
                                        </small>
                                    </div>}
                                    {ptDocDetails.ptType === 'text' && <React.Fragment>
                                        <div className="form-group">
                                            <FormTextareaField
                                                name="ptText"
                                                type="text"
                                                value={this.state.request.ptText ? this.state.request.ptText : {}}
                                                onChange={(event) => this.setField(event.target.name, event.target.value)}
                                                rows="4"
                                                validation={this.state.required}
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}/>
                                        </div>
                                    </React.Fragment>}
                                    <div className="form-group">
                                            <FormTextareaField
                                                name="additionalInformation"
                                                type="text"
                                                value={this.state.request.additionalInformation ? this.state.request.additionalInformation : {}}
                                                onChange={(event) => this.setField(event.target.name, event.target.value)}
                                                rows="4" 
                                                validation={this.state.required}
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}/>
                                    </div>
                                    <div className="form-group">
                                        <FormTextareaField
                                            name="requestedChanges"
                                            type="text"
                                            value={{
                                                label: 'Requested Changes',
                                                required: false,
                                                value: requestedChanges.text
                                            }}
                                            onChange={() => {}}
                                            rows="4"
                                            disabled={true}/>
                                    </div>
                            </form>
                        </div>
                        <div className="modal-footer" >
                            <button className={`btn-popup btn-cancel`} type="button" onClick={this.props.onCancel} >
                                <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                            </button>
                            <button 
                                className={`btn-popup btn-submit`}  
                                type="button" 
                                onClick={this.onSubmitHandler}
                                disabled={this.props.loading}>
                                    <Preloader loadingStyle="dots" loading={this.props.loading}>
                                        <FontAwesomeIcon icon={faCheck} />&nbsp;Submit
                                    </Preloader>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default UpdatePTDoc;