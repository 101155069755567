import React from 'react';
import moment from 'moment';
import { EnumsService, PortService } from '../../../../../services/service.utils';
import { RiceService } from '../../../../../services/service.rice';
import DetailTable from '../../../requests/detail/DetailTable';
import DownloadDocument from '../../../requests/components/DownloadDocument';
import { 
    renderDocumentsTitle, 
    renderDocuments, 
    renderCountry,
    renderPrice 
} from '../../../requests/detail/detailTable/Renderers';

import TradeQuantityFormatter from '../../../../components/Formatter/TradeQuantityFormatter';
import NumberFormat from 'react-number-format';
import {
    DISCHARGE_VALUES,
    PACKAGING,
    SHIPPINGTYPES,
    PAYMENTPERIODOPT,
    CURRENCYOPT,
    DATEFORMATHOURS,
    DATEFORMAT,
    getDischargeRateLabel,
    getDischargeLabel,
    RICECATEGORY_LABEL,
    TOOLTIP_TITLE
} from '../../../../../services/service.values';
//import {DOCUMENT_NAMES, REQUIRED_DOCUMENTS, REQUIRED_DOCUMENTS_CIF} from '../../../trades/services/documents.service';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const Countries = EnumsService.countries();

function TemplateTender({ trade, user, bankRejected, lastAmendment, showMultiOriginPrices }) {

    /**
     * RENDERERS
     */

    const isTradeOwner = () => {
        return user.companyId === trade.ownerId
    }

    const renderBankDetails = (bankName, serie) => {
        const isBankOnly = serie.data.status === 'BANK_ONLY_COUNTERED';
        const RTS_SELLER = trade.requestType === 'SELL' && isTradeOwner;
        const RTS_BUYER = trade.requestType === 'SELL' && !isTradeOwner;
        const isBidOwner = serie.data.fromCompanyID === user.companyId;
        const isRejectedBank = isBankOnly && ((RTS_SELLER && isBidOwner) || (RTS_BUYER && !isBidOwner));
        if (bankName && isRejectedBank) {
            return (
                <>
                    <del>{bankName}</del>
                </>
            )
        }
        return bankName;
    }

    const renderAcceptedAt = (value, serie) => {
        //Don't display on the first column 
        if (serie.data.acceptedByCompanyId) {
            return moment(value).format(DATEFORMATHOURS)
        } else {
            return "-"
        }
    }

    const renderDeclineAt = (value, serie) => {
        //Don't display on the first column 
        if (serie.data.declinedByCompanyId) {
            return moment(value).format(DATEFORMATHOURS)
        } else {
            return "-"
        }
    }

    const renderPortLabel = value => {
        let label = "";
        if (value) {
            label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
        }
        return label;
    }

    const formatPackagingOption = (value, serie) => formatOption(value, serie.data["optionsPackagingTypePremOrDiscount"], serie.data.currency);
    const formatQualityOption = (value, serie) => formatOption(value, serie.data["optionsQualityTypePremOrDiscount"], serie.data.currency);
    const formatShippingOption = (value, serie) => formatOption(value, serie.data["optionsShippingTypePremOrDiscount"], serie.data.currency);
    const formatTypeOfRiceOption = (value, serie) => formatOption(value, serie.data["optionsTypeOfRicePremOrDiscount"], serie.data.currency);
    const formatOption = (optionType, discountValue, discountCurrency) => {
        let sign = (discountValue > 0) ? '+' : '';
        return optionType ? <React.Fragment><div>{optionType}</div><div>{`${sign}${discountValue} ${CURRENCYOPT[discountCurrency]}`}</div></React.Fragment> : "-";
    }

    const formatMultiOriginPrice = (value, serie) => {
        return value.map(item => (
            <NumberFormat
                key={item.origin}
                value={item.isPriceCheck ? item.price : '-'}
                displayType='text'
                renderText={text => <div>{text}</div>}
                thousandSeparator={item.isPriceCheck}
                suffix={item.isPriceCheck ? ` ${CURRENCYOPT[serie.data.currency] || serie.data.currency} (${Countries[item.origin]})` : ''}
            />
        ))
    }

    const formatMultipleOriginLoadPorts = (value) => {
        return value.map(item => <div key={item.origin}>{item.loadCountry ? `${Countries[item.loadCountry]} (${renderPortLabel(item.loadPort)})` : '-'}</div>)
    }

  const  getInspectionCompanyName = (companyId, serie) => {
            if (serie.data.inspection === "1") {
                return <>
                    <Tooltip title={TOOLTIP_TITLE.NON_INSPECTOR_TITLE} placement="top-start" arrow>
                        <span>{serie.data.inspectionOther}&nbsp;
                        <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} /></span>
                    </Tooltip>
                </>
            }
            else {
                return serie.data.inspectionName;
            }
    }

    const fieldMap = {
        createdAt: { key: "createdAt", label: "Date", highlightDiff: false, format: value => moment(value).format(DATEFORMATHOURS), trClass: "table-warning" },
        fromCompanyName: { key: "fromCompanyName", label: "From", highlightDiff: false, format: (value, serie) => serie.data.fromCompanyName ? serie.data.fromCompanyName : serie.data.requestType === "BUY" ? serie.data.buyer : serie.data.seller, trClass: "table-warning" },
        declinedAt: { key: "declinedAt", label: "Declined At", highlightDiff: false, format: renderDeclineAt, trClass: "table-warning" },
        declinedByCompanyName: { key: "declinedByCompanyName", label: "Declined By", highlightDiff: false, format: (value, serie) => value ? <strong>{value} ({serie.data.declinedByUser})</strong> : "-", trClass: "table-warning" },
        declineReason: { key: "declineReason", label: "Declined Reason", highlightDiff: false, trClass: "table-warning" },
        acceptedAt: { key: "acceptedAt", label: "Accepted At", highlightDiff: false, format: renderAcceptedAt, trClass: "table-warning" },
        acceptedByCompanyName: { key: "acceptedByCompanyName", label: "Accepted By", highlightDiff: false, format: (value, serie) => value ? <strong>{value} ({serie.data.acceptedByUserName})</strong> : "-", trClass: "table-warning" },
        fields: { key: "fields", label: "Field names", format: (value) => "", tdClass: "bg-light font-weight-bold", trClass: "table-light" },
        multipleOriginPrice: { key: "multipleOriginPrice", label: "Price per MT(Origin)", "customStyle": "row-price", format: formatMultiOriginPrice },
        price: { key: "price", label: "Price", "customStyle": "row-price", format: renderPrice },
        riceType: { key: "riceType", label: "Type of Rice", format: value => (RiceService.getByRef(value) ? RiceService.getByRef(value).label : value) },
        riceTypeOther: { key: "riceTypeOther", label: "Specify Type of Rice" },
        riceCategory: { key: "riceCategory", label: "Rice Category", format: value => (RICECATEGORY_LABEL[value] || value) },
        incoterm: { key: "incoterm", label: "Incoterms" },
        shipping: { key: "shipping", label: "Shipping", format: value => (SHIPPINGTYPES[value] || value) },
        partialShipmentFlag: { key: "partialShipmentFlag", label: "Partial Shipment Allowed", format: value => value ? "Yes" : "No" },
        inspection: { key: "inspectionName", label: "Inspection Company", format: getInspectionCompanyName},
        measure: { key: "measure", label: "Quantity(MT)", format: (value, serie) => <TradeQuantityFormatter trade={serie.data} suffix={" Metric Ton" } /> },
        tolerance: { key: "tolerance", label: "Contractual Tolerance", format: (value, serie) => !value ? '-' : value === 0 ? `Min/Max in option of ${serie.data.incoterm === "FOB" ? "Buyer" : "Seller"}` : `+/- ${value}% in option of ${serie.data.incoterm === "FOB" ? "Buyer" : "Seller"}` },
        quality: { key: "quality", label: "Quality Standard" },
        packaging: { key: "packaging", label: "Packaging", format: value => (PACKAGING[value] || value) },
        euroConversionAvl: { key: "euroConversionAvl", label: "Euro Conversion Available", format: (value) => { return (value) ? "Yes" : "No" } },
        origin: { key: "origin", label: "Origin", format: renderCountry },
        multiOriginLoadPort: { key: "multipleOriginPrice", label: "Country of Load (Port of Load)", format: formatMultipleOriginLoadPorts },
        loadCountry: { key: "loadCountry", label: "Country of Load", format: renderCountry },
        loadPort: { key: "loadPort", label: "Port of Load", format: renderPortLabel },
        destCountry: { key: "destCountry", label: "Country of Destination", format: renderCountry },
        destPort: { key: "destPort", label: "Port of Destination", format: renderPortLabel },
        deliveryStartDate: { key: "deliveryStartDate", label: "Delivery Start Date", format: (value) => moment(value).format(DATEFORMAT) },
        deliveryEndDate: { key: "deliveryEndDate", label: "Delivery End Date", format: (value) => moment(value).format(DATEFORMAT) },
        discharge: { key: "discharge", label: getDischargeLabel(trade.incoterm), format: (value) => DISCHARGE_VALUES[value]},
        dischargeRate: { key: "dischargeRate", label: getDischargeRateLabel(trade.incoterm, trade.discharge), format: (value) => <NumberFormat value={value} displayType={'text'} thousandSeparator={true} /> },
        cropYear: { key: "cropYear", label: "Crop Year" },
        payment: { key: "payment", label: "Payment Terms" },
        paymentPeriod: { key: "paymentPeriod", label: "Payment Period", format: (value => PAYMENTPERIODOPT[value]) },
        nameOfBank: { key: "nameOfBank", label: "Name of Bank", format: renderBankDetails },
        locationOfBank: { key: "locationOfBank", label: "Location of Bank", format: renderBankDetails },
        swiftCodeOfBank: { key: "swiftCodeOfBank", label: "Swiftcode of Bank", format: renderBankDetails },
        nameOfConfirmingBank: { key: "nameOfConfirmingBank", label: "Name of Confirming Bank", format: renderBankDetails },
        locationOfConfirmingBank: { key: "locationOfConfirmingBank", label: "Location of Confirming Bank", format: renderBankDetails },
        swiftCodeOfConfirmingBank: { key: "swiftCodeOfConfirmingBank", label: "Swiftcode of Confirming Bank", format: renderBankDetails },
        downPaymentPercentage: { key: "downPaymentPercentage", label: "Down Payment", format: (value) => (value) ? `${value}%` : "NO" },
        downPaymentPeriod: { key: "downPaymentPeriod", label: "Down payment to be effected within (days counting from proforma invoice date)" },
        LCOpeningDate: { key: "LCOpeningDate", label: "Letter of Credit to be Opened Latest On", format: value => value ? moment(value).format(DATEFORMAT) : "" },
        ADIssuingDate: { key: "ADIssuingDate", label: "Avalised Draft to be issued latest on", format: value => value ? moment(value).format(DATEFORMAT) : "" },
        optionTitle: { key: "optionTitle", label: "Options", format: value => "", tdClass: "bg-light font-weight-bold" },
        optionsPackagingType: { key: "optionsPackagingType", label: "Packaging", format: formatPackagingOption },
        optionsQualityType: { key: "optionsQualityType", label: "Quality Standard", format: formatQualityOption },
        optionsShippingType: { key: "optionsShippingType", label: "Shipping", format: formatShippingOption },
        optionsTypeOfRice: { key: "optionsTypeOfRice", label: "Type of Rice", format: formatTypeOfRiceOption },
        documents: { key: "documents", label: "Documents", format: value => "", tdClass: "bg-light font-weight-bold" },
        optionalDocuments: { key: "optionalDocuments", label: renderDocumentsTitle(), format: renderDocuments },
        specialRequestTitle: { key: "specialRequestTitle", label: "Special Request", format: value => "", tdClass: "bg-light font-weight-bold" },
        specialRequest: { key: "specialRequest", label: "Content", format: value => value ? value : "NO" },
    }

    const fieldList = [
        ...(showMultiOriginPrices ? [fieldMap.multipleOriginPrice] : [fieldMap.price]),
        ...(trade.riceCategory !== "NON-ORGANIC" ? [fieldMap.riceCategory] : []),
        fieldMap.riceType,
        ...(trade.riceTypeOther ? [fieldMap.riceTypeOther] : []),
        fieldMap.incoterm,
        fieldMap.shipping,
        fieldMap.partialShipmentFlag,
        fieldMap.inspection,
        fieldMap.measure,
        fieldMap.tolerance,
        fieldMap.quality,
        fieldMap.packaging,
        ...(trade.privacyType !== "TENDER" ? [fieldMap.euroConversionAvl] : []),
        ...(showMultiOriginPrices ? [fieldMap.multiOriginLoadPort] : [fieldMap.origin]),
        fieldMap.loadCountry,
        fieldMap.loadPort,
        fieldMap.destCountry,
        fieldMap.destPort,
        fieldMap.discharge,
        fieldMap.dischargeRate,
        fieldMap.cropYear,
        fieldMap.payment,
        fieldMap.paymentPeriod,
        ...(trade.LCOpeningDate ? [fieldMap.LCOpeningDate] : []),
        ...(trade.ADIssuingDate ? [fieldMap.ADIssuingDate] : []),
        fieldMap.nameOfBank,
        fieldMap.locationOfBank,
        fieldMap.swiftCodeOfBank,
        fieldMap.nameOfConfirmingBank,
        fieldMap.locationOfConfirmingBank,
        fieldMap.swiftCodeOfConfirmingBank,
        fieldMap.downPaymentPercentage,
        fieldMap.downPaymentPeriod,
        ...(trade.privacyType !== "TENDER" ? [fieldMap.optionTitle, fieldMap.optionsPackagingType, fieldMap.optionsQualityType, fieldMap.optionsShippingType, fieldMap.optionsTypeOfRice] : []),
        fieldMap.documents,
        fieldMap.optionalDocuments,
        fieldMap.specialRequestTitle,
        fieldMap.specialRequest
    ]

    return (<div className="PDF-wrapper mx-3">
        <div>
            <span className="trades-dtls__contract-text">Contract template for Tender</span>
            <span className="trades-dtls__contract-date text-right">{`${(trade.acceptedAt && trade.acceptedAt !== "0001-01-01T00:00:00Z") ? moment(trade.acceptedAt).format(DATEFORMAT) : ""}`}</span>
        </div>
        <h4 className="trades-dtls__contract-heading">
            Contract REF ID: {trade.tradeRefId || trade.id}
        </h4>
        {lastAmendment && <div className="trades-dtls__contract-subheading">
            {`(Amended in Mutual Agreement on ${moment(lastAmendment.acceptedAt).format(DATEFORMATHOURS)})`}
        </div>}
        <section className="section">
            <span className="trades-dtls__contract-item">1. Seller</span>
            <div className="trades-dtls__contract-text">
                <span><strong>Company : </strong></span>{trade.seller}<br />
                <span><strong>Address : </strong></span><br />
                <span>&nbsp;Street : </span>
                {trade.sellerAddress1 && <span>
                    {trade.sellerAddress1.line1 ? trade.sellerAddress1.line1 : ''}<br />
                    {trade.sellerAddress1.line2 ? trade.sellerAddress1.line2 : ''}<br />
                </span>}
                <span>&nbsp;City : </span>{trade.sellerAddress1 ? trade.sellerAddress1.city : ''}<br />
                <span>&nbsp;Zip Code : </span>{trade.sellerAddress1 ? trade.sellerAddress1.postal : ''}<br />
                <span>&nbsp;Country : </span>{trade.sellerAddress1 ? Countries[trade.sellerAddress1.country] : ''}<br />
                <br />
                <span><strong>User : </strong></span>{trade.sellerUser}<br />
            </div>
        </section>
        <section className="section">
            <span className="trades-dtls__contract-item">2. Buyer</span>
            <div className="trades-dtls__contract-text">
                <span><strong>Company : </strong></span>{trade.buyer}<br />
                <span><strong>Address : </strong></span><br />
                <span>&nbsp;Street : </span>
                {trade.sellerAddress1 && <span>
                    {trade.buyerAddress1.line1 ? trade.buyerAddress1.line1 : ''}<br />
                    {trade.buyerAddress1.line2 ? trade.buyerAddress1.line2 : ''}<br />
                </span>}
                <span>&nbsp;City : </span>{trade.buyerAddress1 ? trade.buyerAddress1.city : ''}<br />
                <span>&nbsp;Zip Code : </span>{trade.buyerAddress1 ? trade.buyerAddress1.postal : ''}<br />
                <span>&nbsp;Country : </span>{trade.buyerAddress1 ? Countries[trade.buyerAddress1.country] : ''}<br />
                <br />
                <span><strong>User : </strong></span>{trade.buyerUser}<br />
            </div>
        </section>
        <section>

        </section>
        <span className="trades-dtls__contract-item">3. Contract Terms</span>
        <div className="trades-dtls__contract-text">
            <DetailTable
                fixWidth={true}
                series={[{ title: 'Field Value', data: trade }]}
                fieldList={fieldList}>
            </DetailTable>
        </div>
        <section>
            <div className="trades-dtls__contract-text">
                All other terms and conditions, if not in contradiction with the above ,  as per tender terms herewith attached”
                  <span>  <DownloadDocument doc={trade.tenderTermsDoc[0]} /></span>
            </div>

        </section>


    </div>
    )
}

export default TemplateTender;