import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import FormInputField from './form/FormInputField';
import Preloader from './preloader/Preloader';
import { verifyAuthorisationCode } from '../../modules/module.trade';

class AuthorisationCodeModal extends Component {
    state = {
        saveData: {
            code: ""
        },
        errors: {},
        inProgress: false
    };

    saveCode = (e) => {
        this.setState({ ...this.state, saveData: { code: e.target.value } });
    }

    validate = () => {
        if (this.state.saveData.code === "") {
            this.setState({ ...this.state, errors: { code: this.state.saveData.code } });
            return false;
        }
        if (this.state.saveData.code.length !== 4) {
            this.setState({ ...this.state, errors: { invalidLength: true } });
            return false;
        }
        this.setState({ ...this.state, errors: {} });
        return true;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validate()) {
            this.setState({ ...this.state, inProgress: true });

            verifyAuthorisationCode(this.state.saveData).then(() => {
                this.props.closeModal(true);
                this.setState({ ...this.state, saveSuccess: true, inProgress: false });
            }, () => {
                this.setState({ ...this.state, errors: { invalidCode: true }, inProgress: false });
            }).catch(() => {
                this.setState({ ...this.state, errors: { invalidCode: true }, inProgress: false });
            });
        }
    }

    render() {
        return (
            <div className="modal__container">
                <form  autoComplete='off' 
                    className="modal__wrapper px-0 py-0 authcode"
                    onSubmit={this.handleSubmit}
                >
                    <div className="modal-content ">
                        <div className="modal-header                                                                                                                                               ">
                            <div className="modal-header-center">
                                 <h5 className="modal-title create-heading">Verify Authorisation Code</h5>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                                 </div>
                            <button type="button" className="close" data-dismiss="modal" onClick={e => this.props.closeModal(false)} aria-label="Close">                                                                                                                                                                                                                                                                    
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.state.errors.invalidLength ? (
                                <div className="alert alert-danger alert-sm" role="alert">
                                Authorisation Code should be 4 digits.
                              </div>
                               
                            ) : ''}
                            {this.state.errors.invalidCode ? (
                                  <div className="alert alert-danger alert-sm" role="alert">
                                 Wrong Authorisation Code. Please try again.
                                </div>
                               
                            ) : ''}
                            <div className="create-req__wrapper mb-0">
                                <div className="form-input">
                                    <label className="label">Enter Authorisation Code</label>
                                    <FormInputField
                                        type="password"
                                        validation={this.state.errors}
                                        name="code"
                                        required={true}
                                        maxlength="4"
                                        value={this.state.saveData.code}
                                        onChange={this.saveCode}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                    />                                              
                                </div>     
                            </div>     
                        </div>    
                       
                        <div className="modal-footer pt-0">
                            <button type="button" onClick={e => this.props.closeModal(false)} className="btn-popup btn-cancel ">
                                <FontAwesomeIcon icon={faTimes} /> Cancel
                                </button>
                            <button type="submit" className="btn-popup btn-submit">
                                <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                    <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Verify</span>
                                </Preloader>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default AuthorisationCodeModal;