export const CompanyTypes = {
    EXPORTER: "EXPORTER",
    IMPORTER: "IMPORTER",
    DISTRIBUTOR: "DISTRIBUTOR",
    TRADER: "TRADER",
    BANK: "BANK",
    INSURANCE: "INSURANCE",
    INSPECTION: "INSPECTION",
    FUMIGATION: "FUMIGATION",
    STEVEDORING: "STEVEDORING",
    SHIPPING: "SHIPPING",
    CUSTOMS_BROKER: "CUSTOMS_BROKER"
};
