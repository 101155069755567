import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import {DATEFORMAT, quoteModalsReactSelectStyle} from '../../../services/service.values';
import {TradeQuotesError} from '../../../services/service.errors';
import FormDateField from '../../components/form/FormDateField';
import FormSelectDropdown from '../../components/form/FormSelectDropdown';
import FormAsyncSelectPort from '../../components/form/FormAsyncSelectPort';
import FCLFormSelector from './FCLFormSelector';
import {
    EnumsService,
    PortService,
    getSelectedCountries
} from '../../../services/service.utils';
import { getPriceQuoteContainer, resetForm } from '../../../modules/module.shipmentQuote';

import Preloader from '../../components/preloader/Preloader';
//ICONS
import { faTimes,faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import TradeQuantityFormatter from '../../components/Formatter/TradeQuantityFormatter';


class RequestModal extends React.Component{

    CUSTOM_COMPONENT_STYLE = {
        wrapperClassName: "form-group",
        labelClassName: "label",
        inputClassName: "form-control",
        selectClassName: "custom-select",
    }

    constructor(props){
        super(props);
        const origins = this.props.trade.items.single.origin.split(',');
        this.state = {
            departureDate:moment(this.props.trade.items.single.deliveryStartDate),
            originCountry: origins.length > 1 ? '' : this.props.trade.items.single.origin,
            originPort: origins.length > 1 ? '' : this.props.trade.items.single.loadPort,
            destinationPort: this.props.trade.items.single.destPort,
            shipping:"",
            noOfContainer: "",
            Error:"",
            portError:"",
            companyError:"",
            containerError:"",
            originPortError:"",
            companyApprove:"",
        };
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleOnClose = this.handleOnClose.bind(this);
    }

    setField = (name, value) => {
        this.setState({
            [name]: value,
            Error:"",
            portError:"",
            companyError:"",
            containerError:"",
            originPortError:"",
            companyApprove:"",
            originCountryError: ""
        })
        if(name === "destinationCountry"){
            var list= PortService.getAsList(value);
            if(list.length===0){
                this.setState({portError: TradeQuotesError.NO_SERVICE_AVAILABLE});
            }
        }
    }

    handleOnChange = (e) => {
        this.setField(e.target.name, e.target.value);
    }

    handleOnSubmit = (e) => {
        e.preventDefault();
        const {
            departureDate, 
            originCountry, 
            originPort, 
            destinationPort, 
            shipping, 
            noOfContainer, 
            destinationCountry
        } = this.state;
        const trade = this.props.trade.items.single;
       
        if(!originCountry){
            this.setState({originCountryError: "Please select origin"});
            return false;
        }

        if(originPort){
            if(PortService.getAsList(this.state.originCountry).length === 0){
                this.setState({originPortError: TradeQuotesError.NO_SERVICE_AVAILABLE});
                return false;
            }
        }else{
            this.setState({originPortError: "Please select port"});
            return false;
        }
            
        if(trade.incoterm === "FOB"){
            if(!destinationCountry){
                this.setState({Error: "Please select country"});
                return false;
            }

            if(destinationPort){
                if(PortService.getAsList(this.state.destinationCountry).length === 0){
                    this.setState({portError: TradeQuotesError.NO_SERVICE_AVAILABLE});
                    return false;
                }
            }else{
                this.setState({portError: "Please select port"});
                return false;
            }
        }

        if(!shipping){
            this.setState({companyError: "Please select company"});
            return false;
        }

        if(!noOfContainer && this.props.shipmentQuote.shipping[this.state.shipping] === "Maersk"){
            this.setState({containerError: "Please enter MT per FCL"});
            return false;
        }

        let selectedInspectionCompany = this.props.shipmentQuote.shippingCompanies.find(company => company.ID === shipping);
        if (selectedInspectionCompany && !selectedInspectionCompany.serviceProviderFlag) {
            this.setState({companyApprove: TradeQuotesError.SHIPPING_COMPANY_NOT_ON_LIST});
            return false;
        }

        const requestBody = {
            tradeRefID: trade.tradeRefId,
            quantity: trade.measure,
            incoterm: trade.incoterm,
            deliveryDate: departureDate.format('YYYY-MM-DD'),
            toCompanyId: shipping,
            toCompanyName: this.props.shipmentQuote.shipping[shipping],
            noOfContainer: noOfContainer,
            quoteType: this.props.quoteType,
            tradeType: trade.buyerId && trade.sellerId ? "Private" : "Public",
            deliveryDateTo : trade.deliveryStartDate,
		    deliveryDateFrom: trade.deliveryEndDate,
		    packaging: trade.packaging,
		    discharge : trade.discharge,
		    dischargeRate : trade.dischargeRate,
        }
        this.props.getPriceQuoteContainer(departureDate.format('YYYY-MM-DD'), originPort, destinationPort, trade.id, requestBody)
    }

    handleOnClose = (e) => {
        this.props.resetForm();
        this.props.onClose(e);
    }

    render(){
        const { shipmentQuote, onRequestMoreDetails } = this.props;
        const trade = this.props.trade.items.single;
        //const availableOriginPorts =  PortService.getAsList(this.state.originCountry || trade.origin.split(','));

        return (<div className="modal__container">
        <form  className="modal__wrapper px-0 py-0"  onSubmit={this.handleOnSubmit}>
            <div className="modal-content"> 
                <div className="modal-header">
                    <div className="modal-header-center">
                        <h5 className="modal-title">Request a {this.props.quoteType==="PROVISIONAL" ? "Provisional" : "Final"} Quote for Shipment via Container</h5>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" onClick={this.handleOnClose} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                    <div className="modal-body provisional-Quote">
                        <p> Your Request is being processed using an automated service (API) to the Service Provider. Please
                            press 'Confirm' to continue or click 'Back' to return previous screen. Note that the information
                            listed next to the text box is the information that is automatically added to your request.</p>
                    <div className="row pt-3">
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Trade Ref Id</div>
                                <div className="trades-header-value">{trade.tradeRefId || trade.id}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Incoterm</div>
                                <div className="trades-header-value">{trade.incoterm}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Quantity (MT)</div>
                                <div className="trades-header-value"> <TradeQuantityFormatter trade = {trade}/> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Packaging</div>
                                <div className="trades-header-value">{trade.packaging}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Shipment</div>
                                <div className="trades-header-value">{trade.shipping}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Origin Country</div>
                                {
                                    !trade.origin.includes(',') ? 
                                        <div className="trades-header-value">
                                            {EnumsService.countries()[trade.origin]}
                                        </div>
                                    :
                                    <div className="trades-header-value">
                                        <FormSelectDropdown 
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            className="select"
                                            name="originCountry"
                                            placeholder="Origin Country"
                                            items={getSelectedCountries(trade.origin.split(','))}
                                            onSelect={(event) => {
                                                this.handleOnChange(event);
                                                this.setState({originPort: ''})
                                            }}
                                            value={{
                                                value: this.state.originCountry
                                            }}/>
                                    </div>
                                }
                                {this.state.originCountryError &&
                                        <div className="counterparty__error">{this.state.originCountryError}</div>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Origin Port</div>
                                    <div className="trades-header-value">
                                        <FormAsyncSelectPort 
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            fieldData={{
                                                name: "originPort",
                                                value:this.state.originPort
                                            }}
                                            onChange={(name, value, fromDropdown) => {
                                                this.setField(name, value);
                                                if(fromDropdown){
                                                    this.setState({originCountry: value.slice(0, 2)})
                                                }
                                            }}
                                            country={this.state.originCountry || trade.origin.split(',')}
                                            menuPlacement="top"
                                            styles={quoteModalsReactSelectStyle}
                                        />
                                    </div>
                                    {this.state.originPortError &&
                                        <div className="counterparty__error">{this.state.originPortError}</div>}
                                </div>
                        </div>
                        {(trade.incoterm==="CIF"|| trade.incoterm==="CFR") &&
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Destination Country</div>
                                <div className="trades-header-value">{EnumsService.countries()[trade.destCountry]}</div>
                            </div>
                        </div>}
                        {trade.incoterm==="FOB" &&
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Destination Country</div>
                                <div className="trades-header-value">
                                    <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        validation={this.state.required}
                                        className="select"
                                        name="destinationCountry"
                                        items={EnumsService.countries()}
                                        onSelect={this.handleOnChange}
                                        value={{value:this.state.destinationCountry}}
                                        placeholder="Country"
                                        />
                                        
                                </div>
                                {this.state.Error &&
                                    <div className="counterparty__error">{this.state.Error}</div>}
                            </div>
                        </div>}
                        {(trade.incoterm==="CIF"|| trade.incoterm==="CFR") &&
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Destination Port</div>
                                <div className="trades-header-value">{PortService.getName(trade.destPort)}</div>
                            </div>
                        </div>}
                        {(trade.incoterm === "FOB")  &&
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Destination Port</div>
                                    <div className="trades-header-value">
                                        <FormAsyncSelectPort 
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            fieldData={{
                                                value: this.state.destinationPort,
                                                name: "destinationPort"
                                            }}
                                            onChange={(name, value, fromDropdown) => {
                                                this.setField(name, value);
                                                if(fromDropdown){
                                                    this.setField("destinationCountry", value.slice(0, 2));
                                                }
                                            }}
                                            country={this.state.destinationCountry}
                                            menuPlacement="top"
                                            styles={quoteModalsReactSelectStyle}
                                        />
                                    </div>
                                    {this.state.portError && <div className="counterparty__error">{this.state.portError}</div>}
                                </div>
                            </div>}

                        {shipmentQuote.shipping &&
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Shipping Company</div>
                                <div className="trades-header-value">
                                    <FormSelectDropdown
                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                        name="shipping"
                                        items={shipmentQuote.shipping}
                                        validation={this.state.required}
                                        value={{value:this.state.shipping}}
                                        onSelect={this.handleOnChange}
                                        showDefault={true}/>
                                </div>
                                {this.state.companyError &&
                                    <div className="counterparty__error">{this.state.companyError}</div>}
                            </div>
                        </div>}
                        <div className="col-md-6">
                            <div className="trades-header-wrapper">
                                <div className="trades-header-label">Shipping Date</div>
                                <div className="trades-header-value">
                                    <FormDateField dateFormat={DATEFORMAT}
                                        required={true}
                                        validation=""
                                        name="departureDate"
                                        minDate={moment()}
                                        item={{ value: this.state.departureDate}}
                                        time={false}
                                        label={false}
                                        showTimeSelect={false}
                                        onSelect={(name, date) => this.handleOnChange({target: { name: "departureDate", value: date}})}/>
                                </div>
                            </div>
                        </div>

                    
                    </div>
                    { shipmentQuote.shipping[this.state.shipping] && shipmentQuote.shipping[this.state.shipping].match("Maersk") &&
                    <React.Fragment>
                        <h5 className="font-weight-normal display-5 pt-3 pb-3">Select number of FCL for <TradeQuantityFormatter trade={trade} /></h5>
                        <FCLFormSelector trade={trade} onChange={this.handleOnChange} Error={this.state.containerError} />
                    
                    </React.Fragment>}
                    {shipmentQuote.priceQuote.totalPrice  &&
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="font-weight-normal text-success display-5 mt-3">Estimated quote</h5>
                            <p>Click the button bellow to view Maersk spot offers: </p>
                            <button className="btn-popup btn-submit float-right" onClick={onRequestMoreDetails}>Maersk Spot offers</button>                    
                        </div>
                    </div>}
                    {shipmentQuote.error &&
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="font-weight-normal text-danger display-5 mt-3">Quote could not be retrieved</h5>
                            {shipmentQuote.error.message?
                                <React.Fragment>
                                    {shipmentQuote.error.message.status === 404 &&
                                    <span>No route schedule could be found</span> }
                                </React.Fragment>
                            :
                             <React.Fragment>
                                {shipmentQuote.error.error && <span>{shipmentQuote.error.error}</span>}
                                {shipmentQuote.error.status && <span>{shipmentQuote.error.status}</span>}  
                            </React.Fragment>} 
                            {(shipmentQuote.error.offerFilteredReasons) && 
                            <ul className='text-danger pl-3'>
                                {shipmentQuote.error.offerFilteredReasons.offerFilteredReasons.map(reason => ( <li>{reason.reason}</li>))}
                            </ul>}          
                        </div>
                    </div>}
                    <div className="pt-3">
                        <div className="stepwizard">
                            <div className="stepwizard-row setup-panel">
                                <div className="stepwizard-step col-xs-3">
                                    <span type="button" className={`btn btn-success btn-wizard-circle`}></span>
                                    <p><small>Initiating API</small></p>
                                </div>
                                <div className="stepwizard-step col-xs-3">
                                    <span className={`btn ${(shipmentQuote.requestQuotePending || shipmentQuote.priceQuote.totalPrice || shipmentQuote.error)?'btn-success':'btn-default bg-white'} btn-wizard-circle`}></span>
                                    <p><small>Checking Connection</small></p>
                                </div>
                                <div className="stepwizard-step col-xs-3">
                                    <span className={` btn ${shipmentQuote.priceQuote.totalPrice?'btn-success':shipmentQuote.error?'btn-warning':'btn-default bg-white'} btn-wizard-circle`}></span>
                                    <p><small>Retrieving Data</small></p>
                                </div>
                                <div className="stepwizard-step col-xs-3">
                                    <span className={`btn ${shipmentQuote.priceQuote.totalPrice?'btn-success':'btn-default bg-white'} btn-wizard-circle`}></span>
                                    <p><small>Displaying Data</small></p>
                                </div>
                            </div>    
                        </div> 
                        {this.state.companyApprove &&
                        <div className="popupCompany__error">{this.state.companyApprove}</div>} 
                    </div> 
                </div>
                    <div className="modal-footer">
                    <button onClick={this.handleOnClose} type="button" className="btn-popup btn-cancel "><FontAwesomeIcon icon={faTimes} />&nbsp;Close</button>
                        {!this.props.shipmentQuote.priceQuote.totalPrice &&
                        <button  type="submit" disabled={this.props.shipmentQuote.requestQuotePending} className="btn-popup btn-submit">
                            <Preloader loadingStyle="dots" loading={this.props.shipmentQuote.requestQuotePending}>
                                <FontAwesomeIcon icon={faCheck} />&nbsp;Request Quote
                            </Preloader>
                        </button>}
                    </div>
                </div>
            </form>
        </div>)
    }

}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        shipmentQuote: state.shipmentQuote,

    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getPriceQuoteContainer,
            resetForm,

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RequestModal);

  