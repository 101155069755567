import React, {useState,useRef, useMemo} from 'react';
import {RicexCustomFileLabel} from '../../../components/form/FormFileUpload.module.scss';
import { TradeApi } from '../../../../services';

import DownloadDocument from '../components/DownloadDocument';
import Preloader from '../../../components/preloader/Preloader';

const UploadTenderConditions = ({onChange, value, validation, name}) => {

    const isInvalid = validation && validation.hasOwnProperty(name);

    const inputFile = useRef(null);
    const [file, setFile] = useState();
    const [pending, setPending] = useState(false);
    const [fileError, setFileError] = useState("");
    const [isInValid, setIsInValid] = useState(false);

    const isValidType = useMemo(() => {
        if(file){
            return file.type === "application/pdf";
        }else{
            return true;
        }
    }, [file])

    const uploadFile = (e) => {
        e.preventDefault();
        e.persist();
        setPending(true);
        const formData = new FormData();
        formData.append("upload", file, file.name);
        TradeApi.postTradeImage(formData)
        .then(res => {
            if (res.status===200) {
                onChange([res.data]);
                setFileError("");
            }else{
                setFileError("An error occured")
            }
            setFile("");
            setPending(false);
        })
        .catch((error) => {
            if (process.env.NODE_ENV === 'development') {
                console.error(error);
            }  
            setPending(false);             
        });               
    };

    const resetForm = () => {
        inputFile.current.value = "";
        setFile("");
        setFileError("");
        setIsInValid(false);
        setPending(false);
        onChange("");
    }
    if(!value){
        return false;
    }
    return <>

        <label className="label mt-2">{value.label}
            <span className="req-field">{value && value.required ? '*' : ''}</span>
        </label> 
        <div className="form-row">
            <div className="col">
                {value.value.length !== 0 && <DownloadDocument doc={value.value[0]}/>}
                <div className={`custom-file ${value.value.length !== 0?'d-none':''}`}>
                    <input type="file" ref={inputFile}
                        hidden={value.value.length !== 0}
                        name={name}
                        className={`custom-file-input ${isInvalid ? 'is-invalid' :  value.touched || value.validated ? 'is-valid' : ''}`}
                        id="inputFile" 
                        aria-describedby="inputImage"
                        accept=".pdf" 
                        onChange={e => setFile(e.target.files[0])}/>
                    <label className={`custom-file-label ${RicexCustomFileLabel}`} 
                        htmlFor="inputImage">{ file? file.name : 'Choose file'}</label> 
                </div>
            </div>
            <div>
                {value.value.length !== 0 && 
                <button  type="button" className="btn btn-clear ml-1"
                    disabled={pending}
                    onClick={resetForm}>
                    <span>{"Remove"}</span>
                </button>}
                {file  && 
                <button  type="button" className="btn btn-upload ml-1"
                    disabled={pending || !isValidType}
                    onClick={uploadFile}>
                    <Preloader loading={pending} loadingStyle="dots">
                        <span>Confirm</span>
                    </Preloader>
                    
                </button>}
            </div>
        </div>
        <div className="mb-2">
            { isInValid &&
            <small id="helpFile" className="form-text text-danger">This document is not uploaded yet</small>}
            {fileError &&
            <small id="helpFile" className="form-text text-danger">{fileError}</small>}
            {value.value.length === 0 &&
            <small id="helpFile" className={`form-text text-muted ${isValidType?'':'text-danger'}`}>
                Only documents of types .pdf are accepted (max size 15MB)
            </small>}
        </div>
    </>

    

}

export default UploadTenderConditions; 