import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EnumsService } from '../../../../services';
import { authHeader, getDocumentFileUrl, TradeApi } from '../../../../services/service.api';
import moment from 'moment-timezone';
import { DATEFORMATHOURS } from '../../../../services/service.values';
import { Link } from 'react-router-dom';
import { OpenDocument } from '../../../../modules/module.trade';
import { push } from "connected-react-router";
import { LoadingStatus } from '../../../../modules/module.loading';
import DocumentaryInstructionsModal from '../modals/DocumentaryInstructionsModal';
import { DOCUMENT_NAMES } from '../services/documents.service';
import Preloader from '../../../components/preloader/Preloader';
import DetailedInfosModal from './DetailedInfosModal';
import PaymentTermDocumentModal from './PaymentTermDocumentModal';
import { viewSubstitueVesselInfo } from '../../trades/modals/ViewSubstitueVesselInfo';
import viewRejectedVesselInfo from '../../trades/modals/ViewRejectedVesselInfo';
import { substitueAuditLogs } from '../../../../modules/module.nomination';
import {
    PT_DOC_MODALS
} from '../services/documents.service';
import {
    getPTDocument,
    downloadPaymentTermDoc
} from '../../../../modules/module.trade';
import PreviewPTDoc from './ptDoc/PreviewPTDoc';
import MaterialTable from 'material-table';


import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ContractFulfillmentRejectReason from '../modals/ContractFulfillmentRejectReason';
import * as constants from '../../../../constants/viewRejectedVesselModalConstant'
const Countries = EnumsService.countries();

//bol -- Bill of Lading
class AuditLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            docInstructionsModal: {
                isOpened: false,
                data: null
            },
            previewDocuments: {
                files: [],
                name: null
            },
            loading: false,
            logDate: '',
            isContractInfo: false,
            showModal: false,
            updated: false,
            uploaded: true,
            modal: '',
            modalData: {},
            modalName: ''

        };
        this.openDocumentaryInstructionsModal = this.openDocumentaryInstructionsModal.bind(this);
        this.closeDocInstructionsModal = this.closeDocInstructionsModal.bind(this);
    }


    get trade() {
        return this.props.trade.items.single
    }

    get draftType() {
        return this.trade.payment === 'Letter of credit' ? 'L/C Draft' : this.trade.payment === 'Confirmed letter of credit' ? 'Confirmed L/C Draft' : 'Avalised Draft'
    }

    componentDidMount = () => {
        if (this.props.id) {
            this.setLoading(true);
            TradeApi.smart(this.props.id)
                .log()
                .then(d => {
                    this.setLoading(false);
                    d.data.items.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    });
                    this.setState({
                        items: d.data.items.reverse()
                    });
                });
        }

        let url = `/trade/${this.trade.id}/letterOfCredit`;
        if (this.trade.payment === 'Avalised Draft') url = `/trade/${this.trade.id}/avalizedDraft`;
        if (this.trade.payment === 'Confirmed letter of credit') url = `/trade/${this.trade.id}/confirmedLC`;
        this.props.getPTDocument(url)

        // this.props.loadRequestDetails(this.props.id);
    };

    setLoading = loading => {
        this.setState({ loading });
    };

    openSubstitueModal = (data) => {
        this.props.substitueAuditLogs(data);
        this.setState({ showModal: true, modalData: data, modal: 'SUBSTITUTE_VESSEL_APPROVED' });
    };


    openVesselRejectedModal = (comment, modalName) => {
        if (!comment) return
        const id = comment.split('@')[1]
        if (!id) return
        this.setState({ showModal: true, modalData: id, modal: modalName });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modalName: ''
        })
    }

    downloadPaymentTermHandler = (type) => {
        if (this.props.trade.ptDocument.ptType === 'document') {
            let cb = () => this.setState({
                showModal: true,
                modalName: PT_DOC_MODALS.PREVIEW_PT_DOC
            })
            let url = `/letterOfCredit/${this.props.trade.ptDocument.ptDocId}/downloadDoc`;
            if (this.trade.payment === 'Avalised Draft') url = `/avalizedDraft/${this.props.trade.ptDocument.ptDocId}/downloadDoc`;
            if (this.trade.payment === 'Confirmed letter of credit') url = `/confirmedLC/${this.props.trade.ptDocument.ptDocId}/downloadDoc`;
            this.props.downloadPaymentTermDoc(url, type, cb);
        } else {
            this.setState({
                showModal: true,
                modalName: PT_DOC_MODALS.PREVIEW_PT_DOC
            })
        }

    }

    viewSubstituteAuditInfo = () => {
        var substituteInfo = this.props.nomination.substituteAuditLog;
        var series = [];
        if (substituteInfo.length !== 0) {
            series = [
                { title: "Performing Vessel", data: { ...substituteInfo[0], inspectionName: this.props.trade.items.single.inspectionName } },
                { title: "Substitute Vessel", data: { ...substituteInfo[1], inspectionName: this.props.trade.items.single.inspectionName } }
            ]
        }
        return series;
    }

    renderSubstitueModal = () => {
        return viewSubstitueVesselInfo({
            onClose: this.closeModal,
            LoadingNominationInfo: this.props.LoadingNominationInfo,
            vesselInfo: this.viewSubstituteAuditInfo(),
            tradeRefId: this.props.trade.items.single.tradeRefId
        });
    }


    renderModal = () => {
        const trade = this.props.trade;
        const { shipping, measurement,incoterm,inspector } = trade.items.single;
        switch (this.state.modal) {
            case "SUBSTITUTE_VESSEL_APPROVED":
                return viewSubstitueVesselInfo({
                    onClose: this.closeModal,
                    LoadingNominationInfo: this.props.LoadingNominationInfo,
                    vesselInfo: this.viewSubstituteAuditInfo(),
                    tradeRefId: this.props.trade.items.single.tradeRefId
                });
            case "VESSEL_REJECTED": {
                return viewRejectedVesselInfo({
                    onClose: this.closeModal,
                    props: this.props,
                    vesselInfo: this.viewSubstituteAuditInfo(),
                    vesselId: this.state.modalData,
                    trade: trade,
                    url: constants.GET_VESSEL_NOMINATION_URL,
                    fieldList: (shipping === 'VESSEL' ? constants.vesselInfoFieldList : constants.containerInfoFieldList(measurement)),
                    vesselInfoHeader: (shipping === 'VESSEL' ? constants.View_Rejected_Vessel_Nomination : constants.View_Rejected_Container_Nomination)
                });
            }
            case "CONTRACT_FULFILLMENT_REJECTED":
                return (
                    <ContractFulfillmentRejectReason
                        rejectReason={this.state.modalData}
                        onClose={this.closeModal}
                    />
                )
            case "PT_DOC_UPDATED":
            case "PT_DOC_CHANGES":
                return (
                    <PaymentTermDocumentModal
                        onClose={this.closeModal}
                        ptDocDetails={this.props.trade.ptDocument}
                        downloadPaymentTermDoc={this.downloadPaymentTermHandler}
                        loading={this.props.loadingPTProcess}
                        trade={this.trade}
                        showRequestedChanges={this.state.modalName === 'PT_DOC_CHANGES'}
                    />
                )
            case PT_DOC_MODALS.PREVIEW_PT_DOC:
                return (
                    <PreviewPTDoc
                        ptDocument={this.props.trade.ptDocument}
                        paymentTerm={this.trade.payment}
                        onClose={this.closeModal}
                    />
                )
            case 'RENDER_REJECTED_SUBSTITUTE_VESSEL/CONTAINER':
                {
                    return viewRejectedVesselInfo({
                        onClose: this.closeModal,
                        props: this.props,
                        vesselId: this.state.modalData,
                        trade: trade,
                        url: constants.GET_REJECTED_SUBSTITUTE_NOMINATION_URL,
                        fieldList:  constants.rejectedSubstituteFieldList(shipping,incoterm,inspector),
                        vesselInfoHeader: (shipping === 'VESSEL' ? constants.View_Rejected_Substitute_Vessel_Nomination : constants.View_Rejected_Substitute_Container_Nomination)
                    });
                }

            default:
                return null;
        }
    }


    displayActionBy = item => {
        let parts = item.comment.split('@');
        switch (parts[0]) {
            case 'trade.contract.created':
            case 'trade.signed':
            case 'trade.confirmDocuments':
            case 'trade.instructions':
            case 'trade.advice':
            case 'trade.closed':
                return <span className="audit__overflow">System</span>;
            default:
                return (
                    <span className="audit__overflow">
                        {' '}
                        {item.author}
                        <br />
                        <small>{item.companyName}</small>
                    </span>
                );
        }
    };

    openDocumentaryInstructionsModal() {
        if (!this.state.docInstructionsModal.data) {
            this.props.LoadingStatus(true);
            TradeApi.smart(this.props.id)
                .getInstructions()
                .then(response => {
                    this.setState({
                        docInstructionsModal: {
                            isOpened: true,
                            data: response.data
                        }
                    })
                })
                .catch(error => {
                    console.error(error);
                    this.props.LoadingStatus(false);
                })
        } else {
            this.setState({
                docInstructionsModal: {
                    ...this.state.docInstructionsModal,
                    isOpened: true
                }
            })
        }
    }

    closeDocInstructionsModal = () => {
        this.setState({
            docInstructionsModal: {
                ...this.state.docInstructionsModal,
                isOpened: false
            }
        })
    };

    openPreviewDocument = (tradeId, shipmentId, files, name) => {
        const previewFiles = files.sort((a, b) => {
            return moment.utc(b.CreatedAt).diff(moment.utc(a.CreatedAt))
        }).slice(0, 2);
        this.setState({
            previewDocuments: {
                name,
                files: previewFiles.map(file => ({
                    id: file.ID,
                    url: getDocumentFileUrl(tradeId, shipmentId, file.ID),
                    httpHeaders: authHeader().headers
                }))
            }
        });
    };

    getDocumentsLink(docType, text, shipmentId = this.props.trade.shipments[0].id) {
        const docName = DOCUMENT_NAMES[docType] ? DOCUMENT_NAMES[docType] : docType;
        return <React.Fragment>
            {(docType === 'DocInstructionsID') ? (
                <a
                    href='#/'
                    className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                    onClick={this.openDocumentaryInstructionsModal}
                >
                    {docName}
                </a>
            ) : (
                    <Link
                        className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                        to={`/trades/details/${this.props.id}/documents?shipmentId=${shipmentId === null ? this.props.trade.shipments[0].id : shipmentId}`}
                    >
                        {docName}
                    </Link>
                )}
            {' '}
            {text}
        </React.Fragment>
    }

    navigateToLink = (linkText, path) => {
        return (
            <Link className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                to={path}>
                {linkText}
            </Link>
        );
    }

    displayAction(item) {
        if (item.comment) {
            let parts = item.comment.split('@');
            let linkToShippingQoute = (
                <Link className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                    to={`/ShippingQuoteDetails/${item.trade}/${parts[2]}`}>
                    Shipping Quote
                </Link>
            );
            let linkToFinalShippingOffer = (
                <Link className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                    to={`/ShippingQuoteDetails/${item.trade}/${parts[2]}`}>
                    Final Shipping Offer
                </Link>
            );
            let linkToInspectionqoute = (
                <Link className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                    to={`/inspectionQuoteDetails/${item.trade}/${parts[2]}`}>
                    Inspection Quote
                </Link>
            );
            let linkToInspectionReport = (
                <Link
                    className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                    to={`/trades/details/${item.trade}/inspection-reports?highlightReport=${parts[1]}`}
                >
                    {parts[2]}
                </Link>
            );
            switch (parts[0]) {
                case 'trade.created':
                    return (
                        <React.Fragment>
                            Trade Request created{' '}
                            <Link
                                className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                                to={`/requests/details/${parts[1]}`}
                            >
                                {item.tradeRefId ? item.tradeRefId : item.trade}
                            </Link>
                        </React.Fragment>
                    );
                case 'partialShipment.created':
                    return (
                        <React.Fragment>
                            Partial Shipment is created {' '}
                            <Link
                                className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                                to={`/requests/details/${parts[1]}`}
                            >
                                {item.tradeRefId ? item.tradeRefId : item.trade}
                            </Link>
                        </React.Fragment>
                    );
                case 'trade.contractFulfillRequest':
                    return (
                        <React.Fragment>
                            Contract fulfillment request is Created {' '}
                            <Link
                                className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                                to={`/trades/details/${parts[1]}/advice`}
                            >
                                {item.tradeRefId ? item.tradeRefId : item.trade}
                            </Link>
                        </React.Fragment>
                    );
                case 'trade.contractFulfillAccept':
                    return (
                        <React.Fragment>
                            Contract fulfillment request is Accepted {' '}
                            <Link
                                className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                                to={`/trades/details/${parts[1]}/advice`}
                            >
                                {item.tradeRefId ? item.tradeRefId : item.trade}
                            </Link>
                        </React.Fragment>
                    );
                case 'trade.contractFulfillReject':
                    return (
                        <React.Fragment>
                            Contract fulfillment request is{' '}
                            <Link
                                className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                                onClick={() => {
                                    this.setState({
                                        showModal: true,
                                        modalData: parts[1],
                                        modal: "CONTRACT_FULFILLMENT_REJECTED"
                                    })
                                }}>
                                Rejected
                                </Link>{' '}
                            {item.tradeRefId ? item.tradeRefId : item.trade}
                            {/* <Link
                                className=""
                                to={`/trades/details/${parts[1]}`}
                            >
                                {item.tradeRefId ? item.tradeRefId : item.trade}
                            </Link> */}
                        </React.Fragment>
                    );
                case 'trade.accepted':
                    return <React.Fragment>
                        <button className="btn-link"
                            onClick={e => this.setState({
                                selectedTrade: item.trade,
                                showDetails: true,
                                isContractInfo: false,
                                logDate: item.logDate,
                                isDeclined: false
                            })} >Trade</button>&nbsp;
                        {item.tradeRefId ? item.tradeRefId : item.trade} is accepted by {item.companyName}
                    </React.Fragment>;
                case 'trade.sign':
                    return <React.Fragment> Contract is signed by {item.companyName}</React.Fragment>;
                case 'trade.contract.created':
                    return (
                        <React.Fragment>
                            <Link
                                className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                                to={`/trades/details/${this.props.id}/contract`}
                            >
                                Contract
                            </Link>{' '}
                            is Created
                        </React.Fragment>
                    );
                case 'trade.advice':
                    return <React.Fragment>Trade status changed from Shipping Advice to Documents
                        Required</React.Fragment>;
                case 'trade.instructions':
                    return (
                        <React.Fragment>
                            Trade status changed from
                            <Link className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                                to={`/trades/details/${this.props.id}/instructions`}>
                                {' '}Documentary Instructions{' '}
                            </Link>
                            Required to Shipping Advice Pending
                        </React.Fragment>
                    );
                case 'trade.documents':
                    return (
                        <React.Fragment>Trade status changed from Shipping Advice Pending to Documents
                            Required</React.Fragment>
                    );
                case 'trade.confirmDocuments':
                    return <React.Fragment>Trade status changed from Documents Required to Payment
                        Required</React.Fragment>;
                case 'trade.close':
                    return <React.Fragment>{item.author} close a trade</React.Fragment>;
                case 'trade.signed':
                    return (
                        <React.Fragment>
                            Contract is signed by all
                        </React.Fragment>
                    );
                case 'trade.payment':
                    return <React.Fragment>{item.author} notifies that {this.navigateToLink('payment', `/trades/details/${item.trade}`)} is done</React.Fragment>;
                case 'trade.acceptPayment':
                    return <React.Fragment>{item.author} viewed {this.navigateToLink('payment', `/trades/details/${item.trade}`)}</React.Fragment>;
                case 'trade.closed':
                    return <React.Fragment>Trade is closed</React.Fragment>;
                case 'trade.vesselNominated':
                    return <React.Fragment>Vessel nomination is created</React.Fragment>;
                case 'trade.vesselAccepted':
                    return <React.Fragment>Vessel nomination is accepted</React.Fragment>;
                case 'trade.vesselAcceptedWithNewLaycanDate':
                    return <React.Fragment>Vessel nomination is accepted with new laycan dates</React.Fragment>;
                case 'trade.vesselFinalised':
                    return <React.Fragment>Vessel nomination is Finalised</React.Fragment>;
                case 'trade.vesselNominatedComplete':
                    return <React.Fragment>Vessel nomination is approved</React.Fragment>;
                case 'trade.laycanDatesChanged':
                    return <React.Fragment>Laycan dates had changed</React.Fragment>;
                case 'trade.vesselRejected':
                    return <><button className="btn-link" onClick={e => this.openVesselRejectedModal(item.comment, 'VESSEL_REJECTED')}>Vessel</button> nomination is rejected</>
                case 'trade.substituteVesselNominated':
                    return <React.Fragment>Substitute Vessel nomination is created</React.Fragment>;
                case 'trade.substituteVesselAccepted':
                    return <React.Fragment>Substitute Vessel nomination is accepted</React.Fragment>;
                case 'trade.substituteVesselAcceptedWithNewLaycanDate':
                    return <React.Fragment>Substitute Vessel nomination is accepted with new laycan dates</React.Fragment>;
                case 'trade.substituteVesselFinalised':
                    return <React.Fragment>Substitute Vessel nomination is Finalised</React.Fragment>;
                case 'trade.substituteVesselNominatedComplete':
                    return <React.Fragment>
                        <button className="btn-link"
                            onClick={e => this.openSubstitueModal(parts[1])}
                        >Substitute Vessel </button>&nbsp;  nomination is approved
                </React.Fragment>;
                case 'trade.substituteVessellaycanDatesChanged':
                    return <React.Fragment>Laycan dates had changed for Substitute Vessel</React.Fragment>;
                case 'trade.substituteVesselRejected':
                    return <React.Fragment><button className="btn-link" onClick={e => this.openVesselRejectedModal(item.comment, 'RENDER_REJECTED_SUBSTITUTE_VESSEL/CONTAINER')} >Substitute Vessel</button> nomination is rejected</React.Fragment>;
                case 'shipment.created':
                    return <React.Fragment>Documentary Instructions sent</React.Fragment>
                case 'trade.document.upload': {
                    const docType = parts[1];
                    const shipmentId = (parts[2] && parts[2] !== '0') ? parts[2] : null;
                    return this.getDocumentsLink(docType, 'is filled in', shipmentId);
                }
                case 'trade.document.upload.updated': {
                    const docType = parts[1];
                    const shipmentId = (parts[2] && parts[2] !== '0') ? parts[2] : null;
                    return this.getDocumentsLink(docType, 'has been updated', shipmentId);
                }
                case 'trade.document.approve': {
                    const docType = parts[1];
                    const shipmentId = (parts[2] && parts[2] !== '0') ? parts[2] : null;
                    return this.getDocumentsLink(docType, 'is approved', shipmentId);
                }
                case 'trade.document.reject': {
                    const docType = parts[1];
                    const shipmentId = (parts[2] && parts[2] !== '0') ? parts[2] : null;
                    return this.getDocumentsLink(docType, 'is rejected', shipmentId);
                }
                case 'trade.document.releaseForBuyer': {
                    const docType = parts[1];
                    const shipmentId = (parts[2] && parts[2] !== '0') ? parts[2] : null;
                    return this.getDocumentsLink(docType, 'is shared for review with the buyer', shipmentId);
                }
                case 'trade.document.invoice': {
                    const docName = DOCUMENT_NAMES.INVOICE;
                    return (
                        <React.Fragment>
                            <Link
                                className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                                to={`/trades/details/${this.props.id}/documents/invoice/preview`}
                            >
                                {docName}
                            </Link>
                            {' is filled in'}
                        </React.Fragment>
                    )
                }

                case 'trade.inspectionQuote.created': {
                    const quoteType = parts[1].toLowerCase();
                    return <React.Fragment>New request for {quoteType} {linkToInspectionqoute} is created
                    </React.Fragment>;
                }

                case 'trade.inspectionQuote.upload': {
                    const quoteType = parts[1];
                    return <React.Fragment>{quoteType === "PROVISIONAL" ? "Provisional" : "Final"} {linkToInspectionqoute} file is uploaded</React.Fragment>;
                }
                case 'trade.inspectionQuote.status': {
                    const quoteType = parts[1];
                    const status = parts[3];
                    return <React.Fragment>{quoteType === "PROVISIONAL" ? "Provisional" : "Final"} {linkToInspectionqoute} status updated as {status}</React.Fragment>;
                }
                case 'trade.instructions.indicator.updated':
                    return <React.Fragment> Trade document instruction had been updated to {parts[2] === "GLOBAL" ? "GLOBAL set BOL" : "Multiple set Bol"}  </React.Fragment>;
                case 'trade.proformaInvoice.created':
                    return <React.Fragment>{this.navigateToLink('Proforma invoice', `/trades/details/${item.trade}/documents`)} has been issued by {item.companyName}</React.Fragment>
                case 'trade.proformaInvoice.upload':
                    return <React.Fragment>{this.navigateToLink('Downpayment proof', `/trades/details/${item.trade}/documents`)} has been uploaded by {item.companyName}</React.Fragment>
                case 'trade.proformaInvoice.status.updated':
                    let lastIndex = parts.length - 1;
                    if (parts[lastIndex] === 'PAYMENT_NOT_CONFIRMED') {
                        return <React.Fragment>{this.navigateToLink('Downpayment', `/trades/details/${item.trade}/documents`)} not confirmed by {item.companyName}</React.Fragment>
                    } else if (parts[lastIndex] === 'PAYMENT_CONFIRMED') {
                        return <React.Fragment>{this.navigateToLink('Downpayment', `/trades/details/${item.trade}/documents`)} confirmed by {item.companyName}</React.Fragment>
                    } else if (parts[lastIndex] === 'EFFECTED') {
                        return <React.Fragment>{this.navigateToLink('Downpayment', `/trades/details/${item.trade}/documents`)} date confirmed by {item.companyName}</React.Fragment>
                    }
                    break;
                case 'trade.shippingQuote.created':
                    if (parts[1] === 'PROVISIONAL') {
                        if (parts[3] === 'SPOT_OFFER') {
                            return <React.Fragment>New provisional {linkToShippingQoute} requested using Maersk offer api</React.Fragment>
                        } else if (parts[3] === '') {
                            return <React.Fragment>Provosional {linkToShippingQoute} requested for vessel</React.Fragment>
                        }
                    } else if (parts[1] === 'FINAL') {
                        return <React.Fragment><Link className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline"
                            to={`/ShippingQuoteDetails/${item.trade}/${parts[2]}`}>
                            Freight booking
                            </Link> requested</React.Fragment>
                    }
                    break;
                case 'trade.shippingQuote.ediFIle':
                    if (parts[3] === 'ACK') {
                        return <React.Fragment>Frieght request processed by Maersk</React.Fragment>
                    } else if (parts[3] === 'BOOKCONF') {
                        return <React.Fragment>Your freight booking request is confirmed by maersk</React.Fragment>
                    } else if (parts[4] === 'WAITING_CONFIRMATION') {
                        return <React.Fragment>Shipment booking is waiting to get confirmed by maersk</React.Fragment>
                    }
                    break;
                case 'trade.shippingQuote.upload':
                    if (parts[1] === 'PROVISIONAL') {
                        return <React.Fragment>Provisional {linkToShippingQoute} has been uploaded by service provider</React.Fragment>
                    } else if (parts[1] === 'FINAL') {
                        return <React.Fragment>{linkToFinalShippingOffer} has been uploaded by service provider</React.Fragment>
                    }
                    break;
                case 'trade.shippingQuote.status':
                    if (parts[1] === 'PROVISIONAL') {
                        if (parts[4] === 'REJECTED') {
                            return <React.Fragment>{linkToShippingQoute} is rejected</React.Fragment>
                        } else if (parts[4] === 'CANCELLED') {
                            return <React.Fragment>{linkToShippingQoute} is canceled</React.Fragment>
                        } else if (parts[4] === 'ACCEPTED') {
                            return <React.Fragment>{linkToShippingQoute} is accepted</React.Fragment>
                        }

                    } else if (parts[1] === 'FINAL') {
                        if (parts[4] === 'REJECTED') {
                            return <React.Fragment>{linkToFinalShippingOffer} is rejected</React.Fragment>
                        } else if (parts[4] === 'CANCELLED') {
                            return <React.Fragment>{linkToFinalShippingOffer} is canceled</React.Fragment>
                        } else if (parts[4] === 'ACCEPTED') {
                            return <React.Fragment>{linkToFinalShippingOffer} is accepted</React.Fragment>
                        } else if (parts[4] === 'COMPLETED') {
                            return <React.Fragment>{linkToFinalShippingOffer} is completed</React.Fragment>
                        }
                    }
                    break;
                case 'trade.uncleanInspectionReport.approved':
                    return <React.Fragment>Unclean Daily Inspection Report - {linkToInspectionReport} is approved</React.Fragment>;
                case 'trade.uncleanInspectionReport.rejected':
                    return <React.Fragment>Unclean Daily Inspection Report - {linkToInspectionReport} is rejected</React.Fragment>;
                case 'trade.repayment':
                    return <React.Fragment>{item.companyName} has requested to reconfirm the commercial invoice payment</React.Fragment>

                case 'contractAmendment.created':
                    return <React.Fragment>Contract amendment term request submitted</React.Fragment>;
                case 'contractAmendment.accepted':
                    return <React.Fragment>
                        <button className="btn-link"
                            onClick={e => this.setState({
                                selectedTrade: item.trade,
                                showDetails: true,
                                isContractInfo: true,
                                isDeclined: false,
                                logDate: item.date,
                            })} >Contract</button>&nbsp;
                   amendment term accepted
                    </React.Fragment>;
                case 'contractAmendment.declined':

                    return <React.Fragment>
                        <button className="btn-link"
                            onClick={e => this.setState({
                                selectedTrade: item.trade,
                                showDetails: true,
                                isContractInfo: true,
                                logDate: item.date,
                                isDeclined: true
                            })} >Contract</button>&nbsp;
                   amendment term rejected
                    </React.Fragment>;
                case 'trade.letterCredit.created':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                L/C draft
                            </button>{' '}
                            has been issued by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.letterCredit.updated':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                L/C draft
                            </button>{' '}
                            has been updated by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.letterCredit.uploaded':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                L/C draft
                            </button>{' '}
                            has been uploaded by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.letterCredit.reupload':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={e => this.setState({
                                    showModal: true,
                                    modalName: 'PT_DOC_UPDATED'
                                })} >
                                L/C draft
                            </button>{' '}
                            has been again uploaded by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.letterCredit.status.updated':
                    let lastIndex1 = parts.length - 1;
                    if (parts[lastIndex1] === 'CONFIRMED')
                        return (
                            <React.Fragment>
                                <button
                                    className="btn-link"
                                    onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                    L/C draft
                            </button>{' '}
                                has been confirmed by {item.companyName}
                            </React.Fragment>
                        )
                    if (parts[lastIndex1] === 'REJECTED') {
                        return (
                            <React.Fragment>Changes has been requested by {item.companyName} in{' '}
                                <button
                                    className="btn-link"
                                    onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_CHANGES' })}>
                                    L/C draft
                                </button>
                            </React.Fragment>
                        )
                    }
                    break;
                case 'trade.avalizedDraft.created':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                Avalised Draft
                            </button>{' '}
                            has been issued by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.avalizedDraft.updated':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                Avalised Draft
                            </button>{' '}
                            has been updated by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.avalizedDraft.uploaded':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                Avalised Draft
                            </button>{' '}
                            has been uploaded by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.avalizedDraft.reupload':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={e => this.setState({
                                    showModal: true,
                                    modalName: 'PT_DOC_UPDATED'
                                })} >
                                Avalised Draft
                            </button>{' '}
                            has been again uploaded by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.avalizedDraft.status.updated':
                    let lastIndex2 = parts.length - 1;
                    if (parts[lastIndex2] === 'CONFIRMED')
                        return (
                            <React.Fragment>
                                <button
                                    className="btn-link"
                                    onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                    Avalised Draft
                            </button>{' '}
                                has been confirmed by {item.companyName}
                            </React.Fragment>
                        )
                    if (parts[lastIndex2] === 'REJECTED') {
                        return (
                            <React.Fragment>Changes has been requested by {item.companyName} in{' '}
                                <button
                                    className="btn-link"
                                    onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_CHANGES' })}>
                                    Avalised Draft
                                </button>
                            </React.Fragment>
                        )
                    }
                    break;
                case 'trade.confirmedLC.created':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                Confirmed L/C Draft
                            </button>{' '}
                            has been issued by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.confirmedLC.updated':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                Confirmed L/C Draft
                            </button>{' '}
                            has been updated by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.confirmedLC.uploaded':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                Confirmed L/C Draft
                            </button>{' '}
                            has been uploaded by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.confirmedLC.reupload':
                    return (
                        <React.Fragment>
                            <button
                                className="btn-link"
                                onClick={e => this.setState({
                                    showModal: true,
                                    modalName: 'PT_DOC_UPDATED'
                                })} >
                                Confirmed L/C Draft
                            </button>{' '}
                            has been again uploaded by {item.companyName}
                        </React.Fragment>
                    )
                case 'trade.confirmedLC.status.updated':
                    let lastIndex3 = parts.length - 1;
                    if (parts[lastIndex3] === 'CONFIRMED')
                        return (
                            <React.Fragment>
                                <button
                                    className="btn-link"
                                    onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_UPDATED' })}>
                                    Confirmed L/C Draft
                            </button>{' '}
                                has been confirmed by {item.companyName}
                            </React.Fragment>
                        )
                    if (parts[lastIndex3] === 'REJECTED') {
                        return (
                            <React.Fragment>Changes has been requested by {item.companyName} in{' '}
                                <button
                                    className="btn-link"
                                    onClick={() => this.setState({ showModal: true, modalName: 'PT_DOC_CHANGES' })}>
                                    Confirmed L/C Draft
                                </button>
                            </React.Fragment>
                        )
                    }
                    break;
                case 'trade.originFinalized':
                    return <React.Fragment>{item.companyName} has selected {Countries[parts[1]]} as an contractual origin</React.Fragment>
                default:
                    break;
            }
        }
        return item.comment;
    }

    render() {
        return (
            <div className="trades-dtls__audit-table">
                <DetailedInfosModal
                    tradeId={this.state.selectedTrade}
                    onClose={(e) => { this.setState({ showDetails: false }) }}
                    show={this.state.showDetails}
                    logDate={this.state.logDate}
                    isContractInfo={this.state.isContractInfo}
                    isDeclined={this.state.isDeclined} />
                {this.state.showModal && this.renderModal()}

                <Preloader loadingStyle="swirl" loading={this.state.loading}>

                    <MaterialTable
                        columns={[
                            {
                                title: 'TxHash', field: 'transactionId', sorting: false,
                                render: rowData =>
                                    <span className="audit__overflow transactionId">{rowData.transactionId}</span>
                            },
                            {
                                title: 'Date', field: 'date',
                                render: rowData =>
                                    <span className="audit__overflow">{moment(rowData.date).format(DATEFORMATHOURS)}</span>
                            },
                            {
                                title: 'Action', field: 'comment',
                                render: rowData => <span className="audit__overflow">{this.displayAction(rowData)}</span>
                            },
                            {
                                title: 'Action By', field: 'comment',
                                render: rowData => <span className="audit__overflow">{this.displayActionBy(rowData)}</span>
                            }
                        ]}
                        data={this.state.items}

                        icons={{
                            FirstPage: FirstPage,
                            LastPage: LastPage,
                            NextPage: ChevronRight,
                            PreviousPage: ChevronLeft,
                            SortArrow: ArrowUpward,
                        }}

                        options={{
                            toolbar: false,
                            pageSize: 20,
                            pageSizeOptions: [10, 20, 30, 40, 50],
                            rowStyle: (rowData, index) => {
                                const backgroundColor = (index % 2 === 0) ? '#00ff301c' : '#FBFBFB';
                                return { borderColor: '#c3e6cb', backgroundColor: backgroundColor }
                            },
                            thirdSortClick: false
                        }}
                    />
                </Preloader>
                {this.state.docInstructionsModal.isOpened && (
                    <DocumentaryInstructionsModal
                        data={this.state.docInstructionsModal.data}
                        previewMode={true}
                        trade={this.props.trade.items.single}
                        onClose={this.closeDocInstructionsModal}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
        loadingPTProcess: state.loading.loadingPTProcess,
        user: state.account.user,
        trade: state.trade,
        documents: state.trade.documents,
        nomination: state.nomination,
        LoadingNominationInfo: state.loading.loadingNomination,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    OpenDocument,
    getPTDocument,
    downloadPaymentTermDoc,
    substitueAuditLogs,
    navigate: path => push(path),
    LoadingStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuditLog);
