import React from 'react';

const basisLabels = {
    PER_DOC: 'Bill of Lading',
    PER_CONTAINER: 'Container'
}

export default function TRSurcharge(props) {
    const { price } = props;
    return (
    <tr>
        <td>{price.chargedescription}</td>
        <td>{basisLabels[price.ratebasis]|| price.ratebasis}</td>
        <td>
            <span className={price.currency === "USD"?'font-weight-bold float-right':'font-weight-normal'}>
                {`${price.rate} ${price.currency}`}
            </span>
            { price.currency !== "USD" && 
            <span className={'ml-2 font-weight-bold float-right'}>{`${price.rateusd} USD`}</span>}
        </td>
        <td>
            <span className={price.currency === "USD"?'font-weight-bold float-right':'font-weight-normal'}>
                {`${price.amount} ${price.currency}`}
            </span>
            { price.currency !== "USD" && 
            <span className={'ml-2 font-weight-bold float-right'}>{`${price.amountusd} USD`}</span>}
        </td>
    </tr>
    )

}