import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';

import { preloadInspectionCompanies, LoadTradeBids } from '../../../../modules/module.trade';
import Preloader from '../../../components/preloader/Preloader';
import DetailedInfos from './DetailedInfos';

class DetailedInfosWrapper extends Component{
    componentDidMount(){
        this.props.LoadTradeBids(this.props.trade.items.single.id);
        this.props.preloadInspectionCompanies();
    }

    getSeries(trade){
        const bids = trade.bids;
        const request = trade.items.single;

        let series = []
        if(bids.length !== 0){
            // RDEV-1777 - Old trade bids don't have 'optionalDocuments' field. For those, optionalDocuments is copied from the parent trade object.
            for(var i=0;i<bids.length;i++) {
                if(bids[i].optionalDocuments === undefined ) {
                    Object.assign(bids[i], {optionalDocuments: request.optionalDocuments});
                }
            }
            const counterparty = request.requestType === "BUY"?request.sellerId:request.buyerId;
            const systemBid = bids.find( bid => bid.systemBid );
            let initialTrade = bids.find( bid => bid.status === "ORIG_TRADE_DATA");
            
            //Rewrite initial trade
            //Add systembid info to the initial trade data
            Object.assign(initialTrade, {
                tradeRefId: request.tradeRefId,
                createdAt: request.createdAt,
                seller: request.seller,
                sellerUser: request.sellerUser,
                buyer: request.buyer,
                buyerUser: request.buyerUser,
                sellerId: request.sellerId,
                buyerId: request.buyerId, 
                requestType: request.requestType,
                privacyType: request.privacyType,
                bidValidateDate: request.validateDate,
                validateDate: request.validateDate,
                acceptedAt: systemBid?systemBid.acceptedAt:"",
                acceptedByCompanyId: systemBid?systemBid.acceptedByCompanyId:"",
                acceptedByCompanyName: systemBid?systemBid.acceptedByCompanyName:"",
                acceptedByUserName: systemBid?systemBid.acceptedByUserName:"",
                specialRequest: request.specialRequest,
                fromCompanyName: request.requestType === "BUY"?request.buyer:request.seller});
               

            //1 - Filter participants, initial trade, remove systemBid
            //2 - Order by date 'createdAt'
            //3 - Return structure of 'serie' for component <DetailTable/>
            series = trade.bids
                .filter(bid => ([bid.fromCompanyID, bid.toCompanyID].includes(counterparty) ||  bid.status === "ORIG_TRADE_DATA") && !bid.systemBid )
                .sort((a,b) => moment(a.createdAt).isBefore(moment(b.createdAt))?-1:1)
                .map((bid,index) => ({ title: index, data: bid}));
        }

        return series;
    }

    render() {
        const {trade, bidLoading } = this.props;
        const series = this.getSeries(trade);
  
        return (
            <Preloader loadingStyle="swirl" loading={bidLoading}>
                { series.length !== 0 && 
                <DetailedInfos series={series} {...this.props}/>}
            </Preloader>
        )

    }

}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        userToken: state.account.token,
        bidLoading: state.loading.bidLoading,
        user: state.account.user
    };
};

const mapDispatchToProps = dispatch => 
    bindActionCreators({
            LoadTradeBids,
            preloadInspectionCompanies
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DetailedInfosWrapper);