import { DOCUMENT_NAMES } from '../app/admin/trades/services/documents.service';
export const LOADING_DATA = 'loading/LOADING_DATA';
export const LOADING_REQUEST_DATA = 'loading/LOADING_REQUEST_DATA';
export const LOADING_BID_DATA = 'loading/LOADING_BID_DATA';
export const LOADING_VESSEL_DETAILS = 'loading/LOADING_VESSEL_DETAILS';
export const LOADING_SHIPMENT_DETAILS = 'loading/LOADING_SHIPMENT_DETAILS';
export const LOADING_REQUEST_PAGE = 'loading/LOADING_REQUEST_PAGE';
export const LOADING_REGISTER_DATA = 'loading/LOADING_REGISTER_DATA';
export const LOADING_CREATE_REQUEST = 'loading/LOADING_CREATE_REQUEST';
export const LOADING_CREATE_PASSWORD = 'loading/LOADING_CREATE_PASSWORD';
export const LOADING_CITIES = 'loading/LOADING_CITIES';
export const LOADING_STATUS = 'loading/LOADING_STATUS';
export const LOADING_VESSEL_SHIPMENT_STATUS='loading/LOADING_VESSEL_SHIPMENT_STATUS';
export const LOADING_INITIATE = 'loading/LOADING_INITIATE';
export const LOADING_DOCUMENTS = 'loading/LOADING_DOCUMENTS';
export const LOADING_DOCUMENT_COMMENTS = 'loading/LOADING_DOCUMENT_COMMENTS';
export const LOADING_NOTIFICATIONS = 'loading/LOADING_NOTIFICATIONS';
export const LOADING_MORE_NOTIFICATIONS = 'loading/LOADING_MORE_NOTIFICATIONS';
export const LOADING_TRADE_MESSAGES = 'loading/LOADING_TRADE_MESSAGES';
export const LOADING_USER_PROFILE = 'loading/LOADING_USER_PROFILE';
export const LOADING_UPDATE_USER_PROFILE = 'loading/LOADING_UPDATE_USER_PROFILE';
export const LOADING_KYC = 'loading/LOADING_KYC';
export const LOADING_RBACGROUP_DATA = 'loading/LOADING_RBACGROUP_DATA';
export const LOADING_PERMISSION_DATA = 'loading/LOADING_PERMISSION_DATA';
export const LOADING_GROUP_ACTION = 'loading/LOADING_GROUP_ACTION';
export const LOADING_KYC_DATA = 'loading/LOADING_KYC_DATA';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const LOADING_SAVE_PERMISSION = 'loading/LOADING_SAVE_PERMISSION'
export const LOADING_PROFORMA_INVOICE_OPERATION = 'loading/LOADING_PROFORMA_INVOICE_OPERATION'
export const LOADING_COMMERCIAL_INVOICE_PROOFS = 'loading/LOADING_COMMERCIAL_INVOICE_PROOFS';
export const LOADING_COMMERCIAL_INVOICE_INDICATOR = 'loading/LOADING_COMMERCIAL_INVOICE_INDICATOR';
export const LOADING_TEMPLATES = 'loading/LOADING_TEMPLATES';
export const LOADING_COUNTER_PARTIES = 'loading/LOADING_COUNTER_PARTIES';
export const LOADING_INSPECTION_COMPANIES = 'loading/LOADING_INSPECTION_COMPANIES';
export const LOADING_CONTRACT_TERM='loading/LOADING_CONTRACT_TERM';
export const LOADING_BANK_DETAILS = 'loading/LOADING_BANK_DETAILS';
export const LOADING_BID_INFO = 'loading/LOADING_BID_INFO';
export const LOADING_PT_PROCESS = 'loading/LOADING_PT_PROCESS';
export const LOADING_TRADE_UPDATE = 'loading/LOADING_TRADE_UPDATE';
export const LOADING_CONTRACT_FULFILLED = 'loading/LOADING_CONTRACT_FULFILLED';

export const LOADING_NOMINATION='loading/LOADING_NOMINATION';
export const LOADING_PROVIDER_LIST ='loading/LOADING_PROVIDER_LIST';


const initialState = {
    isLoading: false,
    registerLoading: false,
    isFileUpload: false,
    requestLoading: false,
    bidLoading: false,
    requestCreation: false,
    requestPassword: false,
    loadingCities: false,
    loadingStatus: false,
    loadingInitiate: false,
    loadingCounterParties: false,
    loadingInspectionCompanies: false,
    loadingDocuments: {
        all: false,
        [DOCUMENT_NAMES.BILL]: false,
        [DOCUMENT_NAMES.INVOICE]: false,
        [DOCUMENT_NAMES.CERT_OF_QUALITY]: false,
        [DOCUMENT_NAMES.PHYTOSANITARY]: false,
        [DOCUMENT_NAMES.CERT_OF_PACKING]: false,
        [DOCUMENT_NAMES.CERT_OF_FUMIGATION]: false,
        [DOCUMENT_NAMES.QUALITY_APPEARANCE_CERT]: false,
        [DOCUMENT_NAMES.INSURANCE]: false,
        [DOCUMENT_NAMES.EXPORT_DECLARATION]: false,
        [DOCUMENT_NAMES.NON_GMO]: false,
        [DOCUMENT_NAMES.CERT_OF_WEIGHT]: false,
        [DOCUMENT_NAMES.CERT_OF_ORIGIN]: false,
        [DOCUMENT_NAMES.CERT_OF_CONFORMITY]: false,
        [DOCUMENT_NAMES.BSC]: false
    },
    loadingDocumentComments: {},
    loadingNotifications: false,
    loadingMoreNotifications: false,
    loadingTradeMessages: false,
    loadingUserProfile: false,
    loadingUpdateUserProfile: false,
    groupLoading: false,
    permissionLoading: false,
    groupActionLoading: false,
    kycLoading: false,
    loadingSavePermission: false,
    loadingVesselDetails: false,
    loadingShipmentDetails: false,
    loadingProformaInvoice: false,
    loadingCommercialInvoiceProofs: false,
    loadingCommercialInvoiceIndicator: false,
    loadingTemplates: {
        'CREATING': false,
        'DELETING': false,
        'UPDATING' : false,
        'FETCHING': false
    },
    contractLoading:false,
    loadingBankDetails: false,
    loadingBidInfo: false,
    loadingNomination:false,
    loadingTradeUpdate:false,
    loadingContractFulfilled: false,
    loadingPTProcess: '',
    loadingProviderList: false,
    loadedAllVesselAndShipment:false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case LOADING_USER_PROFILE:
            return {
                ...state,
                loadingUserProfile: action.payload
            }
        case LOADING_COUNTER_PARTIES:
            return{
                ...state,
                loadingCounterParties: action.payload
            }
        case LOADING_INSPECTION_COMPANIES:
            return{
                ...state,
                loadingInspectionCompanies: action.payload
            }
        case LOADING_COMMERCIAL_INVOICE_INDICATOR:
            return {
                ...state,
                loadingCommercialInvoiceIndicator: action.payload
            }
        case LOADING_DATA:
            return {
                ...state,
                isLoading: action.payload
            };
        case FILE_UPLOAD:
            return {
                ...state,
                isFileUpload: action.payload
            }
        case LOADING_REQUEST_DATA:
            return {
                ...state,
                requestLoading: action.payload
            };
        case LOADING_BID_DATA:
            return {
                ...state,
                bidLoading: action.payload
            };
        case LOADING_VESSEL_DETAILS:
            return {
                ...state,
                loadingVesselDetails: action.payload
            }
        case LOADING_SHIPMENT_DETAILS:
            return {
                ...state,
                loadingShipmentDetails: action.payload
            }
        case LOADING_REQUEST_PAGE:
            return {
                ...state,
                requestLoadingPage: action.payload
            };
        case LOADING_REGISTER_DATA:
            return {
                ...state,
                registerLoading: action.payload
            };
        case LOADING_CREATE_REQUEST:
            return {
                ...state,
                requestCreation: action.payload
            };
        case LOADING_CREATE_PASSWORD:
            return {
                ...state,
                requestPassword: action.payload
            };
        case LOADING_CITIES:
            return {
                ...state,
                loadingCities: action.payload
            };
        case LOADING_VESSEL_SHIPMENT_STATUS:
            return {
                ...state,
                loadedAllVesselAndShipment: action.payload
            };
        case LOADING_STATUS:
            return {
                ...state,
                loadingStatus: action.payload
            };
        case LOADING_INITIATE:
            return {
                ...state,
                loadingInitiate: action.payload
            };
        case LOADING_DOCUMENTS:
            const { docName, isLoading } = action.payload;
            return {
                ...state,
                loadingDocuments: {
                    ...state.loadingDocuments,
                    [docName]: isLoading
                }
            };
        case LOADING_DOCUMENT_COMMENTS:
            return {
                ...state,
                loadingDocumentComments: {
                    ...state.loadingDocumentComments,
                    [action.payload.docId]: action.payload.isLoading
                }
            };
        case LOADING_NOTIFICATIONS:
            return {
                ...state,
                loadingNotifications: action.payload
            };
        case LOADING_MORE_NOTIFICATIONS:
            return {
                ...state,
                loadingMoreNotifications: action.payload
            };
        case LOADING_TRADE_MESSAGES:
            return {
                ...state,
                loadingTradeMessages: action.payload
            };
        case LOADING_RBACGROUP_DATA:
            return {
                ...state,
                groupLoading: action.payload
            };
        case LOADING_PERMISSION_DATA:
            return {
                ...state,
                permissionLoading: action.payload
            };

        case LOADING_GROUP_ACTION:
            return {
                ...state,
                groupActionLoading: action.payload
            };
        case LOADING_KYC_DATA:
            return {
                ...state,
                kycLoading: action.payload
            };
        case LOADING_SAVE_PERMISSION:
            return {
                ...state,
                loadingSavePermission: action.payload
            }
        case LOADING_PROFORMA_INVOICE_OPERATION:
            return {
                ...state,
                loadingProformaInvoice: action.payload
            }
        case LOADING_COMMERCIAL_INVOICE_PROOFS:
            return {
                ...state,
                loadingCommercialInvoiceProofs: action.payload
            }
        case LOADING_TEMPLATES:
            return{
                ...state,
                loadingTemplates: {
                    ...state.loadingTemplates,
                    [action.payload.operationName]: action.payload.isLoading
                }
            }

        case LOADING_CONTRACT_TERM:
            return {
                ...state,
                contractLoading: action.payload
            };         
        case LOADING_BANK_DETAILS:
            return{
                ...state,
                loadingBankDetails: action.payload
            }
        case LOADING_BID_INFO:
            return{
                ...state,
                loadingBidInfo: action.payload
            }

        case LOADING_NOMINATION:    
            return{
                ...state,
                loadingNomination: action.payload
            }
        case LOADING_TRADE_UPDATE:
            return {
                ...state,
                loadingTradeUpdate: action.payload
            }

        case LOADING_PT_PROCESS:
            return {
                ...state,
                loadingPTProcess: action.payload
            }
        case LOADING_CONTRACT_FULFILLED:
            return {
                ...state,
                loadingContractFulfilled: action.payload
            }
        case LOADING_PROVIDER_LIST:
            return {
                ...state,
                loadingProviderList: action.payload
            }
        default:
            return state;
    }
};

export const LoadingDocuments = (docName, isLoading, func) => {
    return func({ type: LOADING_DOCUMENTS, payload: { docName, isLoading } });
};

export const LoadingAllDocuments = (isLoading, func) => {
    return func({ type: LOADING_DOCUMENTS, payload: { docName: 'all', isLoading } });
};

export const LoadingStatus = isLoading => {
    return dispatch => {
        dispatch({
            type: LOADING_STATUS,
            payload: isLoading
        });
    };
};
export const LoadingVesselShipmentsStatus=isLoading=>{
    return dispatch => {
        dispatch({
            type: LOADING_VESSEL_SHIPMENT_STATUS,
            payload: isLoading
        });
    };
}
export const LoadingInitiate = isLoading => {
    return dispatch => {
        dispatch({
            type: LOADING_INITIATE,
            payload: isLoading
        });
    };
};

export const LoadingCities = isLoading => {
    return dispatch => {
        dispatch({
            type: LOADING_CITIES,
            payload: isLoading
        });
    };
};

export const LoadingData = (func, isLoading) => {
    return func({
        type: LOADING_DATA,
        payload: isLoading
    });
};

export const LoadingRegisterData = isLoading => {
    return dispatch => {
        dispatch({
            type: LOADING_REGISTER_DATA,
            payload: isLoading
        });
    };
};

export const LoadingFileUpload = isLoading => {
    return dispatch => {
        dispatch({
            type: FILE_UPLOAD,
            payload: isLoading
        });
    };
};

export const LoadingCreatePassword = (func, isLoading) => {
    return func({
        type: LOADING_CREATE_PASSWORD,
        payload: isLoading
    });
};
export const LoadingRequestData = (func, isLoading) => {
    return func({
        type: LOADING_REQUEST_DATA,
        payload: isLoading
    });
};

export const LoadingContractFulfilledRequest = (func, isLoading) => {
    return func({
        type: LOADING_CONTRACT_FULFILLED,
        payload: isLoading
    });
};

export const LoadingBidData = (func, isLoading) => {
    return func({
        type: LOADING_BID_DATA,
        payload: isLoading
    });
};

export const LoadingVesselDetails = (func, isLoading) => {
    return func({
        type: LOADING_VESSEL_DETAILS,
        payload: isLoading
    });
};

export const LoadingShipmentDetails = (func, isLoading) => {
    return func({
        type: LOADING_SHIPMENT_DETAILS,
        payload: isLoading
    });
};



export const LoadingRequestPage = (func, isLoading) => {
    return func({
        type: LOADING_REQUEST_PAGE,
        payload: isLoading
    });
};

export const LoadingCreateRequest = (func, isLoading) => {
    return func({
        type: LOADING_CREATE_REQUEST,
        payload: isLoading
    });
};

export const LoadingDocumentComments = (isLoading, docId, dispatch) => {
    return dispatch({
        type: LOADING_DOCUMENT_COMMENTS,
        payload: { isLoading, docId }
    });
};

export const LoadingNotifications = (isLoading, dispatch) => {
    return dispatch({
        type: LOADING_NOTIFICATIONS,
        payload: isLoading
    })
};

export const LoadingMoreNotifications = (isLoading, dispatch) => {
    return dispatch({
        type: LOADING_MORE_NOTIFICATIONS,
        payload: isLoading
    })
};

export const LoadingTradeMessages = (isLoading, dispatch) => {
    return dispatch({
        type: LOADING_TRADE_MESSAGES,
        payload: isLoading
    })
};

export const LoadingUserProfile = (isLoading, dispatch) => {
    return dispatch({
        type: LOADING_USER_PROFILE,
        payload: isLoading
    })
};

export const LoadingUpdateUserProfile = (isLoading, dispatch) => {
    return dispatch({
        type: LOADING_UPDATE_USER_PROFILE,
        payload: isLoading
    })
};

export const LoadingRBACGrouptData = (func, isLoading) => {
    return func({
        type: LOADING_RBACGROUP_DATA,
        payload: isLoading
    });
};
export const LoadingPermissionData = (func, isLoading) => {
    return func({
        type: LOADING_PERMISSION_DATA,
        payload: isLoading
    });
};
export const LoadingonGroupAction = (func, isLoading) => {
    return func({
        type: LOADING_GROUP_ACTION,
        payload: isLoading
    });
};

export const LoadingKycData = (func, isLoading) => {
    return func({
        type: LOADING_KYC_DATA,
        payload: isLoading
    });
};

export const LoadingonSavePermission = (func, isLoading) => {
    return func({
        type: LOADING_SAVE_PERMISSION,
        payload: isLoading
    });
};

export const LoadingProformaInvoiceOperation = (func, isLoading) => {
    return func({
        type: LOADING_PROFORMA_INVOICE_OPERATION,
        payload: isLoading
    });
};

export const LoadingCommercialInvoiceProofs = (func, isLoading) => {
    return func({
        type: LOADING_COMMERCIAL_INVOICE_PROOFS,
        payload: isLoading
    });
};

export const LoadingCommercialInvoiceIndicator = (func, isLoading) => {
    return func({
        type: LOADING_COMMERCIAL_INVOICE_INDICATOR,
        payload: isLoading
    });
};

export const LoadingTemplates = (func, isLoading, operationName) => {
    return func({
        type: LOADING_TEMPLATES,
        payload:{isLoading, operationName}
    })
};

export const LoadingCounterParties = (func, isLoading) => {
    return func({
        type: LOADING_COUNTER_PARTIES,
        payload: isLoading
    })
};

export const LoadingInspectionCompanies = (func, isLoading) => {
    return func({
        type: LOADING_INSPECTION_COMPANIES,
        payload: isLoading
    })
}

export const LoadingContractTerm = (func, isLoading) => {
    return func({
        type: LOADING_CONTRACT_TERM,
        payload: isLoading
    });
};

export const LoadingBankDetails = (func, isLoading) => {
    return func({
        type: LOADING_BANK_DETAILS,
        payload: isLoading
    })
}

export const LoadingBidInfo = (func, isLoading) => {
    return func({
        type: LOADING_BID_INFO,
        payload: isLoading
    })
}



export const LoadingNominationInfo = (func, isLoading) => {
    return func({
        type: LOADING_NOMINATION,
        payload: isLoading
    })
  }
export const LoadingPTProcess = (func, currentProcess) => {
    return func({
        type: LOADING_PT_PROCESS,
        payload: currentProcess
    })
}

export const LoadingTradeUpdate = (func, isLoading) => {
    return func({
        type: LOADING_TRADE_UPDATE,
        payload: isLoading
    })
  }

export const LoadingProviderList = (func, isLoading) => {
    return func({
        type: LOADING_PROVIDER_LIST,
        payload: isLoading
    })
}