import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { inspectionProvisionalList,inspectionFinalList } from '../../../../modules/module.inspectionQuote';
import {push} from "connected-react-router";
import { Link } from 'react-router-dom';
import moment from 'moment';
//COMPONENTS
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

import '../trades.scss';
import { DATEFORMATHOURS,QUOTETYPE,QUOTESTATUS } from '../../../../services/service.values';
import Preloader from '../../../components/preloader/Preloader';
import MaterialTable from 'material-table';
import { EnumsService,PortService } from '../../../../services/service.utils';
import Search from '@material-ui/icons/Search';
import { viewInspectionQuotePermission } from '../../../../services/service.permission';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { faEye,faTimes  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from 'react-number-format';
class InspectionQuoteOverview extends Component {
    constructor(props) {
        super(props);
        this.getCountryName = this.getCountryName.bind(this);
       
    }
    state = {
       
        showModal: true,
        modal: '',
        modalData: {},
    };

    UNSAFE_componentWillMount = () => {
        this.fetchFunction();
    };

    fetchFunction = () => {
            // var companyId= this.props.user.companyId;
            this.props.inspectionProvisionalList(QUOTETYPE.PROVISIONAL);
            this.props.inspectionFinalList(QUOTETYPE.FINAL);
        
        this.setState({});
    };

    getItems = () => {
        let quoteList = this.props.inspectionQuote.quoteProvisionalList.quotes;
        return quoteList;
    };
    getItemsTwo = () => {
        let quoteFinalList = this.props.inspectionQuote.quoteFinalList.quotes;   
        return quoteFinalList;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const params = new URLSearchParams(window.location.search);
        const forceUpdateParam = params.get('forceUpdate');
        if (forceUpdateParam) {
            const forceUpdate = forceUpdateParam === 'true';
            if (forceUpdate) {
                this.fetchFunction();
                this.getItems();
                this.getItemsTwo();
            }
            const newLocationSearch = this.props.location.search.replace(/(&|\\?)forceUpdate=(true|false)/g, '');
            this.props.navigate(window.location.pathname + newLocationSearch);
        }
    }

    getCountryName(isoCode) {
        
        const countryName = EnumsService.countries()[isoCode.trim()];
        return countryName;
    }
    renderPortLabel = value => {
        let label = "";
        if(value){
            label = PortService.getJSON()[value]?PortService.getJSON()[value].name : value;
        }
        return label;    
    }

    renderModal = () => {
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Link>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>
                                </div>
                                <div className="modal-footer">
                                    <Link to="/" className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                );
    };

    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, modalData: data });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            modalData: {},
          
        });
    };
    

    returnStatusNumber = (status) => {
        switch (status) {
            case QUOTESTATUS.NEW: 
                return "quote-status pending";
            case QUOTESTATUS.CANCELLED:
                return "quote-status orange";
            case QUOTESTATUS.COMPLETED:
                return "quote-status green";
            case QUOTESTATUS.REJECTED:
                return "quote-status primary";
            case QUOTESTATUS.INPROGRESS:
                 return "quote-status gray";
            default:
                break;
        }
    };

    renderStatusLabel=(status)=>{
        let label="";
        switch(status){
            case QUOTESTATUS.NEW :label= "Quote Requested"; break;
            case QUOTESTATUS.INPROGRESS :label= "Quote Received"; break;
            case QUOTESTATUS.COMPLETED :label= "Completed"; break;
            case QUOTESTATUS.CANCELLED :label= "Cancelled"; break;
            case QUOTESTATUS.REJECTED :label= "Rejected"; break;
            default :
            break;
        }
    return label;     
}

renderStatusFinal=(status)=>{
    let label="";
    switch(status){
        case QUOTESTATUS.NEW :label= "Offer Requested"; break;
        case QUOTESTATUS.INPROGRESS :label= "Offer Received"; break;
        case QUOTESTATUS.COMPLETED :label= "Completed"; break;
        case QUOTESTATUS.CANCELLED :label= "Cancelled"; break;
        case QUOTESTATUS.REJECTED :label= "Rejected"; break;
        default : 
        break;
    }
return label;     
}
    

    render() {
            if (viewInspectionQuotePermission() === 0 ) {
            return (
                <div>
                    {this.state.showModal && this.renderModal()}
                </div>
            );
        }
       
        return (
            <React.Fragment>
                <Header />
                <div className="trades-req-quotes-Container">
        <div className="Container">
       <div className="trades-req-quotes__heading"> <h3 className=" mb-1">Requests for Inspection Quotes</h3></div>
            <nav className="mb-1">
                <ol className="breadcrumb">
                    {/* <li className="breadcrumb-item"><a href="#"><FontAwesomeIcon icon={faAngleLeft}/> Back</a> </li> */}
                </ol>
            </nav>
        
            <div className="content-wrappper trades-req-quotes">
                <p>The following provisional Quotes for the shipment via vessel and container were requested by you.
                    Click on Details to see additional information, add comments or cancel your request.
                </p>
                <p>Note that requests via the API are always tagged with the status 'completed' as they do not require
                    human intervention.</p>
                <div className="table-responsive-md">
                <Preloader loadingStyle="swirl" loading={this.props.loading}>
                        <MaterialTable
                            columns={[
                                {title: 'Trade Ref Id', field:'tradeRefID',
                                render: rowData => <Link  className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline" to={`/requests/details/${rowData.tradeRequestId}`}>
                                                    { rowData.tradeRefID ||  rowData.tradeRequestId }
                                                  </Link>
                            },
                                { title: 'Type', field: 'quoteType'},
                                { title: 'Origin ', field: 'originPort',
                                render: rowData => <span className="trades__overflow">{this.getCountryName(rowData.originPort)}    
                                </span> 
                            },
                                { title: 'Destination Port', field: 'destinationPort',
                                render: rowData => <span className="trades__overflow">{rowData.destinationPort==="" ?"--": this.renderPortLabel(rowData.destinationPort)}    
                                </span> 
                                
                            },
                                { title: 'Incoterm', field: 'incoterm'},
                                { title: 'Rate', field: 'rate',
                                render: rowData => <NumberFormat 
                                value={rowData.rate} 
                                displayType={'text'} 
                                thousandSeparator={true}
                                 renderText={value => <span className="trades__overflow">{value!=="" ? '$ '+value : '--'}</span>} >
                                 </NumberFormat>},
                                { title: 'Eventual Additional Condition', field: 'eventualAdditionalCondition',
                                render: rowData => <span
                                className="trades__overflow">{(rowData.eventualAdditionalCondition!==""? rowData.eventualAdditionalCondition :"--")}</span>},
                                { title: 'Status', field: 'status',
                                render: rowData => <span className={this.returnStatusNumber(rowData.status)} >{this.renderStatusLabel(rowData.status)}    
                                                  </span> 
                            },
                                { title: 'Requested', field: 'requestedDate', defaultSort: 'desc',
                                render: rowData => <span
                                className="trades__overflow">{moment(rowData.requestedDate).format(DATEFORMATHOURS)}</span>},
                                { title: 'Response', field: 'responseDate',
                                render: rowData => <span
                                className="trades__overflow">{(rowData.responseDate!==""? moment(rowData.responseDate).format(DATEFORMATHOURS):"--")}</span>},
                                { title: 'Action', sorting: false, render: rowData => <div className="action-btn">
                                <Tooltip title="Details of Inspection " placement="top-start" arrow >
                                <Link className="btn-circle circle-btn admin" to={`/inspectionQuoteDetails/${rowData.tradeRequestId}/${rowData.inspectionQuoteId}`}> 
                                        <FontAwesomeIcon icon={faEye} />
                                        </Link>
                                </Tooltip>

                            </div>},
                            ]}
                            data={this.getItems()}
                            title="Provisional Quotes"
                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                SortArrow: ArrowUpward,
                                Add: Add,
                                Edit: Edit,
                                DeleteOutline: DeleteOutline,
                                ResetSearch: Clear,
                                SaveIcon: SaveIcon
                            }}
                            options={{
                                pageSize: 5,
                                pageSizeOptions: [10, 15, 20, 30],
                                rowStyle: {
                                    borderColor: '#c3e6cb',
                                    backgroundColor: '##00ff301c'
                                },
                                thirdSortClick: false
                            }}
                        />
                    </Preloader>
                </div>
            </div>
            <div className="content-wrappper trades-req-quotes">
                <p>The following final offer for the shipment via Vessel and Container were requested by you. Click on
                    Details to see additional information, add comments or cancel your request.
                </p>
                <p>Note that requests via the API are always tagged with the status 'completed' as they do not require
                    human intervention.</p>
                <div className="table-responsive-md">
                <Preloader loadingStyle="swirl" loading={this.props.loading}>
                        <MaterialTable
                            columns={[
                                {title: 'Trade Ref Id', field:'tradeRefID',
                                render: rowData => <Link  className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline" to={`/requests/details/${rowData.tradeRequestId}`}>
                                                     { rowData.tradeRefID ||  rowData.tradeRequestId }
                                                  </Link>
                            },
                                { title: 'Type', field: 'quoteType'},
                                { title: 'Origin', field: 'originPort',
                                render: rowData => <span className="trades__overflow">{this.getCountryName(rowData.originPort)}    
                                </span> 
                            },
                                { title: 'Destination Port', field: 'destinationPort',
                                render: rowData => <span className="trades__overflow">{rowData.destinationPort==="" ?"--": this.renderPortLabel(rowData.destinationPort)}   
                                </span> 
                            },
                                { title: 'Incoterm', field: 'incoterm'},
                                { title: 'Rate', field: 'rate',
                                render: rowData => <NumberFormat 
                                value={rowData.rate} 
                                displayType={'text'} 
                                thousandSeparator={true}
                                 renderText={value => <span className="trades__overflow">{value!=="" ? '$ '+value : '--'}</span>} >
                                 </NumberFormat>},
                                 { title: 'Eventual Additional Condition', field: 'eventualAdditionalCondition',
                                render: rowData => <span
                                className="trades__overflow">{(rowData.eventualAdditionalCondition!==""? rowData.eventualAdditionalCondition :"--")}</span>},

                                { title: 'Status', field: 'status',
                                render: rowData => <span className={this.returnStatusNumber(rowData.status)} >{this.renderStatusFinal(rowData.status)}
                                
                            </span> 
                            },
                                { title: 'Requested', field: 'requestedDate',defaultSort: 'desc',
                                render: rowData => <span
                                className="trades__overflow">{moment(rowData.requestedDate).format(DATEFORMATHOURS)}</span>},
                                { title: 'Response', field: 'responseDate',
                                render: rowData => <span
                                className="trades__overflow">{(rowData.responseDate!==""? moment(rowData.responseDate).format(DATEFORMATHOURS):"--")}</span>},
                                { title: 'Action', sorting: false, render: rowData => <div className="action-btn">
                                <Tooltip title="Details of Inspection " placement="top-start" arrow >
                                <Link className="btn-circle circle-btn admin" to={`/inspectionQuoteDetails/${rowData.tradeRequestId}/${rowData.inspectionQuoteId}`}> 
                                        <FontAwesomeIcon icon={faEye} />
                                        </Link>
                                </Tooltip>

                            </div>},
                               
                            ]}
                            data={this.getItemsTwo()}
                            title="Final Offer"
                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                SortArrow: ArrowUpward,
                                Add: Add,
                                Edit: Edit,
                                DeleteOutline: DeleteOutline,
                                ResetSearch: Clear,
                                SaveIcon: SaveIcon
                            }}
                            options={{
                                pageSize: 5,
                                pageSizeOptions: [10, 15, 20, 30],
                                rowStyle: {
                                    borderColor: '#c3e6cb',
                                    backgroundColor: '##00ff301c'
                                },
                                thirdSortClick: false
                            }}
                        />
                    </Preloader>
                </div>
            </div>

        </div>
    </div>
                <Footer />
            </React.Fragment>
        );
      
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        user: state.account.user,
        inspectionQuote:state.inspectionQuote,
        loading: state.loading.groupLoading,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            inspectionProvisionalList,
            inspectionFinalList,
            navigate: path => push(path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(InspectionQuoteOverview);