import _ from "lodash";
import { EnumsService } from "./service.utils";

class Rice {
    constructor(ref, label, ricexCode, shape, grainSize, minLength, fractionLength, type, finishDegree, grade, millingDegree, brokenPct, consumptionType, wholeGrainYield, totalYield, purity, variety, polishing, sortexed) {
        this.ref = ref;
        this.label = label;
        this.ricexCode = ricexCode;
        this.shape = shape;
        this.grainSize = grainSize;
        this.minLength = minLength;
        this.fractionLength = fractionLength;
        this.type = type;
        this.finishDegree = finishDegree;
        this.grade = grade;
        this.millingDegree = millingDegree;
        this.brokenPct = brokenPct;
        this.consumptionType = consumptionType;
        this.wholeGrainYield = wholeGrainYield;
        this.totalYield = totalYield;
        this.purity = purity;
        this.variety = variety;
        this.polishing = polishing;
        this.sortexed = sortexed;
        this.setTag()
    }

    setTag() {
        let tag = "".concat(this.shape ? this.shape.shortKey : 'NA')
            .concat('-').concat(this.grainSize ? this.grainSize.shortKey : 'NA')
            .concat('-LGT').concat(this.minLength ? this.minLength : 'NA')
            .concat('-FCT').concat(this.fractionLength ? this.fractionLength : 'NA')
            .concat('-').concat(this.type ? this.type.shortKey : 'NA')
            .concat('-').concat(this.finishDegree ? this.finishDegree.shortKey : 'NA')
            .concat('-').concat(this.grade ? this.grade.shortKey : 'NA')
            .concat('-').concat(this.millingDegree ? this.millingDegree.shortKey : 'NA')
            .concat('-BPC').concat(this.brokenPct ? this.brokenPct : 'NA');
        if (Array.isArray(this.consumptionType)) {
            for (let type of this.consumptionType) {
                tag = tag.concat('-').concat(type.shortKey);
            }
        } else {
            tag = tag.concat('-').concat(this.consumptionType ? this.consumptionType.shortKey : 'NA');
        }
        tag = tag.concat('-WGY').concat(this.wholeGrainYield ? this.wholeGrainYield.shortKey : 'NA')
            .concat('-TY').concat(this.totalYield ? this.totalYield.shortKey : 'NA')
            .concat('-P').concat(this.purity ? this.purity.shortKey : 'NA')
            .concat('-').concat(this.polishing ? this.polishing.shortKey : 'NA')
            .concat('-').concat(this.sortexed ? this.sortexed.shortKey : 'NA');
        this.tag = tag;
    }
}

const SHAPE = {
    BG: { key: 'BG', label: 'Broken Grain', shortKey: 'BG' },
    LG: { key: 'LG', label: 'Long Grain', shortKey: 'LG' },
    MG: { key: 'MG', label: 'Medium Grain', shortKey: 'MG' },
    RG: { key: 'RG', label: 'Round Grain', shortKey: 'RG' }

}

const GRAIN_SIZE = {
    BROKEN: { key: ' BROKEN', label: 'Broken Grain', shortKey: 'BR' },
    WHOLE: { key: 'WHOLE', label: 'Whole Grain', shortKey: 'WH' },

}

const TYPE = {
    BASMATI: { key: 'BASMATI', label: 'Basmati', shortKey: 'BA' },
    CARGO: { key: 'CARGO', label: 'Cargo', shortKey: 'CA' },
    FRAGRANT: { key: 'FRAGRANT', label: 'Fragrant', shortKey: 'FR' },
    GLUTINOUS: { key: 'GLUTINOUS', label: 'Glutinous', shortKey: 'GL' },
    JASMINE: { key: 'JASMINE', label: 'Jasmine', shortKey: 'JS' },
    JAPONICA: { key: 'JAPONICA', label: 'Japonica', shortKey: 'JA' },
    MILLED: { key: 'MILLED', label: 'Milled', shortKey: 'MI' },
    ORGANIC: { key: 'ORGANIC', label: 'Organic', shortKey: 'OG' },
    PADDY: { key: 'PADDY', label: 'Paddy', shortKey: 'PD' },
    PARBOILED: { key: 'PARBOILED', label: 'Parboiled', shortKey: 'PB' },
}

const FINISH_DEGREE = {
    CARGO: { key: 'CARGO', label: 'Cargo', shortKey: 'CA' },
    FULLY_MILLED: { key: 'FULLY_MILLED', label: 'Fully_Milled', shortKey: 'FM' },
    SEMI_MILLED: { key: ' SEMI_MILLED', label: 'Semi Milled', shortKey: 'SM' }
}

const GRADE = {

}

const VARIETY = {
    HAM_CHAU: { key: 'HAM_CHAU', label: 'Ham Chau', shortKey: 'HC' },
    KDM: { key: 'KDM', label: 'KDM', shortKey: 'KDM' }

}

const POLISHING = {
    DOUBLE: { key: 'DOUBLE', label: 'Doulbe Polished', shortKey: 'DBL' },
    SLIGHTLY: { key: 'SLIGHTLY', label: 'Slightly Polished', shortKey: 'SLT' },
    SILKY: { key: 'SILKY', label: 'Silky Polished', shortKey: 'SLK' },
    SINGLE: { key: 'SINGLE', label: 'Singgle Polished', shortKey: 'SGL' },

}

const CONSUMPTION_TYPE = {
    ANIMAL_FEED: { key: 'ANIMAL_FEED', label: 'Animal Feed', shortKey: 'AFD' },
    BREWERY: { key: 'BREWERY', label: 'Brewery', shortKey: 'BRW' },
    DIRECT: { key: 'DIRECT', label: 'Direct', shortKey: 'DIR' },
    FLOUR: { key: 'FLOUR', label: 'Flour', shortKey: 'FLR' },
    DIRECT_HUMAN: { key: 'DIRECT_HUMAN', label: 'Direct human', shortKey: 'DH' },
    HUMAN: { key: 'HUMAN', label: 'Human', shortKey: 'HUM' },
    INDUSTRIAL: { key: 'INDUSTRIAL', label: 'Industrial', shortKey: 'IND' },
    MILLING: { key: 'MILLING', label: 'For Milling', shortKey: 'MIL' },
    PETFOOD: { key: 'PETFOOD', label: 'Pet Food', shortKey: 'PFD' },


}

const SORTEXED = {
    SORTEXED: { key: 'SORTEXED', label: 'Sortexed', shortKey: 'SORT' },
    SORTEX_REJECTS: { key: ' SORTEX_REJECTS', label: 'Sortex Rejects', shortKey: 'RJCT' }
}


export const RICE_COUNTRY_MAP = {
    "AR": [
        new Rice('LG_CARGO_RICE_5%', 'LG cargo rice 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PADDY', 'LG paddy', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_10%', 'LG white 10%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_5%', 'LG white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MEDIUM_COARSE_WHITE_5%', 'Medium coarse white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "BR": [
        new Rice('LG_PADDY_RICE_NO_1', 'LG paddy rice no 1', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_5%', 'LG white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_SORTEXED', 'LG parboiled milled 5% sortexed', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('ANIMAL_FEED_100%_BROKEN', 'Animal feed 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PET_FOOD_100%_BROKEN', 'Pet food 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "KH": [
        new Rice('SEN_KRA_OB_WHITE_5%', 'Sen Kra Ob white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PKHA_MALIS_WHITE_5%', 'Pkha Malis white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_FRAGRANT_WHITE_100%_BROKEN_A1_SUPER', 'LG Fragrant white 100% broken A1 super', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "CN": [
        new Rice('RG_WHITE_5%', 'RG white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('RG_WHITE_10%', 'RG white 10%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_25%', 'LG white 25%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_SORTEXED', 'LG parboiled milled 5% sortexed', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
    ],
    "EC": [
        new Rice('PILADO_EXTRA_1/5', 'Pilado Extra 1/5', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PILADO_EXTRA_1/8', 'Pilado Extra 1/8', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PILADO_SUPERIOR_2/10', 'Pilado Superior 2/10', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PILADO_SUPERIOR_2/15', 'Pilado Superior 2/15', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "EG": [
        new Rice('RG_WHITE_N°2,5%', 'RG white n°2,5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('RG_WHITE_N°3,10%', 'RG white n°3,10%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MG_WHITE_N°2,5%', 'MG white n°2,5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MG_WHITE_N°3,10%', 'MG white n°3,10%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('WHITE_100%_BROKEN', 'White 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "GR": [
        new Rice('MG_WHITE_5%_RONALDO', 'MG white 5% Ronaldo', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MG_WHITE_5%_LUNA', 'MG white 5% Luna', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MG_WHITE_5%_LEONARDO', 'MG white 5% Leonardo', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_5%_THAI_BONNET', 'LG white 5% Thai Bonnet', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_5%_CLADIO', 'LG white 5% Cladio', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LARGE_GRAIN_WHITE_5%_CAROLINA_TYPE', 'Large grain white 5% Carolina type', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LARGE_GRAIN_WHITE_5%_GLORIA', 'Large grain white 5% Gloria', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LARGE_GRAIN_WHITE_5%_CAMEO', 'Large grain white 5% Cameo', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "GY": [
        new Rice('LG_PADDY', 'LG paddy', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_5%', 'LG white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_15%', 'LG white 15%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_CARGO_5%', 'LG cargo 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "IN": [
        new Rice('LG_WHITE_RICE_5%', 'LG white rice 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_5%_IR64', 'LG white rice 5% IR64', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_5%_IR36', 'LG white rice 5% IR36', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_5%_IR8', 'LG white rice 5% IR8', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_25%', 'LG white rice 25%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_25%_FAN_CLEANED', 'LG white rice 25% fan cleaned', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_PR-106', 'LG parboiled milled 5% PR-106', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_IR-64', 'LG parboiled milled 5% IR-64', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_IR36', 'LG parboiled milled 5% IR36', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_IR8', 'LG parboiled milled 5% IR8', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('SWARNA_WHITE_5%', 'Swarna white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('SWARNA_WHITE_25%', 'Swarna white 25%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MG_WHITE_5%_IDLU', 'MG white 5% IDLU', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_100%_BROKEN', 'LG parboiled 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('TRADITIONAL_WHITE_BASMATI_2%', 'Traditional white Basmati 2%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('TRADITIONAL_BROWN_BASMATI_2%', 'Traditional brown Basmati 2%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('TRADITIONAL_PARBOILED_MILLED_BASMATI_2%', 'Traditional parboiled milled Basmati 2%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PUSA_WHITE_BASMATI_2%', 'Pusa white Basmati 2%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PUSA_BROWN_BASMATI_2%', 'Pusa brown Basmati 2%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PUSA_PARBOILED_MILLED_BASMATI_2%', 'Pusa parboiled milled Basmati 2%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('WHITE_BASMATI_2%_1121', 'White basmati 2% 1121', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PARBOILED_MILLED_BASMATI_2%_1121', 'Parboiled milled Basmati 2% 1121', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('WHITE_BASMATI_2%_386', 'White Basmati 2% 386', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "IT": [
        new Rice('LG_WHITE_RICE_5%_INDICA', 'LG white rice 5% Indica', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_15%_INDICA', 'LG white rice 15% Indica', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('RG_WHITE_5%', 'RG white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_SORTEXED', 'LG parboiled milled 5% sortexed', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('CARNAROLI_WHITE_5%', 'Carnaroli white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('BALDO_WHITE_5%', 'Baldo white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LOTO_WHITE_5%', 'Loto white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LOTO_TYPE_WHITE_5%', 'Loto type white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('RIBE_(LONG_A)_WHITE_5%', 'Ribe (Long A) white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('ROMA_WHITE_5%', 'Roma white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('BARRONE_WHITE_5%', 'Barrone white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('ARBORIO_WHITE_5%', 'Arborio white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('VOLANO_WHITE_5%', 'Volano white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('BREWERY_100%_BROKEN', 'Brewery 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MEZZAGRANA_JAPONICA_100%_BROKEN', 'Mezzagrana Japonica 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('CORPETTO_100%_BROKEN', 'Corpetto 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PARBOILED_REJECTS_100%_BROKEN', 'Parboiled rejects 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_100%_BROKEN_INDICA', 'LG parboiled 100% broken Indica', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "MM": [
        new Rice('LG_WHITE_5%', 'LG white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_25%', 'LG white 25%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_CARGO_RICE_5%', 'LG cargo rice 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN_B1&2', 'LG white 100% broken B1&2', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN_A1&2', 'LG white 100% broken A1&2', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN_B1,2&3', 'LG white 100% broken B1,2&3', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_SORTEXED', 'LG parboiled milled 5% sortexed', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "PK": [
        new Rice('LG_WHITE_RICE_5%', 'LG white rice 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_10%', 'LG white rice 10%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_15%', 'LG white rice 15%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_25%', 'LG white rice 25%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_SORTEXED', 'LG parboiled milled 5% sortexed', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('WHITE_BASMATI_2%_1121', 'White basmati 2% 1121', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PARBOILED_MILLED_BASMAT_2%_1121', 'Parboiled milled basmat 2% 1121', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('WHITE_BASMATI_2%_SUPER_KERNEL', 'White basmati 2% Super Kernel', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('BROWN_BASMATI_2%_SUPER_KERNEL', 'Brown Basmati 2% Super Kernel', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PARBOILED_MILLED_BASMATI_2%_SUPER_KERNEL', 'Parboiled milled basmati 2% Super Kernel', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "PY": [
        new Rice('LG_PADDY_RICE_NO_1', 'LG paddy rice no 1', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_5%', 'LG white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_SORTEXED', 'LG parboiled milled 5% sortexed', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('ANIMAL_FEED_100%_BROKEN', 'Animal feed 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PET_FOOD_100%_BROKEN', 'Pet food 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "PE": [
        new Rice('PILADO_SUPERIOR_2/10', 'Pilado Superior 2/10', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PILADO_SUPERIOR_2/15', 'Pilado Superior 2/15', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "ES": [
        new Rice('LG_WHITE_5%_INDICA', 'LG white 5% Indica', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_INDICA', 'LG parboiled milled 5% Indica', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('BAHIA_WHITE_5%', 'Bahia white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('BALILLA_WHITE_5%', 'Balilla white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('BOMBA_WHITE_5%_', 'Bomba white 5% ', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "UY": [
        new Rice('LG_PADDY_RICE_NO_1', 'LG paddy rice no 1', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_5%', 'LG white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_5%_OLIMAR', 'LG white 5% Olimar', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_SORTEXED', 'LG parboiled milled 5% sortexed', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('ANIMAL_FEED_100%_BROKEN', 'Animal feed 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('PET_FOOD_100%_BROKEN', 'Pet food 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "SR": [
        new Rice('LG_WHITE_5%', 'LG white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_15%', 'LG white 15%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_CARGO_RICE_5%', 'LG cargo rice 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "TH": [
        new Rice('LG_WHITE_RICE_100%_GRADE_B', 'LG white rice 100% grade B', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_5%', 'LG white rice 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_10%', 'LG white rice 10%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_15%', 'LG white rice 15%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_25%', 'LG white rice 25%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN_A1_SUPER', 'LG white 100% broken A1 super', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_100%_SORTEXED', 'LG parboiled milled 100% sortexed', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_SORTEXED', 'LG parboiled milled 5% sortexed', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_FRAGRANT_WHITE_5%_HOM_MALI_(PURITY_92%)', 'LG Fragrant white 5% Hom Mali (purity 92%)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_FRAGRANT_WHITE_100%_BROKEN_A1_SUPER', 'LG Fragrant white 100% broken A1 super', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_FRAGRANT_WHITE_PATHUMTHANI_5%', 'LG Fragrant white Pathumthani 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_GLUTINOUS_WHITE_10%', 'LG glutinous white 10%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_GLUTINOUS_100%_BROKEN', 'LG glutinous 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "US": [
        new Rice('LG_PADDY_2/55/70', 'LG Paddy 2/55/70', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_2/4_HARD_MILLED', 'LG 2/4 hard milled', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_3/15_HARD_MILLED_', 'LG 3/15 hard milled ', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_5/20_HARD_MILLED', 'LG 5/20 hard milled', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_1/4', 'LG parboiled milled 1/4', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('RG_CALIFORNIA_WHITE_1/4', 'RG California white 1/4', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MG_CALIFORNIA_WHITE_1/4', 'MG California white 1/4', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MG_CALROSE_WHITE_1/4', 'MG Calrose white 1/4', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MG_PADDY_1/58/69', 'MG Paddy 1/58/69', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ],
    "VN": [
        new Rice('LG_WHITE_RICE_5%', 'LG white rice 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_10%', 'LG white rice 10%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_5%_OM_5451', 'LG white rice 5% OM 5451', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_5%_OM_6976', 'LG white rice 5% OM 6976', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_15%', 'LG white rice 15%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_RICE_25%', 'LG white rice 25%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_WHITE_100%_BROKEN', 'LG white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_PARBOILED_MILLED_5%_SORTEXED', 'LG parboiled milled 5% sortexed', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_JASMINE_WHITE_5%_MAX,(PURITY_90%)', 'LG Jasmine white 5% max,(purity 90%)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_JASMINE_WHITE_5%_(PURITY_70%)', 'LG Jasmine white 5% (purity 70%)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_JASMINE_100%_BROKEN', 'LG Jasmine 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_FRAGRANT_WHITE_5%_JR', 'LG Fragrant white 5% JR', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_FRAGRANT_WHITE_5%_NANG_HOA', 'LG Fragrant white 5% NANG HOA', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_FRAGRANT_WHITE_5%_OM_4900', 'LG Fragrant white 5% OM 4900', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_FRAGRANT_WHITE_5%_ST21', 'LG Fragrant white 5% ST21', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_FRAGRANT_WHITE_5%_KDM', 'LG Fragrant white 5% KDM', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_FRAGRANT_WHITE_100%_BROKEN', 'LG Fragrant white 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('JAPONICA_WHITE_5%', 'Japonica white 5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('MG_WHITE_(HAM_CHAU)5%', 'MG white (Ham Chau)5%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_GLUTINOUS_WHITE_10%', 'LG glutinous white 10%', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('LG_GLUTINOUS_100%_BROKEN', 'LG glutinous 100% broken', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null),
        new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    ]
}

const REF_OTHER = new Rice('REF_OTHER', 'Other (Please specify)', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);

export const RiceService = {

    getByCountry: (unloc) => {
        if (RICE_COUNTRY_MAP.hasOwnProperty(unloc)) {
            return RICE_COUNTRY_MAP[unloc];
        } else {
            return [REF_OTHER];
        }
    },

    //Return a List of available countries for a reference of rice
    //ie : {label: 'Japan', value: 'JP'}
    getAvailableCountries: (ref) => {
        let list = [];
        _.forEach(RICE_COUNTRY_MAP, (riceList, countryCode) => {
            if (!ref || _.find(riceList, (rice) => rice.ref === ref)) {
                list.push({ label: EnumsService.countries()[countryCode], value: countryCode });
            }
        });

        return list.sort();
    },

    //Return a List of available references of rice for a given country
    //ie : {label: 'Lg white rice 5 %', value: 'LG_WHITE_RICE_5%'}
    getAvailableReference: (unloc) => {
        let collection;
        if (unloc) {
            collection = RICE_COUNTRY_MAP[unloc];
        } else {
            collection = {};
            _.forEach(RICE_COUNTRY_MAP, (riceList) => {
                riceList.map(rice => {
                    collection[rice.ref] = rice;
                    return true;
                });
                return true;
            });
        }
        return _.chain(collection)
            .flatMap((rice, ref) => ({ label: rice.label, value: rice.ref }))
            .orderBy(["value"], ["DESC"])
            .reject(o => o.value === "REF_OTHER")
            .concat({ label: "Other (please specify)", value: "REF_OTHER" })
            .value()

    },

    getAvailableReferencesFromArray: (unlocArray = []) => {
        let result = [];
        switch (unlocArray.length) {
            case 0:
                //Return all riceTypes
                result = _.chain(RICE_COUNTRY_MAP)
                    .map(item => (item))
                    .flatten()
                    .uniqWith(_.isEqual)
                    .reject(o => o.value === "REF_OTHER")
                    .orderBy(["label"], ["DESC"])
                    .concat({ label: "Other (please specify)", ref: "REF_OTHER" })
                    .value();
                break;
            case 1:
                //Return riceTypes of the country
                result = RICE_COUNTRY_MAP[unlocArray[0]];
                break;
            default:
                //Retrun riceTypes present in all the countries
                const riceTypeArrays = unlocArray.map(loc => RICE_COUNTRY_MAP[loc]);
                const intersection = _.intersectionBy(...riceTypeArrays, 'ref');
                result = _.chain(intersection)
                    .reject(o => o.value === "REF_OTHER")
                    .orderBy(["label"], ["DESC"])
                    .value();
                break;
        }
        result = result.map(rice => ({ value: rice.ref, label: rice.label }))
        return result;
    },

    getSelectedCountries: (unloc = []) => {
        let result = [];
        _.forEach(unloc, countryCode => {
            result.push({ label: EnumsService.countries()[countryCode], value: countryCode })
        })
        return result;
    },

    getByRef: (ref) => {
        let res;
        main:
        for (let key in RICE_COUNTRY_MAP) {
            for (let rice of RICE_COUNTRY_MAP[key]) {
                if (rice.ref === ref) {
                    res = rice;
                    break main;
                }
            }
        }
        return res;
    },

    getFullMap: () => {
        return RICE_COUNTRY_MAP;
    },
    getFullList: () => {
        return _.chain(RICE_COUNTRY_MAP)
            .flatMap((value, key) => {
                value.map(item => {
                    item.unloc = key
                    return item;
                });
                return value;
            })
            .orderBy(["label", "unloc"], ["DESC", "DESC"])
            .value();
    },
    enums: {
        REF_OTHER: 'REF_OTHER',
        SHAPE: SHAPE,
        GRAIN_SIZE: GRAIN_SIZE,
        TYPE: TYPE,
        FINISH_DEGREE: FINISH_DEGREE,
        GRADE: GRADE,
        CONSUMPTION_TYPE: CONSUMPTION_TYPE,
        VARIETY: VARIETY,
        POLISHING: POLISHING,
        SORTEXED: SORTEXED

    }
}

