import React from 'react';
import ConfirmModal from './ConfirmModal';

/**
 * Display confirm modal 
 *
 * @since  x.x.x
 * @access public
 *
 * @type     {React.Component}
 * @property {content}
 * @property {title}
 * @property {onCancel}
 * @property {onConfirm}
 * @property {show}
 */
class ConfirmModalDeclineAll extends React.Component {

    constructor(props){
        super(props);
        this.state ={ 
            declineReason: ""
        }
        this.onDeclineReasonChange = this.onDeclineReasonChange.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    onDeclineReasonChange(e){
        this.setState({declineReason: e.target.value});
    }

    onConfirm(){
        this.props.onConfirm(this.state.declineReason);
    }

    render() {
        const { 
            loading = false,
            isCounter = true,
            fromCompanyName
        } = this.props;

        return <ConfirmModal loading={loading}
            title={`You are about to decline ${isCounter?'a counter':'an offer'} from ${fromCompanyName}`}
            buttonClass = "bc-counter mr-3"
            buttonLabel = "Decline" 
            onConfirm = {this.onConfirm}>
            <p className="pb-2">Please provide a reason for this rejection and click 'Confirm' to continue or click 'Back' to return to the previous screen.</p>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="label" htmlFor="resonForRejection">Reason for Rejection</label>
                        <textarea id="resonForRejection" 
                            className="form-control textarea" 
                            onChange={this.onDeclineReasonChange}
                            placeholder={`Provide here message to ${fromCompanyName}`} rows='3'/>
                    </div>
                </div>
            </div>
        </ConfirmModal>
    }

}

export default ConfirmModalDeclineAll;


