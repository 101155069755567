import { PlatformAdminApi } from '../services/service.api';
import { LoadingKycData, LoadingFileUpload } from './module.loading';
export const KYC_COMPANY_LIST = 'KYC_COMPANY_LIST';
export const KYC_JOINING_REQUEST_LIST = 'KYC_JOINING_REQUEST_LIST';
export const KYC_PENDING_REQUEST = 'KYC_PENDING_REQUEST';
export const COMPANY_DETAIL = 'COMPANY_DETAIL';
export const APPROVE_REQUEST = 'APPROVE_REQUEST';
export const REJECT_REQUEST = 'REJECT_REQUEST';
export const APPROVE_KYC = 'APPROVE_KYC';
export const REJECT_KYC = 'REJECT_KYC';
export const BLOCK_COMPANY = 'BLOCK_COMPANY';
export const UNBLOCK_COMPANY = 'UNBLOCK_COMPANY';
export const DOCUMENT_URL = 'DOCUMENT_URL';
// export const COMPANY_DETAIL = 'COMPANY_DETAIL';
// export const COMPANY_DETAIL = 'COMPANY_DETAIL';
export const AUDIT_LOG = 'AUDIT_LOG';
export const KYC_SAVE = 'KYC_SAVE';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const SHOW_ICON_LABEL = 'SHOW_ICON_LABEL';
export const SHOW_OVERLAY_MENU = 'SHOW_OVERLAY_MENU';


const initialState = {
    company: undefined,
    document: undefined,
    kycCompanyList: null,
    kycJoiningRequestList: null,
    kycPendingList: null,
    log: null,
    kycStatus: null,
    fileUpload: null,
    showIconLabel: true,
    showOverlayMenu: false
};

export default (state = initialState, action= {}) => {
    switch (action.type) {

        case KYC_COMPANY_LIST:
            return {
                ...state,
                kycCompanyList: action.payload
            };
        case KYC_JOINING_REQUEST_LIST:
            return {
                ...state,
                kycJoiningRequestList: action.payload
            }
        case KYC_PENDING_REQUEST:
            return {
                ...state,
                kycPendingList: action.payload
            }
        case COMPANY_DETAIL:
        case APPROVE_REQUEST:
        case REJECT_REQUEST:
        case APPROVE_KYC:
        case REJECT_KYC:
        case BLOCK_COMPANY:
        case UNBLOCK_COMPANY:
            return {
                ...state,
                company: action.payload
            }
        case DOCUMENT_URL:
            return {
                ...state,
                document: action.payload
            }
        case AUDIT_LOG:
            return {
                ...state,
                log: action.payload
            }
        case KYC_SAVE:
            return {
                ...state,
                kycStatus: action.payload
            }
        case FILE_UPLOAD:
            return {
                ...state,
                fileUpload: action.payload
            }
        case SHOW_ICON_LABEL:
            return {
                ...state,
                showIconLabel: action.payload
            }
        case SHOW_OVERLAY_MENU:
            return {
                ...state,
                showOverlayMenu: action.payload
            }
        default:
            return state;
    }
};


export const getCompanyList = () => {
    return dispatch => {
        LoadingKycData(dispatch, true);
        PlatformAdminApi.getCompanyList()
            .then(r => {
                LoadingKycData(dispatch, false);
                dispatch({
                    type: KYC_COMPANY_LIST,
                    payload: { ...r.data }
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });

    }
};

export const getLogForAdmin = () => {
    return dispatch => {
        LoadingKycData(dispatch, true);
        PlatformAdminApi.getLogForAdmin()
            .then(r => {
                LoadingKycData(dispatch, false);
                dispatch({
                    type: AUDIT_LOG,
                    payload: { ...r.data }
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });
    }
};

export const getKycJoiningRequestList = () => {
    return dispatch => {
        LoadingKycData(dispatch, true);
        PlatformAdminApi.getKycJoiningRequestList()

            .then(r => {
                LoadingKycData(dispatch, false);
                dispatch({
                    type: KYC_JOINING_REQUEST_LIST,
                    payload: { ...r.data }
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });

    }
};

export const getKycPendingList = () => {
    return dispatch => {
        LoadingKycData(dispatch, true);
        PlatformAdminApi.getKycPendingList()
            .then(r => {
                LoadingKycData(dispatch, false);
                dispatch({
                    type: KYC_PENDING_REQUEST,
                    payload: { ...r.data }
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });

    }
};

export const getCompany = (id, cb) => {
    return dispatch => {
        LoadingKycData(dispatch, true);
        PlatformAdminApi.getCompanyDetails(id)
            .then(r => {
                LoadingKycData(dispatch, false);
                dispatch({
                    type: COMPANY_DETAIL,
                    payload: { ...r.data }
                });
                cb({...r.data.company});
            }, () => {
                dispatch({
                    type: COMPANY_DETAIL,
                    payload: {}
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });

    }
};

export const approveRequest = (id, cb) => {
    return dispatch => {
        PlatformAdminApi.approveRequest(id)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });

    }
};
export const reapproveRequest = (id, cb) => {
    return dispatch => {
        PlatformAdminApi.reapproveRequest(id)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });

    }
};

export const changeCompanyType = (id, companyType, cb) => {
    return dispatch => {
        LoadingKycData(dispatch, true);
        PlatformAdminApi.changeCompanyType(id, companyType)
            .then(r => {
                LoadingKycData(dispatch, false);
                cb({ success: true });
            }, () => {
                LoadingKycData(dispatch, false);
                cb({ success: false });
            })
            .catch((error) => {
                LoadingKycData(dispatch, false);
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });

    }
};

export const changeRegNumber = (id, regNumber, cb) => {
    return dispatch => {
        LoadingKycData(dispatch, true);
        PlatformAdminApi.changeRegNumber(id, regNumber)
            .then(r => {
                LoadingKycData(dispatch, false);
                cb({ success: true });
            }, () => {
                LoadingKycData(dispatch, false);
                cb({ success: false });
            })
            .catch((error) => {
                LoadingKycData(dispatch, false);
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });

    }
};

export const rejectRequest = (id, reason, cb) => {
    return dispatch => {
        PlatformAdminApi.rejectRequest(id, reason)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });

    }
};

export const approveKYC = (id, cb) => {
    return dispatch => {
        PlatformAdminApi.approveKYC(id)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });

    }
};

export const holdKYC = (id, cb) => {
    return dispatch => {
        PlatformAdminApi.holdKYC(id)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });
    }
};

export const unholdKYC = (id, cb) => {
    return dispatch => {
        PlatformAdminApi.unholdKYC(id)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });
    }
};

export const sendKYCReminder = (id, cb) => {
    return dispatch => {
        PlatformAdminApi.sendKYCReminder(id)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });
    }
};

export const rejectKYC = (id, reason, cb) => {
    return dispatch => {
        PlatformAdminApi.rejectKYC(id, reason)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });
    }
};

export const resubmitKYC = (id, reason, cb) => {
    return dispatch => {
        PlatformAdminApi.resubmitKYC(id, reason)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });
    }
};

export const blockCompany = (id, reason, cb) => {
    return dispatch => {
        PlatformAdminApi.blockCompany(id, reason)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });

    }
};

export const unblockCompany = (id, reason, cb) => {
    return dispatch => {
        PlatformAdminApi.unblockCompany(id, reason)
            .then(r => {
                cb({ success: true });
            }, () => {
                cb({ success: false });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                cb({ success: false });
            });

    }
};

export const getDocumentUrl = (params, cb) => {
    return dispatch => {
        PlatformAdminApi.getDocumentUrl(params)
            .then(r => {
                dispatch({
                    type: DOCUMENT_URL,
                    payload: { ...r.data }
                });
                cb(r.data);
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });

    }
}

export const kycSave = (companyId, params, cb) => {
    return dispatch => {
        PlatformAdminApi.kycSave(companyId, params)
            .then(r => {
                dispatch({
                    type: KYC_SAVE,
                    payload: { ...r.data }
                });
                cb(r.data);
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });

    }
};

export const kycDocumentUpload = (params, cb) => {
    return dispatch => {
        LoadingFileUpload(dispatch, true);
        PlatformAdminApi.kycDocumentUpload(params)
            .then(r => {
                LoadingFileUpload(dispatch, false);
                dispatch({
                    type: FILE_UPLOAD,
                    payload: { ...r.data }
                });
                cb(r.data);
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });

    }
};

export const changeShowIconLabel = (showIconLabel) => {
    return dispatch => {
        dispatch({
            type: SHOW_ICON_LABEL,
            payload: !showIconLabel
        })
    }
}

export const changeShowOverlayMenu = (showOverlayMenu) => {
    return dispatch => {
        dispatch({
            type: SHOW_OVERLAY_MENU,
            payload: showOverlayMenu
        })
    }
}

