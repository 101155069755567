import React from 'react';
import moment from 'moment';
import DetailTable from '../../requests/detail/DetailTable';
import TradeImagePreview from '../../requests/components/TradeImagePreview';
import { DATEFORMAT, 
    DATEFORMATHOURS, 
    PAYMENTPERIODOPT, 
    DISCHARGE_VALUES, 
    PACKAGING, 
    getDischargeRateLabel,
    getDischargeLabel, 
    CURRENCYOPT,
    RICECATEGORY_LABEL,
    REQUEST_PRIVACY_TYPE,
    OFFER_TYPES,
    BID_STATUS,
    SHIPPINGTYPES
} from '../../../../services/service.values';
import {formatIncoterm, formatShipping} from '../../../../services';
import { RiceService } from '../../../../services/service.rice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { PortService, renderSelectedOrigins, EnumsService,getLocalTimezone } from '../../../../services/service.utils';
import NumberFormat from 'react-number-format';
import { 
    renderDocumentsTitle, 
    renderDocumentsForm, 
    renderMultipleOriginPrice, 
    renderCountry, 
    renderMultiOriginLoadPort,
    renderPrice 
} from '../../requests/detail/detailTable/Renderers';
import {getInspectionCompanyName} from '../../../../services/service.format';
import TradeQuantityFormatter from '../../../components/Formatter/TradeQuantityFormatter';

const Countries = EnumsService.countries();

class DetailedInfos extends React.Component{

    constructor(props){
        super(props);
        const {user, series,isContractInfo,isDeclined} = this.props;
        this.initialTrade = series[0].data;
        this.ownerView = user.companyId === this.initialTrade.fromCompanyName;
        
        this.withoutPrice = this.initialTrade.price === 0 || this.initialTrade.price === "";
          
        this.initialOrigins = this.initialTrade.origin? this.initialTrade.origin.split(','):'';
        this.isTender = this.props.trade.items.single.privacyType === 'TENDER';
        this.isTenderWithMultiOrigin = this.isTender && this.initialOrigins.length > 1;
        
        this.multiOriginPrices = [{ key: "price title", label: "Price per MT(Origin)", tdClass: "bg-light font-weight-bold" }];
        this.multiOriginLoadPorts = [{ key: "port of load", label: "Country of Load (Port of Load)", tdClass: "bg-light font-weight-bold" }]
        if(this.isTenderWithMultiOrigin){ 
            for(let i = 0; i < this.initialOrigins.length; i++){
                this.multiOriginPrices.push({
                    key: `price${this.initialOrigins[i]}`, 
                    label: `${i+1}. ${Countries[this.initialOrigins[i]]}`, 
                    format: (value, serie, index) => {
                        if(index === 0 && !value && this.props.trade.items.single.bestPrice){
                            return "Best Price";
                        }
                        return renderMultipleOriginPrice(this.initialOrigins[i], value, serie, index)
                    }
                })
                this.multiOriginLoadPorts.push({
                    key:  `loadPort${this.initialOrigins[i]}`,
                    label: `${i+1}. ${Countries[this.initialOrigins[i]]}`,
                    format: (port, serie, index) => renderMultiOriginLoadPort(this.initialOrigins[i], port, serie, index)
                }) 
            }
        }

        this.fieldList = [
            { key: "status", label: "Action", highlightDiff: false, format : this.renderAction, tdClass: "font-weight-bold", trClass:"table-warning"},
            { key: "createdAt", label: "Date", highlightDiff: false, format: value => moment(value).format(DATEFORMATHOURS), trClass:"table-warning"}, 
            { key: "fromCompanyName", label: "From", highlightDiff: false, format: (value, serie) => serie.data.fromCompanyName?serie.data.fromCompanyName:serie.data.requestType === "BUY"?serie.data.buyer:serie.data.seller, trClass:"table-warning"},
        ];
        if(isDeclined){
            this.fieldList = [...this.fieldList, 
                { key: "declinedAt", label: "Declined At", highlightDiff: false, format: this.renderDeclineAt, trClass:"table-warning" },
                { key: "declinedByCompanyName", label: "Declined By", highlightDiff: false, format: (value, serie) => value?<strong>{value} ({serie.data.declinedByUser})</strong>:"-", trClass:"table-warning"},
                { key: "declineReason", label: "Declined Reason", highlightDiff: false,  trClass:"table-warning" },
            ];
        }
        else{
            this.fieldList = [...this.fieldList, 
                { key: "acceptedAt", label: `Accepted At (${getLocalTimezone()})`, highlightDiff: false, format: this.renderAcceptedAt, trClass:"table-warning" },
                { key: "acceptedByCompanyName", label: "Accepted By", highlightDiff: false, format: (value, serie) => value?<strong>{value} ({serie.data.acceptedByUserName})</strong>:"-", trClass:"table-warning"},
            ];
        }
        this.fieldList = [...this.fieldList,
            { key: "fields", label: "Field names", format: (value) => "", tdClass: "bg-light font-weight-bold", trClass:"table-light"},
            ...(this.isTenderWithMultiOrigin ? 
                [...this.multiOriginPrices] : 
                [{ key: "price", label: "Price", "customStyle": "row-price", format: this.renderPriceHistory, tdClass: this.tdClass}]),
            ...( (this.initialTrade.riceCategory && this.initialTrade.riceCategory !== "NON-ORGANIC" ) ? 
                [{ key: "riceCategory", label: "Rice Category", format: value => (RICECATEGORY_LABEL[this.initialTrade.riceCategory]|| this.initialTrade.riceCategory),tdClass: this.tdClass }]
                :
                []),
            { key: "riceType", label: "Type of Rice", format: value => (RiceService.getByRef(value) ? RiceService.getByRef(value).label : value), tdClass: this.tdClass },
            ...(this.initialTrade.riceTypeOther ? [{ key: "riceTypeOther", label: "Specify Type of Rice", tdClass: this.tdClass }] : []),

            { key: "incoterm", label: "Incoterms", tdClass: this.tdClass },
            ...((this.isTender && this.initialTrade.incoterm !== 'FOB') ? [
                { key: "optionalIncoterm", label: "Also accepting offers for incoterm", highlightDiff: false, format: () => this.props.trade.items.single.optionalIncoterm, tdClass: this.tdClass}
            ] : []),
            { key: "shipping", label: "Shipping", format: value => (SHIPPINGTYPES[value]||value), tdClass: this.tdClass },
            { key: "partialShipmentFlag", label: "Partial Shipment Allowed", format: value => value?"Yes":"No", tdClass: this.tdClass },
            { key: "inspection", label: "Inspection Company", format:(value, serie, index, series)=> getInspectionCompanyName(value, serie, index, series,this.props.trade.inspections), tdClass: this.tdClass },
            { key: "measure", label: "Quantity(MT)", format: (value, serie) => <TradeQuantityFormatter trade={serie.data}/>, tdClass: this.tdClass },
            { key: "tolerance", label: "Contractual Tolerance", format: (value,serie) => !value ? '-' : value === 0 ? `Min/Max in option of ${serie.data.incoterm==="FOB"?"Buyer":"Seller"}`: `+/- ${value}% in option of ${serie.data.incoterm==="FOB"?"Buyer":"Seller"}` , tdClass: this.tdClass },

            { key: "quality", label: "Quality Standard", tdClass: this.tdClass },
            { key: "packaging", label: "Packaging", format: value => (PACKAGING[value] || value ), tdClass: this.tdClass },
            ...(this.isTender ? []:
            [{ key: "euroConversionAvl", label: "Euro Conversion Available", format: (value) => { return (value) ? "Yes" : "No" } , tdClass: this.tdClass }]),
            ...(this.isTenderWithMultiOrigin ? 
                [...this.multiOriginLoadPorts] : 
                [{ key: "origin", label: "Origin", format: this.getCountryName, tdClass: this.tdClass },
                { key: "loadCountry", label: "Country of Load", format: renderCountry, tdClass: this.tdClass },
                { key: "loadPort", label: "Port of Load", format: this.renderPortLabel, tdClass: this.tdClass }]),
            { key: "destCountry", label: "Country of Destination", format: renderCountry, tdClass: this.tdClass },
            { key: "destPort", label: "Port of Destination", format: this.renderPortLabel, tdClass: this.tdClass },
            { key: "deliveryStartDate", label: "Delivery Start Date", format: (value) => moment(value).format(DATEFORMAT), tdClass: this.tdClass },
            { key: "deliveryEndDate", label: "Delivery End Date", format: (value) => moment(value).format(DATEFORMAT), tdClass: this.tdClass },
            { key: "discharge", label: "Load or Discharge Terms", format: this.renderDischarge, tdClass: this.tdClass },
            { key: "dischargeRate", label: "Load or Discharge Rate", format: this.renderDischargeRate, tdClass: this.tdClass },
            { key: "cropYear", label: "Crop Year", tdClass: this.tdClass },
            { key: "payment", label: "Payment Terms", tdClass: this.tdClass },
            { key: "paymentPeriod", label: "Payment Period", format: (value => PAYMENTPERIODOPT[value]), tdClass: this.tdClass },
            { key: "LCOpeningDate", label: "Letter of Credit to be Opened Latest On", format: value => value?moment(value).format(DATEFORMAT):"", tdClass: this.tdClass },
            { key: "ADIssuingDate", label: "Avalised Draft to be issued latest on", format: value => value?moment(value).format(DATEFORMAT):"", tdClass: this.tdClass },
            { key: "nameOfBank", label: "Name of Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "locationOfBank", label: "Location of Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "swiftCodeOfBank", label: "Swiftcode of Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "nameOfConfirmingBank", label: "Name of Confirming Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "locationOfConfirmingBank", label: "Location of Confirming Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "swiftCodeOfConfirmingBank", label: "Swiftcode of Confirming Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "downPaymentPercentage", label: "Down Payment", format: (value) => (value) ? `${value}%` : "NO", tdClass: this.tdClass },
            { key: "downPaymentPeriod", label: "Down Payment Period", tdClass: this.tdClass },
        ];
        if(isContractInfo){
            this.fieldList = [...this.fieldList, 
                { key: "contractValidateDate", label: `Expiry Date (${getLocalTimezone()})`, format: this.formatExpiryDateContract, tdClass: this.tdClass }
            ];
        }
        else{
            this.fieldList = [...this.fieldList, 
                { key: "bidValidateDate", label: `Expiry Date (${getLocalTimezone()})`, format: this.formatExpiryDate, tdClass: this.tdClass },
                ...(!this.isTender ? [{key: "option title", label: "Options", format: value => "", tdClass: "bg-light font-weight-bold" },
                { key: "optionsPackagingType", label: "Packaging", format: this.formatPackagingOption, tdClass: this.tdClass },
                { key: "optionsQualityType", label: "Quality Standard", format: this.formatQualityOption, tdClass: this.tdClass },
                { key: "optionsShippingType", label: "Shipping", format: this.formatShippingOption, tdClass: this.tdClass },
                { key: "optionsTypeOfRice", label: "Type of Rice", format: this.formatTypeOfRiceOption, tdClass: this.tdClass }] 
                : [])
                
            ];
        }
          
        this.fieldList = [...this.fieldList,
           
            { key: "Documents", label: "Documents", format: value => "", tdClass: "bg-light font-weight-bold" },
            { key: "optionalDocuments", label: renderDocumentsTitle(), format: renderDocumentsForm, tdClass: this.tdClass },
            { key: "SpecialRequest", label: "Special Request", format: value => "", tdClass: "bg-light font-weight-bold" },
            { key: "specialRequest", label: "Content", format: value => value?value:"NO", tdClass: this.tdClass },
        ];
    }

    get isTradeOwner() {
        return this.props.user && this.props.user.companyId === this.props.trade.items.single.ownerId;
    }

    renderBankDetails = (bankName, serie) => {
        const isBankOnly = serie.data.status === BID_STATUS.BANK_ONLY_COUNTERED;
        const RTS_SELLER = this.props.trade.items.single.requestType === 'SELL' && this.isTradeOwner;
        const RTS_BUYER = this.props.trade.items.single.requestType === 'SELL' && !this.isTradeOwner;
        const isBidOwner = serie.data.fromCompanyID === this.props.user.companyId;
        const isRejectedBank = isBankOnly && ((RTS_SELLER && isBidOwner) || (RTS_BUYER && !isBidOwner));
        if(bankName && isRejectedBank){
            return(
                <>
                    <del>{bankName}</del> <FontAwesomeIcon className="text-danger" icon={faExclamationCircle}/>
                </>
            )
        }
        return bankName;
    }

    renderAction = (value, serie, index) => {
        const trade = this.props.trade.items.single;
        const isBidOwner = serie.data.fromCompanyID === this.props.user.companyId;
        const RTS_SELLER = trade.requestType === 'SELL' && this.isTradeOwner;
        const RTS_BUYER = trade.requestType === 'SELL' && !this.isTradeOwner;
        const previousBid = this.props.series.find(item => item.data.ID === serie.data.previousBidId);

        if(this.props.isContractInfo){
            switch (value) {
                case BID_STATUS.ORIG_CONTRACT_DATA:
                case BID_STATUS.DEAL:
                    return "Initial request";
                case BID_STATUS.COUNTERED:     
                case BID_STATUS.ACCEPTED:
                case BID_STATUS.NEW:
                case BID_STATUS.DECLINED:    
                    return `${isBidOwner?"Request sent":"Request Received"}`;
                default:
                    break;
            }
          } else{
        switch (value) {
            case BID_STATUS.ORIG_TRADE_DATA:
            case BID_STATUS.DEAL:
                //RDEV-2521 Use a ternary operator in the return statement to display " Initial Offer " instead of " Initial Bid " for RTS
                return trade.requestType === "SELL"?"Initial Offer":"Initial Bid";
            case BID_STATUS.IMPROVED:
                if(serie.data.previousBidId === '0') return `${(this.withoutPrice)?"Offer ":"Counter "} ${isBidOwner?"Sent":"Received"}`;
                if(previousBid && previousBid.data.status === BID_STATUS.COUNTERED) return `Counter ${isBidOwner?"Sent":"Received"}`;
                return isBidOwner ? "Improved Price Sent" : "Improved Price Received";
            case BID_STATUS.COUNTERED:     
            case BID_STATUS.ACCEPTED:
                if(previousBid && previousBid.data.status === BID_STATUS.BANK_ONLY_COUNTERED){
                    if(RTS_SELLER) return isBidOwner ? "Bank Details Rejected" : "Bank Details Received"
                    if(RTS_BUYER) return isBidOwner ? "Bank Details Sent" : "Bank Details Rejected"
                }else if(previousBid && previousBid.data.status === BID_STATUS.IMPROVED){
                    return isBidOwner ? "Improved Price Sent" : "Improved Price Received";
                }else if(serie.data.subjectToConfirmInd){
                    return isBidOwner ? "Acceptance Sent Pending Confirmation" : "Acceptance Received Pending Confirmation";
                }else{
                    return `${(this.withoutPrice && serie.data.previousBidId === "0")?"Offer":"Counter"} ${isBidOwner?"Sent":"Received"}`; 
                }
                break;
            case BID_STATUS.PEND_CONFIRM_NEW:
            case BID_STATUS.PEND_CONFIRM_COUNTERED:
                return isBidOwner ? "Acceptance Sent Pending Confirmation" : "Acceptance Received Pending Confirmation";
            case BID_STATUS.BANK_ONLY_COUNTERED:
                if(RTS_SELLER){
                    return isBidOwner ? "Bank Details Rejected" : "Bank Details Received"
                }
                if(RTS_BUYER){
                    return isBidOwner ? "Bank Details Sent" : "Bank Details Rejected"
                }
                break;
            default:
                break;
        }
    }
    }
    
    renderPriceHistory = (value, serie, index, series) => {
        if(index === 0 && !value && this.props.trade.items.single.bestPrice){
            return "Best Price";
        }
        return renderPrice(value, serie, index, series)
    }

    renderAcceptedAt = (value, serie) => {
        //Don't display on the first column 
        if(serie.data.acceptedByCompanyId){
            return moment(value).format(DATEFORMATHOURS)
        }else{
            return "-"
        }
    }

    renderDeclineAt = (value, serie) => {
        //Don't display on the first column 
        if(serie.data.declinedByCompanyId){
            return moment(value).format(DATEFORMATHOURS)
        }else{
            return "-"
        }
    }

    renderPortLabel = value => {
        let label = "";
        if (value) {
            label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
        }
        return label;
    }

    renderDischarge = (value, serie) => {
        if (value) {
            const incoterm = serie.data.incoterm;
            const formatedValue = DISCHARGE_VALUES[value];
            return `${getDischargeLabel(incoterm)}: ${formatedValue}`
        } else {
            return "";
        }

    }

    renderDischargeRate = (value, serie) => {
        if (value) {
            const incoterm = serie.data.incoterm;
            const discharge = serie.data.discharge;
            if(["loadrate", "Discharge rate"].includes(serie.data.discharge)){
                return `${getDischargeRateLabel(incoterm, discharge)} : ${value}`;
            }
            return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={getDischargeRateLabel(incoterm, discharge) + ": "} />
        }
        return "";
    }

    formatPackagingOption = (value, serie) => this.formatOption(value, serie.data["optionsPackagingTypePremOrDiscount"], serie.data.currency);
    formatQualityOption = (value, serie) => this.formatOption(value, serie.data["optionsQualityTypePremOrDiscount"], serie.data.currency);
    formatShippingOption = (value, serie) => this.formatOption(value, serie.data["optionsShippingTypePremOrDiscount"], serie.data.currency);
    formatTypeOfRiceOption = (value, serie) => this.formatOption(value, serie.data["optionsTypeOfRicePremOrDiscount"], serie.data.currency);
    formatOption = (optionTYpe, discountValue, discountCurrency) => {
        let sign = (discountValue > 0) ? '+' : '';
        return optionTYpe?<React.Fragment><div>{optionTYpe}</div><div>{`${sign}${discountValue} ${CURRENCYOPT[discountCurrency]}`}</div></React.Fragment>:"-";
    }
    



    getCountryName = (value, serie) => {
        if(!value){
            return '-'
        }
        if(this.isTenderWithMultiOrigin){
            return serie.data.status === BID_STATUS.ACCEPTED ? renderSelectedOrigins(this.props.trade.items.single.origin) : renderSelectedOrigins(value)
        }
        return renderSelectedOrigins(value)
    };

    formatExpiryDate = (value, serie) => {
        if (serie.data.bidValidateDate) {
            return moment(serie.data.bidValidateDate).format(DATEFORMATHOURS);
        } else if (serie.data.validateDate) {
            return moment(serie.data.validateDate).format(DATEFORMATHOURS);
        } else {
            return "";
        }
    };
    formatExpiryDateContract = (value, serie) => {
        if (serie.data.contractValidateDate) {
            return moment(serie.data.contractValidateDate).format(DATEFORMATHOURS);
        } else if (serie.data.validateDate) {
            return moment(serie.data.validateDate).format(DATEFORMATHOURS);
        } else {
            return "";
        }
    };

    formatMultiOriginPrice = (value, serie, index) => {
        let trade = this.props.trade.items.single;
        const getFormattedPrice = (price, origin, breakdown) => {
            const showBreakdown = breakdown && Object.entries(breakdown).reduce((sum, entry) => sum + entry[1], 0) > 0;
            let breakdownSuffix = '';
            if(showBreakdown){
                if(serie.data.incoterm === 'CFR'){
                    breakdownSuffix = ` (${breakdown.fob}+${breakdown.freight})`;
                }
                if(serie.data.incoterm === 'CIF'){
                    breakdownSuffix = ` (${breakdown.fob}+${breakdown.freight}+${breakdown.insurance})`
                }
            }
            return(
                <NumberFormat 
                    key={origin}
                    displayType='text'
                    renderText={text => <div>{text}</div>}
                    value={price}
                    suffix={`${breakdownSuffix} ${CURRENCYOPT[serie.data.currency] || serie.data.currency} (${Countries[origin]})`}/>
            )
        }

        if(index === 0) return this.initialOrigins.map(origin => getFormattedPrice(serie.data.price, origin))
        if(serie.data.status === BID_STATUS.ACCEPTED && !trade.multipleOriginAccept) return getFormattedPrice(trade.price, trade.origin);

        return value.map(item => getFormattedPrice(item.isPriceCheck ? item.price : '-', item.origin, item.priceBreakdown));
    }

    render() {

        const { user, series } = this.props;
        let updatedSeries = [...series];
        if(this.isTenderWithMultiOrigin){
            updatedSeries = series.map((item, index) => {
                const updatedData = {...item.data};
                if(index === 0){
                    this.initialOrigins.forEach((origin) => {
                        updatedData[`price${origin}`] = updatedData.price;
                        updatedData[`loadPort${origin}`] = '';
                    })
                }else{
                    updatedData.multipleOriginPrice.forEach(x => {
                        updatedData[`price${x.origin}`] = x.price;
                        updatedData[`loadPort${x.origin}`] = x.loadPort;
                    })      
                }
                return{
                    ...item,
                    data: updatedData
                }
            })
        }

        const request = this.initialTrade;
        const trade = this.props.trade.items.single;
        //fetch price for original trade for withPrice/withoutPrice 
        const originalTrade =this.props.trade.bids && this.props.trade.bids.filter(bid => bid.status === BID_STATUS.ORIG_TRADE_DATA);
        const orgPrice = originalTrade.length !== 0 && originalTrade[0].price;
        let privacy = "";
        if (trade.privacyType) {
            privacy = REQUEST_PRIVACY_TYPE[trade.privacyType]
        }

        if (trade.privacyType==="MULTI_PRIVATE" && !this.isTradeOwner) 
        {
            privacy="Private";
        }
        const counterPartyName = trade.ownerId === trade.sellerId ? trade.buyer : trade.seller;
        const ownerName = trade.ownerId === trade.sellerId ? trade.seller : trade.buyer;

        return  <React.Fragment>
            <div id="trade-info" className="request-dtls__status-sections sub-header">
                <div className="card-body">
                    <div className="row trade-detail">
                        <div className="col">
                            <div className="row">
                                <div className="col-md-6">
                                    <span className="field-name">Trade Ref Id</span>
                                </div>
                                <div className="col-md-6">
                                    <span className="field-value">{trade.tradeRefId || request.id}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <span className="field-name">Creation Date</span>
                                </div>
                                <div className="col-md-6">
                                    <span className="field-value">{moment(request.createdAt).format(DATEFORMAT)}</span>
                                </div>
                            </div>
                            {request.buyer && 
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field-name">Buyer </div>
                                </div>
                                <div className="col-6">
                                    <div className={`field-value ${(request.buyerId === user.companyId) ? "text-primary" : ""}`}>
                                        <span>{request.buyer}</span>
                                        {request.buyerUser?
                                        <span>&nbsp;{`(${request.buyerId === user.companyId?'You':request.buyerUser})`}</span> : null}
                                    </div>
                                </div>
                            </div>}
                            {request.seller && 
                            <div className="row ">
                                <div className="col-6">
                                    <div className="field-name">Seller</div>
                                </div>
                                <div className="col-md-6">
                                    <div className={`field-value ${(request.sellerId === user.companyId) ? "text-primary" : ""}`}>
                                        <span>{request.seller}</span>
                                        {request.sellerUser?
                                        <span>&nbsp;{`(${request.sellerId === user.companyId?'You':request.sellerUser})`}</span> : null}
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field-name">Trade Type</div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field-value">
                                        {`${privacy} ${(trade.requestType === "BUY") ? "RTB" : "RTS"} (${(orgPrice !== 0) ? "with Price" : "without Price"} ${trade.dutchAuction?" & with Dutch Auction":""})`}
                                    </div>
                                </div>
                            </div>
                            {trade.requestType === "SELL" && trade.offerType && (
                                <div className="row mt-2 mb-2">
                                    <div className="col-md-6">
                                        <div className="field-name">Offer Type</div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field-value">{OFFER_TYPES[trade.offerType]}</div>
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field-name">Expiration Date ({getLocalTimezone()})</div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field-value">{moment(trade.validateDate).format(DATEFORMATHOURS)}{' '} (Validity)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
            <div className="request-dtls__status-sections sub-header">
                <div className="card-body">
                    <h3 className="request-dtls__card-heading">Trade Infos</h3>
                    {trade.multipleOriginAccept && 
                        <small className="form-text text-muted">
                            {`* ${this.isTradeOwner ? counterPartyName : 'You'} accepted the trade with multiple origin and now ${this.isTradeOwner ? 'you' : ownerName} have to select one origin which will become the contractual origin`}
                        </small>}
                    <div className="table-responsive mt-1 trade-dtls__table-section">
                        <DetailTable
                            options={{
                                showHeader: false
                            }}
                            series={updatedSeries}
                            fieldList={this.fieldList}>
                        </DetailTable>
                    </div>
                    {(this.initialTrade.images && this.initialTrade.images.length !== 0) && 
                    <React.Fragment>
                        <h3 className="request-dtls__card-heading">Images</h3>
                        <div className="container" style={{ display: 'flex', overflowX: 'auto' }}>
                            {this.initialTrade.images.map((image, index) => (
                                <div key={`preview-image-${index}`}>
                                    <TradeImagePreview key={image.hash} image={image} />
                                    <div>{image.name}</div>
                                </div>
                            ))}
                        </div>
                    </React.Fragment>}
                </div>
            </div>
        </React.Fragment>
    }
}

export default DetailedInfos;