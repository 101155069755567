import React from 'react';
import { EnumsService } from "../../../services/service.utils";
import { COMPANY_TYPES } from '../../../services/service.values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck, faTimes, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Tooltip from '@material-ui/core/Tooltip';

const Countries = EnumsService.countries();

const CompanyDetails = ({ company, companyType, canEdit, setCompanyType, saveCompanyType, showCompanyType, setCompanyTypeDisplay, ...props }) => (
    <div>
        <h4>Company Details</h4>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Registered Name
                            </div>
                    <div className="detail-text">
                        {company.name}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Type of Company
                            </div>
                    <div className="detail-text">
                        {showCompanyType ? (
                            <React.Fragment>
                                <select
                                    className='select'
                                    name="companyType"
                                    id="companyType"
                                    onChange={e => setCompanyType(e.target.value)}
                                    value={companyType}
                                >
                                    {Object.keys(COMPANY_TYPES).map(i => {
                                        if (i !== "OTHER") {
                                            return (
                                                <option key={i} value={i}>
                                                    {COMPANY_TYPES[i]}
                                                </option>
                                            );
                                        }
                                        return null;
                                    })}
                                </select>
                                <div className="action-btn change-btn">
                                    <Tooltip title="Save" placement="top-start" arrow >
                                        <a href="#/" className="btn-icon btn-circle-icon btn-accept" onClick={saveCompanyType}>

                                            <FontAwesomeIcon icon={faCheck} />
                                        </a>
                                    </Tooltip>
                                    <Tooltip title="Cancel" placement="top-start" arrow >
                                        <a href="#/" className="btn-icon btn-circle-icon btn-danger" onClick={e => setCompanyTypeDisplay(false)}>

                                            <FontAwesomeIcon icon={faTimes} /> </a>
                                    </Tooltip>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <span>{company.companyType}</span>
                                {canEdit ? (
                                    <div className="action-btn change-btn">
                                        <Tooltip title="Change" placement="top-start" arrow >
                                            <a href="#/" className="btn-icon btn-circle-icon btn-info" onClick={e => setCompanyTypeDisplay(true)}>

                                                <FontAwesomeIcon icon={faSyncAlt} />

                                            </a>
                                        </Tooltip>
                                    </div>
                                ) : ''}
                            </React.Fragment>)}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Registered Address Line1
                            </div>
                    <div className="detail-text">
                        {company.registeredAddress.line1}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Registered Address Line2
                            </div>
                    <div className="detail-text">
                        {company.registeredAddress.line2}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        City
                            </div>
                    <div className="detail-text">
                        {company.registeredAddress.city}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Zip Code
                            </div>
                    <div className="detail-text">
                        {company.registeredAddress.postal}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Country
                            </div>
                    <div className="detail-text">
                        {Countries[company.registeredAddress.country]}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Mailing Address Line1
                            </div>
                    <div className="detail-text">
                        {company.mailingAddress.line1}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Mailing Address Line2
                            </div>
                    <div className="detail-text">
                        {company.mailingAddress.line2}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        City
                            </div>
                    <div className="detail-text">
                        {company.mailingAddress.city}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Zip Code
                            </div>
                    <div className="detail-text">
                        {company.mailingAddress.postal}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Country
                            </div>
                    <div className="detail-text">
                        {Countries[company.mailingAddress.country]}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Company Registration No.
                            </div>
                    <div className="detail-text">
                        {props.showRegNumber ? (
                            <React.Fragment>
                                <form autoComplete="false" onSubmit={props.saveRegNumber}>
                                    <input type="text"
                                        name="regNumber"
                                        value={props.regNumber}
                                        required
                                        onChange={e => props.setRegNumber(e.target.value)}
                                        style={{ border: "1px solid #e1e1e1" }}
                                    />
                                    <div className="action-btn change-btn">
                                        <Tooltip title="Save" placement="top-start" arrow >
                                            <button type="submit" className="btn-icon btn-circle-icon btn-accept" style={{ border: "1px solid transparent" }}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Cancel" placement="top-start" arrow >
                                            <a href="#/" className="btn-icon btn-circle-icon btn-danger" onClick={e => props.setRegNumberDisplay(false)}>
                                                <FontAwesomeIcon icon={faTimes} /> </a>
                                        </Tooltip>
                                    </div>
                                </form>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <span>{company.regNumber}</span>
                                {canEdit ? (
                                    <div className="action-btn change-btn">
                                        <Tooltip title="Change" placement="top-start" arrow >
                                            <a href="#/" className="btn-icon btn-circle-icon btn-info" onClick={e => props.setRegNumberDisplay(true)}>
                                                <FontAwesomeIcon icon={faSyncAlt} />
                                            </a>
                                        </Tooltip>
                                    </div>
                                ) : ''}
                            </React.Fragment>)}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Email
                            </div>
                    <div className="detail-text">
                        <a href="#/">{company.email}</a>
                    </div>
                </div>
            </div>

        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Telephone No.
                            </div>
                    <div className="detail-text">
                        {company.phone}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        TAX/VAT Number
                            </div>
                    <div className="detail-text">
                        {company.tax}
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Website
                            </div>
                    <div className="detail-text">
                        <a href="http://ricex.redledger.eu/">{company.site}</a>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="details-wrapper">
                    <div className="detail-heading">
                        Proof of Address
                            </div>
                    <div className="detail-text">
                        {company.addressProof && company.addressProof.fileUrl !== "#" && <a href={company.addressProof.fileUrl}>View Proof of Address</a>}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default CompanyDetails;