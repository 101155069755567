import { EnumsService } from '../../../../services';
import { TRADE_STATUS, STEPS } from '../../../../services/service.values';

const Countries = EnumsService.countries();

// Filters
export const FILTER_ALL = 'All';
export const FILTER_MY_ACTIONS = 'My actions';
export const FILTER_COUNTERPARTY_ACTIONS = 'Counterparty actions';
export const FILTER_INSPECTION_COMPANY_ACTIONS = 'Inspection company actions';

// Document Statuses
export const STATUS_REQUIRED = 'REQUIRED';
export const STATUS_NEW = 'NEW';
export const STATUS_APPROVED_BY_BUYER_DURING_REVIEW = 'APPROVED_BY_BUYER_DURING_REVIEW';
export const STATUS_REJECTED_BY_BUYER_DURING_REVIEW = 'REJECTED_BY_BUYER_DURING_REVIEW';
export const STATUS_APPROVED_BY_SELLER = 'APPROVED_BY_SELLER';
export const STATUS_REJECTED_BY_SELLER = 'REJECTED_BY_SELLER';
export const STATUS_RELEASED_FOR_BUYER = 'RELEASED_FOR_BUYER';
export const STATUS_APPROVED_BY_BUYER = 'APPROVED_BY_BUYER';
export const STATUS_REJECTED_BY_BUYER = 'REJECTED_BY_BUYER';
export const STATUS_PAYMENT_UPLOADED = 'PAYMENT_UPLOADE_BY_BUYER';
export const STATUS_PAYMENT_CONFIRMED = 'PAYMENT_CONFIRMED_BY_BUYER';
export const STATUS_DOWNPAYMENT_EFFECTED = 'DOWNPAYMENT_EFFECTED_BY_BUYER';
export const STATUS_PROFORMA_INVOICE = 'PROFORMA_INVOICE_FILLED';
export const STATUS_PROFORM_INVOICE_UPDATED = 'PROFORMA_INVOICE_UPDATED';
export const STATUS_PROFORM_INVOICE_COMPLETED = 'PROFORM_INVOICE_COMPLETED';
export const STATUS_PT_ISSUE = 'ISSUE_PT';
export const STATUS_PT_ISSUED = 'PT_ISSUED';
export const STATUS_PT_UPDATED = 'PT_UPDATED';
export const STATUS_PT_COMPLETED = 'PT_COMPLETED';

export const DOC_STATUS = {
    REQUIRED: {
        className: 'required',
        text: 'To be issued',
        status: STATUS_REQUIRED
    },
    NEW: {
        className: 'approval',
        text: 'For approval',
        status: STATUS_NEW
    },
    APPROVED_BY_BUYER_DURING_REVIEW: {
        className: 'approved',
        text: 'Reviewed by buyer',
        docClassName: 'approval',
        status: STATUS_APPROVED_BY_BUYER_DURING_REVIEW
    },
    REJECTED_BY_BUYER_DURING_REVIEW: {
        className: 'rejected',
        text: 'Rejected by buyer',
        status: STATUS_REJECTED_BY_BUYER_DURING_REVIEW
    },
    APPROVED_BY_SELLER: {
        className: 'approved',
        text: 'Approved by seller',
        status: STATUS_APPROVED_BY_SELLER
    },
    REJECTED_BY_SELLER: {
        className: 'rejected',
        text: 'Rejected by seller',
        status: STATUS_REJECTED_BY_SELLER
    },
    RELEASED_FOR_BUYER: {
        className: 'approval',
        text: 'For approval',
        status: STATUS_RELEASED_FOR_BUYER
    },
    APPROVED_BY_BUYER: {
        className: 'approved',
        docClassName: 'approval',
        text: 'Approved by buyer',
        status: STATUS_APPROVED_BY_BUYER
    },
    REJECTED_BY_BUYER: {
        className: 'rejected',
        text: 'Rejected by buyer',
        status: STATUS_REJECTED_BY_BUYER
    }
};

// Document Types
export const DOCUMENT_TYPES = {
    BILL: 'BILL',
    INVOICE: 'INVOICE',
    PROFORMA_INVOICE: 'PROFORMA_INVOICE',
    PAYMENT_TERM_DOCUMENT: 'PAYMENT_TERM_DOCUMENT',
    // CERT_OF_QUALITY: 'CERT_OF_QUALITY',
    QUALITY_APPEARANCE_CERT: 'QUALITY_APPEARANCE_CERT',
    CERT_OF_WEIGHT: 'CERT_OF_WEIGHT',
    CERT_OF_PACKING: 'CERT_OF_PACKING',
    CERT_OF_FUMIGATION: 'CERT_OF_FUMIGATION',
    PHYTOSANITARY: 'PHYTOSANITARY',
    NON_GMO: 'NON_GMO',
    EXPORT_DECLARATION: 'EXPORT_DECLARATION',
    INSURANCE: 'INSURANCE',
    CERT_OF_ORIGIN: 'CERT_OF_ORIGIN',
    CERT_OF_CONFORMITY: 'CERT_OF_CONFORMITY',
    BSC: 'BSC',
    DocInstructionsID: 'DocInstructionsID',
    ShippingAdviceID: 'ShippingAdviceID',
    DOCUMENT_ORGANIC_CERT: 'DOCUMENT_ORGANIC_CERT',
    DOCUMENT_SRP_CERT: 'DOCUMENT_SRP_CERT',
    HOLD_CLEANLINESS_CERT: 'HOLD_CLEANLINESS_CERT',
    CONTAINER_CLEANLINESS_CERT: 'CONTAINER_CLEANLINESS_CERT'
};

export const DOCUMENT_NAMES = {
    BILL: 'Bill of Lading',
    INVOICE: 'Invoice',
    PROFORMA_INVOICE: 'Proforma Invoice',
    // CERT_OF_QUALITY: 'Certificate of Quality',
    PHYTOSANITARY: 'Phytosanitary Certificate',
    CERT_OF_PACKING: 'Packing List',
    CERT_OF_FUMIGATION: 'Certificate of Fumigation',
    QUALITY_APPEARANCE_CERT: 'Quality and Appearance Certificate',
    INSURANCE: 'Insurance Certificate',
    EXPORT_DECLARATION: 'Export Declaration',
    NON_GMO: 'Non-GMO Certificate',
    CERT_OF_WEIGHT: 'Certificate of Weight and Quality',
    CERT_OF_ORIGIN: 'Certificate of Origin',
    CERT_OF_CONFORMITY: 'Certificate of Conformity',
    BSC: 'BSC ',
    DOCUMENT_ORGANIC_CERT: 'Organic Rice Certificate',
    DOCUMENT_SRP_CERT: 'SRP Verification Statement',
    HOLD_CLEANLINESS_CERT: 'Hold Cleanliness Certificate',
    CONTAINER_CLEANLINESS_CERT: 'Container Cleanliness Certificate'
};

export const ALL_DOCUMENTS = [
    DOCUMENT_TYPES.BILL,
    DOCUMENT_TYPES.INVOICE,
    // DOCUMENT_TYPES.CERT_OF_QUALITY,
    DOCUMENT_TYPES.CERT_OF_WEIGHT,
    DOCUMENT_TYPES.CERT_OF_PACKING,
    DOCUMENT_TYPES.CERT_OF_FUMIGATION,
    DOCUMENT_TYPES.PHYTOSANITARY,
    DOCUMENT_TYPES.INSURANCE,
    DOCUMENT_TYPES.CERT_OF_ORIGIN,
    DOCUMENT_TYPES.DOCUMENT_ORGANIC_CERT,
    DOCUMENT_TYPES.DOCUMENT_SRP_CERT,
    DOCUMENT_TYPES.HOLD_CLEANLINESS_CERT,
    DOCUMENT_TYPES.CONTAINER_CLEANLINESS_CERT,
];


export const REQUIRED_DOCUMENTS = [
    DOCUMENT_TYPES.BILL,
    DOCUMENT_TYPES.INVOICE,
    // DOCUMENT_TYPES.CERT_OF_QUALITY,
    DOCUMENT_TYPES.CERT_OF_WEIGHT,
    DOCUMENT_TYPES.CERT_OF_FUMIGATION,
    DOCUMENT_TYPES.PHYTOSANITARY,
    DOCUMENT_TYPES.CERT_OF_PACKING,
    DOCUMENT_TYPES.CERT_OF_ORIGIN,
    DOCUMENT_TYPES.DOCUMENT_ORGANIC_CERT,
    DOCUMENT_TYPES.DOCUMENT_SRP_CERT,
    DOCUMENT_TYPES.HOLD_CLEANLINESS_CERT,
    DOCUMENT_TYPES.CONTAINER_CLEANLINESS_CERT,
];

export const REQUIRED_DOCUMENTS_CIF = [
    DOCUMENT_TYPES.INSURANCE,
];

export const INSPECTION_DOCUMENTS = [
    // DOCUMENT_TYPES.CERT_OF_QUALITY,
    DOCUMENT_TYPES.CERT_OF_WEIGHT,
    DOCUMENT_TYPES.CERT_OF_FUMIGATION,
    DOCUMENT_TYPES.DOCUMENT_ORGANIC_CERT,
    DOCUMENT_TYPES.DOCUMENT_SRP_CERT,
    DOCUMENT_TYPES.HOLD_CLEANLINESS_CERT,
    DOCUMENT_TYPES.CONTAINER_CLEANLINESS_CERT,
];

export const SELLER_DOCUMENTS = [
    DOCUMENT_TYPES.BILL,
    DOCUMENT_TYPES.INVOICE,
    DOCUMENT_TYPES.PHYTOSANITARY,
    DOCUMENT_TYPES.INSURANCE,
    DOCUMENT_TYPES.CERT_OF_ORIGIN,
    DOCUMENT_TYPES.CERT_OF_PACKING
];

export const DOCUMENT_UPLOADER =
{
    SELLER: "Seller",
    CONTRACTUAL_INSPECTOR: "Contractual Inspector"
}

export const documentUploader = (isnonIntegratedInspector) => {
    var DOCUMENT_UPLOADER = {};
    if (isnonIntegratedInspector) {
        DOCUMENT_UPLOADER = {
            SELLER: "Seller"
        }
    } else {
        DOCUMENT_UPLOADER = {
            SELLER: "Seller",
            CONTRACTUAL_INSPECTOR: "Contractual Inspector"
        }
    }
    return DOCUMENT_UPLOADER;
}
// Document structure handled by BE
export class DocumentBE {
    constructor(title, type, sellerDoc = false, inspectionDoc = true) {
        this.title = title;
        this.type = type;
        this.sellerDoc = sellerDoc;
        this.inspectionDoc = inspectionDoc;
    }
}

// Doument Object Definition 
export class DocumentFE {
    constructor(title, type = 'CUSTOM', description = '', uploadedBy = 'SELLER', required = false, selected = false, enableUploaderSelection = true) {
        this.title = title;
        this.type = type;
        this.description = description;
        this.selected = selected;
        this.uploadedBy = uploadedBy;
        this.required = required;
        this.enableUploaderSelection = enableUploaderSelection;
    }
}

// RDEV-1777 Instanciation of all mandatory documents
export const Document = () => ({
    BILL: new DocumentFE(DOCUMENT_NAMES.BILL, 'BILL', '', 'SELLER', true, true, false),
    INVOICE: new DocumentFE(DOCUMENT_NAMES.INVOICE, 'INVOICE', '', 'SELLER', true, true, false),
    CERT_OF_WEIGHT: new DocumentFE(DOCUMENT_NAMES.CERT_OF_WEIGHT, 'CERT_OF_WEIGHT', '(issued by contractual inspector)', 'CONTRACTUAL_INSPECTOR', true, true, false),
    CERT_OF_PACKING: new DocumentFE(DOCUMENT_NAMES.CERT_OF_PACKING, 'CERT_OF_PACKING', '(issued by seller)', 'SELLER', true, true, false),
    CERT_OF_FUMIGATION: new DocumentFE(DOCUMENT_NAMES.CERT_OF_FUMIGATION, 'CERT_OF_FUMIGATION', '(issued by licensed fumigator)', 'CONTRACTUAL_INSPECTOR', true, true, true),
    PHYTOSANITARY: new DocumentFE(DOCUMENT_NAMES.PHYTOSANITARY, 'PHYTOSANITARY', '(issued by competent authority)', 'SELLER', true, true, true),
    CERT_OF_ORIGIN: new DocumentFE(DOCUMENT_NAMES.CERT_OF_ORIGIN, 'CERT_OF_ORIGIN', '', 'SELLER', true, true, true),
    DOCUMENT_ORGANIC_CERT: new DocumentFE(DOCUMENT_NAMES.DOCUMENT_ORGANIC_CERT, 'DOCUMENT_ORGANIC_CERT', '(issued by accredited organisation or inspector).', 'CONTRACTUAL_INSPECTOR', true, true, true),
    DOCUMENT_SRP_CERT: new DocumentFE(DOCUMENT_NAMES.DOCUMENT_SRP_CERT, 'DOCUMENT_SRP_CERT', '(issued by accredited organisation or inspector)', 'CONTRACTUAL_INSPECTOR', true, true, true),
    HOLD_CLEANLINESS_CERT: new DocumentFE(DOCUMENT_NAMES.HOLD_CLEANLINESS_CERT, 'HOLD_CLEANLINESS_CERT', '(issued by contractual inspector)', 'CONTRACTUAL_INSPECTOR', true, true, false),
    CONTAINER_CLEANLINESS_CERT: new DocumentFE(DOCUMENT_NAMES.CONTAINER_CLEANLINESS_CERT, 'CONTAINER_CLEANLINESS_CERT', '(issued by contractual inspector)', 'CONTRACTUAL_INSPECTOR', true, true, false),
    INSURANCE: new DocumentFE(DOCUMENT_NAMES.INSURANCE, 'INSURANCE', '', 'SELLER', true, true, false),
    QUALITY_APPEARANCE_CERT: new DocumentFE(DOCUMENT_NAMES.QUALITY_APPEARANCE_CERT, 'QUALITY_APPEARANCE_CERT', '(issued by contractual inspector in case of sale basis sealed sample)', 'CONTRACTUAL_INSPECTOR', false, false, false),
    NON_GMO: new DocumentFE(DOCUMENT_NAMES.NON_GMO, 'NON_GMO', '(issued by seller)', 'SELLER', false, false, false),
    EXPORT_DECLARATION: new DocumentFE(DOCUMENT_NAMES.EXPORT_DECLARATION, 'EXPORT_DECLARATION', '', 'SELLER', false, false, false),
    CERT_OF_CONFORMITY: new DocumentFE(DOCUMENT_NAMES.CERT_OF_CONFORMITY, 'CERT_OF_CONFORMITY', '', 'CONTRACTUAL_INSPECTOR', false, false, true),
    BSC: new DocumentFE(DOCUMENT_NAMES.BSC, 'BSC', '(Bordereau De Suivi Des Cargaison)', 'SELLER', false, false, false)
})

export class DocumentFactory {

    /* Return new document structure ( DocumentFE ) */
    static getNewFEDoc(...args) { return new DocumentFE(...args) }

    /* Return  document structure ( DocumentFE ) based on the provided type */
    static getDocumentByType(type) {
        if (Document().type) {
            return { ...Document()[type] }
        }
    }

    /* Returna list of all documents ( DocumentFE ) */
    static getAllDocuments(trade) {
        var res = Object.values({ ...Document() }).filter(doc => {
            if (trade.riceCategory !== 'ORGANIC' && doc.type === 'DOCUMENT_ORGANIC_CERT') {
                return false;
            }
            if (trade.riceCategory !== 'SRP' && doc.type === 'DOCUMENT_SRP_CERT') {
                return false;
            }
            if (trade.incoterm !== 'CIF' && doc.type === 'INSURANCE') {
                return false;
            }
            if (trade.shipping !== 'CONTAINER' && doc.type === 'CONTAINER_CLEANLINESS_CERT') {
                return false;
            }
            if (trade.shipping !== 'VESSEL' && doc.type === 'HOLD_CLEANLINESS_CERT') {
                return false;
            }
            return true;
        })
        //For Non Inspector value is 1 and uploadedby default for all SELLER
        if (trade.inspection === "1") {
            [...(res)].forEach(doc => {
                if (doc.uploadedBy === "CONTRACTUAL_INSPECTOR") {
                    doc.uploadedBy = "SELLER"
                }
            });
        }
        return res;
    }

    /* Return new document structure ( DocumentFE ) */
    static getMandatoryDocuments(trade) {
        return this.getAllDocuments(trade).filter(doc => doc.required)
    }

    /* Return all optional documents ( DocumentFE ) */
    static getOptionalDocuments(trade) {
        return this.getAllDocuments(trade).filter(doc => !doc.required)
    }

    /* Format DocumentBE to DocumentFE */
    static formatToFEDoc(doc, inspection) {
        let formattedDoc;
        let uploadedBy;

        // Set fiel value for 'uploadedBy'
        if (doc.uploadedBy) {
            uploadedBy = doc.uploadedBy;
        } else {
            if (inspection === "1") {
                doc.inspectionDoc = false;
                doc.sellerDoc = true;
            }

            uploadedBy = (doc.sellerDoc) ? 'SELLER' : 'CONTRACTUAL_INSPECTOR';
        }

        // If the doc to format has a know type, then we extend the default document
        if (Document()[doc.type]) {
            formattedDoc = { ...Document()[doc.type], uploadedBy: uploadedBy, selected: true }
        } else {
            let title = doc.title;
            let type = doc.title;
            formattedDoc = new DocumentFE(title, type, '', uploadedBy, false, true, true);
        }
        return formattedDoc;
    }

    // Format a list of documents returns an array of DocumentFE
    static formatToFEDocList(BEDocList, inspection) {
        if (Array.isArray(BEDocList)) {
            return BEDocList.map(BEDoc => {
                return this.formatToFEDoc(BEDoc, inspection)
            })
        } else {
            throw (new Error('BEDocList should be a list'))
        }
    }

    /* 
    * Get the list of documents based on the trade given as parameter
    * return DocumentFE[]
    */
    static getFEDocumentList(trade) {
        let documentList = DocumentFactory.getAllDocuments(trade)
        if (Array.isArray(trade.optionalDocuments)) {
            let tradeDocuments = DocumentFactory.formatToFEDocList(trade.optionalDocuments, trade.inspection)
            tradeDocuments.forEach(doc => {
                const index = documentList.findIndex(item => (item.type === doc.type));
                // If the document type is  in the list of default documents, we merge the default document with the document saved in the ledger.
                if (index !== -1) {
                    documentList[index] = doc
                } else {
                    if (Document()[doc.type] === undefined)
                        documentList.push(doc)
                }
            })
        }
        return [...documentList];
    }

    // Return FEDocument is found, empty object otherwise.
    static getDocByName(trade, docName) {
        let doc = DocumentFactory.getFEDocumentList(trade).find(item => item.name === docName);
        if (doc) {
            return doc;
        } else {
            return {}
        }
    }

    static getSelectedFEDocumentList(trade) {
        return DocumentFactory.getFEDocumentList(trade).filter(item => item.selected);
    }
    static getSelectedOpsDocumentList(trade) {
        return DocumentFactory.getFEDocumentList(trade).filter(item => item.selected && !item.required);
    }
    /* Retrieves trade documents for fomrs ('TradeForm', 'ContractAmendmentForm', ... )
    * The list returned contains all documents that can be modified
    */
    static getFormFEDocumentList(trade) {
        return DocumentFactory.getFEDocumentList(trade).filter(item => {
            return (!item.required || item.enableUploaderSelection)
        })
    }
    static getSellerDoc(trade) {
        return this.getFEDocumentList(trade).filter(item => {
            return (item.uploadedBy === 'SELLER')
        })
    }
    static getInsepectionDoc(trade) {
        return this.getFEDocumentList(trade).filter(item => {
            return (item.uploadedBy === 'CONTRACTUAL_INSPECTOR')
        })
    }

    static getSelectedFormFEDocumentList(trade) {
        return DocumentFactory.getFormFEDocumentList(trade).filter(item => item.selected);
    }

    static getMandatoryFEDocumentList(trade) {
        return DocumentFactory.getFEDocumentList(trade).filter(item => (item.required && !item.enableUploaderSelection));
    }


    static formatToBEDoc(doc) {
        let sellerDoc = doc.uploadedBy === 'SELLER';
        let inspectionDoc = doc.uploadedBy === 'CONTRACTUAL_INSPECTOR';
        return new DocumentBE(doc.title, doc.type, sellerDoc, inspectionDoc);
    }

    static formatToBEDocList(FEDocList) {
        let res = [];
        if (Array.isArray(FEDocList)) {
            res = FEDocList.map(FEDoc => {
                return DocumentFactory.formatToBEDoc(FEDoc)
            })
        } else {
            throw (new Error('FEDocList should be a list'))
        }
        return res
    }

    /* 
        Return the list of DocumentBE to store in the BE
        FROM RDEV-1777 trade.optionalDocuments contains a list of documents :
                all 'optional' 
                and 'mandatory' for which the user can select the uploader
    */
    static getBEOptionalDocumentList(feDocumentList) {
        if (Array.isArray(feDocumentList)) {
            let res = DocumentFactory.formatToBEDocList(feDocumentList.filter(
                item => item.enableUploaderSelection || !item.required))
            return res;
        }

    }

    static initBEDocumentList(trade) {
        return this.getBEOptionalDocumentList(this.getSelectedFEDocumentList(trade));
    }


}


export const PROFORMA_INVOICE_OPERATIONS = {
    FILL_PROFORMA_INVOICE: 'FILL_PROFORMA_INVOICE',
    CONFIRM_PAYMENT_RECIEPT: 'CONFIRM_PAYMENT_RECIEPT',
    UPLOAD_PAYMENT_RECIEPT: 'UPLOAD_PAYMENT_RECIEPT',
    DOWNPAYMENT_EFFECTED: 'DOWNPAYMENT_EFFECTED',
    VIEW_DOWNPAYMENT_EFFECTED: 'VIEW_DOWNPAYMENT_EFFECTED',
    PREVIEW_PAYMENT_RECIEPT: 'PREVIEW_PAYMENT_RECIEPT'
}

export const FINAL_PAYMENT_OPERATIONS = {
    CONFIRM_FINAL_PAYMENT: 'CONFIRM_FINAL_PAYMENT',
    UPLOAD_FINAL_PAYMENT_PROOF: 'UPLOAD_FINAL_PAYMENT_PROOF',
    PREVIEW_INVOICE_PAYMENT_PROOFS: 'PREVIEW_INVOICE_PAYMENT_PROOFS'
}

export const getAllDocuments = (trade) => {
    return [...(DocumentFactory.getSelectedFEDocumentList(trade).map(doc => { return doc.type }))];
}

export const getSellerDocuments = (trade) => {
    return [...(DocumentFactory.getSelectedFEDocumentList(trade).filter(doc => doc.uploadedBy === "SELLER").map(doc => { return doc.type }))];
}

export const getInspectionDocuments = (trade) => {
    return [...(DocumentFactory.getSelectedFEDocumentList(trade).filter(doc => doc.uploadedBy === "CONTRACTUAL_INSPECTOR").map(doc => { return doc.type }))];
}

export const getRequiredDocuments = (trade) => {
    return [...(DocumentFactory.getSelectedFEDocumentList(trade).map(doc => { return doc.type }))];
}


export const getDocInfo = (docName, trade) => {
    const proformaPrecentage = trade.downPaymentPercentage === 0 ? 100 : trade.downPaymentPercentage;

    switch (docName) {
        case DOCUMENT_TYPES.BILL:
            return {
                name: 'Bill of Lading',
                text: `Full set of 3/3 originals plus 3 (three) non-negotiable copies of ‘clean on board’
                 charter party bills of lading made out to order and blanked endorsed,
                 marked ‘ freight prepaid’ as per Charter Party ${trade.incoterm === 'CIF' || trade.incoterm === 'CFR'
                        ? ', and showing ' + Countries[trade.destCountry] + '.'
                        : '.'
                    }`,
                url: 'bill',
                type: true
            };
        case DOCUMENT_TYPES.INVOICE:
            return {
                name: 'Invoice',
                text: `Seller’s original signed Invoice for the value of Product based on the Bill of
                 Lading quantity payable 3 business days after receipt.`,
                url: 'invoice',
                type: true
            };
        case DOCUMENT_TYPES.PROFORMA_INVOICE:
            return {
                name: 'Proforma Invoice',
                text: `Pro-forma invoice for ${proformaPrecentage} pct of the value of the goods (Conditional based on down payment)`,
                url: 'proformaInvoice',
                type: true
            }
        case DOCUMENT_TYPES.PAYMENT_TERM_DOCUMENT:
            return {

                name: trade.payment === 'Letter of credit' ? 'Letter of Credit' : trade.payment === 'Confirmed letter of credit' ? 'Confirmed Letter of Credit' : 'Avalised Draft',
                text: trade.payment === 'Letter of credit' ? 'Letter of Credit Draft' : trade.payment === 'Confirmed letter of credit' ? 'Confirmed Letter of Credit Draft' : 'Avalised Draft',
                url: 'ptDoc',
                type: true
            }
        /*         case DOCUMENT_TYPES.CERT_OF_QUALITY:
                    return {
                        name: 'Certificate of Quality',
                        text: `Certificate of quality issued by contractual appointed ${trade.inspectionName}
                         certifying that the goods loaded comply fully with the specifications set
                         forth above under clause entitled “Quality”.`,
                        state: DOCUMENT_TYPES.CERT_OF_QUALITY,
                        type: false
                    }; */
        case DOCUMENT_TYPES.QUALITY_APPEARANCE_CERT:
            return {
                name: 'Quality and Appearance Certificate',
                text: `Certificate issued by contractual appointed ${trade.inspectionName} certifying that the
                 quality and appearance of rice delivered is equal to or better than the
                  ${trade.inspection === "1" ? "" : `above mentioned ${trade.inspectionName}`} sealed sample.`,
                state: DOCUMENT_TYPES.QUALITY_APPEARANCE_CERT,
                type: false
            };
        case DOCUMENT_TYPES.PHYTOSANITARY:
            return {
                name: 'Phytosanitary Certificate',
                text: `Phytosanitary certificate issued by competent authority`,
                state: DOCUMENT_TYPES.PHYTOSANITARY,
                type: false
            };
        case DOCUMENT_TYPES.CERT_OF_PACKING:
            return {
                name: 'Packing List',
                text: `Packing List issued by seller (${trade.seller}).`,
                state: DOCUMENT_TYPES.CERT_OF_PACKING,
                type: false
            };
        case DOCUMENT_TYPES.INSURANCE:
            return {
                name: 'Insurance Certificate',
                text: 'Insurance Certificate',
                state: DOCUMENT_TYPES.INSURANCE,
                type: false
            };
        case DOCUMENT_TYPES.NON_GMO:
            return {
                name: 'Non-GMO Certificate',
                text: `Non-GMO certificate issued by shippers.`,
                state: DOCUMENT_TYPES.NON_GMO,
                type: false
            };
        case DOCUMENT_TYPES.CERT_OF_FUMIGATION:
            return {
                name: 'Certificate of Fumigation',
                text: trade.inspection === "1" ? `Certificate of Fumigation (issued by licensed fumigator)` : `Certificate of fumigation of goods effected at time of shipment of the goods
                 from the origin issued by ${trade.inspectionName}. Fumigation certificate with date
                 after B/L date is acceptable.`,
                state: DOCUMENT_TYPES.CERT_OF_FUMIGATION,
                type: false
            };
        case DOCUMENT_TYPES.EXPORT_DECLARATION:
            return {
                name: 'Export Declaration',
                text: `Copy of export declaration.`,
                state: DOCUMENT_TYPES.EXPORT_DECLARATION,
                type: false
            };
        case DOCUMENT_TYPES.CERT_OF_WEIGHT:
            return {
                name: 'Certificate of Weight and Quality',
                text: `Certificate of Weight and Quality to be issued by contractual appointed ${trade.inspectionName}.`,
                state: DOCUMENT_TYPES.CERT_OF_WEIGHT,
                type: false
            };
        case DOCUMENT_TYPES.CERT_OF_ORIGIN:
            return {
                name: 'Certificate of Origin',
                text: `Certificate of origin.`,
                state: DOCUMENT_TYPES.CERT_OF_ORIGIN,
                type: false
            };
        case DOCUMENT_TYPES.CERT_OF_CONFORMITY:
            return {
                name: 'Certificate of conformity',
                text: `Certificate of conformity issued by contractual appointed ${trade.inspectionName}.`,
                state: DOCUMENT_TYPES.CERT_OF_CONFORMITY,
                type: false
            };
        case DOCUMENT_TYPES.BSC:
            return {
                name: 'BSC',
                text: `Bordereau De Suivi Des Cargaison.`,
                state: DOCUMENT_TYPES.BSC,
                type: false
            };
        case DOCUMENT_TYPES.DocInstructionsID:
            return {
                name: 'Documentary Instructions',
                text: `Documentary instructions for seller`,
                url: 'instructions',
                type: true
            };
        case DOCUMENT_TYPES.ShippingAdviceID:
            return {
                name: 'Shipping Advice',
                text: `Shipping advice for buyer`,
                url: 'advice',
                type: true
            };
        case DOCUMENT_TYPES.DOCUMENT_ORGANIC_CERT:
            return {
                name: 'Organic Rice Certificate',
                text: `A certificate enumerating that the rice is Organic issued by an accredited supervision company or organisation`,
                state: DOCUMENT_TYPES.DOCUMENT_ORGANIC_CERT,
                type: false
            };
        case DOCUMENT_TYPES.DOCUMENT_SRP_CERT:
            return {
                name: 'SRP Verification Statement',
                text: `SRP verification statement to be delivered by an accredited supervision company`,
                state: DOCUMENT_TYPES.DOCUMENT_SRP_CERT,
                type: false
            };
        case DOCUMENT_TYPES.HOLD_CLEANLINESS_CERT:
            return {
                name: 'Hold Cleanliness Certificate',
                text: `Hold Cleanliness Certificate`,
                state: DOCUMENT_TYPES.HOLD_CLEANLINESS_CERT,
                type: false
            };
        case DOCUMENT_TYPES.CONTAINER_CLEANLINESS_CERT:
            return {
                name: 'Container Cleanliness Certificate',
                text: `Container Cleanliness Certificate`,
                state: DOCUMENT_TYPES.CONTAINER_CLEANLINESS_CERT,
                type: false
            };
        default:
            return {
                name: docName,
                text: `Mandatory document ${docName}`,
                state: docName,
                type: false
            };
    }

};

export const getDocStatus = (docName, documents) => {
    return documents[docName] ? DOC_STATUS[documents[docName].status] : DOC_STATUS.REQUIRED;
};

export const LOADING_PT_PROCESS = {
    GET: 'get',
    CREATE: 'create',
    UPDATE: 'update',
    UPLOAD: 'upload',
    PREVIEW: 'preview',
    DOWNLOAD: 'download',
    CONFIRM: 'confirm'
}

export const PT_DOC_MODALS = {
    PREVIEW_PT_DOC: 'PREVIEW_PT_DOC',
    UPLOAD_PT_DOC: 'UPLOAD_PT_DOC',
    CONFIRM_PT_DOC: 'CONFIRM_PT_DOC',
    UPDATE_PT_DOC: 'UPDATE_PT_DOC',
    CREATE_PT_DOC: 'CREATE_PT_DOC',
    COMPLETED_PT_DOC: 'COMPLETED_PT_DOC'
}

export const getPTDocStatus = (ptDoc, trade, userType) => {
    const draftType = trade.payment === 'Letter of credit' ? 'L/C Draft' : trade.payment === 'Confirmed letter of credit' ? 'Confirmed L/C Draft' : 'Avalised Draft';
    if (ptDoc) {
        switch (ptDoc.status) {
            case 'NEW':
            case 'UPLOADED':
                return {
                    className: 'approval',
                    text: `${draftType} Issued`,
                    status: STATUS_PT_ISSUED
                }
            case 'UPDATED':
                return {
                    className: 'approval',
                    text: `${draftType} Updated`,
                    status: STATUS_PT_UPDATED
                }
            case 'REJECTED':
                return {
                    className: 'rejected',
                    text: `Change Request to ${draftType} ${userType === 'seller' ? 'Requested' : 'Received'}`,
                    status: STATUS_REJECTED_BY_SELLER
                }
            case 'CONFIRMED':
                return {
                    className: 'approved',
                    text: `${draftType} Completed`,
                    status: STATUS_PT_COMPLETED
                }
            default:
                return {
                    className: 'approval',
                    text: `Issue ${draftType}`,
                    status: STATUS_PT_ISSUE
                }
        }
    } else {
        return {
            className: 'approval',
            text: `Issue ${draftType}`,
            status: STATUS_PT_ISSUE
        }
    }
}

export const getProformaDocStatus = (proformaInvoice) => {
    if (proformaInvoice) {
        switch (proformaInvoice.status) {
            case 'NEW':
                return {
                    className: 'approval',
                    text: 'Proforma Invoice Issued',
                    status: STATUS_NEW
                }
            case 'EDIT':
                return {
                    className: 'approval',
                    text: 'Proforma Invoice Updated',
                    status: STATUS_PROFORM_INVOICE_UPDATED
                }
            case 'UPLOADED':
                return {
                    className: 'approval',
                    text: 'Proof of Down Payment Uploaded',
                    status: STATUS_PAYMENT_UPLOADED
                }
            case 'PAYMENT_CONFIRMED':
                return {
                    className: 'approved',
                    text: 'Down Payment Confirmed',
                    status: STATUS_PAYMENT_CONFIRMED
                }
            case 'PAYMENT_NOT_CONFIRMED':
                return {
                    className: 'approval',
                    text: 'Down Payment Not Confirmed',
                    status: STATUS_PAYMENT_CONFIRMED
                }
            case 'EFFECTED':
                return {
                    className: 'approval',
                    text: 'Downpayment Effected',
                    status: STATUS_DOWNPAYMENT_EFFECTED
                }
            case 'COMPLETED':
                return {
                    className: 'approved',
                    text: 'Proforma Invoice Completed',
                    status: STATUS_PROFORM_INVOICE_COMPLETED
                }
            default:
                return {
                    className: 'approval',
                    text: 'Proforma Invoice',
                    status: STATUS_PROFORMA_INVOICE
                }
        }
    } else {
        return {
            className: 'approval',
            text: 'Proforma Invoice',
            status: STATUS_PROFORMA_INVOICE
        }
    }
}

// TODO: refactor (split into methods)
export const getDocPermissions = (docName, status, bill, trader, tradeStatus, trade) => {
    const isInspectionDocument = getInspectionDocuments(trade).includes(docName);
    const isSellerDocument = getSellerDocuments(trade).includes(docName);
    let permissions = {};
    switch (status) {
        case STATUS_REQUIRED:
            if (isInspectionDocument && trader === 'inspection') {
                permissions = { canUpload: true };
            } else if (isSellerDocument && trader === 'seller') {
                if (docName === DOCUMENT_TYPES.BILL) {
                    permissions = { canFill: true };
                } else if (docName === DOCUMENT_TYPES.INVOICE) {
                    if (!bill || !bill.BillID) {
                        permissions = { canFill: false };
                    }
                } else {
                    permissions = { canUpload: true };
                }
            } else {
                permissions = {};
            }
            break;
        case STATUS_NEW:
            if (isInspectionDocument) {
                switch (trader) {
                    case 'seller':
                        permissions = {
                            canApprove: true,
                            canReject: true,
                            canDownload: true,
                            canPreview: true,
                            canRelease: true
                        };
                        break;
                    case 'buyer':
                        permissions = { canPreview: true };
                        break;
                    case 'inspection':
                        permissions = { canDownload: true };
                        break;
                    default:
                        permissions = {};
                }
            } else if (isSellerDocument) {
                switch (trader) {
                    case 'seller':
                        permissions = {
                            canDownload: true,
                            canPreview: true,
                            canApprove: true,
                            canReject: true,
                            canRelease: true
                        };
                        break;
                    case 'buyer':
                        permissions = {
                            canPreview: docName !== DOCUMENT_TYPES.BILL
                        };
                        break;
                    case 'inspection':
                    default:
                        permissions = {};
                }
            } else {
                permissions = {};
            }
            break;
        case STATUS_REJECTED_BY_BUYER_DURING_REVIEW:
        case STATUS_APPROVED_BY_BUYER_DURING_REVIEW:
        case STATUS_APPROVED_BY_BUYER:
        case STATUS_REJECTED_BY_BUYER:
            switch (trader) {
                case 'seller':
                    permissions = { canApprove: true, canReject: true, canDownload: true, canPreview: true };
                    break;
                case 'buyer':
                    permissions = { canPreview: true };
                    break;
                default:
                    permissions = {};
            }
            break;
        case STATUS_APPROVED_BY_SELLER:
            switch (trader) {
                case 'seller':
                    permissions = { canDownload: true, canPreview: true };
                    break;
                case 'buyer':
                    permissions = { canPreview: true, canDownload: true };
                    break;
                default:
                    permissions = {};
            }
            break;
        case STATUS_REJECTED_BY_SELLER:
            switch (trader) {
                case 'seller':
                    permissions = { canDownload: true, canPreview: true };
                    break;
                case 'buyer':
                    permissions = { canPreview: true };
                    break;
                default:
                    permissions = {};
            }
            break;
        case STATUS_RELEASED_FOR_BUYER:
            switch (trader) {
                case 'seller':
                    permissions = { canDownload: true, canPreview: true, canApprove: true, canReject: true };
                    break;
                case 'buyer':
                    permissions = { canApprove: true, canReject: true, canPreview: true };
                    break;
                default:
                    permissions = {};
            }
            break;
        default:
            permissions = {};
            break;
    }
    if ((isSellerDocument && trader === 'seller') || (isInspectionDocument && trader === 'inspection')) {
        permissions.canPreview = status !== STATUS_REQUIRED;
        if ([DOCUMENT_TYPES.INVOICE, DOCUMENT_TYPES.BILL].includes(docName)) {
            permissions.canFill = permissions.canFill !== undefined ? permissions.canFill : true;
        } else {
            permissions.canUpload = permissions.canUpload !== undefined ? permissions.canUpload : true;
        }
    }
    if (
        (docName !== DOCUMENT_TYPES.BILL && STEPS.indexOf(tradeStatus) !== STEPS.indexOf(TRADE_STATUS.ADVICE)) ||
        (docName === DOCUMENT_TYPES.BILL && STEPS.indexOf(tradeStatus) > STEPS.indexOf(TRADE_STATUS.ADVICE))
    ) {
        permissions.canApprove = false;
        permissions.canReject = false;
        permissions.canRelease = false;
        permissions.canFill = false;
        permissions.canUpload = false;
    }
    permissions.canComment = true;
    return permissions;
};

export const getDocNamesByFilter = (filter, trade) => {
    switch (filter) {
        case FILTER_ALL:
        case FILTER_MY_ACTIONS:
        case FILTER_COUNTERPARTY_ACTIONS:
            return getAllDocuments(trade);
        case FILTER_INSPECTION_COMPANY_ACTIONS:
            return getInspectionDocuments(trade);
        default:
            break;
    }
};

export const getFilteredDocuments = (filter, trader, documents, trade) => {
    let filteredDocuments = [];
    const bill = documents[DOCUMENT_TYPES.BILL];
    const havePendingActions = permissions => permissions.canApprove || permissions.canReject || permissions.canRelease || permissions.canUpload || permissions.canFill;
    let docNames = getDocNamesByFilter(filter, trade);
    // docNames = filterDocNamesByIncoterm(docNames, trade.incoterm);
    // docNames = filterDocNamesByRiceCategory(docNames, trade.riceCategory)
    switch (filter) {
        case FILTER_ALL:
            filteredDocuments = docNames.map(docName => {
                const status = getDocStatus(docName, documents);
                return {
                    docName,
                    status,
                    permissions: status ? getDocPermissions(docName, status.status, bill, trader, trade.status, trade) : []
                }
            });
            break;
        case FILTER_MY_ACTIONS:
            docNames.forEach(docName => {
                const status = getDocStatus(docName, documents);
                const permissions = status ? getDocPermissions(docName, status.status, bill, trader, trade.status, trade) : [];
                if (havePendingActions(permissions)) {
                    if ([status.status][0] !== "APPROVED_BY_SELLER") {
                        filteredDocuments.push({ docName, status, permissions });
                    }
                }
            });
            break;
        case FILTER_COUNTERPARTY_ACTIONS:
            docNames.forEach(docName => {
                const status = getDocStatus(docName, documents);
                // this check covers all cases because for inspection company seller is the counterparty too
                let counterParty = trader === 'seller' ? 'buyer' : 'seller';
                let permissions = status ? getDocPermissions(docName, status.status, bill, counterParty, trade.status, trade) : [];
                if (havePendingActions(permissions)) {
                    // we should calculate permissions for current user
                    permissions = status ? getDocPermissions(docName, status.status, bill, trader, trade.status, trade) : [];
                    filteredDocuments.push({ docName, status, permissions });
                }
            });
            break;
        case FILTER_INSPECTION_COMPANY_ACTIONS:
            docNames.forEach(docName => {
                const status = getDocStatus(docName, documents);
                // check if inspection company have pending actions
                let permissions = status ? getDocPermissions(docName, status.status, bill, 'inspection', trade.status, trade) : [];
                if (havePendingActions(permissions)) {
                    if (trader !== 'inspection') {
                        // if this user is not inspection company we should calculate permissions for him
                        permissions = status ? getDocPermissions(docName, status.status, bill, trader, trade.status, trade) : [];
                    }
                    filteredDocuments.push({ docName, status, permissions });
                }
            });
            break;
        default:
            break;
    }
    filteredDocuments.map(doc => {
        doc.required = getRequiredDocuments(trade).includes(doc.docName);
        return doc;
    });
    return filteredDocuments;
};