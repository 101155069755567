import React from 'react';

const DirectorDetails = ({ company }) => (
    <div>
        <h4 className="mt-1">Details of Senior Manager(s)</h4>
        <div className="table-responsive mb-4">
            <table className="table table-bordered table-hover table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Position</th>
                    </tr>
                </thead>
                <tbody>
                {company.directors &&
                        company.directors.map((k, i) => {
                            return (
                                <tr key={i}>
                                    <td>{k.name}</td>
                                    <td>{k.title}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    </div>
);

export default DirectorDetails;