import React from 'react';
import PropTypes from 'prop-types';
import RxNumberFormat from './RxNumberFormat';
import { MEASUREMENTS } from '../../../services/service.values.js';

/**
 * @author Jean-Marc Cheynier <jean-marc.cheynier@fujitsu.com>
 * @version RDEV-2063
 * Default component renderer for trade quantity in RicexProject.
 * @param  {object} Trade - Trade Object to render the price
 * @component
 */
const TradeQuantityFormatter = ({ trade, displayType, ...props }) => {

	return (
		<RxNumberFormat
			value={trade.measure}
			displayType={displayType}
			suffix={` ${MEASUREMENTS[trade.measurement]}`}
			{...props}
		/>)
}

TradeQuantityFormatter.propTypes = {
	trade: PropTypes.object.isRequired,
	displayType: PropTypes.oneOf(['text', 'input']),
}

TradeQuantityFormatter.defaultProps = {
	displayType: 'text',
}

export default TradeQuantityFormatter;