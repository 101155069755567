import React from 'react';

import ConfirmModal from './ConfirmModal';

const ConfirmModalTenderSubscribe = function ({ trade, error, ...rest }) {
    return <ConfirmModal
        title="You are about to send a request to subscribe to tender"
        modalWrapperClass="w-auto"
        {...rest}>
        <p className="pb-2">{`By clicking CONFIRM your request to subscribe to the tender will be remitted to the initiator of the tender who might accept or reject it. Click CONFIRM to send request or BACK to return to the previous screen.`}</p>
        {error && <p className="pb-2 text-danger">{`An error occurred : ${error}`}</p>}
    </ConfirmModal>
}

export default ConfirmModalTenderSubscribe; 