import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {push} from "connected-react-router";
import moment from 'moment-timezone';
import {
    submitBankDetailsModal,
} from './components/bankDetailsModals/BankDetailsSubmitModals';
import AlternateBankRequestModal from './components/bankDetailsModals/AlternateBankRequestModal';

import {
    getMessage,
    sorMessageByDate,
    dismissError,
    ReplyMessage,
    postMessage,
    loadRequestDetails,
    preloadInspectionCompanies,
    searchCompanies,
    SmartTrade,
    ClearSingleTrade,
    CounterTrade,
    AcceptTradeBid,
    DeclineTradeBid,
    LoadTradeBids,
    AutoupdateTriggered,
    sendEmail,
    counterBankDetails
} from '../../../modules/module.trade';
import { getTenderSubscriptions } from '../../../modules/module.tender';
import { LoadingInitiate } from '../../../modules/module.loading';
import { getShippingCompanyList, getshippingStatus } from '../../../modules/module.shipmentQuote';
import { getInpectionStatus } from '../../../modules/module.inspectionQuote';
import './requests.details.scss';

//COMPONENTS
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import BidDetail from './detail/BidDetail';
import BidList from './detail/BidList';
import SubscriptionList from './detail/SubscriptionList';
import TradeDetail from './detail/TradeDetail';
import Message from './components/Message';
import InspectionNotAvailableModal from '../../components/InspectionNotAvailableModal';
import AuthorisationCodeModal from '../../components/AuthorisationCodeModal';
import PaginationCustom from '../../components/pagination/PaginationCustom';
import TradeImagePreview from "./components/TradeImagePreview";
import ErrorModal from "./components/ErrorModal";
import PublicCommunicationList from './detail/PublicCommunicationList';
import MultiPartyCommunication from './detail/MultiPartyCommunication';

//VALUES
import {
    TRADE_STATUS, 
    REQUEST_PRIVACY_TYPE, 
    TENDER_SUBSCRIPTIONS_STATUS,
    COUNTER_TYPES,
    BID_STATUS
} from '../../../services/service.values';
import { bypassAuthCodePermission, negotiateTradePermission, viewExchangePermission, viewTradeRequestPermission } from '../../../services/service.permission';
import { Link } from 'react-router-dom';
import Preloader from '../../components/preloader/Preloader';
import CounterProposal from './components/CounterProposal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class RequestDetails extends Component {

    constructor(props) {
        super(props);
        this.textArea = React.createRef();
        this.bidDetailsRef = React.createRef();
        this.state = {
            input: '',
            selectedBids: [],
            tenderHasStarted: false,
            counterModal: {
                show: false,
                bid: {},
                counterType: ''
            },
            availableActions: {
                accept: false,
                cancel: false,
                compare: false,
                counter: false,
                decline: false,
                editCounter: false,
                submitCounter: false,
            },
            heading: {
                main: "Details"
            },
            showModal: true,
            showModalTWo: false,
            modal: '',
            modalData: {},
            acceptBidCounterparty: null,
            acceptBidBody: null,
            isAcceptModal: false,
            isCounterModal: false,
            messagesPerPage: [],
            currentMessageIndex: 0,
            messageNoPerPage: 10,
            bankDetailsData: {}
        };
        this.createCounter = this.createCounter.bind(this);
        this.reviewCounter = this.reviewCounter.bind(this);
        this.editCounter = this.editCounter.bind(this);
        this.submitCounter = this.submitCounter.bind(this);
        this.getCompany = this.getCompany.bind(this);
        this.acceptBid = this.acceptBid.bind(this);
        this.declineBid = this.declineBid.bind(this);
        this.refreshTenderStart = this.refreshTenderStart.bind(this);
        this.latestNegotiationsDeclined = this.latestNegotiationsDeclined.bind(this);
        this.showReplyButton = this.showReplyButton.bind(this);
        this.loadQuoteStatus = this.loadQuoteStatus.bind(this);
        this.loadCompanies = this.loadCompanies.bind(this);
    }

    componentDidMount() {
        this.props.searchCompanies('');
        this.loadRequestData();
        this.loadCompanies();
        this.loadQuoteStatus();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.props.ClearSingleTrade();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const params = new URLSearchParams(window.location.search);
        const forceUpdateParam = params.get('forceUpdate');
        const canceledTradeParam=(params.get('canceledTrade')==='true')?true:false;
        
        let selectedCounterparty = this.props.match.params.counterparty || this.state.selectedCounterparty
        if (forceUpdateParam) {
            const forceUpdate = forceUpdateParam === 'true';
            if (forceUpdate) {
                this.loadRequestData();
                this.loadQuoteStatus();
                this.loadCompanies();
            }
            const newLocationSearch = this.props.location.search.replace(/(&|\\?)forceUpdate=(true|false)/g, '');
            this.props.navigate(window.location.pathname + newLocationSearch);
        }
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.ClearSingleTrade();
            if (this.props.trade && this.props.trade.items.single) {
                this.refreshHeadings()
            }
            this.loadRequestData();
            this.loadQuoteStatus();
            this.loadCompanies();
        }
        if (!prevProps.trade.shouldTriggerTradeUpdate && this.props.trade.shouldTriggerTradeUpdate) {
            this.props.AutoupdateTriggered();
            switch (this.props.trade.shouldTriggerTradeUpdate) {
                case 'TENDER_SUBSCRIPTION_REQUEST_NOTIF':
                case 'TENDER_SUBSCRIPTION_APPROVE_NOTIF':
                case 'TENDER_SUBSCRIPTION_REJECT_NOTIF':
                    this.props.getTenderSubscriptions(this.props.match.params.id);
                    break;
                case 'COMMENT_ADDED_NOTIF':
                    this.props.getMessage(this.props.match.params.id, () => {
                        selectedCounterparty = selectedCounterparty ? selectedCounterparty : this.state.selectedCounterparty;
                        const selectedMessages = this.getSelectedMessages(selectedCounterparty);
                        const messagesPerPage = this.getMessagesPerPage(selectedMessages);
                        this.setState({ selectedMessages, messagesPerPage, selectedCounterparty });
                    });
                    break;
                case 'TRADE_COUNTERED_NOTIF':
                case 'TRADE_BID_RECOUNTER_PRICE_IMPROVED':
                case 'TRADE_BID_SUBJECT_CONFIRM':
                    this.props.loadBids(this.props.match.params.id);
                    break;
                default:
                    this.loadRequestData();

            }

        }

        const statusNotIncluded = [BID_STATUS.NEW, BID_STATUS.PEND_CONFIRM_NEW, BID_STATUS.DECLINED, BID_STATUS.CANCELED, BID_STATUS.EXPIRED];
        if (!canceledTradeParam && this.props.trade.items.single && this.props.trade.items.single.status && !statusNotIncluded.includes(this.props.trade.items.single.status)) {
            this.props.navigateDeal('/details/' + this.props.match.params.id);
        }

        if ((this.props.trade.items.single !== prevProps.trade.items.single || prevProps.trade.bids !== this.props.trade.bids) &&
            this.props.trade.items.single && this.props.trade.bids) {
            this.setState({
                latestNegotiations: this.getLatestNegotiation(),
                pov: this.getPointOfView()
            });
            /* RDEV-1694 - Activate tender */
            this.refreshTenderStart();
            if (this.props.trade.items.single.privacyType === "TENDER") {
                if (!this.timer) {
                    this.timer = setInterval(this.refreshTenderStart, 5000);
                }
            }
        }

        /* RDEV-1694 Select a counterparty ( 'companyId' stored in state.selectedCounterparty).
        ** Negotiation (Bids and Messages) shown on the interface is the one between 
        ** the connected user and the selected counterparty */
        if (this.state.latestNegotiations !== prevState.latestNegotiations ||
            this.props.tender.tenderSubscriptions !== prevProps.tender.tenderSubscriptions) {
            const trade = this.props.trade.items.single || {};
            let selectedCounterPartyName;

            //If the connected user is not the owner of the trade, then the default selected counterparty is the owner of the trade
            if (trade.ownerId !== this.props.account.token.companyId) {
                selectedCounterparty = trade.ownerId;
                selectedCounterPartyName = trade.requestType === "BUY" ? trade.buyer : trade.seller;
            } else {
                //If a counterparty ID is provided in the request params
                //const counterpartyParam = params.get('selected');

                //In case of tender we look in the list of subscriptions, otherwise we look in the list of bid.
                if (trade.privacyType === "TENDER") {
                    if (this.props.tender.tenderSubscriptions.length !== 0) {
                        let selectedSubscription = this.props.tender.tenderSubscriptions[0];
                        if (selectedCounterparty) {
                            const querySubscription = this.props.tender.tenderSubscriptions.find(item => item.subscribedByUser.companyID === selectedCounterparty);
                            if (querySubscription) {
                                selectedSubscription = querySubscription;
                            }
                        }
                        selectedCounterparty = selectedSubscription.subscribedByUser.companyID;
                        selectedCounterPartyName = selectedSubscription.subscribedByUser.companyName;
                    }
                } else {
                    if (this.state.latestNegotiations.length !== 0) {
                        let selectedBid = this.state.latestNegotiations[0];
                        if (selectedCounterparty) {
                            const queryBid = this.state.latestNegotiations.find(item => (item.fromCompanyID === selectedCounterparty || item.toCompanyID === selectedCounterparty));
                            if (queryBid) {
                                selectedBid = queryBid;
                            }
                        }
                        const { id, name } = this.getCounterpartyBid(selectedBid);
                        selectedCounterparty = id;
                        selectedCounterPartyName = name;
                    }
                    //In case of public communication and before negotiation for tradeowner.Counterparty will be set accordingly 
                    if (this.PublicCommunicationList().length !== 0 && (this.props.trade.items.single && this.props.trade.items.single.privacyType === "PUBLIC") && this.isTradeOwner) {
                        const counterpartyList = this.PublicCommunicationList();
                        //Set latest counterPartyName on the basis of Notification for public communication and bids 
                        const counterpartyName = (this.props.match.params.counterparty && this.PublicCommunicationList().find(a => a.fromCompanyID === this.props.match.params.counterparty));
                        if (this.props.messages && counterpartyList.length !== 0) {
                            selectedCounterparty = this.props.match.params.counterparty ? this.props.match.params.counterparty : counterpartyList[0].fromCompanyID;
                            selectedCounterPartyName = this.props.match.params.counterparty ? (counterpartyName ? counterpartyName.companyName : selectedCounterPartyName) : counterpartyList[0].companyName;
                        }
                    }
                    if(this.props.trade.items.single && this.props.trade.items.single.privacyType==="MULTI_PRIVATE" && this.isTradeOwner && this.multiCommunicationList(this.props.trade.items.single.multiPrivateParties).filter(item => item.id === this.props.match.params.counterparty).length){
                        let Counter=this.props.trade.companies.companies &&  this.props.trade.companies.companies.find(item => item.ID === this.props.match.params.counterparty);
                         selectedCounterPartyName =Counter && Counter.name;
                         selectedCounterparty=this.props.match.params.counterparty;
                    }
                }
            }
            this.setState({ selectedCounterparty, selectedCounterPartyName });
        }
        /* When the messages recieved first time before negotiation 
          * counterpartyName and selectedCounterparty set accordingly */

        if ((this.props.messages !== prevProps.messages) && (this.props.trade.items.single && this.props.trade.items.single.privacyType === "PUBLIC") && this.isTradeOwner) {
            const counterpartyList = this.PublicCommunicationList();
            if (this.props.messages && counterpartyList && counterpartyList.length === 1 && this.state.latestNegotiations && this.state.latestNegotiations.length === 0) {
                let selectedCounterPartyName = counterpartyList[0].companyName;
                let selectedCounterparty = counterpartyList[0].fromCompanyID;
                this.setState({ selectedCounterparty, selectedCounterPartyName });
            }
        }
        //intial counterparty and name in case of Multi-Private
        if ((this.state.selectedCounterparty===undefined && this.state.selectedCounterPartyName===undefined)   && this.props.trade.items.single && this.props.trade.items.single.privacyType==="MULTI_PRIVATE" && this.isTradeOwner) {
            const { id, name } = this.props.trade.items.single.multiPrivateParties[0];
            this.onMultiCommunicationSelected(id,name)
            if(this.props.match.params.counterparty && this.props.trade && this.props.trade.companies)
            {
                let Counter=this.props.trade.companies.companies && this.props.trade.companies.companies.find(item => item.ID === this.props.match.params.counterparty);
                let selectedCounterPartyName =Counter && Counter.name;
                let selectedCounterparty=this.props.match.params.counterparty;
                this.setState({ selectedCounterparty, selectedCounterPartyName });
            }
        }

        if ((prevProps.match.params.counterparty !== this.props.match.params.counterparty) && this.props.trade.items.single && this.props.trade.items.single.privacyType==="MULTI_PRIVATE" && this.isTradeOwner && this.multiCommunicationList(this.props.trade.items.single.multiPrivateParties).filter(item => item.id === this.props.match.params.counterparty).length) {
            let Counter= this.props.trade.companies && this.props.trade.companies.companies.find(item => item.ID === this.props.match.params.counterparty);
                let selectedCounterPartyName =Counter && Counter.name;
                let selectedCounterparty=this.props.match.params.counterparty;
                this.setState({ selectedCounterparty, selectedCounterPartyName });
        }

        if (prevProps.match.params.counterparty !== this.props.match.params.counterparty) {
            selectedCounterparty = this.props.match.params.counterparty
            this.setState({ selectedCounterparty })
        }
       //if counterparty name is not defined 
        if ((this.state.selectedCounterPartyName !== prevState.selectedCounterPartyName) && this.props.trade && this.props.trade.companies && this.props.trade.companies.companies) {
            let selectedCounter = this.props.trade.companies && this.props.trade.companies.companies.find(item => item.ID === this.state.selectedCounterparty);
            let selectedCounterPartyName =selectedCounter && selectedCounter.name;
            this.setState({ selectedCounterPartyName });
        }

        /* When the selected counterparty changes 
         * Messages and Bids are filtered accordingly */
        if (this.state.selectedCounterparty !== prevState.selectedCounterparty) {
            const selectedMessages = this.getSelectedMessages(this.state.selectedCounterparty);
            const selectedBids = this.getSelectedBid(this.state.selectedCounterparty);
            const messagesPerPage = this.getMessagesPerPage(selectedMessages);
            this.setState({ selectedBids, selectedMessages, messagesPerPage });
        }

        /* When bid list changed ( IE: bids are loaded, new counter is recieved ...)
         * Bids are filtered according to the selected counterparty */
        if (this.props.trade.bids !== prevProps.trade.bids) {
            const selectedBids = this.getSelectedBid(this.state.selectedCounterparty)
            this.setState({ selectedBids });
        }

        /* When tender subscriptions changes or when a the selected bids change
          New heading are calculated */
        if (this.state.selectedBids !== prevState.selectedBids ||
            this.props.tender.tenderSubscriptions !== prevProps.tender.tenderSubscriptions) {
            this.refreshHeadings();
            this.refreshActions();
        }
    }



    refreshTenderStart() {
        if (this.props.trade.items.single) {
            const startDate = moment(this.props.trade.items.single.tenderStartDate);
            const now = moment(new Date());
            if (now.isSameOrAfter(startDate, 'minute')) {
                this.setState({ tenderHasStarted: true });
                clearInterval(this.timer);
            } else {
                this.setState({ tenderHasStarted: false });
            }
        }
    }

    loadCompanies(){
        this.props.preloadInspectionCompanies();
        this.props.getShippingCompanyList();
    }

    loadQuoteStatus(){
        this.props.getInpectionStatus(this.props.match.params.id, "PROVISIONAL", this.props.account.token.companyId);
        this.props.getshippingStatus(this.props.match.params.id, "PROVISIONAL", this.props.account.user.companyId);
    }

    getCounterpartyBid(bid) {
        if (bid.fromCompanyID !== this.props.account.token.companyId) {
            return { id: bid.fromCompanyID, name: bid.fromCompanyName };
        }
        if (bid.toCompanyID !== this.props.account.token.companyId) {
            return { id: bid.toCompanyID, name: bid.toCompanyName };
        } else {
            return {};
        }
    }

    getCounterparty(trade) {
        if (trade.buyerId === this.props.account.token.companyId) {
            return { id: trade.sellerId, name: trade.seller };
        }
        if (trade.sellerId === this.props.account.token.companyId) {
            return { id: trade.buyerId, name: trade.buyer };
        } else {
            return {};
        }
    }

    getPointOfView() {
        if (this.props.trade.items.single.requestType === "BUY") {
            if (this.props.account.token.companyId === this.props.trade.items.single.ownerId) {
                return "BUYER";
            } else {
                return "SELLER";
            }
        }
        if (this.props.trade.items.single.requestType === "SELL") {
            if (this.props.account.token.companyId === this.props.trade.items.single.ownerId) {
                return "SELLER";
            } else {
                return "BUYER";
            }
        }
        return undefined;
    }

    loadRequestData() {
        const { match } = this.props;
        this.props.loadRequestDetails(match.params.id);
        this.props.loadBids(match.params.id);
        this.props.getMessage(match.params.id);
    }

    getCompany = (companyId) => {
        let company = undefined;
        try {
            if (this.props.trade && this.props.trade.companies && this.props.trade.companies.companies) {
                company = this.props.trade.companies.companies.find(item => item.ID === companyId);
            }
        } catch (error) {
            console.error(error);
        }
        return company;
    }

    cancelBankDetailSubmission = () => {
        this.setState(prevState => ({
            showModalTWo: false,
            modal: '',
            counterModal: {
                ...prevState.counterModal,
                show: true
            },
            counterLoaded: null
        }))
    }

    renderModal = (whatModal) => {
        switch (whatModal) {
            case 'PERMISSION_REQUIRED':
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Link>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>
                                </div>
                                <div className="modal-footer">
                                    <Link to="/" className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case 'PERMISSION_REQUIREDTWO':

                return (

                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();

                                this.closeModal();


                            }}>
                            <div className="modal-content">

                                <div className="modal-header">
                                    <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Link>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text"><h6>You are not authorized to perform this action.</h6>
                                    </p>
                                </div>
                                <div className="modal-footer">


                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel
                            </button>

                                </div>
                            </div>
                        </form>

                    </div>
                );
            case 'INSPECTION_NOT_APPROVED':
                return InspectionNotAvailableModal({
                    onClick: () => {
                        if (this.state.isAcceptModal) {
                            if (bypassAuthCodePermission() === 1) {
                                this.saveBidOrCounter();
                                this.closeModal();
                            } else {
                                this.setState({
                                    modal: 'AUTHORISATION_CODE_MODAL'
                                });
                            }
                            this.props.sendEmail(this.props.trade.items.single.id);
                        } else {
                            this.closeModal();
                        }
                    }
                });
            case 'AUTHORISATION_CODE_MODAL':
                return (
                    <AuthorisationCodeModal closeModal={(isAuthorized) => {
                        if (isAuthorized) {
                            this.saveBidOrCounter();
                        }
                        this.closeModal();
                    }} />
                );
            case 'AUTHORISATION_CODE_MODAL_FOR_BANK_DETAILS':
                return (
                    <AuthorisationCodeModal closeModal={(isAuthorized) => {
                        if (isAuthorized) {
                            this.saveOrRejectBankDetails(this.state.bankDetailsData.rejecting, this.state.bankDetailsData.validationDate, true);
                        }
                        this.closeModal();
                    }} />
                );
            case 'AUTHORISATION_CODE_MODAL_FOR_ACCEPT_OFFER':
                return (
                    <AuthorisationCodeModal closeModal={(isAuthorized) => {
                        if (isAuthorized) {
                            this.onSubmitAcceptOffer(this.state.acceptBidBody);
                        }
                        this.closeModal();
                    }} />
                );
            case 'SUBMIT_BANK_DETAILS':
                return submitBankDetailsModal({
                    onCancel: this.cancelBankDetailSubmission,
                    onSubmit: () => this.submitBankDetails(false),
                    loading: this.props.loadingBankDetails
                });
            case 'REQUEST_ALTERNATIVE_BANK':
                return (
                    <AlternateBankRequestModal
                        onCancel={this.closeModal}
                        onSubmit={this.submitBankDetails}
                        loading={this.props.loadingBankDetails} />
                )
            default:
                break;
        }
    };

    saveBidOrCounter = () => {
        if (this.state.isAcceptModal) {
            this.saveAcceptBid();
        } else if (this.state.isCounterModal) {
            this.saveCounterBid();
        }
    }

    openModal = (name, data) => {
        this.setState({ showModalTWo: true, modal: name, modalData: data });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            showModalTWo: false,
            modal: '',
            modalData: {},
            isAcceptModal: false,
            isCounterModal: false,
            bankDetailsData: {}
        });
    };

    /* Actions on trade request */

    /* Cancel the trade request */
    cancelTrade = () => {
        const request = this.props.trade.items.single;
        if (request) {
            this.props.LoadingInitiate(true);
            this.props.cancel(request.id, r => {
                this.props.LoadingInitiate(false);
                if (r.status === TRADE_STATUS.DEAL) {
                    this.props.navigateDeal('/details/' + this.props.match.params.id);
                } else {
                    this.props.navigateDecline('/requests');
                }
            })
        }
    }

    /* Actions on bids */
    acceptBid = (counterpartyId, inspectionId, acceptBidBody = null) => {
        this.setState({ acceptBidCounterparty: counterpartyId, acceptBidBody }, () => {
            let selectedInspectionCompany = this.props.trade.inspectionCompanies.find(company => company.ID === inspectionId);
            if (selectedInspectionCompany && !selectedInspectionCompany.serviceProviderFlag && this.checkIsSeller()) {
                this.setState({
                    showModalTWo: true,
                    modal: 'INSPECTION_NOT_APPROVED',
                    isAcceptModal: true
                });
            } else {
                if (bypassAuthCodePermission() === 1) {
                    this.saveAcceptBid();
                } else {
                    this.setState({
                        showModalTWo: true,
                        modal: 'AUTHORISATION_CODE_MODAL',
                        isAcceptModal: true
                    });
                }
            }

        });
    };

    saveAcceptBid = () => {
        this.props.acceptBid(this.props.match.params.id, this.state.acceptBidCounterparty, () => {
            this.props.navigateDeal('/details/' + this.props.match.params.id);
        }, this.state.acceptBidBody);
    }

    declineBid = (counterpartyId, params) => {
        this.props.declineBid(this.props.match.params.id, counterpartyId, params, () => {
            this.loadRequestData();

        });
    };


    cancelCounter = () => {
        this.setState({ "counterLoaded": undefined }, this.refreshHeadings);
        this.enableAction("editCounter", false);
        this.enableAction("submitCounter", false);
        this.enableAction("cancelCounter", false);

    }

    createCounter = (bid, counterType = COUNTER_TYPES.NORMAL) => {
        this.setState((state, props) => {
            let withoutPrice = (props.trade.items.single.price === undefined || props.trade.items.single.price === 0 || props.trade.items.single.price === '0');
            if (withoutPrice && !bid.previousBidId) {
                bid.type = "FIRST_OFFER";
            } else {
                bid.type = "COUNTER";
                if(counterType === COUNTER_TYPES.BANK_ONLY){
                    const bankDetails = {
                        nameOfBank: '', 
                        locationOfBank: '', 
                        swiftCodeOfBank: '', 
                        nameOfConfirmingBank: '', 
                        locationOfConfirmingBank: '', 
                        swiftCodeOfConfirmingBank: ''
                    };
                    bid = {...bid, ...bankDetails};
                }
                if(counterType === COUNTER_TYPES.IMPROVE_PRICE) bid.reCounterInd = true;
            }
            if(counterType === COUNTER_TYPES.BANK_ONLY) bid.bankOnlyInd = true;
            return {
                counterModal: {
                    ...state.counterModal,
                    show: true,
                    bid: bid,
                    counterType
                }
            }
        })
    }

    editCounter = () => {
        if (this.state.counterLoaded) {
            this.setState((prevState) => ({
                counterModal: {
                    ...prevState.counterModal,
                    show: true,
                    bid: prevState.counterLoaded,
                    counterType: prevState.counterLoaded.reCounterInd ? COUNTER_TYPES.IMPROVE_PRICE : prevState.counterLoaded.bankOnlyInd ? COUNTER_TYPES.BANK_ONLY : COUNTER_TYPES.NORMAL
                }
            }))
        }
    }

    sendEmailInspectionNotAvailable = () => {
        const counterOffer = this.state.counterLoaded || this.state.counterModal.bid;
        let selectedInspectionCompany = this.props.trade.inspectionCompanies.find(company => company.ID === counterOffer.inspection);
        if (selectedInspectionCompany && !selectedInspectionCompany.serviceProviderFlag && this.checkIsSeller()) {
            this.props.sendEmail(this.props.trade.items.single.id);
        }
    }

    submitCounter = () => {
        if (bypassAuthCodePermission() === 1) {
            this.saveCounterBid();
        } else {
            this.onVerify();
            this.setState({
                showModalTWo: true,
                modal: 'AUTHORISATION_CODE_MODAL',
                isCounterModal: true
            });
        }

        //send email if inspection not available
        this.sendEmailInspectionNotAvailable();
    }

    afterSubmittingBankDetails = () => {
        this.setState(state => ({
            counterModal: {
                ...state.counterModal,
                bid: {},
                show: false,
                counterType: ''
            },
            showModalTWo: false,
            modal: '',
            bankDetailsData: {}
        }));
    }

    saveOrRejectBankDetails = (rejectingBankDetails, bidValidationDate, afterAuthorization) => {
        let counteredBid = null
        if (rejectingBankDetails) {
            const notRequiredKeys = ['ID', 'acceptedAt', 'acceptedByCompanyId', 'acceptedByCompanyName', 'acceptedByUserName', 'buyer', 'buyerAddress1', 'buyerUser', 'createdAt', 'declineReason', 'declinedByCompanyId', 'declinedByCompanyName', 'fromCompanyID', 'fromCompanyName', 'selected', 'seller', 'sellerAddress1', 'sellerUser', 'systemBid', 'terms', 'tradeRefId', 'toCompanyID', 'toCompanyName', 'validateDate',];
            counteredBid = Object.keys(this.state.counterModal.bid).reduce((prev, key) => {
                if (!notRequiredKeys.includes(key)) {
                    prev[key] = this.state.counterModal.bid[key];
                }
                return prev;
            }, {})
            counteredBid.status = BID_STATUS.REVIEW;
            counteredBid.bidValidateDate = bidValidationDate;
            counteredBid.subjectToConfirmInd = false;
            counteredBid.toCompanyID = this.state.counterModal.bid.fromCompanyID
        } else {
            counteredBid = { ...this.state.counterModal.bid, subjectToConfirmInd: false };
        }
        this.props.counterBankDetails(this.props.trade.items.single.id, this.state.selectedCounterparty, counteredBid, afterAuthorization, (err) => {
            if (err) {
                //Handle err submission
            } else {
                this.afterSubmittingBankDetails();
                this.refreshActions();
            }
        }, this.afterSubmittingBankDetails);
    }

    submitBankDetails = (rejecting, validationDate) => {
        if (bypassAuthCodePermission() === 1) {
            this.saveOrRejectBankDetails(rejecting, validationDate, false);
        } else {
            this.onVerify();
            this.setState({
                bankDetailsData: { rejecting, validationDate },
                showModalTWo: true,
                modal: 'AUTHORISATION_CODE_MODAL_FOR_BANK_DETAILS',
                isCounterModal: true
            });
        }

        //send email if inspection not available
        this.sendEmailInspectionNotAvailable();
    }


    onVerify = () => { return false }
    saveCounterBid = () => {
        let counterLoaded = { ...this.state.counterLoaded };
        counterLoaded.bankOnlyInd = false;
        counterLoaded.subjectToConfirmInd = false;
        this.props.counter(this.props.trade.items.single.id, this.state.selectedCounterparty, counterLoaded, (err, data) => {
            if (err) {
                //Handle error submit
            } else {
                this.setState(state => delete state.counterLoaded);
                this.refreshActions();
            }
        });
    }

    closeCounterModal = () => {
        this.setState((state,) => ({ counterModal: { ...state.counterModal, show: false, bid: {}, counterType: '' } }));
    };

    reviewCounter = (bid) => {
        let updatedBid = { ...bid };
        updatedBid.origin = bid.origin.join(',');
        this.closeCounterModal();
        this.refreshHeadings();
        updatedBid.status = BID_STATUS.REVIEW;
        this.setState({ counterLoaded: updatedBid }, this.refreshHeadings);
        this.enableAction("editCounter", true);
        this.enableAction("submitCounter", true);
        this.enableAction("cancelCounter", true);

        //check inspection not available and show message
        let selectedInspectionCompany = this.props.trade.inspectionCompanies.find(company => company.ID === bid.inspection);
        if (selectedInspectionCompany && !selectedInspectionCompany.serviceProviderFlag && this.checkIsSeller()) {
            this.setState({
                showModalTWo: true,
                modal: 'INSPECTION_NOT_APPROVED',
                isCounterModal: true
            });
        }
    }

    checkIsSeller = () => {
        let trade = this.props.trade.items.single;
        let isPrivaterade = trade.sellerId && trade.buyerId;
        if (isPrivaterade) {
            if (trade.sellerId === this.props.account.user.companyId) {
                return true;
            }
        } else {
            if ((trade.requestType === "SELL" && trade.sellerId === this.props.account.user.companyId) ||
                (trade.requestType === "BUY" && trade.buyerId !== this.props.account.user.companyId)) {
                return true;
            }
        }
        return false;
    }

    reply = (messageID) => {
        this.props.ReplyMessage(messageID, this.props.account.user.id);
        this.setState({});
    };


    showReplyButton(message) {
        if (message.toCompanyID === "0") {
            return false;
        }
        return true;
    }



    /* RDEV-1607 adding fiels needed for local update: 
        -  userID, 
        -  userName, 
        -  companyName 
        Those fiels should be retreived by the API postTradeMessage (POST : /trade/${id}/comments) 
        Actually all post APIs should retrive the saved object as it is saved in the BE
        The fileds : 
        -  parentId
        -  id
        Should be written parentID and ID to be consitent with the rest of the app */
    postMessage = (e, messageID, fromCompanyID, toCompanyID) => {
        e.preventDefault();
        if (this.state.input !== '') {
            let message = {
                parentID: messageID,
                fromCompanyID: fromCompanyID,
                toCompanyID: toCompanyID,
                text: this.state.input,
                tradeRefID: this.props.trade.items.single.tradeRefId
            };
            this.props.postMessage(this.props.match.params.id, message, () => {
                let messages = [...this.props.messages];
                this.setState({
                    messagesPerPage: this.getMessagesPerPage(messages)
                })
            });
        }
    };

    //Return the list of latest negociation for each counterparty
    getLatestNegotiation() {
        const userCompId = this.props.account.token.companyId;
        let counterpartyList = [];
        return this.props.trade.bids.filter(bid => {
            if (bid.fromCompanyID === userCompId && !counterpartyList.includes(bid.toCompanyID)) {
                counterpartyList.push(bid.toCompanyID);
                return true
            }
            if (bid.toCompanyID === userCompId && !counterpartyList.includes(bid.fromCompanyID)) {
                counterpartyList.push(bid.fromCompanyID);
                return true
            }

            return false;
        });
    }

    //Return a list of bid/counter involving the counterpartyId in parameter
    getSelectedBid(counterpartyId) {
        let selected = [];
        if (counterpartyId) {
            selected = this.props.trade.bids.filter(bid => {
                return ((bid.fromCompanyID === counterpartyId && bid.toCompanyID === this.props.account.user.companyId) ||
                    (bid.toCompanyID === counterpartyId && bid.fromCompanyID === this.props.account.user.companyId))
            });
        }
        if (selected.length !== 0) {
            selected[0].selected = true;
        }
        return selected;
    }

    getSelectedMessages(counterpartyId) {
        let selected = [];
        if (counterpartyId) {
            selected = this.props.messages.filter(msg => {
                return ((msg.fromCompanyID === counterpartyId && msg.toCompanyID === this.props.account.user.companyId) ||
                    (msg.toCompanyID === counterpartyId && msg.fromCompanyID === this.props.account.user.companyId))
            });
        }
        return selected;
    }

    getMessagesPerPage(messages, index, count) {
        if (messages) {
            let msgPerPage = [...messages];
            if (index || count) {
                msgPerPage = msgPerPage.splice(index, count);
            } else {
                msgPerPage = msgPerPage.splice(this.state.currentMessageIndex, this.state.messageNoPerPage);
            }
            return msgPerPage;
        }
    }

    postRootMessage = (e, value) => {
        e.preventDefault();
        if (value !== '') {
            let message = {
                fromCompanyID: this.props.account.token.companyId,
                toCompanyID: this.state.selectedCounterparty,
                tradeRefID: this.props.trade.items.single.tradeRefId,
                parentID: null,
                text: value,
            };
            this.props.postMessage(this.props.match.params.id, message, () => {
                let messages = this.getSelectedMessages(this.state.selectedCounterparty);
                this.setState({
                    messagesPerPage: this.getMessagesPerPage(messages)
                })
            });
            this.textArea.current.value = '';
        }
    };
    postPublicMessage = (value) => {
        //  e.preventDefault();
        this.bidDetailsRef.current.scrollIntoView({ behavior: 'smooth', block: "start", inline: "start" });
        if (value !== '') {
            let message = {
                fromCompanyID: this.props.account.token.companyId,
                toCompanyID: this.state.selectedCounterparty,
                tradeRefID: this.props.trade.items.single.tradeRefId,
                parentID: null,
                text: value,
            };
            this.props.postMessage(this.props.match.params.id, message, () => {
                let messages = this.getSelectedMessages(this.state.selectedCounterparty);
                this.setState({
                    messagesPerPage: this.getMessagesPerPage(messages)
                })
            });
        }
    };





    getReply = value => {
        this.setState({
            input: value
        });
    };

    get tradeRequest() {
        return this.props.trade.items.single || {};
    }

    get isTradeOwner() {
        if (this.props.account && this.props.account.token) {
            return this.props.account.token.companyId === this.tradeRequest.ownerId;
        }
        return false;
    }

    get isPrivateTrade() {
        return this.tradeRequest.buyerId && this.tradeRequest.sellerId;
    }

    isBidOwner(bid) {
        if (bid && bid.fromCompanyID) {
            return bid.fromCompanyID.trim() === this.props.account.token.companyId.trim();
        } else {
            return false
        }
    }

    isDeclinedByOwner(bid) {
        if (bid && bid.declinedByCompanyId) {
            return bid.declinedByCompanyId.trim() === this.props.account.token.companyId.trim();
        } else {
            return false
        }
    }

    onSubscriptionSelected = (subscription) => {
        if (this.isTradeOwner) {
            this.setState({
                selectedCounterparty: subscription.subscribedByUser.companyID,
                selectedCounterPartyName: subscription.subscribedByUser.companyName
            });

        }
    }

    onBidSelected = (bid) => {
        if (this.isTradeOwner) {
            if (bid.fromCompanyID !== this.tradeRequest.ownerId) {
                this.setState({
                    selectedCounterparty: bid.fromCompanyID,
                    selectedCounterPartyName: bid.fromCompanyName
                });
            } else {
                this.setState({
                    selectedCounterparty: bid.toCompanyID,
                    selectedCounterPartyName: bid.toCompanyName
                });
            }
        }
    }

    onCommunicationSelected = (item) => {
        if (this.isTradeOwner) {
            this.setState({
                selectedCounterparty: item.fromCompanyID,
                selectedCounterPartyName: item.companyName
            });

        }
    }
    onMultiCommunicationSelected = (CompanyID,companyName) => {
        if (this.isTradeOwner) {
            this.setState({
                selectedCounterparty: CompanyID,
                selectedCounterPartyName: companyName
            });

        }
    }


    PublicCommunicationList = () => {

        var listCounetpartyMessage = [];
        const fromCompanyIDFilter = this.state.latestNegotiations && this.state.latestNegotiations.map(s => s.fromCompanyID);
        const toCompanyIDFilter = this.state.latestNegotiations && this.state.latestNegotiations.map(s => s.toCompanyID);
        const message = Array.from(new Set(this.props.messages.map(s => s.fromCompanyID)))
            .map(fromCompanyID => {
                return {
                    fromCompanyID: fromCompanyID,
                    companyName: this.props.messages.find(s => s.fromCompanyID === fromCompanyID).companyName,
                    createdAt: this.props.messages.find(s => s.fromCompanyID === fromCompanyID).createdAt,
                };
            });
        const listCounetparty = this.state.latestNegotiations && message.filter(a1 => { return (fromCompanyIDFilter.indexOf(a1.fromCompanyID) === -1 && toCompanyIDFilter.indexOf(a1.fromCompanyID) === -1) });
        listCounetpartyMessage = listCounetparty && listCounetparty.filter(a => a.fromCompanyID !== this.props.trade.items.single.ownerId);
        return listCounetpartyMessage;
    }

    multiCommunicationList = (multiParty) => {
        let listCounetparty = [];
        if (multiParty) {
            if (this.state.latestNegotiations && this.state.latestNegotiations.length !== 0) {
                const fromCompanyIdFilter = this.state.latestNegotiations && this.state.latestNegotiations.map(s => s.fromCompanyID);
                listCounetparty = this.state.latestNegotiations && multiParty.filter(company => !fromCompanyIdFilter.includes(company.id));
            }
            else {
                listCounetparty = multiParty;
            }
        }
        return listCounetparty;
    }


    enableAction(action, condition) {
        this.setState(state => ({
            availableActions: {
                ...state.availableActions,
                [action]: condition
            }
        }))
    }

    refreshHeadings() {
        const trade = this.props.trade.items.single;
        if (!trade) {
            return this.setState({
                heading: {
                    main: "Bid Detail"
                }
            })
        }
        const hasPrice = (trade.price && trade.price !== 0 && trade.price !== '0');
        const hasSelectedBids = this.state.selectedBids.length !== 0;
        const privacy = trade.privacyType;
        const isOffer = (this.state.selectedBids.length === 1 && this.state.selectedBids[0].status !== BID_STATUS.DECLINED && !hasPrice);
        const isOfferDeclined = (this.state.selectedBids.length === 1 && this.state.selectedBids[0].status === BID_STATUS.DECLINED && this.state.selectedBids[0].systemBid);

        let heading = {
            main: "Default",
            sub: "default sub",
        };

        if (this.state.counterLoaded) {
            if(this.state.counterLoaded.reCounterInd){
                heading.main = `You are reviewing improved price for ${(!hasPrice && !hasSelectedBids) ? "an offer" : "a counter"} for ${this.state.selectedBids[0].toCompanyName}`;
                heading.flag = `Review Improved Price`;
            }else{
                heading.main = `You are reviewing ${(!hasPrice && !hasSelectedBids) ? "an offer" : "a counter"}`;
                heading.flag = `Review`;
            }   
        } else if (!hasSelectedBids) {
            if (this.isTradeOwner) {
                if (privacy === "TENDER" && moment(trade.validateDate).isBefore()) {
                    heading.main = `Tender has expired`;
                } else if (privacy === "TENDER" && this.props.tender && this.props.tender.tenderSubscriptions.length === 0) {
                    heading.main = `Tender open for subscriptions`;
                    heading.flag = `Tender open for subscriptions`;
                } else if (privacy === "TENDER" && this.props.tender && !this.state.tenderHasStarted) {
                    heading.main = `Tender is not open yet`;
                    heading.flag = `Subscription requested`;
                } else {
                    heading.main = `No ${hasPrice ? 'counter' : 'offer'} received`;
                    heading.flag = `No ${hasPrice ? 'counter' : 'offer'} received`;
                }
            } else {
                heading.main = `${(trade.privacyType === "MULTI_PRIVATE" && !this.isTradeOwner) ? "Private" : REQUEST_PRIVACY_TYPE[trade.privacyType]} ${(trade.requestType === "SELL") ? "RTS" : "RTB"} from ${(trade.requestType === "SELL") ? trade.seller : trade.buyer}`;
                if (trade.requestType === "SELL" && trade.sellerUser) {
                    heading.main = `${heading.main} (${trade.sellerUser})`;
                }
                if (trade.requestType === "BUY" && trade.buyerUser) {
                    heading.main = `${heading.main} (${trade.buyerUser})`;
                }
                if (privacy === "TENDER") {
                    if (this.props.tender && this.props.tender.tenderSubscriptions.length === 0) {
                        heading.flag = 'Subscribe';
                    } else if (this.props.tender) {
                        const status = this.props.tender.tenderSubscriptions[0].status;
                        if (this.state.tenderHasStarted && status === "APPROVED") {
                            heading.flag = `${hasPrice ? 'Make a Counter' : 'Make an Offer'}`;
                        } else {
                            heading.flag = `Subscription ${TENDER_SUBSCRIPTIONS_STATUS[this.props.tender.tenderSubscriptions[0].status]}`;
                        }
                    }

                } else {
                    heading.flag = `${hasPrice ? 'Make a Counter' : 'Make an Offer'}`;
                }

            }

        } else {
            const isOwnerOfLastBid = this.isBidOwner(this.state.selectedBids[0]);
            const isOwnerOfDeclinedBid = this.isDeclinedByOwner(this.state.selectedBids[0]);

            const status = this.state.selectedBids[0].status;
            const previousBid = this.props.trade.bids.find(bid => bid.ID === this.state.selectedBids[0].previousBidId);
            const RTS_SELLER = this.props.trade.items.single.requestType === 'SELL' && this.isTradeOwner;
            const RTS_BUYER = this.props.trade.items.single.requestType === 'SELL' && !this.isTradeOwner;
            const isPriceImproved = status === BID_STATUS.NEW && previousBid && previousBid.status === BID_STATUS.IMPROVED;

            switch (status) {
                case BID_STATUS.DECLINED:
                    if (isOwnerOfDeclinedBid) {
                        heading.main = `You have declined ${isOfferDeclined ? "an offer" : "a counter"} from ${this.state.selectedBids[0].fromCompanyName}`;
                        heading.flag = `${hasPrice ? 'counter' : 'offer'} declined`;
                    } else {
                        heading.main = `Your ${isOfferDeclined ? "offer" : "counter"} has been declined by ${this.state.selectedBids[0].declinedByCompanyName}`;
                        heading.flag = `${hasPrice ? 'counter' : 'offer'} declined`;
                    }

                    break;
                case BID_STATUS.NEW:
                    if (isOwnerOfLastBid) {
                        if(isPriceImproved){
                            heading.main = `You have sent an improved price to ${this.state.selectedBids[0].toCompanyName}`;
                            heading.flag = 'Improved price Sent';
                        }else{
                            heading.main = `You have sent ${isOffer ? "an offer" : "a counter"} to ${this.state.selectedBids[0].toCompanyName}`;
                            heading.flag = `${hasPrice ? 'counter' : 'offer'} sent`;
                        }
                    } else {
                        if(isPriceImproved){
                            heading.main = `You received an improved price from ${this.state.selectedBids[0].fromCompanyName}`;
                            heading.flag = 'Improved price received';
                        }else{
                            heading.main = `You received ${isOffer ? "an offer" : "a counter"} from ${this.state.selectedBids[0].fromCompanyName}`;
                            heading.flag = `${hasPrice ? 'counter' : 'offer'} received`;
                        }
                    }
                    break;
                case BID_STATUS.PEND_CONFIRM_NEW:
                    if (isOwnerOfLastBid) {
                        heading.main = `You have sent an acceptance to ${this.state.selectedBids[0].toCompanyName} for confirmation`;
                        heading.flag = 'Acceptance Sent Pending Confirmation';
                    }else{
                        heading.main = `You have received an acceptance from ${this.state.selectedBids[0].fromCompanyName} for confirmation`;
                        heading.flag = 'Acceptance Received Pending Confirmation';
                    }
                    break;
                case BID_STATUS.BANK_ONLY_NEW:
                    if (RTS_SELLER) {
                        if (isOwnerOfLastBid) {
                            heading.main = `You have requested ${this.state.selectedBids[0].toCompanyName} for new bank details`;
                            heading.flag = `Bank Details Rejected`;
                        } else {
                            heading.main = `${this.state.selectedBids[0].fromCompanyName} accepted your offer and proposed bank details for your approval`;
                            heading.flag = `Bank Details Received`;
                        }
                    }
                    if (RTS_BUYER) {
                        if (isOwnerOfLastBid) {
                            heading.main = `You have sent your bank details to ${this.state.selectedBids[0].toCompanyName} for approval`;
                            heading.flag = `Bank Details Sent`;
                        } else {
                            heading.main = `${this.state.selectedBids[0].fromCompanyName} has rejected your previous bank details and asked for alternative bank details`;
                            heading.flag = `Bank Details Rejected`;
                        }
                    }
                    break;
                case BID_STATUS.REVIEW:
                    heading.main = `You are reviewing ${isOffer ? "an offer" : "a counter"} for ${this.state.selectedBids[0].toCompanyName}`;
                    heading.flag = `Review`;
                    break;
                default: break;

            }
        }

        this.setState({ heading: heading })
    }

    latestNegotiationsDeclined = () => {
        let declined = true;
        for (let item of this.state.latestNegotiations) {
            if (item.status !== BID_STATUS.DECLINED) {
                declined = false;
                break;
            }
        }
        return declined;
    }

    refreshActions = () => {
        const trade = this.tradeRequest;
        const privacy = (trade.buyerId && trade.sellerId) ? 'PRIVATE' : 'PUBLIC';
        if (this.isTradeOwner) {
            //ACTIONS ON TRADE


            this.enableAction("compare", this.state.latestNegotiations && this.state.latestNegotiations.length >= 2);
            //ACTIONS ON DEAL
            const canAccept = this.state.selectedBids.length !== 0
                && this.state.selectedBids[0].toCompanyID === this.props.account.token.companyId
                && this.state.selectedBids[0].status === BID_STATUS.NEW
                && !this.state.counterLoaded
            this.enableAction("accept", canAccept);
            this.enableAction("counter", canAccept);
            this.enableAction("decline", canAccept);
            //REVIEW COUNTER BID/OFFER
            this.enableAction("editCounter", this.state.counterLoaded);
            this.enableAction("submitCounter", this.state.counterLoaded);
            this.enableAction("cancelCounter", this.state.counterLoaded);

        } else {
            //ACTIONS ON DEAL
            const canAccept = !this.state.counterLoaded &&
                (this.state.selectedBids.length === 0 ||
                    (this.state.selectedBids[0].toCompanyID === this.props.account.token.companyId && this.state.selectedBids[0].status === BID_STATUS.NEW))

            this.enableAction("accept", canAccept);
            this.enableAction("counter", canAccept);
            this.enableAction("decline", (canAccept && privacy === "PRIVATE") || (trade.price && trade.price !== 0));

            //REVIEW COUNTER BID/OFFER
            this.enableAction("editCounter", this.state.counterLoaded);
            this.enableAction("submitCounter", this.state.counterLoaded);
            this.enableAction("cancelCounter", this.state.counterLoaded);
        }
    }

    onAlternateBankRequest = () => {
        this.setState(prevState => {
            let bid = prevState.selectedBids[0];
            bid.type = "COUNTER";
            bid.bankOnlyInd = true;
            return {
                showModalTWo: true,
                modal: 'REQUEST_ALTERNATIVE_BANK',
                counterModal: {
                    ...prevState.counterModal,
                    bid: bid,
                    counterType: ''
                }
            }
        })
    }

    onSubmitAcceptOffer = (bidObj) => {
        this.props.counter(this.props.trade.items.single.id, this.state.selectedCounterparty, bidObj, (err, data) => {
            if (err) {
                //Handle error submit
            } else {
                this.setState({acceptBidBody: null});
                this.refreshActions();
            }
        });
    }

    onAcceptOffer = (counteredBid) => {
        if (bypassAuthCodePermission() === 1) {
            this.onSubmitAcceptOffer(counteredBid)
        } else {
            this.onVerify();
            this.setState({
                acceptBidBody: counteredBid,
                showModalTWo: true,
                modal: 'AUTHORISATION_CODE_MODAL_FOR_ACCEPT_OFFER',
                isCounterModal: true
            });
        }

        //send email if inspection not available
        this.sendEmailInspectionNotAvailable();
    }

    onCounterReview = (counteredRequest) => {
        if(this.state.counterModal.counterType === COUNTER_TYPES.BANK_ONLY){
            counteredRequest.origin = counteredRequest.origin.join(',');
            counteredRequest.bankOnlyInd = true;
            counteredRequest.status = BID_STATUS.REVIEW;
            this.setState(prevState => ({
                counterModal: {
                    ...prevState.counterModal,
                    bid: counteredRequest,
                    show: false
                },
                showModalTWo: true,
                modal: 'SUBMIT_BANK_DETAILS'
            }))
        }else{
            this.bidDetailsRef.current.scrollIntoView({behavior:'smooth', block: "start", inline: "start"});
            if(this.state.counterModal.counterType === COUNTER_TYPES.IMPROVE_PRICE) counteredRequest.reCounterInd = true;
            this.reviewCounter(counteredRequest);    
        }
    }

    checkPriceBreakDown = () => {
        const trade = this.props.trade.items.single || {};
        const selectedBids = this.state.selectedBids;

        return  trade.privacyType === "TENDER" && 
                (selectedBids.length === 0 || (this.state.counterModal.counterType === COUNTER_TYPES.IMPROVE_PRICE && selectedBids.filter(bid => ![BID_STATUS.NEW, BID_STATUS.IMPROVED].includes(bid.status)).length === 0))
    }

    render() {
        const request = this.props.trade.items.single || {};
        var isNegotiate = false;
        if (negotiateTradePermission() === 1) {
            isNegotiate = true;
        }
        if ((viewExchangePermission() === 0 && document.location.pathname.indexOf("/exchange/details/") === 0) ||
            (viewTradeRequestPermission() === 0 && document.location.pathname.indexOf("/requests/details/") === 0)) {
            return (
                <div>
                    {this.state.showModal && this.renderModal('PERMISSION_REQUIRED')}
                </div>
            );
        }

        else {
            const isPrivate = request.sellerId && request.buyerId;
            const hasOnGoingNegotiation = (() => {
                let res = false;
                if (this.state.latestNegotiations) {
                    this.state.latestNegotiations.map(bid => {
                        if ((bid.status === BID_STATUS.NEW || bid.status === BID_STATUS.BANK_ONLY_NEW) && (moment(bid.bidValidateDate).isAfter(moment(new Date())))) {
                            res = true;
                        }
                        return false;
                    });
                }

                return res;
            })()
            const privateEdit = (isPrivate && moment(request.validateDate).diff(moment(new Date()), 'minutes') < 0 && !hasOnGoingNegotiation) ||
                (this.props.trade.bids.length !== 0 && this.props.trade.bids[0].status === BID_STATUS.DECLINED)
            const publicEdit = !isPrivate && !hasOnGoingNegotiation;
            // const canEdit = this.isTradeOwner && (privateEdit || publicEdit);
            const canCancel = this.isTradeOwner && (privateEdit || publicEdit) && request.status !== BID_STATUS.DECLINED && request.status !== BID_STATUS.EXPIRED;
            const canComment = ((this.state.latestNegotiations && this.state.latestNegotiations.length) || (request.privacyType === "PUBLIC" && this.isTradeOwner) || (request.privacyType === "MULTI_PRIVATE") ||
                (request.privacyType === "TENDER" &&
                    (this.props.tender.tenderSubscriptions.length !== 0) &&
                    moment(request.validateDate).isAfter() &&
                    request.status !== 'EXPIRED'
                ));
            // RDEV-1987 - Current bid selection is considered declined if the trade is declined or cancelled or if the last bid of the selection has been declined.
            const isDeclined = (request.status === BID_STATUS.DECLINED || request.status === BID_STATUS.CANCELED || (this.state.selectedBids.length !== 0 && this.state.selectedBids[0].status === BID_STATUS.DECLINED))

            const listCounetpartyMessage = this.PublicCommunicationList();
            return (

                <div>
                    <Header />
                    {this.state.showModalTWo && this.renderModal(this.state.modal)}
                    {this.props.trade.actionError &&
                        <ErrorModal onClose={e => this.props.dismissError()}
                            errorTitle={"Validation Error"}
                            errorMessage={this.props.trade.actionError} />}
                    <Preloader loading={this.props.requestLoading || this.props.bidLoading} loadingStyle="overflow-spinner" />

                    <div className="request-dtls">
                        <div className="request-dtls__wrapper">
                            <div className="container-fluid">
                                <div className="row justify-content-between mb-2 mt-2">
                                    <a href="#/" className="trades-dtls__back" onClick={(event) => {
                                        event.preventDefault();
                                        this.props.history.goBack();
                                    }}>Back</a>
                                </div>

                                {/* CARD1 - TRADE INFOS */}
                                <div id="trade-info" className="request-dtls__status-sections sub-header">
                                    <div className="card-body">
                                        <TradeDetail request={request}
                                            heading={this.state.heading}
                                            canCancel={canCancel}
                                            onCancel={this.cancelTrade}
                                            loading={this.props.requestLoading}
                                            user={this.props.account.user}>
                                        </TradeDetail>
                                    </div>
                                </div>

                                {/** CARD2 - SUBSCRIBE / SUBSCRIPTION LIST  **/}
                                {request.privacyType === "TENDER" &&
                                    <div className="request-dtls__status-sections mt-4 sub-header">
                                        <div className="card-body">
                                            <SubscriptionList selected={this.state.selectedCounterparty} onSelect={this.onSubscriptionSelected} />
                                        </div>
                                    </div>}

                                {/** CARD3 - Public Communication  **/}
                                {/*To externalise in a useMemo */}
                                {(request.privacyType === "PUBLIC" && this.isTradeOwner && (listCounetpartyMessage && listCounetpartyMessage.length !== 0)) &&
                                    <div className="request-dtls__status-sections mt-4 sub-header">
                                        <div className="card-body">
                                            <PublicCommunicationList
                                                onSelect={this.onCommunicationSelected}
                                                selected={this.state.selectedCounterparty}
                                                listCounetpartyMessage={listCounetpartyMessage}
                                            />
                                        </div>
                                    </div>}
                                    {(request.privacyType === "MULTI_PRIVATE" && this.isTradeOwner && (this.multiCommunicationList(request.multiPrivateParties).length!==0)) &&
                                    <div className="request-dtls__status-sections mt-4 sub-header">
                                        <div className="card-body">
                                            <MultiPartyCommunication
                                                onSelect={this.onMultiCommunicationSelected}
                                                selected={this.state.selectedCounterparty}
                                                trade={request}
                                                companies={this.props.trade.companies.companies}
                                                counterpartyList={this.multiCommunicationList(request.multiPrivateParties)}
                                            />
                                        </div>
                                    </div>}
                                {/** CARD4 - LATEST NEGOCIATIONS & STATUS  **/}
                                {((["PUBLIC", "PRIVATE","MULTI_PRIVATE"].includes(request.privacyType) ||
                                    (this.state.tenderHasStarted &&
                                        (this.isTradeOwner ||
                                            (this.props.tender.tenderSubscriptions.length !== 0 && this.props.tender.tenderSubscriptions[0].status === "APPROVED")
                                        )
                                    )) &&
                                    (!this.isTradeOwner || (this.state.latestNegotiations && this.state.latestNegotiations.length !== 0))) &&
                                    <div className="request-dtls__status-sections mt-4 sub-header">
                                        <div className="card-body">
                                            <BidList
                                                heading={this.state.heading}
                                                bids={this.state.latestNegotiations}
                                                onSelect={this.onBidSelected}
                                                selected={this.state.selectedCounterparty}
                                                user={this.props.account.user}
                                                compareLink={`${this.props.match.url}/compare`}
                                                onCounter={this.createCounter}
                                                onAccept={this.acceptBid}
                                                onDecline={this.declineBid}
                                                loading={this.props.requestLoading}
                                                onAlternateBankRequest={this.onAlternateBankRequest}
                                                onPublicMessage={this.postPublicMessage}
                                                onAcceptOffer={this.onAcceptOffer}
                                            />
                                        </div>
                                    </div>}

                                <div className="row">
                                    <div className="col-lg-7 mt-4">
                                        <div style={{ scrollMarginTop: '84px' }} ref={this.bidDetailsRef} className="request-dtls__status-sections sub-header">
                                            <div className="card-body">
                                                <BidDetail
                                                    heading={this.state.heading}
                                                    availableActions={this.state.availableActions}
                                                    onCancelCounter={this.cancelCounter}
                                                    onEditCounter={this.editCounter}
                                                    onAccept={() => this.acceptBid(this.state.selectedCounterparty)}
                                                    onDecline={() => this.declineBid(this.state.selectedCounterparty)}
                                                    onSubmitCounter={this.submitCounter}
                                                    counterLoaded={this.state.counterLoaded}
                                                    bids={this.state.selectedBids.filter(bid => bid.systemBid === false)}
                                                    user={this.props.account.user.companyId}
                                                    isDeclined={isDeclined}
                                                    onVerify={this.onVerify}
                                                />
                                                {(request.images && request.images.length !== 0) &&
                                                    <React.Fragment>
                                                        <h3 className="request-dtls__card-heading">Images</h3>
                                                        <div className="d-flex overflow-auto">
                                                            <div className="d-flex">
                                                                {request.images.map((image, index) => (
                                                                    <div className="preview-image-wrapper" key={`preview-image-${index}`} style={{ minWidth: '150px', minHeight: '100px' }}>
                                                                        <TradeImagePreview key={image.hash} image={image} />
                                                                        <div className="text-center">{image.name}</div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-5 mt-4">
                                        <div className="request-dtls__comments-wrapper">
                                            <div className="request-dtls__comment-writing">
                                                {canComment ?
                                                    <div className="request-dtls__card-heading mb-2">Private Communication with {this.state.selectedCounterPartyName}</div>
                                                    :
                                                    <div className="request-dtls__card-heading mb-2">
                                                        {request.privacyType === "TENDER" ?
                                                            `Communication is possible once the subscription request for the tender has been ${(this.isTradeOwner) ? 'received' : 'sent'}`
                                                            :
                                                            'Private Communication (only available in negotiation)'}
                                                    </div>
                                                }
                                                <React.Fragment>
                                                    <textarea
                                                        disabled=""
                                                        className="request-dtls__comment-area"
                                                        name="comment"
                                                        id="comment"
                                                        placeholder={canComment ? "Add your message..." : "Messages only possible during negotiations"}
                                                        ref={this.textArea}
                                                    />
                                                    {isNegotiate ? (
                                                        <button
                                                            className="request-dtls__comment-send mt-2"
                                                            disabled={this.props.loadingTradeMessages || isDeclined || !canComment}
                                                            onClick={e => this.postRootMessage(e, this.textArea.current.value)}>
                                                            Send
                                                        </button>
                                                    ) : (
                                                            <button
                                                                className="request-dtls__comment-send mt-2"
                                                                onClick={e => this.openModal('PERMISSION_REQUIREDTWO', this.state.data)}>
                                                                Send
                                                            </button>
                                                        )
                                                    }
                                                </React.Fragment>
                                            </div>
                                            <Preloader loadingStyle="swirl" loading={this.props.loadingTradeMessages} />
                                            {this.state.messagesPerPage.map((message, i) => {
                                                return (
                                                    <Message
                                                        key={i}
                                                        userId={this.props.account.user.id}
                                                        message={message}
                                                        onSubmit={(e, messageID, fromCompanyID, toCompanyID, messages) => this.postMessage(e, messageID, fromCompanyID, toCompanyID, messages)}
                                                        onClick={messageID => this.reply(messageID)}
                                                        onChange={value => this.getReply(value)}
                                                        showReplyButton={this.showReplyButton}
                                                        loading={this.props.loadingTradeMessages}
                                                    />
                                                );
                                            })}
                                            {this.props.messages.length > 0 ? <PaginationCustom
                                                fetchFunction={(index, itemsPerPage) => {
                                                    if (this.state.selectedCounterparty) {
                                                        let messages = this.getSelectedMessages(this.state.selectedCounterparty);
                                                        this.setState({
                                                            messagesPerPage: this.getMessagesPerPage(messages, index, itemsPerPage),
                                                            currentMessageIndex: index,
                                                            messageNoPerPage: itemsPerPage
                                                        });
                                                    }
                                                }}
                                                totalItemsCount={this.getSelectedMessages(this.state.selectedCounterparty).length}
                                                itemsPerPage={this.state.messageNoPerPage}
                                                pagesAtOnce={10} /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.counterModal.show && (
                            <CounterProposal
                                onReview={(counteredRequest) => this.onCounterReview(counteredRequest)}
                                onClose={this.closeCounterModal}
                                trade={request}
                                pov={this.state.pov}
                                type={this.state.counterModal.type}
                                bid={this.state.counterModal.bid}
                                previousBid={this.state.selectedBids[0]}
                                inspectionCompanies={this.props.trade.inspections}
                                doPriceBreakdown={this.checkPriceBreakDown()}
                            />
                        )}
                    </div>
                    <Footer />
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        tender: state.tender,
        trade: state.trade,
        account: state.account,
        messages: sorMessageByDate(state.trade.messages),
        loadingInitiate: state.loading.loadingInitiate,
        requestLoading: state.loading.requestLoading,
        bidLoading: state.loading.bidLoading,
        loadingTradeMessages: state.loading.loadingTradeMessages,
        usermanagment: state.usermanagment,
        loadingBankDetails: state.loading.loadingBankDetails
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadRequestDetails,
            accept: SmartTrade.Accept,
            cancel: SmartTrade.Cancel,
            reject: SmartTrade.Rejcet,
            counter: CounterTrade,
            dismissError: dismissError,
            acceptBid: AcceptTradeBid,
            declineBid: DeclineTradeBid,
            loadBids: LoadTradeBids,
            getTenderSubscriptions,
            preloadInspectionCompanies,
            AutoupdateTriggered,
            getMessage,
            ReplyMessage,
            postMessage,
            LoadingInitiate,
            ClearSingleTrade,
            searchCompanies,
            sendEmail,
            getShippingCompanyList,
            getInpectionStatus,
            getshippingStatus,
            counterBankDetails,
            navigate: path => push(path),
            navigateDecline: path => push('/requests' + path),
            navigateDeal: path => push('/trades' + path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetails);
