import React from 'react';
import moment from 'moment-timezone';
import BagmarkingPreview from './BagmarkingPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons';
import { DATEFORMATHOURS } from '../../../../../services/service.values';
import { approveBagmarkingPermission } from '../../../../../services/service.permission';
import { STATUS_NEW, STATUS_APPROVED_BY_SELLER, STATUS_APPROVED_BY_BUYER, STATUS_REJECTED_BY_BUYER, STATUS_REJECTED_BY_SELLER } from '../../services/documents.service';

const getBagmarkingStatus = (status) => {
    switch (status) {
        case STATUS_NEW:
            return "New"
        case STATUS_APPROVED_BY_BUYER:
            return "Approved by Buyer"
        case STATUS_APPROVED_BY_SELLER:
            return "Approved by Seller"
        case STATUS_REJECTED_BY_BUYER:
            return "Rejected by Buyer"
        case STATUS_REJECTED_BY_SELLER:
            return "Rejected by Seller"
        default:
            return ""
    }
}

const getBagmarkingStatusStyle = (status) => {
    switch (status) {
        case STATUS_NEW:
            return "badge badge-new"
        case STATUS_APPROVED_BY_BUYER:
        case STATUS_APPROVED_BY_SELLER:
            return "badge badge-success"
        case STATUS_REJECTED_BY_BUYER:
        case STATUS_REJECTED_BY_SELLER:
            return "badge badge-danger"
        default:
            return ""
    }
}

export default function BagmarkingsItem(props) {
    let bagmarking = props.bagmarking;
    let image = {
        source: bagmarking.createdBy.companyID,
        url: bagmarking.url,
        type: bagmarking.fileType
    }
    return (
        <tr>
            <td><span>{bagmarking.name}</span></td>
            <td>
                <span>{bagmarking.createdBy.userName} <div className="fw-600">({bagmarking.createdBy.companyName})</div></span>
            </td>
            <td>
                <span>{moment(bagmarking.createdAt).format(DATEFORMATHOURS)}</span>
            </td>
            <td>
                <span className={getBagmarkingStatusStyle(bagmarking.status)}>{getBagmarkingStatus(bagmarking.status)}</span>
            </td>
            <td>
                {(bagmarking.status === STATUS_APPROVED_BY_SELLER || bagmarking.status === STATUS_APPROVED_BY_BUYER) && (
                    <span>{bagmarking.approvedBy.userName} <div className="fw-600">({bagmarking.approvedBy.companyName})</div></span>
                )}
                {(bagmarking.status === STATUS_REJECTED_BY_SELLER || bagmarking.status === STATUS_REJECTED_BY_BUYER) && (
                    <span>{bagmarking.rejectedBy.userName} <div className="fw-600">({bagmarking.rejectedBy.companyName})</div></span>
                )}
            </td>
            <td>
                {(bagmarking.status === STATUS_APPROVED_BY_SELLER || bagmarking.status === STATUS_APPROVED_BY_BUYER) && (
                    <span>{moment(bagmarking.approvedAt).format(DATEFORMATHOURS)}</span>
                )}
                {(bagmarking.status === STATUS_REJECTED_BY_SELLER || bagmarking.status === STATUS_REJECTED_BY_BUYER) && (
                    <span>{moment(bagmarking.rejectedAt).format(DATEFORMATHOURS)}</span>
                )}
            </td>
            <td>
                {(approveBagmarkingPermission() && bagmarking.status === STATUS_NEW && props.canEdit(bagmarking.createdBy.companyID)) ? (
                    <>
                        <button type="button" className="bg-btn btn-icon btn-circle-icon btn-accept" data-toggle="tooltip"
                            data-placement="top" title="Approve" onClick={e => props.approveBagmarking(e, props.brandID, bagmarking.ID)}>
                            {props.acceptProgress && props.bagmarkingID === bagmarking.ID ? (<FontAwesomeIcon icon={faSpinner} spin={true} />) : (<FontAwesomeIcon icon={faCheck} />)}
                        </button>

                        <button type="button" className="bg-btn  btn-icon btn-circle-icon btn-delete" data-toggle="tooltip"
                            data-placement="top" title="Reject" onClick={e => props.rejectBagmarking(e, props.brandID, bagmarking.ID)} >
                            {props.rejectProgress && props.bagmarkingID === bagmarking.ID ? (<FontAwesomeIcon icon={faSpinner} spin={true} />) : (<FontAwesomeIcon icon={faTimes} />)}
                        </button>
                    </>
                ) : null}
                <button type="button" className="bg-btn btn-icon btn-circle-icon btn-info" data-toggle="tooltip"
                    data-placement="top" title="Preview">
                    <BagmarkingPreview image={image}></BagmarkingPreview>
                </button>
                <a href={bagmarking.fileUrl} className="bg-btn btn-icon btn-circle-icon btn-download" data-toggle="tooltip"
                    data-placement="top" title="Download"> <FontAwesomeIcon icon={faDownload} /></a>
                {/* <button type="button" className="bg-btn btn-icon btn-circle-icon btn-comment" data-toggle="tooltip"
                    data-placement="top" title="Comment"> <FontAwesomeIcon icon={faComments} /></button> */}
            </td>
        </tr>

    )
}