import React from 'react';
import NumberFormat from 'react-number-format';

const defaultStyle = {
    labelClassName :"label",
    wrapperClassName : "form-date",
    inputClassName : "input",
    isValidClassName: "",
    isInvalidClassName: "input_error"
};  

const FormInputNumberFormat = ({
        name,
        value,
        validation,
        onChange,
        disabled,
        customStyle,
        ...rest
    }) => {
    
        const style = { ...defaultStyle, ...customStyle};
        const isInvalid = validation && validation.hasOwnProperty(name);
        const inputValue = value.value;
        return (
            <div className={style.wrapperClassName}>
                {value.label &&
                <label htmlFor={name} className={style.labelClassName}>
                    {value.label}
                    {value.required && <span className="req-field">*</span>}
                </label>}
                <NumberFormat value={inputValue} 
                    displayType={'input'} 
                    className={`${style.inputClassName} ${isInvalid ? style.isInvalidClassName :  value.touched || value.validated ? style.isValidClassName : ''}`}
                    id={name}
                    name={name}
                    onValueChange={values => onChange(values.value)}
                    required={!!value.required}
                    disabled={disabled}
                    {...rest}/>
            </div>)
        };

export default FormInputNumberFormat;
