import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import AdminManagementInfo from './AdminManagementInfo';
import Footer from '../components/footer/Footer';
import Preloader from '../components/preloader/Preloader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class AdminManagement extends Component {
    render() {
        return (
            <React.Fragment>

                <Header />
                <div className="container-fluid">
                    <div className="admin-magmt">
                       <h3>Admin Management</h3>
                        <nav className="mb-2">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/" id="drop-menu">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    {/* <Link to="/adminManagement/user-groups" id="drop-menu"> */}
                                        Admin Management
                                    {/* </Link> */}
                                </li>
                            </ol>
                        </nav>
                       
                        <Preloader loadingStyle="overflow-spinner" loading={this.props.loading}>
                            <AdminManagementInfo user={this.props.user} />
                        </Preloader>
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.account.user,
        loading: state.loading.groupActionLoading
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(AdminManagement);