import React from 'react';
import moment from 'moment/moment';
import { DATEFORMAT, DATEFORMATHOURS } from '../../../services/service.values';
import FormDateField from './FormDateField';

const FormDateFieldFromTo = ({
    nameStart,
    nameEnd,
    itemStart,
    itemEnd,
    onSelect,
    validation,
    popperPlacementStart = 'top-start',
    popperPlacementEnd = 'top-end',
    required = false,
    minDate = moment(),
    ...rest
}) => {

    /* React Date Picker v2+ uses js Date Objects */
    const momentToDate = (obj) => {
        if (moment.isMoment(obj)) {
            return obj.toDate();
        }
        return obj
    }

    /* React Date Picker 3.3.0 does not accept format DD -> dd */
    const reacDatePickerFormat = {
        [DATEFORMATHOURS]: "dd MMM yyyy HH:mm",
        [DATEFORMAT]: "dd MMM yyyy",
        default: "dd MMM yyyy"
    }


    return (
        <div className="form-row">
            <div className="col-6">
                <FormDateField
                    dateFormat={reacDatePickerFormat[DATEFORMAT]}
                    required={required}
                    validation={validation}
                    name={nameStart}
                    minDate={momentToDate(minDate)}
                    maxDate={itemEnd.value ? momentToDate(itemEnd.value) : null}
                    onSelect={(name, date) => onSelect(name, moment(date))}
                    label={true}
                    selected={momentToDate(itemStart.value)}
                    placeholderText={'Select a date from'}
                    popperPlacement={popperPlacementStart}
                    item={itemStart}
                    {...rest} />

            </div>
            <div className="col-6">
                <FormDateField
                    dateFormat={reacDatePickerFormat[DATEFORMAT]}
                    required={required}
                    validation={validation}
                    name={nameEnd}
                    minDate={itemStart.value ? momentToDate(itemStart.value) : momentToDate(minDate)}
                    onSelect={(name, date) => onSelect(name, moment(date))}
                    label={true}
                    selected={momentToDate(itemEnd.value)}
                    placeholderText={'Select a date to'}
                    popperPlacement={popperPlacementEnd}
                    item={itemEnd}
                    popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px'
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                          boundariesElement: 'viewport'
                        }
                      }}
                    {...rest} />
            </div>
        </div>
    );
};

export default FormDateFieldFromTo;
