import React, { useState } from "react";
import {RicexCustomFileLabel} from  './FormFileUpload.module.scss';
import { PlatformAdminApi } from '../../../services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCheck } from '@fortawesome/free-solid-svg-icons';

const FormFileUpload = ({value, name, validation, onChange, onError, disabled}) => {

    const placeholder = 'Choose file';
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState();

    const uploadFile = (formData) => {
        setUploadSuccess(false)
        setUploading(true);
        PlatformAdminApi.kycDocumentUpload(formData)
        .then(res => {
            setUploading(false);
            setUploadSuccess(true);
            onChange(res.data.document);                
        })
        .catch((error) => {
            if (process.env.NODE_ENV === 'development') {
                console.error(error);
            }
            setUploading(false);
            setUploadSuccess(true);
        });
    }

    const handleOnChange = e => {
        const newSelectedFile = e.target.files[0];
        if(newSelectedFile) {
            if(validateFileType(newSelectedFile)) {
                const formData = new FormData();
                formData.append("upload", newSelectedFile);
                uploadFile(formData)
            }
        }
    }



 
    const validateFileType = (file) => {
        const fileType = file.type;
        let fileSize = file.size;
        fileSize = fileSize / (1024 * 1024);
        let result = false;
        if (fileType === 'application/pdf' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/tiff') {
            result = true;
        } else {
            onError("Please upload file only with *.pdf, *.png, *.jpeg, *.tif format");
            window.scrollTo(0,0);
            return result;
        }

        if (fileSize > 15.36) {
            onError("File size can't exceed 15 MB");
            window.scrollTo(0,0);
            result = false;
        }
        return result;
    }


    return (
        <div className="file-upload-field form-input">

            <label className="label">
                {value ? value.label : ''}
                <span className="req-field">{value && value.required ? '*' : ''}</span>
            </label>
            <div className='custom-file'>
                <input type="file" 
                    className={`custom-file-input ${(validation && validation.hasOwnProperty(name)) ? ' is-invalid ' : ''}`}
                    id="customFile"
                    disabled={disabled}
                    name={name}
                    onChange={handleOnChange}/>
                <label className={`custom-file-label ${RicexCustomFileLabel}`} htmlFor="customFile">
                    {(value && value.value)? value.value.name:placeholder}
                    {uploading && 
                    <FontAwesomeIcon className={'float-right mt-1'} icon={faCircleNotch} spin={true} color={'#0065ab'} />}
                    {uploadSuccess &&
                    <FontAwesomeIcon className={'float-right mt-1'} icon={faCheck} color={'green'} />}

                </label>
                
            </div>
            

                
        </div>
    );
    
}

export default FormFileUpload;
