import React from 'react';

import TradeDocumentPreview from './TradeDocumentPreview';
import DownloadDocument from './DownloadDocument';

import { faFileDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TradeDocPreviewModal = function ({ doc, onClose }) {


    return <div className="modal__container confirm-modal">
        <div className="modal__wrapper px-0 py-0 overflow-hidden">
            <div className="modal-header">
                <h5 className="modal-title w-100">Tender Terms & Conditions</h5>
                <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                    <span aria-hidden="true" >&times;</span>
                </button>
            </div>
            <div className="modal-body contract-modal" >
                <TradeDocumentPreview doc={doc} />
            </div>
            <div className="modal-footer">
                <button className={`btn btn-confirm-modal btn--grey mx-1`} type="button" onClick={onClose} >
                    <FontAwesomeIcon icon={faTimes} />&nbsp;Back
            </button>
                <DownloadDocument className={`btn btn-confirm-modal btn--blue mx-1`}
                    loadingStyle="dots"

                    doc={doc} label={<><FontAwesomeIcon icon={faFileDownload} />&nbsp;Download</>} />
            </div>
        </div>
    </div>

}

export default TradeDocPreviewModal;