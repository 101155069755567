import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getGroupUserList, deleteGroupUser } from '../../modules/module.adminManagement';
import {push} from "connected-react-router";

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './adminManagement-style.scss';

import Preloader from '../components/preloader/Preloader';
import '../components/form/FormInputField.scss';
import AddUserList from './AddUserList';
import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';

class GroupsUserList extends Component {
    state = {
        showModal: false,
        skip: 0,
        take: 25,
        sorting: {
            createdAt: 'desc'
        },
        modalData: {
            userID: null,
            email: null,
            userName: null

        }
    };

    handleChange = e => {
        const value = e.target.value;
        this.setState({
            [e.target.name]: value
        });
    };


    UNSAFE_componentWillMount = () => {
        this.fetchFunction();
    };

    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, modalData: data });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            input: '',
            modalData: {},
            signatureModal: {}
        });
    };

    fetchFunction = () => {

        let id = this.props.id;
        this.props.getGroupUserList(id);

        this.setState({});

    };

    getItems = () => {
        let userList = this.props.usermanagment.groupUserList;
        return userList;

    };

    renderModal = (whatModal) => {
        switch (whatModal) {

            case 'ADD_USER':

                return (

                    <div className="modal__container">

                        <div className="modal__wrapper px-0 py-0">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Add Users In Group</h5></div>

                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <AddUserList id={this.props.id} />

                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel
                            </button>
                                </div>
                            </div>
                        </div>

                    </div>
                );
            case 'DELETE_GROUPS':
                const data =
                {
                    userID: this.state.modalData.userID
                }

                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                                this.props.deleteGroupUser(this.props.id, data.userID);

                            }}>
                            <div className="modal-content">

                                <div className="modal-header">
                                <div className="modal-header-center"><h5 className="modal-title">Remove user from a group</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text">Are you sure you want to delete this user?
                              <br />
                               Press back to return to previous screen or press confirm to continue.
                              </p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Back
       </button>
                                    <button type="submit" className="btn-popup btn-submit">
                                        <FontAwesomeIcon icon={faCheck} /> Confirm
       </button>
                                </div>
                            </div>
                        </form>

                    </div>


                );
            default:
                break;
        }
    };



    render() {
        const addModeldata = {
            id: this.state.modalData.id,
            email: null,
            userName: null
        };
        return (
            <React.Fragment>

                <section className="rbac-body">
                    {this.state.showModal && this.renderModal(this.state.modal)}
                    <div className="permission_header">
                        <div className="form-input">
                            <div className="row">
                                <div className="col-md-12 permission__wrapper-left">
                                    <div className="form-input">
                                        <label className="label">Manage users for Group Name </label>
                                        <input className="input form-control" name="id" disabled value={this.props.name} />
                                        <div className="ml-md-3"><button type="button" className="trades-dtls__verify-docs whsp-nowrap mt-xxs-10 btn-submit" onClick={() => this.openModal('ADD_USER', addModeldata)}>Add User</button>
                                        </div> </div></div>
                            </div>
                        </div>
                    </div>
                    <Preloader loadingStyle="overflow-spinner" loading={this.props.loading}>
                        <MaterialTable

                            columns={[
                                { title: 'User Name', field: 'userName', },
                                { title: 'Email', field: 'email' },
                                {
                                    title: 'Action',
                                    sorting: false,
                                    render: rowData => <div className="action-btn">
                                        <Tooltip title="Remove User From This Group " placement="top-start" arrow >
                                            <span className="btn-icon btn-circle-icon btn-danger btn-icon-res" onClick={() => this.openModal('DELETE_GROUPS', rowData)}><FontAwesomeIcon icon={faTrash} /></span>
                                        </Tooltip>
                                    </div>
                                }
                            ]}
                            data={this.getItems()}
                            title="Manage Users"
                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                SortArrow: ArrowUpward,
                                Add: Add,
                                Edit: Edit,
                                DeleteOutline: DeleteOutline,
                                ResetSearch: Clear,
                                SupervisorAccountIcon: SupervisorAccountIcon,
                            }}
                            options={{
                                rowStyle: (rowData, index) => {
                                    const backgroundColor = (index%2===0)?'#00ff301c':'#FBFBFB';
                                    return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                                },
                                pageSize: 10,
                                thirdSortClick: false
                            }}

                        />
                    </Preloader>
                </section>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {

        usermanagment: state.usermanagment,
        loading: state.loading.groupLoading
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            deleteGroupUser,
            getGroupUserList,

            navigate: path => push('/group-userlist' + path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GroupsUserList);
