import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProviderList, addNewProvider } from '../../modules/module.adminManagement';
import {push} from "connected-react-router";

import './adminManagement-style.scss';
import '../admin/requests/create/create-trade-request.scss';
import '../components/form/FormInputField.scss';

import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import SaveIcon from '@material-ui/icons/Save';
import {createMuiTheme} from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CustomMaterialPreloader from '../components/preloader/CustomMaterialPreloader';


class AddProvider extends Component {
  constructor(props) {
    super(props);
    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: '#b6bdf0',
        },
        secondary: {
          main: '#5465dd',
        },
      },
    });
  }

  UNSAFE_componentWillMount = () => {
    this.fetchFunction();
  };

  fetchFunction = () => {
    this.props.getProviderList();
  };

  getItems = () => {
    let providers = this.props.usermanagment.providerList;
    let companyProviders = this.props.companyProviders;
    let providersList = providers.filter(u => !(companyProviders.some(eu => eu.companyID === u.ID)));
    return providersList;
  };

  render() {
    let companyID = this.props.companyID;
    return (
      <React.Fragment>
        <section className="rbac-body">
          <MuiThemeProvider theme={this.theme}>
            <MaterialTable
              columns={[
                { title: 'Company Name', field: 'name', },
                { title: 'Company Type', field: 'companyType' }
              ]}
              isLoading={this.props.loading}
              components={{
                OverlayLoading: CustomMaterialPreloader
              }}
              data={this.getItems()}
              title=" "
              actions={[
                {
                  tooltip: 'Add Provider',
                  icon: SaveIcon,
                  onClick: (evt, rowData) => {
                    let providerData = {
                      companyID: rowData.ID,
                      companyName: rowData.name,
                      companyType: rowData.companyType
                    }
                    let postData = {
                      provider: providerData
                    }
                    this.props.addNewProvider(companyID, postData)
                  }
                }
              ]}

              localization={{
                header: {
                  actions: 'Share Company Details'
                }
              }}

              icons={{
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                Search: Search,
                SortArrow: ArrowUpward,
                ResetSearch: Clear,
                SaveIcon: SaveIcon
              }}
              options={{
                actionsColumnIndex: -1,
                pageSize: 5,
                rowStyle: (rowData, index) => {
                  const backgroundColor = (index%2===0)?'#00ff301c':'#FBFBFB';
                  return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                },
                thirdSortClick: false
              }}
            />
          </MuiThemeProvider>
        </section>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    usermanagment: state.usermanagment,
    loading: state.loading.loadingProviderList
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProviderList,
      addNewProvider,
      navigate: path => push('/addProvider' + path)
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(AddProvider);
