import {
    ContractApi
  } from '../services/service.api';
  import {LoadingContractTerm,LoadingRequestData} from './module.loading';
  import {loadRequestDetails} from './module.trade';
  import { TradeAPIErrors } from "../services";
  export const SUBMIT_TERM = 'contract/SUBMIT_TERM';
  export const GET_TERMS = 'contract/GET_TERMS';
  export const DISMISS_ACTION_ERROR = 'contract/DISMISS_ACTION_ERROR';
  export const ACTION_ERROR = 'contract/ACTION_ERROR';

  
  const initialState = {
    terms: [],
  };
  
  
  export default (state = initialState, action= {}) => {
    switch (action.type) {
  
        case GET_TERMS:
            return {
                ...state,
                terms: action.payload
            };

         case DISMISS_ACTION_ERROR:
            return {
                 ...state,
                 actionError: null
            }

         case ACTION_ERROR:
             return {
                 ...state,
                 actionError: action.error
            }   
        
      default:
        return state;
    }
  };

  export const dismissError = () => {
    return {
        type: DISMISS_ACTION_ERROR,
    }
}

export const actionError = (error) => {
    return {
        type: ACTION_ERROR,
        error: error
    }
}



  export const LoadContractTerms = tradeId => {
    return dispatch => {
        LoadingContractTerm(dispatch, true);
        ContractApi.getContractTerms(tradeId)
            .then(response => {
                dispatch({
                    type: GET_TERMS,
                    payload: response.data.termsResult || []
                });
                LoadingContractTerm(dispatch, false);
            })
            .catch(() => {
                LoadingContractTerm(dispatch, false);
            });
    }
};


export const SubmitContractTerm = (tradeId, counterpartyId, params, callback) => {
    return dispatch => {
        LoadingContractTerm(dispatch, true);
        ContractApi.postContractRequest(tradeId, counterpartyId, params)
            .then((response) => {
                LoadingContractTerm(dispatch, false);
                switch (response.status) {
                    case 200:
                        if (response.data.success) {
                            LoadContractTerms(tradeId)(dispatch);
                            dispatch(dismissError());
                            if (callback) { callback(null, response) }
                        } else {
                            dispatch(actionError(TradeAPIErrors[response.data.errorCode] || TradeAPIErrors.DEFAULT_ERROR));
                        }
                        break;
                    default:
                        dispatch(actionError(TradeAPIErrors.DEFAULT_ERROR));
                        break;
                }

            })
            .catch((error) => {
                LoadingContractTerm(dispatch, false);
                dispatch(actionError(TradeAPIErrors.HTTP_500));
                if (callback) {
                    callback(error, null)
                }

            });
    }
};

export const AcceptContractTerm = (tradeId, counterpartyId, callback) => {
    return dispatch => {
        LoadingRequestData(dispatch, true);
        ContractApi.acceptContract(tradeId, counterpartyId)
            .then((response) => {
                LoadingRequestData(dispatch, false);
                switch (response.status) {
                    case 200:
                        if (response.data.success) {
                            LoadContractTerms(tradeId)(dispatch);
                            loadRequestDetails(tradeId)(dispatch);
                            if (callback) {
                                callback({...response});
                            }
                        } else {
                            dispatch(actionError(TradeAPIErrors[response.data.errorCode] || TradeAPIErrors.DEFAULT_ERROR));
                            LoadingRequestData(dispatch, false);
                            if (callback) {
                                callback({...response});
                            }
                        }
                        break;
                    default:
                        dispatch(actionError(TradeAPIErrors.DEFAULT_ERROR));
                        LoadingRequestData(dispatch, false);
                        break;
                }
            })
            .catch(() => {
                dispatch(actionError(TradeAPIErrors.HTTP_500));
                LoadingRequestData(dispatch, false);
            });
    }
};

export const DeclineContractTerm = (tradeId, counterpartyId, params, callback) => {
    return dispatch => {
        LoadingContractTerm(dispatch, true);
        ContractApi.declineContract(tradeId, counterpartyId, params)
            .then((response) => {
                LoadingContractTerm(dispatch, false);
                switch (response.status) {
                    case 200:
                        if (response.data.success) {
                            LoadContractTerms(tradeId)(dispatch);
                            dispatch(dismissError());
                            if (callback) { callback(null, response) }
                        } else {
                            dispatch(actionError(TradeAPIErrors[response.data.errorCode] || TradeAPIErrors.DEFAULT_ERROR));
                        }
                        break;
                    default:
                        dispatch(actionError(TradeAPIErrors.DEFAULT_ERROR));
                        break;
                }
            })
            .catch(() => {
                dispatch(actionError(TradeAPIErrors.HTTP_500));
                LoadingContractTerm(dispatch, false);
            });
    }
};


  
  
  
  
  