import React, { useState, useEffect, useMemo}  from 'react'
import { getChildVesselNomination, GetVesselNomination, getChildShipments, GetShipments, fetchChildTrade } from '../../../../../modules/module.trade';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import NominatedVesselInfo from '../NominatedVesselInfo';
import VesselInfoTab from './VesselInfoTab';
import Preloader from '../../../../components/preloader/Preloader';

const infoType = {
    DEFAULT: "DEFAULT",
    MULTIPLE_SHIPMENTS_PARENT: "MULTIPLE_SHIPMENTS_PARENT",
    MULTIPLE_SHIPMENTS_CHILD: "MULTIPLE_SHIPMENTS_CHILD",
}

/**
 * @author Jean-Marc Cheynier <jean-marc.cheynier@fujitsu.com>
 * @version RDEV-2063
 * Vessel Info Tab component wrapper
 * @param  {string} parentId - The ID of the parent trade
 * @returns {Component} VesselInfoTabWrapper
 */
const VesselInfoTabWrapper = ({ trade, loadingNomination, tradeMap, getChildVesselNomination, getVesselNomination, getShipments,  getChildShipments, pointOfView }) => {
    
    const shipmentInfoType = useMemo(() => {
        if (trade.partialShipmentFlag && trade.parentTradeRequestID) {
            return infoType.MULTIPLE_SHIPMENTS_CHILD;
        }

        if (trade.partialShipmentFlag) {
            return infoType.MULTIPLE_SHIPMENTS_PARENT;
        }
        return infoType.DEFAULT;
    }, [trade])
    
    return (
        <div className="trades-dtls__contract">
            <Preloader loadingStyle="swirl" loading={loadingNomination}>
            {   shipmentInfoType === infoType.MULTIPLE_SHIPMENTS_PARENT &&
                <VesselInfoTab
                    trade={JSON.parse(JSON.stringify(trade))}
                    pointOfView={pointOfView}
                    parentId={trade.id} />
            }
            {   shipmentInfoType === infoType.MULTIPLE_SHIPMENTS_CHILD &&
                < VesselInfoTab
                    trade={JSON.parse(JSON.stringify(trade))}
                    pointOfView={pointOfView}
                    parentId={trade.parentTradeRequestID} />
            }
            {   (shipmentInfoType === infoType.DEFAULT) &&
                <NominatedVesselInfo
                    tradeId={trade.id}
                    inspectionName={trade.inspectionName}
                    whoItIs={pointOfView}
                />
            }
            </Preloader>
        </div>)
}
    
const mapStateToProps = state => {
    return {
        tradeMap: state.trade.items.tradeMap,
        trade: state.trade.items.single,
        triggerNominationUpdate: state.trade.triggerNominationUpdate,
        loadingNomination: state.loading.loadingNomination
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getChildVesselNomination: getChildVesselNomination,
            getVesselNomination: GetVesselNomination,
            getChildShipments: getChildShipments,
            getShipments: GetShipments
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(VesselInfoTabWrapper);