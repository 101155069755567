import React from 'react';
import './FormRadioField.scss';

const defaultStyle = {
  labelClassName: "label",
  wrapperRadioClassName: "form-radio notranslate",
  inputRadioClassName: "d-none",
  radioColLayout: {left : "", right : ""},
  radioDisplayClassName: "custom-control-inline",
  radioColClass: ""
}

export default ({
  name,
  value,
  items,
  onChange,
  required= false,
  disabled = false,
  customStyle
}) => {
  customStyle = { ...defaultStyle, ...customStyle };
  if (!value || !items) {
    return false;
  } else {
    return (
      <div className={`${customStyle.wrapperRadioClassName} row`}>
        <div className={customStyle.radioColLayout.left}>
          <label className={`${customStyle.labelClassName} `}>
            {value.label}
            {value.required && <span className="req-field">&nbsp;*</span>}
          </label>
        </div>
        <div className={customStyle.radioColLayout.right}>
          <div className={`radio ${customStyle.radioColClass}`}>
            {Object.keys(items).map((k, i) => {
              return (
                <React.Fragment key={i}>
                  <div className={`custom-control custom-radio ${customStyle.radioDisplayClassName} mr-0`}>
                    <input
                      type="radio"
                      className={customStyle.inputRadioClassName}
                      name={name}
                      id={k}
                      value={k}
                      required={required}
                      disabled={disabled}
                      onChange={onChange}
                      checked={value.value && items[k.toUpperCase()] === items[value.value.toUpperCase()]}
                    />
                    <label htmlFor={k}>
                      {items[k]}
                    </label>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
};