/* eslint-disable react/prop-types */
import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import RxNumberFormat from '../../../../components/Formatter/RxNumberFormat'
import { DATEFORMATHOURS } from '../../../../../services/service.values'
import moment from 'moment-timezone';
import { STEPS, TRADE_STATUS } from '../../../../../services/service.values';


const getMin = (quantity, tolerance) =>
  (parseFloat(quantity) * (1 - parseFloat(tolerance) / 100)).toFixed(2)

const getMax = (quantity, tolerance) =>
  (parseFloat(quantity) * (1 + parseFloat(tolerance) / 100)).toFixed(2)

// Calculate the shippingHistory(min, max and outstanding balance)
const _shippingHistory = (shipmentList) => {
  const res = {
    shipments: [],
    totalMin: 0,
    totalMax: 0,
  }
  for (let shipment of shipmentList) {
    const min = shipment.quantity
    const max = shipment.quantity

    if (min && max && shipment.status !== 'REJECTED' && shipment.status !== 'UPDATING') {
      res.totalMin += min
      res.totalMax += max
    }

    const row = {
      ...shipment,
      min,
      max,
    }
    res.shipments.push(row)
  }
  return res
}

// Calculate the balance
const _balance = ({ measure, tolerance, shippingHistory, newShipment,status }) => {
  const balance = {
    min: getMin(measure, tolerance),
    max: getMax(measure, tolerance),
  }
  if (shippingHistory) {
    balance.min -= shippingHistory.totalMin
    balance.max -= shippingHistory.totalMax
  }

  if (newShipment) {
    balance.min -= newShipment.quantity
    balance.max -= newShipment.quantity
  }

  // Balance cannot be negative
  if (balance.max < 0 || STEPS.indexOf(status) >= STEPS.indexOf(TRADE_STATUS.CONTRACT_FULFILL_ACCEPT)) {
    balance.max = 0
  }

  if (balance.min < 0 || STEPS.indexOf(status) >= STEPS.indexOf(TRADE_STATUS.CONTRACT_FULFILL_ACCEPT)) {
    balance.min = 0
  }

  return balance;
}

// Calculate the shippingHistory(min, max and outstanding balance)
const prepareShippingHistoryRow = (
  {
    type,
    tolerance,
    name,
    billNumber,
    shippedOnBoard,
    min,
    max,
    ref,
    quantity,
    status,
  },
  index
) => {
  const rejected = status === 'REJECTED'
  const updating = status === 'UPDATING'

  const nominated = type === 'NOMINATED'
  const shipped = type === 'SHIPPED'

  return (
    <tr
      key={`shipment-${index}`}
      className={rejected ? 'table-danger' : updating ? 'table-warning' : ''}>
      <td>
        <div>
          <small>{`REF : ${ref}`}</small>
        </div>
        <div>
          {!shipped
            ? nominated && rejected
              ? 'Nomination Rejected'
              : updating
              ? 'Updating'
              : 'Nominated'
            : rejected
            ? 'Shipment Rejected'
            : 'Shipped'}
        </div>
      </td>
      <td>{tolerance ? `${tolerance}%` : '-'}</td>
      <td>{name}</td>
      <td>{billNumber ? billNumber : '-'}</td>
      <td>
        {shippedOnBoard ? moment(shippedOnBoard).format(DATEFORMATHOURS) : '-'}
      </td>
      <td>
        <RxNumberFormat value={quantity} />
      </td>
      <td
        className="text-danger"
        style={{
          textDecoration: rejected || updating ? 'line-through' : '',
        }}>
        <RxNumberFormat value={min} />
      </td>
      <td
        className="text-success"
        style={{
          textDecoration: rejected || updating ? 'line-through' : '',
        }}>
        <RxNumberFormat value={max} />
      </td>
    </tr>
  )
}

const Balance = ({ partialShipmentFlag, balance }) => (
  <>
    <tr className="table-secondary" key={`shipment-previous}`}>
      <td colSpan="8">
        <strong>Total</strong>
      </td>
    </tr>
    <tr key={`shipment-balance`}>
      {partialShipmentFlag && (
        <td>
          <strong>Still to be shipped</strong>
        </td>
      )}
      {!partialShipmentFlag && (
        <td>
          <strong>Short Shipped</strong>
        </td>
      )}
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td className="text-danger">
        <strong>
          <RxNumberFormat value={balance.min} />
        </strong>
      </td>
      <td className="text-success">
        <strong>
          <RxNumberFormat value={balance.max} />
        </strong>
      </td>
    </tr>
  </>
)

const NewShipment = ({ tolerance, name, quantity }) => (
  <>
    <tr className="table-warning">
      <td>
        <div>Nominating</div>
      </td>
      <td>{tolerance && !isNaN(tolerance)?`${tolerance}%` : '-'}</td>
      <td>{name}</td>
      <td> - </td>
      <td> - </td>
      <td>
        <RxNumberFormat value={quantity} />
      </td>
      <td className="text-danger">
        <RxNumberFormat value={quantity} />
      </td>
      <td className="text-success">
        <RxNumberFormat value={quantity} />
      </td>
    </tr>
  </>
)

const ShipmentHead = ({ partialShipmentFlag }) => (
  <thead className="thead-light">
    <tr>
      <th className="py-1 text-right" colSpan="8">
        <strong>
          Partial Shipments : {partialShipmentFlag ? 'Allowed' : 'Not Allowed'}{' '}
        </strong>
      </th>
    </tr>
    <tr>
      <th />
      <th>Tolerance</th>
      <th>Vessel Name</th>
      <th>Bill of Lading n°</th>
      <th>Shipped On Board Date</th>
      <th>Quantity</th>
      <th>Min to Be Shipped</th>
      <th>Max to Be Shipped</th>
    </tr>
  </thead>
)

const ShipmentRecapTable = ({
  parentTrade,
  shipmentList,
  newShipment,
  onTableUpdate
}) => {
  // On shipmentList change
  if(!parentTrade){
    return null;
  }
  const { tolerance, measure, partialShipmentFlag,status }=parentTrade;
  const shippingHistory = useMemo(() => _shippingHistory(shipmentList), [
    shipmentList,
  ])
  // On newShipment change
  const balance = useMemo(
    () => _balance({ measure, tolerance, shippingHistory, newShipment,status }),
    [measure, tolerance, shippingHistory, newShipment,status]
  )

  // Update callback
  useEffect(() => {
    if (onTableUpdate) {
      onTableUpdate({
        max: getMax(measure, tolerance),
        min: getMin(measure, tolerance),
        newShipment,
        shippingHistory,
        balance,
      })
    }
  }, [shippingHistory, balance])

  return (
    <table className="table table-sm table-striped">
      <ShipmentHead {...{ partialShipmentFlag }} />
      <tbody>
        <tr>
          <td>Contract</td>
          <td>{tolerance ? `${tolerance}%` : '-'}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{<RxNumberFormat value={measure} />}</td>
          <td className="text-danger">
            {<RxNumberFormat value={getMin(measure, tolerance || 0)} />}
          </td>
          <td className="text-success">
            {<RxNumberFormat value={getMax(measure, tolerance || 0)} />}
          </td>
        </tr>

        {shippingHistory.shipments.length > 0 && (
          <>
            <tr className="table-secondary" key={`shipment-previous}`}>
              <td className="py-1" colSpan="8">
                <strong>Shipments</strong>
              </td>
            </tr>
            {shippingHistory.shipments.map(prepareShippingHistoryRow)}
          </>
        )}

        {newShipment && (
          <NewShipment
            tolerance={newShipment.tolerance}
            name={newShipment.name}
            quantity={newShipment.quantity}
          />
        )}

        {balance && <Balance {...{ partialShipmentFlag, balance }} />}
      </tbody>
    </table>
  )
}

ShipmentRecapTable.propTypes = {
  onTableUpdate: PropTypes.func,
  parentTrade: PropTypes.object,
  shipmentList: PropTypes.arrayOf(
    PropTypes.PropTypes.shape({
      name: PropTypes.string,
      measure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      tolerance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  newShipment: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    measure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tolerance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
}

export default ShipmentRecapTable
