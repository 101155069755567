import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';

//permissions
import {
  viewInspectionQuotePermission,
  viewShippingQuotePermission,
  negotiateInspectionQuotePermission,
  negotiateShippingQuotePermission,
} from "../../../../services/service.permission";
import permissionModal from "../../../../utils/permissionModal";
import { sendEmail } from '../../../../modules/module.trade';

//Inspection Quote
import InspectionStepOneModel from '../../trades/components/quotes/InspectionStepOneModel';
import InspectionStepTwoModel from '../../trades/components/quotes/InspectionStepTwoModel';
import QuoteThankYouNoteModal from '../../trades/components/quotes/QuoteThankYouNoteModal';

//Shipping Modals
import RequestModal from '../../quotes/RequestModal';
import RequestBookingModal from '../../quotes/maersk/RequestBookingModal';
import ShippingVesselModal from '../../trades/components/quotes/ShippingVesselModal';

const QuoteOptions = ({
  trade,
  account,
  isDeclined,
  inspections,
  inspectionCompanies,
  inspectionQuote,
  shipmentQuote,
  inspectionInvalidEmail,
  isTradeOwner,
  selectedBids = [],
  series = []
}) => {
  const [modal, setModal] = useState(null);
  const [stepOneData, setStepOneData] = useState(null);
  const [stepTwoData, setStepTwoData] = useState(null);
  const [noteType, setNoteType] = useState('');

  const renderModal = () => {
    switch (modal) {
      case "PERMISSION_REQUIRED":
        return permissionModal({
          onClose: () => setModal(null),
        });
      case "INSPECTION_QUOTE_STEP_1":
        return (
          <InspectionStepOneModel
            initialData={series[0].data}
            stepOneData={stepOneData}
            inspections={inspections}
            inspectionCompanies={inspectionCompanies}
            onSubmit={(data) => {
              setStepOneData(data);
              setModal("INSPECTION_QUOTE_STEP_2");
            }}
            onCancel={() => {
              setModal(null);
              setStepOneData(null);
              setStepTwoData(null);
            }}
            sendEmail={() => inspectionInvalidEmail(trade.id)}
          />
        )
      case "INSPECTION_QUOTE_STEP_2":
        return (
          <InspectionStepTwoModel
            initialData={series[0].data}
            stepOneData={stepOneData}
            stepTwoData={stepTwoData}
            inspections={inspections}
            onCancel={(data) => {
              setStepTwoData(data);
              setModal('INSPECTION_QUOTE_STEP_1');
            }}
            onSubmitSuccess={(type) => {
              setNoteType(type);
              setModal("THANK_YOU_NOTE")
            }}
            onClose={() => {
              setModal(null);
              setStepOneData(null);
              setStepTwoData(null);
            }}
          />
        )
      case "THANK_YOU_NOTE":
        return (
          <QuoteThankYouNoteModal
            data={series[0].data}
            onClose={() => {
              setModal(null);
              setNoteType('');
            }}
            type={noteType} />
        )
      case "SHIPPING_QUOTE_VESSEL":
        return (
          <ShippingVesselModal
            data={series[0].data}
            shipping={shipmentQuote.shipping}
            shippingCompanies={shipmentQuote.shippingCompanies}
            onClose={() => setModal(null)}
            sendEmail={() => inspectionInvalidEmail(trade.id)}
            onSubmitSuccess={(type) => {
              setNoteType(type);
              setModal("THANK_YOU_NOTE");
            }}
          />
        )
      case "SHIPPING_QUOTE_CONTAINER":
        return (
          <RequestModal
            onClose={() => setModal(null)}
            onRequestMoreDetails={() => setModal("SHIPPING_QUOTE_DETAILS")}
            quoteType="PROVISIONAL"
            onCloseAll={() => setModal(null)} />
        )
      case "SHIPPING_QUOTE_DETAILS":
        return (
          <RequestBookingModal
            onClose={() => setModal('SHIPPING_QUOTE_CONTAINER')}
            onCloseAll={() => setModal(null)} />
        )
      default:
        return null;
    }
  };

  if (!isDeclined) {
    const lastBid = selectedBids.length > 0 ? { ...selectedBids[0] } : { ...trade };
    const currentIncoterm = lastBid.incoterm;
    const nonIntegratedInspector = lastBid.inspection!=="1";
    let isSeller = false;
    if (trade.privacyType === "PRIVATE") {
      isSeller = account.token.companyId === trade.sellerId;
    }
    if (trade.privacyType === "PUBLIC") {
      isSeller = trade.requestType === "SELL" ? isTradeOwner : !isTradeOwner;
    }

    const canViewInspectionQuotes = viewInspectionQuotePermission() === 1;
    const canViewShippingQuotes = viewShippingQuotePermission() === 1;
    const canNegotiateInspectionQuote = negotiateInspectionQuotePermission() === 1;
    const canNegotiateShippingQuote = negotiateShippingQuotePermission() === 1;

    if (["CIF", "CFR"].includes(currentIncoterm) && !isSeller) return null;
    if (["FOB"].includes(currentIncoterm) && isSeller && lastBid.inspection==="1") return null;
  

    const renderInspectionQuoteAction = () => {
      if (!inspectionQuote.isInspectionCreated) {
        return canViewInspectionQuotes ? (
          <NavLink className="dropdown-item" to="/inspectionQuoteOverview/">
            View Inspection Quote
          </NavLink>
        ) : (
            <button
              className="dropdown-item"
              onClick={() => setModal("PERMISSION_REQUIRED")}
            >
              View Inspection Quote
            </button>
          );
      } else {
        return (
          <button
            className="dropdown-item"
            onClick={() => {
              if (canNegotiateInspectionQuote) {
                setModal('INSPECTION_QUOTE_STEP_1')
              } else {
                setModal("PERMISSION_REQUIRED");
              }
            }}
          >
            Provisional Inspection Quote
          </button>
        );
      }
    };

    const renderShippingQuoteAction = () => {
      let shippingModal = '';
      let viewShippingQuote = false;
      if (series[0] && series[0].data.shipping === "VESSEL") {
        shippingModal = 'SHIPPING_QUOTE_VESSEL';
        viewShippingQuote = !shipmentQuote.isShipmentCreated;
      } else {
        shippingModal = 'SHIPPING_QUOTE_CONTAINER';
      }

      if (viewShippingQuote) {
        return canViewShippingQuotes ? (
          <NavLink className="dropdown-item" to={`/shippingQuoteOverview/`}>
            View Shipping Quote
          </NavLink>
        ) : (
            <button
              className="dropdown-item"
              onClick={() => setModal("PERMISSION_REQUIRED")}
            >
              View Shipping Quote
            </button>
          );
      } else {
        return (
          <button
            className="dropdown-item"
            onClick={() => {
              if (canNegotiateShippingQuote) {
                setModal(shippingModal);
              } else {
                setModal("PERMISSION_REQUIRED");
              }
            }}
          >
            Provisional Shipping Quote
          </button>
        );
      }
    };

    return (
      <React.Fragment>
        {modal && renderModal()}
        <div className="btn-group">
          <button
            className="btn trades-request-btn btn-success"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Request Provisional Quote
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {isSeller && nonIntegratedInspector  && renderInspectionQuoteAction()}
            {((currentIncoterm !== "FOB" && isSeller) ||
              (currentIncoterm === "FOB" && !isSeller)) &&
              renderShippingQuoteAction()}
          </div>
        </div>
      </React.Fragment>
    );
  }
  return null;
};

const mapStateToProps = (state) => {
  return {
    trade: state.trade.items.single || {},
    bids: state.trade.bids,
    account: state.account,
    inspections: state.trade.inspections,
    inspectionCompanies: state.trade.inspectionCompanies,
    inspectionQuote: state.inspectionQuote,
    shipmentQuote: state.shipmentQuote
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      inspectionInvalidEmail: sendEmail
    },
    dispatch
  );

QuoteOptions.propTypes = {
  trade: PropTypes.object,
  account: PropTypes.object,
  isDeclined: PropTypes.bool,
  inspections: PropTypes.any,
  inspectionCompanies: PropTypes.any,
  inspectionQuote: PropTypes.any,
  shipmentQuote: PropTypes.any,
  inspectionInvalidEmail: PropTypes.func,
  selectedBids: PropTypes.arrayOf(PropTypes.any),
  series: PropTypes.arrayOf(PropTypes.any),
  isTradeOwner: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteOptions);
