import PropTypes from "prop-types";
import React from 'react'

import FormTradeDocumentSelect from './FormTradeDocumentSelect'
//import { DOCUMENT_UPLOADER } from './../../admin/trades/services/documents.service';

/* RDEV-1777 Create form element to handle the list of documents.
*/
const FormTradeDocumentList = ({ documentList, onChange, uploaderOptions, disableDocumentSelection = false }) => {


    /** onDocumentListChanged notify parent component */
    const onDocumentListChanged = (document, index) => {
        let newList = [...documentList]
        newList[index] = document
        onChange(newList);
    }

    return (
        <>
            { documentList.map((document, index) => (
                <FormTradeDocumentSelect
                    key={index}
                    id={`doc-select-${index}`}
                    document={document}
                    uploaderOptions={uploaderOptions}
                    onChange={updatedDoc => onDocumentListChanged(updatedDoc, index)}
                    disableDocumentSelection={disableDocumentSelection} />
            ))}
        </>)
}

FormTradeDocumentList.propTypes = {
    documentList: PropTypes.array,
    onChange: PropTypes.func,
    uploaderOptions: PropTypes.any,
    disableDocumentSelection: PropTypes.bool
}

export default FormTradeDocumentList;