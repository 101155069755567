import React from 'react';
import { EnumsService } from "../../../services/service.utils";
const Countries = EnumsService.countries();

const AssociatedCompnies = ({ company }) => (
    <div>
        <h4 className="mt-1">Parent company and/or affiliated /subsidiary company(ies)</h4>
        <div className="table-responsive mb-4">
            <table className="table table-bordered table-hover table-striped">
                <thead>
                    <tr>
                        <th scope="col">Company Registered Name</th>
                        <th scope="col">Registered Country</th>
                        <th scope="col">Company Relationship</th>
                    </tr>
                </thead>
                <tbody>
                    {company.associatedCompanies &&
                        company.associatedCompanies.map((k, i) => {
                            return (
                                <tr key={i}>
                                    <td>{k.name}</td>
                                    <td>{Countries[k.country]}</td>
                                    <td>{k.relationship.toUpperCase() === "OTHER" ? k.otherRelationship : k.relationship}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    </div>
);

export default AssociatedCompnies;