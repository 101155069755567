import React, { useEffect, useState } from 'react'
import moment from 'moment';
import FormTextareaField from '../../../../components/form/FormTextareaField';
import FormInputGroupField from '../../../../components/form/FormInputGroupField';
import FormDateFieldFromTo from '../../../../components/form/FormDateFieldFromTo';
import FormDateField from '../../../../components/form/FormDateField';
import FormInputField from '../../../../components/form/FormInputField';
import FormFileUpload from '../../../../components/form/FormFileUpload';
import FormSelectDropdown from '../../../../components/form/FormSelectDropdown';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import FormInputNumberFormat from '../../../../components/form/FormInputNumberFormat';
import { DATEFORMAT } from '../../../../../services/service.values';
import Preloader from '../../../../components/preloader/Preloader';

const initForm = (initial) => ({
    'isSeller': {
        value: initial.isSeller,
        placeHolder: '',
        label: '',
        required: true,
        errorMessage: ''
    },
    'message': {
        value:  initial.message,
        label: 'Special Instructions',
        isValid: (message) => true,
        required: false,
        errorMessage: ''
    },
    'bookingReference': {
        value: initial.bookingReference || '',
        label: 'Booking Reference',
        isValid: (bookingReference) => (bookingReference.value !== ''),
        required: true,
        errorMessage: ''
    },
    'cutOfDate': {
        value:  initial.cutOfDate ? moment(initial.cutOfDate) : undefined,
        label: 'Cut off Date',
        isValid: (cutOfDate) => (cutOfDate.value !== ''),
        required: true,
        errorMessage: ''
    },
    
    'shippingLine': {
        value: initial.shippingLine || '',
        label: 'Name of Shipping Line',
        isValid: (shippingLine) => (shippingLine.value !== ''),
        required: true,
        errorMessage: ''
    },
    'noOfContainer': {
        value: initial.noOfContainer || '',
        label: 'Number of Containers',
        type: 'number',
        isValid: (noOfContainer) => (noOfContainer.value !== ''),
        required: true,
        errorMessage: ''
    },
    'quantityPerContainer': {
        value: initial.quantityPerContainer || '',
        label: 'MTon per Container',
        type: 'number',
        isValid: (quantityPerContainer) => (quantityPerContainer.value !== ''),
        required: true,
        errorMessage: ''
    },
    'nominateQuantity': {
        value: initial.nominateQuantity || '',
        label: 'Total MTon',
        type: 'number',
        isValid: (quantityPerContainer) => (quantityPerContainer.value !== ''),
        required: true,
        errorMessage: ''
    },
    'name': {
        value: initial.name || '',
        label: 'Intended Vessel, or Sub, OBN',
        isValid: (name) => (name.value !== ''),
        required: true,
        errorMessage: ''
    },
/*     'document': {
        value: initial.name || '',
        label: 'Upload Supporting Documents',
        isValid: (name) => (name.value !== ''),
        required: false,
    } */
});

/**
 * ContainerNominationForm
 *
 * @component
 * @example
 * return (
 *   <ContainerNominationForm />
 * )
 */
const ContainerNominationForm = ({ initialValues = {}, inspectors, onClose, onSubmit, onValidate, helper, children, loading }) => {

    const [nominationForm, setNominationForm] = useState(initForm({...initialValues}));
    const [isValidated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});

    const handleOnChange = (e) => {
        setValidated(false);
        let value = e.target.value;
        let name = e.target.name;

        if (nominationForm[name]) {
            const update = { ...nominationForm };
            update[name].value = value
            if (['noOfContainer', 'quantityPerContainer'].includes(name)) {
                update.nominateQuantity.value = (parseFloat(update.noOfContainer.value) * parseFloat(update.quantityPerContainer.value)).toFixed(2);
            }
            setNominationForm(update);
        } else {
            console.error(`No parameter field ${name} present in nominationForm`)
        }
    }

    // Validates the form according to its validation rules
    const validate = (validating) => {
        let errors = {};
        for (const [key, value] of Object.entries(nominationForm)) {
            if (typeof value.isValid === "function") {
                if (!value.isValid(value, nominationForm)) {
                    errors[key] = value.errorMessage;
                }
            }
        }
        setErrors(errors);
        const validated = Object.keys(errors).length === 0
        if(validating){
            setValidated(validated);
        }
        return validated
    }
    
    const serializeForm = () => {
        const values = {};
        for (const [key, formItem] of Object.entries(nominationForm)) {
            if (formItem.type === 'number') {
                values[key] = parseFloat(formItem.value)
            } else {
                values[key] = formItem.value
            }
        }
        return values
    }
    
    // Triggered by validate button : validates the form and display confirmation or warning message.
    const handleOnValidate = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (validate(true)) {
            onValidate(serializeForm())
        }
    }

    // Triggered by submit button : validates the form and confirm submit
    const handleOnSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (validate(false)) {
            onSubmit(serializeForm());
        }
    }

    useEffect(() => {
        if (initialValues && initialValues !== {}) {
            setNominationForm(initForm({...initialValues}))
        }
    }, [initialValues])

    return (<form noValidate>
        <div className="row">
            <div className="col-12 mb-3">
                <FormTextareaField
                    name='message'
                    value={nominationForm.message}
                    onChange={handleOnChange}
                    validation={errors}
                    customStyle={{
                        isInvalidClassName: "is-invalid select_error",
                        isValidClassName: 'is-valid',
                        wrapperClassName: "form-group",
                        labelClassName: "label",
                        inputClassName: "form-control"
                    }}
                />
            </div>
            <div className="col-6 my-1">
                <FormInputField
                    name='bookingReference'
                    value={nominationForm.bookingReference}
                    onChange={handleOnChange}
                    validation={errors}
                    customStyle={{
                        isInvalidClassName: "is-invalid select_error",
                        isValidClassName: 'is-valid',
                        wrapperClassName: "form-group",
                        labelClassName: "label",
                        inputClassName: "form-control"
                    }}
                />
            </div>
            <div className="col-6 my-1">
                <FormDateField
                    minDate={moment()}
                    required={true}
                    name="cutOfDate"
                    item={nominationForm.cutOfDate}
                    time={false}
                    onSelect={(name, value) => handleOnChange({
                        target: {
                            name: name,
                            value: value
                        }
                    })}
                    dateFormat={DATEFORMAT}
                    popperPlacement="top-end"
                    validation={errors}
                />
            </div>
            <div className="col-6 my-1">
                <FormInputField
                    name='shippingLine'
                    value={nominationForm.shippingLine}
                    onChange={handleOnChange}
                    validation={errors}
                    customStyle={{
                        isInvalidClassName: "is-invalid select_error",
                        isValidClassName: 'is-valid',
                        wrapperClassName: "form-group",
                        labelClassName: "label",
                        inputClassName: "form-control"
                    }}
                />
            </div>
            <div className="col-6 my-1">
                <FormInputNumberFormat
                    name='noOfContainer'
                    value={nominationForm.noOfContainer}
                    thousandSeparator={true}
                    decimalScale={0}
                    allowNegative={false}
                    type='text'
                    isAllowed={(values) => {
                        const { floatValue, value } = values;
                        return value === "" || floatValue <= 100;
                    }}
                    customStyle={{
                        isInvalidClassName: "is-invalid select_error",
                        isValidClassName: 'is-valid',
                        wrapperClassName: "form-group",
                        labelClassName: "label",
                        inputClassName: "form-control"
                    }}
                    onChange={val => handleOnChange({
                        target: {
                            name: 'noOfContainer',
                            value: val
                        }
                    })}
                    validation={errors} />
            </div>
            <div className="col-4 my-1">
                <FormInputField
                    name='name'
                    value={nominationForm.name}
                    onChange={handleOnChange}
                    validation={errors}
                    customStyle={{
                        isInvalidClassName: "is-invalid select_error",
                        isValidClassName: 'is-valid',
                        wrapperClassName: "form-group",
                        labelClassName: "label",
                        inputClassName: "form-control"
                    }}
                />
            </div>
            <div className="col-8 my-1">
                <div className="row">
                    <div className="col-6">
                        <FormInputNumberFormat
                            name='quantityPerContainer'
                            value={nominationForm.quantityPerContainer}
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            type='text'
                            isAllowed={(values) => {
                                const { floatValue, value } = values;
                                return value === "" || floatValue <= 100;
                            }}
                            onChange={val => handleOnChange({
                                target: {
                                    name: 'quantityPerContainer',
                                    value: val
                                }
                            })}
                            customStyle={{
                                isInvalidClassName: "is-invalid select_error",
                                isValidClassName: 'is-valid',
                                wrapperClassName: "form-group",
                                labelClassName: "label",
                                inputClassName: "form-control"
                            }}
                            validation={errors} />
                    </div>
                    <div className="col-6">
                        <FormInputNumberFormat
                            name='totalQuantity'
                            value={nominationForm.nominateQuantity}
                            disabled={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            type='text'
                            onChange={(value) => {}}
                            customStyle={{
                                isInvalidClassName: "is-invalid select_error",
                                isValidClassName: 'is-valid',
                                wrapperClassName: "form-group",
                                labelClassName: "label",
                                inputClassName: "form-control"
                            }}
                            validation={errors} />
                    </div>
                </div>

            </div>
            {/* <div className="col-12 my-1">
                <FormFileUpload
                    name='document'
                    value={nominationForm.document}
                    validation={errors}
                    onChange={(file) => handleOnChange({
                        target: {
                            name: 'document',
                            value: file
                        }
                    })}
                    onError='' />
            </div> */}
        </div>
        
        {children}
        <div>
            {helper}
        </div>
        { Object.keys(errors).length !== 0 && <div className="text-danger">
            Please fill the form in order to complete the vessel nomination
        </div>}
        
        <div className='modal-footer' style={{ alignItems: 'center', justifyContent: 'center' }}>
            <button type="button" data-dismiss="modal" className="btn btn-secondary" onClick={onClose} aria-label="Close">
                <FontAwesomeIcon icon={faTimes} />&nbsp;
                Cancel
            </button>
            {isValidated ? ( 
                <button type="submit" className="btn btn-primary" aria-label="Submit" onClick={handleOnSubmit}>
                    <Preloader loadingStyle="dots" loading={loading}>
                        <FontAwesomeIcon icon={faCheck} />&nbsp;Submit
                    </Preloader>
                </button>
            ):(
                <button type="submit" className="btn btn-primary" aria-label="button" onClick={handleOnValidate}>
                    <FontAwesomeIcon icon={faCheck} />&nbsp;Validate
                </button>
            )}
        </div>
    </form>)
}

ContainerNominationForm.propTypes = {
    helper: PropTypes.elementType,
    children: PropTypes.elementType,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    inspectors: PropTypes.object,
    loading: PropTypes.bool
}

ContainerNominationForm.defaultProps = {
    onClose: () => alert(`Go back to previous screen`),
    onValidate: (values) => alert(`Validates form values : ${JSON.stringify(values)}`),
    onSubmit: (values) => alert(`Submits form values : ${JSON.stringify(values)}`),
    initialValues: {},
    inspectors: {}
}

export default ContainerNominationForm;