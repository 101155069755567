import {
  InspectionQuoteApi
} from '../services/service.api';
import {
  LoadingRBACGrouptData,
  LoadingRequestData,
  LoadingTradeMessages,
} from './module.loading';



export const GET_QUOTESTATUS = 'inspectionQuote/GET_QUOTESTATUS';
export const API_ERROR = 'inspectionQuote/API_ERROR';
export const PROQUOTES_VESSEL = 'inspectionQuote/PROQUOTES_VESSEL';
export const LOAD_QUOTELIST = 'inspectionQuote/LOAD_QUOTELIST';
export const QUOTEDETAILS = 'inspectionQuote/QUOTEDETAILS';
export const LOAD_QUOTEFINALLIST = 'inspectionQuote/LOAD_QUOTEFINALLIST';
export const SHIPPINGLIST = 'inspectionQuote/SHIPPINGLIST';
export const SERVICE_PROVISIONALLIST = 'inspectionQuote/SERVICE_PROVISIONALLIST';
export const SERVICE_FINALLIST = 'inspectionQuote/SERVICE_FINALLIST';
export const GET_MESSAGES = 'inspectionQuote/GET_MESSAGES';
export const POST_MESSAGE = 'inspectionQuote/POST_MESSAGE';
export const REPLY_MESSAGE = 'inspectionQuote/REPLY_MESSAGE';
export const CLEAR_QOUTEDETAILS_STATE = 'inspectionQuote/CLEAR_QOUTEDETAILS_STATE';
export const GET_DOCUMENTS = 'inspectionQuote/GET_DOCUMENTS';
export const GET_DOWNLOADFILE='inspectionQuote/GET_DOWNLOADFILE';

const arrayToTree = require('array-to-tree');

const initialState = {

  quoteType: {},
  error: {},
  quoteProvisionalList: [],
  quoteFinalList: [],
  quoteDetails: {},
  shippingList: [],
  provisionalProvderList: [],
  finalProvderList: [],
  messages: [],
  documents:null,
};


export default (state = initialState, action= {}) => {
  switch (action.type) {

    case GET_QUOTESTATUS:
      var inspectionStatus=action.payload.quoteType.quoteType;
      var isInspectionCreated = true;
      if (inspectionStatus === "NEW" || inspectionStatus === "INPROGRESS" || inspectionStatus ===  "COMPLETED") {
        isInspectionCreated = false;
      }
      if (inspectionStatus === "NOTFOUND") {
        isInspectionCreated = true;
      }
      return {
        ...state,
        quoteType: action.payload.quoteType,
        isInspectionCreated:isInspectionCreated

      };
      case GET_DOCUMENTS:
        return {
          ...state,
          // documents: action.payload.documents
  
        };
    case PROQUOTES_VESSEL:
      return state;
    case LOAD_QUOTELIST:
      return {
        ...state,
        quoteProvisionalList: action.payload.quoteProvisionalList
      };
    case LOAD_QUOTEFINALLIST:
      return {
        ...state,
        quoteFinalList: action.payload.quoteFinalList
      };
      case QUOTEDETAILS:
        return {
          ...state,
          quoteDetails: action.payload.quoteDetails
       };
      //   case SHIPPINGLIST:
      //   return {
      //     ...state,
      //     shippingList: action.payload.shippingList
      //   };

    case SERVICE_PROVISIONALLIST:
      return {
        ...state,
        provisionalProvderList: action.payload.provisionalProvderList
      };
    case SERVICE_FINALLIST:
      return {
        ...state,
        finalProvderList: action.payload.finalProvderList
      };

      case GET_MESSAGES:
        action.payload.forEach(val => {
            val.isReplying = false;
        });
        let messagesTree = arrayToTree(action.payload.reverse(), {
            parentProperty: 'parentID',
            customID: 'ID'
        });
        return {
            ...state,
            messages: messagesTree
        };
    case POST_MESSAGE:
        return state;
    case REPLY_MESSAGE:
        const onReplyMessage = mes => {
            if (mes.children) {
                mes.children.forEach(value => {
                    if (value.ID === action.payload.id) {
                        value.isReplying = !value.isReplying;
                    }
                    onReplyMessage(value);
                })
            }
        };
        let repliedMessages = state.messages;
        repliedMessages.forEach(value => {
            if (value.ID === action.payload.id) {
                value.isReplying = !value.isReplying;
            }
            onReplyMessage(value);
        });
        return {
            ...state,
            messages: repliedMessages
        };
        case GET_DOWNLOADFILE:
        return state;
        case CLEAR_QOUTEDETAILS_STATE:
            return initialState;
    default:
      return state;
  }
};




export const PostInspectionQuote = (tradeId, params,companyId, cb) => {
  return dispatch => {
    //LoadingonGroupAction(dispatch, true);
    InspectionQuoteApi.PostInspectionQuote(tradeId, params)
      .then((r) => {
        dispatch({
          type: PROQUOTES_VESSEL,
          payload: r.data
        });
        cb({
          ...r.data
        });
        getInpectionStatus(tradeId,params.inspectionQuoteType,companyId)(dispatch);
      })
      .catch((r) => {

        // LoadingonGroupAction(dispatch, false);
      });
  }
};

export const getInpectionStatus = (tradeId,quoteType,companyid, cb) => {
  return dispatch => {
    InspectionQuoteApi.getInpectionStatus(tradeId,quoteType,companyid)
      .then(r => {
        dispatch({
          type: GET_QUOTESTATUS,
          payload: {
            quoteType: r.data
          }
        });
        if(cb){
          cb({...r.data});
        }
      })
      .catch(e => {
        console.error(e);
      });
  };
};

export const inspectionProvisionalList = (quoteType) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    InspectionQuoteApi.inspectionList(quoteType)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: LOAD_QUOTELIST,
          payload: {
            quoteProvisionalList: r.data
          }
        });
      })
      .catch(e => {
        console.log('catch', e);
        LoadingRBACGrouptData(dispatch, false);
      });
  };
};

export const inspectionFinalList = (quoteType) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    InspectionQuoteApi.inspectionList(quoteType)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: LOAD_QUOTEFINALLIST,
          payload: {
            quoteFinalList: r.data
          }
        });
      })
      .catch(e => {
        console.log('catch', e);
        LoadingRBACGrouptData(dispatch, false);
      });
  };
};

export const getInspectionDetails = (Id, InspectionQuoteId, cb) => {
  return dispatch => {
    LoadingRequestData(dispatch, true);
    InspectionQuoteApi.getInspectionDetails(Id, InspectionQuoteId)
      .then(r => {

        dispatch({
          type: QUOTEDETAILS,
          payload: {
            quoteDetails: r.data
          }
        });
        cb({
          ...r.data
        });
       LoadingRequestData(dispatch, false);
      })
      .catch(e => {
        console.log('catch', e);
        LoadingRequestData(dispatch, false);
      });
  };
};

// export const getShippingCompanyList = () => {
//   return dispatch => {
//     // LoadingRBACGrouptData(dispatch, true);
//     ShipmentQuoteApi.getShippingCompanyList()
//       .then(r => {
//         //LoadingRBACGrouptData(dispatch, false);
//         dispatch({
//           type: SHIPPINGLIST,
//           payload: {
//             shippingList: r.data
//           }
//         });
//       })
//       .catch(e => {
//         console.log('catch', e);
//       });
//   };
// };

export const getProvisionalProviderQuoteList = (quoteType) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    InspectionQuoteApi.getServiceProviderQuoteList(quoteType)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: SERVICE_PROVISIONALLIST,
          payload: {
            provisionalProvderList: r.data
          }
        });
      })
      .catch(e => {
        LoadingRBACGrouptData(dispatch, false);
      });
  };
};

export const getFinalProviderQuoteList = (quoteType) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    InspectionQuoteApi.getServiceProviderQuoteList(quoteType)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: SERVICE_FINALLIST,
          payload: {
            finalProvderList: r.data
          }
        });
      })
      .catch(e => {
        LoadingRBACGrouptData(dispatch, false);
      });
  };
};
export const updateQuoteStatus = (InspectionQuoteId, status,params,cb) => {
  return dispatch => {
    LoadingRequestData(dispatch, true);
    InspectionQuoteApi.updateQuoteStatus(InspectionQuoteId, status,params).then(res => {
      cb({res});
      LoadingRequestData(dispatch, false);
    })
      .catch(res => {
        LoadingRequestData(dispatch, false);
      });
  };
};

export const inspectionDocumentUpload = (tradeid,quoteType,companyid,params, cb) => {
  return dispatch => {
    LoadingRequestData(dispatch, true);
    InspectionQuoteApi.inspectionDocumentUpload(tradeid,quoteType,companyid,params)
      .then((res) => {
        
        cb({
         res
        });
        LoadingRequestData(dispatch, false);
      })
      .catch((res) => {
        cb({
         res
        });
       LoadingRequestData(dispatch, false);
      });
  }
};

export const postInspectionMessage = (inspectionId,CounterpartyId, message,cb) => {
  return dispatch => {
      LoadingTradeMessages(true, dispatch);
    //  InspectionQuoteApi.postInspectionMessage(inspectionId,"5be3f9c0-9106-11ea-8fea-6b0a662818a1", message).then(r => {
        InspectionQuoteApi.postInspectionMessage(inspectionId,CounterpartyId, message).then(r => {
          dispatch({
              type: POST_MESSAGE,
              payload: ''
          });
          InspectionQuoteApi.getInspectionMessages(inspectionId).then(response => {
              LoadingTradeMessages(false, dispatch);
              dispatch({
                  type: GET_MESSAGES,
                  payload: response.data.comments
              });
              if(cb){
                cb(); 
             }
          });
      });
  };
};



export const getInspectionMessages = (id,cb) => {
  return dispatch => {
      LoadingTradeMessages(true, dispatch);
      InspectionQuoteApi.getInspectionMessages(id).then(r => {
          LoadingTradeMessages(false, dispatch);
          dispatch({
              type: GET_MESSAGES,
              payload: r.data.comments
          });
      }).catch(() => {
        LoadingTradeMessages(false, dispatch);
    });
    if(cb){
      cb(); 
   }
  };
};




export const ReplyMessage = (id, userId) => {
  return dispatch => {
      dispatch({
          type: REPLY_MESSAGE,
          payload: { id, userId }
      });
  };
};

export const ClearQuoteState = () => {
  return dispatch => {
      dispatch({
          type: CLEAR_QOUTEDETAILS_STATE,
      })
  }
};

// export const downloadInspectionDoc = (tradeId,inspectionId,cb) => {
//   return dispatch => {
//      // LoadingTradeMessages(true, dispatch);
//       InspectionQuoteApi.downloadInspectionDoc(tradeId,inspectionId).then(r => {
//         //  LoadingTradeMessages(false, dispatch);
//           dispatch({
//               // type: GET_DOCUMENTS,
//               // payload: r.data
//           });
//           if(cb){
//             cb(); 
//          }
//       }).catch((r) => {
//       //  LoadingTradeMessages(false, dispatch);
//     });
//     if(cb){
//       cb(); 
//    }
//   };
// };


export const downloadInspectionDoc = (tradeId,inspectionId, cb) => {
  return dispatch => {
    InspectionQuoteApi.downloadInspectionDoc(tradeId,inspectionId)
      .then(r => {
        dispatch({
          type: GET_DOWNLOADFILE,
          payload: 
             r.data
          
        });
        cb({
          ...r.data
        });
      })
      .catch(r => {
        console.log("Response", r);
        cb({
          ...r.data
        });
      });
  };
};