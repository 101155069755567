import React, { Component } from 'react';
import { EnumsService } from '../../../../services/service.utils';

import {
    FormRow,
    VirtualDropdown
} from '../../Form';

import FormInputField from '../../form/FormInputField';
import FormPhoneField from '../../form/FormPhoneField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

const Countries = EnumsService.countries();
const countriesOptions = Object.keys(Countries).map((key) => ({
    value: key,
    label: Countries[key]
}));

export class TradeReferences extends Component {

    render() {

        let { state, setField,validation,add, remove } = this.props;
        return (
            <React.Fragment>
                {state.tradeReferences &&
                    state.tradeReferences.map((k, i) => {
                        return (
                            <React.Fragment key ={i}>
                                <FormRow>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            name="name"
                                            value={k.name}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "tradeReferences", i)
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            name="contact"
                                            value={k.contact}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "tradeReferences", i)
                                            }}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormPhoneField
                                            value={k.phone}
                                            name="phone"
                                            onChange={value => setField('phone', value, "tradeReferences", i)}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            name="email"
                                            value={k.email}
                                            validation={validation[i]}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "tradeReferences", i)
                                            }}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            name="line1"
                                            value={k.address.line1}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "tradeReferences", i, "address")
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            name="line2"
                                            value={k.address.line2}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "tradeReferences", i, "address")
                                            }}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            name="city"
                                            value={k.address.city}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "tradeReferences", i, "address")
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            name="postal"
                                            value={k.address.postal}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "tradeReferences", i, "address")
                                            }}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-12 col-md-6 mb-3">
                                        <VirtualDropdown
                                            name="country"
                                            label="Country"
                                            items={countriesOptions}
                                            required={false}
                                            onChange={
                                                val => {
                                                    setField('country', val.value, "tradeReferences", i, "address");
                                                }
                                            }
                                            value={{ value: k.address.country.value, label: Countries[k.address.country.value] }}
                                        />
                                    </div>
                                </FormRow>
                                <div className="text-right">
                                    {i !== 0 ? (
                                        <button type="button" className="btn btn-section-removed" onClick={e => remove(i)}>
                                            <FontAwesomeIcon icon={faTimes} /> Remove
                                        </button>
                                    ) : ''}
                                    {i === state.tradeReferences.length - 1 ? (
                                        <button type="button" className="btn btn-add" onClick={e => add()}>
                                            <FontAwesomeIcon icon={faPlus} /> Add
                                        </button>
                                    ) : ''}

                                </div>
                            </React.Fragment>
                        );
                    })}
            </React.Fragment>
        );
    }
}
