import React from 'react';
import Cleave from 'cleave.js/react';
import './FormInputGroupField.scss';

export default ({
    name,
    prepValue,
    apValue,
    value,
    validation,
    onChange,
    placeholder = '',
    customStyle = {
        wrapperClassName : "form-input",
        labelClassName :"label",
        inputClassName : "input-field",
        inputGroupTable : "input-group-table",
        isInvalidClassName: "input_error",
        isValidClassName: ''

    },
    options,
    ...rest
}) => { 
    const isInvalid = validation && validation.hasOwnProperty(name);  
    if(!value){
        return false 
    } else {
        return (
        <div className={customStyle.wrapperClassName}>
            {value.label &&
            <label htmlFor={name} className={customStyle.labelClassName}>
                {value.label}
                {value.required && <span className="req-field">&nbsp;*</span>}
            </label>}
            <div className={`input-group mb-3 ${customStyle.inputGroupTable}`}>
                { prepValue && 
                <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{prepValue}</span>
                </div> }
                <Cleave
                    placeholder={placeholder}
                    value={value.value}
                    className={`${customStyle.inputClassName} ${isInvalid ? customStyle.isInvalidClassName :  value.touched || value.validated ? customStyle.isValidClassName : ''}`}
                    id={name}
                    name={name}
                    onChange={onChange}
                    required={!!value.required}
                    options={options}
                    {...rest}
                />
                { apValue && 
                <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon1">{apValue}</span>
                </div> }
            </div>
        </div>)
    }    
};