import React from 'react';

import ModalHeader from './ModalHeader'
import ModalFooter from './ModalFooter'

const defaultClasses = {
    modalContainerClass: '',
    modalWrapperClass: 'py-0 px-0',
}

const defaultComponents = {
    header: ModalHeader,
    footer: ModalFooter
}

const Modal = ({
    title,
    classes,
    components = {},
    onClose,
    children,
    ...rest }) => {

    components = { ...defaultComponents, ...components };
    classes = { ...defaultClasses, ...classes };
    return (
        <div className={`modal__container ${classes.modalContainerClass}`}>
            <div className={`modal__wrapper ${classes.modalWrapperClass}`}>
                <div className="modal-content">
                    {components.header && React.createElement(components.header, { title, onClose, ...rest })}
                    <div className="modal-body py-0">{children}</div>
                    {components.footer && React.createElement(components.footer, { onClose, ...rest })}

                </div>
            </div>
        </div>
    )
}

export default Modal;
