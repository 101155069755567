
import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';

import { AsyncPaginate } from "react-select-async-paginate";
import FormInputField from './FormInputField';
import {PortService} from '../../../services/service.utils'

const FormAsyncSelectPort = ({  
    fieldData,
    onChange,
    country,
    styles={},
    disabled=false,
    customStyle={},
    validation={},
    ...rest
}) => {
    const [timerId, setTimerId] = useState(null);
    
    useEffect(()=>{
        return()=>{
            clearTimeout(timerId)
        }
    }, [timerId]);

    const availablePorts = useMemo(() => {
        return PortService.getAsList(country);
    }, [country])

    const isInvalid = validation && validation.hasOwnProperty(fieldData.name);
    if(availablePorts.length !== 0){
        const defaultStyles = {
            control: (provided) => ({
                ...provided,
                border: isInvalid ? '1px solid rgb(220, 53, 69)' : fieldData.touched ? '1px solid rgb(40, 167, 69)' : '',
                "&:hover": isInvalid ? '1px solid rgb(220, 53, 69)' : fieldData.touched ? '1px solid rgb(40, 167, 69)' : '',
                minHeight: 31
            }),
            menu: (provided) => ({
                ...provided,
                border: '1px solid black'
            })
        }
        const updatedStyles = {...defaultStyles, ...styles};
        const sleep = (ms) =>
            new Promise((resolve) => {
                let timer = setTimeout(() => resolve(), ms);
                setTimerId(timer);
            }
        );
      
        const loadOptions = async (search, prevOptions) => {
            await sleep(100);
        
            let filteredOptions = availablePorts;
        
            if (search) {
                const searchLower = search.toLowerCase();
                filteredOptions = filteredOptions.filter(({ label }) =>
                    label.toLowerCase().includes(searchLower)
                );
            }
        
            const hasMore = filteredOptions.length > prevOptions.length + 10;
            const slicedOptions = filteredOptions.slice(
                prevOptions.length,
                prevOptions.length + 10
            );
        
            return {
                options: slicedOptions,
                hasMore
            };
        };
    
        return(
            <div className={customStyle.wrapperClassName}>
                {fieldData.label &&(
                    <label className={customStyle.labelClassName} htmlFor={fieldData.name}>
                        {fieldData.label}
                        {fieldData.required && <span className="req-field">&nbsp;*</span>}
                    </label>
                )}
                <AsyncPaginate
                    styles={updatedStyles}
                    id={fieldData.name}
                    name={fieldData.name} 
                    value={availablePorts.filter((item) => item.value === fieldData.value)}
                    loadOptions={loadOptions}
                    onChange={(item) => onChange(fieldData.name, item.value, true)}
                    cacheUniqs={[country]}
                    isDisabled={disabled}
                    {...rest}
                />
            </div>
        )
    }else{
        const defaultInputStyle = {
            wrapperClassName : "form-group",
            inputClassName : "form-control",
            isValidClassName: "is-valid",
            isInvalidClassName: "is-invalid select_error"
        }

        return(
            <FormInputField
                customStyle={{...defaultInputStyle, ...customStyle}}
                name={fieldData.name}
                validation={validation}
                value={fieldData}
                onChange={e => onChange(fieldData.name, e.target.value, false)}
                placeholder="Port"
                disabled={disabled}
            />
        )
    }
    
}

FormAsyncSelectPort.propTypes = {
    customStyle: PropTypes.object,
    fieldData: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    country: PropTypes.string,
    rest: PropTypes.any,
}

export default FormAsyncSelectPort;