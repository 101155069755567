export const getInitialCompanyCapabilities = () => ({

    "riceCategory": [
        {
            "id": 1,
            "value": "ORGANIC",
            "name": "Organic",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 2,
            "value": "NON_ORGANIC",
            "name": "Non-Organic",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 3,
            "value": "SRP_COMPLIENT_RICE",
            "name": "SRP Certified",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 4,
            "value": "INSTANT",
            "name": "Instant",
            "isChecked": false,
            "isDisabled": false
        }
    ],
    "origin": [
        {
            "id": 1,
            "value": "AR",
            "name": "Argentina",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 2,
            "value": "BR",
            "name": "Brazil",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 3,
            "value": "KH",
            "name": "Cambodia",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 4,
            "value": "CN",
            "name": "China, People\"s Republic of",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 5,
            "value": "EC",
            "name": "Ecuador",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 6,
            "value": "EG",
            "name": "Egypt",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 7,
            "value": "GR",
            "name": "Greece",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 8,
            "value": "IN",
            "name": "India",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 9,
            "value": "IT",
            "name": "Italy",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 10,
            "value": "MM",
            "name": "Myanmar",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 11,
            "value": "PK",
            "name": "Pakistan",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 12,
            "value": "PY",
            "name": "Paraguay",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 13,
            "value": "PE",
            "name": "Peru",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 14,
            "value": "ES",
            "name": "Spain",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 15,
            "value": "UY",
            "name": "Uruguay",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 16,
            "value": "SR",
            "name": "Suriname",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 17,
            "value": "TH",
            "name": "Thailand",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 18,
            "value": "VT",
            "name": "Vietnam",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 19,
            "value": "US",
            "name": "United States",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 20,
            "value": "other",
            "name": "Other",
            "isChecked": false,
            "isDisabled": false
        }
    ],
    "packing": [
        {
            "id": 1,
            "value": "BULK",
            "name": "Bulk",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 2,
            "value": "LINER_BAGS",
            "name": "Liner Bags",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 3,
            "value": "BIG_BAGS_1_TON",
            "name": "Big bags of 1 MT",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 4,
            "value": "PP_BAGS_50_KILOS",
            "name": "PP bags 50 Kilos",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 5,
            "value": "PP_BAGS_25_KILOS",
            "name": "PP bags 25 Kilos",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 6,
            "value": "RETAIL_PACKS",
            "name": "Retail Packs",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 7,
            "value": "other",
            "name": "Other",
            "isChecked": false,
            "isDisabled": false
        }

    ],
    "qualityStandard": [
        {
            "id": 1,
            "value": "SINGLE_POLISHED",
            "name": "Single Polished",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 2,
            "value": "DOUBLE_POLISHED",
            "name": "Double Polished",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 3,
            "value": "SILKY_POLISHED",
            "name": "Silky Polished",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 4,
            "value": "SORTEXED",
            "name": "Sortexed",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 5,
            "value": "other",
            "name": "Other",
            "isChecked": false,
            "isDisabled": false
        }

    ],
    "typeOfRice": [
        {
            "id": 1,
            "value": "LONG_GRAIN",
            "name": "Long Grain",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 2,
            "value": "MEDIUM_GRAIN",
            "name": "Medium Grain",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 3,
            "value": "ROUND_GRAIN",
            "name": "Round Grain",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 4,
            "value": "FRAGRANT",
            "name": "Fragrant",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 5,
            "value": "other",
            "name": "Other",
            "isChecked": false,
            "isDisabled": false
        }

    ],
    "modeTransportation": [
        {
            "id": 1,
            "value": "CONTAINER",
            "name": "Container",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 2,
            "value": "CONVENTIONAL_VESSEL",
            "name": "Conventional Vessel",
            "isChecked": false,
            "isDisabled": false
        }
    ],
    "incoterm": [
        {
            "id": 1,
            "value": "FOB",
            "name": "FOB",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 2,
            "value": "CFR",
            "name": "CFR",
            "isChecked": false,
            "isDisabled": false
        },
        {
            "id": 2,
            "value": "CIF",
            "name": "CIF",
            "isChecked": false,
            "isDisabled": false
        }
    ]

}
)
