import React from 'react';


/**
 * Display a cell
 *
 * @since  05/02/20
 * @access public
 *
 * @type     React.Component
 * @property {type} content : content.
 * @property {type} style : CSS properties.
 * @property {type} className : className to set to the component
 * .
 */
class DetailTableDivision extends React.Component{
    constructor(props){
        super(props);
        this.className = this.props.className | "color-primary";

    }

    render(){   
        const {content = " ", className, style, index, fixWidth} = this.props;
        if(index===0){
            const width = (fixWidth)?undefined:"300px";
            return <td width={width} style={{...style, maxWidth: '200px'}} className={className}>{content}</td>
        }else{
            return <td style={style} className={className}>{content}</td>
        }



    }
}

export default DetailTableDivision;