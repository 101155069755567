import React from 'react';
import Dropzone from 'react-dropzone';

import { FaRegFileWord, FaRegFilePdf } from 'react-icons/fa';
import Preloader from '../../../components/preloader/Preloader';

const acceptedFileTypes = 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

const FileUploadModal = ({ visibility,loading, name, onDrop, file, close, docName, postDoc, accept = acceptedFileTypes, onShareChange, shareWithBuyer, isInspectionReport, isReportClean, onCleanChange }) => 
{   
    
  // const [isloading, setisloading] = useState(!{loading});

    return(
     <div className={`modal__container ${visibility ? '' : 'd-none'}`}>
        <form onSubmit={e => postDoc(e)} encType="multipart/form-data" className="modal__wrapper modal__wrapper_upload px-0 py-0">
            <div className="modal-content upload-doc">
                <div className="modal-header">
                    <div className="modal-header-center"> <h5 className="modal-title">Upload Document</h5></div>
                    <button type="button" className="close" data-dismiss="modal" onClick={close} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body">
                    <div className="modal__line row mt-0">
                        <div className="col-md-4">Document</div>
                        <Dropzone
                            className="upload col-md-8"
                            activeClassName="upload__active"
                            accept={accept}
                            onDrop={(filesAccept, filesNotAccept) => onDrop(filesAccept, filesNotAccept, name)}
                        >
                            {file ? (
                                file[0].type === 'application/pdf' ? (
                                    < FaRegFilePdf className="icon" />
                                ) : (
                                        <FaRegFileWord className="icon" />
                                    )
                            ) : null}
                            {file ? <p className="file">{file[0].name}</p> : <p>Choose File (or Drop)</p>}
                        </Dropzone>
                    </div>
                    <div className="modal__line row">
                        <div className="col-md-4">Document type</div>
                        <div className="col-md-8">{docName}</div>
                    </div>
                    {isInspectionReport && (<div className="modal__line row">
                        <div className="col-md-4">Is Report Clean</div>
                        <div className="col-md-8">
                            <div className="custom-control custom-radio custom-control-inline ml-4">
                                <input
                                    type="radio"
                                    name="isReportClean"
                                    id={`isReportClean_true`}
                                    value={true}
                                    className="custom-control-input"
                                    onChange={onCleanChange}
                                    checked={isReportClean === true}
                                />
                                <label className="custom-control-label" htmlFor={`isReportClean_true`}>
                                    {"Yes"}
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline ml-4">
                                <input
                                    className="custom-control-input"
                                    type="radio"
                                    name="isReportClean"
                                    id={`isReportClean_false`}
                                    value={false}
                                    onChange={onCleanChange}
                                    checked={isReportClean === false}
                                />
                                <label className="custom-control-label" htmlFor={`isReportClean_false`}>
                                    {"No"}
                                </label>
                            </div>
                        </div>
                    </div>)}
                    {onShareChange && (
                        <div className="modal__line row">
                            <div className="col-md-4">Share for review with buyer</div>
                            <div className="col-md-8" >
                                <input type="checkbox" name="shareWithBuyer" onChange={onShareChange} checked={shareWithBuyer} />
                            </div>
                        </div>
                    )}
                    <div className="modal__comments">
                        * Only files with *.pdf extension are allowed.
            </div>

                </div>

                <div className="modal-footer pt-0">
                    {file && (
                        <React.Fragment>
                            <button className="btn-popup btn-cancel" onClick={close}>
                                <span>Cancel</span>
                            </button>
                            <button className="btn-popup btn-submit"  type="submit">
                            <Preloader loadingStyle="dots" loading={loading}>
                                <span>Upload Document</span>
                                </Preloader>
                            </button>

                        </React.Fragment>
                    )}
                </div>
            </div>
        </form>
    </div>
);
                    };                    

export default FileUploadModal;