import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

export default function FCLFormSelector(props){
    const {trade, onChange, name="noOfContainer",Error=""} = props;
    const [noOfContainer, setNoOfContainer] = useState(0);
    const [mtonsPerFCL, setMtonsPerFCL] = useState("");

    function getCeilFCL(pct){
        let ratio = pct/100;
        if(mtonsPerFCL){
            return Math.ceil(trade.measure*ratio/mtonsPerFCL)===Infinity?"0":Math.ceil(trade.measure*ratio/mtonsPerFCL);
        }else{
            return "-";
        }
    }

    return  ( <div className="row">
        <div className="col-sm-6 ">
            <div className="trades-header-wrapper">
                <div className="trades-header-label">MT per FCL</div>
                <div className="trades-header-value">
                    <input type="number" 
                        min="1"
                        max="27"
                        className="form-control" 
                        placeholder="MT per FCL" 
                        name="mtonsPerFCL" 
                        value={mtonsPerFCL}
                        onChange={e => {
                            setMtonsPerFCL(e.target.value);
                            setNoOfContainer(0);
                        }}/>
                </div>
                {mtonsPerFCL==="" &&
                  <div className="counterparty__error">{Error}</div>}
            </div>
        </div>
        <div className="col-sm-6">
            <div className="trades-header-wrapper">
                <div className="trades-header-label">Container Size</div>
                <div className="trades-header-value">20 ft DRY</div>
            </div>
        </div>
        <div className="col-sm-6">
            <div className="trades-header-wrapper">
                <div className="trades-header-label">Min tonnage</div>
                <div className="trades-header-value"><NumberFormat value={trade.measure*95/100} displayType={'text'} thousandSeparator={true}/></div>
            </div>
        </div>
        <div className="col-sm-6">
            <div className="trades-header-wrapper">
                <div className="trades-header-label">Min Number of FCL</div>
                <div className="trades-header-value">{getCeilFCL(95)}</div>
            </div>
        </div>
        <div className="col-sm-6">
            <div className="trades-header-wrapper">
                <div className="trades-header-label">Max tonnage</div>
                <div className="trades-header-value"><NumberFormat value={trade.measure/100*105} displayType={'text'} thousandSeparator={true}/></div>
            </div>
        </div>
        <div className="col-sm-6">
            <div className="trades-header-wrapper">
                <div className="trades-header-label">Max Number of FCL</div>
                <div className="trades-header-value">{getCeilFCL(105)}</div>
            </div>
        </div>
        <div className="col-sm-6">
            <div className="trades-header-wrapper">
                <div className="trades-header-label">Number of FCL</div>
                <div className="trades-header-value">
                    <input type="number"
                        min={getCeilFCL(95)}
                        max={getCeilFCL(105)}
                        className="form-control" 
                        placeholder="Select no of FCL " 
                        name={name} 
                        value={noOfContainer}
                        onChange={e => {
                            setNoOfContainer(e.target.value);
                            onChange(e);
                         
                        }}/>
                </div>
            </div>
        </div>
    </div>)
}