import React, {useState} from 'react';
import PropTypes from "prop-types";

import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormRadioField from '../../../components/form/FormRadioField';
import { MultiOriginService } from '../../../../services';
import { ERROR_MESSAGES } from '../../../../services/service.values';
import Contract from '../components/Contract';

const SelectOriginAndSignModal = ({trade, user, traderType, onClose, onSign}) => {
    const [declared, setDeclared] = useState(false);
    const [error, setError] = useState(null);
    const [origin, setOrigin] = useState("");

    const CUSTOM_COMPONENT_STYLE = {
        radioColLayout: { left: "col-md-12", right: "col-md-12" },
        radioColClass: "mw-100"
    }

    const onSubmitHandler = () => {
        if(declared){
            if(origin){
                onSign(MultiOriginService.getOriginData(origin, trade.multipleOriginPrice));
                onClose();
            }else{
                setError(ERROR_MESSAGES.SELECT_ONE_BID_ORIGIN)
            }
        }else{
            setError(ERROR_MESSAGES.CONFIRM_BID_TERMS)
        }
    }

    return(
        <div className="modal__container">
            <form
                className="modal__wrapper px-0 py-0"
                onSubmit={event => {
                    event.preventDefault();
                    onSubmitHandler();
                }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-header-center"> 
                                {!origin && 
                                    <h5 className="modal-title">
                                        {`${traderType === 'seller'?trade.buyerUser:trade.sellerUser} has accepted your proposal. Please select one origin below which will become the contractual origin for this trade.`}
                                    </h5>
                                }
                            </div>
                            <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body py-0">
                            {origin && (
                                <div className="confirm-modal-origin">
                                    <Contract
                                        trade={{ 
                                            ...trade, 
                                            ...MultiOriginService.getOriginData(origin, trade.multipleOriginPrice)
                                        }}
                                        user={user}
                                        showMultiOriginPrices={false} />
                                </div>
                            )}
                            <div className="pl-3" >
                                <div className="form-group mt-3">
                                    <FormRadioField
                                        customStyle={CUSTOM_COMPONENT_STYLE}
                                        items={MultiOriginService.getOfferedPrices(trade.multipleOriginPrice, trade.currency)}
                                        onChange={(event) => {
                                            setOrigin(event.target.value);
                                            setError(null);
                                        }}
                                        value={{
                                            label: 'Please Select an Origin',
                                            required: true,
                                            value: origin
                                        }} />
                                </div>
                            </div>
                            <div className={`pl-3`}>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox"
                                        name="declared"
                                        className="custom-control-input"
                                        id="declared"
                                        checked={declared}
                                        onChange={event => {
                                            setDeclared(event.target.checked);
                                            setError(null);
                                        }} />
                                    <label className="custom-control-label" htmlFor="declared">
                                        I confirm that I am signing the contract with the above selected origin which will be 
                                        the contractual origin and above terms which are negotiated/agreed. 
                                    </label>
                                </div>
                            </div>
                            {error && <div className="counterparty__error text-left pl-3">{error}</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn-popup btn-cancel" onClick={onClose}>
                                <FontAwesomeIcon icon={faTimes} /> <span>Cancel</span>
                            </button>
                            <button type="submit" className="btn-popup btn-submit">
                                <FontAwesomeIcon icon={faCheck} /> <span>Confirm & Sign</span>
                            </button>
                        </div>
                    </div> 
            </form>
        </div>
    )
}

SelectOriginAndSignModal.propTypes = {
    trade: PropTypes.object,
    user: PropTypes.object,
    traderType: PropTypes.string,
    onClose: PropTypes.func,
    onSign: PropTypes.func
}

export default SelectOriginAndSignModal;