import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
/**
 * Display confirm modal 
 *
 * @since  x.x.x
 * @access public
 *
 * @type     {React.Component}
 * @property {content}
 * @property {title}
 * @property {onCancel}
 * @property {onConfirm}
 * @property {show}
 */
class DeclineContractModal extends React.Component {

    constructor(props){
        super(props);
        this.state ={ 
            declineReason: ""
        }
        this.onDeclineReasonChange = this.onDeclineReasonChange.bind(this);
      // this.onConfirm = this.onConfirm.bind(this);
    }

    onDeclineReasonChange(e){
        this.setState({declineReason: e.target.value});
    }

    // onConfirm= (e) =>{
    //     if(this.props.onConfirm){
    //     this.props.onConfirm(this.state.declineReason);
    //     }
    // }


    render() {
        const { 
            fromCompanyName
        } = this.props;

        return (<React.Fragment>
           <div className="modal__container confirm-modal">
                    <div className="modal__wrapper px-0 py-0 modal-scroll">
                        <div className="modal-header">
                            <h5 className="modal-title w-100">{`You are about to reject changes from ${fromCompanyName}`}</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onClose} aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                            <p className="pb-2">Please provide a reason for this rejection and click 'Confirm' to continue or click 'Back' to return to the previous screen.</p>
                                <div className="row">
                                <div className="col-md-12">
                                   <div className="form-group">
                                  <label className="label" htmlFor="resonForRejection">Reason for Rejection</label>
                                  <textarea id="resonForRejection" 
                                     className="form-control textarea" 
                                     onChange={this.onDeclineReasonChange}
                                      placeholder={`Provide here message to ${fromCompanyName}`} rows='3'/>
                                  </div>
                              </div>
                             </div>
                           </div>
                        </div>
                        <div className="modal-footer">
                            <button className={`btn btn-secondary`} type="button" onClick={(e)=>{this.props.onClose()}} >
                            <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                            </button>
                            <button className={`btn btn-primary`}  type="button" onClick={(e)=>{this.props.onConfirm(this.state.declineReason)}} >
                                {/* <Preloader loadingStyle="dots" loading={loading}> */}
                                    <span><FontAwesomeIcon icon={faCheck} />&nbsp;Confirm</span>
                                {/* </Preloader> */}
                            </button>
                        </div>
                    </div>
                </div>
        </React.Fragment>)
           
      
    }
}

export default DeclineContractModal;


