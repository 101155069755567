import React from 'react';


/**
 * Display error modal 
 *
 * @since  x.x.x
 * @access public
 *
 * @type     {React.Component}
 * @property {errorTitle}
 * @property {errorMessage}
 * @property {onClose}
 */
class ErrorModal extends React.Component {

    handleOnClose = (e) => {
        this.props.onClose();
    }

    render() {
        const { errorTitle, errorMessage } = this.props;

        return (<div className="modal__container confirm-modal">
            <div className="modal__wrapper px-0 py-0">
                <div className="modal-header">
                    <h5 className="modal-title w-100 text-danger">{errorTitle}</h5>
                    <button type="button" className="close" data-dismiss="modal" onClick={this.handleOnClose} aria-label="Close">
                        <span aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-md-12">
                        {errorMessage}
                    </div>
                </div>
                <div className="modal-footer">
                    <button className={`btn btn-close`} type="button" onClick={this.handleOnClose} >
                        Close
                    </button>

                </div>
            </div>
        </div>)
    }

}

export default ErrorModal;