import moment from "moment";
import { refreshToken } from "./module.account";

import { logout } from "./module.account";
import { push } from "connected-react-router";
import jwtDecode from "jwt-decode";

export function jwt({ dispatch, getState }) {
  return next => action => {
    if (typeof action === "function") {
      if (sessionStorage.getItem("jwt") && (sessionStorage.getItem("jwt") ? jwtDecode(sessionStorage.getItem("jwt")).exp - moment().unix() > 0 : false)) {
        var tokenExpiration = jwtDecode(sessionStorage.getItem("jwt"));
        const diff = tokenExpiration.exp - moment().unix();
        if (diff < 300 && diff > 0) {
          refreshToken();
          next(action);
        } else if (diff < 0) {
          logout()(dispatch);
          dispatch(push("/account/login"));
          return;
        }
      } else if (sessionStorage.getItem("jwt") ? jwtDecode(sessionStorage.getItem("jwt")).exp - moment().unix() < 0 : false) {
        logout()(dispatch);
      } else {
        logout()(dispatch);        
      }
    }

    return next(action);
  };
}
