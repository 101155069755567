import React, { useState } from 'react';
import PropTypes from "prop-types";

import ConfirmModal from './ConfirmModal';
import Contract from '../../../trades/components/Contract';
import Preloader from '../../../../components/preloader/Preloader';
import { MultiOriginService } from '../../../../../services';
import { ERROR_MESSAGES } from '../../../../../services/service.values';
import FormRadioField from '../../../../components/form/FormRadioField';

const ConfirmModalAcceptTenderWithMultiOrigin = ({isTradeOwner, onConfirm, trade, user, fromCompanyName, buttonClass, loadingBidInfo = false, onClickHandler = () => {}}) => {
    const [declared, setDeclared] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [error, setError] = useState(null);
    const [origin, setOrigin] = useState("");

    const CUSTOM_COMPONENT_STYLE = {
        radioColLayout: { left: "col-md-12", right: "col-md-12" },
        radioColClass: "mw-100"
    }
    const offeredPrices = MultiOriginService.getOfferedPrices(trade.multipleOriginPrice, trade.currency);
    const originsWithPrices = Object.keys(offeredPrices);
    const singleOriginInLastBid = originsWithPrices.length === 1;

    const onConfirmHandler = () => {
        if (!declared) {
            setError(ERROR_MESSAGES.CONFIRM_BID_TERMS);
        } else {
            if (!isTradeOwner && !singleOriginInLastBid) {
                if (accepted) {
                    onConfirm(null);
                } else {
                    setError(ERROR_MESSAGES.CONFIRM_BID_TERMS);
                }
            } else if (isTradeOwner && !singleOriginInLastBid) {
                if (origin) {
                    onConfirm(MultiOriginService.getOriginData(origin, trade.multipleOriginPrice));
                } else {
                    setError(ERROR_MESSAGES.SELECT_ONE_BID_ORIGIN);
                }
            } else {
                onConfirm(MultiOriginService.getOriginData(originsWithPrices[0], trade.multipleOriginPrice));
            }
        }
    }

    const onCancelHandler = () => {
        setDeclared(false);
        setAccepted(false);
        setError(null);
        setOrigin('');
    }

    let finalContract = null;
    let closeOnSubmitCondition = false;
    let confirmMessageClass = "";
    if ((isTradeOwner && singleOriginInLastBid) || (!isTradeOwner && singleOriginInLastBid)) {
        confirmMessageClass = "pt-3";
        closeOnSubmitCondition = declared;
        finalContract = (
            <Preloader loading={loadingBidInfo} loadingStyle="swirl">
                <div className="confirm-modal-origin">
                    <Contract
                        trade={{ ...trade, ...MultiOriginService.getOriginData(originsWithPrices[0], trade.multipleOriginPrice) }}
                        user={user}
                        showMultiOriginPrices={false} />
                </div>
            </Preloader>
        )
    }

    if (isTradeOwner && !singleOriginInLastBid) {
        closeOnSubmitCondition = declared && origin !== '';
        finalContract = origin ? (
            <Preloader loading={loadingBidInfo} loadingStyle="swirl">
                <div className="confirm-modal-origin">
                    <Contract
                        trade={{ ...trade, ...MultiOriginService.getOriginData(origin, trade.multipleOriginPrice) }}
                        user={user}
                        showMultiOriginPrices={false} />
                </div>
            </Preloader>
        ) : null;
    }

    if (!isTradeOwner && !singleOriginInLastBid) {
        closeOnSubmitCondition = declared && accepted;
        finalContract = (
            <Preloader loading={loadingBidInfo} loadingStyle="swirl">
                <div className="confirm-modal-origin">
                    <Contract
                        trade={trade}
                        user={user}
                        showMultiOriginPrices={true} />
                </div>
            </Preloader>
        )
    }

    return (
        <ConfirmModal
            title={`You are about to accept a proposal from ${fromCompanyName}`}
            buttonClass={buttonClass}
            buttonLabel="Accept"
            modalBodyClass="py-0"
            modalFootarClass="pt-0"
            modalWrapperClass="modal__wrapper-height-RM"
            onConfirm={() => onConfirmHandler()}
            closeOnSubmit={closeOnSubmitCondition}
            onCancel={onCancelHandler}
            buttonHandler={onClickHandler}>
            {finalContract}
            {(isTradeOwner && !singleOriginInLastBid) && (
                <div className="pl-3" >
                    <div className="form-group mt-3">
                        <FormRadioField
                            customStyle={CUSTOM_COMPONENT_STYLE}
                            name="origin"
                            items={offeredPrices}
                            required={true}
                            onChange={(event) => {
                                setOrigin(event.target.value);
                                setError(null);
                            }}
                            value={{
                                label: 'Please Select an Origin',
                                required: true,
                                value: origin
                            }} />
                    </div>
                </div>
            )}
            {(!isTradeOwner && !singleOriginInLastBid) && (
                <div className="pl-3 pt-3" >
                    <div className="form-group1">
                        {originsWithPrices.map(item => (
                            <span className="mr-2" key={item}>
                                <input type="checkbox"
                                    name={item}
                                    className="mr-1" 
                                    id={item}
                                    checked={true}
                                    disabled={true}
                                    onChange={() => { }} />
                                <label htmlFor={item}>{offeredPrices[item]}</label>
                            </span>
                        ))}
                    </div>
                </div>
            )}
            <div className={`pl-3 ${confirmMessageClass}`}>
                <div className="custom-control custom-checkbox">
                    <input type="checkbox"
                        name="declared"
                        className="custom-control-input"
                        id="declared"
                        checked={declared}
                        onChange={event => {
                            setDeclared(event.target.checked);
                            setError(null);
                        }} />
                    <label className="custom-control-label" htmlFor="declared">
                        I confirm having reviewed all terms and conditions,
                        which are hereby accepted and furthermore acknowledge that
                        I am entering into a legally binding contract independent whether
                        I do sign the contract later or not. Please click Back to go back or Confirm to continue.
                    </label>
                </div>
            </div>
            {(!isTradeOwner && !singleOriginInLastBid) && (
                <div className="pl-3 pt-2">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                            name="accepted"
                            className="custom-control-input"
                            id="accepted"
                            checked={accepted}
                            onChange={event => {
                                setAccepted(event.target.checked);
                                setError(null);
                            }} />
                        <label className="custom-control-label" htmlFor="accepted">
                            The proposal I am about to accept contains more than one origin.
                            I agree and understand that {fromCompanyName} will select one of the origin at the time of signing the contract.
                            That selected origin will become the contractual origin with the price and other term attached to it as per negotiated/agreed.
                        </label>
                    </div>
                </div>
            )}
            {error && <div className="counterparty__error text-left pl-3">{error}</div>}
        </ConfirmModal>
    );
}

ConfirmModalAcceptTenderWithMultiOrigin.propTypes = {
    isTradeOwner: PropTypes.bool,
    onConfirm: PropTypes.func,
    trade: PropTypes.object,
    user: PropTypes.object,
    fromCompanyName: PropTypes.string,
    buttonClass: PropTypes.string, 
    loadingBidInfo: PropTypes.bool,
    onClickHandler: PropTypes.func
}

export default ConfirmModalAcceptTenderWithMultiOrigin;