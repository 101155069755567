import React from 'react';
import './FormRadioField.scss';

const defaultStyle = {
  labelClassName :"label",
  wrapperRadioClassName : "form-radio",
  inputRadioClassName : "d-none",
  radioColLayout: {left : "", right : ""}
}

export default ({ 
  name, 
  value, 
  onChange,
  customStyle = {
    labelClassName :"label",
    wrapperClassName : "form-radio",
    inputClassName : "d-none"

  }
 }) => { 
  customStyle = { ...defaultStyle, ...customStyle};
  if(!value){
    return false; 
  }else{ 
    return (
      <div className={`${customStyle.wrapperRadioClassName} row`}>
        <div className="col-12">
          <label className={`${customStyle.labelClassName} mr-3`}>
            {value.label}
            {value.required && <span className="req-field">&nbsp;*</span>}
          </label>
        </div>
        <div className={customStyle.radioColLayout.right}>
        <div className={`radio `}>
          <div className="custom-control custom-radio custom-control-inline mr-0">
            <input
                type="radio"
                className={customStyle.inputRadioClassName}
                name={name}
                id={`${name}_true`}
                value={true}
                onChange={onChange}
                checked={value.value === true}
            />
            <label htmlFor={`${name}_true`}>
                {"Yes"}
            </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline mr-0">
            <input
                type="radio"
                className={customStyle.inputRadioClassName}
                name={name}
                id={`${name}_false`}
                value={false}
                onChange={onChange}
                checked={value.value === false}
            />
            <label htmlFor={`${name}_false`}>
                {"No"}
            </label>
            </div>
        </div>

        </div>
        
      </div>
  )}
};
