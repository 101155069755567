import React from 'react';
import ConfirmModal from './ConfirmModal';

/**
 * Display confirm modal 
 *
 * @since  x.x.x
 * @access public
 *
 * @type     {React.Component}
 * @property {content}
 * @property {title}
 * @property {onCancel}
 * @property {onConfirm}
 * @property {show}
 */
class PublicCommunicationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: ""
        }
        this.onMessageChange = this.onMessageChange.bind(this);
        // this.onConfirm = this.onConfirm.bind(this);
    }

    onMessageChange(e) {
        this.setState({ message: e.target.value, showError: false });
    }

    onConfirm = () => {
        if (this.state.message === "") {
            this.showErrorMessage("Please enter message");

        }
        else {
            this.props.onConfirm(this.state.message);
            this.setState({ message: "" });
        }
    }

    showErrorMessage = (errorMessage) => {
        this.setState({
            showError: true,
            errorMessage
        });
    }

    onCancel = () => {
        this.setState({
            errorMessage: "",
            showError: false,
            message: ""
        });
    }


    render() {
        const {
            loading = false,
        } = this.props;

        return <ConfirmModal loading={loading}
            title={`Ask More Information`}
            buttonClass="btn btn-compare float-right mr-0"
            buttonLabel="Ask Information"
            onConfirm={this.onConfirm}
            closeOnSubmit={this.state.message !== ""}
            onCancel={this.onCancel}>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="label" htmlFor="messageInfo">Enter your Message<span className="counterparty__error">*</span></label>
                        <textarea id="messageInfo"
                            className="form-control textarea"
                            onChange={this.onMessageChange}
                            placeholder={`Add your message...`} rows='3' />
                    </div>
                    {this.state.showError && <div className="counterparty__error text-center">{this.state.errorMessage}</div>}
                </div>
            </div>
        </ConfirmModal>
    }

}

export default PublicCommunicationModal;


