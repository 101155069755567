import React, { Component } from 'react';
import {push} from "connected-react-router";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { LoadInspectionTradeList, FetchNewTrade, FetchTrade } from '../../../modules/module.trade';
//COMPONENTS
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import CustomMaterialPreloader from '../../components/preloader/CustomDotsPreloader';
import TradeTablePanel from './TradeTablePanel';

import { viewTradeListPermission } from '../../../services/service.permission';
import { DATEFORMAT, TRADE_STATUS, TRADE_STATUS_HUMANIZE, SHIPPINGTYPES, getNominateVesselStatus } from '../../../services/service.values';

import { RiceService } from '../../../services/service.rice';


import './trades.scss';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { faTimes, faHistory, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import TradeQuantityFormatter from '../../components/Formatter/TradeQuantityFormatter';

class Trades extends Component {

    constructor(props){
        super(props);
        this.inspectionView = false;
        this.state = {
            showModal: true,
            modal: '',
            modalData: {},
        };
        const { user } = props;
        if (user.companyType === "INSPECTION") {
            this.inspectionView = true;
            this.props.LoadInspectionTradeList({
                skip: 0,
                take: 25,
                type: 'all',
                status: 'DEAL',
                page: 'trade',
            });
        }
    }

    componentDidMount = () => {
        if(this.props.trade.lastUpdate){
            this.props.FetchNewTrade();
        }
    }

//Render functions
sortRef  = (a, b) => { 
    let refA = a.tradeRefId || a.id;
    let refB = b.tradeRefId || b.id;
    return refA.localeCompare(refB);
};
sortQuantity  = (a, b) => (parseInt(a.quantity) - parseInt(b.quantity));
renderOverflow = (content, className) => <span className={`trades__overflow ${className}`}>{content?content:'-'}</span>;
renderRequestType = rowData => rowData.requestType === "BUY"?'Bid':'Offer'; 
renderHumanizeStatus = rowData => (<div className={`trades__cell trades__cell_status trades__cell_status--${TRADE_STATUS[rowData.status]}`}>
    {TRADE_STATUS.PROFORMA_INVOICE !== rowData.status ? TRADE_STATUS_HUMANIZE[TRADE_STATUS[rowData.status]] : getNominateVesselStatus(rowData, TRADE_STATUS_HUMANIZE[TRADE_STATUS[rowData.status]], this.props.user.companyId)}
</div>);
renderRiceType = rowData => {
    if(rowData.riceType === RiceService.enums.REF_OTHER){
        return rowData.riceTypeOther
    }else{
        const rice = RiceService.getByRef(rowData.riceType);
        return rice?rice.label:rowData.riceType;
    }   
}

columns =  {
    REF:            { title: 'Trade Ref Id', field: 'tradeRefId ', type: 'string', customSort: this.sortRef, render: rowData => rowData.tradeRefId || rowData.id},
    REQUEST_TYPE:   { title: 'Type', field: 'type', render: this.renderRequestType },
    BUYER:          { title: 'Buyer', field: 'buyer', render: rowData => this.renderOverflow(rowData.buyer)},
    SELLER:         { title: 'Seller', field: 'seller', render: rowData => this.renderOverflow(rowData.seller)},
    UPDATED_AT:     { title: 'Updated At ', field: 'updatedAt', type: 'date', hidden: true, defaultSort: 'desc'},
    SHIPPING_TYPE:  { title: 'Vessel/ container', field: 'shipping', render: rowValue => SHIPPINGTYPES[rowValue.shipping] },
    QUANTITY:       { title: 'Quantity(MT)', filed: 'measure', customSort: this.sortQuantity, render: rowData => <TradeQuantityFormatter trade={rowData}/>},
    INCOTERM:       { title: 'Incoterm', field: 'incoterm' },
    RICE_TYPE:      { title: 'Rice Type', field: 'riceType', render: rowData => this.renderOverflow(this.renderRiceType(rowData))},
    SHIPPING_FROM:  { title: 'Shipping/ Delivery Period Start', field: 'deliveryStartDate', render: rowData => this.renderOverflow(moment(rowData.deliveryStartDate).format(DATEFORMAT))},
    SHIPPING_TO:    { title: 'Shipping/ Delivery Period End', field: 'deliveryEndDate', render: rowData => this.renderOverflow(moment(rowData.deliveryEndDate).format(DATEFORMAT))},
    PRICE:          { title: 'Price', field: 'price', type: 'numeric', render: rowData => <NumberFormat value={rowData.price} displayType={'text'} thousandSeparator={true} prefix={rowData.currency === 'USD' || rowData.currency==='US$'?'$':'€'} renderText={value => <span className="exchange__overflow">{value}</span>} ></NumberFormat>},
    CREATED_AT:     { title: 'Creation Date ', field: 'createdAt', render: rowData => this.renderOverflow(moment(rowData.createdAt).format(DATEFORMAT))},
    STATUS:         { title: 'Trade Status', field: 'status', render: this.renderHumanizeStatus}
}

defaultColumnList = [
    this.columns.REF, 
    this.columns.UPDATED_AT, 
    this.columns.BUYER, 
    this.columns.SELLER,
    this.columns.STATUS,
    this.columns.INCOTERM,
    this.columns.QUANTITY,
    this.columns.PRICE, 
    this.columns.RICE_TYPE, 
    this.columns.SHIPPING_TYPE, 
    this.columns.CREATED_AT,
    this.columns.SHIPPING_FROM, 
    this.columns.SHIPPING_TO]

inspectionCompColumns = [
    this.columns.REF, 
    this.columns.UPDATED_AT, 
    this.columns.BUYER, 
    this.columns.SELLER,
    this.columns.STATUS,
    this.columns.INCOTERM,
    this.columns.QUANTITY,
    this.columns.RICE_TYPE, 
    this.columns.SHIPPING_TYPE, 
    this.columns.SHIPPING_FROM, 
    this.columns.SHIPPING_TO]

    renderModal = () => {
        return ( <div className="modal__container">
            <form className="modal__wrapper px-0 py-0 authcode" onSubmit={e => {
                e.preventDefault();
                    this.closeModal();}}>
                <div className="modal-content">
                        <div className="modal-header">   
                        <Link to="/"  className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>    
                        </Link>      
                    </div>          
                    <div className="modal-body">
                        <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>                                        
                    </div>     
                    <div className="modal-footer">              
                        <Link to="/" className="btn-popup btn-cancel "><FontAwesomeIcon icon={faTimes} /> Cancel</Link>
                    </div>
                </div>
            </form>
        </div>);
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            modalData: {}
        });
    };

    render() {
        const {trade, user, pendingInspectionList} = this.props;
        if(viewTradeListPermission() === 0 ){       
            return <div> {this.state.showModal && this.renderModal()}</div>
        }else {
            return (
            <React.Fragment>
                <Header />
                <section className="trades">
                    <div className="row">
                        <div className="col"><h2 className="trades__heading">Live Contracts</h2></div>
                        <div className="col text-right">
                            <Tooltip title="Refresh"><IconButton onClick={ (e) => this.props.FetchNewTrade("LIVE") }><FontAwesomeIcon icon={faSyncAlt}/></IconButton></Tooltip>
                            <Tooltip title="Load more"><IconButton onClick={ (e) => this.props.FetchTrade("LIVE")  }><FontAwesomeIcon icon={faHistory}/></IconButton></Tooltip>
                        </div>
                    </div>
                    <MaterialTable
                        columns={this.inspectionView?this.inspectionCompColumns:this.defaultColumnList}
                        data={_.chain(trade.items.tradeMap)
                                .values()
                                .filter(item => (
                                    !item.parentTradeRequestID
                                    && !["NEW", "CANCELED", "EXPIRED", "DECLINED", "PAYED"].includes(item.requestStatus)
                                    && (item.buyerId === user.companyId || item.sellerId === user.companyId || item.inspection === user.companyId)))
                                .value()}
                        title="Trades Board"
                        onRowClick={(event, rowData) => this.props.navigate('/details/' + rowData.id)}
                        isLoading={(this.inspectionView)?pendingInspectionList:trade.items.LIVE.pending || trade.items.NEW.pending}
                        icons={{
                            Filter: FilterList,
                            FirstPage: FirstPage,
                            LastPage: LastPage,
                            NextPage: ChevronRight,
                            PreviousPage: ChevronLeft,
                            SortArrow: ArrowUpward,
                            DetailPanel: ChevronRight                  
                        }}
                        components={{
                            OverlayLoading: CustomMaterialPreloader,
                            Toolbar: () => (false)
                        }}
                        detailPanel={[{
                            disabled: false,
                            isFreeAction: true,
                            render: rowData => rowData.childTradeRequestID.length !== 0 ? <TradeTablePanel trade={rowData} />:false
                        }]}
                        options={{
                            search: false,
                            pageSize: 10,
                            pageSizeOptions: [10, 15, 20, 30],
                            rowStyle: (rowData, index) => {
                                const backgroundColor = (index%2===0)?'#00ff301c':'#FBFBFB';
                                return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                            },
                            thirdSortClick: false
                        }}
                    />
                </section>
                <Footer />
            </React.Fragment>);
        } 
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        pendingInspectionList: state.loading.requestLoading,
        user: state.account.user,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            FetchNewTrade,
            FetchTrade,
            LoadInspectionTradeList,
            navigate: path => push('/trades' + path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Trades);