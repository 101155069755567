import React from 'react';

const ShareholderDetails = ({ company }) => (
    <div>
        <h4 className="mt-1">Company shareholders details</h4>
        <div className="table-responsive mb-4">
            <table className="table table-bordered table-hover table-striped">
                <thead>
                    <tr>
                        <th scope="col">Shareholder Name (Ownership > 10%)</th>
                        <th scope="col">Owner Percentage</th>
                    </tr>
                </thead>
                <tbody>
                {company.shareholders[0].name ? company.shareholders &&
                        company.shareholders.map((k, i) => {
                            return (
                                <tr key={i}>
                                    <td>{k.name}</td>
                                    <td>{k.percentage}</td>
                                </tr>
                            );
                        }):<tr ><td>{"Not Applicable"}</td><td>{"Not Applicable"}</td></tr>}
                </tbody>
            </table>
        </div>
    </div>
);

export default ShareholderDetails;