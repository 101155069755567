import React, {useEffect} from 'react'
import { bindActionCreators } from 'redux';
import { connect,useSelector  } from 'react-redux';
import MultipleShipmmentDetail from './components/shipments/MultipleShipmmentDetail';
import { getPartialShipments } from '../../../modules/module.trade';
import Preloader from '../../components/preloader/Preloader';

const TradeTablePanel = ({ trade, getPartialShipments }) => {
    const {vesselNominationMap,shipmentMap} = useSelector((state)=>state.trade);
    const {requestLoading}=useSelector((state)=>state.loading);
    
    // Refresh the tradeMap with the list of child trades
    useEffect(() => {
        if(trade.id){
            getPartialShipments(trade.id);
        } 
    }, [trade])
    
    return (requestLoading)? <Preloader loadingStyle="swirl" loading />:<MultipleShipmmentDetail parentId={trade.id} redirect="nominatedVesselInfo" vesselNominationMap={vesselNominationMap} shipmentMap={shipmentMap}/>
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getPartialShipments
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(TradeTablePanel)
