import React, { Component } from 'react';

import {
    FormRow,
} from '../../Form';

import FormInputField from '../../form/FormInputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import FormFileUpload from '../../form/FormFileUpload';
import FromInputFormPercentage from '../../form/FromInputFormPercentage';


export class UboDetails extends Component {

    render() {

        let { state, setField, validation, add, remove, fileUploadValidate} = this.props;
        return (
            <React.Fragment>
                {state.ubos &&
                    state.ubos.map((k, i) => {
                        return (
                            <React.Fragment key={i}>
                                <FormRow>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormInputField
                                            validation={validation[i]}
                                            name="name"
                                            value={k.name}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "ubos", i)
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FromInputFormPercentage
                                            type="number"
                                            validation={validation[i]}
                                            name="percentage"
                                            value={k.percentage}
                                            onChange={e => {
                                                setField(e.target.name, e.target.value, "ubos", i)
                                            }}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                  <div className="col-12 col-md-6 mb-3">
                                    <FormFileUpload
                                        value={k.passport} 
                                        name="passport"
                                        validation={validation[i]}
                                        onChange={file => setField("passport", file, "ubos", i) } 
                                        onError={fileUploadValidate}
                                    />
                                  </div>
                                </FormRow>
                                <div className="text-right">
                                    {i !== 0 ? (
                                        <button type="button" className="btn btn-section-removed" onClick={e => remove(i)}>
                                            <FontAwesomeIcon icon={faTimes} /> Remove
                                        </button>
                                    ) : ''}
                                    {i === state.ubos.length - 1 ? (
                                        <button type="button" className="btn btn-add" onClick={e => add()}>
                                            <FontAwesomeIcon icon={faPlus} /> Add
                                        </button>
                                    ) : ''}

                                </div>
                            </React.Fragment>
                        );
                    })}
            </React.Fragment>
        );
    }
}
