import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const PriceBreakdownFormat = ({price, breakdown}) => {
    const BreakDownTooltip = withStyles(() => ({
        tooltip: {
            color: "hsl(0deg 0% 100%)",
            padding: "4px 8px",
            fontSize: "0.75rem",
            maxWidth: "300px",
            wordWrap: "break-word",
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontWeight: "500",
            lineHeight: "1.4em",
            borderRadius: "4px",
            backgroundColor: "hsl(0deg 0% 38% / 90%)"
        }
    }))(Tooltip);

    if(!breakdown) return price;
    return(
        <BreakDownTooltip title={breakdown} arrow interactive>
            <span>
                {price}&nbsp;
                <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} />
            </span>
        </BreakDownTooltip>
    )
}

export default PriceBreakdownFormat;