import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Preloader from '../../../../components/preloader/Preloader';
import Dropzone from 'react-dropzone';
import { FaRegFilePdf, FaRegFile} from 'react-icons/fa';
import FormTextareaField from '../../../../components/form/FormTextareaField';

class CreatePTDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            request: {}, 
            required: {},
            ptType: 'document'
        }

        this.CUSTOM_COMPONENT_STYLE = {
            wrapperClassName : "form-group",
            labelClassName :"label",
            inputClassName : "form-control",
            selectClassName : "custom-select",
            isInvalidClassName: "is-invalid input_error",
            isValidClassName: 'is-valid',
        }
    }

    get draftType(){
        return this.props.trade.payment === 'Letter of credit' ? 'L/C Draft' : this.props.trade.payment === 'Confirmed letter of credit' ? 'Confirmed L/C Draft' : 'Avalised Draft'
    }

    initField = (name, label, value, required, touched = true) => {
        this.setState( prevState => {
            return {
                ...prevState,
                request: {
                    ...prevState.request,
                    [name]: { name, label, value, required, touched }
                }
            }
        });
    }

    componentDidMount(){
        let isEmpty = value => !value || value === undefined || value === '';
        if(this.state.ptType === 'document'){
            this.initField('ptDoc', `Please Upload ${this.draftType}`, null, value => isEmpty(value) || value[0].type !== 'application/pdf', false);
        }else{
            this.initField('ptText', `Please Paste Your ${this.draftType}`, '', isEmpty, false);
        }
        this.initField('additionalInformation', 'Additional Information', '', false, false);
    }

    setClasses = (type) => {
        let classes = ['trades-dtls__tab'];
        if(type === this.state.ptType){
            classes.push('active')
        }
        return classes.join(' ');
    }

    validateField = (name, value) => {
        this.setState(prevState => {
            let request = { ...prevState.request };
            let required = { ...prevState.required };
            request[name].validated = true;

            if (request[name].required && request[name].required(value)) {
                required[name] = prevState.request[name];
            } else {
                delete required[name];
            }
            return { request, required }
        });
    }

    setField = (name, value) => {
        let request = { ...this.state.request };
        if(name) {
            request[name] = {
                ...request[name],
                value,
                touched: true
            }
            this.setState(prevState => {
                return {
                    ...prevState,
                    request
                }
            },() => {this.validateField(name, value)})
        }
    }

    ptTypeChangeHandler = (event, ptType) => {
        event.preventDefault();
        let isEmpty = value => !value || value === undefined || value === ''
        const request = { ...this.state.request };
        const required = { ...this.state.required }
        if(ptType === 'text'){
            delete request.ptDoc;
            delete required.ptDoc
            request.ptText = {
                name: 'ptText',
                label: `Please Paste Your ${this.draftType}`,
                value: '',
                touched: false,
                required: isEmpty
            }
        }else{
            delete request.ptText;
            delete required.ptText;
            request.ptDoc = {
                name: 'ptDoc',
                label: `Please Upload ${this.draftType}`,
                value: null,
                touched: false,
                required: value => isEmpty(value) || value[0].type !== 'application/pdf'
            }
        }
        this.setState({request, ptType, required})
    }

    validate = () => {
        let container = { ...this.state.request };
        let required = {};
        Object.keys(container).map(key => {
            let v = container[key];
            container[key].touched = true;
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
            }
            this.setState(prevState => {
                prevState.request[key].validated = true;
                return { ...prevState };
            })
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    onSubmitHandler = (event) => {
        event.preventDefault();
        if(this.validate()){
            let data = null
            if(this.state.ptType === 'document'){
                data = new FormData();
                data.append('upload', this.state.request.ptDoc.value[0]);
                data.append('remark', this.state.request.additionalInformation.value);
                data.append('additionalInformation', '');
                data.append('fcn', 'upload');
                if(this.props.trade.payment === 'Avalised Draft'){
                    data.append('adType', this.state.ptType);
                    data.append('docType', 'AVALIZED_DRAFT');
                }else if(this.props.trade.payment === 'Confirmed letter of credit'){
                    data.append('confirmedLcType', this.state.ptType);
                    data.append('docType', 'CONFIRMED_LETTER_CREDIT');
                }else{
                    data.append('lcType', this.state.ptType);
                    data.append('docType', 'LETTEROFCREDIT');
                }
            }else{
                data = {};
                let draftTextDetails = {
                    text: this.state.request.ptText.value,
                    additionalInformation: this.state.request.additionalInformation.value,
                    createdAt:  new Date().toISOString()
                }
                if(this.props.trade.payment === 'Avalised Draft'){
                    data.adText = [{ ...draftTextDetails}];
                    data.adType = this.state.ptType;
                }else if(this.props.trade.payment === 'Confirmed letter of credit') {
                    data.confirmedLcText = [{ ...draftTextDetails}];
                    data.confirmedLcType = this.state.ptType;
                }else{
                    data.lcText = [{ ...draftTextDetails}];
                    data.lcType = this.state.ptType;
                }
            }
            let url = `/trade/${this.props.trade.id}/letterOfCredit`;
            if(this.props.trade.payment === 'Avalised Draft') url = `/trade/${this.props.trade.id}/avalizedDraft`;
            if(this.props.trade.payment === 'Confirmed letter of credit') url = `/trade/${this.props.trade.id}/confirmedLC`
            this.props.createPTDoc(url, data, this.state.ptType, this.props.onCancel);
        }
    }

    render() { 
        const file = this.state.request.ptDoc ? this.state.request.ptDoc.value : null;
        return ( 
            <div className="modal__container">
                <div className="modal__wrapper px-0 py-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title w-100">
                                {this.state.ptType === 'document' ? `Upload ${this.draftType}` : `${this.props.trade.payment === 'Avalised Draft' ? 'Avalised Draft' : 'Draft of Letter of Credit(L/C)'}`}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onCancel} aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="trades-dtls__info">
                                <div className="trades-dtls__tabs">
                                    <a href="#/" className={this.setClasses('document')} onClick={(event) => this.ptTypeChangeHandler(event, 'document')}>
                                        Upload Draft
                                    </a>
                                    <a href="#/" className={this.setClasses('text')} onClick={(event) => this.ptTypeChangeHandler(event, 'text')}>
                                        Upload Text
                                    </a>
                                </div>
                            </div>
                            <form
                                className="trades-dtls__doc-wrapper trades-dtls__invoice-wrapper"
                                onSubmit={this.onSubmitHandler}
                                style={{padding: '32px'}}>
                                    {this.state.ptType === 'document' && <div className="form-group">
                                        <label className="label">
                                            {this.state.request.ptDoc ? this.state.request.ptDoc.label : ''}<span className="req-field">&nbsp;*</span>
                                        </label>
                                        <div className={`form-control pointer ${this.state.required.hasOwnProperty('ptDoc') ? 'input_error is-invalid' : this.state.request.ptDoc && this.state.request.ptDoc.touched ? 'is-valid' : ''}`}>
                                            <Dropzone
                                                className="input-group"
                                                activeClassName="upload__active"
                                                accept={'application/pdf'}
                                                onDrop={(filesAccept, filesNotAccept) => {
                                                    if(filesAccept && filesAccept.length !== 0){
                                                        this.setField('ptDoc', filesAccept);
                                                    }else{
                                                        this.setField('ptDoc', filesNotAccept);
                                                    } 
                                                }}
                                            >
                                                {file ? file[0].type === 'application/pdf' ? <FaRegFilePdf className="icon" /> : <FaRegFile className="icon" /> : null}
                                                {file ? <p className="file">{file[0].name}</p> : <p>Choose File (or Drop)</p>}
                                            </Dropzone>
                                        </div>
                                        <small className={Object(this.state.required).hasOwnProperty('ptDoc') ? 'trades-dtls__error-message' : ''}>
                                            * Only files with *.pdf extension are allowed.
                                        </small>
                                    </div>}
                                    {this.state.ptType === 'text' && <div className="form-group">
                                        <FormTextareaField
                                            name="ptText"
                                            type="text"
                                            value={this.state.request.ptText ? this.state.request.ptText : {}}
                                            onChange={(event) => this.setField(event.target.name, event.target.value)}
                                            rows="4"
                                            validation={this.state.required}
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}/>
                                    </div>}
                                    <div className="form-group">
                                        <FormTextareaField
                                            name="additionalInformation"
                                            type="text"
                                            value={this.state.request.additionalInformation ? this.state.request.additionalInformation : {}}
                                            onChange={(event) => this.setField(event.target.name, event.target.value)}
                                            rows="4" 
                                            validation={this.state.required}
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}/>
                                    </div>
                            </form>
                        </div>
                        <div className="modal-footer" >
                            <button className={`btn-popup btn-cancel`} type="button" onClick={this.props.onCancel} >
                                <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                            </button>
                            <button 
                                className={`btn-popup btn-submit`}  
                                type="button" 
                                onClick={this.onSubmitHandler}
                                disabled={this.props.loading}>
                                    <Preloader loadingStyle="dots" loading={this.props.loading}>
                                        <FontAwesomeIcon icon={faCheck} />&nbsp;Submit
                                    </Preloader>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default CreatePTDoc;