import Shipment from '../app/admin/trades/components/shipments/Shipment';

export const getShipmentList = (tradeMap, parentTrade, vesselNominationMap, shipmentMap, initialValues = {}) => {
    let res = [];
    // If the parent trade exist, all the vessel nominations of its childrens are added to the list
    if (parentTrade && parentTrade.childTradeRequestID) {
        if (parentTrade.childTradeRequestID.length === 0) {
            const parentVesselNomination = vesselNominationMap[parentTrade.id]
            const parentShipment = shipmentMap[parentTrade.id]

            // To display the shipment we check the existence of the multiple bill of lading
            if (parentShipment && parentShipment.list && Array.isArray(parentShipment.list) && parentShipment.list !== 0 && parentShipment.list[0] && parentShipment.list[0].Bill && parentShipment.list[0].Bill.quantCleanOnBoard) {
                for (let shipment of parentShipment.list) {
                    if (shipment.Bill && shipment.Bill.quantCleanOnBoard) {
                        res.push(new Shipment({ ...shipment.Bill, ref: parentTrade.tradeRefId }))
                    }
                }

            } else if (parentVesselNomination) {
                res.push(new Shipment({ ...parentVesselNomination, ref: parentTrade.tradeRefId }))
            }

        } else {
            parentTrade.childTradeRequestID.forEach(childId => {
                const childVesselNomination = vesselNominationMap[childId];
                const childShipment = shipmentMap[childId];
                const childTrade=tradeMap[childId];
                if(childTrade && childTrade.status==='CANCELED')return null;
                // To display the shipment we check the existence of the multiple bill of lading
                if (childShipment && childShipment.list && childShipment.list.length !== 0 && childShipment.list[0].Bill.quantCleanOnBoard) {
                    for (let shipment of childShipment.list) {
                        if (shipment.Bill && shipment.Bill.quantCleanOnBoard) {
                            res.push(new Shipment({ ...shipment.Bill, ref: childTrade.tradeRefId }))
                        }
                    }
                } else if (childVesselNomination && !childVesselNomination.pending) {
                    let shipment = new Shipment({ ...childVesselNomination, ref: childTrade.tradeRefId });
                    if(Object.keys(initialValues).length && initialValues.tradeRequestId === childVesselNomination.tradeRequestId ) {
                        shipment.status="UPDATING"
                    }
                    res.push(shipment)
                }
            })
        }

    }
    return res;
}