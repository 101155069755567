import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Preloader from '../../../../components/preloader/Preloader';
import DocumentShippingAdviceList from '../DocumentShippingAdviceList';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import RxNumberFormat from '../../../../components/Formatter/RxNumberFormat';
import { TRADE_STATUS_HUMANIZE } from '../../../../../services/service.values';

const ShippingAdviceConfirmation = ({
    error,
    bills,
    trade,
    tradeMap,
    requestInfo,
    shipmentMap,
    shipments,
    synthesis,
    onSubmit,
    onClose,
    loadingStatus
        
}) => {
    
    const [isLastChildTrade, setLastChildTrade] = useState();
    const [short, setShort] = useState();
    const [treshold, setTreshold] = useState();
    const [fulfilled, setFulfilled] = useState();
    const [isShippingAdviceComplete, setShippingAdvice] = useState();
    const [showInfQtyMsg, setInfQtyMsg] = useState();
    
    const shipment = shipmentMap[trade.id];
    
    const handleOnSubmit = () => {
        // WARNING: legacy API
        const text = JSON.stringify(shipments && shipments.map(shipment => ({
            text: document.getElementById(`shipment-advice-text-${shipment.id}`).innerText,
            shipmentId: shipment.id
        })))
        onSubmit({
            text, isLastChildTrade
        })
    }
    
    useEffect(() => {
        const _fulfilled = synthesis && synthesis.balance.toShip.max === 0;
        // RDEV-2063 Always ask the user if the shipping advice is the last one
        setShort(synthesis && synthesis.balance.toShip.min > 0/*  && synthesis.totalNominated === 0 */);
        setTreshold(synthesis && synthesis.balance.toShip.min === 0 && synthesis.balance.toShip.max > 0 /* && synthesis.totalNominated === 0 */);
        setFulfilled(_fulfilled);
        if(trade.incoterm==='FOB'){
            setLastChildTrade(false);
        }else if(_fulfilled){
            setLastChildTrade(true);
        }    
        let isShippingAdvice = true;
        const synthesisPartial = synthesis ? synthesis.partial : {};
        const childTrades = []
        const tradeStatus = Object.keys(TRADE_STATUS_HUMANIZE)
        Object.keys(synthesisPartial).forEach(it => {
            if (it !== trade.id) {
              childTrades.push(it)
          }
        });
        childTrades.forEach(it => {
            const tStatus = tradeMap[it] && tradeMap[it].status;
            if (tStatus) {
                for (let i=0; i<tradeStatus.length; i+=1) {
                    if (tradeStatus[i] === "ADVICE") {
                        break;
                    }
                    if (tradeStatus[i] === tStatus) {
                        isShippingAdvice = false;
                        break;
                    }
                }
            }
        })
        setShippingAdvice(isShippingAdvice)
    }, [synthesis])
    
    useEffect(() => {
        setInfQtyMsg(trade.partialShipmentFlag && isShippingAdviceComplete && (short || treshold))
    }, [isShippingAdviceComplete])
    
    return (
        <div className="modal__container">
            <div className="modal__wrapper  px-0 py-0">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-header-center"> <h5 className="modal-title">Shipping Advice</h5></div>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <DocumentShippingAdviceList
                            bills={bills}
                            trade={trade}
                            status={trade.status}
                            userName={requestInfo.sellerUserName}
                            isModal={true}
                            synthesis={synthesis}
                        />
                    </div>
                    <div className="modal-footer">
                        {/* Helper */}
                            <>
                                { /* Case of Short Shipment */}
                                {(trade.partialShipmentFlag && short && isShippingAdviceComplete && trade.incoterm !=='FOB') &&
                                    <>
                                    { isLastChildTrade === undefined &&
                                    <>
                                        <div>The total quantity shipped under this contract (<RxNumberFormat
                                                value={synthesis.totalShipped}
                                                displayType={'text'}
                                                suffix={`MT`}
                                            />) is inferior to the Min required contract tonnage (<RxNumberFormat
                                                value={synthesis.contractualMin}
                                                displayType={'text'}
                                                suffix={`MT`}
                                            />), do you want to keep this contract open for further shipments?</div>
                                        <div>
                                            <button className="btn btn-secondary mx-1"
                                                onClick={() => { setLastChildTrade(true) }}
                                                type="button">
                                                No
                                            </button>
                                            <button className="btn btn-primary mx-1"
                                                onClick={() => { setLastChildTrade(false) }}
                                                type="button">
                                                Yes
                                            </button>

                                        </div>
                                    </>
                                    }
                                    {isLastChildTrade === false &&
                                    <>
                                        <div>You may nominate a next shipment under VESSEL INFO/NOMINATE A NEXT VESSEL </div>
                                        {!synthesis.partialShipmentAllowed &&
                                        <div>
                                            Partial shipments are not allowed. Use “request contract amendment “ function to request the counter party to accept “partial shipments.
                                            Click CANCEL to go back to the previous screen or SUBMIT to submit the present nomination” 
                                        </div>}

                                    </>
                                    }
                                    {isLastChildTrade === true &&
                                    <div className="text-justify" style={{'fontSize': '1em'}}>
                                        The quantity shipped under this contract is&nbsp;<strong>
                                            <RxNumberFormat
                                                value={synthesis.balance.toShip.min}
                                                displayType={'text'}
                                                suffix={` MT`}
                                            /> of the min required tonnage </strong> including the contractual tolerance {!trade.partialShipmentFlag ? 'and partial shipment are not allowed' : ''}.
                                        <br/>
                                        <span>
                                            I
                                            <strong> "{requestInfo.sellerUserName}" </strong> herewith request 
                                            <strong> "{requestInfo.buyerUserName}"</strong> to accept the tonnage of&nbsp;
                                        </span>
                                        <RxNumberFormat
                                            value={synthesis.totalShipped}
                                            displayType={'text'}
                                            suffix={`MT`}
                                        />
                                        <span>&nbsp;as final and complete fulfillment of this contract.</span>
                                        <br/>
                                        Click 'CANCEL' to go back to the previous screen or 'SUBMIT' to submit the request.
                                    </div>
                                    }
                                    </>
                                }
                                { /* Case of treshold met but not exceeded */}
                                {(trade.partialShipmentFlag && treshold && isShippingAdviceComplete && trade.incoterm !=='FOB') &&
                                <>
                                    {isLastChildTrade === undefined && 
                                        <>
                                            <div>The total quantity shipped under this contract is between the Min and the Max required contract tonnage, do you want to keep this contract open for further shipments?</div>
                                            <div>
                                                <button className="btn btn-secondary mx-1"
                                                    onClick={() => { setLastChildTrade(true) }}
                                                    type="button">
                                                    No
                                                </button>
                                                <button className="btn btn-primary mx-1"
                                                    onClick={() => { setLastChildTrade(false) }}
                                                    type="button">
                                                    Yes
                                                </button>
                                            </div>
                                        </>
                                    }
                                    {isLastChildTrade === false &&
                                        <div>You may nominate a next shipment under VESSEL INFO/NOMINATE A NEXT VESSEL </div>
                                    }
                                    {isLastChildTrade === true &&
                                    <div className="text-justify" style={{'fontSize': '1em'}}>
                                        <span>
                                        The total quantity shipped under this contract is&nbsp;<strong>
                                         between the Min and the Max</strong> required contractual tonnage {!trade.partialShipmentFlag ? 'and partial shipment are not allowed' : ''}.
                                        </span>
                                        <br/>
                                        <span>
                                            I
                                            <strong> "{requestInfo.sellerUserName}" </strong> herewith request  
                                            <strong> "{requestInfo.buyerUserName}"</strong> to accept the tonnage of&nbsp;
                                        </span>
                                        <RxNumberFormat
                                            value={synthesis.totalShipped}
                                            displayType={'text'}
                                            suffix={`MT`}
                                        />
                                        <span>&nbsp;as final and complete fulfillment of this contract.</span>
                                        <br/>
                                        Click 'CANCEL' to go back to the previous screen or 'SUBMIT' to submit the request.
                                    </div>
                                    }
                                </>
                                }
                                { /* Case of shipment fulfilled / Last shipment */}
                            {(isLastChildTrade !== undefined || !trade.partialShipmentFlag || (isLastChildTrade === undefined && !showInfQtyMsg)) &&
                                
                                <div className="w-100">
                                {error && <div className="text-danger my-2"><strong>Error : </strong>{error}</div>}
                                    <button type="button"
                                        className="btn btn-secondary mx-1"
                                        onClick={onClose}>
                                            <FontAwesomeIcon icon={faTimes} /> Cancel
                                    </button>
                                    <button type="button"
                                        style={{minHeight: '33.1px'}}
                                        className="btn btn-primary mx-1"
                                        onClick={ handleOnSubmit}>                             
                                            <Preloader loadingStyle="dots" loading={loadingStatus}>
                                                <><FontAwesomeIcon icon={faCheck} /> Submit</>
                                            </Preloader>
                                    </button>
                                </div>
                                }
                            </>                    
                    </div>
                </div>
            </div>       
        </div>
    )

}

const mapStateToProps = state => {
    return {
        bills: state.trade.bills,
        trade: state.trade.items.single,
        tradeMap: state.trade.items.tradeMap,
        requestInfo: state.trade.requestInfo,
        shipments: state.trade.shipments,
        shipmentMap: state.trade.shipmentMap,
        synthesis: state.shipment.synthesis,
        loadingStatus: state.loading.loadingStatus,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAdviceConfirmation);

            

