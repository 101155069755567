import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Preloader from '../../../../components/preloader/Preloader';


export const submitBankDetailsModal = ({onCancel, onSubmit, loading}) => {
    return(
        <div className="modal__container">
            <div className="modal__wrapper px-0 py-0">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100">Submit Bank Details</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={onCancel} aria-label="Close">
                            <span aria-hidden="true" >&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            You are about to submit your bank details to a seller for an offer that you would like to accept. If seller accepts your bank details you are entering into a legally binding trade.
                            By clicking SUBMIT you are confirming that you accept all the terms and conditions of the trade. Click SUBMIT to confirm or BACK to return to the previous screen.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className={`btn-popup btn-cancel`} type="button" onClick={onCancel} >
                            <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                        </button>
                        <button className={`btn-popup btn-submit`}  type="button" onClick={onSubmit} >
                            <Preloader loadingStyle="dots" loading={loading}>
                                <FontAwesomeIcon icon={faCheck} />&nbsp;Submit
                            </Preloader>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}