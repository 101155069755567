import React, { Component } from 'react';
import FormInputGroupField from '../../../components/form/FormInputGroupField';
import FormInputField from '../../../components/form/FormInputField';
import FormInputNumberFormat from '../../../components/form/FormInputNumberFormat';
import FormSelectDropdown from '../../../components/form/FormSelectDropdown';
import FormSelectDropdownList from '../../../components/form/FormSelectDropdownList';
import FormTextareaField from '../../../components/form/FormTextareaField';
import FormDateField from '../../../components/form/FormDateField';
import FormUnitsInputField from '../../../components/form/FormUnitsInputField';
import FormMultipleOriginPrice from '../../../components/form/FormMultipleOriginPrice';
import FormPriceBreakdown from '../../../components/form/FormPriceBreakdown';
import FormAsyncSelectPort from '../../../components/form/FormAsyncSelectPort';
import scrollToElement from 'scroll-to-element';
import moment from 'moment-timezone';
import ReactGA from 'react-ga';
import omitBy from 'lodash/omitBy';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { DocumentFactory } from '../../trades/services/documents.service';

//ACTIONS
import {
    preloadInspectionCompanies,
} from '../../../../modules/module.trade';
import { EnumsService, getLocalTimezone } from '../../../../services/service.utils';
import { RiceService } from '../../../../services/service.rice';
import MessageNonIntegratedInspector from '../../trades/modals/MessageNonIntegratedInspector';
import { filterInspectionCompany } from '../../../../services/service.utils';

//CSS
import './CounterProposal.scss';

//values
import {
    DATEFORMATHOURS,
    DATEFORMAT,
    QUALITYSTANDARD,
    PAYMENTTERMSOPT,
    PAYMENTPERIODOPT,
    DOWNPAYMENTPERIODOPT,
    INCOTERMOPT,
    DISCHARGEFOB,
    DISCHARGECIF,
    SHIPPINGTYPES,
    PACKAGING,
    CURRENCYOPT,
    CROP_YEARS,
    RICECATEGORY,
    getNonIntegratedInspector,
    TOOLTIP_TITLE,
    TOLERANCE
} from '../../../../services/service.values';
import FormRadioFieldTrueFalse from '../../../components/form/FormRadioFieldTrueFalse';
import FormRadioField from '../../../components/form/FormRadioField';

import DocumentSelection from './../create/documentSelection/DocumentSelection';

const Countries = EnumsService.countries();
const errorMessage = 'Before clicking on "REVIEW", please confirm insertion of your document or click on cancel';


class CounterProposal extends Component {
    FORM_CREATE_WRAPPER_CLASS = "col-sm-6 col-md-4 col-lg-3";
    state = {
        request: {},
        required: {},
        initiated: false,
        showInsuranceTerms: false,
        showModal:false     
    }

    constructor(props) {
        super(props)
        this.onReview = this.onReview.bind(this);
        this.validate = this.validate.bind(this);
        preloadInspectionCompanies();

        this.type = props.bid.type === "FIRST_OFFER" ? "offer" : "counter";

        this.CUSTOM_COMPONENT_STYLE = {
            wrapperClassName: "form-group",
            labelClassName: "label",
            inputClassName: "form-control",
            selectClassName: "custom-select",
            radioColLayout: { left: "col-12", right: "col-12" },
            isInvalidClassName: "is-invalid select_error",
            isValidClassName: 'is-valid',
        }
    }

    get isTenderWithMultiOrigin() {
        return this.props.trade.privacyType === 'TENDER' && this.props.trade.origin.split(',').length > 1
    }

    componentDidMount() {
        this.initializeForm(this.props.bid);
    }
    
    isEmpty = value => !value || value === undefined || value === '';
    isEmptyOrNegativePrice = value => this.isEmpty(value) || value === '-' || parseFloat(value) <= 0;
    isRiceTypeOtherInvalid = (value) => this.state.request.riceType.value === RiceService.enums.REF_OTHER && this.isEmpty(value);

    multiOriginPricesWithValidation = (prices = [], initializing=false) => {
        const incoterm = initializing ? this.props.bid.incoterm : this.state.request.incoterm.value;
        const isIncotermFOB = incoterm === 'FOB'
        let atLeastOnePriceChecked = false;
        for(let i = 0; i < prices.length; i++){
            if(prices[i].isPriceCheck){
                atLeastOnePriceChecked = true;
                break;
            }
        }
        if(atLeastOnePriceChecked){
            return prices.map((item,index,prices) => {
                let updatedItem = {...item};
                let priceInvalid = false;
                let countryInvalid = false;
                let portInvalid = false;
                let fobInvalid = false;
                let freightInvalid = false;
                let insuranceInvalid = false;
                let validateupdateprice = false;
                let isUpdatepricegreater = false;
                
               
                for (let i = 0; i < prices.length; i++) {
                    if (this.props.bid.reCounterInd && parseFloat(prices[i].price)) {
                        const prevPriceObj = this.props.previousBid.multipleOriginPrice.find(prevData => prevData.origin === prices[i].origin);
                        validateupdateprice = (prevPriceObj.isPriceCheck && this.props.trade.requestType === "BUY") ?
                            parseFloat(prices[i].price) >= prevPriceObj.price :
                            parseFloat(prices[i].price) <= prevPriceObj.price;
                    }
                    if (!validateupdateprice && parseFloat(prices[i].price)) {
                        validateupdateprice = false;
                        break;
                    }
                }
                if(updatedItem.isPriceCheck){
                    const isPriceEmpty = this.isEmptyOrNegativePrice(item.price);
                    let validImprovedPrice = false;
                    if(this.props.bid.reCounterInd){
                        const prevPriceObj = this.props.previousBid.multipleOriginPrice.find(prevData => prevData.origin === updatedItem.origin);
                        validImprovedPrice = (prevPriceObj.isPriceCheck && this.props.trade.requestType === "BUY") ? 
                                                parseFloat(updatedItem.price) >= prevPriceObj.price :  
                                                parseFloat(updatedItem.price) <= prevPriceObj.price;
                    }
                    if(this.props.bid.reCounterInd){
                        const prevPriceObj = this.props.previousBid.multipleOriginPrice.find(prevData => prevData.origin === updatedItem.origin);
                        isUpdatepricegreater = (prevPriceObj.isPriceCheck && this.props.trade.requestType === "BUY") ? 
                                                parseFloat(updatedItem.price) > prevPriceObj.price :  
                                                parseFloat(updatedItem.price) < prevPriceObj.price;
                    }

                    priceInvalid = isPriceEmpty || validImprovedPrice;
                    countryInvalid = isIncotermFOB && this.isEmpty(updatedItem.loadCountry);
                    portInvalid = isIncotermFOB && this.isEmpty(updatedItem.loadPort);

                    if(this.props.doPriceBreakdown){
                        if(isIncotermFOB || incoterm === false){
                            fobInvalid = (priceInvalid && validateupdateprice) || isUpdatepricegreater || isPriceEmpty;
                        }else{
                            fobInvalid = this.isEmptyOrNegativePrice(item.priceBreakdown.fob);
                            freightInvalid = this.isEmptyOrNegativePrice(item.priceBreakdown.freight);
                            priceInvalid = priceInvalid || fobInvalid || freightInvalid;
                            if(incoterm === 'CIF'){
                                insuranceInvalid = this.isEmptyOrNegativePrice(item.priceBreakdown.insurance);
                                priceInvalid =  priceInvalid || insuranceInvalid;
                            }
                        }
                    }
                }
                
                updatedItem.isInvalidPrice = ((priceInvalid && validateupdateprice) || isUpdatepricegreater )  
                updatedItem.isInvalidLoadCountry = countryInvalid;
                updatedItem.isInvalidLoadPort = portInvalid;
                updatedItem.isInvalidFob = fobInvalid ;  
                updatedItem.isInvalidFreight = freightInvalid;
                updatedItem.isInvalidInsurance = insuranceInvalid;
                return updatedItem;
            })
        }else{
            return prices.map(item =>({
                ...item, 
                isInvalidPrice: true, 
                isInvalidLoadCountry: isIncotermFOB,
                isInvalidLoadPort: isIncotermFOB,
                ...(this.props.doPriceBreakdown ? {
                    isInvalidFob: true,
                    isInvalidFreight: !isIncotermFOB,
                    isInvalidInsurance: incoterm === "CIF"
                } : {})
            }))
        }
    }

    initializeForm(trade) {

        const options = true;

        //Initialize destination
        let toCompanyID = "";
        if (trade.ownerId) {
            toCompanyID = trade.ownerId;
        } else if (trade.fromCompanyID) {
            toCompanyID = trade.fromCompanyID;
        } else {
            //Edit review
            toCompanyID = trade.toCompanyID;
        }

        this.initField('toCompanyID', 'To Company', toCompanyID, this.isEmpty);
        //Initilize type 'FIRST_OFFER' or 'COUNTER'
        this.initField('type', 'type', this.props.bid.type, false);

        /* STEP 1 */
        const requestType = this.props.trade.requestType || '';
        this.initField('requestType', 'Request Type', requestType, this.isEmpty);
        const currency = trade.currency || "US$";
        this.initField('currency', '', currency, this.isEmpty);

        //price breakdown initialization
        if(this.props.doPriceBreakdown && !this.isTenderWithMultiOrigin){
            const isInvalidFOBPrice = value => {
                if(["FOB", false].includes(this.state.request.incoterm.value)){
                    return isInvalidPrice(value);
                }
                return this.isEmptyOrNegativePrice(value);
            }
            const priceBreakdown = trade.priceBreakdown || {}
            const fob = priceBreakdown.fob || '';
            this.initField('fob', 'FOB', fob, isInvalidFOBPrice);

            const isInvalidFreightPrice = value => ["CFR", "CIF"].includes(this.state.request.incoterm.value) && this.isEmptyOrNegativePrice(value);
            const freight = priceBreakdown.freight || '';
            this.initField('freight', 'Freight', freight, isInvalidFreightPrice);

            const isInvalidInsurancePrice = value => this.state.request.incoterm.value === "CIF" && this.isEmptyOrNegativePrice(value);
            const insurance = priceBreakdown.insurance || '';
            this.initField('insurance', 'Insurance', insurance, isInvalidInsurancePrice)
        }

        //trade price initialization
        const isInvalidPrice = value => {
            if(!this.isTenderWithMultiOrigin){
                const defaultValidation = this.isEmptyOrNegativePrice(value);
                let invalidBreakdown = false;
                if(this.props.doPriceBreakdown && !["FOB", false].includes(this.state.request.incoterm.value)){
                    const invalidFOB = this.isEmptyOrNegativePrice(this.state.request.fob.value);
                    const invalidFreight = this.isEmptyOrNegativePrice(this.state.request.freight.value);
                    if(this.state.request.incoterm.value === "CIF"){
                        invalidBreakdown = invalidFOB || invalidFreight ||  this.isEmptyOrNegativePrice(this.state.request.insurance.value)
                    }else{
                        invalidBreakdown = invalidFOB || invalidFreight;
                    }
                }
                if(!trade.reCounterInd){
                    return defaultValidation || invalidBreakdown;
                }
                const requestTypeValidation = (this.props.previousBid && this.props.trade.requestType === "BUY") ? 
                                            parseFloat(value) >= this.props.previousBid.price :  
                                            parseFloat(value) <= this.props.previousBid.price;
                return defaultValidation || invalidBreakdown || requestTypeValidation;
            }
            return false;  
        }
        let price = '';
        if(this.props.doPriceBreakdown && !this.isTenderWithMultiOrigin){
            if(trade.priceBreakdown){
                price = trade.priceBreakdown.fob + (
                    trade.incoterm === "CFR" ? trade.priceBreakdown.freight : trade.incoterm === "CIF" ? 
                                        (trade.priceBreakdown.freight + trade.priceBreakdown.insurance) 
                                    : 0) 
            }
        }else{
            price = trade.price || ''
        }
        this.initField('price', 'Your Price per MT', price, isInvalidPrice);

        //tender multi origin price initialization 
        if (this.isTenderWithMultiOrigin) {
            let initialData = [];
            if(trade.multipleOriginPrice){
                initialData = trade.multipleOriginPrice.map(item => {
                    return {
                        ...item, 
                        price: item.price || '',
                        priceBreakdown: this.props.doPriceBreakdown ? 
                        Object.entries(item.priceBreakdown).reduce((obj, entry) => {
                            obj[entry[0]] = entry[1] !== 0 ? entry[1] : '';
                            return obj;
                        },{}) : null
                    }
                })              
            }else{
                initialData = trade.origin.split(',').map(item => ({
                    origin: item, 
                    price: '', 
                    isPriceCheck: false, 
                    loadCountry: '', 
                    loadPort: '',
                    priceBreakdown: this.props.doPriceBreakdown ? {fob: '', freight: '', insurance: ''} : null
                }));
            }
            const withValidations = this.multiOriginPricesWithValidation(initialData, true);
            this.initField('multipleOriginPrice', 'Your Price per MT', withValidations, value => {
                let isInvalid = false;
                if (this.isTenderWithMultiOrigin) {
                    for(let i = 0; i < value.length; i++){
                        const defaultValidation = value[i].isInvalidPrice || value[i].isInvalidLoadCountry || value[i].isInvalidLoadPort;
                        const breakdownValidation = value[i].isInvalidFob || value[i].isInvalidFreight || value[i].isInvalidInsurance;
                        if( defaultValidation || breakdownValidation){
                            isInvalid = true;
                            break;
                        }
                    }
                }
                return isInvalid;
            });
        }
        const measurement = trade.measurement || 'TONS';
        this.initField('measurement', '', measurement, this.isEmpty);
        const measure = trade.measure || "";
        this.initField('measure', 'Quantity (expressed in MT)', measure, this.isEmpty);

        this.initField('bidValidateDate', 
            `Validity of your ${this.type.charAt(0).toUpperCase() + this.type.substr(1).toLowerCase()} (${getLocalTimezone()})`, 
            (trade.type==="FIRST_OFFER" && this.props.trade.privacyType === "TENDER") ? moment(trade.bidValidateDate || trade.validateDate ) : undefined,  
            value => {
                if(trade.reCounterInd){
                    return this.isEmpty(value) || moment(trade.bidValidateDate).diff(value) > 0
                }
                return this.isEmpty(value) || moment().diff(value) > 0 
            });
        const euroConversionAvl = trade.euroConversionAvl || false;
        this.initField('euroConversionAvl', 'Euro Conversion Available', (euroConversionAvl === true), false);
        const standardContract = trade.standardContract ? trade.standardContract : true;
        this.initField('standardContract', 'Standard Contract', standardContract, false);


        /* STEP 2 */
        const shipping = trade.shipping || '';
        this.initField('shipping', 'Shipping Type', shipping, this.isEmpty);
        const origin = trade.origin.split(',');
        this.initField('origin', 'Origin', origin, (value) => {
            if (value) {
                if (this.props.trade.privacyType === "TENDER") {
                    return value.length === 0;
                } else {
                    return value.length === 0 || value.length > 1;
                }
            }
            return false;
        });
        const riceType = trade.riceType || "";
        this.initField('riceType', 'Type of Rice', riceType, this.isEmpty);
        const riceTypeOther = trade.riceTypeOther || "";
        this.initField('riceTypeOther', 'Specify Rice Type', riceTypeOther, this.isRiceTypeOtherInvalid);
        const riceCategory = trade.riceCategory || "";
        this.initField('riceCategory', 'Category of Rice', riceCategory, this.isEmpty);
        const quality = trade.quality || 'Standard';
        this.initField('quality', 'Quality Standard (Base Price)', quality, this.isEmpty);
        const cropYear = trade.cropYear;
        this.initField('cropYear', 'Crop Year', cropYear, this.isEmpty);
        const incoterm = trade.incoterm;
        this.initField('incoterm', this.props.doPriceBreakdown ? 'Select Your Incoterm' : 'Incoterms', incoterm, this.isEmpty);

        const initialLoadCountryPortValidation = trade.incoterm === 'FOB' && !this.isTenderWithMultiOrigin ? this.isEmpty : false;
        const loadCountry = trade.loadCountry;
        this.initField('loadCountry', 'Country of Load', loadCountry, initialLoadCountryPortValidation);
        const loadPort = trade.loadPort;
        this.initField('loadPort', 'Port of Load', loadPort, initialLoadCountryPortValidation);
        const destCountry = trade.destCountry;
        const invalidDestCountry = (trade.incoterm === 'CIF' || trade.incoterm === 'CFR') ? this.isEmpty : false;
        this.initField('destCountry', 'Destination', destCountry, invalidDestCountry);
        const invalidDestPort = (trade.incoterm === 'CIF' || trade.incoterm === 'CFR') ? this.isEmpty : false;
        const destPort = trade.destPort;
        this.initField('destPort', 'Port of Destination', destPort, invalidDestPort);

        const insuranceRequired = trade.insuranceRequired || false;
        this.initField('insuranceRequired', 'Do You want a Ricex Dedicated Insurance Quote?', insuranceRequired, false);
        const destinationZone = trade.destinationZone;
        const destinationZoneInvalid = insuranceRequired === "true" ? this.isEmpty : false;
        this.initField('destinationZone', 'Select Destination Zone', destinationZone, destinationZoneInvalid);
        const typeOfCover = trade.typeOfCover;
        const typeOfCoverInvalid = insuranceRequired === "true" ? this.isEmpty : false;
        this.initField('typeOfCover', 'Select the Type of Cover', typeOfCover, typeOfCoverInvalid);

        /* STEP 3 */
        const deliveryStartDate = trade.deliveryStartDate;
        this.initField('deliveryStartDate', 'Shipping / Delivery Period (from)', moment(deliveryStartDate), this.isEmpty);
        const deliveryEndDate = trade.deliveryEndDate;
        this.initField('deliveryEndDate', 'Shipping / Delivery Period (to)', moment(deliveryEndDate), this.isEmpty);
        const packaging = trade.packaging || ''
        if (PACKAGING.hasOwnProperty(packaging)) {
            this.initField('packaging', 'Packaging (Base Price)', packaging, this.isEmpty);
            this.initField('packagingOther', 'Packaging', "", (value) => { return this.state.request.packaging.value === 'OTHER' && value === "" });
        } else {
            this.initField('packaging', 'Packaging (Base Price)', 'OTHER', this.isEmpty);
            this.initField('packagingOther', 'Packaging', packaging, (value) => { return this.state.request.packaging.value === 'OTHER' && value === "" });
        }
        const inspection = trade.inspection || '';
        this.initField('inspection', 'Inspection Company', inspection, this.isEmpty);
        const inspectionOther = trade.inspectionOther || '';
        this.initField('inspectionOther', `Select Other Inspector`, inspectionOther, (value) => { return this.state.request.inspection.value === "1" && this.isEmpty(value) });
        const discharge = trade.discharge || '';
        this.initField('discharge', 'Load & Discharge Terms', discharge, (value) => (this.state.request.shipping.value === "VESSEL" && this.isEmpty(value)));
        const dischargeRate = trade.dischargeRate;
        const dischargeField = this.initDischargeRate(discharge, dischargeRate);
        this.initField(dischargeField.name, dischargeField.label, dischargeField.value, dischargeField.required);

        const optionalDocuments = trade.optionalDocuments || [];
        this.initField("optionalDocuments", 'Optional Documents', optionalDocuments);
        const partialShipmentFlag = trade.partialShipmentFlag || false;
        this.initField('partialShipmentFlag', 'Partial Shipment Allowed', partialShipmentFlag, false, 'step3');

        /* STEP 4*/
        const payment = trade.payment || "";
        this.initField('payment', 'Payment Terms', payment, this.isEmpty);
        const paymentPeriod = trade.paymentPeriod || '';
        this.initField('paymentPeriod', 'Payment Period', paymentPeriod, this.isEmpty);

        // RDEV-1813
        const LCOpeningDate = trade.LCOpeningDate ? moment(trade.LCOpeningDate) : undefined;
        this.initField('LCOpeningDate', 'Letter of Credit to be Opened Latest On', LCOpeningDate, value => {
            return (this.state.request.payment.value === 'Letter of credit' && this.isEmpty(value))
        });

        const ADIssuingDate = trade.ADIssuingDate ? moment(trade.ADIssuingDate) : undefined;
        this.initField('ADIssuingDate', 'Avalised Draft to be issued latest on', ADIssuingDate, value => {
            return (this.state.request.payment.value === 'Avalised Draft' && this.isEmpty(value))
        });

        let nameOfBankInvalid = (value) => {
            return this.props.pov === "BUYER" &&
                this.state.request.payment.value !== "CAD" &&
                this.isEmpty(value)
        };
        const nameOfBank = trade.nameOfBank || '';
        this.initField('nameOfBank', "Name of Buyer's Bank", nameOfBank, nameOfBankInvalid);
        const locationOfBank = trade.locationOfBank || "";
        this.initField('locationOfBank', "Location of Buyer's Bank", locationOfBank, nameOfBankInvalid);
        const swiftCodeOfBank = trade.swiftCodeOfBank || "";
        this.initField('swiftCodeOfBank', "Swiftcode of Buyer's Bank", swiftCodeOfBank, nameOfBankInvalid);

        let confirmingBankInvalid = (value) => {
            return this.state.request.payment.value === "Confirmed letter of credit"
                && this.props.pov === "BUYER"
                && this.isEmpty(value)
        };

        const nameOfConfirmingBank = trade.nameOfConfirmingBank || "";
        this.initField('nameOfConfirmingBank', 'Name of Confirming Bank', nameOfConfirmingBank, confirmingBankInvalid);
        const locationOfConfirmingBank = trade.locationOfConfirmingBank || "";
        this.initField('locationOfConfirmingBank', 'Location of Confirming Bank', locationOfConfirmingBank, confirmingBankInvalid);
        const swiftCodeOfConfirmingBank = trade.swiftCodeOfConfirmingBank || "";
        this.initField('swiftCodeOfConfirmingBank', 'Swiftcode of Bank', swiftCodeOfConfirmingBank, confirmingBankInvalid);

        const downPaymentRequired = trade.downPaymentPercentage ? true : false;
        this.initField('downPaymentRequired', 'DownPayment Required', downPaymentRequired, false);
        const downPaymentPercentage = trade.downPaymentPercentage || '';
        let downPaymentInvalid = (value) => {
            return this.state.request.downPaymentRequired.value &&
                (this.isEmpty(value) || parseFloat(value) < 0 || parseFloat(value) > 100)
        };
        this.initField('downPaymentPercentage', 'Down Payment (%)', downPaymentPercentage, downPaymentInvalid);

        const downPaymentPeriod = trade.downPaymentPeriod || '';
        let downPaymentPeriodInvalid = (value) => { return this.state.request.downPaymentRequired.value && this.isEmpty(value) }
        this.initField('downPaymentPeriod', 'Down Payment to be effected within', downPaymentPeriod, downPaymentPeriodInvalid);

        const specialRequest = trade.specialRequest || "";
        this.initField("specialRequest", "Special Request", specialRequest, false );

        const tolerance = (trade.tolerance !== undefined) ? `${trade.tolerance}` : ''
        const tolerancePreset = (trade.tolerance !== undefined) ? TOLERANCE[tolerance] ? tolerance : 'OTHER' : '';
        this.initField('tolerancePreset', `Contractual Tolerance`, tolerancePreset, this.isEmpty);
        this.setState(prev => ({
            request: {
                ...prev.request,
                tolerance: {
                    touched: false,
                    validated: false,
                    step: "step1",
                    name: 'tolerance',
                    label: `Other`,
                    value: tolerance,
                    required: value => {
                        return (this.state.request.tolerancePreset.value === "OTHER" && ["","0","5","10"].includes(value))
                    },
                    step: 'step1',
                    visible: tolerancePreset === 'OTHER'
                }
            }
        }));
        
        // OPTIONS
        if (options) {
            const optionsPackagingType = trade.optionsPackagingType || '';
            if (PACKAGING.hasOwnProperty(optionsPackagingType) || optionsPackagingType === "") {
                this.initField('optionsPackagingType', 'Packaging (Option)', optionsPackagingType, false);
                this.initField('optionsPackagingTypeOther', 'Specify', "", (value) => { return this.state.request.optionsPackagingType.value === 'OTHER' && value === "" });
            } else {
                this.initField('optionsPackagingType', 'Packaging (Option)', 'OTHER', false);
                this.initField('optionsPackagingTypeOther', 'Specify', optionsPackagingType, (value) => { return this.state.request.optionsPackagingType.value === 'OTHER' && value === "" });
            }
            const optionsPackagingTypePremOrDiscount = trade.optionsPackagingTypePremOrDiscount;
            this.initField('optionsPackagingTypePremOrDiscount', 'Premium / Discount', optionsPackagingTypePremOrDiscount, (value) => (this.state.request.optionsPackagingType.value && value === "" && this.props.trade.privacyType !== "TENDER"));
            const optionsQualityType = trade.optionsQualityType;
            this.initField('optionsQualityType', 'Quality Standard ( Option )', optionsQualityType, false);
            const optionsQualityTypePremOrDiscount = trade.optionsQualityTypePremOrDiscount;
            this.initField('optionsQualityTypePremOrDiscount', 'Premium / Discount', optionsQualityTypePremOrDiscount, (value) => (this.state.request.optionsQualityType.value && value === "" && this.props.trade.privacyType !== "TENDER"));
            const optionsTypeOfRice = trade.optionsTypeOfRice;
            this.initField('optionsTypeOfRice', 'Type of Rice (Option)', optionsTypeOfRice, false);
            const optionsTypeOfRicePremOrDiscount = trade.optionsTypeOfRicePremOrDiscount;
            this.initField('optionsTypeOfRicePremOrDiscount', 'Premium / Option', optionsTypeOfRicePremOrDiscount, (value) => (this.state.request.optionsTypeOfRice.value && value === "" && this.props.trade.privacyType !== "TENDER"));
            const optionsShippingType = trade.optionsShippingType;
            this.initField('optionsShippingType', 'Shipping Type (Option)', optionsShippingType, false);
            const optionsShippingTypePremOrDiscount = trade.optionsShippingTypePremOrDiscount;
            this.initField('optionsShippingTypePremOrDiscount', 'Premium / Option', optionsShippingTypePremOrDiscount, (value) => (this.state.request.optionsShippingType.value && value === "" && this.props.trade.privacyType !== "TENDER"));
        }

        let showInsuranceTerms = false;
        if (shipping === 'CONTAINER') {
            if (requestType === 'SELL' && incoterm === INCOTERMOPT.CIF) {
                showInsuranceTerms = true;
            }
            if (requestType === 'BUY' && [INCOTERMOPT.CFR, INCOTERMOPT.FOB].includes(incoterm)) {
                showInsuranceTerms = true;
            }
        }

        this.setState({
            initiated: true,
            showInsuranceTerms: showInsuranceTerms
        }, () => { this.validate() });

    }

    initField(name, label, value, required, touched = true) {
        this.setState(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                [name]: {
                    name: name,
                    label: label,
                    required: required,
                    touched: touched,
                    value: value,
                    disabled: false
                }
            }
        }));
    }

    disabledOriginsWhileImprovingPrice = () => {
        if(this.isTenderWithMultiOrigin && this.props.bid.reCounterInd){
            return this.props.previousBid.multipleOriginPrice.reduce((origins, item) => {
                if(item.isPriceCheck) origins.push(item.origin);
                return origins;
            }, [])
        }
        return [];
    }

    multiOriginPriceChangeHandler = (key, value, origin, dependentFields={}) => {
        let multiOriginPrices = this.state.request.multipleOriginPrice.value.map(item => ({...item}));
        let updatedPrices = multiOriginPrices.map(item => {
            if(item.origin === origin){
                return {
                    ...item, 
                    [key]: value,
                    ...dependentFields
                }
            }
            return {...item}
        });
        let updatedPriceWithValidation = this.multiOriginPricesWithValidation(updatedPrices, false);
        this.setField('multipleOriginPrice', updatedPriceWithValidation);
    }

    priceBreakdownChangeHandler = (origin, name, value, changeLoadCountry, breakDown) => {
        if(!this.isTenderWithMultiOrigin){
            this.setField(name, value);
            if(name === 'loadCountry'){
                this.setField('loadPort', '');
            }
            if(name === 'loadPort' && changeLoadCountry){
                this.setField('loadCountry', value.slice(0, 2))
            }
        }else{
            switch(name){
                case "isPriceCheck":
                    this.multiOriginPriceChangeHandler(
                        name, 
                        value, 
                        origin, 
                        {
                            price: '', 
                            loadCountry: '', 
                            loadPort: '', 
                            priceBreakdown: {fob: '', freight: '', insurance: ''}
                        }
                    );
                    break;
                case "loadCountry":
                    this.multiOriginPriceChangeHandler(
                        name, 
                        value, 
                        origin, 
                        {loadPort: ''}
                    );
                    break;
                case "loadPort":
                    this.multiOriginPriceChangeHandler(
                        name, 
                        value, 
                        origin, 
                        changeLoadCountry ? {loadCountry: value.slice(0, 2)} : {}
                    );
                    break;
                case 'fob':
                case 'freight':
                case 'insurance':
                    const totalPrice = Object.entries(breakDown).reduce((sum, entry) => {
                        const valueToAdd = entry[0] === name ? value : entry[1];
                        sum += valueToAdd ? parseFloat(valueToAdd) : 0;
                        return sum;
                    }, 0);

                    const priceBreakdown = {...breakDown, [name]: value};
                    this.multiOriginPriceChangeHandler('priceBreakdown', priceBreakdown, origin, {price: +totalPrice.toFixed(2)});
                    break;
                default:
                    break;
            }
        }
    }

    setField = (name, value) => {
        const updateRequest = (args) => this.setState(args, () => this.validate())
        switch (name) {
            case 'bidValidateDate':
            case 'deliveryStartDate':
            case 'deliveryEndDate':
                if (typeof value === 'object' && value !== null) {
                    let request = this.state.request;
                    request[name] = {
                        ...request[name],
                        value: value
                    };

                    updateRequest({
                        required: {},
                        request: request
                    });
                }
                break;
            case 'origin':
                this.setState(prevState => {
                    let updatedRequest = { ...prevState.request };
                    if (updatedRequest.incoterm.value === 'FOB') {
                        if (value.length === 1 && (!updatedRequest.loadCountry.value || !updatedRequest.loadPort.value)) {
                            updatedRequest.loadCountry.value = value[0];
                            updatedRequest.loadPort.value = '';
                            updatedRequest.loadCountry.required = this.isEmpty;
                            updatedRequest.loadPort.required = this.isEmpty;
                        } else {
                            updatedRequest.loadCountry.required = this.isEmpty;
                            updatedRequest.loadPort.required = this.isEmpty;
                        }
                    }
                    updatedRequest.origin.value = value;
                    return { request: updatedRequest }
                }, () => { this.validate() });
                break;
            case 'incoterm': {
                let request = { ...this.state.request };
                const firstOfferWithFOB = this.props.bid.type === "FIRST_OFFER" && [this.props.trade.incoterm, this.props.trade.optionalIncoterm].includes("FOB");
                request.incoterm.value = value;
                if (value === INCOTERMOPT.FOB) {
                    request.destCountry.value = '';
                    request.destCountry.required = false;
                    request.destPort.value = '';
                    request.destPort.required = false;
                    request.loadCountry.required = loadCountry_value => this.isEmpty(loadCountry_value) && !this.isTenderWithMultiOrigin;
                    request.loadPort.required = loadPort_value => this.isEmpty(loadPort_value) && !this.isTenderWithMultiOrigin;
                    request.deliveryStartDate.label = 'Delivery Period from';
                    request.deliveryEndDate.label = 'Delivery Period to';
                    if (this.state.request.origin.value.length === 1 && !request.destCountry.value) {
                        request.loadCountry.value = this.state.request.origin.value[0];
                    }
                    if(this.props.doPriceBreakdown && !this.isTenderWithMultiOrigin){
                        request.freight.value = "";
                        request.insurance.value = "";
                        const fob = request.fob.value ? parseFloat(request.fob.value) : 0;
                        request.price.value = fob !== 0 ? fob : "";
                    }
                } else if (value === INCOTERMOPT.CIF || value === INCOTERMOPT.CFR) {
                    request.loadCountry.value = '';
                    request.loadCountry.required = false;
                    request.loadPort.value = '';
                    request.loadPort.required = false;
                    request.destCountry.value = firstOfferWithFOB ?  this.props.trade.destCountry : this.props.bid.destCountry;
                    request.destCountry.required = this.isEmpty;
                    request.destPort.value = firstOfferWithFOB ?  this.props.trade.destPort : this.props.bid.destPort;
                    request.destPort.required = this.isEmpty;
                    request.deliveryStartDate.label = 'Shipping period from';
                    request.deliveryEndDate.label = 'Shipping period to';
                    if(this.props.doPriceBreakdown && !this.isTenderWithMultiOrigin){
                        const fob = request.fob.value ? parseFloat(request.fob.value) : 0;
                        const freight = request.freight.value ?  parseFloat(request.freight.value) : 0;
                        const insurance = request.insurance.value ? parseFloat(request.insurance.value) : 0;
                        let totalPrice = fob;
                        if(value === INCOTERMOPT.CFR){
                            request.insurance.value = "";
                            totalPrice = totalPrice + freight;
                        }else{
                            totalPrice = totalPrice + freight + insurance;
                        }
                        request.price.value = totalPrice !== 0 ? +totalPrice.toFixed(2) : "";
                    }
                }
                let showInsuranceTerms = false;
                if (request.shipping.value === 'CONTAINER') {
                    if (request.requestType.value === 'SELL') {
                        if (value === INCOTERMOPT.CIF) {
                            showInsuranceTerms = true;
                        }
                    } else {
                        if (value === INCOTERMOPT.CFR || value === INCOTERMOPT.FOB) {
                            showInsuranceTerms = true;
                        }
                    }
                }else{
                    if(value !== INCOTERMOPT.FOB){
                        const dischargeValue = firstOfferWithFOB ?  this.props.trade.discharge : this.props.bid.discharge
                        const dischargeRate = firstOfferWithFOB ?  this.props.trade.dischargeRate : this.props.bid.dischargeRate
                        request.discharge.value = dischargeValue;
                        request.dischargeRate = this.initDischargeRate(dischargeValue, dischargeRate);
                    }else{
                        request.discharge.value = undefined;
                        request.dischargeRate = this.initDischargeRate(undefined, undefined)
                    }  
                }
                if (this.isTenderWithMultiOrigin) {
                    const oldPrices = request.multipleOriginPrice.value;
                    let updatedPrices = [];
                    if(this.props.doPriceBreakdown){
                        updatedPrices = oldPrices.map(item => {
                            let updatedBreakdown = {}
                            if(this.state.request.incoterm.value === "FOB"){
                                updatedBreakdown = {...item.priceBreakdown, freight: '', insurance: ''};
                            }else if(this.state.request.incoterm.value === "CFR"){
                                updatedBreakdown = {...item.priceBreakdown, insurance: ''};
                            }else{
                                updatedBreakdown = {...item.priceBreakdown};
                            }

                            const totalPrice = Object.entries(updatedBreakdown).reduce((sum, entry) => {
                                sum = sum + (entry[1] ? parseFloat(entry[1]) : 0);
                                return sum;
                            }, 0);
                            return {
                                ...item,
                                price: +totalPrice.toFixed(2),
                                loadCountry: '',
                                loadPort: '',
                                priceBreakdown: updatedBreakdown
                            }
                        })
                    }else{
                        updatedPrices = oldPrices.map(item => ({...item, loadCountry: '', loadPort: '', priceBreakdown: null}))
                    }
                    request.multipleOriginPrice.value = this.multiOriginPricesWithValidation(updatedPrices, false)
                }
                updateRequest({ request, showInsuranceTerms, required: {} });
                break;
            }
            case 'payment': {
                this.setState(prevState => {
                    let request = { ...prevState.request };
                    // RDEV-1813 refresh dates in case payment terms changes
                    request.LCOpeningDate.value = undefined;
                    request.ADIssuingDate.value = undefined;

                    request.payment.value = value;
                    request.paymentPeriod.value = undefined;
                    request.paymentPeriod.disabled = false;

                    request.nameOfBank = { ...request.nameOfBank, label: "Name of buyer's Bank", value: "" };
                    request.locationOfBank = { ...request.locationOfBank, label: "Location of Buyer's Bank", value: "" };
                    request.swiftCodeOfBank = { ...request.swiftCodeOfBank, label: "Swiftcode of Buyer's Bank", value: "" };

                    request.nameOfConfirmingBank = { ...request.nameOfConfirmingBank, label: "Name of Confirming Bank", value: "" };
                    request.locationOfConfirmingBank = { ...request.locationOfConfirmingBank, label: "Location of Confirming Bank", value: "" };
                    request.swiftCodeOfConfirmingBank = { ...request.swiftCodeOfConfirmingBank, label: "Swiftcode of Confirming Bank", value: "" };

                    if (value === 'CAD') {
                        request.paymentPeriod.value = PAYMENTPERIODOPT['At sight'];
                        request.nameOfBank = { ...request.nameOfBank, value: "" };
                        request.locationOfBank = { ...request.locationOfBank, value: "" };
                        request.swiftCodeOfBank = { ...request.swiftCodeOfBank, value: "" };
                    }
                    if (value === 'Confirmed letter of credit') {
                        request.nameOfBank = { ...request.nameOfBank, label: "Name of Opening Bank", value: "" };
                        request.locationOfBank = { ...request.locationOfBank, label: "Location of Opening Bank", value: "" };
                        request.swiftCodeOfBank = { ...request.swiftCodeOfBank, label: "Swiftcode of Opening Bank", value: "" };

                        request.nameOfConfirmingBank = { ...request.nameOfConfirmingBank, label: "Name of Confirming Bank", value: "" };
                        request.locationOfConfirmingBank = { ...request.locationOfConfirmingBank, label: "Location of Confirming Bank", value: "" };
                        request.swiftCodeOfConfirmingBank = { ...request.swiftCodeOfConfirmingBank, label: "Swiftcode of Confirming Bank", value: "" };
                    }
                    return { request: request }

                }, () => { this.validate() });
                break;
            }
            case 'euroConversionAvl' :
            case 'downPaymentRequired' :
            case 'insuranceRequired' :
            case 'standardContract' : 
            case 'partialShipmentFlag':{
                let request = this.state.request;
                request[name] = {
                    ...request[name],
                    value: (value === 'true')
                };
                this.setState({
                    required: {},
                    request: request
                }, () => this.validate());
                break;
            }
            case 'fob':
            case 'freight':
            case 'insurance':
                this.setState(prevState => {
                    let request = prevState.request;
                    request[name].value = value;

                    const inputValue = value ? parseFloat(value) : 0;
                    const fob = request.fob.value ? parseFloat(request.fob.value): 0;
                    const freight = request.freight.value ? parseFloat(request.freight.value): 0;
                    const insurance = request.insurance.value ? parseFloat(request.insurance.value): 0;

                    let totalPrice = name === 'fob' ? inputValue: fob;
                    if(request.incoterm.value === "CFR"){
                        totalPrice = totalPrice + freight
                    }
                    if(request.incoterm.value === "CIF"){
                        totalPrice = totalPrice + freight + insurance;
                    }
                    
                    request.price.value = totalPrice !== 0 ? +totalPrice.toFixed(2) : "";
                    return{request, required: {}}
                }, () => this.validate())
                break;
            case 'shipping':                                                
                this.setState(prevState => {
                    let request = prevState.request;
                    request.shipping.value = value;
                    request.incoterm.value = this.props.trade.privacyType === "TENDER" ? (this.props.trade.optionalIncoterm ? false : request.incoterm.value) : undefined;
                    if(this.props.doPriceBreakdown){
                        if(!this.isTenderWithMultiOrigin){
                            request.freight.value = '';
                            request.insurance.value = '';
                            request.price.value = request.fob.value;
                        }else{
                            request.multipleOriginPrice.value = request.multipleOriginPrice.value.map(item => ({
                                ...item,
                                price: item.priceBreakdown.fob,
                                priceBreakdown: {
                                    ...item.priceBreakdown,
                                    freight: '',
                                    insurance: ''
                                }
                            }))
                        }
                    }
                    request.dischargeRate = this.initDischargeRate(undefined, undefined);
                    return {request, required: {}}
                }, () => this.validate());
                break;
            case 'discharge':
                this.setState(prevState => {
                    let request = prevState.request;
                    request.discharge.value = value;
                    request.dischargeRate = this.initDischargeRate(value, '');
                    return {request, required: {}};
                }, () => this.validate())
                break;
            default: {
                let request = this.state.request;
                request[name] = {
                    ...request[name],
                    value: value
                };
                this.setState({
                    required: {},
                    request: request
                }, () => this.validate());
                break;
            }
        }
    };

    trackAction(action) {
        ReactGA.event({
            category: 'Trade',
            action
        })
    }

    onReview = () => {
        if (this.validate()) {
            this.setState({ showError: false });
            this.setState({
                disabled: true
            });
            this.props.onReview(this.getStateValue(this.state.request));
        } else {
            this.setState({ showError: true });
        }
    };

    submitUpdate = () => {
        const valFromState = this.getStateValue(this.state.request);
        const loaded = this.state.loadedRequest;

        const counterparty =
            loaded.buyerId === this.props.account.token.companyId
                ? { ID: loaded.sellerId, name: loaded.Seller }
                : { ID: loaded.buyerId, name: loaded.Buyer };

        let diff = omitBy(valFromState, (v, k) => {
            if (counterparty && k === 'counterparty') {
                if (valFromState.counterparty === counterparty.id) {
                    return valFromState.counterparty;
                }
            }
            return loaded[k] === v;
        });

        if (typeof diff.counterparty == 'number') {
            diff.counterparty = [diff.counterparty];
        } else {
            diff.counterparty = valFromState.counterparty;
        }

        this.props.UpdateTradeRequest(this.props.match.params.id, diff).then(() => {
            this.props.navigate('/');
            this.trackAction('Update Request');
        });
    };

    validate() {
        let required = {};
        let container = this.state.request;
        Object.keys(container).map(key => {
            let v = container[key];
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
            }
            return false;
        });
        if (this.state.privacyType === 'PRIVATE' && this.state.counterparty === '') {
            required.counterparty = this.state.request.counterparty;
        }
        if (Object.keys(required).length > 0) {
            this.setState({ required: required }, () => {
                scrollToElement('input', {
                    offset: -130,
                    ease: 'inOutQuad',
                    duration: 600
                });
            });
            return false;
        }
        return true;
    }

    getStateValue(container) {
        let result = {};
        Object.keys(container).map(key => {
            switch (key) {
                case 'price':
                    result[key] = (this.isTenderWithMultiOrigin && this.props.previousBid) ? this.props.previousBid.price : parseFloat(container[key].value);
                    break;
                case 'optionsPackagingTypePremOrDiscount':
                case 'optionsQualityTypePremOrDiscount':
                case 'optionsShippingTypePremOrDiscount':
                case 'optionsTypeOfRicePremOrDiscount':
                case 'measure':
                    result[key] = parseFloat(container[key].value);
                    break;
                case 'multipleOriginPrice':
                    result[key] = container[key].value.map((item) => ({ 
                            ...item, 
                            price: item.price ? parseFloat(item.price) : null,
                            priceBreakdown: item.priceBreakdown ? 
                                Object.entries(item.priceBreakdown).reduce((obj, entry) => {
                                    obj[entry[0]] = entry[1] ? parseFloat(entry[1]) : null
                                    return obj;
                                }, {}) 
                                : null
                        }));
                    break;
                case 'inspection':
                    result[key] = container[key].value ? String(container[key].value) : null;
                    break;
                case 'bidValidateDate':
                case 'deliveryStartDate':
                case 'deliveryEndDate':
                case 'LCOpeningDate':
                case 'ADIssuingDate':
                    result[key] = (container[key].value) ? container[key].value.format() : "";
                    break;
                case 'downPayment':
                    result[key] = parseInt(container[key].value);
                    break
                case 'packaging':
                    if (container[key].value === "OTHER") {
                        result[key] = container['packagingOther'].value;
                    } else {
                        result[key] = container[key].value;
                    }
                    break;
                case 'optionsPackagingType':
                    if (container[key].value === "OTHER") {
                        result[key] = container['optionsPackagingTypeOther'].value;
                    } else {
                        result[key] = container[key].value;
                    }
                    break;
                case 'optionsPackagingTypeOther':
                case 'packagingOther':
                    break;
                case 'downPaymentPercentage':
                    result[key] =container[key].value===""? 0 : container[key].value;
                    break;     
                case 'tolerance':
                    result[key] = parseFloat(container[key].value);
                    break;
                case 'tolerancePreset':
                    break
                default:
                    result[key] = container[key].value;
                    break;
            }
            return false;
        });

        if(this.props.doPriceBreakdown){
            if(!this.isTenderWithMultiOrigin){
                result.priceBreakdown = {
                    fob: result.fob ? parseFloat(result.fob) : 0,
                    freight: result.freight ? parseFloat(result.freight) : 0,
                    insurance: result.insurance ? parseFloat(result.insurance) : 0
                }
                delete result.fob;
                delete result.freight;
                delete result.insurance;
            } 
        }else{
            result.priceBreakdown = null;
        }

        return result;
    }

    initDischargeRate = (dischargeValue, value) => {
        let DISCHARGE_RATE_LABEL = "Load & Discharge";
        let DISCHARGE_RATE_VALUE = (value) ? value : "";
        let isInValid = (valueInvalid) => (this.state.request.shipping.value !== "CONTAINER" && this.isEmpty(valueInvalid));
        switch (dischargeValue) {
            case 'CQD load':
                DISCHARGE_RATE_LABEL = "Demurrage rate /day in $";
                break;
            case 'loadrate':
                DISCHARGE_RATE_LABEL = "Specify load rate details";
                break;
            case 'CQD':
                DISCHARGE_RATE_LABEL = "Demurrage rate/day in $";
                break;
            case 'Discharge rate':
                DISCHARGE_RATE_LABEL = "Specify discharge rate details ";
                break;
            default: break;
        }
        return {
            name: 'dischargeRate',
            label: DISCHARGE_RATE_LABEL,
            value: DISCHARGE_RATE_VALUE,
            required: isInValid,
            touched: true
        }
    }

    openModalIntegratedInspector = () => {
        return (
            <MessageNonIntegratedInspector
                onCancel={() => this.setState({
                    showModal: false
                })}
            />
        )
    }
   
    formPriceBreakdownData = () => {
        if(!this.isTenderWithMultiOrigin){
            return [{
                origin: this.state.request.origin.value,
                price: this.state.request.price.value,
                isInvalidPrice: this.state.required.hasOwnProperty('price'),
                loadCountry: this.state.request.loadCountry.value,
                isInvalidLoadCountry: this.state.required.hasOwnProperty('loadCountry'),
                loadPort: this.state.request.loadPort.value,
                isInvalidLoadPort: this.state.required.hasOwnProperty('loadPort'),
                fob: this.state.request.fob.value,
                isInvalidFob: this.state.required.hasOwnProperty('fob'),
                freight: this.state.request.freight.value,
                isInvalidFreight: this.state.required.hasOwnProperty('freight'),
                insurance: this.state.request.insurance.value,
                isInvalidInsurance: this.state.required.hasOwnProperty('insurance')  
            }]
        }
        return this.state.request.multipleOriginPrice.value;
    }

    render() {
        const { bid, doPriceBreakdown } = this.props;
        const isNotNormalCounter = bid.bankOnlyInd || bid.reCounterInd;

        if (!this.state.initiated) {
            return null;
        }

        let originDropdown = (
            <div className={this.isTenderWithMultiOrigin ? "col-sm-7 col-md-7 col-lg-7 mb-3" : this.FORM_CREATE_WRAPPER_CLASS}>
                <label className={`${this.CUSTOM_COMPONENT_STYLE.labelClassName} `}>
                    {this.state.request.origin.label}
                    {this.state.request.origin.required && <span className="req-field">&nbsp;*</span>}
                </label>
                <Select
                    styles={{
                        control: (provided) => {
                            const border = this.state.required.hasOwnProperty('origin') ? '1px solid rgb(220, 53, 69)' :
                                this.state.request.origin.touched ? '1px solid rgb(40, 167, 69)' : '';
                            return { ...provided, border };
                        }
                    }}
                    options={RiceService.getAvailableCountries(this.state.request.riceType.value)}
                    isMulti
                    onChange={selectedOrigins => {
                        this.setField('origin', selectedOrigins.map(origin => origin.value));
                        if (selectedOrigins.length === 0) {
                            this.setField("riceType", "");
                            this.setField("riceTypeOther", "");
                        }
                    }}
                    value={RiceService.getSelectedCountries(this.state.request.origin.value)}
                    placeholder="Select Origins"
                    isDisabled={this.props.trade.privacyType === "TENDER" || isNotNormalCounter} />
                { this.state.request.riceType.value &&
                    <small className="form-text text-muted">
                        *List of origins for the selected type
                    </small>
                }
            </div>
        )

        const shippingDropdown = (
            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                <FormSelectDropdown
                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                    validation={this.state.required}
                    className="select"
                    name="shipping"
                    items={SHIPPINGTYPES}
                    onSelect={e => this.setField('shipping', e.target.value)}
                    value={this.state.request.shipping}
                    disabled={isNotNormalCounter}
                />
            </div>
        )

        const counterTypeStatusFlag = bid.bankOnlyInd ? 'Fill Bank Details' : bid.reCounterInd ? 'Improve Price' : '' ;
        const isTender = this.props.trade.privacyType === "TENDER";
        
        return (
            <div className="modal__container counter-request">
                 {this.state.showModal && this.openModalIntegratedInspector()}
                <div className="modal__wrapper px-0 py-0" style={{ maxWidth: '90vw' }}>
                    <div className="rtb-popup-box container-fluid">
                        <div className="popup-header">
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    {!bid.price &&
                                        <span className="status-flag">
                                            <FontAwesomeIcon icon={faInfoCircle} /> {counterTypeStatusFlag || 'Offer Price'}
                                        </span>
                                    }
                                    {bid.price !== 0 &&
                                        <span className="status-flag">
                                            <FontAwesomeIcon icon={faInfoCircle} /> {counterTypeStatusFlag || 'Create Counter'}
                                        </span>
                                    }
                                </div>
                                <div className="col-md-6">
                                    <h3 className="heading-4 text-center">
                                        {bid.bankOnlyInd ?
                                            `Complete Bank Details For Seller's Acceptance` : 
                                            bid.reCounterInd ? 'Improve Price' :
                                            `Prepare ${bid.price ? 'Counter' : 'Offer'} for ${this.props.trade.requestType === "BUY" ? 'RTB' : 'RTS'} ${this.props.trade.price ? '(with Price)' : '(Without Price)'}`}
                                    </h3>
                                    {(!bid.bankOnlyInd && !bid.reCounterInd) &&
                                        <>
                                            <strong className="float-left">Note</strong>
                                            <div className="note-text">
                                                <div>1) The values of the initial bid are preloaded as initial values.</div>
                                                <div>2) Not changing the values makes them part of your {this.type}.</div>
                                                <div>3) At the end of your {this.type} you can review it, edit it or submit by pushing submit.</div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <span className="modal__close" onClick={this.props.onClose} />
                            </div>
                        </div>
                        <hr />
                        <div className="popup-body container-fluid">
                            <form className={`create-counter-form needs-validation`} noValidate>
                                <div className="row align-items-start">
                                    {(!isTender) && (
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormRadioFieldTrueFalse
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={"euroConversionAvl"}
                                                value={this.state.request.euroConversionAvl}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                disabled={isNotNormalCounter}
                                            />
                                        </div>
                                    )}
                                    <div className="w-100 d-md-none d-lg-block" />
                                    {(isTender) && (
                                        <React.Fragment>
                                            {shippingDropdown}
                                            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                <FormRadioField
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    name="incoterm"
                                                    items={{
                                                        [this.props.trade.incoterm]: INCOTERMOPT[this.props.trade.incoterm],
                                                        ...(this.props.trade.optionalIncoterm ? {
                                                            [this.props.trade.optionalIncoterm]: INCOTERMOPT[this.props.trade.optionalIncoterm]
                                                        }:{})
                                                    }}
                                                    required={true}
                                                    onChange={e => {
                                                        this.setField('incoterm', e.target.value);
                                                    }}
                                                    value={this.state.request.incoterm} 
                                                    disabled={isNotNormalCounter}/>
                                            </div>
                                            <div className="w-100 d-md-none d-lg-block" />
                                        </React.Fragment>
                                    )}
                                    {(isTender && doPriceBreakdown) && (
                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <label className="label" htmlFor="priceBreakdown">
                                                Your Price Per MT<span className="req-field">&nbsp;*</span>
                                            </label>
                                            <FormPriceBreakdown 
                                                incoterm={this.state.request.incoterm.value}
                                                data={this.formPriceBreakdownData()}
                                                isMultiOrigin={this.isTenderWithMultiOrigin}
                                                onChange={this.priceBreakdownChangeHandler}
                                                currency={this.state.request.currency.value}
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                disabledOrigins={this.disabledOriginsWhileImprovingPrice()}
                                                improvingPrice={bid.reCounterInd}
                                            />
                                        </div>
                                    )}
                                    {(this.isTenderWithMultiOrigin && !doPriceBreakdown) && originDropdown}
                                    {(this.isTenderWithMultiOrigin && !doPriceBreakdown) && (
                                        <FormMultipleOriginPrice
                                            value={this.state.request.multipleOriginPrice}
                                            name='multipleOriginPrice'
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            disabled={bid.bankOnlyInd}
                                            currency={CURRENCYOPT[this.state.request.currency.value]}
                                            countries={Countries}
                                            onChange={this.multiOriginPriceChangeHandler}
                                            showPortAndCountries={this.state.request.incoterm.value === "FOB"} 
                                            defaultDisabledOrigins={this.disabledOriginsWhileImprovingPrice()}/>
                                    )} 
                                    {(!this.isTenderWithMultiOrigin && !doPriceBreakdown) && (
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormUnitsInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name="price"
                                                dropName="currency"
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                allowNegative={false}
                                                isAllowed={(values) => {
                                                    const { floatValue, value } = values;
                                                    return value === "" || floatValue <= 1000000;
                                                }}
                                                items={CURRENCYOPT}
                                                validation={this.state.required}
                                                placeholder="Price"
                                                type="text"
                                                onSelect={val => this.setField('currency', val)}
                                                onChange={val => this.setField("price", val)}
                                                value={this.state.request.price}
                                                dropValue={this.state.request.currency}
                                                disabled={bid.bankOnlyInd}
                                                unitsDisabled={bid.reCounterInd}
                                            />
                                        </div>
                                    )}
                                    {(!this.isTenderWithMultiOrigin && !doPriceBreakdown) && originDropdown}
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="riceCategory"
                                            items={RICECATEGORY}
                                            validation={this.state.required}
                                            value={this.state.request.riceCategory}
                                            disabled={this.state.request.riceCategory.value || isNotNormalCounter}
                                            onSelect={e => this.setField('riceCategory', e.target.value)}
                                            placeholder={this.state.request.riceCategory.value}
                                            required={false}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdownList
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            items={RiceService.getAvailableReferencesFromArray(this.state.request.origin.value)}
                                            value={this.state.request.riceType}
                                            keyLabel={'label'}
                                            keyValue={'value'}
                                            validation={this.state.required}
                                            placeholder={"Type of rice"}
                                            name="riceType"
                                            captionText={this.state.request.origin.value ? `*List of types for the selected origin` : ''}
                                            required={true}
                                            onSelect={e => {
                                                this.setField("riceType", e.target.value);
                                                this.setField("riceTypeOther", "");
                                                const countryList = RiceService.getAvailableCountries(e.target.value);
                                                if (e.target.value === "") {
                                                    this.setField("origin", []);
                                                    this.setField("multipleOriginPrice", []);
                                                }
                                                if (countryList.length === 1) {
                                                    this.setField("origin", [countryList[0].value]);
                                                }
                                            }}
                                            defaultLabel={"Select"}
                                            disableDefault={false}
                                            showDefault={this.state.request.riceType ? true : false}
                                            disabled={this.props.trade.privacyType === "TENDER" || isNotNormalCounter} />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        {this.state.request.riceType.value === RiceService.enums.REF_OTHER &&
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                value={this.state.request.riceTypeOther}
                                                validation={this.state.required}
                                                placeholder={"Please Specify"}
                                                name="riceTypeOther"
                                                required={true}
                                                onChange={e => {
                                                    this.setField("riceTypeOther", e.target.value);
                                                }}
                                                disabled={this.props.trade.privacyType === "TENDER" || isNotNormalCounter} />}
                                    </div>
                                    <div className="w-100" />
                                    {!isTender && shippingDropdown}
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormInputNumberFormat
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="measure"
                                            placeholder="Quantity"
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            allowNegative={false}
                                            type='text'
                                            isAllowed={(values) => {
                                                const { floatValue, value } = values;
                                                return value === "" || floatValue <= 100000;
                                            }}
                                            validation={this.state.required}
                                            onChange={val => this.setField('measure', val)}
                                            value={this.state.request.measure}
                                            disabled={isNotNormalCounter}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="quality"
                                            items={QUALITYSTANDARD}
                                            validation={this.state.required}
                                            value={this.state.request.quality}
                                            onSelect={e => this.setField('quality', e.target.value)}
                                            placeholder={this.state.request.quality.value}
                                            required={false}
                                            showDefault={true}
                                            disabled={isNotNormalCounter}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdownList
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="cropYear"
                                            items={CROP_YEARS}
                                            sortKeys={false}
                                            validation={this.state.required}
                                            value={this.state.request.cropYear}
                                            onSelect={e => this.setField('cropYear', e.target.value)}
                                            placeholder={this.state.request.cropYear.value}
                                            required={false}
                                            showDefault={true}
                                            disabled={isNotNormalCounter}
                                        />
                                    </div>

                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="tolerancePreset"
                                            items={TOLERANCE}
                                            validation={this.state.required}
                                            captionText="Tolerance is in buyer's option for FOB and in seller's option for CFR and CIF and  MIN/MAX will have effect of zero tolerance up or down"
                                            value={this.state.request.tolerancePreset}
                                            onSelect={e => {
                                                if (e.target.value === 'OTHER') {
                                                    this.setState(prev => {
                                                        prev.request.tolerance.visible = true
                                                        return { ...prev }
                                                    })
                                                    this.setField('tolerance', "");
                                                } else {
                                                    this.setState(prev => {
                                                        prev.request.tolerance.visible = false
                                                        return { ...prev }
                                                    })
                                                    this.setField('tolerance', e.target.value);
                                                }
                                                this.setField('tolerancePreset', e.target.value);
                                            }}
                                            placeholder={this.state.request.tolerance.value}
                                            required={false}
                                            showDefault={true}
                                        />
                                    </div>

                                    <div className={`${this.FORM_CREATE_WRAPPER_CLASS} ${this.state.request.tolerance.visible ? "visible" : "invisible"}`}>
                                        <FormInputGroupField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={'tolerance'}
                                            apValue={"%"}
                                            validation={this.state.required}
                                            value={this.state.request.tolerance}
                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                            placeholder="Tolerance value"
                                            options={{
                                                numeral: true,
                                                numeralIntegerScale: 2,
                                                numeralPositiveOnly: true,
                                                stripLeadingZeroes: true
                                            }}
                                        />
                                    </div>

                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            name="incoterm"
                                            items={INCOTERMOPT}
                                            onSelect={e => {
                                                this.setField('incoterm', e.target.value);
                                                this.setField('discharge', "");
                                                this.setField('dischargeRate', "");
                                            }}
                                            value={this.state.request.incoterm}
                                            disabled={isNotNormalCounter}
                                        />
                                    </div>
                                    {(this.state.request.incoterm.value === 'CIF' || this.state.request.incoterm.value === 'CFR') &&
                                        <React.Fragment>
                                            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                <FormSelectDropdown
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    validation={this.state.required}
                                                    className="select"
                                                    name="destCountry"
                                                    captionText="Country of the port of destination"
                                                    items={Countries}
                                                    onSelect={e => {
                                                        this.setField("destCountry", e.target.value);
                                                        this.setField("destPort", "");
                                                    }}
                                                    value={this.state.request.destCountry}
                                                    placeholder="Country"
                                                    disabled={isNotNormalCounter}
                                                />
                                            </div>
                                            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                <FormAsyncSelectPort 
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    fieldData={this.state.request.destPort}
                                                    onChange={(name, value, fromDropdown) => {
                                                        this.setField(name, value);
                                                        if(fromDropdown){
                                                            this.setField("destCountry", value.slice(0, 2));
                                                        } 
                                                    }}
                                                    country={this.state.request.destCountry.value}
                                                    validation={this.state.required}
                                                    styles={{
                                                        indicatorsContainer: (provided) => ({...provided, height: 31})
                                                    }}
                                                    disabled={isNotNormalCounter}
                                                />
                                            </div>
                                        </React.Fragment>}
                                    {(this.state.request.incoterm.value === 'FOB' && !this.isTenderWithMultiOrigin && !doPriceBreakdown) &&
                                        <React.Fragment>
                                            <div className={`${this.FORM_CREATE_WRAPPER_CLASS}`}>
                                                <FormSelectDropdown
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    className="select"
                                                    validation={this.state.required}
                                                    name="loadCountry"
                                                    items={Countries}
                                                    onSelect={e => {
                                                        this.setField("loadCountry", e.target.value);
                                                        this.setField("loadPort", "");
                                                    }}
                                                    value={this.state.request.loadCountry}
                                                    placeholder="Country"
                                                    disabled={isNotNormalCounter}
                                                />
                                            </div>
                                            <div className={`${this.FORM_CREATE_WRAPPER_CLASS}`}>
                                                <FormAsyncSelectPort 
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    fieldData={this.state.request.loadPort}
                                                    onChange={(name, value, fromDropdown) => {
                                                        this.setField(name, value);
                                                        if(fromDropdown){
                                                            this.setField("loadCountry", value.slice(0, 2));
                                                        } 
                                                    }}
                                                    country={this.state.request.loadCountry.value}
                                                    validation={this.state.required}
                                                    styles={{
                                                        indicatorsContainer: (provided) => ({...provided, height: 31})
                                                    }}
                                                    disabled={isNotNormalCounter}
                                                />
                                            </div>
                                        </React.Fragment>}

                                    <div className="col-sm-12 col-md-8 col-lg-6">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <FormDateField
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    dateFormat={DATEFORMAT}
                                                    required={false}
                                                    validation={this.state.required}
                                                    time={false}
                                                    name="deliveryStartDate"
                                                    minDate={moment()}
                                                    item={this.state.request.deliveryStartDate}
                                                    label={true}
                                                    onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                                    disabled={isNotNormalCounter}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <FormDateField
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    dateFormat={DATEFORMAT}
                                                    required={false}
                                                    validation={this.state.required}
                                                    time={false}
                                                    name="deliveryEndDate"
                                                    minDate={this.state.request.deliveryStartDate.value}
                                                    item={this.state.request.deliveryEndDate}
                                                    label={true}
                                                    selectsStart={this.state.request.deliveryStartDate.value}
                                                    selectsEnd={this.state.request.deliveryEndDate.value}
                                                    onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                                    disabled={isNotNormalCounter}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="packaging"
                                            items={PACKAGING}
                                            validation={this.state.required}
                                            value={this.state.request.packaging}
                                            onSelect={e => {
                                                this.setField('packaging', e.target.value);
                                                if (e.target.value === 'OTHER') {
                                                    this.initField('packagingOther', "Packaging (other)", "", this.required);
                                                }
                                            }}
                                            placeholder={this.state.request.packaging.value}
                                            required={false}
                                            showDefault={true}
                                            disabled={isNotNormalCounter}
                                        />
                                    </div>
                                    {this.state.request.packaging.value === 'OTHER' &&
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name="packagingOther"
                                                validation={this.state.required}
                                                value={this.state.request.packagingOther}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder={"Packing"}
                                                required={true}
                                                disabled={isNotNormalCounter}
                                            />
                                        </div>}
                                    
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        {/* <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="inspection"
                                            items={this.filterInspectionCompany(this.props.inspectionCompanies, this.state.request.origin.value)}
                                            validation={this.state.required}
                                            value={this.state.request.inspection}
                                            onSelect={e =>{
                                                 this.setField('inspection', e.target.value)
                                                 this.setField('inspectionOther', "");
                                                }}
                                            placeholder={this.state.request.inspection.value}
                                            required={false}
                                            showDefault={true}
                                            disabled={isNotNormalCounter}
                                        /> */}
                                        <FormSelectDropdownList
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            name={"inspection"}
                                            items={filterInspectionCompany(this.props.inspectionCompanies, this.state.request.origin.value,false)}
                                            showDefault={true}
                                            onSelect={e => {
                                                this.setField('inspection', e.target.value)
                                                this.setField('inspectionOther', "");
                                            }}
                                            value={this.state.request.inspection}
                                            placeholder={this.state.request.inspection.value}
                                            required={false}
                                            disabled={isNotNormalCounter}
                                        />
                                    </div>
                                   
                                    {this.state.request.inspection.value === "1" &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="inspectionOther"
                                            items={getNonIntegratedInspector(this.state.request.origin.value)}
                                            validation={this.state.required}
                                            value={this.state.request.inspectionOther}
                                            onSelect={e =>{ 
                                                this.setField('inspectionOther', e.target.value)
                                                this.setState({
                                                    showModal: true
                                                });
                                            }}
                                            placeholder={this.state.request.inspectionOther.value}
                                            required={true}
                                            showDefault={true}
                                            isShowToolTipLabel={this.state.request.inspection.value === "1"}
                                            toolTipTitle={TOOLTIP_TITLE.NON_INSPECTOR_TITLE}
                                            disabled={isNotNormalCounter}
                                        />
                                    </div>}
                                  
                                    <div className="w-100" />
                                    {this.state.request.shipping.value === "VESSEL" &&
                                        <React.Fragment>
                                            <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                                <FormSelectDropdown
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    validation={this.state.required}
                                                    name="discharge"
                                                    items={this.state.request.incoterm.value === "FOB" ? DISCHARGEFOB : DISCHARGECIF}
                                                    onSelect={e => this.setField('discharge', e.target.value)}
                                                    value={this.state.request.discharge}
                                                    disabled={isNotNormalCounter}
                                                />
                                            </div>
                                            {["CQD load", "CQD"].includes(this.state.request.discharge.value) &&
                                                <div className="col-sm-8">
                                                    <FormInputNumberFormat
                                                        type="text"
                                                        thousandSeparator={true}
                                                        step="any"
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        name="dischargeRate"
                                                        isAllowed={(values) => {
                                                            const { floatValue, value } = values;
                                                            return value === "" || floatValue >= 0;
                                                        }}
                                                        validation={this.state.required}
                                                        value={this.state.request.dischargeRate}
                                                        onChange={val => this.setField('dischargeRate', val)}
                                                        placeholder={"Rate"}
                                                        required={true}
                                                        disabled={isNotNormalCounter}
                                                    />
                                                </div>}
                                            {["loadrate", "Discharge rate"].includes(this.state.request.discharge.value) &&
                                                <div className="col-sm-8">
                                                    <FormTextareaField
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        name="dischargeRate"
                                                        validation={this.state.required}
                                                        value={this.state.request.dischargeRate}
                                                        onChange={e => this.setField(e.target.name, e.target.value)}
                                                        placeholder={"Rate"}
                                                        required={true}
                                                        disabled={isNotNormalCounter}
                                                    />
                                                </div>}
                                        </React.Fragment>}
                                </div>
                                <div className="row">
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="payment"
                                            items={PAYMENTTERMSOPT}
                                            validation={this.state.required}
                                            value={this.state.request.payment}
                                            onSelect={e => this.setField('payment', e.target.value)}
                                            placeholder={this.state.request.payment.value}
                                            required={false}
                                            showDefault={true}
                                            disabled={isNotNormalCounter}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormSelectDropdown
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="paymentPeriod"
                                            items={PAYMENTPERIODOPT}
                                            validation={this.state.required}
                                            value={this.state.request.paymentPeriod}
                                            disabled={this.state.request.payment.value === "CAD" || isNotNormalCounter}
                                            onSelect={e => this.setField('paymentPeriod', e.target.value)}
                                            placeholder={this.state.request.paymentPeriod.value}
                                            required={false}
                                        />
                                    </div>
                                    {(this.state.request.payment.value === 'Avalised Draft') &&
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormDateField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE} Cou
                                                dateFormat={DATEFORMAT}
                                                required={true}
                                                validation={this.state.required}
                                                minDate={new Date()}
                                                time={false}
                                                name="ADIssuingDate"
                                                item={this.state.request.ADIssuingDate}
                                                label={true}
                                                onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                                disabled={isNotNormalCounter}
                                            />
                                        </div>}

                                    {(this.state.request.payment.value === 'Letter of credit' || this.state.request.payment.value === 'Confirmed letter of credit') &&
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormDateField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            dateFormat={DATEFORMAT}
                                            required={true}
                                            validation={this.state.required}
                                            time={false}
                                            name="LCOpeningDate"
                                            minDate={new Date()}
                                            item={this.state.request.LCOpeningDate}
                                            label={true}
                                            onSelect={(name, onSelectDate) => this.setField(name, onSelectDate)}
                                        />
                                    </div>}

                                    {this.state.request.payment.value !== "CAD" &&
                                    <React.Fragment>
                                        <div className="w-100" />
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'nameOfBank'}
                                                readOnly={this.props.pov === "SELLER"}
                                                validation={this.state.required}
                                                value={this.state.request.nameOfBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Bank name"
                                                type="text"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'locationOfBank'}
                                                readOnly={this.props.pov === "SELLER"}
                                                validation={this.state.required}
                                                value={this.state.request.locationOfBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Location"
                                                type="text"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'swiftCodeOfBank'}
                                                readOnly={this.props.pov === "SELLER"}
                                                validation={this.state.required}
                                                value={this.state.request.swiftCodeOfBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Swift code"
                                                type="text"
                                            />
                                        </div>
                                        <div className="w-100" />
                                    </React.Fragment>}

                                    {this.state.request.payment.value === "Confirmed letter of credit" &&
                                    <React.Fragment>
                                        <div className="w-100" />
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'nameOfConfirmingBank'}
                                                readOnly={this.props.pov === "SELLER"}
                                                validation={this.state.required}
                                                value={this.state.request.nameOfConfirmingBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Confirming Bank name"
                                                type="text"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'locationOfConfirmingBank'}
                                                readOnly={this.props.pov === "SELLER"}
                                                validation={this.state.required}
                                                value={this.state.request.locationOfConfirmingBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Location"
                                                type="text"
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'swiftCodeOfConfirmingBank'}
                                                readOnly={this.props.pov === "SELLER"}
                                                validation={this.state.required}
                                                value={this.state.request.swiftCodeOfConfirmingBank}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Swift code"
                                                type="text"
                                            />
                                        </div>
                                        <div className="w-100" />
                                    </React.Fragment>}

                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormRadioFieldTrueFalse
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"downPaymentRequired"}
                                            value={this.state.request.downPaymentRequired}
                                            onChange={e => {
                                                this.setField("downPaymentPercentage", "");
                                                this.setField("downPaymentPeriod", "");
                                                this.setField(e.target.name, e.target.value);
                                            }}
                                            disabled={isNotNormalCounter}
                                        />
                                    </div>
                                    <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                        <FormRadioFieldTrueFalse
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name={"partialShipmentFlag"}
                                            value={this.state.request.partialShipmentFlag}
                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                        />
                                    </div>
                                

                                    {this.state.request.downPaymentRequired.value && 
                                    <React.Fragment>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormInputGroupField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'downPaymentPercentage'}
                                                apValue={"%"}
                                                validation={this.state.required}
                                                value={this.state.request.downPaymentPercentage}
                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                placeholder="Down payment"
                                                step="any"
                                                max={100}
                                                min={0}
                                                options={{
                                                    numeral: true,
                                                    numeralIntegerScale: 3,
                                                    numeralPositiveOnly: true,
                                                    stripLeadingZeroes: true
                                                }}
                                                disabled={isNotNormalCounter}
                                            />
                                        </div>
                                        <div className={this.FORM_CREATE_WRAPPER_CLASS}>
                                            <FormSelectDropdown
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                validation={this.state.required}
                                                name="downPaymentPeriod"
                                                items={DOWNPAYMENTPERIODOPT}
                                                captionText="(days counting from proforma invoice date)"
                                                onSelect={e => this.setField('downPaymentPeriod', e.target.value)}
                                                value={this.state.request.downPaymentPeriod}
                                                disabled={isNotNormalCounter}
                                            />
                                        </div>
                                    </React.Fragment>}
                                    <div className={'col-sm-12'}>
                                        <FormTextareaField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            validation={this.state.required}
                                            disabled={isNotNormalCounter}
                                            name="specialRequest"
                                            onChange={e => this.setField('specialRequest', e.target.value)}
                                            value={this.state.request.specialRequest}
                                            required={false}
                                        />
                                    </div>
                                </div>
                                {this.props.trade.privacyType !== "TENDER" && <div className="rtb-wrapper">
                                    <div className="w-100" />
                                    <h4 className="mb-3 mt-2">Options</h4>
                                    <div>Options are not included in the total price above</div>
                                    <div className="row options-wrapper">
                                        <div className="col-md-6">
                                            <div className="m-1">
                                                <div className="row option-block p-2">
                                                    <div className="col-md-6 ">
                                                        <FormSelectDropdown
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name="optionsPackagingType"
                                                            items={PACKAGING}
                                                            validation={this.state.required}
                                                            value={this.state.request.optionsPackagingType}
                                                            onSelect={e => this.setField('optionsPackagingType', e.target.value)}
                                                            placeholder={this.state.request.optionsPackagingType.value}
                                                            disableDefault={false}
                                                            defaultLabel="No option"
                                                            showDefault={true}
                                                            disabled={isNotNormalCounter}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        {this.state.request.optionsPackagingType.value === "OTHER" &&
                                                            <FormInputField
                                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                                name={'optionsPackagingTypeOther'}
                                                                validation={this.state.required}
                                                                value={this.state.request.optionsPackagingTypeOther}
                                                                onChange={e => this.setField(e.target.name, e.target.value)}
                                                                placeholder="Specify"
                                                                type="text"
                                                                disabled={isNotNormalCounter}
                                                            />}
                                                        <FormInputGroupField
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name={'optionsPackagingTypePremOrDiscount'}
                                                            prepValue={CURRENCYOPT[this.state.request.currency.value]}
                                                            validation={this.state.required}
                                                            value={this.state.request.optionsPackagingTypePremOrDiscount}
                                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                                            options={{
                                                                numeral: true,
                                                                numeralIntegerScale: 6,
                                                                stripLeadingZeroes: true
                                                            }}
                                                            disabled={isNotNormalCounter}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="m-1">
                                                <div className="row option-block p-2">
                                                    <div className="col-md-6">
                                                        <FormInputField
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name={'optionsTypeOfRice'}
                                                            validation={this.state.required}
                                                            value={this.state.request.optionsTypeOfRice}
                                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                                            placeholder="Type of rice"
                                                            type="text"
                                                            disabled={isNotNormalCounter}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormInputGroupField
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name={'optionsTypeOfRicePremOrDiscount'}
                                                            prepValue={CURRENCYOPT[this.state.request.currency.value]}
                                                            validation={this.state.required}
                                                            value={this.state.request.optionsTypeOfRicePremOrDiscount}
                                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                                            options={{
                                                                numeral: true,
                                                                numeralIntegerScale: 6,
                                                                stripLeadingZeroes: true
                                                            }}
                                                            disabled={isNotNormalCounter}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="m-1">
                                                <div className="row option-block p-2">
                                                    <div className="col-md-6">
                                                        <FormSelectDropdown
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name="optionsQualityType"
                                                            items={QUALITYSTANDARD}
                                                            validation={this.state.required}
                                                            value={this.state.request.optionsQualityType}
                                                            onSelect={e => this.setField('optionsQualityType', e.target.value)}
                                                            placeholder={this.state.request.optionsQualityType.value}
                                                            showDefault={true}
                                                            disableDefault={false}
                                                            defaultLabel="No option"
                                                            disabled={isNotNormalCounter}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormInputGroupField
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name={'optionsQualityTypePremOrDiscount'}
                                                            prepValue={CURRENCYOPT[this.state.request.currency.value]}
                                                            validation={this.state.required}
                                                            value={this.state.request.optionsQualityTypePremOrDiscount}
                                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                                            options={{
                                                                numeral: true,
                                                                numeralIntegerScale: 6,
                                                                stripLeadingZeroes: true
                                                            }}
                                                            disabled={isNotNormalCounter}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="m-1">
                                                <div className="row option-block p-2">
                                                    <div className="col-md-6">
                                                        <FormSelectDropdown
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name="optionsShippingType"
                                                            items={SHIPPINGTYPES}
                                                            validation={this.state.required}
                                                            value={this.state.request.optionsShippingType}
                                                            onSelect={e => this.setField('optionsShippingType', e.target.value)}
                                                            showDefault={true}
                                                            disableDefault={false}
                                                            defaultLabel="No option"
                                                            disabled={isNotNormalCounter}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormInputGroupField
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name={'optionsShippingTypePremOrDiscount'}
                                                            prepValue={CURRENCYOPT[this.state.request.currency.value]}
                                                            validation={this.state.required}
                                                            value={this.state.request.optionsShippingTypePremOrDiscount}
                                                            onChange={e => this.setField(e.target.name, e.target.value)}
                                                            options={{
                                                                numeral: true,
                                                                numeralIntegerScale: 6,
                                                                stripLeadingZeroes: true
                                                            }}
                                                            disabled={isNotNormalCounter}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-100" />
                                        <div className="col-sm-12">
                                            <div>Discounts must be expressed as a negative premium (e.g. a 26 us$ discount for vessel per MT, please enter -26)</div>
                                        </div>
                                    </div>
                                </div>}
                                <div>
                                    <hr />
                                    <div className="modal-header-center"><h6>Document Selection/Uploading Party</h6>
                                        <div className="text-left">
                                            Add or Remove documents and define the uploading party. Always consider regulatory requirements for your origin and destination. Documents with a grey tick box cannot be removed. In addition to the documents listed below the full set of documents will also include the following mandatory documents : bills of lading, commercial invoice , quality-weight-packing certificate, {this.state.request.shipping.value === "VESSEL" ? <span>hold cleanliness certificate</span> : <span>container cleanliness certificate</span>} issued and uploaded by the contractual inspector
                                    </div>
                                    </div>
                                    <DocumentSelection
                                        value={DocumentFactory.getFormFEDocumentList(this.getStateValue(this.state.request))}
                                        onChange={documentList => this.setField('optionalDocuments', DocumentFactory.getBEOptionalDocumentList(documentList))}
                                        errorMessage={errorMessage} 
                                        disableDocumentSelection={isNotNormalCounter}
                                        isnonIntegratedInspector={this.state.request.inspection.value === "1"}/>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormDateField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            dateFormat={DATEFORMATHOURS}
                                            required={true}
                                            validation={this.state.required}
                                            time={true}
                                            disabled={this.props.bid.type === "FIRST_OFFER" && this.props.trade.privacyType === "TENDER"}
                                            name="bidValidateDate"
                                            minDate={this.props.bid.reCounterInd ? moment(this.props.bid.bidValidateDate): moment().add(1, 'hours').minutes(0).seconds(0)}
                                            item={this.state.request.bidValidateDate}
                                            label={true}
                                            onSelect={(name, onSelectDate) => {
                                                if(this.props.bid.reCounterInd && !this.state.request.bidValidateDate.value){
                                                    const date =  moment(this.props.bid.bidValidateDate).set("hour", onSelectDate.hour()).set("minute", onSelectDate.minutes()).set("second", onSelectDate.seconds());
                                                    this.setField(name, date);
                                                }else{
                                                    this.setField(name, onSelectDate);
                                                }
                                            }} />
                                    </div>
                                    {this.props.trade.privacyType !== "TENDER" && this.state.request.origin.value.length > 1 && <div className="col-md-12">
                                        <div className="trades-dtls__error-message text-center">
                                            Please select only one origin from the dropdown
                                    </div>
                                    </div>}
                                    <div className="col-md-12">
                                        <div className="text-center mb-2 small">Click {bid.bankOnlyInd ? `'Submit'` : `'Review'`}  to proceed to the next step or click 'Back' to
                                        return previous
                                        screen.
                                    </div>
                                    </div>
                                </div>
                                <div className="text-center mb-3">
                                    <button className="btn btn--grey" type="button" onClick={this.props.onClose}>
                                        <FontAwesomeIcon icon={faTimes} /> Back
                                </button>
                                    <button className="btn btn--blue bc-btn-radius" type="button" onClick={this.onReview}>
                                        <FontAwesomeIcon icon={faCheck} /> {bid.bankOnlyInd ? 'Submit' : 'Review'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default CounterProposal;