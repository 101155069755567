import React, { useState } from 'react';
import moment from 'moment-timezone';
import { DATEFORMATHOURS } from '../../../../../services/service.values';
import FormDateField from '../../../../components/form/FormDateField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Preloader from '../../../../components/preloader/Preloader';

const AlternateBankRequestModal = props => {
    const [request, setRequest] = useState({
        bidValidateDate: {
            name: 'bidValidateDate',
            label: 'Validity of your request',
            value: null,
            required: value => !value || value === undefined || value === '' || moment().diff(value) > 0
        }
    })
    const [required, setRequired] = useState({});

    const setField = (name, value) => {
        if(name === 'bidValidateDate'){
            if (typeof value === 'object' && value !== null) {
                let updatedRequest = {...request};
                updatedRequest[name] = {
                    ...updatedRequest[name],
                    value: value
                };
                setRequest(updatedRequest);
                setRequired({});
            }
        }
    }

    const validate = () => {
        let updatedRequired = {};
        let container = {...request};
        Object.keys(container).map(key => {
            let v = container[key];
            if (v && v.required && v.required(v.value)) {
                updatedRequired[key] = v;
            }
            return false;
        });
        if (Object.keys(updatedRequired).length > 0) {
            setRequired(updatedRequired);
            return false;
        }
        return true;
    }

    const onSubmitHandler = () => {
        if(validate()){
            props.onSubmit(true, request.bidValidateDate.value)
        }
    }

    return(
        <div className="modal__container">
            <div className="modal__wrapper px-0 py-0">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100">Request Alternative Bank</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={props.onCancel} aria-label="Close">
                            <span aria-hidden="true" >&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    You are about to ask the buyer to provide alternative bank details. Click SUBMIT to confirm the request or BACK to return to the previous screen. 
                                </p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">   
                            <div className="col-md-12">
                                <FormDateField
                                    customStyle={{
                                        wrapperClassName : "form-group",
                                        labelClassName :"label",
                                        inputClassName : "form-control",
                                        selectClassName : "custom-select",
                                        radioColLayout: {left : "col-12", right : "col-12"},
                                        isInvalidClassName: "is-invalid select_error",
                                        isValidClassName: 'is-valid',
                                    }}            
                                    dateFormat={DATEFORMATHOURS}
                                    required={true}
                                    validation={required}
                                    time={true}
                                    name="bidValidateDate"
                                    minDate={moment().add(1, 'hours').minutes(0).seconds(0)}
                                    item={request.bidValidateDate}
                                    label={true}
                                    onSelect={(name, onSelectDate) => setField(name, onSelectDate)}
                                    popperPlacement="right-start"
                                    />
                            </div>
                        </div>
                    </div>
                    
                    <div style={{marginTop: '160px'}}>
                        {Object.keys(required).length > 0 && <div className="text-center mb-2 large">Please fill all the required fields.</div>}
                        <div className="modal-footer" >
                            <button className={`btn-popup btn-cancel`} type="button" onClick={props.onCancel} >
                                <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                            </button>
                            <button className={`btn-popup btn-submit`}  type="button" onClick={onSubmitHandler} >
                                <Preloader loadingStyle="dots" loading={props.loading}>
                                    <FontAwesomeIcon icon={faCheck} />&nbsp;Submit
                                </Preloader>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlternateBankRequestModal;