import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserPermission } from '../../modules/module.adminManagement';

import './view-permissions-style.scss';

import Preloader from '../components/preloader/Preloader';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

class ViewUserPermission extends Component {
    state = {
        showModal: false,
    };

    UNSAFE_componentWillMount = () => {
        this.fetchFunction();
    };

    fetchFunction = () => {
        let userId = this.props.id;
        this.props.getUserPermission(userId);
    };

    getItems = () => {
        let permissionData = [];
        let keyValuePair = {};
        let permissionArray = {};
        let permissionAll = null;
        let userPermission = this.props.usermanagment.userPermission;
        permissionArray = Object.entries(userPermission).map(([key, value]) => {
            return userPermission[key];
        })
        Object.keys(permissionArray).forEach(function (key) {
            let createTradeRequest = permissionArray[key].permissions.createTradeRequest ? " Create Trade Request," : "";
            let viewTradeRequest = permissionArray[key].permissions.viewTradeRequest ? " View Trade Request," : "";
            let negotiateTradeRequest = permissionArray[key].permissions.negotiateTradeRequest ? " Negotiate Trade Request," : "";
            let viewTradeList = permissionArray[key].permissions.viewTradeList ? " View Trade List," : "";
            let uploadBagMarking = permissionArray[key].permissions.uploadBagMarking ? " Upload Bag Marking," : "";
            let viewBagMarkings = permissionArray[key].permissions.viewBagMarkings ? " View Bag Markings," : "";
            let approveBagMarking = permissionArray[key].permissions.approveBagMarking ? " Approve Bag Marking," : "";
            let signContract = permissionArray[key].permissions.signContract ? " Sign Contract," : "";
            let viewContract = permissionArray[key].permissions.viewContract ? " View Contract," : "";
            let sendVessalNomination = permissionArray[key].permissions.sendVessalNomination ? " Send Vessal Nomination," : "";
            let approveVessalNomination = permissionArray[key].permissions.approveVessalNomination ? " Approve Vessal Nomination," : "";
            let sendDocInstructions = permissionArray[key].permissions.sendDocInstructions ? " Send Documentary Instructions," : "";
            let viewDocInstructions = permissionArray[key].permissions.viewDocInstructions ? " View Documentary Instructions," : "";
            let issueDocument = permissionArray[key].permissions.issueDocument ? " Issue Document," : "";
            let uploadDocument = permissionArray[key].permissions.uploadDocument ? " Upload Document," : "";
            let approveDocument = permissionArray[key].permissions.approveDocument ? " Approve Document," : "";
            let releaseDocument = permissionArray[key].permissions.releaseDocument ? " Release Document," : "";
            let payment = permissionArray[key].permissions.payment ? " Payment," : "";
            let confirmPayment = permissionArray[key].permissions.confirmPayment ? " Confirm Payment," : "";
            let viewExchange = permissionArray[key].permissions.viewExchange ? " View Exchange," : "";
            let viewCompanyProfile = permissionArray[key].permissions.viewCompanyProfile ? " View Company Profile," : "";
            let manageTenderWhitelist = permissionArray[key].permissions.manageTenderWhitelist ? "Manage Tender Whitelist," : "";
            let bypassAuthorisationCode = permissionArray[key].permissions.bypassAuthorisationCode ? " Allow Bypass of Authorisation Code," : "";
            let negotiateShippingQuote = permissionArray[key].permissions.negotiateShippingQuote ? " Negotiate Shipping Quote," : "";
            let viewShippingQuote = permissionArray[key].permissions.viewShippingQuote ? " View Shipping Quote," : "";
            let negotiateInspectionQuote = permissionArray[key].permissions.negotiateInspectionQuote ? " Negotiate Inspection Quote," : "";
            let viewInspectionQuote = permissionArray[key].permissions.viewInspectionQuote ? " View Inspection Quote," : "";
            
            permissionAll = createTradeRequest + "" + viewTradeRequest + "" + negotiateTradeRequest + "" + negotiateShippingQuote +
                "" + viewShippingQuote + "" + negotiateInspectionQuote + "" + viewInspectionQuote + "" + viewTradeList + "" + uploadBagMarking +
                "" + viewBagMarkings + "" + approveBagMarking + "" + signContract + "" + viewContract + "" + sendVessalNomination +
                "" + approveVessalNomination + "" + sendDocInstructions + "" + viewDocInstructions + "" + issueDocument + "" + uploadDocument +
                "" + approveDocument + "" + releaseDocument + "" + payment + "" + confirmPayment + "" + viewExchange + "" + viewCompanyProfile + "" + bypassAuthorisationCode + "" + manageTenderWhitelist;

            keyValuePair.groupName = permissionArray[key].groupName;
            keyValuePair.permissionList = permissionAll.substring(0, permissionAll.length - 1);

            permissionData.push(keyValuePair);
            keyValuePair = {};
        });
        return permissionData;
    };



    render() {

        return (
            <React.Fragment>
                <Preloader loadingStyle="swirl" loading={this.props.loading}>
                    <MaterialTable
                        columns={[     
                            { 
                                title: 'Group Name', 
                                field: 'groupName',
                                render: rowData => (
                                    <div >
                                        <span className="table-col-text">{rowData.groupName}</span>
                                    </div>
                                )
                            },
                            { title: 'Permission List', field: 'permissionList' },
                        ]}
                        data={this.getItems()}
                        title=""
                        icons={{
                            Filter: FilterList,
                            FirstPage: FirstPage,
                            LastPage: LastPage,
                            NextPage: ChevronRight,
                            PreviousPage: ChevronLeft,

                            SortArrow: ArrowUpward,
                            Add: Add,
                            Edit: Edit,
                            DeleteOutline: DeleteOutline,
                            ResetSearch: Clear,
                            SaveIcon: SaveIcon
                        }}

                        options={{
                            search: false,
                            pageSize: 5,
                            rowStyle: (rowData, index) => {
                                const backgroundColor = (index % 2 === 0) ? '#00ff301c' : '#FBFBFB';
                                return { borderColor: '#c3e6cb', backgroundColor: backgroundColor }
                            },
                            thirdSortClick: false
                        }}
                    />
                </Preloader>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        usermanagment: state.usermanagment,
        loading: state.loading.permissionLoading
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUserPermission
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ViewUserPermission);
