export default class Shipment {
    // Constructor Adaptor, takes shippingAdvice, or vesselNomination as argument
    constructor(args) {
        const {
            shippingTolerance,
            nominateQuantity,
            shippingQuantity,
            rejectMessage,
            vessVoyage,
            name,
            laycanDateFrom,
            laycanDateTo,
            billNumber,
            shippedOnBoard,
            quantCleanOnBoard,
            ref   
        } = args;

        this.ref = ref;
        this.tolerance = shippingTolerance;
        this.name = vessVoyage || name;
        
        if (nominateQuantity) {
            this.quantity = nominateQuantity
            this.type = 'NOMINATED'
        }
        if (quantCleanOnBoard) {
            this.quantity = quantCleanOnBoard
            this.type = 'SHIPPED'
        }
        if (billNumber) {
            this.billNumber = billNumber
        }
        if (shippedOnBoard) {
            this.shippedOnBoard = shippedOnBoard
        }
        if (rejectMessage) {
            this.status = 'REJECTED'
        }

        
    }

}