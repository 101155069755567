import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFinalProviderQuoteList } from '../../../../modules/module.inspectionQuote';
import {push} from "connected-react-router";
import { Link } from 'react-router-dom';
import moment from 'moment';
import NumberFormat from 'react-number-format'; 
//COMPONENTS
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { viewInspectionQuotePermission } from '../../../../services/service.permission';
import '../trades.scss';
import { DATEFORMATHOURS,QUOTETYPE,QUOTESTATUS } from '../../../../services/service.values';
import Preloader from '../../../components/preloader/Preloader';
import MaterialTable from 'material-table';
import { EnumsService,PortService } from '../../../../services/service.utils';
import Search from '@material-ui/icons/Search';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
class FinalInspectionList extends Component {
    constructor(props) {
        super(props);
        this.getCountryName = this.getCountryName.bind(this);

    }
    state = {

        showModal: true,
        modal: '',
        modalData: {},
    };

    UNSAFE_componentWillMount = () => {
        this.fetchFunction();
    };

    fetchFunction = () => {
        this.props.getFinalProviderQuoteList(QUOTETYPE.FINAL);

        this.setState({});
    };
    getCountryName(isoCode) {

        const countryName = EnumsService.countries()[isoCode.trim()];
        return countryName;
    }

    renderPortLabel = value => {
        let label = "";
        if(value){
            label = PortService.getJSON()[value]?PortService.getJSON()[value].name : value;
        }
        return label;    
    }
   

    getItems = () => {
        var quoteFinalList = this.props.inspectionQuote.finalProvderList.quotes;
        let filterFinalList = [];
        if (quoteFinalList !== undefined) {
            for (var i = 0; i < quoteFinalList.length; i++) {
                if (quoteFinalList[i].status !== QUOTESTATUS.REJECTED) {
                    filterFinalList.push(quoteFinalList[i]);
                }
            }
        }

        return filterFinalList;
    };
    getItemsTwo = () => {
        let quoteFilterList = this.props.inspectionQuote.finalProvderList.quotes;
        let filterFinalList = [];
        if (quoteFilterList !== undefined) {
            for (var i = 0; i < quoteFilterList.length; i++) {
                if (quoteFilterList[i].status === QUOTESTATUS.REJECTED) {
                    filterFinalList.push(quoteFilterList[i]);
                }
            }
        }
        return filterFinalList;
    };


    returnStatusNumber = (status) => {
        switch (status) {
            case QUOTESTATUS.NEW:
                return "quote-status pending";
            case QUOTESTATUS.CANCELLED:
                return "quote-status orange";
            case QUOTESTATUS.COMPLETED:
                return "quote-status green";
            case QUOTESTATUS.REJECTED:
                return "quote-status primary";
            case QUOTESTATUS.INPROGRESS:
                return "quote-status gray";
            default:
                break;
        }
    };

    renderStatusLabel = (status) => {
        let label = "";
        switch (status) {
            case QUOTESTATUS.NEW: label = "New Final Request"; break;
            case QUOTESTATUS.INPROGRESS: label = "Offer Sent"; break;
            case QUOTESTATUS.COMPLETED: label = "Completed"; break;
            case QUOTESTATUS.CANCELLED: label = "Cancelled"; break;
            case QUOTESTATUS.REJECTED: label = "Rejected"; break;
            default: 
            break;
        }
        return label;

    }

    renderModal = () => {
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Link>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>
                                </div>
                                <div className="modal-footer">
                                    <Link to="/" className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                );
    };

    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, modalData: data });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            modalData: {},

        });
    };



    render() {
        if (viewInspectionQuotePermission() === 0) {
            return (
                <div>
                    {this.state.showModal && this.renderModal()}
                </div>
            );
        }
        return (
            <React.Fragment>
                <Header />
                <div className="trades-req-quotes-Container">
                    <div className="Container">
                        <div className="trades-req-quotes__heading"> <h3 className=" mb-1">Requests for Final Offer</h3></div>
                        <nav className="mb-1">
                            <ol className="breadcrumb">
                                {/* <li className="breadcrumb-item"><a href="#"><FontAwesomeIcon icon={faAngleLeft}/> Back</a> </li> */}
                            </ol>
                        </nav>

                        <div className="content-wrappper trades-req-quotes">
                            <p>The following companies have requested a final offer for the Inspection.</p>
                            <div className="table-responsive-md">
                                <Preloader loadingStyle="swirl" loading={this.props.loading}>
                                    <MaterialTable
                                        columns={[
                                            { title: 'Trade Ref Id', field: 'tradeRefID',render: rowData => rowData.tradeRefID || rowData.tradeRequestId},
                                            { title: 'Name', field: 'fromCompanyName'},
                                            // { title: 'VatNumber', field: 'vatNumber'},
                                            { 
                                                title: 'Origin', field: 'originPort',
                                                render: rowData => <span className="trades__overflow">{this.getCountryName(rowData.originPort)}    
                                                </span> 
                                            },
                                            {   
                                                title: 'Destination Port', field: 'destinationPort',
                                                render: rowData => <span className="trades__overflow">{rowData.destinationPort==="" ?"--": this.renderPortLabel(rowData.destinationPort)}    
                                                </span>
                                            },
                                            { 
                                                title: 'Quantity(MT)', field: 'quantity',
                                                render: rowData => <NumberFormat 
                                                    value={rowData.quantity} 
                                                    displayType={'text'} 
                                                    thousandSeparator={true} 
                                                    suffix = {" MT"}>
                                                </NumberFormat>
                                            },
                                            { 
                                                title: 'Rate', field: 'rate',

                                                render: rowData => <NumberFormat 
                                                    value={rowData.rate} 
                                                    displayType={'text'} 
                                                    thousandSeparator={true}
                                                    renderText={value => <span className="trades__overflow">{value!=="" ? '$ '+value : '--'}</span>} >
                                                </NumberFormat>
                                            },
                                            { title: 'Status', field: 'status',
                                                render: rowData => <span className={this.returnStatusNumber(rowData.status)} >{this.renderStatusLabel(rowData.status)} </span> 
                                            },
                                            { title: 'Requested', field: 'requestedDate',defaultSort: 'desc',
                                                render: rowData => <span
                                                className="trades__overflow">{moment(rowData.requestedDate).format(DATEFORMATHOURS)}</span>},
                                            { title: 'Response', field: 'responseDate',
                                                render: rowData => <span
                                                className="trades__overflow">{(rowData.responseDate!==""? moment(rowData.responseDate).format(DATEFORMATHOURS):"--")}</span>},
                                            { 
                                                title: 'Action',
                                                sorting: false,
                                                render: rowData => <div className="action-btn">
                                                    <Tooltip title="Details of Inspection " placement="top-start" arrow >
                                                        <Link className="btn-circle circle-btn admin" to={`/inspectionQuoteDetails/${rowData.tradeRequestId}/${rowData.inspectionQuoteId}`}> 
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </Link>
                                                    </Tooltip>
                                                </div>
                                            },
                                        ]}
                                        data={this.getItems()}
                                        title=""
                                        icons={{
                                            Filter: FilterList,
                                            FirstPage: FirstPage,
                                            LastPage: LastPage,
                                            NextPage: ChevronRight,
                                            PreviousPage: ChevronLeft,
                                            Search: Search,
                                            SortArrow: ArrowUpward,
                                            Add: Add,
                                            Edit: Edit,
                                            DeleteOutline: DeleteOutline,
                                            ResetSearch: Clear,
                                            SaveIcon: SaveIcon
                                        }}
                                        options={{
                                            pageSize: 5,
                                            pageSizeOptions: [10, 15, 20, 30],
                                            rowStyle: {
                                                borderColor: '#c3e6cb',
                                                backgroundColor: '##00ff301c'
                                            },
                                            thirdSortClick: false
                                        }}/>
                                </Preloader>
                            </div>
                        </div>
                        <div className="content-wrappper trades-req-quotes">
                            <p>The following companies have requested a final offer for the Inspection however you have rejected the request
                            this is can not be undone and status is final.
                            </p>
                            <div className="table-responsive-md">
                                <Preloader loadingStyle="swirl" loading={this.props.loading}>
                                    <MaterialTable
                                        columns={[
                                            { title: 'Trade Ref Id', field: 'tradeRefID',render: rowData => rowData.tradeRefID || rowData.tradeRequestId},
                                            { title: 'Name', field: 'fromCompanyName'},
                                            // { title: 'VatNumber', field: 'vatNumber'},
                                            { title: 'Origin', field: 'originPort',
                                            render: rowData => <span className="trades__overflow">{this.getCountryName(rowData.originPort)}    
                                            </span> 
                                            },
                                            { title: 'Destination Port', field: 'destinationPort',
                                                render: rowData => <span className="trades__overflow">{rowData.destinationPort==="" ?"--": this.renderPortLabel(rowData.destinationPort)}    
                                                </span>},
                                            { title: 'Quantity(MT)', field: 'quantity', 
                                                render: rowData => <NumberFormat 
                                                    value={rowData.quantity} 
                                                    displayType={'text'} 
                                                    thousandSeparator={true}
                                                    suffix = {" MT"} >
                                                </NumberFormat>},
                                            { title: 'Rate', field: 'rate',
                                                render: rowData => <NumberFormat 
                                                        value={rowData.rate} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true}
                                                        renderText={value => <span className="trades__overflow">{value!=="" ? '$ '+value : '--'}</span>} >
                                                    </NumberFormat>
                                            },
                                            { title: 'Status', field: 'status',
                                                render: rowData => <span className={this.returnStatusNumber(rowData.status)} >{this.renderStatusLabel(rowData.status)}    
                                                            </span> 
                                            },
                                            { title: 'Requested', field: 'requestedDate',defaultSort: 'desc',
                                            render: rowData => <span
                                            className="trades__overflow">{moment(rowData.requestedDate).format(DATEFORMATHOURS)}</span>},
                                            { title: 'Response', field: 'responseDate',
                                            render: rowData => <span
                                            className="trades__overflow">{(rowData.responseDate!==""? moment(rowData.responseDate).format(DATEFORMATHOURS):"--")}</span>},
                                            { title: 'Action', sorting: false, render: rowData => <div className="action-btn">
                                                    <Tooltip title="Details of Inspection " placement="top-start" arrow >
                                                        <Link className="btn-circle circle-btn admin" to={`/inspectionQuoteDetails/${rowData.tradeRequestId}/${rowData.inspectionQuoteId}`}> 
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </Link>
                                                    </Tooltip>
                                                </div>
                                            },
                                        ]}
                                        data={this.getItemsTwo()}
                                        title=""
                                        icons={{
                                            Filter: FilterList,
                                            FirstPage: FirstPage,
                                            LastPage: LastPage,
                                            NextPage: ChevronRight,
                                            PreviousPage: ChevronLeft,
                                            Search: Search,
                                            SortArrow: ArrowUpward,
                                            Add: Add,
                                            Edit: Edit,
                                            DeleteOutline: DeleteOutline,
                                            ResetSearch: Clear,
                                            SaveIcon: SaveIcon
                                        }}
                                        options={{
                                            pageSize: 5,
                                            pageSizeOptions: [10, 15, 20, 30],
                                            rowStyle: {
                                                borderColor: '#c3e6cb',
                                                backgroundColor: '##00ff301c'
                                            },
                                            thirdSortClick: false
                                        }}
                                    />
                                </Preloader>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );

    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        user: state.account.user,
        inspectionQuote: state.inspectionQuote,
        loading: state.loading.groupLoading,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getFinalProviderQuoteList,
            navigate: path => push('/finalInspectionList' + path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FinalInspectionList);