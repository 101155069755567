import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import { DATEFORMATHOURS} from '../../../../services/service.values';
import { subscribe, getTenderSubscriptions, approve, reject, clearErrors } from '../../../../modules/module.tender';

import ConfirmModalTenderApprove from '../components/confirmModals/ConfirmModalTenderApprove';
import ConfirmModalTenderSubscribe from '../components/confirmModals/ConfirmModalTenderSubscribe';
import ConfirmModalTenderReject from '../components/confirmModals/ConfirmModalTenderReject';
import { Link } from 'react-router-dom';
import TradeDocPreviewModal from '../components/TradeDocPreviewModal';
import Preloader from '../../../components/preloader/Preloader';



function SubscriptionList({ trade, bids, getTenderSubscriptions, subscribe, approve, reject, account, tender, onSelect, selected, clearErrors }) {

    const [showTermAndConditions, setShowTermAndConditions] = useState(false);

    useEffect(() => {
        getTenderSubscriptions(trade.id);
    }, [trade.id, getTenderSubscriptions]);

    const userSubscription = useMemo(() => {
        return tender.tenderSubscriptions.length !== 0 ? tender.tenderSubscriptions[0] : null
    }, [tender]);

    const isOwner = useMemo(() => {
        return trade.ownerId === account.user.companyId;
    }, [trade, account]);

    const hasBid = (companyId) => {
        for( let bid of bids) {
            if([bid.fromCompanyID, bid.toCompanyID].includes(companyId)){
                return true;
            }
        }
        return false;
    }
    
    return <>
        <div className='row'>
            <div className='col-sm-12'>
                <h3 className='request-dtls__card-heading'>{isOwner ? 'Subscribers List' : 'Tender Subscription Details'}</h3>

            </div>
            <div className='col-sm-12'>
                <Preloader loadingStyle='swirl' loading={tender.getTenderSubscriptionsPending}>
                    <div className='table-responsive mt-2' style={{ maxHeight: '200px' }}>
                        {isOwner ?
                            tender.tenderSubscriptions.length !== 0 ?
                                <table className='table table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Subscriber Name</th>
                                            <th>Subscribed On</th>
                                            <th>Status</th>
                                            <th>Approved/Rejected On</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                          { tender.tenderSubscriptions.map(subscription => {
                                            const { subscribedAt, approvedAt, rejectedAt } = subscription;
                                            return (<tr key={subscription.subscriptionID} 
                                                className={(selected === subscription.subscribedByUser.companyID) ? "table-info" : (subscription.status === 'REJECTED') ? "table-dark" : ""}
                                                onClick={e => onSelect(subscription)}>
                                                <td><Link to={`/company-profile/${subscription.subscribedByUser.companyID}`}>{`${subscription.subscribedByUser.companyName} (${subscription.subscribedByUser.userName})`}</Link></td>
                                                <td>{moment(subscribedAt).format(DATEFORMATHOURS)}</td>
                                                <td>
                                                    <div className={`requests__row_${subscription.status.toLowerCase()}`}>
                                                        <span className="status">{subscription.status.toLowerCase()}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {(subscription.status === 'REJECTED' && rejectedAt) &&
                                                        moment(rejectedAt).format(DATEFORMATHOURS)}
                                                    {(subscription.status === 'APPROVED' && approvedAt) &&
                                                        moment(approvedAt).format(DATEFORMATHOURS)}
                                                </td>
                                                <td>
                                                    <>
                                                    {((moment(trade.validateDate).isAfter(moment(),'minutes') && subscription.status === "REJECTED") || subscription.status === "PENDING") &&
                                                    <ConfirmModalTenderApprove
                                                        buttonClass='bc-counter mx-1'
                                                        buttonLabel="Approve"
                                                        subscription={subscription}
                                                        loading={subscription.approvePending}
                                                        onCancel={e => clearErrors()}
                                                        onConfirm={(e) => approve(subscription.tradeID, subscription.subscriptionID)} /> }
                                                    {((moment(trade.validateDate).isAfter(moment(),'minutes') && subscription.status === "APPROVED" && !hasBid(subscription.subscribedByUser.companyID)) || subscription.status === "PENDING") &&
                                                    <ConfirmModalTenderReject
                                                        buttonClass='bc-counter mx-1'
                                                        buttonLabel="Reject"
                                                        subscription={subscription}
                                                        loading={subscription.rejectPending}
                                                        onCancel={e => clearErrors()}
                                                        onConfirm={(e) => reject(subscription.tradeID, subscription.subscriptionID)} /> }
                                                    </>
                                                </td>
                                            </tr>)
                                        })}

                                    </tbody>
                                </table>
                                :
                                <span>No subscription received</span>
                            :
                            <table className='table table-hover'>
                                <thead>
                                    <tr>
                                        <th>Trade Owner</th>
                                        <th>Subscribed On</th>
                                        <th>Status</th>
                                        <th>Approved/Rejected On</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={`table-info ${(userSubscription && userSubscription.status === 'REJECTED') ? "table-dark" : ""}`}>
                                        <td>{trade.requestType === 'BUY' ? <Link to={`/company-profile/${trade.buyerId}`}>{trade.buyer}</Link> : <Link to={`/company-profile/${trade.sellerId}`}>{trade.seller}</Link>}</td>
                                        <td>{userSubscription ? moment(userSubscription.subscribedAt).format(DATEFORMATHOURS) : "-"}</td>
                                        <td>{userSubscription ?
                                            <div className={`requests__row_${userSubscription.status.toLowerCase()}`}>
                                                <span className="status">{userSubscription.status.toLowerCase()}</span>
                                            </div>
                                            : 
                                            "-"}
                                        </td>
                                        <td>
                                            {(userSubscription && userSubscription.status === 'REJECTED') && moment(userSubscription.rejectedAt).format(DATEFORMATHOURS)}
                                            {(userSubscription && userSubscription.status === 'APPROVED') && moment(userSubscription.approvedAt).format(DATEFORMATHOURS)}
                                        </td>
                                        <td>
                                            <>
                                                {(!userSubscription && moment(trade.validateDate).isAfter()) &&
                                                    <ConfirmModalTenderSubscribe
                                                        buttonClass='bc-counter mx-1'
                                                        buttonLabel="Subscribe to Tender"
                                                        closeOnSubmit={false}
                                                        trade={trade}
                                                        loading={tender.subscribePending}
                                                        disabled={tender.subscribePending}
                                                        error={tender.subscribeError}
                                                        onCancel={e => clearErrors()}
                                                        onConfirm={e => subscribe(trade.id)} />}
                                                <button type='button' className='bc-counter mx-1' onClick={e => setShowTermAndConditions(true)}>Tender T&C</button>
                                            </>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </div>
                </Preloader>
            </div>
        </div>
        {showTermAndConditions && <TradeDocPreviewModal doc={trade.tenderTermsDoc[0]} onClose={e => setShowTermAndConditions(false)} />}
    

    </>
}



const mapStateToProps = state => {
    return {
        bids: state.trade.bids,
        trade: state.trade.items.single || {},
        tender: state.tender,
        account: state.account,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ subscribe, getTenderSubscriptions, approve, reject, clearErrors }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionList);