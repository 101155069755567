import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import CustomMaterialPreloader from '../../components/preloader/CustomMaterialPreloader';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const TenderWhitelistCompanyAddition = (props) => {
    return ( 
        <div className="modal__container">
            <div className="modal__wrapper px-0 py-0">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-header-center">
                            <h5 className="modal-title">
                                Add Company To Tender Whitelist Group
                            </h5>
                        </div>
                        <button 
                            type="button" 
                            className="close" 
                            data-dismiss="modal" 
                            onClick={props.onCancel} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <MaterialTable
                            columns={[{
                                title: 'Company Name', field: 'name'
                            },{
                                title: 'Company Type', field: 'companyType'
                            }]}
                            data={props.counterParties}
                            title=""
                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                SortArrow: ArrowUpward,
                                ResetSearch: Clear
                            }}
                            actions={[{
                                tooltip: 'Add Company',
                                icon: () => <AddCircleIcon color="primary" fontSize="large"/>,
                                onClick: (event, rowData) => {
                                    props.onAddition(rowData)
                                },
                                sorting: false
                            }]}
                            options={{
                                actionsColumnIndex: -1,
                                rowStyle: (rowData, index) => {
                                    const backgroundColor = (index%2 === 0) ? '#00ff301c' : '#FBFBFB';
                                    return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                                },
                                pageSize: 5,
                                thirdSortClick: false
                            }}
                            components={{
                                OverlayLoading: CustomMaterialPreloader
                            }}
                            isLoading={props.loading} 
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn-popup btn-cancel" onClick={props.onCancel}>
                            <FontAwesomeIcon icon={faTimes} /> Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default TenderWhitelistCompanyAddition;