import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import FormTextareaField from '../../../components/form/FormTextareaField';

const Footer = ({onConfirm}) => (
    <div className="my-3 text-center">
        <button type="button" className="btn btn-primary" onClick={onConfirm}>
            <FontAwesomeIcon icon={faCheck} />&nbsp;<span>Ok</span>
        </button>
    </div>
)

export default function ContractFulfillmentRejectReason({rejectReason, onClose}) {
    return (
        <Modal
            title="Reason of Rejection"
            onClose={onClose}
            onConfirm={onClose}
            components={{
                footer: Footer
            }}
        >
            <form>
                <FormTextareaField
                    maxLength="300"
                    rows="3"
                    cols="70"
                    name="rejectReason"
                    value={{
                        label: "",
                        value: rejectReason,
                        name: "rejectReason"
                    }}
                    disabled
                    onChange={() => {}}
                />
            </form>
        </Modal>
    )
}

Footer.propTypes = {
    onConfirm: PropTypes.func
}

ContractFulfillmentRejectReason.propTypes = {
    rejectReason: PropTypes.string,
    onClose: PropTypes.func
}