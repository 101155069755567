import React from 'react';

const BankDetails = ({ bank }) => (
    <div>
        <h4>Bank Reference</h4>
        {bank ? (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Bank Name
                            </div>
                            <div className="detail-text">
                                {bank.name}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Relationship Manager Name
                            </div>
                            <div className="detail-text">
                                {bank.managerName}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Email
                            </div>
                            <div className="detail-text">
                                {bank.email}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Phone No.
                            </div>
                            <div className="detail-text">
                                {bank.phone}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Address
                            </div>
                            <div className="detail-text">
                                {bank.address}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">

                    </div>
                </div>
            </React.Fragment>
        ) : "Bank Reference Not Found"}
    </div>
);

export default BankDetails;