import React from "react";
import { Route, NavLink } from 'react-router-dom';
import Header from '../components/header/Header';
import './adminManagement-style.scss';
import './modals.scss';
import UserGroups from './UserGroups';
import Permission from './Permission';
import GroupsUserList from './GroupsUserList';
import ProviderList from './ProviderList';
import ProviderKycList from './ProviderKycList';
import CompanyKycInfo from './CompanyKycInfo';
import UserList from './UserList';
import AuthorisationCode from './AuthorisationCode';
import AccessControl from '../components/AccessControl';
import { UserTypes } from '../../utils/userTypes';
import { CompanyTypes } from '../../utils/companyTypes';
import AdditionalEmails from './AdditionalEmails';


const AdminManagementInfo = (user) => (
  <React.Fragment>
    <Header />
    <div className="trades-dtls__info">
      <div className="trades-dtls__tabs">
        <NavLink to={`/adminManagement/user-groups`}
          className="trades-dtls__tab trades-dtls__tab_active">
          User Groups
        </NavLink>
        <NavLink to={`/adminManagement/user-list`}
          className="trades-dtls__tab">
          Users
        </NavLink>
        <AccessControl user={user.user} excludeUserTypes={[UserTypes.PLATFORM_ADMIN, UserTypes.COMPANY_EMPLOYEE, UserTypes.KYC_USER]} companyTypes={[CompanyTypes.IMPORTER, CompanyTypes.EXPORTER, CompanyTypes.DISTRIBUTOR, CompanyTypes.TRADER]}>
          <NavLink to={`/adminManagement/provider-list`}
            className="trades-dtls__tab">
            Service Providers
        </NavLink>
        </AccessControl>
        <AccessControl user={user.user} excludeUserTypes={[UserTypes.PLATFORM_ADMIN, UserTypes.COMPANY_EMPLOYEE, UserTypes.KYC_USER]} companyTypes={[CompanyTypes.IMPORTER, CompanyTypes.EXPORTER, CompanyTypes.DISTRIBUTOR, CompanyTypes.TRADER]}>
          <NavLink to={`/adminManagement/auth-code`}
            className="trades-dtls__tab">
            Authorisation Code
        </NavLink>
        </AccessControl>
        <AccessControl user={user.user} excludeUserTypes={[UserTypes.PLATFORM_ADMIN, UserTypes.COMPANY_EMPLOYEE, UserTypes.KYC_USER]} excludeCompanyTypes={[CompanyTypes.IMPORTER, CompanyTypes.EXPORTER, CompanyTypes.DISTRIBUTOR, CompanyTypes.TRADER]}>
          <NavLink to={`/adminManagement/emails`}
            className="trades-dtls__tab">
            CC Emails
        </NavLink>
        </AccessControl>
        <AccessControl user={user.user} excludeUserTypes={[UserTypes.PLATFORM_ADMIN, UserTypes.COMPANY_EMPLOYEE, UserTypes.KYC_USER]} excludeCompanyTypes={[CompanyTypes.IMPORTER, CompanyTypes.EXPORTER, CompanyTypes.DISTRIBUTOR, CompanyTypes.TRADER]}>
          <NavLink to={`/adminManagement/provider-kyc-list`}
            className="trades-dtls__tab">
            KYC Service
        </NavLink>
        </AccessControl>
      </div>
    </div>

    <Route
      exact
      path={`/adminManagement/user-groups`}
      render={() => <UserGroups />}
    />
    <Route
      exact
      path={`/adminManagement/user-list`}
      render={() => <UserList />}
    />
    <Route
      exact
      path={`/adminManagement/permission/:id?/:name?`}
      render={(props) => <Permission id={props.match.params.id} name={props.match.params.name} />}
    />
    <Route
      exact
      path={`/adminManagement/group-userlist/:id?/:name?`}
      //    render={() => <GroupsUserList/>}
      render={(props) => <GroupsUserList id={props.match.params.id} name={props.match.params.name} />}
    />
    <Route
      exact
      path={`/adminManagement/provider-list`}
      render={(props) => <ProviderList {...props} />}
    />
    <Route
      exact
      path={`/adminManagement/emails`}
      render={(props) => <AdditionalEmails {...props} />}
    />
    <Route
      exact
      path={`/adminManagement/auth-code`}
      render={(props) => <AuthorisationCode {...props} />}
    />
    <Route
      exact
      path={`/adminManagement/provider-kyc-list`}
      render={(props) => <ProviderKycList {...props} />}
    />
    <Route
      exact
      path={`/adminManagement/company/:id/kyc-info`}
      render={(props) => <CompanyKycInfo {...props} />}
    />
  </React.Fragment >
);

export default AdminManagementInfo;
