import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import store from './store';
import App from './app';
import 'bootstrap/dist/js/bootstrap.min';
import reportWebVitals from './reportWebVitals';

//Styles
import 'sanitize.css/sanitize.css';
import './static/style/index.scss'

const root = document.getElementById('root');
render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();