import React from 'react';
import { DATEFORMAT,TOOLTIP_TITLE } from '../../../../services/service.values';
import moment from 'moment-timezone';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailTable from '../../requests/detail/DetailTable';
import Preloader from '../../../components/preloader/Preloader';
import { Tooltip } from '@material-ui/core';
import { faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';


export const getInspectionCompanyName = (companyName, serie,inspector) => {
        if (inspector === "1") {
            return <>
                <Tooltip title={TOOLTIP_TITLE.NON_INSPECTOR_TITLE} placement="top-start" arrow>
                    <span>{companyName}&nbsp;
                    <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} /></span>
                </Tooltip>
            </>
        }
        else {
            return companyName;
        }
};

export const viewSubstitueVesselInfo = ({
    onApproveWithNewLaycan, 
    onClose, 
    onReject, 
    vesselInfo,  
    whoItIs,
    incoterm, 
    isLaycanDateChanged, 
    LoadingNominationInfo, 
    vesselAccepted,
    shippingType,
    inspector,
    tradeRefId=''
}) => {

        // let vesselNominationInfo = {...vesselInfo, inspectionName: inspectionName }
        let fieldList = [
            {key: 'name', label: 'Vessel Name'},
            {key: 'message', label: 'Special Instructions'},
            ...(shippingType === "VESSEL" ? [
                {key: 'laycanDateFrom', label: 'Laycan Date From', format: (value) => moment(value).format(DATEFORMAT)},
                {key: 'laycanDateTo', label: 'Laycan Date To', format: (value) => moment(value).format(DATEFORMAT)},
                {key: 'shippingTolerance', label: 'Shipping Tolerance'},
            ] : [
                {key: 'bookingReference', label: 'Booking Reference'},
                {key: 'cutOfDate', label: 'Cut off Date', format: (value) => moment(value).format(DATEFORMAT)},
                {key: 'shippingLine', label: 'Name of Shipping Line'},
                {key: 'noOfContainer', label: 'Number of Containers'},
                {key: 'quantityPerContainer', label: 'MTon per Container'},
            ]),
            {key: 'nominateQuantity', label: shippingType === "VESSEL" ? 'Quantity (MTons)' : 'Total MTon'},
            {key: 'inspectionName', label: "Inspection Company",format: (value,serie) => getInspectionCompanyName(value,serie,inspector)},
            ...(incoterm === "FOB" ? [{key: 'rejectMessage', label: 'Reason to reject vessel'}] : [])
        ]

        return (
            <React.Fragment>
                <div className='modal__container vessel_modal accpet_with_new_laycan'>
                    <form className='modal__wrapper px-0 py-0 laycan-calendar' onSubmit={onApproveWithNewLaycan} noValidate={true}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-header-center">
                                <h5 className="modal-title">
                                    {shippingType === "VESSEL" ? `View Substitute Vessel Nomination for ${tradeRefId}` : `View Substitute Container Nomination for ${tradeRefId}`}
                                </h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>        
                            <div className='pt-1'>
                                <Preloader loadingStyle="swirl" loading={LoadingNominationInfo}>
                                    <DetailTable
                                        fixWidth={false}
                                        series={vesselInfo}
                                        fieldList={fieldList}>
                                    </DetailTable>
                                </Preloader>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer' style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                        {whoItIs==="buyer" &&
                        <div>
                            {(incoterm==="FOB" && isLaycanDateChanged && !vesselAccepted) &&  
                            <button type="button" className="btn btn-secondary" onClick={onReject}>
                                <FontAwesomeIcon icon={faTimes} /> Reject
                            </button>}
                            {(incoterm==="FOB" && isLaycanDateChanged && !vesselAccepted) &&
                            <button type="submit" className="btn btn-primary">
                                <Preloader loadingStyle="dots" loading={LoadingNominationInfo}>
                                        <FontAwesomeIcon icon={faCheck} /> Accept
                                </Preloader>
                            </button>}
                        </div>}
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
};