import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ModalFooter = ({ onClose }) => {
    return (
        <div className='modal-footer' style={{ alignItems: 'center', justifyContent: 'center' }}>
            <button type="button" data-dismiss="modal" className="btn btn-secondary" onClick={onClose} aria-label="Close">
                <FontAwesomeIcon icon={faTimes} />
                Cancel
            </button>
        </div>)
}

export default ModalFooter;

