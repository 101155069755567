import {
  ShipmentQuoteApi
} from '../services/service.api';
import {
  LoadingRBACGrouptData,
  LoadingRequestData,
  LoadingTradeMessages
} from './module.loading';

export const REQUEST_QUOTE = 'shipmentQuote/REQUEST_QUOTE';
export const GET_QUOTEPRICE = 'shipmentQuote/GET_QUOTEPRICE';
export const API_ERROR = 'shipmentQuote/API_ERROR';
export const PROQUOTES_VESSEL = 'shipmentQuote/PROQUOTES_VESSEL';
export const LOAD_QUOTELIST = 'shipmentQuote/LOAD_QUOTELIST';
export const QUOTEDETAILS = 'shipmentQuote/QUOTEDETAILS';
export const LOAD_QUOTEFINALLIST = 'shipmentQuote/LOAD_QUOTEFINALLIST';
export const GET_MESSAGES = 'shipmentQuote/GET_MESSAGES';
export const POST_MESSAGE = 'shipmentQuote/POST_MESSAGE';
export const REPLY_MESSAGE = 'shipmentQuote/REPLY_MESSAGE';
export const SERVICE_PROVISIONALLIST = 'shipmentQuote/SERVICE_PROVISIONALLIST';
export const SERVICE_FINALLIST = 'shipmentQuote/SERVICE_FINALLIST';
export const LOADED_SHIPPING_COMPANIES = 'shipmentQuote/LOADED_SHIPPING_COMPANIES';
export const GET_QUOTESTATUS = 'shipmentQuote/GET_QUOTESTATUS';
export const GET_DOCUMENT = 'shipmentQuote/GET_DOCUMENT';
export const FETCH_BOOKING = 'shipmentQuote/FETCH_BOOKING';
export const FETCH_BOOKING_SUCCESS = 'shipmentQuote/FETCH_BOOKING_SUCCESS';
export const FETCH_BOOKING_ERROR = 'shipmentQuote/REQUEST_BOOKING_ERROR';
export const REQUEST_BOOKING = 'shipmentQuote/REQUEST_BOOKING';
export const REQUEST_BOOKING_SUCCESS = 'shipmentQuote/REQUEST_BOOKING_SUCCESS';
export const REQUEST_BOOKING_ERROR = 'shipmentQuote/REQUEST_BOOKING_ERROR';
export const RESET_QUOTE_REQUEST = 'shipmentQuote/RESET_QUOTE_REQUEST';
export const RESET_INITIAL = 'shipmentQuote/RESET_INITIAL';
export const GET_MAERSK_DOCUMENT = 'shipmentQuote/GET_MAERSK_DOCUMENT';

const arrayToTree = require('array-to-tree');

const initialState = {
  bookingPending: false,
  bookingError: false,
  bookingResponse: false,
  fetchBookingPending: false,
  fetchBookingError: false,
  bookingDetails: false,
  document: {},
  error: false,
  finalProvderList: [],
  messages: [],
  priceQuote: false,
  provisionalProvderList: [],
  quoteProvisionalList: [],
  quoteFinalList: [],
  quoteDetails: {},
  quoteType: {},
  requestQuotePending: false,
  serviceProvderList: [],
  shipping: { companies: [] },
  shippingCompanies: [],
  shippingList: [],
  documentContainer: [],
  isShipmentCreated: true
};


export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_QUOTE:
      return {
        ...state,
        requestQuotePending: true,
        error: false,
        priceQuote: false

      };
    case GET_QUOTEPRICE:
      return {
        ...state,
        requestQuotePending: false,
        error: false,
        priceQuote: action.payload.priceQuote

      };
    case API_ERROR:
      return {
        ...state,
        requestQuotePending: false,
        error: action.error
      };
    case PROQUOTES_VESSEL:
      return state;
    case LOAD_QUOTELIST:
      return {
        ...state,
        quoteProvisionalList: action.payload.quoteProvisionalList
      };
    case LOAD_QUOTEFINALLIST:
      return {
        ...state,
        quoteFinalList: action.payload.quoteFinalList
      };
    case QUOTEDETAILS:
      return {
        ...state,
        quoteDetails: action.payload.quoteDetails
      };
    case LOADED_SHIPPING_COMPANIES:
      let companies = {};
      if (action.payload) {
        action.payload.companies.reduce((accumulator, value) => {
          accumulator[String(value.ID)] = value.name;
          return accumulator;
        }, companies)
      }

      return {
        ...state,
        shipping: companies,
        shippingCompanies: action.payload.companies
      };
    case GET_MESSAGES:
      action.payload.forEach(val => {
        val.isReplying = false;
      });
      let messagesTree = arrayToTree(action.payload.reverse(), {
        parentProperty: 'parentID',
        customID: 'ID'
      });
      return {
        ...state,
        messages: messagesTree
      };
    case POST_MESSAGE:
      return state;
    case REPLY_MESSAGE:
      const onReplyMessage = mes => {
        if (mes.children) {
          mes.children.forEach(value => {
            if (value.ID === action.payload.id) {
              value.isReplying = !value.isReplying;
            }
            onReplyMessage(value);
          })
        }
      };
      let repliedMessages = state.messages;
      repliedMessages.forEach(value => {
        if (value.ID === action.payload.id) {
          value.isReplying = !value.isReplying;
        }
        onReplyMessage(value);
      });
      return {
        ...state,
        messages: repliedMessages
      };

    case SERVICE_PROVISIONALLIST:
      return {
        ...state,
        provisionalProvderList: action.payload.provisionalProvderList
      };
    case SERVICE_FINALLIST:
      return {
        ...state,
        finalProvderList: action.payload.finalProvderList
      };
    case GET_QUOTESTATUS:
      var shippingStatus = action.payload.quoteType.quoteType;
      var isShipmentCreated = true;
      if (shippingStatus === "NEW" || shippingStatus === "INPROGRESS" || shippingStatus === "COMPLETED" || shippingStatus === "WAITING_CONFIRMATION") {
        isShipmentCreated = false;
      }
      if (shippingStatus === "NOTFOUND") {
        isShipmentCreated = true;
      }
      return {
        ...state,
        quoteType: action.payload.quoteType,
        isShipmentCreated: isShipmentCreated
      };
    case GET_DOCUMENT:
      return {
        ...state,
        document: action.payload.document

      };
    case REQUEST_BOOKING: {
      return {
        ...state,
        bookingResponse: false,
        bookingError: false,
        bookingPending: true,
      }
    }
    case REQUEST_BOOKING_SUCCESS: {
      return {
        ...state,
        bookingResponse: action.payload.bookingResponse,
        bookingPending: false,
      }
    }
    case REQUEST_BOOKING_ERROR: {
      return {
        ...state,
        error: action.error,
        bookingPending: false
      }
    }
    case FETCH_BOOKING: {
      return {
        ...state,
        fetchBookingError: false,
        fetchBookingPending: true,
      }
    }
    case FETCH_BOOKING_SUCCESS: {
      return {
        ...state,
        bookingDetails: action.payload.booking,
        fetchBookingPending: false,
      }
    }
    case FETCH_BOOKING_ERROR: {
      return {
        ...state,
        fetchBookingError: action.error,
        fetchBookingPending: false
      }
    }
    case RESET_QUOTE_REQUEST: {
      return {
        ...state,
        bookingResponse: false,
        bookingPending: false,
        bookingError: false,
        requestQuotePending: false,
        error: false,
        priceQuote: false
      }
    }
    case RESET_INITIAL: {
      return { ...initialState }
    }
    case GET_MAERSK_DOCUMENT:
      let documentContainer = action.payload.documentContainer
      var urlBOOKREQ = "";
      var urlACK = "";
      var urlBOOKCON = "";
      var comment = "";
      if (documentContainer.length > 0) {
        for (var i = 0; i < documentContainer.length; i++) {
          if (documentContainer[i].type === "BOOKREQ") {
            urlBOOKREQ = documentContainer[i].docUrl;
          }
          if (documentContainer[i].type === "ACK") {
            urlACK = documentContainer[i].docUrl;
          }
          if (documentContainer[i].type === "BOOKCONF") {
            urlBOOKCON = documentContainer[i].docUrl;
            comment = documentContainer[i].comment;
          }
        }
      }
      return {
        ...state,
        documentContainer: {
          urlBOOKREQ: urlBOOKREQ,
          urlACK: urlACK,
          urlBOOKCON: urlBOOKCON,
          comment: comment
        }
      };
    default:
      return state;
  }
};

export const resetState = () => {
  return dispatch => dispatch({ type: RESET_INITIAL });
}

export const resetForm = () => {
  return dispatch => dispatch({ type: RESET_QUOTE_REQUEST });
}

export const getMaerskBookingReqFinal = (priceQuoteId, priceId) => {
  return dispatch => {
    dispatch({ type: FETCH_BOOKING })
    ShipmentQuoteApi.getMaerskBookingReqFinal(priceQuoteId, { priceId: priceId })
      .then(r => {
        dispatch({
          type: FETCH_BOOKING_SUCCESS,
          payload: {
            booking: r.data.data
          }
        });
      })
      .catch(e => {
        dispatch({
          type: FETCH_BOOKING_ERROR,
          error: e
        });
      })
  }
}

export const getMaerskBookingReq = (priceQuoteId) => {
  return dispatch => {
    dispatch({ type: REQUEST_QUOTE })
    ShipmentQuoteApi.getMaerskBookingReq(priceQuoteId)
      .then(r => {
        dispatch({
          type: GET_QUOTEPRICE,
          payload: {
            priceQuote: r.data
          }
        });
      })
      .catch(e => {
        dispatch({
          type: API_ERROR,
          error: e
        });
      })
  }
}


export const maerskBookingReq = (tradeId, priceQuoteId, priceId, companyName, companyId, totalPrice, traderCompanyId) => {
  return dispatch => {
    dispatch({ type: REQUEST_BOOKING })
    ShipmentQuoteApi.postMaerskBookingReq(tradeId, { priceQuoteId: priceQuoteId, priceid: priceId, companyName: companyName, companyId: companyId, totalPrice: totalPrice })
      .then(r => {
        dispatch({
          type: REQUEST_BOOKING_SUCCESS,
          payload: {
            bookingResponse: r.data
          }
        });
        getshippingStatus(tradeId, "FINAL", traderCompanyId)(dispatch);
      })
      .catch(e => {
        dispatch({
          type: REQUEST_BOOKING_ERROR,
          error: e
        });
      })
  }
}


export const getPriceQuoteContainer = (depatureDate, origin, destination, tradeId, jsonBody, cb) => {
  return dispatch => {
    dispatch({ type: REQUEST_QUOTE });
    ShipmentQuoteApi.getPriceQuoteContainer(depatureDate, origin, destination, tradeId, jsonBody)
      .then(r => {
        dispatch({
          type: GET_QUOTEPRICE,
          payload: {
            priceQuote: r.data
          }
        });
        if (cb) {
          cb({ ...r });
        }
      })
      .catch(e => {
        if (e !== undefined && e.response !== undefined) {
          dispatch({
            type: API_ERROR,
            error: e.response.data
          });
          if (cb) {
            cb({ ...e.response });
          }
        }
      });
  };
};

export const ProvisionalQuoteVessel = (tradeId, quoteType, comapnyId, params, cb) => {
  return dispatch => {
    //LoadingonGroupAction(dispatch, true);
    ShipmentQuoteApi.ProvisionalQuoteVessel(tradeId, quoteType, comapnyId, params)
      .then((r) => {
        dispatch({
          type: PROQUOTES_VESSEL,
          payload: r.data
        });
        cb({
          ...r.data
        });
        getshippingStatus(tradeId, quoteType, comapnyId)(dispatch);
      })
      .catch((r) => {

        // LoadingonGroupAction(dispatch, false);
      });
  }
};

export const getQuoteStatus = (tradeId, cb) => {
  return dispatch => {
    ShipmentQuoteApi.getPriceQuoteContainer(tradeId)
      .then(r => {
        dispatch({
          type: GET_QUOTEPRICE,
          payload: {
            priceQuote: r.data
          }
        });
        cb({
          ...r
        });
      })
      .catch(e => {
        console.log("Response", e);
      });
  };
};

export const getQuoteProvisionalList = (quoteType, companyId) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    ShipmentQuoteApi.getQuoteOverviewList(quoteType)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: LOAD_QUOTELIST,
          payload: {
            quoteProvisionalList: r.data
          }
        });
      })
      .catch(e => {
        console.error('catch', e);
        LoadingRBACGrouptData(dispatch, false);
      });
  };
};

export const getQuoteFinalList = (quoteType, companyId) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    ShipmentQuoteApi.getQuoteOverviewList(quoteType)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: LOAD_QUOTEFINALLIST,
          payload: {
            quoteFinalList: r.data
          }
        });
      })
      .catch(e => {
        console.log('catch', e);
        LoadingRBACGrouptData(dispatch, false);
      });
  };
};

export const getQuoteDetails = (Id, shipmentQuoteId, cb) => {
  return dispatch => {
    LoadingRequestData(dispatch, true);
    ShipmentQuoteApi.getQuoteDetails(Id, shipmentQuoteId)
      .then(r => {

        dispatch({
          type: QUOTEDETAILS,
          payload: {
            quoteDetails: r.data
          }
        });
        cb({
          ...r.data
        });
        LoadingRequestData(dispatch, false);
      })
      .catch(e => {
        console.log('catch', e);
        LoadingRequestData(dispatch, false);
      });
  };
};

export const getShippingCompanyList = () => {
  return dispatch => {
    // LoadingRBACGrouptData(dispatch, true);
    ShipmentQuoteApi.getShippingCompanyList()
      .then(r => {
        //LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: LOADED_SHIPPING_COMPANIES,
          payload: { companies: r.data.companies }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};

export const getProvisionalProviderQuoteList = (quoteType) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    ShipmentQuoteApi.getServiceProviderQuoteList(quoteType)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: SERVICE_PROVISIONALLIST,
          payload: {
            provisionalProvderList: r.data
          }
        });
      })
      .catch(e => {
        LoadingRBACGrouptData(dispatch, false);
      });
  };
};

export const getFinalProviderQuoteList = (quoteType) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    ShipmentQuoteApi.getServiceProviderQuoteList(quoteType)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: SERVICE_FINALLIST,
          payload: {
            finalProvderList: r.data
          }
        });
      })
      .catch(e => {
        LoadingRBACGrouptData(dispatch, false);
      });
  };
};
export const postShippingMessage = (shipmentId, CounterpartyId, message, cb) => {
  return dispatch => {
    LoadingTradeMessages(true, dispatch);
    //  InspectionQuoteApi.postInspectionMessage(inspectionId,"5be3f9c0-9106-11ea-8fea-6b0a662818a1", message).then(r => {
    ShipmentQuoteApi.postShippingMessage(shipmentId, CounterpartyId, message).then(r => {
      dispatch({
        type: POST_MESSAGE,
        payload: ''
      });
      ShipmentQuoteApi.getShippingMessages(shipmentId).then(response => {
        LoadingTradeMessages(false, dispatch);
        dispatch({
          type: GET_MESSAGES,
          payload: response.data.comments
        });
        if (cb) {
          cb();
        }
      });
    });
  };
};



export const getShippingMessages = (id, cb) => {
  return dispatch => {
    LoadingTradeMessages(true, dispatch);
    ShipmentQuoteApi.getShippingMessages(id).then(r => {
      LoadingTradeMessages(false, dispatch);
      dispatch({
        type: GET_MESSAGES,
        payload: r.data.comments
      });
    }).catch(() => {
      LoadingTradeMessages(false, dispatch);
    });
    if (cb) {
      cb();
    }
  };
};




export const ReplyMessage = (id, userId) => {
  return dispatch => {
    dispatch({
      type: REPLY_MESSAGE,
      payload: { id, userId }
    });
  };
};

export const shippingDocumentUpload = (tradeid, quoteType, companyid, params, cb) => {
  return dispatch => {
    LoadingRequestData(dispatch, true);
    ShipmentQuoteApi.shippingDocumentUpload(tradeid, quoteType, companyid, params)
      .then((res) => {

        cb({
          res
        });
        LoadingRequestData(dispatch, false);
      })
      .catch((res) => {
        cb({
          res
        });
        LoadingRequestData(dispatch, false);
      });
  }
};

export const updateQuoteStatus = (shipmentQuoteId, status, params, cb) => {
  return dispatch => {
    LoadingRequestData(dispatch, true);
    ShipmentQuoteApi.updateQuoteStatus(shipmentQuoteId, status, params).then(res => {
      cb({ res });
      LoadingRequestData(dispatch, false);
    })
      .catch(res => {
        LoadingRequestData(dispatch, false);
      });
  };
};
export const getshippingStatus = (tradeId, quoteType, companyid, cb) => {
  return dispatch => {
    ShipmentQuoteApi.getshippingStatus(tradeId, quoteType, companyid)
      .then(r => {
        dispatch({
          type: GET_QUOTESTATUS,
          payload: {
            quoteType: r.data
          }
        });
        if (cb) {
          cb({ ...r.data });
        }

      })
      .catch(e => {
        console.error(e);
      });
  };
};

export const downloadShippingDoc = (tradeId, shipmentQuoteID, cb) => {
  return dispatch => {
    ShipmentQuoteApi.downloadShippingDoc(tradeId, shipmentQuoteID)
      .then(r => {
        dispatch({
          type: GET_DOCUMENT,
          payload:
            r.data

        });
        cb({
          ...r.data
        });
      })
      .catch(r => {
        cb({
          ...r.data
        });
      });
  };
};

export const downlaodMaerskDocument = (tradeId, shipmentQuoteID, cb) => {
  return dispatch => {
    ShipmentQuoteApi.downlaodMaerskDocument(tradeId, shipmentQuoteID)
      .then(r => {
        dispatch({
          type: GET_MAERSK_DOCUMENT,
          payload: {
            documentContainer: r.data

          }
        });
        cb({
          ...r.data
        });
      })
      .catch(r => {
        // cb({
        //   ...r.data
        // });
      });
  };
};

