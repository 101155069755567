import React, {useState} from 'react';

import {TradeApi} from '../../../../services/service.api'; 

import Preloader from '../../../components/preloader/Preloader';

const DownloadDocument = function({doc, className="btn-link", loadingStyle="dots--black", label, ...rest}){

    const [pending, setPending] = useState(false);

    const downloadDoc = () => {
        setPending(true);
        TradeApi.getTradeDocument(doc).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            let a = document.createElement('a');
            a.target = "blank";
            a.href = url;
            a.download = 'Tender Conditions.pdf';
            a.click();
            setPending(false);
        }).catch(e => {
            console.error(e);
            setPending(false);
        })
    }
    if(doc){
        return <button type="button" 
            disabled={pending} {...rest} 
            className={className} 
            onClick={downloadDoc}>
                <Preloader loadingStyle={loadingStyle} loading={pending}>{label?label:doc.name}</Preloader>
            </button>
    }else{
        return false;
    }

    
}

export default DownloadDocument;