import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTradeMessages, postMessage, sorMessageByDate } from '../../../../modules/module.trade';

import Message from '../../requests/components/Message';
import Preloader from '../../../components/preloader/Preloader';
import Pagination from '../../../components/pagination/PaginationCustom';

class TradeComments extends Component {
    constructor(props) {
        super(props);
        this.textArea = React.createRef();
        this.state = {
            messagesPerPage: [],
            currentMessageIndex: 0,
            messageNoPerPage: 20
        }
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.getTradeMessages(this.props.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.messages.length !== prevProps.messages.length) {
            this.setMessagesPerPage();
        }
    }

    getCounterparty() {
        if (this.props.trade) {
            let trade = this.props.trade.items.single;
            if (trade.buyerId === this.props.account.token.companyId) {
                return { id: trade.sellerId, name: trade.seller };
            }
            if (trade.sellerId === this.props.account.token.companyId) {
                return { id: trade.buyerId, name: trade.buyer };
            } else {
                return {};
            }
        }
    }

    setMessagesPerPage(index, itemsPerPage) {
        let messages = [...this.props.messages];
        if (index || itemsPerPage) {
            this.setState({
                messagesPerPage: this.getMessagesPerPage(messages, index, itemsPerPage),
                currentMessageIndex: index,
                messageNoPerPage: itemsPerPage
            });
        } else {
            this.setState({
                messagesPerPage: this.getMessagesPerPage(messages)
            });
        }
    }

    getMessagesPerPage(messages, index, itemsPerPage) {
        if (messages) {
            let msgPerPage = [...messages];
            if (index || itemsPerPage) {
                msgPerPage = msgPerPage.splice(index, itemsPerPage);
            } else {
                msgPerPage = msgPerPage.splice(this.state.currentMessageIndex, this.state.messageNoPerPage);
            }
            return msgPerPage;
        }
    }

    postMessage = (e, value) => {
        e.preventDefault();
        let counterparty = this.getCounterparty();
        /* RDEV-1607 adding fiels needed for local update: 
            -  userID, 
            -  userName, 
            -  companyName 
            -  createdAt
            Those fiels should be retreived by the API postTradeMessage (POST : /trade/${id}/comments) 
            Actually all post APIs should retrive the saved object as it is saved in the BE
            The fileds : 
            -  parentId
            -  id
            Should be written parentID and ID to be consitent with the rest of the app */
        if (value !== '') {
            let message = {
                parentID: null,
                fromCompanyID: this.props.account.token.companyId,
                toCompanyID: counterparty ? counterparty.id : "",
                text: value,
                tradeComment: true,
                tradeRefID: this.props.tradeRefId
            };
            this.props.postMessage(this.props.id, message);
            this.textArea.current.value = '';
        }
    }

    render() {
        let counterparty = this.getCounterparty();
        return (
            <div className="trades-dtls__contract trade_comments">
                <div className="request-dtls__card-heading">Private Communication with {counterparty && counterparty.name}</div>
                <>
                    <textarea
                        className="request-dtls__comment-area"
                        name="comment"
                        id="comment"
                        placeholder="Add your message..."
                        ref={this.textArea}
                    />
                    <button
                        className="request-dtls__comment-send mt-2"
                        disabled={this.props.loadingTradeMessages}
                        onClick={e => this.postMessage(e, this.textArea.current.value)}>
                        Send
                    </button>
                    <Preloader loadingStyle="swirl" loading={this.props.loadingTradeMessages} />
                    {
                        this.state.messagesPerPage.map((message, i) => {
                            return (
                                <Message
                                    key={i}
                                    userId={this.props.account.user.id}
                                    message={message}
                                />
                            );
                        })
                    }
                    {this.props.messages.length > 0 ?
                        <Pagination
                            fetchFunction={(index, itemsPerPage) => {
                                this.setMessagesPerPage(index, itemsPerPage);
                            }}
                            totalItemsCount={this.props.messages.length}
                            itemsPerPage={this.state.messageNoPerPage}
                            pagesAtOnce={10} /> : null}
                </>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        account: state.account,
        messages: sorMessageByDate(state.trade.messages),
        loadingTradeMessages: state.loading.loadingTradeMessages,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getTradeMessages,
    postMessage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TradeComments);