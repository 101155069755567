import React, { Component } from 'react';
import { 
    DOCUMENT_TYPES, 
    getDocInfo, 
    getPTDocStatus, 
    LOADING_PT_PROCESS, 
    PT_DOC_MODALS 
} from '../../services/documents.service';
import DocumentCard from '../DocumentCard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Preloader from '../../../../components/preloader/Preloader';
import CreatePTDoc from './CreatePTDoc';
import {
    createPTDoc,
    getPTDocument,
    downloadPaymentTermDoc,
    confirmPTDoc,
    updatePaymentTermDoc
} from '../../../../../modules/module.trade';
import ConfirmPTDoc from './ConfirmPTDoc';
import UpdatePTDoc from './UpdatePTDoc';
import PreviewPTDoc from './PreviewPTDoc';
import CompletedPTDoc from './CompletedPTDoc';

class PaymentTermDocument extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showModal: false,
            modalName: ''
        }
    }

    get trade(){
        return this.props.trade.items.single
    }

    get draftType(){
        return this.trade.payment === 'Letter of credit' ? 'L/C Draft' : this.trade.payment === 'Confirmed letter of credit' ? 'Confirmed L/C Draft' : 'Avalised Draft'
    }

    componentDidMount(){
        let url = `/trade/${this.trade.id}/letterOfCredit`;
        if(this.trade.payment === 'Avalised Draft') url = `/trade/${this.trade.id}/avalizedDraft`;
        if(this.trade.payment === 'Confirmed letter of credit') url = `/trade/${this.trade.id}/confirmedLC`;
        this.props.getPTDocument(url)
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            modalName: ''
        })
    }

    downloadPaymentTermHandler = (type) => {
        let cb;
        if(type === 'preview'){
            cb = () => this.setState({
                showModal: true,
                modalName: PT_DOC_MODALS.PREVIEW_PT_DOC
            })
        }else{
            cb = () => {
                const latestFile = this.props.trade.ptDocument.files[this.props.trade.ptDocument.files.length-1]
                let link = document.createElement('a');
                link.download = latestFile.name;
                link.href = latestFile.url;
                link.click();
            }
        }
        let url = `/letterOfCredit/${this.props.trade.ptDocument.ptDocId}/downloadDoc`;
        if(this.trade.payment === 'Avalised Draft') url = `/avalizedDraft/${this.props.trade.ptDocument.ptDocId}/downloadDoc`;
        if(this.trade.payment === 'Confirmed letter of credit') url = `/confirmedLC/${this.props.trade.ptDocument.ptDocId}/downloadDoc`;
        this.props.downloadPaymentTermDoc(url, type, cb);
    }

    renderModal = () => {
        switch(this.state.modalName){
            case PT_DOC_MODALS.CREATE_PT_DOC:
                return(
                    <CreatePTDoc
                        trade={this.trade} 
                        onCancel={this.closeModal}
                        createPTDoc={this.props.createPTDoc}
                        loading={this.props.loadingPTProcess === LOADING_PT_PROCESS.CREATE}
                    />
                )
            case PT_DOC_MODALS.CONFIRM_PT_DOC:
                return(
                    <ConfirmPTDoc 
                        onCancel={this.closeModal}
                        onSubmit={this.props.confirmPTDoc}
                        ptDocDetails={this.props.trade.ptDocument}
                        downloadPaymentTermDoc={this.downloadPaymentTermHandler}
                        loading={this.props.loadingPTProcess}
                        trade={this.trade}/>
                )
            case PT_DOC_MODALS.UPDATE_PT_DOC:
                return(
                    <UpdatePTDoc 
                        onCancel={this.closeModal}
                        loading={this.props.loadingPTProcess === LOADING_PT_PROCESS.UPDATE}
                        ptDocDetails={this.props.trade.ptDocument}
                        updatePaymentTermDoc={this.props.updatePaymentTermDoc}
                        trade={this.trade} />
                )
            case PT_DOC_MODALS.PREVIEW_PT_DOC:
                return(
                    <PreviewPTDoc
                        ptDocument={this.props.trade.ptDocument}
                        paymentTerm={this.trade.payment}
                        onClose={() => this.setState({
                            showModal: true,
                            modalName: this.props.trade.ptDocument.status !== 'CONFIRMED' ? PT_DOC_MODALS.CONFIRM_PT_DOC : PT_DOC_MODALS.COMPLETED_PT_DOC
                        })} />
                )
            case PT_DOC_MODALS.COMPLETED_PT_DOC:
                return(
                    <CompletedPTDoc 
                        ptDocDetails={this.props.trade.ptDocument}
                        downloadPaymentTermDoc={this.downloadPaymentTermHandler}
                        loading={this.props.loadingPTProcess}
                        trade={this.trade}
                        onClose={this.closeModal}/>
                )
            default:
                return null;
        }
    }

    renderPTDocActions = () => {
        const actions = [];
        if (this.props.account.token.companyId === this.trade.sellerId) {
            if(this.props.trade.ptDocument){
                switch(this.props.trade.ptDocument.status){
                    case 'NEW':
                    case 'UPDATED':
                        actions.push((
                            <a href="#/" key="confirmPTDoc" className="dropdown-item"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.setState({showModal: true, modalName: PT_DOC_MODALS.CONFIRM_PT_DOC})
                                }}>
                                    View {this.draftType}
                            </a>
                        ));
                        break;
                    default:
                        break;
                }
            }
        }

        if (this.props.account.token.companyId === this.trade.buyerId) {
            if(this.props.trade.ptDocument){
                switch(this.props.trade.ptDocument.status){
                    case 'REJECTED':
                        actions.push((
                            <a href="#/" key="editPTDoc" className="dropdown-item"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.setState({showModal: true, modalName: PT_DOC_MODALS.UPDATE_PT_DOC})
                                }}>
                                    Edit {this.draftType}
                            </a>
                        ));
                        break;
                    case 'CONFIRMED':
                        actions.push((
                            <a href="#/" key="editPTDoc" className="dropdown-item"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.setState({showModal: true, modalName: PT_DOC_MODALS.COMPLETED_PT_DOC})
                                }}>
                                    View {this.draftType}
                            </a>
                        ));
                        break;
                    default:
                        break;
                }
            }else{
                actions.push((
                    <a href="#/" key="fillPTDoc" className="dropdown-item"
                        onClick={(event) => {
                            event.preventDefault();
                            this.setState({showModal: true, modalName: PT_DOC_MODALS.CREATE_PT_DOC})
                        }}>
                            Upload {this.draftType}
                        </a>
                ));
            }
        }
        if(actions.length === 0){
            return null;
        }
        const isLoading = this.props.loadingPTProcess === LOADING_PT_PROCESS.GET;
        const btnClass = isLoading ? 'trades-dtls__doc-button disabled' : 'trades-dtls__doc-button trades-dropdown-btn dropdown-toggle';
        return (
            <div className="dropdown">
                <button className={btnClass} type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <Preloader loading={isLoading} loadingStyle="dots">
                        <span>Actions</span>
                    </Preloader>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {actions}
                </div>
            </div>
        );
    }

    render() {
        const ptDocument =  this.props.trade.ptDocument;
        return ( 
            <React.Fragment>
                {this.state.showModal && this.renderModal()}
                <Preloader loading={this.props.loadingPTProcess === LOADING_PT_PROCESS.GET} loadingStyle="swirl">
                        <div className="trades-dtls__doc-wrapper">
                                <DocumentCard
                                    key={"ptDoc"}
                                    documentParams={{
                                        tradeId: this.trade.id,
                                        documentId: ptDocument ? ptDocument.ptDocId : ''
                                    }}
                                    user={this.props.account.user}
                                    required={true}
                                    comments={{ count: 0, data: [] }}
                                    loadingDocumentComments={false}
                                    GetDocumentComments={() => {}}
                                    PostDocumentComment={() => {}}
                                    commentReceivers={this.props.commentReceivers}
                                    openPreviewDocument={() => {}}
                                    permissions={{}}
                                    renderButton={this.renderPTDocActions}
                                    status={getPTDocStatus(ptDocument, this.trade, this.props.userType)}
                                    document={null}
                                    documentName={DOCUMENT_TYPES.PAYMENT_TERM_DOCUMENT}
                                    match={this.props.match}
                                    documentInfo={getDocInfo(DOCUMENT_TYPES.PAYMENT_TERM_DOCUMENT, this.trade)}
                                    showBillOfLadingRequired={false}
                                    userPermission={this.props.userPermission}
                                />
                        </div>
                        <hr />
                    </Preloader>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        account: state.account,
        loadingPTProcess: state.loading.loadingPTProcess
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    createPTDoc,
    getPTDocument,
    downloadPaymentTermDoc,
    confirmPTDoc,
    updatePaymentTermDoc
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTermDocument);