import React from 'react';
import { TradeApi } from '../../../../services/service.api';
import { faSpinner, faTimes, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TradeImagePreview.scss'

class TradeImagePreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingImage: false
        }
    }

    downLoadPreview = (image) => {
        this.setState({ loadingImage: true });
        TradeApi.getTradeDocument(image).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            this.setState({
                url: url,
                loadingImage: false
            });
        })
        .catch(e => {
            console.error(e);
            this.setState({ loadingImage: false });
        })
    }

    UNSAFE_componentWillMount(){
        this.downLoadPreview(this.props.image); 
    }

    componentDidUpdate(prevProps){
        if(prevProps.image !== this.props.image){
            this.downLoadPreview(this.props.image)
        }
    }


    render() {
        return <React.Fragment>
            <div className="card preview-card" style={{ minHeight: '60px'}}>
                {this.state.url && !this.state.loadingImage?
                    <React.Fragment>
                        <img className="rounded mx-auto d-block" width="100%" src={this.state.url} alt="Rice example" />
                        <div className="card-img-overlay preview-overlay bg-light text-center align-middle" onClick={e => this.setState({ showModal: true })}>
                            <div className="m-auto align-middle"><FontAwesomeIcon icon={faSearchPlus} size="6x"></FontAwesomeIcon></div>
                        </div>
                    </React.Fragment> :
                    <div className="rounded m-auto d-block w-100 h-100 text-center align-middle">
                        <div className="m-auto align-middle"><FontAwesomeIcon size="3x" icon={faSpinner} spin={true} /></div>
                    </div>}
            </div>
            {this.state.showModal && <div className="modal__container">
                <div className="modal__wrapper px-0 py-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="close" data-dismiss="modal" onClick={e => this.setState({ showModal: false })} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <img className="rounded mx-auto d-block" width="700" src={this.state.url} alt="Ricex Img" />
                        </div>
                        <div className="modal-footer">
                            <button className="btn-popup btn-cancel" onClick={e => this.setState({ showModal: false })}><FontAwesomeIcon icon={faTimes} /> Close</button>
                        </div>
                    </div>
                </div>
            </div>}
        </React.Fragment>
    }
}

export default TradeImagePreview;