import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { EnumsService } from '../../../services';
import NumberFormat from 'react-number-format';
import { CURRENCYOPT } from '../../../services/service.values';
import FormAsyncSelectPort from './FormAsyncSelectPort';

const Countries = EnumsService.countries();

const FormPriceBreakdown = ({incoterm, isMultiOrigin, improvingPrice, currency, customStyle, onChange, data=[], disabledOrigins=[]}) => {
    const tableHeaders = useMemo(() => {
        const headers = ["Origin", "FOB"]
        switch(incoterm){
            case "FOB":
                headers.push("Currency", "Country of Load", "Port of Load");
                break;
            case "CFR":
                headers.push("Freight", "CFR Price Per MT", "Currency");
                break;
            case "CIF":
                headers.push("Freight", "Insurance", "CIF Price Per MT", "Currency");
                break;
            default:
                headers.push("Currency");
                break;
        }
        return headers
    }, [incoterm]);

    const tableColumns = ({
        isPriceCheck,
        origin, 
        price, 
        isInvalidPrice, 
        loadCountry, 
        isInvalidLoadCountry, 
        loadPort, 
        isInvalidLoadPort, 
        fob, 
        isInvalidFob, 
        freight, 
        isInvalidFreight, 
        insurance, 
        isInvalidInsurance,
        priceBreakdown
    }) => {

        const fobValue = isMultiOrigin ? priceBreakdown.fob : fob;
        const freightValue = isMultiOrigin ? priceBreakdown.freight : freight;
        const insuranceValue = isMultiOrigin ? priceBreakdown.insurance: insurance;

        const originCol = (
            <td key="origin">
                <span className="input-group-text input-group-text-no-radius">
                    {isMultiOrigin &&
                    (<>
                        <input 
                            type="checkbox"
                            style={{cursor: 'pointer'}} 
                            name={origin} 
                            value={isPriceCheck}
                            checked={isPriceCheck} 
                            onChange={e => onChange(origin, 'isPriceCheck', e.target.checked, false, priceBreakdown)}
                            disabled={disabledOrigins.includes(origin)}/>&nbsp;
                    </>)}
                    {Countries[origin]}
                </span>
            </td>
        )

        const currencyCol = (
            <td key="currency">
               {isMultiOrigin ? (
                    <span className="input-group-text input-group-text-no-radius">{currency}</span>
               ):(
                    <select className="custom-select" 
                        value={currency} 
                        name={"currency"}
                        onChange={ e => { onChange(origin, e.target.name, e.target.value, false, priceBreakdown)}}
                        disabled={improvingPrice}>
                        {Object.keys(CURRENCYOPT).map(i => {
                            return (
                                <option key={i} value={i}>{CURRENCYOPT[i]}</option>
                            );
                        })}
                    </select>
               )} 
            </td>
        )

        const loadCountryCol = (
            <td key="loadCountry">
                <select
                    className={`${customStyle.selectClassName} ${isInvalidLoadCountry ? customStyle.isInvalidClassName : customStyle.isValidClassName}`}
                    name={origin+"-loadCountry"}
                    value={loadCountry}
                    id={origin+"-loadCountry"}
                    disabled={isMultiOrigin ? (!isPriceCheck || disabledOrigins.includes(origin)) : improvingPrice}
                    onChange={e => onChange(origin, 'loadCountry', e.target.value, false, priceBreakdown)}>
                        <option value="" disabled={true} hidden={true} defaultValue="">Select</option>
                        {Object.keys(Countries).map(isoCode => (
                            <option key={isoCode} value={isoCode}>{Countries[isoCode]}</option>
                        ))}
                </select>
            </td>
        )

        const loadPortCol = (
            <td key="loadPort" className="w-25">
                <FormAsyncSelectPort
                    customStyle={{wrapperClassName : ""}} 
                    fieldData={{
                        name: origin+"-loadPort",
                        value: loadPort,
                        touched: true
                    }}
                    onChange={(name, value, fromDropdown) => onChange(origin, 'loadPort', value, fromDropdown, priceBreakdown)}
                    country={loadCountry}
                    validation={isInvalidLoadPort ? {[origin+"-loadPort"]: isInvalidLoadPort } : {}}
                    styles={{
                        indicatorsContainer: (provided) => ({...provided, height: 31})
                    }}
                    disabled={isMultiOrigin ? (!isPriceCheck || disabledOrigins.includes(origin)) : improvingPrice}
                />
            </td>
        )

        const fobPriceCol = (
            <td key="fob">
                <NumberFormat 
                    type="text" 
                    className={`${customStyle.inputClassName} ${isInvalidFob ? customStyle.isInvalidClassName : customStyle.isValidClassName}`}
                    name={origin+"-fob"}
                    value={fobValue} 
                    onValueChange={values => onChange(origin, "fob", values.value, false, priceBreakdown)}
                    displayType={'input'} 
                    disabled={isMultiOrigin && !isPriceCheck}
                    thousandSeparator={true}
                    decimalScale={2}
                    allowEmptyFormatting={false}
                    isNumericString={true}
                    allowNegative={false}
                    isAllowed={(values) => {
                        const {floatValue, value  } = values;
                        return value  === "" || (floatValue>0 && floatValue <= 1000000);
                    }}/>
            </td>
        )

        const freightPriceCol = (
            <td key="freight">
                <NumberFormat 
                    type="text" 
                    className={`${customStyle.inputClassName} ${isInvalidFreight ? customStyle.isInvalidClassName : customStyle.isValidClassName}`}
                    name={origin+"-freight"}
                    value={freightValue} 
                    onValueChange={values => onChange(origin, "freight", values.value, false, priceBreakdown)}
                    displayType={'input'} 
                    disabled={isMultiOrigin && !isPriceCheck}
                    thousandSeparator={true}
                    decimalScale={2}
                    allowEmptyFormatting={false}
                    isNumericString={true}
                    allowNegative={false}
                    isAllowed={(values) => {
                        const {floatValue, value  } = values;
                        return value  === "" || (floatValue>0 && floatValue <= 1000000);
                    }}/>
            </td>
        )

        const insurancePriceCol = (
            <td key="insurance">
                <NumberFormat 
                    type="text" 
                    className={`${customStyle.inputClassName} ${isInvalidInsurance ? customStyle.isInvalidClassName : customStyle.isValidClassName}`}
                    name={origin+"-insurance"}
                    value={insuranceValue} 
                    onValueChange={values => onChange(origin, "insurance", values.value, false, priceBreakdown)}
                    displayType={'input'} 
                    disabled={isMultiOrigin && !isPriceCheck}
                    thousandSeparator={true}
                    decimalScale={2}
                    allowEmptyFormatting={false}
                    isNumericString={true}
                    allowNegative={false}
                    isAllowed={(values) => {
                        const {floatValue, value  } = values;
                        return value  === "" || (floatValue>0 && floatValue <= 1000000);
                    }}/>
            </td>
        )

        const priceCol = (
            <td key="price">
                <NumberFormat 
                    type="text" 
                    className={`${customStyle.inputClassName} ${isInvalidPrice ? customStyle.isInvalidClassName : customStyle.isValidClassName}`}
                    name={origin+"-price"}
                    value={price} 
                    onValueChange={values => onChange(origin, "price", values.value, false, priceBreakdown)}
                    displayType={'input'} 
                    disabled={true}
                    thousandSeparator={true}
                    decimalScale={2}
                />
            </td>
        )

        const columns = [originCol, fobPriceCol];
        switch(incoterm){
            case 'FOB':
                columns.push(currencyCol, loadCountryCol, loadPortCol);
                break;
            case 'CFR':
                columns.push(freightPriceCol, priceCol, currencyCol);
                break;
            case 'CIF':
                columns.push(freightPriceCol, insurancePriceCol, priceCol, currencyCol);
                break;
            default:
                columns.push(currencyCol);
                break;

        }
        return columns
    }

    return(
        <React.Fragment>
            <table id="priceBreakdown" className="table table-bordered table-sm mb-0">
                <thead className="thead-dark">
                    <tr>
                        {tableHeaders.map(header => <th key={header}>{header}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.origin}>
                            {tableColumns(item)}
                        </tr>
                    ))}
                </tbody>
            </table>
            { improvingPrice && (
                <small className="form-text text-muted">
                   *{incoterm === 'FOB' ? 'FOB Price' : `${incoterm} Price Per MT`} should be less than from the previous offer
                </small>
            )}
        </React.Fragment>
    )
}

FormPriceBreakdown.propTypes = {
    incoterm: PropTypes.any,
    isMultiOrigin: PropTypes.bool,
    currency: PropTypes.string,
    customStyle: PropTypes.object,
    onChange: PropTypes.func,
    data: PropTypes.array,
    improvingPrice: PropTypes.bool,
    disabledOrigins: PropTypes.array
}

export default FormPriceBreakdown;