import React from 'react';
import './FormInputField.scss';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export default ({
                    name,
                    value,
                    type,
                    validation,
                    onChange,
                    placeholder = ''
                }) => (
    <div className="form-input form-input--phone">
        {value.label &&
        <label htmlFor={name} className="label">
            {value.label}<span className="req-field">{value.required ? '*' : ''}</span>
        </label>}
        <PhoneInput
            placeholder={placeholder}
            value={value.value}
            onChange={onChange}
            required={!!value.required}
            name={name}
            limitMaxLength={true}
            inputClassName={`input form-control ${validation && validation.hasOwnProperty(name) ? ' input_error ' : ''}`}
        />
    </div>
);