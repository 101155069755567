import React from 'react';
import { TRADE_STATUS, STEPS } from '../../../../services/service.values';
import { EnumsService, PortService } from '../../../../services/service.utils';
import DocumentShippingAdvice from './DocumentShippingAdvice';

const DocumentShippingAdviceList = ({ trade, bills, userName, isModal, status, synthesis }) => {
    status = status || trade.status;
    const showError = !isModal && STEPS.indexOf(status) < STEPS.indexOf(TRADE_STATUS.ADVICE);
    return (
        <div className={`shipping-advice-form${isModal ? ' shipping-advice-form1--modal' : ''}`}>
            {showError
                ? 'Shipping advice has not been issued yet'
                : Object.values(bills).map((bill,index) => (
                    <DocumentShippingAdvice key={index} trade={trade} bill={bill} userName={userName} isModal={isModal} status={status} synthesis={synthesis} />
                ))
            }
        </div>
    );
};

export default DocumentShippingAdviceList;