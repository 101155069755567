import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const QuoteThankYouNoteModal = ({type, data, onClose}) => {
  return (
    <React.Fragment>
      <div className="modal__container">
        <form
          className="modal__wrapper px-0 py-0"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-header-center">
                <h5 className="modal-title">
                  Request a Provisional Quote for {type} via {data.shipping === "VESSEL" ? "Vessel" : "Container"}
                </h5>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={onClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body provisional-Quote">
              <div className="text-center">
                <div className="big-dot">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </div>
                <h5 className="thanks-message text-center">Thank you</h5>
              </div>
              <p className="mt-2 px-2 text-center">
                Your request has been submitted to the Service Provider. Note
                that any information is provisional and subject to a final
                quotation by the Service Provider at a later stage during the
                process.
              </p>
              <p className="mt-1 px-1 text-center">
                You will receive a notification once the Service Provider has
                processed your request. Via your 'Quote Requests' Overview
                screen you have the opportunity to add comments or ask
                additional questions. Click the Back button to return to the
                previous screen.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <button onClick={onClose} className="btn-popup btn-cancel ">
              <FontAwesomeIcon icon={faTimes} /> <span>Close</span>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default QuoteThankYouNoteModal;