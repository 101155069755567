import React, { Component } from 'react';

import moment from 'moment-timezone';
import { DATEFORMAT, DATEFORMATHOURS,REQUEST_PRIVACY_TYPE, OFFER_TYPES } from '../../../../services/service.values';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import ConfirmModalCancelTrade from '../components/confirmModals/ConfirmModalCancelTrade.js';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { negotiateTradePermission } from '../../../../services/service.permission';
import {getLocalTimezone} from '../../../../services/service.utils';
import ExtendTradeValidity from './../components/confirmModals/ExtendTradeValidity';

class TradeDetail extends Component {

    constructor() {
        super();
        this.state = {
            showModal: false,
            modal: '',
            modalData: {},
        };
    }



    renderModal = (whatModal) => {
        if (whatModal === 'PERMISSION_REQUIRED') {
            return (
                <div className="modal__container">
                    <form
                        className="modal__wrapper px-0 py-0 authcode"
                        onSubmit={e => {
                            e.preventDefault();
                            this.closeModal();
                        }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </Link>
                            </div>
                            <div className="modal-body">
                                <p className="center-text"><h6>You are not authorized to perform this action.</h6>
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                    <FontAwesomeIcon icon={faTimes} /> Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            );
        }
    };
    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, modalData: data });
    };
    
    


    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            modalData: {}
        });
    };

    render() {
        var isNegotiate = false;
        if (negotiateTradePermission() === 1) {
            isNegotiate = true;
        }

        const { request, user, heading, canCancel, onCancel, loading } = this.props;
        let privacy = "";
        if (request.privacyType) {
            privacy = REQUEST_PRIVACY_TYPE[request.privacyType]
        } 
        // else {
        //     privacy = (!request.buyerId || !request.sellerId) ? "Public " : "Private ";
        // }
        const isTradeOwner = user.companyId === request.ownerId
        if (request.privacyType==="MULTI_PRIVATE" && !isTradeOwner) 
        {
            privacy="Private";
        }
        return (
            <div className="row trade-detail">
                {this.state.showModal && this.renderModal(this.state.modal)}
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-5">
                            <span className="field-name">Trade Ref Id</span>
                        </div>
                        <div className="col-md-7">
                            <span className="field-value">{request.tradeRefId || request.id}</span>
                        </div>
                    </div>
                    <div className="row mt-2 mb-2">
                        <div className="col-md-5">
                            <span className="field-name">Creation Date</span>
                        </div>
                        <div className="col-md-7">

                            <span className="field-value">{request.createdAt && moment(request.createdAt).format(DATEFORMAT)}</span>
                        </div>
                    </div>
                    {request.buyer && <div className="row mt-2 mb-2">
                        <div className="col-md-5">
                            <div className="field-name">{(request.requestType) === "BUY" ? 'Request from' : ''} Buyer </div>
                        </div>
                        <div className="col-md-7">
                            <div className={`field-value ${(request.buyerId === user.companyId) ? "text-primary" : ""}`}>
                            <Link to={`/company-profile/${request.buyerId}`}>
                                <span>{request.buyer}</span>
                                {request.buyerUser ? <span>&nbsp;({request.buyerUser})</span> : null}
                                {request.buyerId === user.companyId ? <span>&nbsp;(You)</span> : null}
                                </Link>
                            </div>
                        </div>
                    </div>}
                    {request.seller && <div className="row mt-2 mb-2">
                        <div className="col-md-5">
                            <div className="field-name">{(request.requestType) === "SELL" ? 'Request from' : ''} Seller</div>
                        </div>
                        <div className="col-md-7">
                            <div className={`field-value ${(request.sellerId === user.companyId) ? "text-primary" : ""}`}>
                               <Link to={`/company-profile/${request.sellerId}`}>
                                <span>{request.seller}</span>
                                {request.sellerUser ? <span>&nbsp;({request.sellerUser})</span> : null}
                                {request.sellerId === user.companyId ? <span>&nbsp;(You)</span> : null}
                                </Link>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="col-md-4">
                    {['NEW', 'EXPIRED', 'CANCELED', 'DECLINED'].includes(request.requestStatus) &&
                        <div className="row">
                            <div className="col-md-5">
                                <div className="field-name">Trade Status</div>
                            </div>
                            <div className="col-md-7">
                                {request.requestStatus === 'NEW' &&
                                    <span className="badge badge-accepted">{request.requestStatus}</span>}
                                {request.requestStatus === 'EXPIRED' &&
                                    <span className="badge badge-rejected">{request.requestStatus}</span>}
                                {request.requestStatus === 'CANCELED' &&
                                    <span className="badge badge-disabled">{request.requestStatus}</span>}
                                {request.requestStatus === 'DECLINED' &&
                                    <span className="badge badge-rejected">{request.requestStatus}</span>}
                            </div>
                        </div>}
                    <div className="row mt-2 mb-2">
                        <div className="col-md-5">
                            <div className="field-name">Trade Type</div>
                        </div>
                        <div className="col-md-7">
                            { request.requestType &&
                            <div className="field-value">      
                                {`${privacy} ${ (request.requestType === "BUY")?  "RTB" : "RTS"} (${(request.price && parseInt(request.price) !== 0) ? "with Price" : "without Price"}${request.dutchAuction?" & with Dutch Auction":""}${privacy === 'Tender' && request.ownerId === user.companyId ? request.whitelistOnlyTender ? " & available for Whitelist Group" : " & available for All Companies" : ""})`}
                            </div> }
                        </div>
                    </div>
                    { request.tenderStartDate &&
                    <div className="row mt-2 mb-2">
                        <div className="col-md-5">
                            <div className="field-name">Opening Date ({getLocalTimezone()})</div>
                        </div>
                        <div className="col-md-7">
                            <div className="field-value">{request.tenderStartDate && moment(request.tenderStartDate).format(DATEFORMATHOURS)}</div>
                        </div>
                    </div>}
                    {request.requestType === "SELL" && request.offerType && (
                    <div className="row mt-2 mb-2">
                        <div className="col-md-5">
                            <div className="field-name">Offer Type</div>
                        </div>
                        <div className="col-md-7">
                            <div className="field-value">{OFFER_TYPES[request.offerType]}</div>
                        </div>
                    </div>)}
                    <div className="row mt-2 mb-2">
                        <div className="col-md-5">
                            <div className="field-name">{privacy === "Tender" ? `Closing Date (${getLocalTimezone()})`: `Expiration Date (${getLocalTimezone()})`}</div>
                        </div>
                        <div className="col-md-7">
                            { (request.validateDate) && (
                                <div 
                                    className="field-value" >
                                        {moment(request.validateDate).format(DATEFORMATHOURS)}{' '} 
                                        {(isTradeOwner && privacy !== "Tender" && moment() <= moment(request.validateDate)) && <ExtendTradeValidity/>}
                                </div>   
                            )}
                        </div>
                    </div>
                </div>
                {heading &&
                    <div className="col-md-4">
                        <div className="float-right">
                            {(heading.flag && request.requestStatus !== "CANCELED") && <div className="text-right">
                                <div className="status-flag mb-3" ><FontAwesomeIcon icon={faInfoCircle} />&nbsp;{heading.flag}</div>
                                {isNegotiate ?
                                <span>
                                    {canCancel && 
                                    <ConfirmModalCancelTrade 
                                        requestStatus={request.status} 
                                        buttonLabel={`Cancel ${request.privacyType === "TENDER"?'Tender':'Trade'}`}
                                        buttonConfirmLabel={<React.Fragment><FontAwesomeIcon icon={faTrashAlt} />&nbsp;{`Cancel ${request.privacyType === "TENDER"?'Tender':'Trade'}`}</React.Fragment>}
                                        onConfirm={onCancel} 
                                        loading={loading} />} 
                                </span>
                                : 
                                <span>
                                    {canCancel && 
                                    <button onClick={() => this.openModal('PERMISSION_REQUIRED', this.state.data)} 
                                        className="btn btn-action btn-danger" 
                                        type="button" >
                                            {`CANCEL ${request.privacyType === "TENDER"?'TENDER':'TRADE'}`}
                                    </button>}
                                </span>}
                            </div>}

                        </div>
                    </div>}
            </div>
        )
    }
}

export default TradeDetail;