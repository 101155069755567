import React from 'react';

const CompanyDocuments = ({ company }) => (
    <div>
        <h4>Documents</h4>
        {company ? (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Certificate of Incorporation/Registry Extract
                            </div>
                            <div className="detail-text">
                                {company.certificateOfIncorporation && company.certificateOfIncorporation.fileUrl !== "#" && <a href={company.certificateOfIncorporation.fileUrl} >View</a>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="details-wrapper">
                            <div className="detail-heading">
                                Articles of Incorporation (By laws)
                            </div>
                            <div className="detail-text">
                                {company.articlesOfIncorporation && company.articlesOfIncorporation.fileUrl !== "#" && <a href={company.articlesOfIncorporation.fileUrl}>View</a>}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        ) : "Documents Not Found"}
    </div>
);

export default CompanyDocuments;